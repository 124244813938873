import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { mapCategoryIdToName } from '../../../../requests/categories';
import MyDatePicker from '../../../../widgets/DatePicker';
import { formatStringToDate } from '../../../../utils/formats';
import priorities, { prioritiesMap } from '../../priorities';
import MySelect from '../../../../widgets/MySelect';
import { globalTypesProject } from '../../projectTypes';
import imageType from '../../image_type';
import SelectTag from '../../../../widgets/SelectTag';
import checkRole from '../../../../utils/checkRole';
import { getRolesFromGroupsUsers } from '../../../customers/roles/groupsUsers';
import useMobileScreen from '../../../../hooks/useMobileScreen';
import hcContentTypes from '../../hcContentTypes';
import { useProjectsList } from './ProjectsListContext';



const propTypes = {
  toggleFilter: PropTypes.func.isRequired,
  fromStatsPage: PropTypes.bool.isRequired,
  onFilterApply: PropTypes.func.isRequired,
  onFilterChange: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
};

const ProjectsFilter = ({
  toggleFilter,
  fromStatsPage,
  onFilterApply,
  onFilterChange,
  resetFilter,
}) => {
  const { filter, type } = useProjectsList();
  const { lang, categories, users, userGroups } = useSelector(({ language, categories, users, user }) => ({
    lang: language.lang,
    categories,
    users,
    userGroups: user.groups,
  }), shallowEqual);
  const [ isMobile ] = useMobileScreen();
  const availableRoles = getRolesFromGroupsUsers(userGroups);
  const categoryIdValue = mapCategoryIdToName(categories, filter.category_id);
  const filterExecutor = users.find((user) => user.id === filter.executor);

  const setDateRangeTo = (date, selectedDate, field) => {
    if (date > new Date(selectedDate)) {
      onFilterChange({
        target: {
          name: field,
          value: date,
        },
      });
    }
  };
  const setDateRangeFrom = (date, selectedDate, field) => {
    if (date < new Date(selectedDate)) {
      onFilterChange({
        target: {
          name: field,
          value: date,
        },
      });
    }
  };

  return (
    <div
      className={`${isMobile ? 'projects-filter' : ''} kt-demo-panel d-flex flex-column h-100 overflow-hidden ${filter.show ? 'kt-demo-panel--on' : ''}`}
    >
      <div className='kt-demo-panel__head'>
        <h3 className='kt-demo-panel__title'>{lang['PROJECT.FILTER']}</h3>
        <a
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className='kt-demo-panel__close'
          onClick={toggleFilter}
        >
          <i className="flaticon2-delete" />
        </a>
      </div>
      <div
        className='kt-demo-panel__body d-inline-flex'
      >
        <Form
          className="col-12"
          onSubmit={(event) => {
            event.preventDefault();
            onFilterApply();
          }}
        >
          <div className="kt-portlet__body">
            <div className="row">
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>ID</Form.Label>
                <Form.Control
                  type="text"
                  name="project_ids"
                  value={filter.project_ids}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['GLOBAL.TITLE']}</Form.Label>
                <Form.Control
                  type="text"
                  name="title"
                  value={filter.title}
                  onChange={onFilterChange}
                />
              </Form.Group>
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['GLOBAL.DATE_CREATE']}</Form.Label>
                <div className="row">
                  <div className="col-6 kt-mb-5">
                    <MyDatePicker
                      name="created_from"
                      selected={filter.created.from ? formatStringToDate(filter.created.from) : ''}
                      placeholder={lang['GLOBAL.FROM']}
                      minDate={new Date(2017, 0, 1)}
                      maxDate={new Date()}
                      position="bottom left"
                      onChange={(date) => {
                        onFilterChange({
                          target: {
                            name: 'created_from',
                            value: date,
                          },
                        });
                        setDateRangeTo(date, filter.created.to, 'created_to');
                      }}
                    />
                  </div>
                  <div className="col-6 kt-mb-5">
                    <MyDatePicker
                      name={'created_to'}
                      selected={filter.created.to ? formatStringToDate(filter.created.to) : ''}
                      placeholder={lang['GLOBAL.TO']}
                      minDate={new Date(2017, 0, 1)}
                      maxDate={new Date()}
                      position="bottom right"
                      onChange={(date) => {
                        onFilterChange({
                          target: {
                            name: 'created_to',
                            value: date,
                          },
                        });
                        setDateRangeFrom(date, filter.created.from, 'created_from');
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['GLOBAL.DEADLINE']}</Form.Label>
                <div className="row">
                  <div className="col-6 kt-mb-5">
                    <MyDatePicker
                      name="deadline_from"
                      selected={filter.deadline && filter.deadline.from ? formatStringToDate(filter.deadline.from) : ''}
                      placeholder={lang['GLOBAL.FROM']}
                      minDate={new Date(2017, 0, 1)}
                      position="bottom right"
                      onChange={(date) => {
                        onFilterChange({
                          target: {
                            name: 'deadline_from',
                            value: date,
                          },
                        });
                        setDateRangeTo(date, filter.deadline.to, 'deadline_to');
                      }}
                    />
                  </div>
                  <div className="col-6 kt-mb-5">
                    <MyDatePicker
                      name="deadline_to"
                      selected={filter.deadline.to ? formatStringToDate(filter.deadline.to) : ''}
                      placeholder={lang['GLOBAL.TO']}
                      minDate={new Date(2017, 0, 1)}
                      position="bottom right"
                      onChange={(date) => {
                        onFilterChange({
                          target: {
                            name: 'deadline_to',
                            value: date,
                          },
                        });
                        setDateRangeFrom(date, filter.deadline.from, 'deadline_from');
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['PROJECT.FIELDS.hc_release_date']}</Form.Label>
                <div className="row">
                  <div className="col-6 kt-mb-5">
                    <MyDatePicker
                      name="hc_release_date_from"
                      selected={filter.hc_release_date && filter.hc_release_date.from ? formatStringToDate(filter.hc_release_date.from) : ''}
                      placeholder={lang['GLOBAL.FROM']}
                      position="bottom right"
                      onChange={(date) => {
                        onFilterChange({
                          target: {
                            name: 'hc_release_date_from',
                            value: date,
                          },
                        });
                        setDateRangeTo(date, filter.hc_release_date.to, 'hc_release_date_to');
                      }}
                    />
                  </div>
                  <div className="col-6 kt-mb-5">
                    <MyDatePicker
                      name="hc_release_date_to"
                      selected={filter.hc_release_date.to ? formatStringToDate(filter.hc_release_date.to) : ''}
                      placeholder={lang['GLOBAL.TO']}
                      position="bottom right"
                      onChange={(date) => {
                        onFilterChange({
                          target: {
                            name: 'hc_release_date_to',
                            value: date,
                          },
                        });
                        setDateRangeFrom(date, filter.hc_release_date.from, 'hc_release_date_from');
                      }}
                    />
                  </div>
                </div>
              </Form.Group>
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['PROJECT.FIELDS.hc_content_type']}</Form.Label>
                <Form.Control
                  as="select"
                  name="hc_content_type"
                  value={filter.hc_content_type}
                  onChange={onFilterChange}
                >
                  <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                  {hcContentTypes.map((hcContentType) => (
                    <option
                      key={hcContentType}
                      value={hcContentType}
                    >
                      {lang[`PROJECT.FIELDS.hc_content_type.${hcContentType}`]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['GLOBAL.PRIORITY']}</Form.Label>
                <Form.Control
                  as="select"
                  name="priority"
                  value={filter.priority}
                  onChange={onFilterChange}
                >
                  <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                  {priorities.map((priority) => (
                    <option
                      key={priority}
                      value={prioritiesMap[priority]}
                    >
                      {lang[`GLOBAL.PRIORITY.${prioritiesMap[priority]}`]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>

              <Form.Group className="col-md-6 col-12 kt-mb-5">
                <Form.Label>
                  {lang['GLOBAL.EXECUTOR']}
                </Form.Label>
                <MySelect
                  isClearable
                  name="executor"
                  value={filterExecutor ? {
                    value: filterExecutor.id,
                    label: filterExecutor.username,
                  } : ''}
                  options={users.filter((item) => checkRole(item.roles, availableRoles)).map((user) => ({
                    value: user.id,
                    label: user.username,
                  }))}
                  onChange={(value) => {
                    onFilterChange({
                      target: {
                        name: 'executor',
                        value: value?.value || '',
                      },
                    });
                  }}
                />
              </Form.Group>
              {type === (globalTypesProject.COLORING) && (
                <>
                  {!fromStatsPage && (
                    <Form.Group className="col-md-6 col-12 kt-mb-5">
                      <Form.Label>
                        {lang['PROJECT.TAPS_COUNT']}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="taps"
                        value={`${filter.taps.from}-${filter.taps.to}`}
                        onChange={onFilterChange}
                      >
                        <option value="-">{lang['GLOBAL.NO_CHOOSE']}</option>
                        <option value="1-300">1-300</option>
                        <option value="301-500">301-500</option>
                        <option value="501-700">501-700</option>
                        <option value="701-1000">701-1000</option>
                        <option value="1001-10000">1001 {lang['GLOBAL.AND_MORE']}</option>
                      </Form.Control>
                    </Form.Group>
                  )}
                  <Form.Group className="col-md-6 col-12 kt-mb-5">
                    <Form.Label>
                      {lang['PROJECT.FIELDS.type']}
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="image_type"
                      value={filter.image_type}
                      onChange={onFilterChange}
                    >
                      <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                      {imageType.map((cat) => (
                        <option key={cat} value={cat}>
                          {lang[`PROJECT.FIELDS.type.${cat}`]}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>
                  <Form.Group className="col-12 kt-mb-5">
                    <Form.Label>
                      {lang['MENU.TAGS']}
                    </Form.Label>
                    <SelectTag
                      name="tags_id"
                      canCreate={false}
                      value={filter.tags_id}
                      onChange={(id, tags) => {
                        onFilterChange({
                          target: {
                            name: 'tags_id',
                            value: {
                              id,
                              tags,
                            },
                          },
                        });
                      }}
                    />
                  </Form.Group>
                  {!fromStatsPage && (
                    <Form.Group className="col-12 kt-mb-5">
                      <Form.Label>
                        {lang['GLOBAL.CATEGORY']}
                      </Form.Label>
                      <Form.Control
                        as="select"
                        name="category_id"
                        value={categoryIdValue}
                        onChange={onFilterChange}
                      >
                        <option value="" />
                        {categories.map((item) => (
                          <option key={item.id} value={item.name}>
                            {item.name}
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  )}
                </>
              )}
              <div className="col-12 kt-mb-5">
                <div className="float-right kt-mt-20">
                  <Button type="submit">
                    {lang['GLOBAL.APPLY']}
                  </Button>
                  <Button
                    as="span"
                    className="kt-ml-20"
                    variant="secondary"
                    onClick={resetFilter}
                  >
                    {lang['GLOBAL.RESET']}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

ProjectsFilter.propTypes = propTypes;

export default ProjectsFilter;
