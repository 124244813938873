const getGroupObject = (newGroup, groupId, store) => {
  const editor = store.users.filter((user) => user.id === newGroup.editor);
  const editorName = editor[0].username;

  return {
    id: groupId,
    name: newGroup.name,
    description: newGroup.description,
    count_artists: newGroup.artists.length,
    count_designers: newGroup.designers.length,
    editor: editorName,
    editor_id: newGroup.editor,
  };
};

export default getGroupObject;
