import { complexityLevels } from '../../tasks/complexityLevels';
import taskTypes from '../../tasks/taskTypes';
import isDisabledProjectContourComplexity from './isDisabledProjectContourComplexity';
import isDisabledProjectColorComplexity from './isDisabledProjectColorComplexity';



const isDisabledProjectComplexityChange = (project, type) => {
  return (
    type === complexityLevels.contour_complexity_level && isDisabledProjectContourComplexity(project, taskTypes.artist_drawing.type)
        ||
        type === complexityLevels.color_complexity_level && isDisabledProjectColorComplexity(project, taskTypes.designer_coloring.type)
  );
};

export default isDisabledProjectComplexityChange;
