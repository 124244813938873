import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import { Loader } from '../../../../components/Loader';
import TaskListHeader from './TaskListHeader';
import TaskItem from './TaskItem';



const TasksList = ({ items, loading }) => {
  return (
    <div className='kt-portlet mw-100 overflow-auto' style={{ boxShadow: '1px 1px 5px #8d8c9b', height: '800px' }}>
      {loading ? <Loader /> : (
        <Table
          striped
          className='vertical-align-middle text-center kt-font-md'
          size="sm"
        >
          <TaskListHeader />
          <tbody>
            {items.map((item) => {
              return (
                <TaskItem
                  key={item.id}
                  item={item}
                />
              );
            })}
          </tbody>
        </Table>
      )}
    </div>
  );
};

TasksList.propTypes = {
  items: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default TasksList;
