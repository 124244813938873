import { entitySubtypes, entityTypes } from '../../config/config';



export const transformToRequest = (data) => {
  const options = [];

  Object.entries(data)
    .forEach(([ key, value ]) => {
      if (!key.startsWith('option_')) {
        return;
      }

      options.push({
        name: key.replace('option_', ''),
        value,
      });
    });

  const subtype = () => {
    if (data?.subtype) {
      return data.subtype;
    }

    return data.is_global ? entitySubtypes.global : entitySubtypes.local;
  };

  return {
    name: data.name,
    hint: data.hint,
    type: entityTypes.group,
    subtype: subtype(),
    children: [],
    parents: Array.isArray(data.templates) ? data.templates?.map((template) => template?.value) : [ data.templates?.value ],
    options,
  };
};
