import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Preload from '../../widgets/Preload';
import axiosApiInstance from '../../requests/utils/api';
import { buildResponseUsersFromAttribute } from '../../requests/users';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';



const Invitation = () => {
  const lang = useSelector((store) => store.language.lang);
  const users = useSelector((store) => store.users);
  const [ invitations, setInvitations ] = useState([]);
  const [ LOAD, setLOAD ] = useState(true);

  const getInvitations = () => {
    axiosApiInstance.get('invitations', {})
      .then((response) => {
        const msg = response.data;

        if (msg) {
          let invitations = buildResponseUsersFromAttribute(msg.invitations, 'invited_by');

          invitations = buildResponseUsersFromAttribute(invitations, 'user_id');
          setInvitations(invitations);
        }
      })
      .finally(() => setLOAD(false));
  };

  useEffect(() => {
    if (users.length) {
      getInvitations();
    }
  }, [ users ]);

  return LOAD ? (
    <Preload />
  ) : (
    <>
      <SubHeader />
      <div className="kt-portlet kt-portlet--mobile">
        <div className="kt-portlet__body">
          <Table bordered className={'mb-0'}>
            <thead>
              <tr>
                <th>{lang['INVITATION.ID']}</th>
                <th>{lang['INVITATION.By']}</th>
                <th>{lang.ROLE}</th>
                <th>{lang['INVITATION.REGISTERED']}</th>
                <th>{lang['INVITATION.PROFILE']}</th>
              </tr>
            </thead>
            <tbody>
              {invitations.map((invitation) => {
                return (
                  <tr key={invitation.id}>
                    <td>{invitation.id}</td>
                    <td>{invitation.invited_by?.username}</td>
                    <td>{invitation.role}</td>
                    <td>
                      {invitation.is_used ? (
                        <span className="kt-badge  kt-badge--success kt-badge--inline kt-badge--pill">
                          {lang['GLOBAL.YES']}
                        </span>
                      ) : (
                        <span className="kt-badge  kt-badge--danger kt-badge--inline kt-badge--pill">
                          {lang['GLOBAL.NO']}
                        </span>
                      )}
                    </td>
                    <td>
                      <Link to={urlPageFreelancerDetails({ freelancerId: invitation.user_id?.id })}>
                        {invitation.user_id?.username}
                      </Link>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </div>
    </>);
};

export default Invitation;
