import taskTypes from '../../pages/tasks/taskTypes';



const actions = {
  SET_BILLING_TASKS: 'SET_BILLING_TASKS',
  SET_LOADING_BILLING: 'SET_LOADING_BILLING',
  SET_PREV_MONTH_BILLING: 'SET_PREV_MONTH_BILLING',
  UPDATE_TRACKING_TIME: 'UPDATE_TRACKING_TIME',
  UPDATE_TRACKING_TIME_PREV: 'UPDATE_TRACKING_TIME_PREV',
  setBilling: (payload) => ({
    type: actions.SET_BILLING_TASKS,
    payload,
  }),
  setLoadingBilling: (payload) => ({
    type: actions.SET_LOADING_BILLING,
    payload,
  }),
  setPrevMonthBilling: (payload) => ({
    type: actions.SET_PREV_MONTH_BILLING,
    payload,
  }),
  updateTrackingTime: (payload) => ({
    type: actions.UPDATE_TRACKING_TIME,
    payload,
  }),
  updateTrackingTimeForPrevMonth: (payload) => ({
    type: actions.UPDATE_TRACKING_TIME_PREV,
    payload,
  }),
  fetchBilling: () => async (dispatch, getState, api) => {
    try {
      dispatch(actions.setLoadingBilling(true));
      const response = await api.get('billings');
      const data = response.data;
      const state = getState();

      if (data) {
        const stat = {
          tasks: {
            checked: 0,
            finished: 0,
          },
          finance: {
            balance: 0,
          },
        };

        let currentUserTasks = data.tasks.filter((task) => taskTypes[task.type].role === localStorage.getItem('selected_role'));

        if (state.user.multipleRoles.length > 1) {
          currentUserTasks = data.tasks;
        }

        currentUserTasks.forEach((task) => {
          stat.tasks.checked += task.checked_at ? 1 : 0;
          stat.tasks.finished += 1;
          stat.finance.balance += task.checked_at ? parseFloat(task.price) : 0;
        });

        dispatch(actions.setBilling({
          invoices: data.invoices,
          tasks: currentUserTasks,
          stat,
        }));
      }
    } catch {
      // todo show notification
    } finally {
      dispatch(actions.setLoadingBilling(false));
    }
  },
  fetchPrevMonthBilling: () => async (dispatch, getState, api) => {
    try {
      dispatch(actions.setLoadingBilling(true));
      const response = await api.get('tasks-to-pay');
      const data = response.data;
      const state = getState();

      if (data) {
        let prevMonthTasks = data.tasks.filter((task) => taskTypes[task.type].role === localStorage.getItem('selected_role'));

        if (state.user.multipleRoles.length > 1) {
          prevMonthTasks = data.tasks;
        }

        dispatch(actions.setPrevMonthBilling({
          prevMonthTasks,
        }));
      }
    } catch {
      // todo show notification
    } finally {
      dispatch(actions.setLoadingBilling(false));
    }
  },
};

export default actions;
