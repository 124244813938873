export const getClassesForDeadline = (deadline) => {
  let cls = 'btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent';

  if (deadline) {
    const diff = (new Date(deadline) - new Date()) / (1000 * 60 * 60 * 24);

    if (diff <= 5 && diff > 0) {
      cls += ' btn-label-warning';
    } else if (diff <= 0) {
      cls += ' btn-label-danger';
    }
  }

  return cls;
};

export const getBadgeVariantForDeadline = (deadline) => {
  let variant = 'secondary';

  if (deadline) {
    const diff = (new Date(deadline) - new Date()) / (1000 * 60 * 60 * 24);

    if (diff <= 5 && diff > 0) {
      variant = 'warning';
    } else if (diff <= 0) {
      variant = 'danger';
    }
  }

  return variant;
};
