import actions from './actions';



const initialState = {
  loading: false,
  tagGroups: [],
  tagGroup: {},
};

export const TagSystemGroupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.SET_TAG_GROUPS: {
      return {
        ...state,
        tagGroups: action.payload,
      };
    }
    case actions.SET_TAG_GROUP: {
      return {
        ...state,
        tagGroup: action.payload,
      };
    }
    case actions.ADD_GROUP: {
      return {
        ...state,
        tagGroups: [ action.payload, ...state.tagGroups ],
      };
    }
    case actions.ADD_TAG_TO_GROUP: {
      return {
        ...state,
        tagGroup: {
          ...state.tagGroup,
          children: [ action.payload.tag, ...(state.tagGroup?.children || []) ],
        },
        tagGroups: state.tagGroups.map((group) => {
          if (group.id === action.payload.group_id) {
            return {
              ...group,
              children: [ action.payload.tag, ...(group?.children || []) ],
            };
          }
          return group;
        }),
      };
    }
    case actions.UPDATE_GROUP: {
      return {
        ...state,
        tagGroups: state.tagGroups.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              ...action.payload,
            };
          }
          return group;
        }),
      };
    }
    case actions.DELETE_GROUP: {
      return {
        ...state,
        tagGroups: state.tagGroups.filter((group) => Number(group.id) !== Number(action.payload)),
      };
    }
    case actions.REMOVE_TAG_FROM_GROUP: {
      if (action.payload?.groupId !== state.tagGroup?.id) {
        return state;
      }

      const tagGroup = {
        ...state.tagGroup,
        children: (state.tagGroup?.children || []).filter((item) => item.id !== action.payload.tagId),
      };

      const newState = {
        ...state,
        tagGroup,
        tagGroups: state.tagGroups.map((group) => {
          if (group.id === tagGroup.id) {
            return tagGroup;
          }
          return group;
        }),
      };

      return newState;
    }
    default: {
      return state;
    }
  }
};
