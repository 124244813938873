import { toAbsoluteUrl } from '../../utils/gettersUrl';
import defLang from '../../../langs/list/ru';
import setLanguage from './setLanguage';
import { flatten } from './utils';



export const DEFAULT_LANG = 'ru';

const defaultLang = {
  ...defLang,
  ...flatten(defLang),
};

const actions = {
  EDIT_LANG: Symbol('EDIT_LANG'),
};

/**
 * Create an action for redux that sets the language
 *
 * @param {string} newLang
 * @param {{}} dataLang
 * @returns {{dataLang: {}, newLang: string, type: symbol}}
 */
export const setLang = (newLang, dataLang = defaultLang) => ({
  type: actions.EDIT_LANG,
  newLang,
  dataLang,
});

/**
 * List languages
 *
 * @type {{flag: string, name: string, lang: string}[]}
 */
export const languages = [
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/226-united-states.svg'),
  },
  {
    lang: 'ru',
    name: 'Русский',
    flag: toAbsoluteUrl('/media/flags/248-russia.svg'),
  },
];

/**
 * Combines the default language and used to close all the missing values from the default
 *
 * @param {any} def
 * @param {any} lang
 * @returns {{}}
 */
const build = (def, lang) => {
  if (!lang || lang === def) {
    return def;
  }
  lang = {
    ...lang,
    ...flatten(lang),
  };
  const data = {};

  // eslint-disable-next-line fp/no-loops,no-restricted-syntax
  for (const key in def) {
    if (def.hasOwnProperty(key)) {
      if (!lang[key]) {
        data[key] = def[key];
      } else if (typeof def[key] === 'string' || def[key] instanceof String) {
        data[key] = lang[key] || def[key];
      } else {
        data[key] = build(def[key], lang[key]);
      }
    }
  }

  return data;
};

/**
 * Initializes the store for the language, takes the selected from localStorage
 *
 * @returns {{cur: string, lang: {}}}
 */
const initStore = () => {
  const cur = localStorage.getItem('lang') || DEFAULT_LANG;

  if (cur !== DEFAULT_LANG) {
    setLanguage(cur).then();
  }
  return {
    cur: DEFAULT_LANG,
    lang: build(defaultLang, defaultLang),
  };
};

/** languageReducer func
 *
 * @param {any} store
 * @param {any} action
 * @returns {{cur: string, lang: {}}}
 */
const languageReducer = (store = initStore(), action) => {
  switch (action.type) {
    case actions.EDIT_LANG: {
      localStorage.setItem('lang', action.newLang);
      return {
        ...store,
        cur: action.newLang,
        lang: build(defaultLang, action.dataLang),
      };
    }
    default: {
      return store;
    }
  }
};

export default languageReducer;
