import React from 'react';
import { connect } from 'react-redux';
import { Form } from 'react-bootstrap';
import _ from 'lodash';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import Collapse from 'react-bootstrap/Collapse';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Modal from '../../widgets/Modal';
import InputSelector from '../../widgets/InputSelector';
import Preload from '../../widgets/Preload';
import { parseToPars } from '../../utils/parseUrlParams';
import { saveTag, deleteTag } from '../../requests/tags';
import urlPageProjectsList from '../../urls/urlPageProjectsList';
import { tagValidator } from '../../utils/validators';
import toSlug from './toSlug';



const propTypes = {
  lang: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  saveTag: PropTypes.func.isRequired,
  deleteTag: PropTypes.func.isRequired,
};

/**
 * Component for creating, editing, deletion and viewing lists of tags
 */

class Tags extends React.Component {
  static defaultProps = {
    tagsFromStore: [],
  };

  state = {
    createTag: {
      show: false,
      isEdit: false,
      id: null,
      name: '',
      slug: '',
    },
    removeTag: null,
    LOAD_TAGS: false,
    selectTags: [],
    selectGroupTags: [],
  };

  render () {
    const { createTag } = this.state;
    const { lang } = this.props;

    const tagsFromStore = _.sortBy(this.props.tags, [ (tag) => tag.name.toLowerCase() ]);
    const groupsTags = _(tagsFromStore)
      .sortBy((tag) => tag.name)
      .groupBy((tag) => tag.name[0].toUpperCase())
      .value();

    return (
      <>
        <SubHeader
          title={lang['TAGS.CONTROL']}
        />
        <div className="row">
          <div className="col-xl-12">
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title font-weight-bold">
                    {lang['MENU.TAGS']}
                  </h3>
                </div>
                <div className="kt-portlet__head-toolbar">
                  <div className="kt-portlet__head-actions">
                    <button
                      className="btn btn-clean btn-sm btn-icon btn-icon-md"
                      onClick={() => {
                        this.setState({
                          createTag: {
                            show: true,
                            name: '',
                            slug: '',
                          },
                        });
                      }}
                    >
                      <i className="flaticon2-plus" />
                    </button>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__body">
                {this.state.LOAD_TAGS ? (
                  <Preload />
                ) : (
                  <>
                    <Form.Group className="row kt-mb-10">
                      <div className="col-10">
                        <Form.Control
                          as={InputSelector}
                          variant="cloud"
                          list={tagsFromStore.map((tag) => ({ value: tag.id, title: tag.name, slug: tag.slug }))}
                          cur={this.state.selectTags}
                          add={(tag) => {
                            this.setState({
                              selectTags: [
                                ...this.state.selectTags,
                                tag,
                              ],
                            });
                          }}
                          remove={(tagToRemove) => {
                            this.setState({
                              selectTags: this.state.selectTags.filter((tag) => tag !== tagToRemove),
                            });
                          }}
                        />
                      </div>
                      <div className="col-2">
                        <Button
                          variant="info"
                          block
                          disabled={this.state.selectTags.length === 0}
                          onClick={() => {
                            this.props.history.push(`${urlPageProjectsList({ type: 'coloring' })}${
                              parseToPars({
                                tags_id: this.state.selectTags.map((id) => tagsFromStore.find((item) => item.id === id).id),
                                tags: this.state.selectTags.map((id) => tagsFromStore.find((item) => item.id === id).slug),
                              })}`);
                          }}
                        >
                          {lang['TAGS.FIND_PROJECT']}
                        </Button>
                      </div>
                    </Form.Group>
                    <hr className='w-100' />
                    <ul className="kt-nav w-100">
                      <div className="row">
                        {Object.keys(groupsTags).sort().map((group) => {
                          const open = this.state.selectGroupTags.findIndex((item) => item === group) !== -1;

                          return (
                            <div className="col-md-4" key={group}>
                              <li className={`kt-nav__item${open ? ' kt-nav__item--active' : ''}`}>
                                <a
                                  role="link"
                                  tabIndex={0}
                                  onKeyDown={() => {}}
                                  className="kt-nav__link collapsed font-weight-bold"
                                  onClick={() => {
                                    this.setState({
                                      selectGroupTags: open
                                        ? this.state.selectGroupTags.filter((item) => item !== group)
                                        : [ ...this.state.selectGroupTags, group ],
                                    });
                                  }}
                                >{group}
                                </a>
                                <Collapse
                                  in={open}
                                >
                                  <ul className="kt-nav__sub">
                                    {groupsTags[group].map((tag) => {
                                      const isSelect = this.state.selectTags.findIndex((item) => item === tag.id) !== -1;

                                      return (
                                        <li
                                          key={tag.id}
                                          className="kt-nav__item kt-nav__item--no-active"
                                        >
                                          <a className="kt-nav__link">
                                            <span
                                              className="kt-nav__link-bullet kt-nav__link-bullet--line"
                                            >
                                              <span />
                                            </span>
                                            <span className="kt-nav__link-text">
                                              {tag.name}
                                            </span>
                                            <span
                                              className="kt-nav__link-badge"
                                            >
                                              <i
                                                role="link"
                                                tabIndex={0}
                                                onKeyDown={() => {}}
                                                className="fa fa-edit hover kt-mr-5"
                                                onClick={() => {
                                                  this.setState({
                                                    createTag: {
                                                      show: true,
                                                      isEdit: true,
                                                      id: null,
                                                      name: '',
                                                      slug: '',
                                                      ...tag,
                                                    },
                                                  });
                                                }}
                                              />
                                              <i
                                                role="link"
                                                tabIndex={0}
                                                onKeyDown={() => {}}
                                                className="fa fa-window-close hover kt-mr-5"
                                                onClick={() => {
                                                  this.setState({
                                                    removeTag: tag.id,
                                                  });
                                                }}
                                              />
                                              <i
                                                role="link"
                                                tabIndex={0}
                                                onKeyDown={() => {}}
                                                className={`fa fa-check-square hover ${isSelect ? 'active' : ''}`}
                                                onClick={() => {
                                                  if (isSelect) {
                                                    this.setState({
                                                      selectTags: this.state.selectTags.filter((item) => item !== tag.id),
                                                    });
                                                  } else {
                                                    this.setState({
                                                      selectTags: [ ...this.state.selectTags, tag.id ],
                                                    });
                                                  }
                                                }}
                                              />
                                            </span>
                                          </a>
                                        </li>
                                      );
                                    })}
                                  </ul>
                                </Collapse>
                              </li>
                            </div>
                          );
                        })}
                      </div>
                    </ul>
                  </>)}
              </div>
            </div>
          </div>
        </div>
        <Modal
          as={'form'}
          onSubmit={this.onSubmitEditModal}
          title={createTag.isEdit ? lang['TAGS.EDIT'] : lang['TAGS.NEW']}
          show={createTag.show && !this.state.removeTag}
          close={this.onCancelEditModal}
          footer={
            <>
              <Button
                as={'span'}
                variant='secondary'
                onClick={this.onCancelEditModal}
              >{lang['GLOBAL.CANCEL']}</Button>
              <button
                className="btn btn-success"
                type='submit'
                disabled={this.state.createTag.slug.length <= 0}
              >{createTag.isEdit ? lang['GLOBAL.SAVE'] : lang['TAGS.ADD']}</button>
            </>
          }
        >
          <Form.Group>
            <Form.Label>{lang['TAGS.TITLE']}</Form.Label>
            <Form.Control
              type="text" value={createTag.name} onChange={(event) => {
                const value = tagValidator(event.target.value);

                this.setState({
                  createTag: {
                    ...createTag,
                    name: value,
                    slug: toSlug(value),
                  },
                });
              }}
            />
          </Form.Group>
        </Modal>
        <Modal
          title={`${lang['TAGS.YOU_SURE_REMOVE_TAG']}?`}
          show={!!this.state.removeTag}
          close={this.onCancelDeleteTagModal}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={this.onCancelDeleteTagModal}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-danger"
                onClick={this.onSubmitDeleteTagModal}
              >{lang['GLOBAL.REMOVE']}</button>
            </>
          }
        />
      </>
    );
  }

  onSubmitEditModal = (event) => {
    event.preventDefault();
    if (this.state.createTag.slug.length > 0) {
      this.saveTag();
    }
  };

  onCancelEditModal = () => {
    this.setState({
      createTag: {
        show: false,
        isEdit: false,
        id: null,
        name: '',
        slug: '',
      },
    });
  };

  onCancelDeleteTagModal = () => {
    this.setState({
      removeTag: null,
    });
  };

  onSubmitDeleteTagModal = async () => {
    await this.deleteTag(this.state.removeTag);
    this.setState({
      removeTag: null,
      createTag: {
        show: false,
        isEdit: false,
        id: null,
        name: '',
        slug: '',
      },
    });
  };

  saveTag = async () => {
    this.setState({
      createTag: {
        show: false,
        isEdit: false,
        id: null,
        name: '',
        slug: '',
      },
      LOAD_TAGS: true,
    });
    const { createTag } = this.state;

    await this.props.saveTag(createTag);

    this.setState({
      LOAD_TAGS: false,
    });
  };

  deleteTag = async (id) => {
    this.setState({
      LOAD_TAGS: true,
    });

    await this.props.deleteTag(id);

    this.setState({
      LOAD_TAGS: false,
    });
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    tags: store.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveTag: bindActionCreators(saveTag, dispatch),
    deleteTag: bindActionCreators(deleteTag, dispatch),
  };
};

Tags.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(Tags);
