import React from 'react';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { Form, Spinner } from 'react-bootstrap';
import { object, string } from 'yup';
import Modal from '../../../../widgets/Modal';
import { latinAndSymbolsRegExp } from '../../../../utils/checker';



const CreateChildTag = ({
  parent,
  isActive,
  loading,
  onSubmit,
  onCancel,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const validationSchema = object().shape({
    name: string().matches(latinAndSymbolsRegExp).trim().min(1).max(100).required(),
  });

  const initialValues = { name: '' };

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const handleClose = () => {
    reset(initialValues);
    onCancel();
  };

  const onCreateTag = (data) => {
    const childGroup = parent.getDependentGroup();

    data.parent = [ childGroup?.id ];
    onSubmit(data, () => {
      onCancel();
      reset(initialValues);
    });
  };

  const footer = (<>
    <Button variant='secondary' onClick={handleClose}>{lang['GLOBAL.CANCEL']}</Button>
    <Button variant='success' onClick={handleSubmit(onCreateTag)}>
      {loading && <Spinner animation="border" style={{ height: '12px', width: '12px' }} />}
      {' '}
      {lang['GLOBAL.SAVE']}
    </Button>
  </>);

  return (
    <Modal
      title={lang['TAG_SYSTEM.TAGS.ADD_CHILD_TAG']}
      show={isActive}
      bodyStyle={{ overflow: 'unset', textAlign: 'left' }}
      close={onCancel}
      footer={footer}
    >
      <div className='d-flex flex-column'>
        <Form.Group>
          <Form.Label>
            {lang['GLOBAL.NAME']}
            <span style={{ color: 'red' }}>*</span>
          </Form.Label>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Form.Control
                {...field}
                type="text"
                isInvalid={!!errors.name}
              />
            )}
          />
          <Form.Control.Feedback type="invalid">
            {lang['TAG_SYSTEM.ERRORS.NAME_ERROR']}
          </Form.Control.Feedback>
        </Form.Group>
      </div>
    </Modal>
  );
};

CreateChildTag.propTypes = {
  parent: PropTypes.object,
  isActive: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateChildTag;
