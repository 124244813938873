/**
 * groups array elements
 *
 * @param {T[]}value
 * @param {Function} iteratee
 * @returns {{key: T[]}}
 */
export const groupBy = (value, iteratee) => {
  const object = {};

  value.forEach((v) => {
    const key = iteratee(v);

    if (!object[key]) {
      object[key] = [];
    }
    object[key].push(v);
  });
  return object;
};

/**
 * transforms object field names
 *
 * Example:
 *
 * @example {"a":{"b": "any1"},"c":"any2"} => {"a.b": "any1","c": "any2"}
 *
 * @param {{}}data
 * @param {string} fullKey - value that needs to be added at the beginning of the keys
 * @returns {{}}
 */
export const flatten = (data, fullKey = '') => {
  const oldKeys = Object.keys(data);

  let obj = {};

  // eslint-disable-next-line array-callback-return
  oldKeys.map((key) => {
    if (typeof data[key] === 'object') {
      obj = { ...obj, ...flatten(data[key], fullKey ? (`${fullKey}.${key}`) : key) };
    } else if (key === '___') {
      obj[fullKey] = data[key];
    } else {
      obj[fullKey ? (`${fullKey}.${key}`) : key] = data[key];
    }
  });

  return obj;
};

/**
 * opposite action for "flatten"
 *
 * @param {object} data
 * @returns {*}
 */
export const groups = (data) => {
  const gr = groupBy(Object.keys(data), (item) => item.split('.')[0]);

  Object.keys(gr).forEach((key) => {
    const oldKeys = gr[key];

    gr[key] = {};

    oldKeys.forEach((k) => {
      const newName = k.slice(k.indexOf('.') + 1);

      if (newName === k) {
        gr[key].___ = data[k];
      } else {
        gr[key][newName] = data[k];
      }
    });

    const keys = Object.keys(gr[key]);

    if (keys.length === 1 && keys[0] === '___') {
      gr[key] = gr[key].___;
    } else {
      gr[key] = groups(gr[key]);
    }
  });

  return gr;
};
