import actions from './actions';



const initialState = {};

export const configReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CONFIG: {
      return {
        ...action.payload,
      };
    }

    default:
      return state;
  }
};
