import React from 'react';

/**
 * Component for load animation
 *
 * @returns {*}
 */
const Preload = () => {
  return (
    <div
      className="kt-content  kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor d-flex flex-column justify-content-center align-items-center h-100"
    >
      <img src="/media/logo.png" alt="Bee logo" style={{ width: 150, height: 150 }} />
      <svg className="spinner kt-ml-0 kt-mt-20" viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

export default Preload;
