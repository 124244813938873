import React from 'react';
import { Link } from 'react-router-dom';
import urlPageTagSystemGroups from '../../../urls/urlPageTagSystemGroups';



const getGroupsLink = (lang) => {
  return (
    <Link
      key={'group-link'}
      className='font-weight-semibold'
      to={urlPageTagSystemGroups()}
    >
      {lang['TAG_SYSTEM.GROUPS.TITLE']}
    </Link>
  );
};

export default getGroupsLink;
