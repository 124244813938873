import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { orderBy } from 'lodash';
import queryString from 'query-string';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import tagGroupActions from '../../../store/tagSystem/groups/actions';
import tagActions from '../../../store/tagSystem/tags/actions';
import getGroupsLink from '../helpers/GetGroupsLink';
import { Loader } from '../../../components/Loader';
import { useToggle } from '../../../hooks/useToggle';
import usePagination from '../../../hooks/usePagination';
import useQueryParams from '../../../hooks/useQueryParams';
import { transformChildToRequest, transformToRequest } from '../api/transformers/tag';
import TagEntity from '../model/TagEntity';
import { entityTypes } from '../config/config';
import getTagLink from '../helpers/GetTagLink';
import TagsTableItem from './TagsTableItem';
import TagsTableHeader from './TagsTableHeader';
import DeleteTag from './modals/DeleteTag';
import CreateTag from './modals/CreateTag';
import CreateChildTag from './modals/CreateChildTag';



const Tags = () => {
  const { tagId, groupId } = useParams();
  const dispatch = useDispatch();
  const updateQueryParams = useQueryParams();
  const location = useLocation();
  const currentQuery = queryString.parse(location.search);

  const [ confirmModal, toggleConfirmModal ] = useToggle(false);
  const [ openCreateModal, toggleCreateModal ] = useToggle(false);
  const [ openCreateChildModal, toggleCreateChildModal ] = useToggle(false);
  const [ curTag, setCurTag ] = useState(null);
  const [ currentItems, setCurrentItems ] = useState([]);
  const [ filter, setFilter ] = useState({
    page: 1,
    limit: 20,
    search: '',
    orderBy: '',
    sortedBy: '',
  });

  const { lang, tagGroup, tagGroups, loading, tags, tagsLoading } = useSelector(({ language, tagSystemGroups, tagSystemTags }) => ({
    lang: language.lang,
    tags: tagSystemTags.tags,
    tagGroup: tagSystemGroups.tagGroup,
    tagGroups: tagSystemGroups.tagGroups,
    loading: tagSystemGroups.loading,
    tagsLoading: tagSystemTags.loading,
  }), shallowEqual);
  const {
    currentPage,
    itemsPerPage,
    goToPage,
    PaginationComponent,
  } = usePagination({ totalItems: currentItems?.length ?? 0, itemsPerPage: filter.limit });

  useEffect(() => {
    dispatch(tagGroupActions.getGroups());
    dispatch(tagActions.getTags());

    return () => {
      dispatch(tagGroupActions.setGroup({}));
    };
  }, []);

  useEffect(() => {
    dispatch(tagGroupActions.getGroup(tagId || groupId));
  }, [ groupId, tagId ]);

  const applyFilter = (filter) => {
    let items = tagGroup?.children || [];

    if (filter.search) {
      items = items.filter((item) => item.name.toLowerCase().includes(filter.search.toLowerCase()));
    }

    if (filter?.orderBy && filter?.sortedBy) {
      items = orderBy(items, [ filter.orderBy ], [ filter.sortedBy ]);
    }

    setCurrentItems(items);
  };

  useEffect(() => {
    setFilter((prevState) => {
      const newState = { ...prevState };

      if (prevState.page !== currentPage) {
        newState.page = currentPage;
      }

      if (prevState.limit !== itemsPerPage) {
        newState.limit = itemsPerPage;
      }

      applyFilter(newState);

      return newState;
    });
  }, [ currentPage, itemsPerPage ]);

  useEffect(() => {
    if (!loading || !tagsLoading) {
      const newFilter = {
        page: 1,
        limit: 20,
        search: '',
        orderBy: '',
        sortedBy: '',
      };

      Object.keys(newFilter).forEach((key) => {
        if (currentQuery[key] !== undefined && currentQuery[key] !== '') {
          let value = currentQuery[key];

          if (key === 'page' || key === 'limit') {
            value = Number(currentQuery[key]);
          }

          newFilter[key] = value;
        }
      });

      setFilter(newFilter);
      applyFilter(newFilter);
    }
  }, [ loading, tagsLoading ]);

  const onFilterChange = (field, value) => {
    setFilter((prevState) => {
      let newState = {
        ...prevState,
        [field]: value,
      };

      if (field === 'search') {
        newState.page = 1;
        goToPage(1);
      }

      if (field === 'orderBy') {
        const newSort = {};

        if (!filter.sortedBy) {
          newSort.sortedBy = 'desc';
          newSort.orderBy = value;
        } else if (filter.sortedBy === 'desc') {
          newSort.sortedBy = 'asc';
          newSort.orderBy = value;
        } else if (filter.sortedBy === 'asc') {
          newSort.sortedBy = '';
          newSort.orderBy = '';
        }

        newState = { ...newState, ...newSort };
      }

      updateQueryParams(newState);
      applyFilter(newState);

      return newState;
    });
  };

  const currentEntity = new TagEntity(tagGroup, tagGroups);

  const handleDelete = (cb) => {
    dispatch(tagActions.deleteTag(Number(curTag), tagId ? Number(tagId) : (Number(groupId) ?? null)))
      .then(() => {
        if (typeof cb === 'function') {
          cb();
        }
      });
  };

  const onDeleteTag = (id) => {
    setCurTag(id);
    toggleConfirmModal();
  };

  const onCreateChildTag = (id) => {
    setCurTag(id);
    toggleCreateChildModal();
  };

  const handelSave = (data, cb) => {
    if (tagId && groupId) {
      data.parent = {
        value: Number(groupId),
      };
    }
    const body = transformToRequest(data, tagGroup);
    const action = data.id ? 'updateTag' : 'createTag';

    dispatch(tagActions[action](body, tagGroup.id))
      .then(() => {
        if (typeof cb === 'function') {
          cb();
        }
      });
  };

  const handelChildSave = (data, cb) => {
    const body = transformChildToRequest(data, curTag);
    const action = data.id ? 'updateTag' : 'createTag';

    dispatch(tagActions[action](body, tagGroup.id, curTag))
      .then(() => {
        if (typeof cb === 'function') {
          cb();
        }
      });
  };

  const subtitle = () => {
    const group = tagGroup?.parents?.find((parent) => parent.type === entityTypes.group);

    return [
      (<span key={'separator-1'} className="subtitle-separator" />),
      tagId ? getTagLink(group?.name, group?.id) : <span key={`group-${tagGroup?.name}`}>{tagGroup?.name}</span>,
      tagId ? (<span key={'separator-2'} className="subtitle-separator" />) : '',
      tagId ? <span key={`group-${tagGroup?.name}`}>{tagGroup?.name}</span> : '',
    ];
  };

  return (
    <>
      <SubHeader
        title={getGroupsLink(lang)}
        subtitle={subtitle()}
      />
      <div className='d-flex justify-content-between my-2'>
        <div className='d-flex mr-auto'>
          <input
            className="form-control mr-2 w-75"
            type="text"
            value={filter.search}
            placeholder={lang['GLOBAL.SEARCH']}
            onChange={(event) => onFilterChange('search', event.target?.value?.trim())}
          />
          <Button
            variant="success"
            size="sm"
            className="text-nowrap kt-margin"
            onClick={toggleCreateModal}
          >
            {lang['TAG_SYSTEM.TAGS.ADD_TAG']}
          </Button>
        </div>
        <PaginationComponent />
      </div>
      <div className='kt-portlet mw-100 overflow-auto table-wrapper custom-table position-relative'>
        {loading || !tagGroup?.id || tagsLoading && <Loader centered showImg={false} />}
        {!!tags?.length && (
          <Table
            striped
            className='vertical-align-middle text-center kt-font-md'
            size="sm"
          >
            <TagsTableHeader
              sort={{
                orderBy: filter.orderBy,
                sortedBy: filter.sortedBy,
              }}
              currentEntity={currentEntity}
              handleSort={onFilterChange}
            />
            <tbody>
              {currentItems.slice((filter.page - 1) * filter.limit, filter.page * filter.limit).map((tag) => {
                const tagWithChildren = tags.find((tagItem) => tagItem.id === tag.id);

                if (!tagWithChildren) {
                  return null;
                }

                return (
                  <TagsTableItem
                    key={`${tagWithChildren.id}_${tagWithChildren.name}`}
                    tag={tagWithChildren}
                    tags={tags}
                    onDelete={onDeleteTag}
                    onCreate={onCreateChildTag}
                    currentEntity={currentEntity}
                  />
                );
              })}
            </tbody>
          </Table>
        )}
      </div>
      <CreateTag
        parent={currentEntity}
        isActive={openCreateModal}
        onCancel={toggleCreateModal}
        onSubmit={handelSave}
        loading={tagsLoading}
      />
      <CreateChildTag
        parent={currentEntity}
        isActive={openCreateChildModal}
        onCancel={toggleCreateChildModal}
        onSubmit={handelChildSave}
        loading={tagsLoading}
      />
      <DeleteTag
        show={confirmModal}
        onClose={toggleConfirmModal}
        onDelete={handleDelete}
        loading={tagsLoading}
      />
    </>
  );
};

export default Tags;
