import React, { useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';



const TasksFilter = ({ applyFilter, resetFilter }) => {
  const [ querySearch, setQuerySearch ] = useState('');
  const lang = useSelector((state) => state.language.lang);

  const handleInputChange = (event) => {
    setQuerySearch(event.target.value);
  };

  const onFilterReset = () => {
    setQuerySearch('');
    resetFilter();
  };

  const onFilterApply = () => {
    applyFilter(querySearch);
  };

  return (
    <InputGroup>
      <FormControl
        type="text"
        onChange={handleInputChange}
        placeholder={lang['GLOBAL.PROJECT_FILTER']}
        value={querySearch}
      />
      <Button style={{ borderRadius: 0 }} variant="primary" onClick={onFilterApply}>{lang['GLOBAL.APPLY']}</Button>
      <Button style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }} variant="outline-dark" onClick={onFilterReset}>{lang['GLOBAL.RESET']}</Button>
    </InputGroup>
  );
};

TasksFilter.propTypes = {
  applyFilter: PropTypes.func.isRequired,
  resetFilter: PropTypes.func.isRequired,
};

export default TasksFilter;
