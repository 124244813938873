import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Preload from '../../widgets/Preload';
import WidgetsPanel from './components/WidgetsPanel';



const Dashboard = () => {
  const { lang, user } = useSelector(({ language, user }) => ({
    lang: language.lang,
    user: user.user,
  }), shallowEqual);

  return (
    <>
      <SubHeader
        title={lang['DASHBOARD.TITLE']}
      />
      {!user.id ? <Preload /> : <WidgetsPanel />}
    </>
  );
};

export default Dashboard;
