/**
 * create a separator
 *
 * @yields {string}
 * @returns {Generator<string, void, ?>}
 */

const split = function *() {
  yield '?';
  // eslint-disable-next-line fp/no-loops
  while (true) {
    yield '&';
  }
};

/**
 * Converts a string to a value
 *
 * @param {string} val
 * @returns {string|boolean|number}
 */
const parseVal = (val) => {
  if (val === 'true') {
    return true;
  }

  if (val === 'false') {
    return false;
  }

  if (!isNaN(Number(val))) {
    return Number(val);
  }

  return val.replace('%20', ' ');
};

/**
 * Convert object to URL parameters
 *
 * @param {object} obj
 * @returns {string}
 */
export const parseToPars = (obj) => {
  let pars = '';

  const _split = split();

  // eslint-disable-next-line no-restricted-syntax,fp/no-loops
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (obj[key] || obj[key] === 0 || obj[key] === false)) {
      if (Array.isArray(obj[key])) {
        // eslint-disable-next-line no-loop-func
        obj[key].forEach((val, index) => {
          if (typeof val === 'boolean') {
            val = Number(val);
          }
          pars += `${_split.next().value + key}[${index}]=${encodeURI(val)}`;
        });
      } else if (typeof obj[key] === 'object') {
        // eslint-disable-next-line no-restricted-syntax,fp/no-loops
        for (const index in obj[key]) {
          if (obj[key].hasOwnProperty(index) && (obj[key][index] || obj[key][index] === 0 || obj[key][index] === false)) {
            if (typeof obj[key][index] === 'boolean') {
              obj[key][index] = Number(obj[key][index]);
            }
            pars += `${_split.next().value + key}[${index}]=${encodeURI(obj[key][index])}`;
          }
        }
      } else {
        if (typeof obj[key] === 'boolean') {
          obj[key] = Number(obj[key]);
        }
        pars += `${_split.next().value + key}=${encodeURI(obj[key])}`;
      }
    }
  }

  return pars;
};

/**
 * Convert URL parameters to object
 *
 * @param {string} pars
 * @returns {object}
 */
export const parseFromPars = (pars) => {
  if (!pars) {
    return {};
  }

  pars = decodeURI(pars);

  const _pars = (pars[0] === '?' ? pars.slice(1) : pars).split('&');

  const obj = {};

  _pars.forEach((par) => {
    const val = [];

    const ind = par.indexOf('=');

    val.push(par.slice(0, ind));
    val.push(par.slice(ind + 1));

    const key = val[0].replace(']', '').split('[');

    if (key.length > 1) {
      if (!obj[key[0]]) {
        if (isNaN(Number(key[1]))) {
          obj[key[0]] = {};
        } else {
          obj[key[0]] = [];
        }
      }

      obj[key[0]][key[1]] = parseVal(val[1]);
    } else {
      obj[val[0]] = parseVal(val[1]);
    }
  });

  return obj;
};
