import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';
import urlPageError from '../../urls/urlPageError';
import urlPageError403 from '../../urls/urlPageError403';
import urlPageError404 from '../../urls/urlPageError404';
import Error from './ErrorPage';

/**
 * Error page routing
 *
 * @returns {*}
 */
export default function ErrorsPage () {
  const lang = useSelector((store) => store.language.lang);

  return (
    <Switch>
      <Route
        exact path={urlPageError()} component={() => {
          return <Error code={404} title={lang.ERROR} description={lang['ERROR.404']} />;
        }}
      />
      <Route
        exact path={urlPageError404()} component={() => {
          return <Error code={404} title={lang.ERROR} description={lang['ERROR.404']} />;
        }}
      />
      <Route
        exact path={urlPageError403()} component={() => {
          return <Error code={403} title={lang.ERROR} description={lang['ERROR.403']} />;
        }}
      />
    </Switch>
  );
}
