import { orderBy } from 'lodash';
import { formatStringToDate } from '../utils/formats';
import { filterTasksAction, setTasksAction, updateTaskAction } from '../store/tasks';
import { prevMonthFirstDate, prevMonthLastDate } from '../../const/date';
import axiosApiInstance from './utils/api';



export const nameTasks = 'tasks';


export const getTasksForExecutor = async (idExecutor) => {
  const URL = `tasks/list/all?executor=${idExecutor}`;

  try {
    const response = await axiosApiInstance.get(URL);

    if (!response?.data) {
      return [];
    }

    return orderBy(response?.data?.tasks, (task) => task.id);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error?.response?.data);

    return { tasks: [] };
  }
};

// eslint-disable-next-line consistent-return
export const getTasksForBonuses = async (userId, tasksReadyToPay) => {
  const URL = `tasks?executor=${userId}&from=${prevMonthFirstDate}&to=${prevMonthLastDate}&type=payment`;

  try {
    const response = await axiosApiInstance.get(URL, {});

    if (response) {
      const data = response.data;

      data.tasks = orderBy(data.tasks, (task) => task.id);

      return data.tasks.filter((task) => (tasksReadyToPay.includes(task.id)));
    }
  } catch (error) {
    if (error.response) {
      // eslint-disable-next-line no-console
      console.error(error.response.data);
      return { tasks: [] };
    }
  }
};

export const getBatchTasksForBonuses = async (users) => {
  const URL = `tasks/batch/bonuses?from=${prevMonthFirstDate}&to=${prevMonthLastDate}&type=payment`;

  try {
    const response = await axiosApiInstance.post(URL, {
      users,
    });

    return response.data.map((tasks) => {
      const tempTasks = {};

      tempTasks.tasks = orderBy(tasks.tasks, (task) => task.id);

      let user = null;

      return tempTasks.tasks.filter((task) => {
        if (!user) {
          user = users.find((user) => user.user.id === task.executor_id);
        }

        if (user) {
          return user.tasks.includes(task.id);
        }

        return [];
      });
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }

};

export const getTaskById = async (id) => {
  const URL = `tasks/${id}`;

  try {
    const task = await axiosApiInstance.get(URL);

    task.data.task.files.forEach((file) => {
      file.prefix = file.prefix.replace('_', '.');
    });

    task.data.task.project_files = [ ...task.data.task.project.files ];

    return task.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
  return {};
};

/**
 * Gets list tasks by a list ids
 *
 * @param {Array} ids
 * @returns {Promise<*[]>}
 */
export const getTasksByIds = async (ids) => {
  try {
    return (await Promise.allSettled(ids.map((id) => getTaskById(id)))).map((res) => res.value.task);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    return [];
  }
};

// eslint-disable-next-line consistent-return
export const getTasks = (url, type, filteredParams = {}) => async (dispatch) => {
  try {
    const response =  await axiosApiInstance.get(url, {});

    if (response.data) {
      const tasks = response.data.tasks;

      tasks.forEach((task) => {
        task.deadline_to = formatStringToDate(task.deadline_to);
      });

      dispatch(setTasksAction(tasks, type));
      dispatch(filterTasksAction(filteredParams, type));

      return tasks;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

// eslint-disable-next-line consistent-return
export const getTasksTypeForExecutor = async (url) => {
  try {
    const response = await axiosApiInstance.get(url);

    if (response.data) {
      const msg = response.data;

      Object.values(msg.tasks).forEach((task) => {
        task.deadline_to = formatStringToDate(task.deadline_to);
      });
      return response.data;
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const setTasks = (tasks, type) => (dispatch) => {
  try {
    dispatch(setTasksAction(tasks, type));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const updateTask = (updatedTask, categoryId, tasksType) => (dispatch) => {
  try {
    dispatch(updateTaskAction(updatedTask, categoryId, tasksType));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const filterTasks = (params, tasksType) => (dispatch) => {
  try {
    dispatch(filterTasksAction(params, tasksType));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
