export const SET_TAGS = 'SET_TAGS';
export const ADD_TAG = 'ADD_TAG';
export const UPDATE_TAG = 'UPDATE_TAG';
export const DELETE_TAG = 'DELETE_TAG';

export const setTagsAction = (tags) => ({
  type: SET_TAGS,
  payload: tags,
});

export const addTagAction = (tag) => ({
  type: ADD_TAG,
  payload: tag,
});

export const updateTagAction = (tag) => ({
  type: UPDATE_TAG,
  payload: tag,
});

export const deleteTagAction = (id) => ({
  type: DELETE_TAG,
  payload: id,
});

const initialState = [];

export const tagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TAGS: {
      return [ ...action.payload ];
    }
    case ADD_TAG: {
      return [ ...state, action.payload ];
    }
    case UPDATE_TAG: {
      const index = state.findIndex((tag) => tag.id === action.payload.id);

      if (index === -1) {
        return state;
      }

      const tags = [ ...state ];

      tags[index] = {
        ...tags[index],
        name: action.payload.name,
        slug: action.payload.slug,
      };

      return tags;
    }
    case DELETE_TAG: {
      return state.filter((tag) => tag.id !== action.payload);
    }
    default: {
      return state;
    }
  }
};
