import React from 'react';
import { useSelector } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Modal from '../../widgets/Modal';
import { useGlobalModalContext } from '../GlobalModal';
import BillingHeaderTable from '../../pages/billing/BillingHeaderTable';
import BillingItemTable from '../../pages/billing/BillingItemTable';



/**
 * Component of billing modal item
 *
 * @returns {*}
 */
const BillingItemModal = () => {
  const lang = useSelector(({ language }) => language.lang);
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { data, invoiceDate, invoiceStatus } = modalProps || {};

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      close={handleModalToggle}
      full
      autoheight
      title={`${lang[invoiceStatus === 'unpaid' ?
        'BILLING.BILLING_READY_TO_PAY_ITEM_HEADER' :
        'BILLING.BILLING_PAYED_ITEM_HEADER']} ${invoiceDate.from} - ${invoiceDate.to}`}
      subtitle={invoiceStatus === 'unpaid' ? '' :
        `${lang['BILLING.BILLING_PAYED_ITEM_SUBHEADER']} - ${data.length}`}
      show
      bodyStyle={{ overflowY: 'auto' }}
      showFooter={false}
    >
      <div className='mw-100 overflow-auto'>
        <Table
          striped
          className='vertical-align-middle text-center kt-font-md'
          size="sm"
        >
          <BillingHeaderTable lang={lang} />
          <tbody>
            {data.map((task) => {
              return (
                <BillingItemTable
                  key={task.id}
                  task={task}
                />
              );
            })}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export default BillingItemModal;
