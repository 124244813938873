import React from 'react';
import { Route, Switch } from 'react-router-dom';
import urlPageTagSystem from '../../urls/urlPageTagSystem';
import urlPageTagSystemGroups from '../../urls/urlPageTagSystemGroups';
import urlPageTagSystemGroupsEdit from '../../urls/urlPageTagSystemGroupsEdit';
import urlPageTagSystemGroupsCreate from '../../urls/urlPageTagSystemGroupsCreate';
import urlPageTagSystemTemplates from '../../urls/urlPageTagSystemTemplates';
import urlPageTagSystemTags from '../../urls/urlPageTagSystemTags';
import urlPageTagSystemTagsChildren from '../../urls/urlPageTagSystemTagsChildren';
import urlPageTagSystemPictures from '../../urls/urlPageTagSystemPictures';
import urlPageTagSystemTemplatesEdit from '../../urls/urlPageTagSystemTemplatesEdit';
import urlPageTagSystemTemplatesCreate from '../../urls/urlPageTagSystemTemplatesCreate';
import Groups from './groups/Groups';
import AddEditGroup from './groups/AddEditGroup';
import Templates from './templates/Templates';
import AddEditTemplates from './templates/AddEditTemplate';
import Tags from './tags/Tags';
import TagPicture from './picture/TagPicture';



const TagSystem = () => {
  return (
    <Switch>
      <Route exact path={`${urlPageTagSystemPictures()}`} component={TagPicture} />
      <Route exact path={urlPageTagSystemGroupsEdit()} component={AddEditGroup} />
      <Route exact path={urlPageTagSystemGroupsCreate()} component={AddEditGroup} />
      <Route exact path={urlPageTagSystemTags()} component={Tags} />
      <Route exact path={urlPageTagSystemTagsChildren()} component={Tags} />
      <Route exact path={urlPageTagSystemGroups()} component={Groups} />
      <Route exact path={urlPageTagSystemTemplatesEdit()} component={AddEditTemplates} />
      <Route exact path={urlPageTagSystemTemplatesCreate()} component={AddEditTemplates} />
      <Route exact path={urlPageTagSystemTemplates()} component={Templates} />
      <Route exact path={urlPageTagSystem()} component={Templates} />
    </Switch>
  );
};

export default TagSystem;
