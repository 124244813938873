import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import axiosApiInstance from '../../../requests/utils/api';
import { parseFromPars } from '../../../utils/parseUrlParams';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const useExecutorsWidgets = () => {
  const location = useLocation();
  const [ executors, setExecutors ] = useState({
    artists: {
      choosingArtistTasksAmount: 0,
      idle: [],
    },
    colorists: {
      choosingColoristTasksAmount: 0,
      idle: [],
    },
  });
  const [ isExecutorsLoading, setIsExecutorsLoading ] = useState(false);
  const { userRoles, user } = useSelector(({ user }) => ({
    userRoles: user.roles,
    user: user.user,
  }), shallowEqual);

  useEffect(async () => {
    setIsExecutorsLoading(true);
    try {
      const queryParams = parseFromPars(location.search);
      const isAdminOrLeadEditor = checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ]);
      const isGroupDefined = typeof queryParams.group_id !== 'undefined';
      const isUserMatchGroup = user.groups.includes(queryParams.group_id);

      if (isGroupDefined && (isAdminOrLeadEditor || isUserMatchGroup)) {
        const [ tasksResponse, executorsResponse ] = await Promise.all([
          axiosApiInstance.get(`/dashboards/widgets/tasks?group_id=${queryParams.group_id}`),
          axiosApiInstance.get(`/dashboards/executors?group_id=${queryParams.group_id}`),
        ]);

        setExecutors({
          artists: {
            choosingArtistTasksAmount: tasksResponse.data?.artists,
            idle: executorsResponse.data.idle_executors?.artists,
          },
          colorists: {
            choosingColoristTasksAmount: tasksResponse.data?.colorists,
            idle: executorsResponse.data.idle_executors?.colorists,
          },
        });
      }
    } finally {
      setIsExecutorsLoading(false);
    }
  }, [ location ]);

  return [ executors, isExecutorsLoading ];
};

export {
  useExecutorsWidgets,
};
