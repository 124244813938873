import React from 'react';
import ReactDOM from 'react-dom';
import { init } from '@sentry/browser';
import Echo from 'laravel-echo';
import store, { persistor } from './app/store/store';
import App from './App';
import './index.scss';
import 'socicon/css/socicon.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import './_metronic/_assets/plugins/line-awesome/css/line-awesome.css';
import './_metronic/_assets/plugins/flaticon/flaticon.css';
import './_metronic/_assets/plugins/flaticon2/flaticon.css';
import './app/polyfill/promise';
import axiosApiInstance from './app/requests/utils/api';
import { API_URL } from './app/utils/gettersUrl';

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
// eslint-disable-next-line no-process-env
const { PUBLIC_URL } = process.env;

//Sentry key for DEV environment
const hostname = window.location.hostname;

if ([ 'localhost', 'bee-freelancer-dev.fabros-team.com', 'bee-freelancer-stage.fabros-team.com', 'bee-freelancer-dev.xfin.net', 'bee-freelancer-stage.xfin.net' ].includes(hostname)) {
  init({ dsn: 'https://6289b07bd4d84f5db9b64a746bc8dd9f@o273644.ingest.sentry.io/5685076' });
} else if ([ 'bee-freelancer.com', 'bee-freelancer.app' ].includes(hostname)) {
  //Sentry key for PROD environment
  init({ dsn: 'https://cf71bac603cb4beead35cf3ebd35c36d@sentry.io/2118817' });
}

window.Pusher = require('pusher-js');



window.Echo = new Echo({
  broadcaster: 'pusher',
  // eslint-disable-next-line no-process-env
  key: process.env.REACT_APP_MIX_PUSHER_APP_KEY,
  // eslint-disable-next-line no-process-env
  cluster: process.env.REACT_APP_MIX_PUSHER_APP_CLUSTER,
  forceTLS: false,
  authorizer: (channel) => {
    return {
      authorize: (socketId, callback) => {

        axiosApiInstance.post(`${API_URL}broadcasting/auth`, {
          socket_id: socketId,
          channel_name: channel.name,
        }, {
          'Content-Type': 'application/json',
        })
          .then((response) => {
            callback(false, response.data);
          })
          .catch((error) => {
            callback(true, error);
          });
      },
    };
  },
});

ReactDOM.render(
  <App
    store={store}
    persistor={persistor}
    basename={PUBLIC_URL}
  />,
  document.getElementById('root'),
);
