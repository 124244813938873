import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Slider from 'react-slick';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import imgURL, { IMG_DUMMY } from '../utils/imgURL';



class Img extends React.PureComponent {
  state = {
    isError: false,
    isFull: false,
    isLoad: true,
  };

  refSlider = React.createRef();

  render () {
    const { files = [], current } = this.props;
    const fileRegex = /(.png|.jpeg|.jpg|.svg)/;

    const props = {
      ...this.props,
      src: (this.state.isError || this.state.isLoad) ? (fileRegex.test(this.props.src) ? this.props.src : IMG_DUMMY) : this.props.src,
      // eslint-disable-next-line consistent-return
      onError: (error) => {
        this.setState({
          isError: true,
        });

        if (typeof this.props.onError === 'function') {
          return this.props.onError(error);
        }
      },
      // eslint-disable-next-line consistent-return
      onLoad: (event) => {
        this.setState({
          isLoad: false,
        });

        if (typeof this.props.onLoad === 'function') {
          return this.props.onLoad(event);
        }
      },
      // eslint-disable-next-line consistent-return
      onClick: (event) => {
        if (this.props.canFull && !this.state.isLoad) {
          document.addEventListener('keydown', this.eventKeydown);
          const nFile = files.findIndex((file) => current.id === file.id);

          this.setState({
            isFull: true,
            curFile: nFile <= 0 ? 0 : nFile,
          }, () => {
            if (this.refSlider.current) {
              this.refSlider.current.slickGoTo(this.state.curFile);
            }
          });
        }

        if (typeof this.props.onClick === 'function') {
          return this.props.onClick(event);
        }
      },
      style: !this.state.isError && !this.state.isLoad && this.props.canFull && IMG_DUMMY !== this.props.src
        ? {
          cursor: 'pointer',
          ...(this.props.style || {}),
        }
        : this.props.style,
    };

    // eslint-disable-next-line fp/no-delete
    delete props.canFull;
    // eslint-disable-next-line fp/no-delete
    delete props.files;

    return (<>
      <LazyLoadImage {...props} src={props.src} />
      {!this.state.isError && this.state.isFull && !this.state.isLoad && this.props.canFull && IMG_DUMMY !== this.props.src && (<>
        {files.length > 1 ? (
          <div className='img-list'>
            {files.length > 0 && (
              <div className={`img-list__body${this.state.isFull ? ' img-list__body--full' : ''}`}>
                <div
                  role="link"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className='img-list__btn img-list__btn__close'
                  onClick={(ev) => {
                    ev.stopPropagation();
                    document.removeEventListener('keydown', this.eventKeydown);
                    this.setState({
                      isFull: false,
                    });
                  }}
                >
                  <i className='la la-close' />
                </div>
                <Slider
                  dots
                  infinity
                  centerMode
                  lazyLoad
                  centerPadding={0}
                  focusOnSelect={false}
                  arrows
                  ref={this.refSlider}
                >
                  {this.props.files.map((file) => {
                    return (
                      <div key={uuidv4()}>
                        <img src={file.preview ? file.preview : imgURL(file.link)} alt='img' />
                      </div>
                    );
                  })}
                </Slider>
              </div>
            )}
          </div>
        ) : (
          <div className='img-list img-list--solo'>
            <div
              role="link"
              tabIndex={0}
              onKeyDown={() => {}}
              className={`img-list__body${this.state.isFull ? ' img-list__body--full' : ''}`}
              onClick={() => {
                document.removeEventListener('keydown', this.eventKeydown);
                this.setState({
                  isFull: false,
                });
              }}
            >
              <div
                role="link"
                tabIndex={0}
                onKeyDown={() => {}}
                className='img-list__btn img-list__btn__close'
                onClick={(ev) => {
                  ev.stopPropagation();
                  document.removeEventListener('keydown', this.eventKeydown);
                  this.setState({
                    isFull: false,
                  });
                }}
              >
                <i className='la la-close' />
              </div>
              <div className="slick-slider">
                <div className="slick-track">
                  <div className="slick-slide">
                    <div>
                      <div>
                        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                        <img
                          onKeyDown={() => {}}
                          src={this.props.current
                            ? (this.props.current.preview
                              ? this.props.current.preview
                              : (this.props.current.link
                                ? imgURL(this.props.current.link)
                                : this.props.src)
                            )
                            : this.props.src
                          }
                          alt='img'
                          onClick={(ev) => {
                            ev.stopPropagation();
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>)}
    </>);
  }

  componentDidUpdate (prevProps) {
    if (this.props.src !== prevProps.src) {
      this.setState({
        isError: false,
      });
    }
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.eventKeydown);
  }

  eventKeydown = (event) => {
    if (this.state.isFull) {
      if (event.keyCode === 27) {
        document.removeEventListener('keydown', this.eventKeydown);
        this.setState({
          isFull: false,
        });
      } else if (this.refSlider.current) {
        if (event.keyCode === 39) {
          this.refSlider.current.slickNext();
        } else if (event.keyCode === 37) {
          this.refSlider.current.slickPrev();
        }
      }
    }
  };
}

Img.propTypes = {
  src: PropTypes.string,
  canFull: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({
    preview: PropTypes.string,
    link: PropTypes.string,
    id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  })),
  current: PropTypes.shape({
    preview: PropTypes.string,
    link: PropTypes.string,
    id: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]),
  }),
  lang: PropTypes.object,
  onLoad: PropTypes.any,
  onError: PropTypes.any,
  onClick: PropTypes.any,
  style: PropTypes.any,
};

export default Img;
