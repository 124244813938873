import React from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash';
import { formatToPrettyDate } from '../../utils/formats';
import TaskItem from '../tasks/list/TaskItem';
import { bonusStatusToColor, getTaskBonusStatus } from '../../../const/tasks';

/**
 * Task line component for the table list of tasks
 */
class BillingItemTable extends TaskItem {
  render () {
    const { task, lang } = this.props;

    return (
      <>
        <tr style={{ height: '40px' }} key={task.id}>
          <td>{`${task.project_id}/${task.id}`}</td>
          <td>{task.title || '---'}</td>
          <td>{task.price}</td>
          <td>{task.bonus.amount || '---'}</td>
          <td>{formatToPrettyDate(task.started_at) || '---'}</td>
          <td>{formatToPrettyDate(task.finished_at) || '---'}</td>
          <td>
            <span className={`badge badge-${bonusStatusToColor(task.bonus.status)} badge__position`}>
              {isEmpty(task.bonus.status) ? getTaskBonusStatus(lang, task.bonus.status) : '---'}
            </span>
          </td>
        </tr>
      </>
    );
  }
}

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
});

export default connect(mapStoreToProps)(BillingItemTable);
