import Table from 'react-bootstrap/Table';
import React from 'react';
import PropTypes from 'prop-types';
import Tabs from '../../../widgets/Tabs';
import { formatStringToDate, formatToPrettyDateTime } from '../../../utils/formats';



const propTypes = {
  lang: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};

/**
 * Component for building tables like "artist"
 */

export const Artist = ({ lang, data }) => {
  return (
    <Tabs
      tabs={[
        {
          title: `${lang['REPORTS.TYPES_USER_ANALYZE.report_by_artist_types.finished']} (${data.finished.length})`,

          body: () => (
            <Table
              borderless
              striped
              className="vertical-align-middle text-center"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{lang['CUSTOMER.TYPE.artist']}</th>
                  <th>{lang['REPORTS.DATE_CREATE_DRAFT']}</th>
                  <th>{lang['REPORTS.DATE_CREATE_BACKLOG']}</th>
                  <th>{lang['REPORTS.DATE_READY_TO_TEST']}</th>
                  <th>{lang['PROJECT.FIELDS.type']}</th>
                </tr>
              </thead>
              <tbody>
                {data.finished.map((row) => (
                  <tr key={row.project_id}>
                    <td>{row.project_id}</td>
                    <td>{row.artist}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.draft_created))}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.backlog_created))}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.ready_to_test))}</td>
                    <td>{row.image_type}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
        {
          title: `${lang['REPORTS.TYPES_USER_ANALYZE.report_by_artist_types.content']} (${data.content.length})`,

          body: () => (
            <Table
              borderless
              striped
              className="vertical-align-middle text-center"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{lang['CUSTOMER.TYPE.artist']}</th>
                  <th>{lang['REPORTS.DATE_CREATE_PROJECT']}</th>
                  <th>{lang['REPORTS.DATE_CONTENT_ADDED']}</th>
                  <th>{lang['CUSTOMER.TYPE.editor']}</th>
                  <th>{lang['REPORTS.DATE_READY_TO_REVIEW']}</th>
                </tr>
              </thead>
              <tbody>
                {data.content.map((row) => (
                  <tr key={row.project_id}>
                    <td>{row.project_id}</td>
                    <td>{row.artist}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.project_created))}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.content_added))}</td>
                    <td>{row.editor}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.ready_to_review))}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
        {
          title: `${lang['REPORTS.TYPES_USER_ANALYZE.report_by_artist_types.checked']} (${data.checked.length})`,

          body: () => (
            <Table
              borderless
              striped
              className="vertical-align-middle text-center"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{lang['CUSTOMER.TYPE.artist']}</th>
                  <th>{lang['REPORTS.DATE_CREATE_DRAFT']}</th>
                  <th>{lang['PROJECT.FIELDS.type']}</th>
                </tr>
              </thead>
              <tbody>
                {data.checked.map((row) => (
                  <tr key={row.project_id}>
                    <td>{row.project_id}</td>
                    <td>{row.artist}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.sent_to_manager))}</td>
                    <td>{row.image_type}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
        {
          title: `${lang['REPORTS.TYPES_USER_ANALYZE.report_by_artist_types.assigned']} (${data.assigned.length})`,

          body: () => (
            <Table
              borderless
              striped
              className="vertical-align-middle text-center"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{lang['CUSTOMER.TYPE.artist']}</th>
                  <th>{lang['REPORTS.DATE_CREATE_DRAFT']}</th>
                  <th>{lang['REPORTS.DATE_CREATE_BACKLOG']}</th>
                  <th>{lang['CUSTOMER.TYPE.designer']}</th>
                  <th>{lang['CUSTOMER.TYPE.editor']}</th>
                  <th>{lang['REPORTS.DATE_READY_TO_REVIEW']}</th>
                  <th>{lang['PROJECT.FIELDS.type']}</th>
                </tr>
              </thead>
              <tbody>
                {data.assigned.map((row) => (
                  <tr key={row.project_id}>
                    <td>{row.project_id}</td>
                    <td>{row.artist}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.draft_created))}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.backlog))}</td>
                    <td>{row.designer}</td>
                    <td>{row.designer_editor}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.ready_to_review))}</td>
                    <td>{typeof row.image_type === 'object' ? row.image_type.type : row.image_type}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
      ]}
    />
  );
};

Artist.propTypes = propTypes;

export default Artist;

