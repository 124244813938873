import React from 'react';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import { Button, ButtonGroup } from 'react-bootstrap';
import { orderBy, isEmpty } from 'lodash';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';

import PropTypes from 'prop-types';
import Preload from '../../widgets/Preload';
import Modal from '../../widgets/Modal';
import imgURL from '../../utils/imgURL';
import axiosApiInstance from '../../requests/utils/api';
import { notification } from '../../requests/notifications';
import KTUtil from '../../../_metronic/_assets/js/util';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';
import SoftSubheader from './SoftSubheader';
import SoftLoaderForm from './SoftLoaderForm';
import MakeVersionActualModal from './modal/MakeVersionActualModal';



const propTypes = {
  lang: PropTypes.object.isRequired,
  notification: PropTypes.func,
  userRoles: PropTypes.array.isRequired,
};

/**
 * Component for software download page
 */

class Soft extends React.PureComponent {
  state = {
    soft: [],
    LOAD: true,
    deleteVersion: null,
    isActiveForm: false,
    softFormValues: {
      platform: 'mac',
      is_actual: 0,
      version: '',
    },
    makeVersionActual: null,
  };

  computed = {
    canDeleteVersion: () => {
      return checkRole(this.props.userRoles, [ roles.administrator.key ]);
    },
    canUploadVersion: () => {
      return checkRole(this.props.userRoles, [ roles.administrator.key ]);
    },
    canSeeAllVersions: () => {
      return checkRole(this.props.userRoles, [ roles.administrator.key ]);
    },
    canMakeVersionActual: () => {
      return checkRole(this.props.userRoles, [ roles.administrator.key ]);
    },
  };

  SoftLoaderForm = SoftLoaderForm.bind(this);

  render () {
    const { lang } = this.props;
    const { isActiveForm } = this.state;

    return this.state.LOAD ? (
      <Preload />
    ) : (
      <>
        <SoftSubheader
          title={lang['MENU.SOFT']}
          isActiveForm={isActiveForm}
          toggleForm={this._toggleForm}
        />
        <div className="row">
          {this.SoftLoaderForm()}
        </div>
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className='row'>
              <div className='col-12'>
                <h3>{lang['SOFT.FILES']}</h3>
                <Table bordered className='vertical-align-unset text-center'>
                  <thead>
                    <tr>
                      <th>{lang['SOFT.VERSION']}</th>
                      <th>{lang['SOFT.PLATFORM']}</th>
                      <th>{lang['SOFT.IS_ACTUAL']}</th>
                      <th>{lang['GLOBAL.ACTIONS']}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.buildList()}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
        <Modal
          title={lang['SOFT.YOU_SURE_REMOVE']}
          show={!!this.state.deleteVersion}
          close={() => {
            this.setState({
              deleteVersion: null,
            });
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({
                    deleteVersion: null,
                  });
                }}
              >
                {lang['GLOBAL.CANCEL']}
              </button>
              <button
                className="btn btn-danger"
                onClick={async () => {
                  await this.deleteSoft(this.state.deleteVersion);
                }}
              >
                {lang['GLOBAL.REMOVE']}
              </button>
            </>
          }
        />
        <MakeVersionActualModal
          makeVersionActual={this.state.makeVersionActual}
          makeVersionActualFn={this.makeVersionActual}
          setModalMakeVersionActual={this._setModalMakeVersionActual}
        />
      </>);
  }

  componentDidMount () {
    this.getSoft();
  }

  buildList = () => {
    const { lang } = this.props;

    return orderBy(this.state.soft.versions, (soft) => soft.version).map((item) => {
      if (!isEmpty(item) && (
        (item.is_actual && !this.computed.canSeeAllVersions())
          || this.computed.canSeeAllVersions())
      ) {
        return (
          <tr key={item.id}>
            <td>
              {item.version}
            </td>
            <td>
              {item.platform}
            </td>
            <td>
              {item.is_actual ? (
                <i className='fa fa-check-circle text-success kt-ml-5 kt-mr-0' />
              ) : (
                <i className='fa fa-times-circle text-danger kt-ml-5 kt-mr-0' />
              )}
            </td>
            <td>
              <ButtonGroup>
                <Button
                  title={lang['GLOBAL.DOWNLOAD']}
                  size='sm'
                  variant='info'
                  as={'a'}
                  target="_blank"
                  download
                  href={imgURL(item.path)}
                >
                  <i className='flaticon2-download-2 kt-m-0' />
                </Button>
                { this.computed.canMakeVersionActual() && (
                  <Button
                    title={lang['SOFT.MAKE_VERSION_ACTUAL']}
                    variant='success'
                    size='sm'
                    disabled={item.is_actual}
                    onClick={() => {
                      this.setState({
                        makeVersionActual: { 'id': item.id, 'platform': item.platform },
                      });
                    }}
                  >
                    <i className="fa fa-check-circle kt-m-0" />
                  </Button>
                )}
                { this.computed.canDeleteVersion() && (
                  <Button
                    title={lang['GLOBAL.REMOVE']}
                    variant='danger'
                    size='sm'
                    onClick={() => {
                      this.setState({
                        deleteVersion: item.id,
                      });
                    }}
                  >
                    <i className="fa fa-trash kt-m-0" />
                  </Button>
                )}
              </ButtonGroup>
            </td>
          </tr>
        );
      }
      return true;
    });
  };

  _toggleForm = () => {
    if (!this.state.isActiveForm) {
      KTUtil.scrollTop(0, 300);
    }

    this.setState({
      isActiveForm: !this.state.isActiveForm,
    });
  };

  getSoft = () => {
    this.setState({
      LOAD: true,
    });
    axiosApiInstance.get('software-versions')
      .then((response) => {
        const msg = response.data;

        this.setState({
          soft: msg,
          LOAD: false,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  postSoft = (softFormValues) => {
    if (!this.computed.canUploadVersion() || !this._isSoftVersionValid(softFormValues)) {
      return false;
    }
    this.setState({
      LOAD: true,
    });

    const fd = new FormData();

    fd.append('platform', softFormValues.platform);
    fd.append('version', softFormValues.version);
    fd.append('is_actual', softFormValues.is_actual);
    fd.append('file', softFormValues.file);

    axiosApiInstance.post('software-versions', fd, {})
      .then(() => {
        this._clearSoftLoaderForm();
        this.props.notification('NOTIFICATION.SUCCESS_LOAD_VERSION', 'success');
        this.getSoft();
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_LOAD_VERSION', 'error');
        this.setState({
          LOAD: false,
        });
      });
  };

  putSoft = (id, platform) => {
    this.setState({
      LOAD: true,
    });

    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('type', 'main');
    fd.append('platform', platform);

    axiosApiInstance.post(`software-versions/${id}`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_UPDATE_SOFT', 'success');
        this.getSoft();
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_UPDATE_SOFT', 'error');
        this.setState({
          LOAD: false,
        });
      });
  };

  makeVersionActual = (soft) => {
    if (!this.computed.canMakeVersionActual()) {
      return false;
    }
    this.setState({
      LOAD: true,
    });

    const fd = new FormData();

    fd.append('_method', 'PUT');
    fd.append('type', 'main');
    fd.append('platform', soft.platform);

    axiosApiInstance.post(`software/make-actual/${soft.id}`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_UPDATE_SOFT', 'success');
        this.setState({
          makeVersionActual: null,
        });
        this.getSoft();
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_UPDATE_SOFT', 'error');
        this.setState({
          makeVersionActual: null,
          LOAD: false,
        });
      });
  };

  deleteSoft = (id) => {
    this.setState({
      LOAD: true,
    });

    const fd = new FormData();

    fd.append('_method', 'DELETE');

    axiosApiInstance.post(`software-versions/${id}`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_REMOVE_VERSION', 'success');
        this.setState({
          deleteVersion: null,
        });
        this.getSoft();
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_REMOVE_VERSION', 'error');
        this.setState({
          deleteVersion: null,
          LOAD: false,
        });
      });
  };

  _setModalMakeVersionActual = (val) => {
    this.setState({
      makeVersionActual: val,
    });
  };

  _clearSoftLoaderForm = () => {
    this.setState({
      isActiveForm: !this.state.isActiveForm,
      softFormValues: {
        version: '',
        platform: 'mac',
        is_actual: 0,
        file: '',
      },
    });
  };

  _isSoftVersionValid = (softFormValues) => {

    if (this.state.soft.versions.find((soft) => (soft.version === softFormValues.version)
        && (soft.platform === softFormValues.platform))
    ) {
      this.props.notification('NOTIFICATION.ERROR_SOFT_VERSION_EXISTS', 'error');
      return false;
    }
    return true;

  };
}

const mapStateToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

Soft.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(Soft);
