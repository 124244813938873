import React, { createContext, useContext } from 'react';
import PropTypes from 'prop-types';



const TagFormContext = createContext(null);

export const TagFormProvider = ({ children, formValues, control, setValue, errors }) => {
  return (
    <TagFormContext.Provider value={{ formValues, control, setValue, errors }}>
      {children}
    </TagFormContext.Provider>
  );
};


TagFormProvider.propTypes = {
  children: PropTypes.node.isRequired,
  control: PropTypes.any.isRequired,
  formValues: PropTypes.object.isRequired,
  setValue: PropTypes.func.isRequired,
  errors: PropTypes.object.isRequired,
};

export const useTagFormContext = () => {
  const context = useContext(TagFormContext);

  if (!context) {
    throw new Error('useTagFormContext must be used within a FormProvider');
  }
  return context;
};
