import React, { useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import MySelect from '../../../../widgets/MySelect';
import templateActions from '../../../../store/tagSystem/templates/actions';
import sortCaseInsensitive from '../../../../utils/SortCaseInsensitive';



const GroupsFilter = ({ filter, onFilterChange }) => {
  const dispatch = useDispatch();
  const { lang, templates } = useSelector(({ language, tagSystemTemplates }) => ({
    lang: language.lang,
    templates: tagSystemTemplates.templates,
  }), shallowEqual);

  useEffect(() => {
    dispatch(templateActions.getTemplates());
  }, []);

  const getTemplateOptions = useMemo(() => {
    if (templates) {
      return orderBy(templates, sortCaseInsensitive([ 'name' ]), [ 'asc' ]).map((template) => {
        return {
          label: template.name,
          value: template.id,
        };
      });
    }
    return [];
  }, [ templates ]);

  const handleSearch = (event) => {
    const search = event.target.value.trim();

    onFilterChange('search', search);
  };

  const handleTemplate = (event) => {
    onFilterChange('template', event);
  };

  return (
    <Row>
      <Col>
        <input
          className="form-control"
          type="text"
          placeholder={lang['GLOBAL.SEARCH']}
          value={filter.search}
          onChange={handleSearch}
          style={{ width: '200px' }}
        />
      </Col>
      <Col>
        <div style={{ width: '250px', marginRight: '20px' }}>
          <MySelect
            isClearable
            placeholder={lang['TAG_SYSTEM.GROUPS.SELECT_TEMPLATE']}
            options={getTemplateOptions}
            value={filter.template}
            onChange={handleTemplate}
          />
        </div>
      </Col>
    </Row>
  );
};

GroupsFilter.propTypes = {
  filter: PropTypes.shape({
    search: PropTypes.string,
    template: PropTypes.oneOfType([
      PropTypes.oneOf([ null, PropTypes.string ]),
      PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.number,
      }),
    ]),
  }),
  onFilterChange: PropTypes.func.isRequired,
};

export default GroupsFilter;
