import React from 'react';
import PropTypes from 'prop-types';



const StatLabel = (props) => {
  return (
    <h5 style={{ whiteSpace: 'nowrap' }}>{props.children}</h5>
  );
};

StatLabel.propTypes = {
  children: PropTypes.any,
};

export default StatLabel;
