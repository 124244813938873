import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../../../widgets/Modal';



const ApproveBonusesModal = ({ confirm, showApproveBonusesModal, toggleApproveBonusesModal }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <Modal
      title={lang['BONUSES.YOU_SURE_PAYMENTS_AGREED']}
      show={showApproveBonusesModal}
      close={toggleApproveBonusesModal}
      footer={(
        <>
          <button
            className="btn btn-secondary"
            onClick={toggleApproveBonusesModal}
          >
            {lang['GLOBAL.NO']}
          </button>

          <button
            className="btn btn-success"
            onClick={confirm}
          >
            {lang['GLOBAL.YES']}
          </button>
        </>
      )}
    />
  );
};

ApproveBonusesModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  showApproveBonusesModal: PropTypes.bool.isRequired,
  toggleApproveBonusesModal: PropTypes.func.isRequired,
};

export default ApproveBonusesModal;
