import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import axiosApiInstance from '../../requests/utils/api';
import urlPageProjectsList from '../../urls/urlPageProjectsList';



const propTypes = {
  lang: PropTypes.object.isRequired,
};

/**
 * Component categories statistic page
 */

class CategoriesStatistic extends React.Component {
  state = {
    statisticsData: [],
  };

  computed = {
    preparedData: () => {
      const preparedResult = [];

      // eslint-disable-next-line fp/no-loops,no-restricted-syntax
      for (const i in this.state.statisticsData) {
        if (this.state.statisticsData.hasOwnProperty(i)) {
          const name = i !== '' ? (this.state.statisticsData[i][0]?.deleted_at ? `${i} (Deleted)` : i) : '_uncategorized';

          const taps = this.state.statisticsData[i].map((item) => item.taps);
          const categoryId = this.state.statisticsData[i][0].category_id;

          const preparedTaps = {
            easy: 0,
            medium: 0,
            hard: 0,
            very_hard: 0,
            impossible: 0,
          };

          taps.forEach((item) => {
            if (item > 0 && item <= 300) {
              preparedTaps.easy += 1;
            }
            if (item >= 301 && item <= 500) {
              preparedTaps.medium += 1;
            }
            if (item >= 501 && item <= 700) {
              preparedTaps.hard += 1;
            }
            if (item >= 701 && item <= 1000) {
              preparedTaps.very_hard += 1;
            }
            if (item >= 1001) {
              preparedTaps.impossible += 1;
            }
          });

          const totalTaps = taps.length;

          preparedResult.push({ name, preparedTaps, totalTaps, categoryId });
        }

      }
      return preparedResult;
    },
    getTotalForColumn: (data, type) => {
      return data.reduce((acc, item) => acc + item.preparedTaps[type], 0);
    },
  };

  render () {
    const { lang } = this.props;
    const { preparedData, getTotalForColumn } = this.computed;

    const data = [ ...preparedData() ];
    const urlPrefix = `${urlPageProjectsList({ type: 'coloring' })}?`;

    return (
      <>
        <SubHeader
          title={lang['CATEGORIES.STATISTIC']}
        />
        <div className="row">
          <div className="col-12 kt-portlet mw-100 overflow-auto kt-font-md table-wrapper">
            <table className="table table-hover mb-0">
              <thead>
                <tr>
                  <th style={{ verticalAlign: 'initial' }}>{lang['TAGS.CATEGORY']}</th>
                  <th>{lang['CATEGORIES.STATISTIC_HEADER.EASY']} (1 - 300)
                    <br />
                    <span className='badge badge-info badge__position'>
                      {getTotalForColumn(data, 'easy')}
                    </span>
                  </th>
                  <th>{lang['CATEGORIES.STATISTIC_HEADER.MEDIUM']} (301 - 500)
                    <br />
                    <span className='badge badge-info badge__position'>
                      {getTotalForColumn(data, 'medium')}
                    </span>
                  </th>
                  <th>{lang['CATEGORIES.STATISTIC_HEADER.HARD']} (501 - 700)
                    <br />
                    <span className='badge badge-info badge__position'>
                      {getTotalForColumn(data, 'hard')}
                    </span>
                  </th>
                  <th>{lang['CATEGORIES.STATISTIC_HEADER.VERY_HARD']} (701 - 1000)
                    <br />
                    <span className='badge badge-info badge__position'>
                      {getTotalForColumn(data, 'very_hard')}
                    </span>
                  </th>
                  <th>{lang['CATEGORIES.STATISTIC_HEADER.IMPOSSIBLE']} (1000+)
                    <br />
                    <span className='badge badge-info badge__position'>
                      {getTotalForColumn(data, 'impossible')}
                    </span>
                  </th>
                  <th>{lang['CATEGORIES.STATISTIC_HEADER.TOTAL']}
                    <br />
                    <span className='badge badge-info badge__position'>
                      {data.reduce((acc, item) => acc + item.totalTaps, 0)}
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item) => {
                  const isDisabled = (field) => item.preparedTaps[field] === 0;

                  return (
                    <tr key={item.name}>
                      <th>{item.name}</th>
                      <td>
                        {isDisabled('easy') ? (
                          <>
                            {item.preparedTaps.easy}
                          </>
                        ) : (
                          <Link to={`${urlPrefix}taps[from]=1&taps[to]=300&category_id=${item.categoryId}&from_stat=true`}>
                            {item.preparedTaps.easy}
                          </Link>
                        )}
                      </td>
                      <td>
                        {isDisabled('medium') ? (
                          <>
                            {item.preparedTaps.medium}
                          </>
                        ) : (
                          <Link to={`${urlPrefix}taps[from]=301&taps[to]=500&category_id=${item.categoryId}&from_stat=true`}>
                            {item.preparedTaps.medium}
                          </Link>
                        )}
                      </td>
                      <td>
                        {isDisabled('hard') ? (
                          <>
                            {item.preparedTaps.hard}
                          </>
                        ) : (
                          <Link to={`${urlPrefix}taps[from]=501&taps[to]=700&category_id=${item.categoryId}&from_stat=true`}>
                            {item.preparedTaps.hard}
                          </Link>
                        )}
                      </td>
                      <td>
                        {isDisabled('very_hard') ? (
                          <>
                            {item.preparedTaps.very_hard}
                          </>
                        ) : (
                          <Link to={`${urlPrefix}taps[from]=701&taps[to]=1000&category_id=${item.categoryId}&from_stat=true`}>
                            {item.preparedTaps.very_hard}
                          </Link>
                        )}
                      </td>
                      <td>
                        {isDisabled('impossible') ? (
                          <>
                            {item.preparedTaps.impossible}
                          </>
                        ) : (
                          <Link to={`${urlPrefix}taps[from]=1001&taps[to]=10000&category_id=${item.categoryId}&from_stat=true`}>
                            {item.preparedTaps.impossible}
                          </Link>
                        )}
                      </td>
                      <td>{item.totalTaps}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  }


  componentDidMount () {
    this.getStatistic();
  }

  getStatistic = async () => {
    const response = await axiosApiInstance.get('tap-stat');

    this.setState({
      statisticsData: response.data,
    });
  };
}

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
});

CategoriesStatistic.propTypes = propTypes;

export default connect(mapStoreToProps)(CategoriesStatistic);
