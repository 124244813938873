export const DAILY = 'Daily';
export const FEED = 'Feed';
export const PACKS = 'Packs';
export const SOCIAL_MEDIA = 'Social media';

/**
 * Happy Color content types
 *
 * @type {string[]}
 */
const hcContentTypes = [ DAILY, FEED, PACKS, SOCIAL_MEDIA ];

export default hcContentTypes;
