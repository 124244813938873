import React from 'react';
import PropTypes from 'prop-types';



const MatchItem = ({ value }) => {
  return (
    <div
      className="tags-match__item" style={{
        backgroundColor: value ? '#ffc212f5' : '#67c23a',
      }}
    >{value}</div>
  );
};

MatchItem.propTypes = {
  value: PropTypes.number.isRequired,
};

const TagsMatch = ({ primaryDiff, typeDiff, additionalDiff }) => {
  return (
    <div className="d-flex position-absolute tags-match-wrapper">
      <MatchItem value={primaryDiff} />
      <MatchItem value={typeDiff} />
      <MatchItem value={additionalDiff} />
    </div>
  );
};

TagsMatch.propTypes = {
  primaryDiff: PropTypes.number.isRequired,
  typeDiff: PropTypes.number.isRequired,
  additionalDiff: PropTypes.number.isRequired,
};

export default TagsMatch;
