import React from 'react';
import PropTypes from 'prop-types';
import { entityTypes } from '../../pages/tagSystem/config/config';



const TagSystemPictureTagsTreeWidget = ({ picture = {} }) => {
  const { entities = [] } = picture;

  const sortNodes = (nodes) => {
    return nodes.sort((first, second) => {
      if (first.type === entityTypes.group && second.type === entityTypes.template) {
        return -1;
      }
      if (first.type === entityTypes.template && second.type === entityTypes.group) {
        return 1;
      }

      return 0;
    });
  };

  // Рекурсивная функция для рендеринга дерева тегов
  const renderTree = (nodes) => {
    const sortedNodes = sortNodes(nodes);

    return sortedNodes.map((node) => {
      if (node.type === entityTypes.tag) {
        return (
          <button
            key={node.id}
            style={{ padding: '2px 7px' }}
            className="mr-2 mb-2 btn btn-label-dark-o2 btn-sm"
          >
            {node.name}
          </button>
        );
      }

      let classes = 'kt-font-lg kt-font-bolder';

      if (node.type === entityTypes.template) {
        classes += ' template-title kt-mt-20';
      }

      // Обработка групп и шаблонов
      return (
        <div key={node.id} className="kt-ml-10 kt-mt-10">
          <h6 className={classes}>{node.name}</h6>
          <div className="kt-mt-5 kt-ml-10">
            {node.children && renderTree(node.children)}
          </div>
        </div>
      );
    });
  };

  return (
    <div className="kt-widget__item flex-grow-0 justify-content-end kt-pr-0">
      <div className="kt-widget__details">
        <span className="kt-widget__title">
          {renderTree(entities.filter((entity) => entity.type !== entityTypes.tag))}
        </span>
      </div>
    </div>
  );
};

TagSystemPictureTagsTreeWidget.propTypes = {
  picture: PropTypes.shape({
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired,
        type: PropTypes.string.isRequired,
        children: PropTypes.array,
      })
    ),
  }).isRequired,
};

export default TagSystemPictureTagsTreeWidget;
