import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Slider from 'react-slick';

/**
 * Component for selected files
 *
 * @param {any} file
 * @returns {*}
 */
class DropFile extends React.Component {
  state = {
    isFull: false,
  };

  inputRef = React.createRef();

  refSlider = React.createRef();

  render () {
    const { lang, fileTextPreview, hasCustomText, customInnerText } = this.props;

    return (
      <>
        <div
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className={`dropzone dropzone-default d-flex flex-wrap justify-content-center p-10 align-items-center dropzone-${this.props.theme} ${this.props.className} dz-clickable`}
          style={{ background: 'white', ...this.props.styles }}
          onClick={() => {
            this.inputRef.current.dispatchEvent(new MouseEvent('click'));
          }}
          onDragOver={((event) => {
            event.stopPropagation();
            event.preventDefault();
          })}
          onDrop={async (event) => {
            const files = [ ...event.dataTransfer.files ];

            event.stopPropagation();
            event.preventDefault();
            if (!this.props.multiple && files.length > 1) {
              return;
            }
            const _files = (await Promise.allSettled(files.map((file) => this.readFile(file)))).map((res) => res.value);

            if (this.props.addFiles) {
              this.props.addFiles(this.props.accept ? _files.filter((file) => file.file.type.match(this.props.accept)) : _files);
            }
          }}
        >
          <div
            className="dropzone-msg dz-message needsclick w-100 d-flex flex-wrap align-items-center justify-content-center"
            style={{
              pointerEvents: 'none',
            }}
          >
            <h3 className="dropzone-msg-title dropzone w-100">
              {hasCustomText ? customInnerText : lang[this.props.multiple ? 'GLOBAL.DROP_FILES' : 'GLOBAL.DROP_FILE']}</h3>
            <span
              className="dropzone-msg-desc w-100"
            >{this.props.info}</span>
          </div>
          {this.props.curFiles && this.props.curFiles.map((file) => {
            return (
              <div
                key={uuidv4()}
                className='dz-preview dz-processing dz-image-preview dz-error dz-complete w-100 d-flex mw-100 d-flex justify-content-between align-items-center kt-mb-10'
              >
                <div className='d-flex align-items-center overflow-hidden'>
                  <div className={`dz-image ${file.preview ? '' : 'border-0'}`}>
                    {file.preview && (
                      // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
                      <img
                        onKeyDown={() => {}}
                        alt={file.file.name}
                        src={file.preview}
                        style={{
                          width: '100%',
                          height: '100%',
                          objectFit: 'cover',
                        }}
                        onClick={(ev) => {
                          ev.preventDefault();
                          ev.stopPropagation();
                          this.setState({
                            isFull: true,
                          }, () => {
                            this.refSlider.current.slickGoTo(this.props.curFiles.filter((fi) => fi.preview).findIndex((fi) => fi === file));
                          });
                        }}
                      />
                    )}
                  </div>
                  <div
                    className="text-nowrap w-100"
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {file.file.name}
                  </div>
                </div>
                <span
                  className="dz-remove btn btn-danger btn-sm kt-ml-5 kt-p-5 text-nowrap"
                  role="link"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  onClick={(event) => {
                    event.stopPropagation();
                    event.preventDefault();
                    if (this.props.removeFile) {
                      this.props.removeFile(file);
                    }
                  }}
                >{lang['GLOBAL.REMOVE_FILE']}</span>
              </div>
            );
          })}
          {fileTextPreview && (
            <div
              className="text-nowrap w-100"
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                fontSize: '16px',
              }}
            >
              {fileTextPreview}
            </div>
          )}
        </div>
        <input
          ref={this.inputRef}
          type="file"
          className="form-control form-control-file"
          multiple={this.props.multiple}
          style={{
            display: 'none',
          }}
          accept={this.props.accept}
          onChange={async (event) => {
            const files = [ ...event.target.files ];
            const _files = (await Promise.allSettled(files.map((file) => this.readFile(file)))).map((res) => res.value);

            if (this.props.addFiles) {
              this.props.addFiles(_files);
            }
          }}
        />
        {this.state.isFull && (
          <div className='img-list'>
            <div
              className={'img-list__body img-list__body--full'}
            >
              <div
                role="link"
                tabIndex={0}
                onKeyDown={() => {}}
                className='img-list__btn img-list__btn__close'
                onClick={(event) => {
                  event.stopPropagation();
                  document.removeEventListener('keydown', this.eventKeydown);
                  this.setState({
                    isFull: false,
                  });
                }}
              >
                <i className='la la-close' />
              </div>
              <Slider
                dots
                infinity
                centerMode
                centerPadding={0}
                focusOnSelect={false}
                arrows
                ref={this.refSlider}
              >
                {/* eslint-disable-next-line array-callback-return,consistent-return */}
                {this.props.curFiles.map((file) => {
                  if (file.preview) {
                    return (
                      <div key={uuidv4()}>
                        <img src={file.preview} alt='img' />
                      </div>
                    );
                  }
                })}
              </Slider>
            </div>
          </div>
        )}
      </>
    );
  }

  readFile = (file) => {
    if (!file.type.match('image.*')) {
      const fi = {
        file,
        preview: undefined,
      };

      if (this.props.addFile) {
        this.props.addFile(fi);
      }

      return new Promise((resolve) => {
        resolve(fi);
      });
    }

    return new Promise((resolve) => {
      const reader = new FileReader();
      // Closure to capture the file information.

      reader.onload = (() => {
        return (ev) => {
          // Render thumbnail.
          const fi = {
            file,
            preview: ev.target.result,
          };

          if (this.props.addFile) {
            this.props.addFile(fi);
          }
          resolve(fi);
        };
      })(file);
      // Read in the image file as a data URL.
      reader.readAsDataURL(file);
    });
  };
}

DropFile.propTypes = {
  theme: PropTypes.oneOf([ 'brand', 'light', 'dark', 'primary', 'success', 'info', 'warning', 'danger' ]),
  styles: PropTypes.object,
  className: PropTypes.string,
  info: PropTypes.node,
  addFile: PropTypes.func,
  addFiles: PropTypes.func,
  removeFile: PropTypes.func,
  multiple: PropTypes.bool,
  hasCustomText: PropTypes.bool,
  customInnerText: PropTypes.string,
  accept: PropTypes.string,
  curFiles: PropTypes.arrayOf(PropTypes.shape({
    preview: PropTypes.any,
    file: PropTypes.instanceOf(File),
  })),
  showPreview: PropTypes.bool,
  fileTextPreview: PropTypes.string,
  lang: PropTypes.object,
};

DropFile.defaultProps = {
  multiple: true,
  theme: 'success',
  className: '',
  customInnerText: '',
  hasCustomText: false,
  fileTextPreview: '',
  styles: {},
  showPreview: true,
};

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

export default connect(mapStoreToProps)(DropFile);
