import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import LanguageSelector from '../../partials/layout/LanguageSelector';
import imgURL from '../../../app/utils/imgURL';
import Img from '../../../app/widgets/Img';
import urlPageProfile from '../../../app/urls/urlPageProfile';
import RoleSelector from '../../partials/layout/RoleSelector';
import UserNotifications from "../../partials/layout/UserNotifications";
import checkRole from "../../../app/utils/checkRole";
import roles, {executorsRoles} from "../../../app/pages/customers/roles/roles";



class Topbar extends React.Component {
  render () {
    const { lang, user, userRoles, multipleRoles } = this.props;

    return (
      <div className="kt-header__topbar no-print">
        { checkRole(userRoles, [ ...executorsRoles ]) && <UserNotifications icon={'fa fa-bell'} pulse={true} />}
        <RoleSelector roles={multipleRoles} />
        <LanguageSelector iconType="" />
        <Link to={urlPageProfile()} className='kt-header__topbar-item kt-header__topbar-item--user'>
          <div className="kt-header__topbar-user">
            <span className="kt-header__topbar-welcome">
              {`${lang['USER_PROFILE.HI']}, ${user?.username || ''}`}
            </span>

            <Img alt="Pic" src={imgURL(user?.avatar)} />
          </div>
        </Link>
      </div>
    );
  }
}


const mapStateToProps = (store) => ({
  user: store.user.user,
  userRoles: store.user.roles,
  multipleRoles: store.user.multipleRoles,
  lang: store.language.lang,
});

export default connect(mapStateToProps)(Topbar);
