import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React from 'react';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import MyComponent from '../../utils/MyComponent';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';

/**
 * Subheader component for task list
 */
class SoftSubheader extends MyComponent {

  computed = {
    canUploadVersion: () => {
      return checkRole(this.props.userRoles, [ roles.administrator.key ]);
    },
  };

  render () {
    const {
      lang,
      isActiveForm,
      toggleForm,
    } = this.props;

    return (
      <SubHeader
        size="sm"
        title={lang['MENU.SOFT']}
        toolbar={
          <div className='d-flex align-items-center'>
            { this.computed.canUploadVersion && (
              <Button
                variant='label-info'
                className="btn-bold"
                active={isActiveForm}
                onClick={toggleForm}
              >
                {lang['SOFT.HEADER_LOAD_FILE']}
              </Button>
            )}
          </div>
        }
      />
    );
  }
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
  };
};

export default connect(mapStoreToProps)(withRouter(SoftSubheader));
