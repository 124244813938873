import React from 'react';
import { useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { isFabros } from '../../utils/isFabros';



const DocumentPreview = ({ type, document }) => {
  const lang = useSelector((store) => store.language.lang);

  const isTerm = () => type === 'term';

  return (
    <div className="row">
      <div className="col-xl-12">
        <div className="kt-portlet kt-portlet--height-fluid">
          <div className="kt-portlet__head no-print">
            <div className="kt-portlet__head-label no-print">
              <h3 className="kt-portlet__head-title font-weight-bold">
                {isTerm() ? lang['CUSTOMER.MY_CONTRACT'] : lang[isFabros ? 'CUSTOMER.MY_POLICY' : 'CUSTOMER.MY_POLICY_XFLOW']}
              </h3>
            </div>
            <div className="kt-portlet__head-label no-print">
              <Button
                variant='success'
                onClick={() => {
                  window.print();
                }}
              >{lang['GLOBAL.DOWNLOAD']}</Button>
            </div>
          </div>
          <div className="kt-portlet__body">
            <div className="row w-100">
              <div className="col-lg-12 no-print">
                <div className="alert alert-success">
                  <div className="alert-icon"><i className="flaticon-user-ok" /></div>
                  <div className="alert-text">
                    {lang[isTerm() ? 'GLOBAL.CONTRACT_ACCEPTED'
                      : (isFabros ? 'GLOBAL.POLICY_ACCEPTED' : 'GLOBAL.POLICY_ACCEPTED_XFLOW')]}
                  </div>
                </div>
                <hr />
              </div>
              <div
                className="col-lg-12"
                dangerouslySetInnerHTML={{ __html: document }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

DocumentPreview.propTypes = {
  type: PropTypes.string.isRequired,
  document: PropTypes.string,
};


export default DocumentPreview;
