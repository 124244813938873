import { shallowEqual, useSelector } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { Button, Form } from 'react-bootstrap';
import { useLocation, useParams } from 'react-router-dom';
import SubHeader from '../../../../../_metronic/layout/sub-header/SubHeader';
import useMobileScreen from '../../../../hooks/useMobileScreen';
import ViewFilters from '../../../../widgets/ViewFilters';
import { parseFromPars } from '../../../../utils/parseUrlParams';
import { useProjectsList } from './ProjectsListContext';



const propTypes = {
  total: PropTypes.number.isRequired,
  removeFilter: PropTypes.func.isRequired,
  toggleViewType: PropTypes.func.isRequired,
  toggleFilter: PropTypes.func.isRequired,
  paginator: PropTypes.node,
  onFilterChange: PropTypes.func.isRequired,
  startProjects: PropTypes.func.isRequired,
};


const ProjectsListSubheader = ({
  total,
  toggleViewType,
  removeFilter,
  toggleFilter,
  paginator,
  onFilterChange,
  startProjects,
}) => {
  const { canSplit, filter, isSelectedStage, selectedProjects, tableView } = useProjectsList();
  const [ isMobile ] = useMobileScreen();
  const location = useLocation();
  const { type } = useParams();

  const { lang, users, config } = useSelector(({ config, language, user, users }) => ({
    lang: language.lang,
    config,
    users,
    userRoles: user.roles,
  }), shallowEqual);
  const activeFilters = parseFromPars(location.search);
  const isFromStatistics = !!activeFilters?.from_stat;
  const filterExecutor = users.find((user) => user.id === filter.executor);

  const getTitle = () => {
    const title = lang['PROJECT.LIST'];

    if (isFromStatistics) {
      return title;
    }

    if (filter.project_type && config[filter.project_type]) {
      return config[filter.project_type].name;
    }

    if (type) {
      if (lang[`MENU.${type.toUpperCase()}`]) {
        return lang[`MENU.${type.toUpperCase()}`];
      }

      return '';
    }

    return title;
  };

  return (
    <SubHeader
      size="sm"
      title={getTitle()}
      subtitle={(filter.status && lang[`MENU.${filter.status.toUpperCase()}`] ? `(${lang[`MENU.${filter.status.toUpperCase()}`].toLowerCase()})` : '')}
      info={`${lang['GLOBAL.TOTAL']}: ${total}`}
      main={(Object.keys(activeFilters).length > 0 || canSplit) && (
        <>
          {Object.keys(activeFilters).length > 0 && (
            <ViewFilters
              filters={activeFilters}
              isFromStatistics={isFromStatistics}
              options={{
                executor: filterExecutor,
              }}
              onRemoveFilter={removeFilter}
            />
          )}
          {canSplit && (
            <>
              <Form.Group className="d-flex kt-m-0 kt-ml-5 align-items-center">
                <Form.Label className="kt-m-0 text-nowrap kt-mr-5">
                  {lang['PROJECT.STAGE']}
                </Form.Label>
                <Form.Control
                  size="sm"
                  as="select"
                  name="stage"
                  value={filter.stage}
                  onChange={onFilterChange}
                >
                  <option value="">{lang['GLOBAL.ANY']}</option>
                  <option value="artists_group_choosing">{lang['GLOBAL.STATUS.artists_group_choosing']}</option>
                  <option value="artist_choosing">{lang['GLOBAL.STATUS.artist_choosing']}</option>
                  <option value="artist_drawing">{lang['GLOBAL.STATUS.artist_drawing']}</option>
                  <option value="editor_checking_artist">{lang['GLOBAL.STATUS.editor_checking_artist']}</option>
                  <option value="manager_checking">{lang['GLOBAL.STATUS.manager_checking']}</option>
                  <option value="designers_group_choosing">{lang['GLOBAL.STATUS.designers_group_choosing']}</option>
                  <option value="designer_choosing">{lang['GLOBAL.STATUS.designer_choosing']}</option>
                  <option value="designer_coloring">{lang['GLOBAL.STATUS.designer_coloring']}</option>
                  <option value="editor_checking_designer">{lang['GLOBAL.STATUS.editor_checking_designer']}</option>
                  <option value="device_testing">{lang['GLOBAL.STATUS.device_testing']}</option>
                </Form.Control>
              </Form.Group>

              {isSelectedStage && (
                <Form.Group className="d-flex kt-m-0 kt-ml-5 align-items-center">
                  <Form.Label className="kt-m-0 text-nowrap kt-mr-5">
                    {lang['GLOBAL.STATUS']}
                  </Form.Label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="step_status"
                    value={filter.step_status}
                    onChange={onFilterChange}
                  >
                    <option value="">{lang['GLOBAL.ANY']}</option>
                    <option value="waiting">{lang['GLOBAL.STATUS.waiting']}</option>
                    <option value="process">{lang['GLOBAL.STATUS.process']}</option>
                  </Form.Control>
                </Form.Group>
              )}
            </>
          )}
        </>
      )}
      toolbar={(
        <div className="d-flex align-items-center">
          {!isMobile && (
            <OverlayTrigger
              placement="bottom"
              overlay={(
                <Tooltip id="projectViewTable">
                  {lang[tableView ? 'GLOBAL.CARDS_MODE' : 'GLOBAL.TABLE_MODE']}
                </Tooltip>
              )}
            >
              <Button
                variant="label-info"
                onClick={toggleViewType}
              >
                {tableView
                  ? (<i className="flaticon-squares-1 kt-m-0 kt-p-0" />)
                  : (<i className="fa fa-th-list kt-m-0 kt-p-0" />)}
              </Button>
            </OverlayTrigger>
          )}
          <OverlayTrigger
            placement="bottom"
            overlay={(
              <Tooltip id="FILTERS">
                {lang['GLOBAL.FILTERS']}
              </Tooltip>
            )}
          >
            <Button
              variant="label-info"
              active={filter.show}
              onClick={toggleFilter}
            >
              <i className="fa fa-filter kt-m-0 kt-p-0" />
            </Button>
          </OverlayTrigger>
          {selectedProjects.length > 0 && (
            <Button
              variant="label-success"
              className="btn-bold"
              onClick={startProjects}
            >
              {lang['PROJECT.START_SELECT_PROJECTS']}
            </Button>
          )}
          <div className="kt-ml-5 d-flex">
            {paginator}
          </div>
        </div>
      )}
    />
  );
};

ProjectsListSubheader.propTypes = propTypes;

export default ProjectsListSubheader;
