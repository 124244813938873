import React, { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import PropsTypes from 'prop-types';
import Preload from '../../widgets/Preload';
import axiosApiInstance from '../../requests/utils/api';
import { actionsNotifications } from '../../store/notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';
import TaskInfo from './TaskInfo/TaskInfo';
import getTasksRequest from './requests/getTasks';
import SetTagsModal from './modal/SetTagsModal';
import ChangeImageTypeModal from './modal/ChangeImageTypeModal';
import TasksGroupChooseSubheader from './info/TasksGroupChooseSubheader';
import SidebarChat from './info/SidebarChat';
import startTasks from './requests/startTasks';
import SetComplexityLevelModal from './modal/SetComplexityLevelModal';



const TasksChooseGroup = ({ location, history }) => {
  const [ tasks, setTasks ] = useState([]);
  const [ currentTask, setCurrentTask ] = useState({});
  const [ loading, setLoading ] = useState(false);
  const [ showChat, setShowChat ] = useState(false);
  const [ isTagsModalActive, setIsTagsModalActive ] = useState(false);
  const [ isContourLevelModalActive, setIsContourLevelModalActive ] = useState(false);
  const [ isColorLevelModalActive, setIsColorLevelModalActive ] = useState(false);
  const [ isChangeImageTypeModalActive, setIsChangeImageTypeModalActive ] = useState(false);
  const [ selectedGroup, setSelectedGroup ] = useState('');
  const [ isStarted, setIsStarted ] = useState(true);
  const dispatch = useDispatch();
  const lang = useSelector((store) => store.language.lang);
  const groups = useSelector((store) => store.groups.groups);
  const userId = useSelector((store) => store.user.user.id);
  const user = useSelector((store) => store.user.user);
  const currentRole = useSelector((store) => store.user.currentRole);

  const getTasks = async () => {
    setLoading(true);
    const tasks = await getTasksRequest(location.state.selectedTasks);

    await setTasks(tasks);
  };

  useEffect(() => {
    getTasks()
      .then(() => {
        setLoading(false);
      })
      .catch((err) => {
        // eslint-disable-next-line no-console
        console.error(err);
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    const unstartedTasks = tasks.filter((task) => {
      return task.status !== 'process';
    });

    if (unstartedTasks.length > 0) {
      setIsStarted(false);
    }
  }, [ tasks ]);

  const triggerModal = (modalType) => {
    return async (isActive, taskId) => {
      await setCurrentTask(tasks.find((task) => task.id === taskId));
      // eslint-disable-next-line default-case
      switch (modalType) {
        case 'contour_modal':
          setIsContourLevelModalActive(isActive);
          break;
        case 'color_modal':
          setIsColorLevelModalActive(isActive);
          break;
        case 'tags_modal':
          setIsTagsModalActive(isActive);
          break;
        case 'image_type_modal':
          setIsChangeImageTypeModalActive(isActive);
          break;
      }
    };
  };

  const onContourComplexityChange = async (data) => {
    setLoading(true);

    const { complexityLevel, project, type, price } = data;
    const url = `projects/${project.id}/complexity-level`;

    axiosApiInstance.put(url, {
      complexity_level: complexityLevel,
      type,
      price,
    })
      .then(() => {
        const newTasks = tasks.map((task) => {
          if (task.id === currentTask.id) {
            return {
              ...task,
              project: {
                ...task.project,
                options: {
                  ...task.project.options,
                  [type]: complexityLevel,
                  [type === 'contour_complexity_level' ? 'artist_price' : 'designer_price']: price,
                },
              },
            };
          }
          return task;
        });

        setTasks(newTasks);

        dispatch(actionsNotifications.add(
          lang['NOTIFICATION.SUCCESS_PROJECT_COMPLEXITY_LEVEL_CHANGED'],
          NOTIFICATIONS_TYPES.success
        ));
      })
      .catch(() => {
        dispatch(actionsNotifications.add(
          lang['NOTIFICATION.ERROR_PROJECT_COMPLEXITY_LEVEL_CHANGED'],
          NOTIFICATIONS_TYPES.error
        ));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onImageTypeChange = async (projectId, imageType) => {
    setLoading(true);
    const data = {
      image_type: imageType,
    };

    try {
      await axiosApiInstance.put(`projects/${projectId}/image-type`, data);
      dispatch(actionsNotifications.add(
        lang['NOTIFICATION.SUCCESS_PROJECT_EDIT_IMAGE_CATEGORY'],
        NOTIFICATIONS_TYPES.success
      ));

      const newTasks = tasks.map((task) => {
        if (task.id === currentTask.id) {
          return {
            ...task,
            image_type: imageType,
            project: {
              ...task.project,
              options: { ...task.project.options, type: imageType },
            },
          };
        }
        return task;
      });

      setTasks(newTasks);
      setIsChangeImageTypeModalActive(false);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(actionsNotifications.add(
        lang['NOTIFICATION.ERROR_PROJECT_EDIT_IMAGE_CATEGORY'],
        NOTIFICATIONS_TYPES.error
      ));
      setIsChangeImageTypeModalActive(false);
      setLoading(false);
    }
  };

  const setTags = (tags) => {
    const updatedTask = {
      ...currentTask,
      project: {
        ...currentTask.project,
        tags,
      },
    };

    const newTasks = tasks.map((task) => {
      if (task.id === currentTask.id) {
        return updatedTask;
      }
      return task;
    });

    setCurrentTask(updatedTask);
    setTasks(newTasks);
  };

  const onGroupChanged = (value) => {
    setSelectedGroup(value);
  };

  const setGroupForSelectedTasks = async () => {
    setLoading(true);
    try {
      await axiosApiInstance.put('/tasks/group', {
        tasks_ids: tasks.map((task) => task.id),
        group_id: selectedGroup,
      });
      dispatch(actionsNotifications.add(
        lang['NOTIFICATION.SUCCESS_ASSIGN_MULTIPLE_TASKS_FOR_GROUP'],
        NOTIFICATIONS_TYPES.success
      ));
      setSelectedGroup('');
      setLoading(false);
      history.goBack();
    } catch (error) {
      dispatch(actionsNotifications.add(
        lang['NOTIFICATION.ERROR_ASSIGN_MULTIPLE_TASKS_FOR_GROUP'],
        NOTIFICATIONS_TYPES.error
      ));
      setSelectedGroup('');
      setLoading(false);
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const updateComment = async (commentId, comment, updated_at) => {
    const data = { comment, updated_at };

    try {
      await axiosApiInstance.put(`comments/${commentId}`, data);
      const updatedTask = {
        ...currentTask,
        project: {
          ...currentTask.project,
          comments: currentTask.project.comments.map((item) => {
            if (item.id === commentId) {
              return { ...item, comment, updated_at };
            }

            return item;
          }),
        },
      };

      const newTasks = tasks.map((task) => {
        if (task.id === currentTask.id) {
          return updatedTask;
        }
        return task;
      });

      setTasks(newTasks);
      setCurrentTask(updatedTask);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      this.props.notification('NOTIFICATION.ERROR_UPDATE_COMMENT', 'error');
    }
  };

  const deleteComment = async (commentId) => {
    try {
      await axiosApiInstance.delete(`comments/${commentId}`, {});
      const updatedTask = {
        ...currentTask,
        project: {
          ...currentTask.project,
          comments: currentTask.project.comments.map((item) => {
            if (item.id === commentId) {
              return { ...item, deleted_at: new Date() };
            }

            return item;
          }),
        },
      };

      const newTasks = tasks.map((task) => {
        if (task.id === currentTask.id) {
          return updatedTask;
        }
        return task;
      });

      setTasks(newTasks);
      setCurrentTask(updatedTask);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
      this.props.notification('NOTIFICATION.ERROR_DELETE_COMMENT', 'error');
    }
  };

  const addComment = async (comment) => {
    const data = {
      task_id: currentTask.id,
      project_id: currentTask.project_id,
      current_role: currentRole,
      comment,
    };

    try {
      const newComment = await axiosApiInstance.post('comments', data);
      const updatedTask = {
        ...currentTask,
        project: {
          ...currentTask.project,
          comments: [
            ...currentTask.project.comments,
            {
              id: newComment.data.id,
              user_id: userId,
              project_id: currentTask.project_id,
              task_id: currentTask.id,
              comment,
              created_at: new Date(),
              updated_at: null,
              user: {
                username: user.username,
                avatar: user.avatar,
              },
            },
          ],
        },
      };

      const newTasks = tasks.map((task) => {
        if (task.id === currentTask.id) {
          return updatedTask;
        }
        return task;
      });

      setTasks(newTasks);
      setCurrentTask(updatedTask);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.props.notification('NOTIFICATION.ERROR_SEND_COMMENT', 'error');
    }
  };

  const openChat = async (isActive, taskId) => {
    await setCurrentTask(tasks.find((task) => task.id === taskId));
    setShowChat(isActive);
  };

  const removeTask = (taskId) => {
    const filteredTasks = tasks.filter((task) => task.id !== taskId);

    if (filteredTasks.length === 0) {
      history.goBack();
    }
    setTasks(filteredTasks);
  };

  const startTasksWithoutExecutor = async () => {
    setLoading(true);
    const unstartedTasks = tasks.filter((task) => task.status !== 'process');

    try {
      if (unstartedTasks.length > 0) {
        await startTasks(unstartedTasks);
        await getTasks();
      }
      dispatch(actionsNotifications.add(
        lang['NOTIFICATION.SUCCESS_TASKS_START'],
        NOTIFICATIONS_TYPES.success
      ));
      setIsStarted(true);
      setLoading(false);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(actionsNotifications.add(
        lang['NOTIFICATION.ERROR_TASK_START'],
        NOTIFICATIONS_TYPES.error
      ));
      setLoading(false);
    }
  };

  return (<>
    {loading ? <Preload /> : <>
      <TasksGroupChooseSubheader
        selectedGroup={selectedGroup}
        groups={groups}
        onGroupChanged={onGroupChanged}
        applyGroup={setGroupForSelectedTasks}
        startTasks={startTasksWithoutExecutor}
        isStarted={isStarted}
      />
      <div>
        {tasks.map((task) => {
          return (
            <TaskInfo
              key={uuidv4()}
              task={task}
              showTagsModal={triggerModal('tags_modal')}
              showImageTypeModal={triggerModal('image_type_modal')}
              showContourComplexityLevelModal={triggerModal('contour_modal')}
              showColorComplexityLevelModal={triggerModal('color_modal')}
              userId={userId}
              openChat={openChat}
              removeTask={removeTask}
              isStarted={isStarted}
            />);
        })}

        <SidebarChat
          show={showChat}
          comments={currentTask && currentTask.project ? currentTask.project.comments : []}
          userId={userId}
          toggleChat={setShowChat}
          addComment={addComment}
          updateComment={updateComment}
          deleteComment={deleteComment}
        />

        <SetTagsModal
          userId={userId}
          setTags={setTags}
          showModal={triggerModal('tags_modal')}
          isActive={isTagsModalActive}
          task={currentTask}
        />
        <ChangeImageTypeModal
          isActive={isChangeImageTypeModalActive}
          onConfirm={onImageTypeChange}
          showModal={triggerModal('image_type_modal')}
          task={currentTask}
        />
        <SetComplexityLevelModal
          isActive={isContourLevelModalActive}
          onConfirm={onContourComplexityChange}
          showModal={triggerModal('contour_modal')}
          task={currentTask}
          type='contour'
        />
        <SetComplexityLevelModal
          isActive={isColorLevelModalActive}
          onConfirm={onContourComplexityChange}
          showModal={triggerModal('color_modal')}
          task={currentTask}
          type='color'
        />
      </div>
    </>}
  </>);
};

TasksChooseGroup.propTypes = {
  location: PropsTypes.object.isRequired,
  history: PropsTypes.object.isRequired,
};

export default TasksChooseGroup;
