import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import imgURL from '../../utils/imgURL';
import typesUsers from '../customers/typesUsers';
import Modal from '../../widgets/Modal';
import Preload from '../../widgets/Preload';
import Img from '../../widgets/Img';
import { getGroup } from '../../requests/groups';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import { isEmptyObject } from '../../utils/checker';
import urlPageGroups from '../../urls/urlPageGroups';
import urlPageGroupEdit from '../../urls/urlPageGroupEdit';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';
import actions from '../../store/groups/actions';
import GroupCustomerItm from './GroupCustomerItem';



const propTypes = {
  lang: PropTypes.object.isRequired,
  match: PropTypes.any,
  history: PropTypes.any,
  deleteGroup: PropTypes.func,
  users: PropTypes.any,
};
/**
 * Component for group info page
 */

class GroupInfo extends React.Component {
  state = {
    group: {},
    showModalRemove: false,
    LOAD: true,
  };

  render () {
    const { group } = this.state;
    const { lang } = this.props;

    const editor = group && group.users && group.users.find((user) => user.type === typesUsers.editor.key) || {};

    return this.state.LOAD ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={group.name || lang['GROUP.VIEW']}
          subtitle={group.title}
          info={`${lang['GROUP.TOTAL']}: ${group.users ? group.users.length - 1 : 0}`}
          toolbar={<>
            <Button
              className="btn btn-bold btn-danger"
              onClick={() => {
                this.setState({
                  showModalRemove: true,
                });
              }}
            >
              {lang['GLOBAL.REMOVE']}
            </Button>
            <Button
              as={Link}
              to={urlPageGroupEdit({ groupId: this.props.match.params.id })}
              className="btn btn-bold btn-default"
            >
              {lang['GLOBAL.EDIT']}
            </Button>
          </>}
        />
        <div className='row'>
          <div className='col-12 d-flex'>
            <div className="kt-portlet">
              <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-4">
                  <div className="kt-widget__head">
                    <div className="kt-widget__media">
                      <Img className="kt-widget__img" src={imgURL(editor.avatar)} />
                    </div>
                    <div className="kt-widget__content">
                      <div className="kt-widget__section">
                        <Link
                          to={urlPageFreelancerDetails({ freelancerId: editor.id })}
                          className="kt-widget__username"
                        >
                          {editor.username}
                        </Link>
                        <div className="kt-widget__button">
                          <span
                            className="btn btn-label-warning"
                          >{lang['CUSTOMER.TYPE.editor']}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-12 d-flex' style={{ marginBottom: 20 }}>
            <h3 style={{ marginBottom: 0 }}>{lang['GROUP.DESIGNER']}</h3>
            <hr className='w-100' style={{ marginLeft: 10 }} />
          </div>
          {group.users && group.users.map((user) => {
            if (user.type === typesUsers.designer.key) {
              return (
                <GroupCustomerItm key={user.id} customer={user} lang={lang} />
              );
            }
            return true;
          })}
          <div className='col-12 d-flex' style={{ marginBottom: 20 }}>
            <h3 style={{ marginBottom: 0 }}>{lang['GROUP.ARTIST']}</h3>
            <hr className='w-100' style={{ marginLeft: 10 }} />
          </div>
          {group.users && group.users.map((user) => {
            if (user.type === typesUsers.artist.key) {
              return (
                <GroupCustomerItm key={user.id} customer={user} lang={lang} />
              );
            }
            return true;
          })}
        </div>
        <Modal
          title={lang['GROUP.YOU_SURE_REMOVE']}
          show={this.state.showModalRemove}
          close={() => {
            this.setState({
              showModalRemove: false,
            });
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({
                    showModalRemove: false,
                  });
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  this.deleteGroup();
                }}
              >{lang['GLOBAL.REMOVE']}</button>
            </>
          }
        />
      </>
    );
  }

  componentDidMount () {
    if (!isEmptyObject(this.props.users)) {
      this.getGroupInfo();
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.users.length < this.props.users.length) {
      this.getGroupInfo();
    }
  }

  getGroupInfo = async () => {
    this.setState({
      LOAD: true,
    });

    await getGroup(this.props.match.params.id).then((response) => {
      this.setState({
        group: response,
        LOAD: false,
      });
    });
  };

  deleteGroup = async () => {
    try {
      this.setState({
        LOAD: true,
      });

      await this.props.deleteGroup(this.props.match.params.id);
      this.props.history.push(urlPageGroups());
    } catch {
      this.setState({
        LOAD: false,
      });
    }
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    users: store.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    deleteGroup: bindActionCreators(actions.deleteGroup, dispatch),
  };
};

GroupInfo.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(GroupInfo);
