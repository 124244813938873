import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { getGroupUserByRoles } from '../../pages/customers/roles/groupsUsers';
import { forgotLastLocation } from '../../router/RouterHelpers';
import groupsRoles from '../../pages/customers/roles/groupsRoles';
import actions from './actions';

/**
 * Default authorization state
 *
 * @type {{isRemember: boolean, roles: [], authToken: null, groups: [], user: {personal_info: {}, finance_info: {}, document: {}}}}
 */
const initialState = {
  loading: false,
  roles: [],
  multipleRoles: [],
  currentRole: null,
  authToken: null,
  isRemember: true,
  groups: [],
  user: {
    personal_info: {},
    document: {},
    finance_info: {},
  },
};

/**
 * The variable determining whether the "Remember ьe" option was selected during user authorization
 *
 * @type {number|boolean}
 */
let isSession = Number(sessionStorage.getItem('isSession'));

/**
 * Reducer for data a user
 *
 * @type {Reducer<{}, {type: *, payload: *}>}
 */
export const userReducer = persistReducer(
  {
    storage,
    key: 'customer',
    whitelist: [ 'roles', 'authToken', 'groups', 'isRemember' ],
  },
  (state = { ...initialState }, action) => {
    if (!state.isRemember && !isSession) {
      isSession = true;
      return state;
    }
    switch (action.type) {
      case actions.LOADING: {
        return {
          ...state,
          loading: action.payload,
        };
      }
      case actions.LOGIN: {
        const { authToken, isRemember = false, roles, expiresAt } = action.payload;

        sessionStorage.setItem('isSession', '1');
        localStorage.setItem('selected_role', roles[roles.length - 1]);
        localStorage.setItem('tokenExpiresAt', expiresAt);
        localStorage.setItem('authToken', authToken);
        isSession = true;

        return {
          ...state,
          authToken,
          groups: getGroupUserByRoles(roles),
          roles,
          multipleRoles: roles.filter((item) => item.includes('coloring')),
          isRemember,
        };
      }

      case actions.LOGOUT: {
        storage.removeItem('persist:customer');
        storage.removeItem('selected_role');
        storage.removeItem('tokenExpiresAt');
        storage.removeItem('authToken');
        // localStorage.clear();
        forgotLastLocation();
        return {
          loading: false,
          roles: [],
          multipleRoles: [],
          currentRole: null,
          authToken: null,
          isRemember: true,
          groups: [],
          user: {
            personal_info: {},
            document: {},
            finance_info: {},
          },
        };
      }

      case actions.SET_ROLES: {
        const roles = action.payload;
        const groups = getGroupUserByRoles(roles);

        let storedRole = localStorage.getItem('selected_role');
        const preparedMultiRole = roles.filter((item) => groupsRoles.executors.withoutTesters.includes(item));

        if (!storedRole && localStorage.getItem('test_mode_params')) {
          localStorage.setItem('selected_role', roles[roles.length - 1]);
          storedRole = localStorage.getItem('selected_role');
        }
        return {
          ...state,
          groups,
          roles,
          multipleRoles: preparedMultiRole,
          currentRole: storedRole || preparedMultiRole[0],
        };
      }

      case actions.SET_CURRENT_ROLE: {
        const currentRole = action.payload;

        localStorage.setItem('selected_role', currentRole);

        return {
          ...state,
          currentRole,
          roles: [ 'user', 'freelancer', currentRole ],
        };
      }

      case actions.SET_USER: {
        return {
          ...state,
          user: {
            ...state.user,
            ...action.payload,
          },
        };
      }

      case actions.READ_NOTIFICATIONS: {
        return {
          ...state,
          user: {
            ...state.user,
            notifications: state.user.notifications.map((notification) => {
              if (action.payload.includes(notification.id)) {
                notification.is_read = true;
              }
              return notification;
            }),
          },
        };
      }

      case actions.ADD_NOTIFICATION: {
        return {
          ...state,
          user: {
            ...state.user,
            notifications: [ action.payload, ...state.user.notifications ],
          },
        };
      }

      default:
        return state;
    }
  },
);

