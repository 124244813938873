import Table from 'react-bootstrap/Table';
import React from 'react';
import { parseToPars } from '../../../utils/parseUrlParams';
import roles from '../../customers/roles/roles';
import { tasksTypes } from '../../../store/tasks';
import TaskHeaderTable from './TaskHeaderTable';
import TaskItemTable from './TaskItemTable';
import TaskItemCard from './TaskItemCard';

/**
 * Builds a list of projects for a page with a list of projects
 *
 * @this TasksList
 * @returns {*}
 */
export default function TasksTable () {
  const { filter, isMobile, taskViewTable } = this.state;

  const {
    listTasks,
    listPreview,
    isColoring,
    canSelected,
    isChecked,
    showTaskDeadline,
    showProjectDeadline,
    isContentManager,
    showPrevExecutor,
  } = this.computed;

  const type = this.props.match.params.type;
  const status = this.props.match.params.status;
  const selectedTasksLimit = this.state.selectedTasks.length <= 29;
  const firstTask = this.state.selectedTasks.length > 0 ? this.props.tasks[type].find((task) => task.id === this.state.selectedTasks[0]) : null;
  const isFinishedTask = window.location.pathname.includes('finished');
  const isReopened = window.location.pathname.includes(tasksTypes.reopened);

  const isSameGroup = (task) => {
    if (!firstTask || this.props.currentRole === roles['content-manager'].key || this.props.location.pathname.includes('group_choosing')) {
      return true;
    }

    return task.chosen_group === firstTask.chosen_group;
  };

  return taskViewTable && !isMobile ? (
    <div className='kt-portlet mw-100 overflow-auto table-wrapper'>
      <Table
        striped
        className='vertical-align-middle text-center kt-font-md'
        size="sm"
      >
        <TaskHeaderTable
          canSort
          sort={filter.sort}
          currentPath={type}
          setSort={(sort) => {
            this.setState({
              filter: {
                ...this.state.filter,
                sort,
              },
            }, () => {
              const pars = parseToPars({ ...this.state.filter, show: undefined });

              this.props.history.push(this.props.history.location.pathname + pars);
            });
          }}
          isColoring={isColoring}
          canSelected={canSelected}
          isChecked={isChecked}
          isFinishedTask={isFinishedTask}
          showPrevExecutor={showPrevExecutor}
          showTaskDeadline={showTaskDeadline}
          showProjectDeadline={showProjectDeadline}
          isContentManager={isContentManager}
          showExecutor={!!((status || type === 'available')) || (isContentManager && isReopened)}
        />
        <tbody>
          {listTasks.map((task) => {
            const selected = canSelected && this.state.selectedTasks.findIndex((id) => id === task.id) !== -1;

            return (
              <TaskItemTable
                key={task.id}
                task={task}
                listPreview={listPreview}
                isColoring={isColoring}
                isChecked={isChecked}
                showPrevExecutor={showPrevExecutor}
                showTaskDeadline={showTaskDeadline}
                showProjectDeadline={showProjectDeadline}
                showExecutor={!!(status || type === 'available') || (isContentManager && isReopened)}
                canSelected={canSelected}
                isFinishedTask={isFinishedTask}
                disableCheckbox={selectedTasksLimit && isSameGroup(task)}
                selected={selected}
                setModalCategories={this._setModalCategories}
                setModalTags={this._setModalTags}
                showChangeImageTypeModal={this._showChangeImageTypeModal}
                setTags={this._setTags}
                setProjectId={this._setProjectId}
                canChangeCategory={this.state.canChangeCategory}
                setUpdatedTask={this._setUpdatedTask}
                setCategoryId={this._setCategoryId}
                isContentManager={isContentManager}
                onSelect={canSelected && ((selectedTask) => {
                  if (selected) {
                    this.setState({
                      selectedTasks: this.state.selectedTasks.filter((id) => id !== task.id),
                      selectedProjects: this.state.selectedProjects.filter((id) => id !== task.project_id),
                    });
                  } else {
                    this.setState({
                      selectedTasks: [ ...this.state.selectedTasks, selectedTask.id ],
                      selectedProjects: [ ...this.state.selectedProjects, selectedTask.project_id ],
                    });
                  }
                })}
                startTask={this._startTask}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  ) : (
    <div className={listTasks.length === 1 ? 'col-12' : 'row'}>
      {listTasks.map((task) => {
        const selected = canSelected && this.state.selectedTasks.findIndex((id) => id === task.id) !== -1;

        return (
          <TaskItemCard
            key={task.id}
            task={task}
            listPreview={listPreview}
            isChecked={isChecked}
            showPrevExecutor={showPrevExecutor}
            showProjectDeadline={showProjectDeadline}
            showExecutor={!!this.props.match.params.status}
            canSelected={canSelected}
            selected={selected}
            onSelect={canSelected && ((selectedTask) => {
              if (selected) {
                this.setState({
                  selectedTasks: this.state.selectedTasks.filter((id) => id !== task.id),
                  selectedProjects: this.state.selectedProjects.filter((id) => id !== task.project_id),
                });
              } else {
                this.setState({
                  selectedTasks: [ ...this.state.selectedTasks, selectedTask.id ],
                  selectedProjects: [ ...this.state.selectedProjects, selectedTask.project_id ],
                });
              }
            })}
          />
        );
      })}
    </div>
  );
}
