export const SET_USERS = 'SET_USERS';

export const setUsersAction = (users) => ({
  type: SET_USERS,
  payload: users,
});

const initialState = [];

export const usersReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS: {
      return [ ...action.payload ];
    }
    default: {
      return state;
    }
  }
};
