import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toMonthEnd } from '../../../utils/toMonthEnd';
import { isFabros } from '../../../utils/isFabros';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const BillingStatistics = ({ balance, finishedTasks, payableAmount }) => {
  const { lang, userRoles } = useSelector(({ language, user }) => ({
    lang: language.lang,
    userRoles: user.roles,
  }), shallowEqual);

  const isTester = checkRole(userRoles, [ roles['coloring-tester'].key ]);

  const fabrosMarkup = (
    <div className="kt-portlet">
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="row row-no-padding row-col-separator-lg">
          <div className="col-12 col-xl-4">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <h4 className="kt-widget24__title no-hover font-weight-bold">
                    {lang['BILLING.CUR_FINANCE_PERIOD']}
                  </h4>
                  <span className="kt-widget24__desc">
                    {lang['BILLING.COUNT_DAYS_TO_AND']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-brand">
                  {`${toMonthEnd()} ${lang['BILLING.DAYS']}`}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-xl-4">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <h4 className="kt-widget24__title no-hover font-weight-bold">
                    {lang['MENU.TASKS']}
                  </h4>
                  <span className="kt-widget24__desc">
                    {isTester ? lang['BILLING.COUNT_TASK_TESTER'] : lang['BILLING.COUNT_TASK']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-warning">
                  {finishedTasks}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-xl-4">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <h4 className="kt-widget24__title no-hover font-weight-bold">
                    {lang['CUSTOMER.BALANCE']}
                  </h4>
                  <span className="kt-widget24__desc">
                    {lang['BILLING.TOTAL_BALANCE']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-success">
                  {balance}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  const xflowMarkup = (
    <div className="kt-portlet">
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div className="row row-no-padding row-col-separator-lg">
          <div className="col-12 col-lg-6 col-xl-3">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <span className="kt-widget24__desc">
                    {lang['BILLING.DAYS_TO_END_OF_PERIOD']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-success">
                  {`${toMonthEnd()} ${lang['BILLING.DAYS']}`}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-3">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <span className="kt-widget24__desc">
                    {isTester ? lang['BILLING.COUNT_TASK_TESTER'] : lang['BILLING.CHECKED_TASKS']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-success">
                  {finishedTasks}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-3">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <span className="kt-widget24__desc">
                    {lang['BILLING.BALANCE_OF_PERIOD']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-success">
                  {balance}
                </span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 col-xl-3">
            <div className="kt-widget24">
              <div className="kt-widget24__details">
                <div className="kt-widget24__info">
                  <span className="kt-widget24__desc">
                    {lang['BILLING.PAYOUT_AMOUNT']}
                  </span>
                </div>
                <span className="kt-widget24__stats kt-font-success">
                  {payableAmount.toFixed(2)}€
                </span>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );

  return isFabros ? fabrosMarkup : xflowMarkup;
};

export default BillingStatistics;
