import { mapCategoryIdToName } from '../requests/categories';
import { formatToPrettyDate } from './formats';



export const getTitle = (key, lang) => {
  let title;

  switch (key) {
    case 'project': {
      title = 'ID';
      break;
    }
    case 'created': {
      title = lang['GLOBAL.DATE_CREATE'];
      break;
    }
    case 'title': {
      title = lang['GLOBAL.TITLE'];
      break;
    }
    case 'deadline': {
      title = lang['GLOBAL.DEADLINE'];
      break;
    }
    case 'taps': {
      title = lang['PROJECT.TAPS_COUNT'];
      break;
    }
    case 'type': {
      title = lang['TASK.STATUS'];
      break;
    }
    case 'tags': {
      title = lang['MENU.TAGS'];
      break;
    }
    case 'image_type': {
      title = lang['PROJECT.FIELDS.type'];
      break;
    }
    case 'priority': {
      title = lang['GLOBAL.PRIORITY'];
      break;
    }
    case 'executor': {
      title = lang['GLOBAL.EXECUTOR'];
      break;
    }
    case 'prev_executor': {
      title = lang['GLOBAL.EXECUTOR'];
      break;
    }
    case 'role': {
      title = lang.ROLE;
      break;
    }
    case 'country': {
      title = lang['CUSTOMER.COUNTRY'];
      break;
    }
    case 'inactive': {
      title = lang['GLOBAL.STATUS'];
      break;
    }
    case 'name': {
      title = lang['CUSTOMER.NAME'];
      break;
    }
    case 'finished_at': {
      title = lang['GLOBAL.FINISHED_AT'];
      break;
    }
    case 'category_id': {
      title = lang['GLOBAL.CATEGORY'];
      break;
    }
    case 'project_hc_release_date': {
      title = lang['PROJECT.FIELDS.hc_release_date'];
      break;
    }
    case 'project_hc_content_type': {
      title = lang['PROJECT.FIELDS.hc_content_type'];
      break;
    }
    default: {
      title = key;
    }
  }

  return title;
};

export const getValue = (key, filter, lang, options, categories) => {
  let value;

  switch (key) {
    case 'created':
    case 'deadline':
    case 'project_hc_release_date': {
      if (filter.from && filter.to) {
        value = `${formatToPrettyDate(filter.from)} - ${formatToPrettyDate(filter.to)}`;
      } else if (filter.from) {
        value = `${lang['GLOBAL.FROM']} ${formatToPrettyDate(filter.from)}`;
      } else if (filter.to) {
        value = `${lang['GLOBAL.TO']} ${formatToPrettyDate(filter.to)}`;
      }
      break;
    }
    case 'finished_at': {
      if (filter.from && filter.to) {
        value = `${formatToPrettyDate(filter.from)} - ${formatToPrettyDate(filter.to)}`;
      } else if (filter.from) {
        value = `${lang['GLOBAL.FROM']} ${formatToPrettyDate(filter.from)}`;
      } else if (filter.to) {
        value = `${lang['GLOBAL.TO']} ${formatToPrettyDate(filter.to)}`;
      }
      break;
    }
    case 'taps': {
      if (filter.to === 10000) {
        value = `${filter.from} ${lang['GLOBAL.AND_MORE']}`;
      } else {
        value = `${filter.from} - ${filter.to}`;
      }
      break;
    }
    case 'type': {
      value = lang[`TASK.TYPES.${filter}`];
      break;
    }
    case 'priority': {
      value = lang[`GLOBAL.PRIORITY.${filter}`];
      break;
    }
    case 'category_id': {
      value = categories ? mapCategoryIdToName(categories, String(filter)) : String(filter);
      break;
    }
    case 'executor': {
      value = options.executor ? options.executor?.username : '';
      break;
    }
    case 'prev_executor': {
      value = options.prev_executor ? options.prev_executor?.username : '';
      break;
    }
    case 'country': {
      value = options.country ? options.country : '';
      break;
    }
    case 'inactive': {
      value = filter ? lang['CUSTOMER.BLOCKED'] : lang['CUSTOMER.ACTIVES'];
      break;
    }
    case 'tags_id': {
      value = '';
      break;
    }
    case 'project_hc_content_type': {
      value = lang[`PROJECT.FIELDS.hc_content_type.${filter}`];
      break;
    }
    default: {
      value = filter;
    }
  }

  return value;
};
