import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { findLastIndex, orderBy } from 'lodash';
import PropTypes from 'prop-types';
import { formatToPrettyDate } from '../../utils/formats';
import { status } from '../../utils/statusToColor';
import getRandomColor from '../../utils/randomColor';
import axiosApiInstance from '../../requests/utils/api';
import checkRole from '../../utils/checkRole';
import Preload from '../../widgets/Preload';
import imgURL from '../../utils/imgURL';
import Tabs from '../../widgets/Tabs';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';
import roles from './roles/roles';



const propTypes = {
  lang: PropTypes.object.isRequired,
  categories: PropTypes.any,
};

const defaultProps = {
  categories: [],
};

/**
 * Component for dashboard manager page
 *
 * @returns {*}
 */
class ContentManagerDashboard extends React.Component {
  state = {
    LOAD: true,
    new_users: [],
    designers: [],
    artists: [],
    testers: [],
    active_projects: [],
    tasksPercentage: [],
    skip: 0,
    total: 0,
    sortsProjects: {},
    countOnPage: 20,
  };

  render () {
    const { lang } = this.props;

    return (this.state.LOAD) ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={lang['MENU.DASHBOARD']}
        />
        <div className="row">
          <div className="col-xl-12 order-lg-1 order-xl-1">
            <Tabs
              fluid
              title={lang['DASHBOARD.NEW_USERS']}
              tabs={[
                this.state.designers.length > 0 ? {
                  title: lang['GROUP.DESIGNER'],
                  body: () => {
                    return (
                      <div className="kt-widget4">
                        {this.state.designers.map((user) => {
                          return (
                            <div key={user.id} className="kt-widget4__item">
                              <div className="kt-widget4__pic kt-widget4__pic--pic">
                                <img src={imgURL(user.avatar)} alt='' />
                              </div>
                              <div className="kt-widget4__info">
                                <button className="kt-widget4-bnt__username">
                                  {user.username}
                                </button>
                                <p className="kt-widget4__text">
                                  {`${lang['CUSTOMER.REGISTRATION']} ${formatToPrettyDate(user.created_at)}`}
                                </p>
                              </div>
                              <Link
                                to={urlPageFreelancerDetails({ freelancerId: user.id })}
                                className="btn btn-sm btn-label-info"
                              >{lang['GLOBAL.INSPECT']}</Link>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                } : null,
                this.state.artists.length > 0 ? {
                  title: lang['GROUP.ARTIST'],
                  body: () => {
                    return (
                      <div className="kt-widget4">
                        {this.state.artists.map((user) => {
                          return (
                            <div key={user.id} className="kt-widget4__item">
                              <div className="kt-widget4__pic kt-widget4__pic--pic">
                                <img src={imgURL(user.avatar)} alt='' />
                              </div>
                              <div className="kt-widget4__info">
                                <button className="kt-widget4-bnt__username">
                                  {user.username}
                                </button>
                                <p className="kt-widget4__text">
                                  {`${lang['CUSTOMER.REGISTRATION']} ${formatToPrettyDate(user.created_at)}`}
                                </p>
                              </div>
                              <Link
                                to={urlPageFreelancerDetails({ freelancerId: user.id })}
                                className="btn btn-sm btn-label-info"
                              >{lang['GLOBAL.INSPECT']}</Link>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                } : null,
                this.state.testers.length > 0 ? {
                  title: lang['GROUP.TESTER'],
                  body: () => {
                    return (
                      <div className="kt-widget4">
                        {this.state.testers.map((user) => {
                          return (
                            <div key={user.id} className="kt-widget4__item">
                              <div className="kt-widget4__pic kt-widget4__pic--pic">
                                <img src={imgURL(user.avatar)} alt='' />
                              </div>
                              <div className="kt-widget4__info">
                                <button className="kt-widget4-bnt__username">
                                  {user.username}
                                </button>
                                <p className="kt-widget4__text">
                                  {`${lang['CUSTOMER.REGISTRATION']} ${formatToPrettyDate(user.created_at)}`}
                                </p>
                              </div>
                              <Link
                                to={urlPageFreelancerDetails({ freelancerId: user.id })}
                                className="btn btn-sm btn-label-info"
                              >{lang['GLOBAL.INSPECT']}</Link>
                            </div>
                          );
                        })}
                      </div>
                    );
                  },
                } : null,
              ].filter((item) => item)}
            />
          </div>

        </div>
      </>);
  }

  componentDidMount () {
    this.getData();
  }

  getData = () => {
    this.setState({
      LOAD: true,
    });

    axiosApiInstance.get('dashboards', {})
      .then((response) => {
        const msg = response.data;

        if (msg) {
          const newUsers = msg.new_users || [];
          const tasksPercentage = [];

          Object.keys(msg.statistics.tasks_percentage).forEach((key) => {
            const total = msg.statistics.tasks_percentage[key].process + msg.statistics.tasks_percentage[key].ready;

            tasksPercentage.push({
              key,
              process: msg.statistics.tasks_percentage[key].process,
              ready: msg.statistics.tasks_percentage[key].ready,
              total,
              percentage_process: ((100 * msg.statistics.tasks_percentage[key].process) / total).toFixed(1),
              percentage_ready: ((100 * msg.statistics.tasks_percentage[key].ready) / total).toFixed(1),
              color: getRandomColor(),
            });
          });

          this.setState({
            LOAD: false,
            new_users: newUsers,
            designers: newUsers.filter((user) => checkRole(user.roles, [ roles['coloring-designer'].key ])),
            artists: newUsers.filter((user) => checkRole(user.roles, [ roles['coloring-artist'].key ])),
            testers: newUsers.filter((user) => checkRole(user.roles, [ roles['coloring-tester'].key ])),
            active_projects: msg.active_projects,
            tasksPercentage,
            total: msg.active_projects.length,
            skip: 0,
          }, async () => {
            const tasks = this.state.active_projects.map((project) => project.tasks || []);

            this.setState({
              active_projects: this.state.active_projects.map((project, index) => {
                const indexActiveTask = findLastIndex(tasks[index], (task) => task.status === status.finished) + 1;

                const activeTask = indexActiveTask >= 0 && tasks[index][indexActiveTask];

                const personalDeadline = activeTask ? activeTask.deadline_to : null;

                return {
                  ...project,
                  tasks: tasks[index],
                  personal_deadline: personalDeadline,
                };
              }),
            });
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            LOAD: false,
          });
        }
      });
  };

  getList = () => {
    const sort = this.state.sortsProjects;

    if (sort && Object.keys(sort).length > 0) {
      const keys = Object.keys(sort);
      const iterable = [ ...keys.map((key) => {
        if (key === 'image_type') {
          return 'options.type';
        }
        if (key === 'taps') {
          return 'options.taps';
        }
        return key;
      }) ];

      return orderBy(
        this.state.active_projects,
        iterable,
        [ ...keys.map((key) => sort[key]), 'desc' ]
      ).slice(this.state.skip, this.state.skip + this.state.countOnPage);
    }
    return this.state.active_projects.slice(this.state.skip, this.state.skip + this.state.countOnPage);
  };
}

const mapStateToProps = (store) => {
  return {
    user: store.user.user,
    roles: store.user.roles,
    lang: store.language.lang,
    authToken: store.user.authToken,
    shape2Color: store.builder.layoutConfig.colors.base.shape[2],
    shape3Color: store.builder.layoutConfig.colors.base.shape[3],
    categories: store.categories,
  };
};

ContentManagerDashboard.propTypes = propTypes;
ContentManagerDashboard.defaultProps = defaultProps;

export default connect(mapStateToProps)(ContentManagerDashboard);
