import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';



const GroupsTableHeader = ({
  sort,
  onChangeSort,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const headerItems = useMemo(() => [
    {
      id: 1,
      name: 'id',
      width: '5%',
      sortable: true,
      translation: 'ID',
    },
    {
      id: 2,
      name: 'name',
      translation: lang['GLOBAL.NAME'],
      width: '10%',
      sortable: true,
    },
    {
      id: 3,
      name: 'templates',
      translation: lang['TAG_SYSTEM.TEMPLATES.TITLE'],
      width: '10%',
      sortable: false,
    },
    {
      id: 4,
      name: 'description',
      translation: lang['GLOBAL.DESCRIPTION'],
      width: '10%',
      sortable: false,
    },
    {
      id: 5,
      name: 'actions',
      translation: lang['GLOBAL.ACTIONS'],
      width: '6%',
      sortable: false,
    },
  ], [ lang ]);

  return (
    <thead>
      <tr>
        {
          headerItems.map((item, index) => {
            const lastItem = index === headerItems.length - 1;

            return (
              <th
                key={item.id}
                className={`${index === 1 ? 'text-left' : 'text-right'}`}
                style={{ width: item.width }}
              >
                <div
                  role="button"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className={`d-flex ${(index === 0 || lastItem) ? 'justify-content-center' : 'justify-content-start'} align-items-center`}
                  style={{ cursor: item.sortable ? 'pointer' : 'default' }}
                  onClick={() => {
                    if (item.sortable) {
                      onChangeSort('orderBy', item.name);
                    }
                  }}
                >
                  <div className={`d-flex text-center ${(index === 0 || lastItem) ? 'justify-content-center mx-3' : 'justify-content-start mr-3'}`}>
                    <span>{item.translation}</span>
                  </div>
                  {
                    item.sortable && (
                      sort?.sortedBy !== '' && sort?.orderBy === item.name ? (
                        sort?.sortedBy === 'asc' ? <i className='flaticon2-up' /> : <i className='flaticon2-down' />
                      ) : <i className='flaticon2-sort' />
                    )
                  }
                </div>
              </th>
            );
          })
        }
      </tr>
    </thead>
  );
};

GroupsTableHeader.propTypes = {
  sort: PropTypes.any,
  onChangeSort: PropTypes.func,
};

GroupsTableHeader.defaultProps = {
  onChangeSort: ((sort) => sort),
  sort: {},
};
export default GroupsTableHeader;
