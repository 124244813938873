import { entityTypes } from '../../config/config';



export const transformToRequest = (data) => {
  const options = [];
  const children = [];

  Object.entries(data)
    .forEach(([ key, value ]) => {
      if (key.startsWith('global_group_') || key.startsWith('local_group_')) {
        if (value) {
          const parts = key.split('_');

          children.push(Number(parts[2]));
        }
      } else if (key.startsWith('option_')) {
        options.push({
          name: key.replace('option_', ''),
          value,
        });
      }
    });

  const body = {
    name: data.name,
    type: entityTypes.template,
    children,
    parents: [],
    options,
  };

  if (data.id && data.id !== 'new') {
    body.id = Number(data.id);
    body.subtype = data.subtype;
  }

  return body;
};
