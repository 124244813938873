import React from 'react';

/**
 * Builds input for the field and filters its additional fields in order to also build recursively
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {string} prefix
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, value = null, onChange = null, project, prefix, required) {
  return (<React.Fragment key={`${field.key}_wrap` || `${field.keys[0]}_wrap`}>
    {this._switchInput(field, value, onChange, project, prefix, required)}
    {field.addedFields && this._filterAddedFields(field, project).map((field) => {
      return this._buildField(field, null, (val) => {
        onChange(val, field.key);
      }, project, required);
    })}
  </React.Fragment>);
}
