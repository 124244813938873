import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../widgets/Modal';



const SetGroupConfirmModal = ({ title, isActive, toggleModal, onConfirm, lang, body }) => {


  const modalFooter = (<>
    <button
      className="btn btn-secondary"
      onClick={toggleModal}
    >
      {lang['GLOBAL.CANCEL']}
    </button>
    <button
      className="btn btn-primary"
      onClick={onConfirm}
    >
      {lang['GLOBAL.APPLY']}
    </button>
  </>);

  return (
    <Modal
      title={title}
      show={isActive}
      close={toggleModal}
      footer={modalFooter}
    >
      {body}
    </Modal>
  );
};

SetGroupConfirmModal.propTypes = {
  title: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  toggleModal: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  lang: PropTypes.object.isRequired,
  body: PropTypes.element.isRequired,
};

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};


export default connect(mapStoreToProps)(SetGroupConfirmModal);
