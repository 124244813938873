import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component for modals
 *
 * @param {number} offset
 * @returns {*}
 */
class Modal extends React.Component {
  refBody = React.createRef();

  render () {
    const props = {
      footer: null,
      ...this.props,
      className: `modal fade${this.props.show ? ' show' : ''} ${this.props.className ?? ''}`,
      title: null,
      close: null,
      show: null,
      children: null,
      full: null,
      autoheight: null,
      as: null,
    };

    // eslint-disable-next-line fp/no-delete
    delete props.bodyStyle;
    // eslint-disable-next-line fp/no-delete
    delete props.showFooter;

    return React.createElement(
      this.props.as || 'div',
      props,
      <div
        className="modal-dialog modal-dialog-centered"
        style={
          this.props.full
            ? {
              maxWidth: 'calc(100vw - 30px)',
              maxHeight: 'calc(100vh - 30px)',
              width: 'calc(100vw - 30px)',
              height: 'calc(100vh - 30px)',
              overflow: 'hidden',
              margin: 'auto',
            }
            : {
              maxHeight: '95vh',
              width: 'calc(100% - 1rem)',
            }
        }
      >
        <div
          className="modal-content"
          style={{
            maxHeight: '100%',
            ...(this.props.full ? { height: '100%' } : {}),
            ...(this.props.autoheight ? { height: 'initial' } : {}),
          }}
        >
          <div className="modal-header">
            <div>
              <h5 className="modal-title font-weight-bold">{this.props.title}</h5>
              {this.props.subtitle && <h6 className="modal-title">{this.props.subtitle}</h6>}
            </div>
            <button
              type="button" className="close" onClick={() => {
                if (this.props.close) {
                  this.props.close();
                }
              }}
            />
          </div>
          {this.props.show && this.props.children && (
            <div
              className="modal-body"
              style={this.props.bodyStyle || {}}
              ref={this.refBody}
            >
              {this.props.children}
            </div>
          )}
          {this.props.showFooter && (
            <div className="modal-footer">
              {this.props.footer}
            </div>
          )}
        </div>
      </div>
    );
  }

  scrollTo = (offset = 0) => {
    this.refBody.current.scroll({
      top: offset,
    });
  };
}

Modal.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  close: PropTypes.func,
  show: PropTypes.bool,
  children: PropTypes.node,
  footer: PropTypes.node,
  full: PropTypes.bool,
  autoheight: PropTypes.bool,
  showFooter: PropTypes.bool,
  bodyStyle: PropTypes.object,
  as: PropTypes.any,
};

Modal.defaultProps = {
  showFooter: true,
};

export default Modal;
