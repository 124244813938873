import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import GroupEntity from '../../model/GroupEntity';


// eslint-disable-next-line react/display-name
const DeleteHintTooltip = React.forwardRef((props, ref) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);
  const { group } = props;

  if (group.protected) {
    return (
      <Tooltip id={'PROTECTED_GROUP_DELETE'} {...props} ref={ref}>
        {lang['TAG_SYSTEM.GROUPS.PROTECTED_GROUP_DELETE']}
      </Tooltip>
    );
  }

  if (group.hasChildren) {
    return (
      <Tooltip id={'GROUP_WITH_CHILDREN_DELETE'} {...props} ref={ref}>
        {lang['TAG_SYSTEM.GROUPS.GROUP_WITH_CHILDREN_DELETE']}
      </Tooltip>
    );
  }

  return <div />;
});

DeleteHintTooltip.propTypes = {
  group: PropTypes.instanceOf(GroupEntity).isRequired,
};

export default DeleteHintTooltip;
