import { status } from '../../utils/statusToColor';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../customers/roles/groupsRoles';
import taskTypes from './taskTypes';

/**
 * Introduces an additional status "review" for tasks
 *
 * @param {object} task
 * @returns {null|number}
 */
const getStatus = (task) => {
  if (!task) {
    return null;
  }

  let _status = task.status;

  if (_status === status.finished && task.project && task.project.status !== status.finished) {
    if (task.type === taskTypes.artist_drawing.type) {
      const checkTask = task.project.tasks ? task.project.tasks.find((task) => task.type === taskTypes.editor_checking_artist.type) : null;

      if (checkTask && checkTask.status !== status.finished) {
        _status = status.review;
      }
    } else if (
      task.project
            && task.project.status !== status.finished
            && taskTypes[task.type]
            && checkRole([ taskTypes[task.type].role ], groupsRoles.executors.all)
    ) {
      _status = status.review;
    }
  }

  return _status;
};

export default getStatus;
