import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useForm, Controller } from 'react-hook-form';
// eslint-disable-next-line import/no-namespace
import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import userActions from '../../store/user/actions';
import LanguageSelector from '../../../_metronic/partials/layout/LanguageSelector';



const validationSchema = Yup.lazy((values) => {
  return Yup.object().shape({
    username: Yup.string().min(6).max(30).matches(/^[a-zA-Z0-9._-]*$/).required(),
    firstname: Yup.string().matches(/^[a-zA-Z ]*$/).required(),
    password: Yup.string().trim().min(8).required(),
    cpassword: Yup.string().trim().min(8).test(
      'repeat_password',
      'Wrong password',
      (val) => {
        return val === values.password;
      }
    ).required(),
  });
});

const RegistrationEmployee = ({ code }) => {
  const dispatch = useDispatch();
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);
  const { control, formState: { errors }, handleSubmit } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const onSubmit = (data) => {
    dispatch(userActions.registerUser({
      ...data,
      is_employee: true,
      code,
    }));
  };

  return (
    <>
      <div className="kt-header__topbar d-flex justify-content-end">
        <LanguageSelector iconType="" />
      </div>
      <div
        className="kt-container min-vh-100 kt-grid__item kt-grid__item--fluid d-block kt-p-0"
      >
        <div className="d-table-cell vh-100 vertical-align-middle kt-p-20 vw-100">
          <div className="kt-portlet kt-mb-0 kt-ml-a kt-mr-a" style={{ maxWidth: 760 }}>
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <div className="kt-portlet__head-title font-weight-bold">
                  {lang['REGISTRATION.REGISTRATION']}
                </div>
              </div>
            </div>
            <form
              className="kt-portlet__body"
              onSubmit={handleSubmit(onSubmit)}
            >
              <Form.Group className="row kt-mb-0-sm">
                <Form.Label
                  column
                  className="col-sm-3"
                >
                  {lang['REGISTRATION.FIRST_NAME']}
                </Form.Label>
                <div className="col-sm-9">
                  <Controller
                    name="firstname"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.firstname}
                        readOnly
                        onFocus={(event) => {
                          event.target.removeAttribute('readonly');
                        }}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.FIRST_NAME_PLEASE_LATIN']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.FIRST_NAME_ERROR_LATIN']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group className="row kt-mb-0-sm">
                <Form.Label
                  column
                  className="col-sm-3"
                >
                  {lang['REGISTRATION.USERNAME']}
                </Form.Label>
                <div className="col-sm-9">
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        isInvalid={!!errors.username}
                        readOnly
                        onFocus={(event) => {
                          event.target.removeAttribute('readonly');
                        }}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.USERNAME_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.USERNAME_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group className="row kt-mb-0-sm">
                <Form.Label
                  column
                  className="col-sm-3"
                >
                  {lang['REGISTRATION.PASSWORD']}
                </Form.Label>
                <div className="col-sm-9">
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="password"
                        isInvalid={!!errors.password}
                        readOnly
                        onFocus={(event) => {
                          event.target.removeAttribute('readonly');
                        }}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.PASSWORD_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.PASSWORD_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>

              <Form.Group className="row">
                <Form.Label
                  column
                  className="col-sm-3"
                >
                  {lang['REGISTRATION.REPEAT_PASSWORD']}
                </Form.Label>
                <div className="col-sm-9">
                  <Controller
                    name="cpassword"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="password"
                        isInvalid={!!errors.cpassword}
                        readOnly
                        onFocus={(event) => {
                          event.target.removeAttribute('readonly');
                        }}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.REPEAT_PASSWORD_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.REPEAT_PASSWORD_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <div className="d-flex justify-content-center">
                <Button type="submit">{lang['GLOBAL.SUBMIT']}</Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

RegistrationEmployee.propTypes = {
  code: PropTypes.string.isRequired,
};

export default RegistrationEmployee;
