import React from 'react';
import { Form } from 'react-bootstrap';
import { coloringProjectTypes, globalTypesProject, projectTypes, typesFields } from '../projectTypes';
import { intValidator } from '../../../utils/validators';
import { parseFromPars, parseToPars } from '../../../utils/parseUrlParams';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import roles from '../../customers/roles/roles';

/**
 * Builds a step on which the basic information for creating projects is entered.
 *
 * @this ProjectCreate
 * @returns {*}
 */
export default function _stepBodyGeneralSettings () {
  const { lang, cRoles } = this.props;
  const { project } = this.state;
  const projectType = projectTypes.find((type) => type.key === project.project_type);

  return (
    <div className='kt-wizard-v1__content'>
      <div className='kt-section'>
        <div className='kt-wizard-v1__form'>
          <div className='row'>
            <div className='col-12'>
              <Form.Group
                as={Form.Row} style={{
                  maxWidth: 1024,
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  marginBottom: '1rem',
                }}
              >
                <div className='col-12 col-lg-3'>
                  <Form.Label column>
                    {lang['PROJECT.TYPE']}
                    <span style={{ color: 'red' }}>{' *'}</span>
                  </Form.Label>
                </div>
                <div className='col-lg-9 col-12'>
                  <Form.Control
                    as="select"
                    isInvalid={this.state.showInvalid && !project.project_type}
                    value={project.project_type || ''}
                    onChange={(event) => {
                      const pars = parseToPars({
                        ...(parseFromPars(window.location.search) || {}),
                        project_type: event.target.value,
                      });

                      this.props.history.push(this.props.history.location.pathname + pars);

                      const { config } = this.props;
                      const type = config[event.target.value];

                      const prices = {};

                      if (type && type.steps) {
                        Object.values(type.steps).forEach((step, key) => {
                          prices[key] = String(step.price);
                        });
                      }

                      this.setState({
                        project: {
                          ...project,
                          project_type: event.target.value,
                          prices,
                        },
                      });
                    }}
                  >
                    <option disabled value=''>{lang['GLOBAL.CHOOSE']}</option>
                    {[
                      ...(checkRole(cRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.coloring, roles['content-manager'].key, roles['lead-coloring-editor'].key ])
                        ? projectTypes.filter((pr) => {
                          return pr.type === globalTypesProject.COLORING && pr.key !== coloringProjectTypes.COLORING_ARTIST;
                        })
                        : []
                      ),
                    ].map((type) => {
                      return (
                        <option key={type.key} value={type.key}>
                          {lang[`PROJECT.TYPE.${type.key}`]}
                        </option>);
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
            </div>
            {project.project_type && (
              <>
                {project.project_type === 'coloring-any' && this._buildField(
                  {
                    ...projectType.generalSettings.find((field) => field.key === 'source'),
                    addedFields: [],
                  },
                  project.source,
                  (val) => {
                    this.setState({
                      project: {
                        ...project,
                        source: val,
                      },
                    });
                  },
                  null,
                  null,
                  true
                )}
                {this._buildField(
                  {
                    key: 'countProjects',
                    isRequired: true,
                    type: 'text',
                    typeFields: typesFields.input,
                    isFull: false,
                    title: lang['PROJECT.COUNT'],
                    check: (val) => {
                      return val && Number(val) > 0;
                    },
                  },
                  this.state.countProjects,
                  (value) => {
                    const count = intValidator(value);
                    const num = Number(count);

                    this.setState({
                      countProjects: (count ? (num > 50 ? '50' : `${num}`) : count),
                    });
                  },
                  null,
                  null,
                  true
                )
                }
              </>
            )}
          </div>
          { project.project_type && this._buildProject(project, (project) => {
            this.setState({
              project,
            });
          }, false, true)}
        </div>
      </div>
    </div>
  );
}
