import { typesFields } from '../projectTypes';

/**
 * Merges keys into a field name in FormData
 *
 * @param {string} prefix
 * @param {string} subgroup
 * @param {any} key
 * @param {number} index
 * @returns {string}
 */
const buildName = (prefix = null, subgroup = null, key = null, index = null) => {
  const keys = [ prefix, subgroup, key, index ];

  let name = '';

  keys.forEach((key) => {
    if (key || key === 0) {
      if (!name) {
        name = key;
      } else {
        name += `[${key}]`;
      }
    }
  });

  return name;
};

/**
 * Adds fields to FormData
 *
 * @this ProjectCreate
 * @param {FormData} formData
 * @param {object} field
 * @param {{}|null}project
 * @param {string|null} prefix
 * @returns {void}
 */
export default function (formData, field, project = null, prefix = null) {
  if (project === null) {
    project = this.state.project;
  }

  const key = field.key || field.keys[0];

  if (!key) {
    return;
  }

  if (project[key] || project[key] === 0 || field.typeFields === typesFields.switch) {
    if (field.typeFields === typesFields.inputSelect) {
      if (field.keys) {
        field.keys.forEach((key) => {
          project[key].forEach((val, index) => {
            formData.append(buildName(prefix, field.subgroup, field.key, index), val);
          });
        });
      } else if (field.key) {
        project[key].forEach((val, index) => {
          formData.append(buildName(prefix, field.subgroup, field.key, index), val);
        });
      }
    } else if (field.typeFields === typesFields.switch) {
      const val = project[field.key] || 'off';

      formData.append(buildName(prefix, field.subgroup, field.key), val);

      if (field.addedFields) {
        field.addedFields.forEach((item) => {
          if (!item.value || item.value === val) {
            this._addField(formData, item, project, prefix);
          }
        });
      }
    } else {
      if (field.key) {
        formData.append(buildName(prefix, field.subgroup, key), project[key]);
      } else if (field.keys) {
        field.keys.forEach((key) => {
          formData.append(buildName(prefix, field.subgroup, key), project[key]);
        });
      }

      if (field.addedFields) {
        field.addedFields.forEach((item) => {
          if (!item.value || item.value === project[key]) {
            this._addField(formData, item, project, prefix);
          }
        });
      }
    }
  }
}
