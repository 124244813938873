import { entitySubtypes, entityTypes } from '../config/config';



class TagEntity {
  constructor (entity, groups) {
    this.entity = entity;
    this.groups = groups;
  }

  get isParent () {
    if (!this.entity.options) {
      return false;
    }

    const option = this.entity.options?.find((option) => option.name === 'is_parent');

    return !!option?.value;
  }

  get isDependent () {
    if (!this.entity.options) {
      return false;
    }

    const option = this.entity.options?.find((option) => option.name === 'is_dependent');

    return !!option?.value;
  }

  getDependentGroup () {
    const template = this.entity?.parents?.find((option) => option.type === entityTypes.template);

    if (!template) {
      return {};
    }

    let subtype = entitySubtypes.default;

    switch (this.entity.subtype) {
      case entitySubtypes.main:
        subtype = entitySubtypes.usual;
        break;
      case entitySubtypes.usual:
        subtype = entitySubtypes.cloud;
        break;
      default:
        subtype = null;
    }

    if (!subtype) {
      return {};
    }

    const dependentGroup = this.groups?.find((group) => {
      if (group.subtype !== subtype) {
        return false;
      }

      return group.parents?.find((parent) => {
        return parent.id === template?.id;
      });
    });

    return dependentGroup ?? {};
  }

  getParentTags () {
    const template = this.entity?.parents?.find((option) => option.type === entityTypes.template);

    if (!template) {
      return [];
    }

    let subtype = entitySubtypes.default;

    switch (this.entity.subtype) {
      case entitySubtypes.usual:
        subtype = entitySubtypes.main;
        break;
      case entitySubtypes.cloud:
        subtype = entitySubtypes.usual;
        break;
      default:
        subtype = null;
    }

    if (!subtype) {
      return [];
    }

    const parentGroup = this.groups?.find((group) => {
      if (group.subtype !== subtype) {
        return false;
      }

      return group.parents?.find((parent) => {
        return parent.id === template?.id;
      });
    });

    return parentGroup?.children ?? [];
  }
}

export default TagEntity;
