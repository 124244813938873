import { orderBy } from 'lodash';



const markDeletedCategories = (categories) => categories.map((category) => {
  return {
    ...category,
    name: category.deleted_at ? `${category.name} (Deleted)` : category.name,
  };
});

const actions = {
  SET_CATEGORIES: 'SET_CATEGORIES',
  setCategories: (categories) => ({
    type: actions.SET_CATEGORIES,
    payload: categories,
  }),
  fetchCategories: () => async (dispatch, getState, api) => {
    try {
      const response = await api.get('categories');
      const data = response.data;

      data.categories = markDeletedCategories(data.categories);
      data.categories = orderBy(data.categories, (category) => category.slug);
      data.categories.push({
        id: 'null',
        name: '_uncategorized',
        slug: 'uncategorized',
      });

      dispatch(actions.setCategories(data.categories));
    } catch {
      // todo add notification
    }
  },
};

export default actions;

