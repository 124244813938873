import actions from './actions';



const initialState = {
  loading: false,
  group: {},
  groups: [],
};

export const groupsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.SET_GROUP: {
      return {
        ...state,
        group: action.payload,
      };
    }
    case actions.SET_GROUPS: {
      return {
        ...state,
        groups: action.payload,
      };
    }
    case actions.ADD_GROUP: {
      return {
        ...state,
        groups: [ ...state.groups, action.payload ],
      };
    }
    case actions.UPDATE_GROUP: {
      return {
        ...state,
        groups: state.groups.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              ...action.payload,
            };
          }

          return group;
        }),
      };
    }
    case actions.DELETE_GROUP: {
      return {
        ...state,
        groups: state.groups.filter((group) => group.id !== action.payload),
      };
    }
    default: {
      return state;
    }
  }
};
