import React from 'react';
import { connect } from 'react-redux';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import Preload from '../../widgets/Preload';
import axiosApiInstance from '../../requests/utils/api';
import { notification } from '../../requests/notifications';



const propTypes = {
  lang: PropTypes.object.isRequired,
  notification: PropTypes.any,
};

/**
 * Password Change Component
 *
 * @returns {*}
 */
class ChangePassword extends React.Component {
  state = {
    newVal: {},
    LOAD: false,
  };

  render () {
    const { lang } = this.props;
    const { newVal, LOAD } = this.state;

    return LOAD ? (<Preload />) : (
      <div className="row">
        <div className="col-xl-12">
          <div className="kt-portlet kt-portlet--height-fluid">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title font-weight-bold">
                  {lang['CUSTOMER.CHANGE_PASSWORD']}
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-section kt-section--first">
                <div style={{ width: '100%' }}>
                  <div className="kt-section__body">
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        {lang['CUSTOMER.CURRENT_PASSWORD']}
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          autoComplete="off"
                          type="password"
                          className="form-control"
                          placeholder=''
                          value={newVal.password || ''}
                          onChange={((event) => {
                            this.setState({
                              newVal: {
                                ...newVal,
                                password: event.target.value,
                              },
                            });
                          })}
                        />
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        {lang['CUSTOMER.NEW_PASSWORD']}
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          autoComplete="off"
                          type="password"
                          className="form-control"
                          placeholder=''
                          value={newVal.new_password || ''}
                          onChange={((event) => {
                            this.setState({
                              newVal: {
                                ...newVal,
                                new_password: event.target.value,
                              },
                            });
                          })}
                        />
                      </div>
                    </div>
                    <div className="form-group form-group-last row">
                      <label className="col-xl-3 col-lg-3 col-form-label">
                        {lang['CUSTOMER.VERIFY_PASSWORD']}
                      </label>
                      <div className="col-lg-9 col-xl-6">
                        <input
                          autoComplete="off"
                          type="password"
                          className="form-control"
                          placeholder=''
                          value={newVal.new_confirm || ''}
                          onChange={((event) => {
                            this.setState({
                              newVal: {
                                ...newVal,
                                new_confirm: event.target.value,
                              },
                            });
                          })}
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ paddingTop: '40px' }} className='row'>
                    <div className='col-xl-6 offset-xl-3'>
                      <button
                        className="btn btn-success btn-sm btn-block"
                        type="submit"
                        onClick={() => {
                          this.postPassword();
                        }}
                      >
                        {lang['GLOBAL.SAVE_CHANGES']}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  postPassword = () => {
    const { newVal } = this.state;
    const fd = new FormData();

    Object.keys(newVal).forEach((key) => {
      fd.append(key, newVal[key] || '');
    });

    axiosApiInstance.post('users/password', fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PASSWORD_EDIT', 'success');
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_PASSWORD_EDIT', 'error');
      });

    this.setState({
      newVal: {},
    });
  };
}

const mapStateToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

ChangePassword.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);
