import React, {useEffect, useRef, useState} from 'react';
import clsx from 'clsx';
import rolesMap from './../../../app/pages/customers/roles/roles';
import {Dropdown} from 'react-bootstrap';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import {isEmpty} from 'lodash';
import {useDispatch} from 'react-redux';
import userActions from '../../../app/store/user/actions';


const RoleSelector = ({roles}) => {
  const [currentRole, setCurrentRole] = useState('');
  const prevCurrentRoleRef = useRef(0);
  const dispatch = useDispatch();

  useEffect(() => {
    const storedRole = localStorage.getItem("selected_role");
    if (!isEmpty(storedRole)) {
      setCurrentRole(storedRole);
      prevCurrentRoleRef.current = prevCurrentRoleRef.current + 1;
    }
    if (!isEmpty(roles) && isEmpty(storedRole)) {
      setCurrentRole(roles[0]);
      prevCurrentRoleRef.current = prevCurrentRoleRef.current + 1;
    }
  }, [roles]);

  useEffect(() => {
    if (prevCurrentRoleRef.current > 1) {
      dispatch(userActions.setCurrentRole(currentRole));
    }
  }, [currentRole]);

  if (roles.length <= 1) {
    return null;
  }

  return (
    <Dropdown
      className="kt-header__topbar-item kt-header__topbar-item--langs"
      drop="down"
      alignRight
    >
      <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span className={clsx('kt-header__topbar-select')}
                style={{
                  backgroundColor: 'rgb(29 201 183 / 37%)',
                  fontWeight: "400",
                  height: '36px',
                }}
          >
            {rolesMap[currentRole]?.title}
          </span>
      </Dropdown.Toggle>
      <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
        <ul className="kt-nav kt-mt-10 kt-mb-10">
          {roles.map((role, index) => {
            return (
              <li
                key={role}
                className={clsx('kt-nav__item', {
                  'kt-nav__item--active': currentRole === role,
                })}
              >
                <span
                  onClick={() => {
                    setCurrentRole(role);
                    prevCurrentRoleRef.current = prevCurrentRoleRef.current + 1;
                  }}
                  className={clsx('kt-nav__link', {
                    'kt-nav__link--active': currentRole === role,
                  })}
                >
                  <span className="kt-nav__link-text">{rolesMap[roles[index]].title}</span>
                </span>
              </li>
            )
          })}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default RoleSelector;
