/**
 * Transform status name to color name
 *
 * @param {string} status
 * @returns {string}
 */
const statusToColor = (status) => {
  switch (status) {
    case 'done':
    case 'paid':
    case 'finished':
    case 1: {
      return 'success';
    }
    case 'waiting': {
      return 'dark';
    }
    case 'draft': {
      return 'secondary';
    }
    case 'backlog':
    case 'in hold': {
      return 'danger';
    }
    case 'archived':
    case 'review': {
      return 'warning';
    }
    case 0:
    case 'process':
    default: {
      return 'info';
    }
  }
};

export const status = {
  reopened: 'reopened',
  draft: 'draft',
  waiting: 'waiting',
  process: 'process',
  process__video_edit_check: 'video_edit_check',
  process__artist_choosing: 'artist_choosing',
  process__designer_choosing: 'designer_choosing',
  process__prepare_video_content: 'prepare_video_content',
  process__prepare_dynamic_content: 'prepare_dynamic_content',
  process__promo_static_manager_check_1: 'promo_static_manager_check_1',
  process__promo_dynamic_manager_check_2: 'promo_dynamic_manager_check_2',
  process__video_production_check: 'video_production_check',
  process__designer_coloring: 'designer_coloring',
  process__editor_checking_designer: 'editor_checking_designer',
  process__prepare_static_content: 'prepare_static_content',
  process__edit_video_content: 'edit_video_content',
  process__artist_drawing: 'artist_drawing',
  process__manager_checking: 'manager_checking',
  process__promo_dynamic_manager_check_1: 'promo_dynamic_manager_check_1',
  process__promo_static_manager_check_2: 'promo_static_manager_check_2',
  process__editor_checking_artist: 'editor_checking_artist',
  process__device_testing: 'device_testing',
  process__designers_group_choosing: 'designers_group_choosing',
  process__artists_group_choosing: 'artists_group_choosing',
  finished: 'finished',
  archived: 'archived',
  review: 'review',
};

export default statusToColor;
