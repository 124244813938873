import React from 'react';
import { connect } from 'react-redux';
import {
  groupBy, maxBy, uniqBy, orderBy, isEmpty,
} from 'lodash';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { formatDateTimeToString, formatStringToDate, setDateHours } from '../../../utils/formats';
import { status } from '../../../utils/statusToColor';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../taskTypes';
import typesUsers from '../../customers/typesUsers';
import Preload from '../../../widgets/Preload';
import groupsRoles from '../../customers/roles/groupsRoles';
import getStatus from '../getStatus';
import {
  _getPreviewImagesFromProject,
  getListFilesFromTask,
  getPreviewFilesFromProject,
  getPreviewImagesFromTask,
} from '../../projects/getPreviewFromProject';
import typesComments from '../../../utils/typesComments';
import { getGroup } from '../../../requests/groups';
import MyComponent from '../../../utils/MyComponent';
import roles from '../../customers/roles/roles';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import { buildResponseUsersFromAttribute } from '../../../requests/users';
import urlPageTutorial from '../../../urls/urlPageTutorial';
import urlPageTasks from '../../../urls/urlPageTasks';
import ApplyTaskModal from '../modal/ApplyTaskModal';
import urlTesterTime from '../../../urls/urlTesterTime';
import {
  getPriceByColorComplexityLevel,
  getPriceByContourComplexityLevel,
  highestComplexityLevel,
} from '../../projects/projectPrices';
// eslint-disable-next-line import/no-named-as-default
import isGroupExist from '../../../utils/isGroupExist';
import { filterCommentsByRoles } from '../../../utils/filterComments';
import imgURL from '../../../utils/imgURL';
import isDisabledComplexityChange from '../helpers/isDisabledComplexityChange';
import { complexityLevels } from '../complexityLevels';
import getTaskByTypeFromProject from '../../projects/helpers/getTaskByTypeFromProject';
import tagSystemsPicturesActions from '../../../store/tagSystem/pictures/actions';
import { getUniqTagsFromTagSystemPicture } from '../../tagSystem/picture/helpers/helpers';
import config from '../../../../config/app';
import TaskInfoSubheader from './TaskInfoSubheader';
import TaskInfoMainInfo from './TaskInfoMainInfo';
import TaskInfoFinishBlock from './TaskInfoFinishBlock';
import TaskInfoPreviewBlocks from './TaskInfoPreviewBlocks';
import TaskInfoListFilesBlock from './TaskInfoListFilesBlock';
import TaskInfoRightMenu from './TaskInfoRightMenu';
import TaskInfoModals from './TaskInfoModals';
import Comments from './Comments';

// eslint-disable-next-line valid-jsdoc
/**
 * Component for task information page
 *
 * @returns {*}
 */
export class TaskInfo extends MyComponent {
  state = {
    task: {},
    taskFinishStatus: false,
    executors: [],
    reasons: [],
    additionalData: [],
    canBeRefuse: false,
    tokenDev: '',
    LOAD_TASK: true,
    LOAD_GROUP: true,
    LOAD_BONUS: false,
    LOAD_FILES: false,
    showModalTags: false,
    showModalCategories: false,
    showModalTagSystemPictureTags: false,
    showModalSetBonus: false,
    showModalDeleteBonus: false,
    showModalEditType: false,
    showModalReopenTask: false,
    showModalApplyTask: false,
    showModalLimitReached: false,
    showModalEditContourComplexityLevel: false,
    showModalEditColorComplexityLevel: false,
    bonusAmount: null,
    reason: null,
    versions: [],
    filesVersion: null,
    textureSize: 0,
    config: {},
  };

  computed = {
    isWork: (state) => {
      return this._isWork(state.task);
    },
    isChoseGroup: (state) => {
      return state.task.type
        ? taskTypes[state.task.type].type === taskTypes.artists_group_choosing.type
            || taskTypes[state.task.type].type === taskTypes.designers_group_choosing.type
        : null;
    },
    isChoseExecutor: (state) => {
      return state.task.type
        ? (taskTypes[state.task.type].type === taskTypes.designer_choosing.type
            || taskTypes[state.task.type].type === taskTypes.artist_choosing.type)
        : null;
    },
    canBonus: (state) => {
      return state.task.status === status.process
          && (state.task.type === taskTypes.designer_choosing.type
            || state.task.type === taskTypes.artist_choosing.type
            || state.task.type === taskTypes.editor_checking_designer.type
          );
    },
    isAlreadyHaveBonus: (state) => {
      return !isEmpty(state.task.bonus);
    },
    canSetTags: ({ task }, { userId }) => {
      const type = taskTypes[task.type];

      if (!type) {
        return false;
      }
      return checkRole(groupsRoles.coloring, [ type.role ])
                && task.type !== taskTypes.device_testing.type
                && task.executor_id === userId
                && task.status !== status.finished
                && task.status !== status.review;
    },
    previewFiles: (state) => getPreviewFilesFromProject(state.task.project, state.filesVersion),
    previewReferences: (state) => {
      let previewReferences = [];

      if (state.additionalData && state.additionalData.references) {
        previewReferences = state.additionalData.references.map((refFile) => {
          if (refFile?.path?.includes('?')) {
            refFile.link = refFile.path;
            refFile.path = refFile.path?.split('?')[0];
          }

          const name = refFile.path.slice(refFile.path.lastIndexOf('_') + 1);
          const prefix = refFile.path.slice(refFile.path.lastIndexOf('.') + 1);
          const fileName = refFile.path.slice(refFile.path.lastIndexOf('_') + 1, refFile.path.lastIndexOf('.'));

          return {
            ...refFile,
            name,
            fileName,
            prefix,
          };
        });
      }
      return previewReferences;
    },
    previewImages: ({ task }) => {
      return task.type !== taskTypes.editor_checking_designer.type
            && task.type !== taskTypes.device_testing.type
                && !this._isPreviewForDesigner(task)
        ? getPreviewImagesFromTask(task, this.computed.previewFiles)
        : _getPreviewImagesFromProject(task.project, this.computed.previewFiles);
    },
    taskFiles: (state) => getListFilesFromTask(state.task, state.config),
    sourceFiles: ({ task }) => {
      let sourceFiles = [];

      if (task.project && task.project.files && task.project.files.length > 0) {
        if (this._canViewSourceFiles(task)) {
          sourceFiles = task.project.files.filter((file) => file.prefix === 'source' && Boolean(file.status)).map((file) => {
            const name = file.link.slice(file.link.lastIndexOf('/') + 1);

            const prefix = name.slice(name.lastIndexOf('.') + 1);

            return {
              name,
              prefix,
              link: file.link,
            };
          });
        } else {
          const groups = groupBy(task.project.files, 'prefix');
          const eps = groups.eps ? maxBy(groups.eps, (file) => file.version_id) : null;

          if (eps) {
            sourceFiles = [ {
              name: eps.name,
              prefix: eps.prefix,
              link: eps.link,
            }, ...sourceFiles ];
          }
        }
      }

      return sourceFiles;
    },
    workTask: ({ task }) => {
      if (!(task && task.project && task.project.tasks)) {
        return null;
      }

      task.project.tasks.sort((firstTask, secondTask) => firstTask.id - secondTask.id);
      // eslint-disable-next-line fp/no-loops
      for (let i = task.project.tasks.findIndex((value) => value.id === task.id); i >= 0; i -= 1) {
        if (this._isWork(task.project.tasks[i])) {
          return task.project.tasks[i];
        }
      }

      return null;
    },
    comments: ({ task }, { users }) => {
      const workTask = this.computed.workTask;

      let storedRole = localStorage.getItem('selected_role');

      if (!checkRole(this.props.userRoles, [ storedRole ])) {
        storedRole = null;
      }

      if (task && users) {
        if (workTask && workTask.id === task.id) {
          let filteredComments = task.project && task.project.comments ? task.project.comments.filter((comment) => comment.status !== typesComments.refuseTask && (!comment.task_id || comment.task_id === workTask.id)) : [];

          filteredComments = filterCommentsByRoles(storedRole ? [ storedRole ] : this.props.userRoles, filteredComments, users);


          return this._mergeUsersToComments(filteredComments);
        }
        let filteredComments = task.project && task.project.comments ? task.project.comments.filter((comment) => comment.status !== typesComments.refuseTask) : [];

        filteredComments = filterCommentsByRoles(storedRole ? [ storedRole ] : this.props.userRoles, filteredComments, users);

        return this._mergeUsersToComments(filteredComments);
      }

      return [];
    },
    uniqTags: ({ task }) => {
      return (task.project && task.project.tags) ? uniqBy(task.project.tags, (item) => item.id) : [];
    },
    projectCategories: ({ task }) => {
      return (task.project && task.project.categories);
    },
    canSetCategories: ({ task }, { userRoles }) => {
      const type = taskTypes[task.type];

      if (!type) {
        return false;
      }
      return checkRole(userRoles, groupsRoles.managers.all)
        && task.type === taskTypes.manager_checking.type
        && task.status !== status.finished
        && task.status !== status.review;
    },
    realStatus: ({ task }) => getStatus(task),
    viewFileRight: ({ task }) => {
      return checkRole(
        [ taskTypes[task.type] ? taskTypes[task.type].role : '' ],
        [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.editors.all, ...groupsRoles.testers.all ]
      );
    },
    sizingBlocks: ({ task }, { userId }) => {
      const {
        viewFileRight, taskFiles, sourceFiles, previewImages, realStatus, isWork, isChoseGroup, isChoseExecutor,
      } = this.computed;

      let countBlocks = 1;

      if (!viewFileRight && (taskFiles.length > 0 || (sourceFiles.length > 0))) {
        countBlocks += 1;
      }

      if (previewImages.length > 0) {
        countBlocks += 1;
      }

      if (realStatus === status.process && task.executor_id === userId && (isWork || isChoseGroup || isChoseExecutor)) {
        countBlocks += 1;
      }
      return {
        xl: 12 / countBlocks < 3 ? 4 : 4,
        lg: 12 / countBlocks < 4 ? 4 : 4,
        md: 12 / countBlocks < 6 ? 6 : 12 / countBlocks,
        sm: 12 / countBlocks < 12 ? 12 : 12 / countBlocks,
      };
    },
    IS_LOAD: ({
      LOAD_BONUS, LOAD_TASK, LOAD_GROUP, LOAD_FILES,
    }) => {
      return LOAD_BONUS || LOAD_TASK || LOAD_GROUP || LOAD_FILES;
    },
    canRemoveAllTags: ({ task }, { userId }) => {
      return (
        (task.project && task.project.status !== status.finished) && (
          (task.executor_id === userId
              || (task.project && task.project.manager_id === userId)
          ) && checkRole([ taskTypes[task.type].role ], [ ...groupsRoles.editors.all, ...groupsRoles.managers.all ])
        )
      );
    },
    canRemoveTags: ({ task }, { userId }) => {
      return (
        this.computed.canRemoveAllTags || (task.executor_id === userId && task.status === status.process)
      );
    },
    canViewSourceFiles: ({ task }) => {
      if (task.project && task.project.files && task.project.files.length > 0) {
        return this._canViewSourceFiles(task);
      }
      return true;
    },
    canStartWork: ({ task }, { userRoles, userId }) => {
      return (
        this.computed.realStatus === status.waiting && checkRole(userRoles, [ taskTypes[task.type].role, roles.administrator.key ]) && (
          task.executor_id ? task.executor_id === userId : true
        )
      );
    },
    canApplyTask: ({ task }, { userRoles }) => {
      const uniqTagSystemPictureTags = this._uniqTagsFromTagSystemPicture();
      const isTaskReadyToApply = this.computed.realStatus !== status.finished &&
        !checkRole(userRoles, [ roles['coloring-artist'].key, roles['coloring-designer'].key ], [ roles['coloring-editor'].key ]);

      if (config.tagsEnabled && task.type === taskTypes.editor_checking_designer.type) {
        return isTaskReadyToApply && uniqTagSystemPictureTags.length > 0;
      }

      return isTaskReadyToApply;
    },
    isArtistTaskPaid: ({ task }) => {
      return task?.project && getTaskByTypeFromProject(task.project, taskTypes.artist_drawing.type)?.paid_at;
    },
  };

  _urlToObject = async (file) => {
    const response = await fetch(imgURL(file.link));
    // here image is url/location of image
    const blob = await response.blob();
    const fl = new File([ blob ], 'image.jpg', { type: blob.type });

    this.setState({
      textureSize: (fl.size / 1024 / 1024).toFixed(2),
    });
  };

  _getTaskDeadline = (task) => {
    if (task.type === taskTypes.editor_checking_artist.type
      || task.type === taskTypes.artist_choosing.type
      || task.type === taskTypes.artist_drawing.type
    ) {
      return getTaskByTypeFromProject(task.project, taskTypes.artist_drawing.type)?.deadline_to;
    }
    if (task.type === taskTypes.editor_checking_designer.type
      || task.type === taskTypes.designer_choosing.type
      || task.type === taskTypes.designer_coloring.type
    ) {
      return getTaskByTypeFromProject(task.project, taskTypes.designer_coloring.type)?.deadline_to;
    }
    if (task.type === taskTypes.device_testing.type) {
      return getTaskByTypeFromProject(task.project, taskTypes.device_testing.type)?.deadline_to;
    }

    return '';
  };

  render () {
    return this.computed.IS_LOAD ? (
      <Preload />
    ) : (
      <>
        <TaskInfoSubheader
          task={this.state.task}
          taskFinishStatus={this.state.taskFinishStatus}
          canBeRefuse={this.state.canBeRefuse}
          realStatus={this.computed.realStatus}
          canApplyTask={this.computed.canApplyTask}
          isChoseExecutor={this.computed.isChoseExecutor}
          isChoseGroup={this.computed.isChoseGroup}
          isWork={this.computed.isWork}
          canBonus={this.computed.canBonus}
          isAlreadyHaveBonus={this.computed.isAlreadyHaveBonus}
          canStartWork={this.computed.canStartWork}
          setModalSetBonus={this._setModalSetBonus}
          setModalDeleteBonus={this._setModalDeleteBonus}
          setModalApplyTask={this._setModalApplyTask}
          setModalReopenTask={this._setModalReopenTask}
          setTaskLoad={this._setTaskLoad}
          getTask={this._getTask}
          startTask={this._startTask}
          isArtistTaskPaid={this.computed.isArtistTaskPaid}
          tagSystemPictureTags={this._uniqTagsFromTagSystemPicture()}
        />
        <TaskInfoMainInfo
          task={this.state.task}
          canSetTags={this.computed.canSetTags}
          uniqTags={this.computed.uniqTags}
          canSetCategories={this.computed.canSetCategories}
          projectCategories={this.computed.projectCategories}
          groupName={this.state.groupName}
          setModalEditType={this._setModalEditType}
          setModalEditField={this._setModalEditField}
          setModalTags={this._setModalTags}
          setModalCategories={this._setModalCategories}
          setModalTagSystemPictureTags={this._setModalTagSystemPictureTags}
          isChoseExecutor={this.computed.isChoseExecutor}
          isTester={checkRole(this.props.userRoles, [ roles['coloring-tester'].key ])}
          getTaskDeadline={this._getTaskDeadline}
          tagSystemPictureTags={this._uniqTagsFromTagSystemPicture()}
          canSeeTagSystemPictureTags={this._canSeeTagSystemPictureTags}
        />
        <div className="row">
          <Comments
            task={this.state.task}
            sizingBlocks={this.computed.sizingBlocks}
            comments={this.computed.comments}
            onCreate={this._addComment}
            onUpdate={this._updateComment}
            onDelete={this._deleteComment}
          />
          <TaskInfoFinishBlock
            task={this.state.task}
            groups={this.props.groups}
            executors={this.state.executors}
            canSetTags={this.computed.canSetTags}
            isChoseExecutor={this.computed.isChoseExecutor}
            isWork={this.computed.isWork}
            isChoseGroup={this.computed.isChoseGroup}
            sizingBlocks={this.computed.sizingBlocks}
            taskFiles={this.computed.taskFiles}
            setTaskLoad={this._setTaskLoad}
            setFilesLoad={this._setFilesLoad}
            getTask={this._getTask}
            finishTask={this._finishTask}
            getTaskDeadline={this._getTaskDeadline}
          />
          <TaskInfoListFilesBlock
            task={this.state.task}
            taskFiles={this.computed.taskFiles}
            viewFileRight={this.computed.viewFileRight}
            sizingBlocks={this.computed.sizingBlocks}
            sourceFiles={this.computed.sourceFiles}
          />
          <TaskInfoPreviewBlocks
            {...this.props}
            computed={this.computed}
            task={this.state.task}
            deleteReferenceFile={this._deleteReferenceFile}
            userRoles={this.props.userRoles}
          />
        </div>
        <TaskInfoRightMenu
          task={this.state.task}
          taskFiles={this.computed.taskFiles}
          comments={this.computed.comments}
          viewFileRight={this.computed.viewFileRight}
          canViewSourceFiles={this.computed.canViewSourceFiles}
          textureSize={this.state.textureSize}
          getImage={this._urlToObject}
        />
        {window.location.pathname !== urlPageTutorial && (
          <TaskInfoModals
            task={this.state.task}
            reasons={this.state.reasons}
            showModalTags={this.state.showModalTags}
            showModalCategories={this.state.showModalCategories}
            showModalTagSystemPictureTags={this.state.showModalTagSystemPictureTags}
            showModalSetBonus={this.state.showModalSetBonus}
            showModalDeleteBonus={this.state.showModalDeleteBonus}
            showModalEditType={this.state.showModalEditType}
            showModalReopenTask={this.state.showModalReopenTask}
            showModalEditContourComplexityLevel={this.state.showModalEditContourComplexityLevel}
            showModalEditColorComplexityLevel={this.state.showModalEditColorComplexityLevel}
            // showModalApplyTask={this.state.showModalApplyTask}
            showModalLimitReached={this.state.showModalLimitReached}
            canBonus={this.computed.canBonus}
            isAlreadyHaveBonus={this.computed.isAlreadyHaveBonus}
            canSetTags={this.computed.canSetTags}
            uniqTags={this.computed.uniqTags}
            reason={this.state.reason}
            bonusAmount={this.state.bonusAmount}
            canRemoveAllTags={this.computed.canRemoveAllTags}
            canRemoveTags={this.computed.canRemoveTags}
            setModalSetBonus={this._setModalSetBonus}
            setModalDeleteBonus={this._setModalDeleteBonus}
            setModalSetBonusValues={this._setModalSetBonusValues}
            setModalApplyTask={this._setModalApplyTask}
            setModalReopenTask={this._setModalReopenTask}
            setModalEditType={this._setModalEditType}
            setModalEditField={this._setModalEditField}
            setModalLimitReached={this._setModalLimitReached}
            setModalTags={this._setModalTags}
            reopenTask={this._reopenTask}
            finishTask={this._finishTask}
            createBonus={this._createBonus}
            updateBonus={this._updateBonus}
            deleteBonus={this._deleteBonus}
            setTags={this._setTags}
            editImageCategoryProject={this._editImageCategoryProject}
            updateComplexityLevel={this._updateComplexityLevel}
            canSetCategories={this.computed.canSetCategories}
            projectCategories={this.computed.projectCategories}
            categories={this.state.categories}
            setModalCategories={this._setModalCategories}
            setModalTagSystemPictureTags={this._setModalTagSystemPictureTags}
            setCategories={this._setCategories}
            getTaskDeadline={this._getTaskDeadline}
            tagSystemPicture={this._getTagSystemPicture()}
          />
        )}
        <ApplyTaskModal
          canBonus={this.computed.canBonus}
          canSetTags={this.computed.canSetTags}
          finishTask={this._finishTask}
          setModalApplyTask={this._setModalApplyTask}
          showModalApplyTask={this.state.showModalApplyTask}
        />
      </>
    );
  }

  componentDidMount () {
    this._getTask();
  }

  //Redirect on Tasks page when currentRole is changed
  componentDidUpdate (prevProps) {
    if (prevProps.currentRole && prevProps.currentRole !== this.props.currentRole) {
      this.props.history.replace(urlPageTasks());
    }
    this.setState({ config: this.props.config });
  }

  _updateComplexityLevel = (complexityLevel, type, price = null) => {
    if (isDisabledComplexityChange(this.state.task, type)) {
      return false;
    }

    this.setState({
      LOAD: true,
    });
    const url = `projects/${this.state.task.project.id}/complexity-level`;

    axiosApiInstance.put(url, {
      complexity_level: complexityLevel,
      type,
      price,
    })
      .then((response) => {
        let field = 'designer_price';

        let newPrice = Number(complexityLevel) === highestComplexityLevel ? price : getPriceByColorComplexityLevel(complexityLevel);

        if (type === complexityLevels.contour_complexity_level) {
          field = 'artist_price';
          newPrice = Number(complexityLevel) === highestComplexityLevel ? price : getPriceByContourComplexityLevel(complexityLevel);
        }
        const options = {
          [type]: complexityLevel,
        };

        if (price) {
          options[field] = newPrice;
        }

        this.setState((prevState) => ({
          task: {
            ...prevState.task,
            ...(this.state.task.type === taskTypes.artist_drawing.type
              || this.state.task.type === taskTypes.designer_coloring.type
            ) && { price: response?.data?.price },
            project: {
              ...prevState.task.project,
              options: {
                ...prevState.task.project?.options,
                ...options,
              },
              tasks: prevState.task.project.tasks.map((task) => {
                if (
                  (type === complexityLevels.contour_complexity_level && task.type === taskTypes.artist_drawing.type) ||
                  (type === complexityLevels.color_complexity_level && task.type === taskTypes.designer_coloring.type)
                ) {
                  return {
                    ...task,
                    price: response?.data?.price,
                  };
                }

                return task;
              }),
            },
          },
        }));
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_COMPLEXITY_LEVEL_CHANGED', 'success');
      })
      .catch(() => {
        this.props.notification('NOTIFICATION.ERROR_PROJECT_COMPLEXITY_LEVEL_CHANGED', 'error');
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };


  /**
   * Check availability to view source files
   *
   * @param {object} task
   * @private
   * @returns {boolean}
   */
  _canViewSourceFiles = (task) => {
    if (task && task.project) {
      return (
        (task.project.type === 'coloring-any' && task.type !== taskTypes.device_testing.type)
          || ([ 'coloring-artist', 'coloring' ].includes(task.project.type) && (task.type !== taskTypes.designer_coloring.type && task.type !== taskTypes.device_testing.type))
      );
    }
    return true;
  };

  /**
   * Check that preview for designer
   *
   * @param {object} task
   * @private
   * @returns {boolean}
   */
  _isPreviewForDesigner = (task) => {
    if (task) {
      return (task.type === taskTypes.designer_coloring.type && task.status !== status.waiting)
                && (task.type === taskTypes.designer_coloring.type && task.status !== status.process);
    }
    return true;
  };

  /**
   * Merge users to comments
   *
   * @param {Array} comments
   * @private
   * @returns {Array}
   */
  _mergeUsersToComments (comments) {
    if (comments) {
      return comments.map(
        (comment) => {
          comment.user = this.props.users.find((user) => user.id === comment.user_id);
          return comment;
        }
      );
    }
    return [];
  }

  _canSeeTagSystemPictureTags = () => {
    return config.tagsEnabled ?? (this.state.task?.type === taskTypes.editor_checking_designer.type || this.state.task?.type === taskTypes.device_testing.type);
  };

  _getTask = () => {
    this.setState({
      LOAD_TASK: true,
    });

    axiosApiInstance.get(`tasks/${this.props.match.params.id}`, {})
      .then((response) => {
        const msg = response.data;

        if (msg) {
          const task = msg.task;
          const isWork = this._isWork(task);
          const isChoseExecutor = !isWork && task.type
            ? taskTypes[task.type].type === taskTypes.artist_choosing.type
                || taskTypes[task.type].type === taskTypes.designer_choosing.type
            : null;

          task.project.comments.forEach((comment) => {
            comment.created_at = formatStringToDate(comment.created_at);
            if (comment.data) {
              comment.data = JSON.parse(comment.data);
            }
          });

          task.project.tasks = buildResponseUsersFromAttribute(task.project.tasks, 'executor_id');

          const versions = orderBy(Object.keys(groupBy(task.project.files, (item) => item.version_id)).map((v) => Number(v)), [ (v) => v ], [ 'desc' ]);

          this.setState({
            task,
            reasons: msg.additionalData && msg.additionalData.reasons ? msg.additionalData.reasons : [],
            tokenDev: msg.additionalData && msg.additionalData.token ? msg.additionalData.token : '',
            canBeRefuse: msg.canBeRefuse,
            additionalData: msg.additionalData,
            LOAD_TASK: false,
            LOAD_GROUP: isChoseExecutor,
            showModalApplyTask: false,
            versions,
            bonusAmount: msg.task.bonus ? msg.task.bonus.amount : '0', //@TODO: Delete ternary when back will send bonus object (bonusAmount, reason)
            reason: msg.task.bonus ? msg.task.bonus.reason : null,
          }, () => {
            if (task.status === status.process) {
              this._postTaskFinishStatus(this.state.task.id);
            }
            if (isChoseExecutor && this.state.task.project.options.chosen_group && isGroupExist(this.state.task.project.options.chosen_group)) {
              this._getGroup();
            } else {
              this.setState({
                LOAD_GROUP: false,
              });
            }
            if (this._canSeeTagSystemPictureTags()) {
              this._getTagSystemPictureTags(task.project.id);
            }
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          this.setState({
            LOAD_TASK: false,
            LOAD_GROUP: false,
          });
        }
      });
  };

  _getGroup = async () => {
    this.setState({
      LOAD_GROUP: true,
    });

    const msg = await getGroup(this.state.task.project.options.chosen_group);

    if (msg) {
      this.setState({
        groupName: msg.name,
        executors: msg.users.filter((user) => {
          if (this.state.task.type === taskTypes.artist_choosing.type) {
            return user.type === typesUsers.artist.key;
          }

          return user.type === typesUsers.designer.key;
        }),
        LOAD_GROUP: false,
      });
    } else {
      this.setState({
        LOAD_GROUP: false,
      });
    }
  };

  /**
   * Finish task
   *
   * @param {int} taskId
   * @private
   * @returns {void}
   */
  _postTaskFinishStatus = (taskId) => {
    axiosApiInstance.post(`tasks/${taskId}/check`, {})
      .then((response) => {
        const msg = response.data;

        this.setState({
          taskFinishStatus: msg.status,
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error.response) {
          this.setState({
            taskFinishStatus: false,
          });
        }
      });
  };

  /**
   * Trigger loader
   *
   * @param {boolean} status
   * @param {Function} callback
   * @private
   * @returns {void}
   */
  _setTaskLoad = (status, callback) => {
    this.setState({
      LOAD_TASK: status,
    }, callback);
  };

  /**
   * Trigger loader
   *
   * @param {boolean} status
   * @param {Function} callback
   * @private
   * @returns {void}
   */
  _setFilesLoad = (status, callback) => {
    this.setState({
      LOAD_FILES: status,
    }, callback);
  };

  /**
   * Finish task
   *
   * @param {int} time
   * @returns {Promise<void>}
   * @private
   */
  _finishTask = async (time = null) => {
    this.setState({
      LOAD_TASK: true,
    });

    try {
      if (time) {
        await axiosApiInstance.put(`tasks/${this.props.match.params.id}/tester`, {
          time,
        });
      }

      await axiosApiInstance.put(`tasks/${this.props.match.params.id}/finish`);
      this.props.notification('NOTIFICATION.SUCCESS_TASK_END', 'success');
      await this._getTask();
    } catch (error) {
      if (error.response) {
        if (error.response.data.error === 'Task limit has been reached') {
          this.setState({
            showModalLimitReached: true,
          });
        }
        this.props.notification('NOTIFICATION.ERROR_TASK_END', 'error');
      }
    }

    this.setState({
      LOAD_TASK: false,
      showModalApplyTask: false,
      LOAD_FILES: false,
    });
  };

  _startTask = () => {
    const taskId = this.state.task.id;

    this.setState({
      LOAD_TASK: true,
    });
    const fd = new FormData();

    fd.append('_method', 'PUT');

    axiosApiInstance.post(`tasks/${taskId}/start`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_TASK_START', 'success');
        this._getTask();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_TASK_START', 'error');

          this.setState({
            LOAD_TASK: false,
          });
        }
      });
  };

  /**
   * Add testing time
   *
   * @param {int} time
   * @returns {Promise<void>}
   */
  addTestingTime = async (time) => {
    await axiosApiInstance.put(urlTesterTime({ taskId: this.props.match.params.id }), {
      time,
    });
  };

  /**
   * Reopen Task
   *
   * @param {{
   *  message: string,
   *  returnFiles: Array,
   *  time: int
   * }} data
   * @returns {Promise<void>}
   * @private
   */
  _reopenTask = async (data) => {
    const { message, returnFiles, time = 0, selectedDeadline } = data;

    try {
      if (checkRole(this.props.userRoles, [ roles['coloring-tester'].key ]) && time > 0) {
        await this.addTestingTime(time);
      }

      this.setState({
        LOAD_TASK: true,
      });
      const fd = new FormData();

      fd.append('_method', 'PUT');
      fd.append('message', message);
      if (selectedDeadline) {
        fd.append('deadline', formatDateTimeToString(setDateHours(selectedDeadline)));
      }

      returnFiles.forEach((file, index) => {
        fd.append(`files[${index}]`, file.file, file.file.name);
      });

      await axiosApiInstance.post(`tasks/${this.props.match.params.id}/reopen`, fd);
      this.props.notification('NOTIFICATION.SUCCESS_TASK_REOPEN', 'success');
      this.props.history.goBack();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error.response) {
        this.props.notification('NOTIFICATION.ERROR_TASK_REOPEN', 'error');
        this.setState({
          LOAD_TASK: false,
        });
      }
    }
  };

  /**
   * Create bonus
   *
   * @param {int} bonusAmount
   * @param {string} reason
   * @private
   * @returns {void}
   */
  _createBonus = (bonusAmount, reason) => {
    this.setState({
      LOAD_BONUS: true,
    });

    axiosApiInstance.post('bonuses', {
      task_id: this.state.task.id,
      amount: bonusAmount,
      reason,
    })
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_SET_BONUS', 'success');
        this.setState({
          showModalSetBonus: false,
          LOAD_BONUS: false,
          reason,
          bonusAmount,
        });
        this._getTask();

      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_SET_BONUS', 'error');
        }
      })
      .finally(() => {
        this.setState({
          LOAD_BONUS: false,
        });
      });
  };

  /**
   * Update bonus
   *
   * @param {int} bonusId
   * @param {int} bonusAmount
   * @param {string} reason
   * @returns {Promise<void>}
   * @private
   */
  _updateBonus = async (bonusId, bonusAmount, reason) => {
    this.setState({
      LOAD_BONUS: true,
    });

    axiosApiInstance.put(`bonuses/${bonusId}`, {
      task_id: this.state.task.id,
      amount: bonusAmount || 0,
      reason,
    })
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_CHANGE_BONUS', 'success');
        this.setState({
          showModalSetBonus: false,
          LOAD_BONUS: false,
          reason,
          bonusAmount,
        });
        this._getTask();

      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_CHANGE_BONUS', 'error');
        }
      })
      .finally(() => {
        this.setState({
          LOAD_BONUS: false,
        });
      });
  };

  /**
   * Delete Bonus
   *
   * @param {int} bonusId
   * @returns {void}
   * @private
   */
  _deleteBonus = (bonusId) => {
    this.setState({
      LOAD_BONUS: true,
    });

    axiosApiInstance.delete(`bonuses/${bonusId}`)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_REMOVE_BONUS', 'success');
        this.setState({
          showModalDeleteBonus: false,
          LOAD_BONUS: false,
          bonusId,
        });
        this._getTask();

      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_REMOVE_BONUS', 'error');
        }
      })
      .finally(() => {
        this.setState({
          LOAD_BONUS: false,
        });
      });
  };

  _deleteReferenceFile = ({ fileId, taskId }) => {
    this.setState({
      LOAD_TASK: true,
    });

    axiosApiInstance.delete(`references/${fileId}?task_id=${taskId}`)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_REMOVE_REF_FILE', 'success');
        this.setState({
          LOAD_TASK: false,
        });
        this._getTask();

      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_REMOVE_REF_FILE', 'error');
        }
      })
      .finally(() => {
        this.setState({
          LOAD_TASK: false,
        });
      });
  };

  /**
   * Edit image category project
   *
   * @param {string} newType
   * @param {int} projectId
   * @returns {Promise<void>}
   * @private
   */
  _editImageCategoryProject = async (newType, projectId) => {
    this.setState({

      LOAD_TASK: true,
    });

    axiosApiInstance.put(`projects/${projectId}/image-type`, {
      image_type: newType,
    })
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_EDIT_IMAGE_CATEGORY', 'success');
        this._getTask();
        this.setState({
          showModalEditType: false,
          newType: '',
        });
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_EDIT_IMAGE_CATEGORY', 'error');
        }
      })
      .finally(() => {
        this.setState({
          LOAD_TASK: false,
        });
      });
  };

  /**
   * Is work
   *
   * @param {int} task
   * @returns {boolean}
   * @private
   */
  _isWork = (task = null) => {
    if (!task) {
      task = this.state.task;
    }

    return !!(task.type
      ? checkRole(
        [ taskTypes[task.type].role ],
        groupsRoles.executors.all,
        groupsRoles.testers.all
      )
      : null);
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalSetBonus = (val) => {
    this.setState({
      showModalSetBonus: val,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalDeleteBonus = (val) => {
    this.setState({
      showModalDeleteBonus: val,
    });
  };

  /**
   * Set bonus value
   *
   * @param {int} bonusAmount
   * @param {string} reason
   * @returns {void}
   * @private
   */
  _setModalSetBonusValues = (bonusAmount, reason) => {
    this.setState({
      bonusAmount,
      reason,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalApplyTask = (val) => {
    this.setState({
      showModalApplyTask: val,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalReopenTask = (val) => {
    this.setState({
      showModalReopenTask: val,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalEditType = (val) => {
    this.setState({
      showModalEditType: val,
    });
  };

  _setModalEditField = (val, field) => {
    this.setState({
      [field]: val,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalLimitReached = (val) => {
    this.setState({
      showModalLimitReached: val,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalTags = (val) => {
    this.setState({
      showModalTags: val,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalCategories = (val) => {
    this.setState({
      showModalCategories: val,
    });
  };

  /**
   * Set tags
   *
   * @param {any} tags
   * @returns {void}
   * @private
   */
  _setTags = (tags) => {
    const { task } = this.state;

    this.setState({
      task: {
        ...task,
        project: {
          ...task.project,
          tags,
        },
      },
    });
  };

  _setModalTagSystemPictureTags = (val) => {
    this.setState({
      showModalTagSystemPictureTags: val,
    });
  };

  /**
   * Set categories
   *
   * @param {any} categories
   * @returns {void}
   * @private
   */
  _setCategories = (categories) => {
    const { task } = this.state;

    this.setState({
      task: {
        ...task,
        project: {
          ...task.project,
          categories,
        },
      },
    });
  };

  /**
   * Update comment
   *
   * @param {string} comment
   * @param {Function} callback
   * @returns {void}
   * @private
   */
  _updateComment = (comment, callback) => {
    const { task } = this.state;

    this.setState({
      task: {
        ...task,
        project: {
          ...task.project,
          comments: task.project.comments.map((item) => {
            if (item.id === comment.id) {
              return comment;
            }
            return item;
          }),
        },
      },
    }, callback);
  };

  /**
   * Add comment
   *
   * @returns {void}
   * @private
   */
  _addComment = () => {
    this._getTask();
  };

  _uniqTagsFromTagSystemPicture = () => {
    return getUniqTagsFromTagSystemPicture(this.props.tagSystemPictureTags);
  };

  _getTagSystemPictureTags = (projectId) => {
    this.props.getTagSystemPictureTags(projectId);
  };

  _getTagSystemPicture = () => {
    return this.props.tagSystemPictureTags;
  };
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userId: store.user.user.id,
    userRoles: store.user.roles,
    currentRole: store.user.currentRole,
    users: store.users,
    groups: store.groups.groups,
    categories: store.categories,
    config: store.config,
    tagSystemPictureTags: store.tagSystemPictures.picture,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
    getTagSystemPictureTags: (id) => dispatch(tagSystemsPicturesActions.getPicture(id)),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(TaskInfo);
