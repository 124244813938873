import React from 'react';
import PropTypes from 'prop-types';



const AiTags = ({
  tags,
  group,
  fieldName,
  addAiTag,
}) => {
  return (
    <div className='d-flex mt-2'>
      <p className='text-dark font-weight-bolder mr-2'>AI tags: </p>
      {
        tags?.map((tag) => {
          return (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
            <span
              key={`${group.id}${tag}`}
              onClick={() => {
                addAiTag(fieldName, tag, group);
              }}
              className='mr-2 btn-link cursor-pointer'
            >
              <u>{tag}</u>
            </span>
          );
        })
      }
    </div>
  );
};

AiTags.propTypes = {
  tags: PropTypes.array.isRequired,
  group: PropTypes.object.isRequired,
  fieldName: PropTypes.string.isRequired,
  addAiTag: PropTypes.func.isRequired,
};

export default AiTags;
