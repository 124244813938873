import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React from 'react';
import Modal from '../../../widgets/Modal';
import { contourComplexityLevels, getPriceByContourComplexityLevel } from '../../projects/projectPrices';
import rateTypes from '../rateTypes';
import rateComplexityTypes from '../rateComplexityTypes';



export default function ArtistRatesModal () {
  const { lang } = this.props;

  return (
    <Modal
      title={lang['CUSTOMER.CHANGE_ARTIST_RATE']}
      show={this.state.showArtistRatesModal}
      close={this.toggleArtistRatesModal}
      footer={(
        <>
          <Button
            variant="secondary"
            onClick={this.toggleArtistRatesModal}
          >
            {lang['GLOBAL.CANCEL']}
          </Button>
          <Button
            variant="success"
            onClick={async () => {
              this.updateRates();
            }}
          >
            {lang['GLOBAL.SAVE']}
          </Button>
        </>
      )}
    >
      <Form>
        <Form.Group>
          <Form.Label>
            {lang['PROJECT.FIELDS.contour_complexity_level']} C1
            ({getPriceByContourComplexityLevel(contourComplexityLevels[0])}€)
          </Form.Label>
          <Form.Control
            type="text"
            value={this.state.newRates?.artist?.c1}
            onChange={(event) => {
              this.handleRateChange(rateTypes.artist.key, rateComplexityTypes.c1.key, event);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {lang['PROJECT.FIELDS.contour_complexity_level']} C2
            ({getPriceByContourComplexityLevel(contourComplexityLevels[1])}€)
          </Form.Label>
          <Form.Control
            type="text"
            value={this.state.newRates?.artist?.c2}
            onChange={(event) => {
              this.handleRateChange(rateTypes.artist.key, rateComplexityTypes.c2.key, event);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {lang['PROJECT.FIELDS.contour_complexity_level']} C3
            ({getPriceByContourComplexityLevel(contourComplexityLevels[2])}€)
          </Form.Label>
          <Form.Control
            type="text"
            value={this.state.newRates?.artist?.c3}
            onChange={(event) => {
              this.handleRateChange(rateTypes.artist.key, rateComplexityTypes.c3.key, event);
            }}
          />
        </Form.Group>
      </Form>
    </Modal>
  );
}

