import React from 'react';
import PropTypes from 'prop-types';



const propTypes = {
  lang: PropTypes.object,
};

// eslint-disable-next-line valid-jsdoc
/**
 * Component for table header for billing modal table
 */
const BillingHeaderTable = ({ lang }) => {
  return (
    <thead>
      <tr>
        <th>{lang['BILLING.TABLE_ID']}</th>
        <th>{lang['TASK.TITLE']}</th>
        <th>{lang['TASK.PRICE']}</th>
        <th>{lang['BILLING.BONUS_VALUE']}</th>
        <th>{lang['BILLING.BEGIN']}</th>
        <th>{lang['BILLING.END']}</th>
        <th>{lang['BILLING.BONUS_STATUS']}</th>
      </tr>
    </thead>
  );
};


BillingHeaderTable.propTypes = propTypes;

export default BillingHeaderTable;
