import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { isFabros } from '../../../utils/isFabros';
import { MODAL_TYPES, useGlobalModalContext } from '../../../components/GlobalModal';
import axiosApiInstance from '../../../requests/utils/api';



const Footer = ({ classes }) => {
  const { lang, curLang } = useSelector(({ language }) => ({
    lang: language.lang,
    curLang: language.cur,
  }), shallowEqual);
  const { showModal } = useGlobalModalContext();

  const [ policy, setPolicy ] = useState({});
  const [ term, setTerm ] = useState({});

  const getTerm = async () => {
    try {
      const response = await axiosApiInstance.get('terms');

      await setTerm(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getPolicy = async () => {
    try {
      const response = await axiosApiInstance.get('privacies');

      setPolicy(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    Promise.allSettled([
      getPolicy(),
      getTerm(),
    ]);
  }, []);

  const termModal = () => {
    showModal(MODAL_TYPES.CONTRACT_MODAL, {
      contract: term[curLang],
    });
  };

  const policyModal = async () => {
    showModal(MODAL_TYPES.CONTRACT_MODAL, {
      contract: policy[curLang],
    });
  };

  const getMail = () => {
    if (isFabros) {
      return 'mailto:support@bee-freelancer.com';
    }

    return 'mailto:support@bee-freelancer.app';
  };

  return (
    <div className={classes || 'text-center'}>
      <button
        type="button"
        className="btn btn-link text-center"
        style={{
          fontSize: '15px',
          fontWeight: '500',
        }}
        onClick={termModal}
      >
        {lang['TOUR.REGISTRATION.PUBLIC_OFFER']}
      </button>
      <button
        type="button"
        className="btn btn-link text-center"
        style={{
          fontSize: '15px',
          fontWeight: '500',
        }}
        onClick={policyModal}
      >
        {isFabros ? lang['GLOBAL.PRIVACY_POLICY'] : lang['GLOBAL.XFLOW_PRIVACY_POLICY']}
      </button>
      <a
        className="btn btn-link text-center"
        style={{
          fontSize: '15px',
          fontWeight: '500',
        }}
        href={getMail()}
      >
        {lang['GLOBAL.SUPPORT']}
      </a>
    </div>
  );
};

Footer.propTypes = {
  classes: PropTypes.string,
};

export default Footer;
