import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urlPageGroups from '../../urls/urlPageGroups';
import urlPageError_v4 from '../../urls/urlPageError_v4';
import urlPageGroupsCreate from '../../urls/urlPageGroupsCreate';
import urlPageGroupDetails from '../../urls/urlPageGroupDetails';
import urlPageGroupEdit from '../../urls/urlPageGroupEdit';
import GroupsList from './ListGroups';
import GroupCreate from './GroupCreate';
import GroupInfo from './GroupInfo';
import GroupEdit from './GroupEdit';

/**
 * Group rooting component
 */

export const Groups = () => {
  return (
    <Switch>
      <Route path={urlPageGroupsCreate()} component={GroupCreate} />
      <Route path={urlPageGroupEdit()} component={GroupEdit} />
      <Route path={urlPageGroupDetails()} component={GroupInfo} />
      <Route path={urlPageGroups()} component={GroupsList} />
      <Redirect to={urlPageError_v4()} />
    </Switch>
  );
};

export default Groups;
