import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { array, object, string, boolean, mixed } from 'yup';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import { latinAndSymbolsRegExp } from '../../../utils/checker';
import getGroupsLink from '../helpers/GetGroupsLink';
import actions from '../../../store/tagSystem/templates/actions';
import tagGroupActions from '../../../store/tagSystem/groups/actions';
import { Loader } from '../../../components/Loader';
import urlPageTagSystemTags from '../../../urls/urlPageTagSystemTags';
import MySelect from '../../../widgets/MySelect';
import { getOptions } from '../helpers/GetOptions';
import { getTemplateOptions } from '../helpers/GetTemplateOptions';
import { transformToRequest } from '../api/transformers/group';
import { entitySubtypes, entityTypes } from '../config/config';
import { isDefaultGroup } from '../helpers/IsDefaultGroup';
import { isGlobalGroup } from '../helpers/isGlobalGroup';
import OptionList from './components/OptionList';



const AddEditGroup = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const { lang, loading, loadingTemplates, templates, tagGroup } = useSelector(({
    language,
    tagSystemTemplates,
    tagSystemGroups,
  }) => (
    {
      lang: language.lang,
      locale: language.cur,
      loading: tagSystemGroups.loading,
      loadingTemplates: tagSystemTemplates.loadingTemplates,
      templates: tagSystemTemplates.templates,
      tagGroup: tagSystemGroups.tagGroup,
    }
  ), shallowEqual);

  const validationSchema = object().shape({
    templates: mixed().when('is_global', {
      is: true,
      then: array().required(),
      otherwise: object().required(),
    }),
    name: string().matches(latinAndSymbolsRegExp).trim().min(1).max(100).required(),
    hint: string().trim().matches(latinAndSymbolsRegExp).max(255).nullable().transform((value) => value || null),
    is_global: boolean(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  const values = watch();

  useEffect(() => {
    return () => {
      dispatch(tagGroupActions.setGroup({}));
      dispatch(actions.setTemplates([]));
    };
  }, []);

  useEffect(() => {
    if (values?.is_global) {
      if (values.templates) {
        setValue('templates', Array.isArray(values.templates) ? values.templates : [ values.templates ]);
      }
    } else {
      if (values?.templates?.length > 0) {
        setValue('templates', values?.templates[0]);
      }
    }

    if (id === 'new') {
      setValue('subtype', values?.is_global ? entitySubtypes.global : entitySubtypes.local);

      const options = getOptions('group', values?.is_global ? 'global' : 'local');

      options?.forEach((opt) => {
        let value = opt.default;

        if (`option_${opt.name}` in values) {
          value = values[`option_${opt.name}`];
        }
        setValue(`option_${opt.name}`, value);
      });
    } else {
      if ([ entitySubtypes.global, entitySubtypes.local ].includes(values.subtype)) {
        setValue('subtype', values?.is_global ? entitySubtypes.global : entitySubtypes.local);
      }
    }

  }, [ values?.is_global ]);

  useEffect(() => {
    dispatch(actions.getTemplates());

    if (id !== 'new') {
      dispatch(tagGroupActions.getGroup(id));
    }
  }, [ id ]);

  const prepareTemplateValues = (isGlobal) => {
    const res = [];

    tagGroup.parents?.forEach((parent) => {
      if (parent.type === entityTypes.template) {
        const template = templates?.find((template) => template.id === parent.id);

        if (template) {
          res.push({
            label: template.name,
            value: template.id,
          });
        }
      }
    });

    if (isGlobal) {
      return res;
    }

    return res.length ? res[0] : {};
  };

  useEffect(() => {
    if (id !== 'new' && tagGroup.id && templates && !loading && !loadingTemplates) {
      if (tagGroup?.name) {
        const isGlobal = tagGroup.subtype === entitySubtypes.global;
        const preparedTemplates = prepareTemplateValues(isGlobal);

        const res = {
          name: tagGroup.name || '',
          hint: tagGroup.hint || '',
          is_global: isGlobal,
          subtype: tagGroup.subtype,
          templates: preparedTemplates,
        };

        tagGroup.options?.forEach((opt) => {
          if (opt.readonly) {
            return;
          }
          res[`option_${opt.name}`] = opt.value;
        });

        reset(res);
      }
    }
  }, [ tagGroup, loading, templates, loadingTemplates ]);

  const handleCancel = () => {
    history.goBack();
  };

  const handleSave = (data) => {
    const newData = transformToRequest(data);

    if (id === 'new') {
      dispatch(tagGroupActions.createGroup(newData, () => history.goBack()));
    } else {
      newData.id = Number(id);
      dispatch(tagGroupActions.updateGroup(newData, () => history.goBack()));
    }
  };

  const isEdit = id && id !== 'new';

  const getSubtitle = () => {
    const link = (
      <Link
        key={'subtitle-name'}
        className='font-weight-semibold'
        to={urlPageTagSystemTags({ groupId: tagGroup?.id })}
      >
        {loading ? '-' : tagGroup?.name}
      </Link>
    );

    return isEdit
      ? [ (<span key={'separator'} className="subtitle-separator" />), loading ? <span key="empty-link">-</span> : link ]
      : [];
  };

  return (
    <div className="position-relative">
      <SubHeader
        title={[ getGroupsLink(lang) ]}
        subtitle={getSubtitle()}
        info={isEdit ? lang['GLOBAL.EDIT'] : lang['GLOBAL.CREATE']}
      />
      <div className='kt-portlet mw-100 overflow-auto table-wrapper'>
        {(loading || loadingTemplates || !templates?.length) ? <Loader centered /> : (
          <div className='pl-4 text-black col-lg-6 col-md-8 col-12'>
            <h4
              className='text-black-50 kt-m-30'
            >{isEdit ? lang['TAG_SYSTEM.GROUPS.EDIT_GROUP'] : lang['TAG_SYSTEM.GROUPS.ADD_GROUP']}</h4>
            <div className='px-5'>
              <Form.Group className="d-flex flex-row">
                <Form.Label column className="col-3">{lang['TAG_SYSTEM.TEMPLATES.TEMPLATE']}</Form.Label>
                <div className="col-9 d-flex flex-column">
                  <Controller
                    name="templates"
                    control={control}
                    render={({ field }) => (
                      <MySelect
                        {...field}
                        isMulti={values?.is_global}
                        autoComplete="on"
                        isClearable
                        isInvalid={!!errors.templates}
                        options={getTemplateOptions(templates)}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {values?.is_global ? lang['TAG_SYSTEM.ERRORS.TEMPLATES_GLOBAL_SELECTOR_ERROR'] : lang['TAG_SYSTEM.ERRORS.TEMPLATES_SELECTOR_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group className="d-flex flex-row">
                <Form.Label column className="col-3">
                  {lang['GLOBAL.NAME']}
                  <span style={{ color: 'red' }}>{' *'}</span>
                </Form.Label>
                <div className="col-9 d-flex flex-column">
                  <Controller
                    name="name"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.name}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['TAG_SYSTEM.ERRORS.NAME_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group className="d-flex flex-row">
                <Form.Label column className="col-3">
                  {lang['GLOBAL.HINT']}
                </Form.Label>
                <div className="col-9 d-flex flex-column">
                  <Controller
                    name="hint"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.hint || false}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['TAG_SYSTEM.ERRORS.HINT_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <Form.Group className="d-flex flex-row">
                <Form.Label column className="col-3">
                  {lang['TAG_SYSTEM.OPTIONS.IS_GLOBAL']}
                </Form.Label>
                <div className="col-9 d-flex flex-column justify-content-center">
                  <Controller
                    name="is_global"
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        {...field}
                        disabled={isEdit && (isDefaultGroup(tagGroup) || isGlobalGroup(tagGroup))}
                        type="checkbox"
                        role='button'
                        id={'is_global'}
                        checked={field.value}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['TAG_SYSTEM.ERRORS.SUBTYPE_ERROR']}
                  </Form.Control.Feedback>
                </div>
              </Form.Group>
              <OptionList group={tagGroup} options={getOptions('group', values.subtype)} control={control} />

              <div className='d-flex justify-content-end py-5'>
                <Button variant='secondary' className="mr-4" onClick={handleCancel}>
                  {lang['GLOBAL.CANCEL']}
                </Button>
                <Button variant='success' onClick={handleSubmit(handleSave)}>{lang['GLOBAL.SAVE']}</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditGroup;
