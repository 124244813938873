import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';



const TaskListHeader = () => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  return (
    <thead>
      <tr>
        <th />
        <th>ID</th>
        <th>{lang['TASK.TITLE']}</th>
        <th>{lang['TASK.STATUS']}</th>
        <th>{lang['PROJECT.TAPS']}</th>
        <th>{lang['PROJECT.DIFFICULTY']}</th>
        <th>{lang['TASK.TYPE_IMG']}</th>
        <th>{lang['MENU.TAGS']}</th>
        <th>{lang['GLOBAL.BEGIN']}</th>
        <th>{lang['GLOBAL.FINISHED_AT']}</th>
        <th>{lang['GLOBAL.PAID_AT']}</th>
        <th>{lang['TASK.DEADLINE']}</th>
        <th>{lang['PROJECT.DEADLINE']}</th>
        <th>{lang['TASK.PRICE_AWARD']}</th>
        <th>{lang['GLOBAL.PRIORITY']}</th>
        <th>{lang['GLOBAL.PREV_EXECUTOR']}</th>
        <th>{lang['GLOBAL.EXECUTOR']}</th>
        <th>{lang['GLOBAL.ACTIONS']}</th>
      </tr>
    </thead>
  );
};

export default TaskListHeader;
