import actions from './actions';



const initialState = [];

export const categoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CATEGORIES:
      return [ ...action.payload ];
    default:
      return state;
  }
};
