import React from 'react';
import { groupBy } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import typesComments from '../utils/typesComments';
import { checkImageType } from '../pages/projects/getPreviewFromProject';
import imgURL from '../utils/imgURL';
import Img from './Img';
import History from './History';



const propTypes = {
  comments: PropTypes.arrayOf(PropTypes.shape({
    status: PropTypes.number,
    created_at: PropTypes.instanceOf(Date),
    user_id: PropTypes.number,
  })),
  images: PropTypes.any,
  users: PropTypes.any,
  lang: PropTypes.object,
};

/**
 * Component for show history load files and returned tasks to rework
 */
class VersionsHistory extends React.Component {
  render () {
    const {
      comments = [], images = [], users = [], lang,
    } = this.props;

    const groups = groupBy(images, 'version_id');
    const versions = Object.keys(groups).map((v) => (Number(v) ? Number(v) : 0));

    return (
      <History
        history={[
          ...versions.map((item) => {
            const user = users.find((user) => user.id === groups[item][0]?.user_id);
            const listImage = groups[item].filter((file) => checkImageType(file) ? file.link : file.link);

            listImage.map((file) => ({
              id: file.id,
              link: file.link,
            }));
            return {
              id: item,
              title: user
                ? `${user?.username} ${lang['TASK.UPLOADED_FILES'].toLowerCase()}`
                : lang['TASK.UPLOADED_FILES'],
              date: new Date(item * 1000),
              info: (<div className="w-100">
                <div
                  className="kt-wizard-v1__review-content dropzone dropzone-default border-0 kt-p-0"
                  style={{
                    cursor: 'default',
                  }}
                >
                  {groups[item].map((file) => {
                    const fileLink = (file && file.prefix !== 'eps')
                      ? file?.link
                      : file?.data;

                    const fileName = (file.name || file.prefix || 'file').toUpperCase();

                    const canFull = checkImageType(file);

                    return (
                      <div
                        key={uuidv4()}
                        className="dz-preview dz-processing dz-image-preview dz-error dz-complete mx-2 w-100 d-flex mw-100 d-flex justify-content-between align-items-center kt-mb-10"
                      >
                        <div className="d-flex align-items-center">
                          <div className={`dz-image ${fileLink ? '' : 'border-0'}`}>
                            {fileLink && (
                              <Img
                                src={fileLink}
                                style={{
                                  width: '100%',
                                  height: '100%',
                                  objectFit: 'cover',
                                }}
                                canFull={canFull}
                                current={{
                                  id: file.id,
                                  fileLink,
                                }}
                                files={listImage}
                              />
                            )}
                          </div>
                          <a
                            target="_blank"
                            rel="noreferrer"
                            download={fileName}
                            href={imgURL(file?.link)}
                            className="text-dark hover"
                          >
                            {fileName}
                          </a>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>),
            };
          }),
          ...comments.filter((item) => item.status === typesComments.reopenTask).map((comment) => {
            const user = users.find((user) => user.id === comment.user_id);

            return {
              id: comment.created_at.getTime(),
              date: comment.created_at,
              color: 'danger',
              title: user
                ? `${user.username} ${lang['TASK.RETURNED'].toLowerCase()}`
                : lang['TASK.RETURNED'],
            };
          }),
        ]}
      />
    );
  }
}

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  users: store.users,
});

VersionsHistory.propTypes = propTypes;

export default connect(mapStoreToProps)(VersionsHistory);
