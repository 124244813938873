import queryString from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';



const useQueryParams = () => {
  const history = useHistory();
  const location = useLocation();
  const currentQuery = queryString.parse(location.search);

  return (newParams) => {
    const updatedQuery = {
      ...currentQuery,
      ...newParams,
    };

    const filteredQuery = Object.keys(updatedQuery)
      .filter((key) => updatedQuery[key] !== '') // Исключаем пустые строки
      .reduce((acc, key) => {
        acc[key] = updatedQuery[key];
        return acc;
      }, {});

    history.replace({
      search: queryString.stringify(filteredQuery),
    });
  };
};

export default useQueryParams;
