import Badge from 'react-bootstrap/Badge';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import RightMenu from '../../../widgets/RightMenu';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import imgURL from '../../../utils/imgURL';
import History from '../../../widgets/History';
import { formatStringToDate } from '../../../utils/formats';
import VersionsHistory from '../../../widgets/VersionsHistory';
import Img from '../../../widgets/Img';
import getLinkToIcon from '../linksToIconFiles';

/**
 * Builds the right menu for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { project, textureSize } = this.state;
  const { lang, userRoles } = this.props;
  const {
    listFiles,
  } = this.computed;

  return (
    <RightMenu
      isOpen={false}
      tabs={[
        {
          title: lang['PROJECT.HISTORY'],
          icon: 'fa fa-map-pin',
          color: 'success',
          scroll: true,
          // TODO убрать сужность юзера

          body: () => project.history && (
            <History
              history={project.history.map((item) => ({
                id: item.id,
                date: formatStringToDate(item.created_at),
                title: `${item.user?.username || ''} `,
                info: item.message,
              }))}
            />
          ),
        },
        checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.editors.all, ...groupsRoles.admin ]) && {
          icon: 'flaticon2-files-and-folders',
          color: 'danger',
          title: lang['PROJECT.HISTORY_FILES'],
          scroll: true,

          body: () => (
            <VersionsHistory
              users={this.props.users}
              comments={project.comments}
              images={project.files}
            />
          ),
        },
        ((listFiles.length > 0) || (project.options && project.files && project.files.length > 0)) && {
          title: lang['GLOBAL.LIST_FILES'],
          icon: 'flaticon2-google-drive-file',
          color: 'warning',
          scroll: true,

          body: () => (
            <div className="w-100">
              {(listFiles.length > 0) && (
                <div className="w-100">
                  <h5 className="kt-mb-20">
                    {lang['TASK.LOAD_FILES']}
                  </h5>
                  <div className="kt-widget4 w-100">
                    {listFiles.map((file) => {
                      if (file.prefix === 'texture') {
                        this._urlToObject(file);
                      }

                      return (
                        <div className="kt-widget4__item" key={uuidv4()}>
                          <div className="kt-widget4__pic kt-widget4__pic--pic">
                            <Img src={getLinkToIcon(file.prefix)} />
                          </div>
                          <div className="kt-widget4__info">
                            {file.link ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                download={file.name || file.prefix || 'file'}
                                href={imgURL(file.link)}
                                className="kt-widget4__username"
                              >
                                {(file.name || 'file') + (file.prefix ? `(${file.prefix.replace('_', '.')})` : '')}
                                {file.required && (
                                  <span style={{ color: 'red' }}>*</span>
                                )} {textureSize > 0 && file.prefix === 'texture' ? (`${textureSize} Mb`) : null}
                              </a>
                            ) : (
                              <span className="kt-widget4__username no-hover">
                                {(file.name || 'file') + (file.prefix ? ` (${file.prefix.replace('_', '.')})` : '')}
                                {file.required && (
                                  <span style={{ color: 'red' }}>*</span>
                                )}
                              </span>
                            )}
                          </div>
                          {!file.uploaded && file.status === 0 ? (
                            <Badge variant="primary">{lang['GLOBAL.FILES.NO_LOAD']}</Badge>
                          ) : (file.valid || file.status === 1 ? (
                            <Badge variant="success">{lang['GLOBAL.FILES.VALID']}</Badge>
                          ) : (
                            <Badge variant="danger">{lang['GLOBAL.FILES.NO_VALID']}</Badge>
                          )
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
              <span className="kt-pb-20 kt-mt-20 kt-pt-20 w-100 d-block" />
              {(project.options && project.files && project.files.length > 0) && (
                <div>
                  <h5 className="kt-mb-20">
                    {lang['TASK.SOURCE_FILES']}
                  </h5>
                  <div className="kt-widget4">
                    {project.files.filter((file) => file.prefix === 'source').map((file) => {
                      const name = file.link.slice(file.link.lastIndexOf('/') + 1);
                      const prefix = name.slice(name.lastIndexOf('.') + 1);

                      return (
                        <div className="kt-widget4__item" key={uuidv4()}>
                          <div className="kt-widget4__pic kt-widget4__pic--pic">
                            <Img src={getLinkToIcon(prefix)} />
                          </div>
                          <div className="kt-widget4__info">
                            {file.link ? (
                              <a
                                target="_blank"
                                rel="noreferrer"
                                download={name || 'file'}
                                href={imgURL(file.link)}
                                className="kt-widget4__username"
                              >
                                {name || 'file'}
                                {file.required && (
                                  <span style={{ color: 'red' }}>*</span>
                                )}
                              </a>
                            ) : (
                              <span className="kt-widget4__username no-hover">
                                {file.name || file.prefix || 'file'}
                                {file.required && (
                                  <span style={{ color: 'red' }}>*</span>
                                )}
                              </span>
                            )}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          ),
        },
      ]}
    />
  );
}
