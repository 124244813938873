import isDisabledContourComplexity from '../../tasks/helpers/isDisabledContourComplexity';
import getTaskByTypeFromProject from './getTaskByTypeFromProject';



const isDisabledProjectContourComplexity = (project, type, userRoles) => {
  return (
    getTaskByTypeFromProject(project, type)
      && isDisabledContourComplexity(getTaskByTypeFromProject(project, type), userRoles)
  );
};

export default isDisabledProjectContourComplexity;
