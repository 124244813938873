import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { isBYPersonalData, isBYResidence, isRU, isRUResidence } from '../../../utils/checker';
import { isFabros, isXFLOW } from '../../../utils/isFabros';
import MySelect from '../../../widgets/MySelect';
import { DatePickerForwardRef as MyDatePicker } from '../../../widgets/DatePicker';
import { toAbsoluteUrl } from '../../../utils/gettersUrl';
import getCountriesOptionsList from '../../../utils/getCountriesOptionsList';



const DocumentInformationTab = ({ isDisabled, control, errors, formValues }) => {
  const { lang, countries } = useSelector(({ language, countries }) => ({
    lang: language.lang,
    countries: countries.countries,
  }), shallowEqual);

  return (
    <div className="row">
      <h3 className='col-12'>{lang['CUSTOMER.PASSPORT']}</h3>
      <div className='col-12'>
        <hr />
      </div>
      {((!isRU(formValues) || isBYResidence(formValues)) && !isRUResidence(formValues)) && isFabros && (
        <Controller
          name="personal_number"
          control={control}
          render={({ field }) => (
            <Form.Group className="col-lg-6 col-12">
              <Form.Label>
                {lang['REGISTRATION.PASSPORT_ID']}
              </Form.Label>
              <Form.Control
                {...field}
                type="text"
                disabled={isDisabled}
                placeholder=''
                isInvalid={!!errors.personal_number}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.PASSPORT_ID_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.PASSPORT_ID_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}
      <Controller
        name="passport_number"
        control={control}
        render={({ field }) => (
          <Form.Group className={isRU(formValues) || isFabros ? 'col-lg-6 col-12' : 'col-lg-4 col-12'}>
            <Form.Label>
              {lang['REGISTRATION.PASSPORT_NUMBER']}
            </Form.Label>
            <Form.Control
              {...field}
              type="text"
              disabled={isDisabled}
              isInvalid={!!errors.passport_number}
            />
            <Form.Text className="text-muted">
              {lang['REGISTRATION.PASSPORT_NUMBER_PLEASE']}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {lang['REGISTRATION.PASSPORT_NUMBER_ERROR']}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />
      {isFabros && (
        <Controller
          name="date_of_issue"
          control={control}
          render={({ field }) => (
            <Form.Group className="col-lg-6">
              <Form.Label>
                {lang['REGISTRATION.PASSPORT_DATE_ISSUE']}
              </Form.Label>
              <MyDatePicker
                {...field}
                disabled={isDisabled}
                maxDate={new Date()}
                isInvalid={!!errors.date_of_issue}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.PASSPORT_DATE_ISSUE_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.PASSPORT_DATE_ISSUE_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}
      <Controller
        name="expiration_date"
        control={control}
        render={({ field }) => (
          <Form.Group className={isFabros ? 'col-lg-6' : 'col-lg-4'}>
            <Form.Label>
              {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION']}
            </Form.Label>
            <MyDatePicker
              {...field}
              disabled={isDisabled}
              minDate={new Date()}
              isInvalid={!!errors.expiration_date}
            />
            <Form.Text className="text-muted">
              {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION_PLEASE']}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {lang['REGISTRATION.PASSPORT_DATE_EXPIRATION_ERROR']}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />
      {isXFLOW && (
        <Controller
          name="country_issuing_document"
          control={control}
          render={({ field }) => (
            <Form.Group className="col-lg-4">
              <Form.Label>
                {lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT']}
              </Form.Label>
              <MySelect
                {...field}
                isClearable
                disabled={isDisabled}
                isInvalid={!!errors.country_issuing_document}
                options={getCountriesOptionsList(countries)}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.COUNTRY_ISSUING_DOCUMENT_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}
      <div className="col-12">
        <div className="row">
          {isFabros && (
            <Controller
              name="organization"
              control={control}
              render={({ field }) => (
                <Form.Group className="col-lg-12">
                  <Form.Label>
                    {lang['REGISTRATION.PASSPORT_ISSUED']}
                  </Form.Label>
                  <Form.Control
                    {...field}
                    type="text"
                    disabled={isDisabled}
                    isInvalid={!!errors.organization}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.PASSPORT_ISSUED_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.PASSPORT_ISSUED_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            />
          )}
        </div>
      </div>
      {isBYPersonalData(formValues) && isFabros && (
        <>
          <h3 className='col-12'>{lang['CUSTOMER.SOCIAL_INSURANCE']}</h3>
          <div className='col-12'>
            <hr />
          </div>
          <div className="col-xl-6 col-12">
            <div>
              <img
                src={toAbsoluteUrl('/media/docs/soc_card.jpg')}
                alt="soc_card"
                style={{
                  width: '450px',
                  borderRadius: '15px',
                  display: 'block',
                  margin: '2em auto',
                }}
              />
            </div>
          </div>
          <div className="col-xl-6 col-12">
            <Controller
              name="insurance_number"
              control={control}
              render={({ field }) => (
                <Form.Group>
                  <Form.Label>
                    {lang['REGISTRATION.INSURANCE']}
                  </Form.Label>
                  <Form.Control
                    {...field}
                    type="text"
                    disabled={isDisabled}
                    isInvalid={!!errors.insurance_number}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.INSURANCE_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.INSURANCE_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            />
          </div>
        </>)}
    </div>
  );
};

DocumentInformationTab.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default DocumentInformationTab;
