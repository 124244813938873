export const tagConfig = {
  'template': {
    'subtypes': {
      'default': {
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': true,
          },
        ],
        'create_groups': [
          { 'name': 'main_tags' },
          { 'name': 'usual_tags' },
          { 'name': 'cloud_tags' },
        ],
        'attach_global_groups': [
          { 'name': 'genre' },
          { 'name': 'technique' },
        ],
        'options': [
          {
            'name': 'is_searchable',
            'value': true,
          },
        ],
      },
    },
  },
  'group': {
    'subtypes': {
      'main': {
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'can_be_dependent',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'can_change_subtype',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'is_required',
            'readonly': true,
            'value': true,
          },
        ],
        'options': [
          { 'name': 'is_multiple', 'default': false },
          { 'name': 'is_searchable', 'default': false },
          { 'name': 'use_ai_suggestions', 'default': false },
          { 'name': 'is_parent', 'readonly': true, 'default': true },
          { 'name': 'allow_fast_creation', 'default': false },
        ],
      },
      'usual': {
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'can_be_dependent',
            'readonly': true,
            'value': true,
          },
          {
            'name': 'can_change_subtype',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'is_required',
            'readonly': true,
            'value': true,
          },
        ],
        'options': [
          { 'name': 'is_multiple', 'default': true },
          { 'name': 'is_searchable', 'default': false },
          { 'name': 'use_ai_suggestions', 'default': false },
          { 'name': 'is_dependent', 'default': true },
          { 'name': 'is_parent', 'readonly': true, 'default': true },
          { 'name': 'allow_fast_creation', 'default': false },
        ],
      },
      'cloud': {
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'can_be_dependent',
            'readonly': true,
            'value': true,
          },
          {
            'name': 'can_change_subtype',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'is_required',
            'readonly': true,
            'value': true,
          },
        ],
        'options': [
          { 'name': 'is_multiple', 'default': true },
          { 'name': 'is_searchable', 'default': false },
          { 'name': 'use_ai_suggestions', 'default': false },
          { 'name': 'is_dependent', 'default': true },
          { 'name': 'allow_fast_creation', 'default': false },
        ],
      },
      'local': {
        'change_subtypes': [
          { 'name': 'global' },
        ],
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': true,
          },
          {
            'name': 'can_be_dependent',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'can_change_subtype',
            'readonly': true,
            'value': true,
          },
        ],
        'options': [
          { 'name': 'is_required', 'default': true },
          { 'name': 'is_multiple', 'default': false },
          { 'name': 'is_searchable', 'default': true },
          { 'name': 'use_ai_suggestions', 'default': false },
          { 'name': 'allow_fast_creation', 'default': false },
        ],
      },
      'global': {
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': true,
          },
          {
            'name': 'can_be_dependent',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'can_change_subtype',
            'readonly': true,
            'value': false,
          },
          {
            'name': 'is_global',
            'readonly': true,
            'value': true,
          },
        ],
        'options': [
          { 'name': 'is_required', 'default': true },
          { 'name': 'is_multiple', 'default': false },
          { 'name': 'is_searchable', 'default': true },
          { 'name': 'use_ai_suggestions', 'default': false },
          { 'name': 'allow_fast_creation', 'default': false },
        ],
      },
    },
  },
  'tag': {
    'subtypes': {
      'default': {
        'params': [
          {
            'name': 'can_be_deleted',
            'readonly': true,
            'value': true,
          },
        ],
        'options': [],
      },
    },
  },
};
