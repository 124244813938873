import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import Tabs from '../../../widgets/Tabs';
import { formatStringToDate, formatToPrettyDateTime } from '../../../utils/formats';



const propTypes = {
  lang: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
};


/**
 * Component for building tables like "other" and "shutter"
 */

export const Other = ({ lang, data }) => {
  return (
    <Tabs
      tabs={[
        {
          title: `${lang['REPORTS.TYPES_USER_ANALYZE.report_by_artist_types.finished']} (${data.finished.length})`,

          body: () => (
            <Table
              borderless
              striped
              className="vertical-align-middle text-center"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{lang['REPORTS.DATE_CREATE_BACKLOG']}</th>
                  <th>{lang['REPORTS.DATE_READY_TO_TEST']}</th>
                  <th>{lang['REPORTS.DATE_FINISHED']}</th>
                  <th>{lang['PROJECT.FIELDS.type']}</th>
                  <th
                    style={{
                      textAlign: 'end',
                      paddingRight: '155px',
                    }}
                  >
                    {lang['TASK.EXECUTORS']}
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.finished.map((row) => (
                  <tr key={row.project_id}>
                    <td>
                      {row.project_id}
                    </td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.backlog))}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.ready_to_test))}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.finished_at))}</td>
                    <td>{row.image_type || '---'}</td>
                    <td>
                      <Table
                        borderless
                        size="sm"
                        striped
                        className="vertical-align-middle text-left kt-mb-0"
                      >
                        <tbody>
                          {Object.keys(row.executors).map((executor) => (
                            <tr
                              key={executor}
                              style={{
                                background: 'transparent',
                              }}
                            >
                              <th>
                                {lang[`TASK.TYPES.${executor}`]}
                              </th>
                              <td style={{ width: '230px' }}>
                                {row.executors[executor]}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
        {
          title: `${lang['REPORTS.TYPES_USER_ANALYZE.report_by_artist_types.assigned']} (${data.assigned.length})`,

          body: () => (
            <Table
              borderless
              striped
              className="vertical-align-middle text-center"
            >
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{lang['REPORTS.DATE_CREATE_BACKLOG']}</th>
                  <th>{lang['CUSTOMER.TYPE.designer']}</th>
                  <th>{lang['CUSTOMER.TYPE.editor']}</th>
                  <th>{lang['REPORTS.DATE_READY_TO_REVIEW']}</th>
                  <th>{lang['PROJECT.FIELDS.type']}</th>
                </tr>
              </thead>
              <tbody>
                {data.new_for_designers.map((row) => (
                  <tr key={row.project_id}>
                    <td>{row.project_id}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.backlog))}</td>
                    <td>{row.designer}</td>
                    <td>{row.editor}</td>
                    <td>{formatToPrettyDateTime(formatStringToDate(row.ready_to_review))}</td>
                    {row.image_type && (
                      <td>{row.image_type.type}</td>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ),
        },
      ]}
    />
  );
};


Other.propTypes = propTypes;

export default Other;
