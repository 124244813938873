import React, { useMemo } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';



const GlobalGroupsList = ({ template, isEdit, items, control }) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const sortedGroups = useMemo(() => {
    return items?.sort((first, second) => first.name?.localeCompare(second?.name, undefined, { sensitivity: 'base' }));
  }, [ items ]);

  const isDisabled = (item) => {
    const globalGroup = template?.children?.find((group) => group.id === item.id);

    return isEdit && globalGroup;
  };

  return (
    <>
      <strong className="d-flex mb-4">{lang['TAG_SYSTEM.TEMPLATES.GLOBAL_GROUPS']}</strong>
      <Row>
        {
          sortedGroups.map((group, index) => {
            const name = `global_group_${group.id}_${group.name}`;

            return (
              <Col sm={4} key={index.toString()}>
                <Form.Group
                  className='d-flex'
                >
                  <Controller
                    name={name}
                    control={control}
                    render={({ field }) => (
                      <Form.Check
                        disabled={isDisabled(group)}
                        {...field}
                        type="checkbox"
                        id={name}
                        name={name}
                        checked={field.value}
                      />
                    )}
                  />
                  <Form.Label
                    htmlFor={name}
                    role="button"
                    className='w-100'
                  >
                    {group.name}
                  </Form.Label>
                </Form.Group>
              </Col>
            );
          })
        }
      </Row>
    </>
  );
};

GlobalGroupsList.propTypes = {
  template: PropTypes.object.isRequired,
  isEdit: PropTypes.bool.isRequired,
  items: PropTypes.array.isRequired,
  control: PropTypes.any.isRequired,
};

export default GlobalGroupsList;
