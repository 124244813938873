import React from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { intersection, isEmpty, orderBy } from 'lodash';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';
import Paginator from '../../../widgets/Paginator';
import Modal from '../../../widgets/Modal';
import roles, { executorsRoles, realRoles } from '../roles/roles';
import { getRolesFromGroupsUsers } from '../roles/groupsUsers';
import KTUtil from '../../../../_metronic/_assets/js/util';
import { formatDateToString, formatStringToDate } from '../../../utils/formats';
import { parseFromPars, parseToPars } from '../../../utils/parseUrlParams';
import Preload from '../../../widgets/Preload';
import checkRole from '../../../utils/checkRole';
import MyDatePicker from '../../../widgets/DatePicker';
import ViewFilters from '../../../widgets/ViewFilters';
import MySelect from '../../../widgets/MySelect';
import { isScreenMd } from '../../../utils/size';
import axiosApiInstance from '../../../requests/utils/api';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import { notification } from '../../../requests/notifications';
import freelancerAction from '../../../store/freelancers/actions';
import { getUsersAll } from '../../../requests/users';
import { isEmptyObject } from '../../../utils/checker';
import urlPageInvitations from '../../../urls/urlPageInvitations';
import CustomerItemTable from './CustomerItemTable';
import ListCustomersTableHeader from './ListCustomersTableHeader';
import CustomerItem from './CustomerItem';



const propTypes = {
  lang: PropTypes.object.isRequired,
  customer: PropTypes.any,
  history: PropTypes.object.isRequired,
  userRoles: PropTypes.array.isRequired,
  match: PropTypes.object.isRequired,
  notification: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  groupsRoles: PropTypes.array.isRequired,
  getFreelancers: PropTypes.func.isRequired,
  filterFreelancers: PropTypes.func.isRequired,
  setFreelancers: PropTypes.func.isRequired,
  freelancers: PropTypes.array,
  getUsersAll: PropTypes.func.isRequired,
  users: PropTypes.array,
  user: PropTypes.object.isRequired,
};

const today = new Date();

const initialFilter = {
  show: false,
  role: [],
  country: '',
  inactive: 0,
  name: null,
  id: null,
  skip: 0,
  sort: {},
};

/**
 * Component for user list page
 *
 * @returns {*}
 */
class ListCustomers extends React.Component {
  state = {
    modal: {
      show: false,
      isInvalid: false,
      email: '',
      inviteRole: [],
      expires: formatDateToString(new Date(today.getFullYear(), today.getMonth() + 1, today.getDate())),
      employee: false,
    },
    selectedRoles: [],
    filter: initialFilter,
    LOAD: false,
    LOAD_FREELANCERS: true,
    countOnPage: 20,
    isMobile: isScreenMd(),
    customersViewTable: !!Number(localStorage.getItem('customers_type_view')),
    freelancersInitial: [],
    filterUser: {},
  };

  constructor (props) {
    super(props);
  }

  computed = {
    filterUser: (value, label) => {
      const params = parseFromPars(window.location.search);

      if (!isEmpty(params)) {
        return {
          'value': `${this.state.filter.id ? Number(this.state.filter.id) : value || ''}`,
          'label': `${this.state.filter.name ? this.state.filter.name : label || ''}`,
        }; //ЗАПИСЫВАТЬ ОБЬЕКТ В filterUser и доставать после обновления!!
      }
      return null;
    },
  };

  listCustomers = () => {
    const activeFilters = parseFromPars(this.props.location.search);
    const { freelancers } = this.props;

    const sortKeys = Object.keys(activeFilters.sort || []);
    const filteredData = freelancers.filter((user) => {
      let comparisonResult = true;

      // eslint-disable-next-line consistent-return
      Object.keys(activeFilters).forEach((key) => {
        switch (key) {
          case 'country':
            comparisonResult = user.personal_info && activeFilters[key] === user.personal_info?.country;
            break;
          case 'name':
            comparisonResult = activeFilters[key].trim().toLowerCase() === user.username.trim().toLowerCase();
            break;
          case 'role':
            comparisonResult = !checkRole([ activeFilters[key] ], user.roles);
            break;
          case 'inactive':
            comparisonResult = !!activeFilters[key] === !!user.inactive;
            break;
          default:
            return comparisonResult;
        }
      });

      return comparisonResult;
    });

    return orderBy(filteredData, sortKeys.map((key) => {
      return (row) => {
        const value = row[key];

        if (key !== 'username') {
          return value;
        }
        return value != null ? value.toString().toLowerCase() : '';
      };
    }), [ ...sortKeys.map((key) => activeFilters.sort[key]), 'desc' ]);
  };

  render () {
    const { lang = [] } = this.props;
    const {
      filter, customersViewTable, isMobile, selectedRoles,
    } = this.state;
    const { filterUser } = this.computed;
    const activeFilters = parseFromPars(this.props.location.search);

    return this.state.LOAD || this.state.LOAD_FREELANCERS ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={lang.CUSTOMERS}
          info={`${lang['GLOBAL.TOTAL']}: ${this.listCustomers().length}`}
          main={Object.keys(activeFilters).length > 0 && (
            <ViewFilters
              filters={activeFilters}
              onRemoveFilter={(key) => {
                const newFilter = { ...filter, skip: 0 };

                // eslint-disable-next-line fp/no-delete
                delete newFilter[key];

                this.setState({
                  filterUser: filterUser(),
                  selectedRoles: [],
                  filter: newFilter,
                }, () => {
                  const pars = parseToPars({ ...newFilter, show: undefined });

                  this.props.history.push(this.props.history.location.pathname + pars);
                });
              }}
            />
          )}
          toolbar={
            <div className='d-flex align-items-center'>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={'FILTERS'}>
                    {lang['GLOBAL.FILTERS']}
                  </Tooltip>
                }
              >
                <Button
                  variant='label-info'
                  className="btn-bold"
                  active={filter.show}
                  onClick={() => {
                    if (!filter.show) {
                      KTUtil.scrollTop(0, 300);
                    }

                    this.setState({
                      filter: {
                        ...filter,
                        show: !filter.show,
                      },
                    });
                  }}
                >
                  <i className='fa fa-filter kt-m-0 kt-p-0' />
                </Button>
              </OverlayTrigger>
              {!isMobile && (
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={'taskViewTable'}>
                      {lang[customersViewTable ? 'GLOBAL.CARDS_MODE' : 'GLOBAL.TABLE_MODE']}
                    </Tooltip>
                  }
                >
                  <Button
                    variant={'label-info'}
                    className="btn-bold"
                    onClick={() => {
                      localStorage.setItem('customers_type_view', customersViewTable ? '0' : '1');

                      this.setState({
                        customersViewTable: !customersViewTable,
                      });
                    }}
                  >
                    {customersViewTable
                      ? (<i className='flaticon-squares-1 kt-m-0 kt-p-0' />)
                      : (<i className='fa fa-th-list kt-m-0 kt-p-0' />)
                    }
                  </Button>
                </OverlayTrigger>
              )}
              {checkRole(this.props.userRoles, [ roles.administrator.key ]) && (
                <Button
                  onClick={() => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        show: true,
                      },
                    });
                  }}
                  variant='label-brand'
                  className='btn-bold kt-5'
                >{lang['CUSTOMER.ADD_CUSTOMER']}</Button>
              )}
              <div className='kt-ml-5'>
                <OverlayTrigger
                  placement="bottom"
                  overlay={
                    <Tooltip id={'COUNT_ON_PAGE'}>
                      {lang['GLOBAL.COUNT_ON_PAGE']}
                    </Tooltip>
                  }
                >
                  <Form.Group className='d-flex kt-m-0 kt-ml-5 align-items-center'>
                    <Form.Control
                      size="sm"
                      as='select'
                      value={this.state.countOnPage}
                      onChange={(event) => {
                        this.setState({
                          countOnPage: Number(event.target.value),
                        });
                      }}
                    >
                      <option value="10">10</option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                    </Form.Control>
                  </Form.Group>
                </OverlayTrigger>
              </div>
              <div className='kt-ml-5'>
                <Paginator
                  active={(this.state.filter.skip / this.state.countOnPage) || 0}
                  count={Math.floor(this.listCustomers().length / this.state.countOnPage) - (this.listCustomers().length % this.state.countOnPage === 0 ? 1 : 0)}
                  select={(newPage) => {
                    this.setState({
                      filter: {
                        ...filter,
                        skip: newPage * this.state.countOnPage,
                        show: false,
                      },
                    }, () => {
                      const pars = parseToPars({ ...this.state.filter, show: undefined });

                      this.props.history.push(this.props.history.location.pathname + pars);
                    });
                  }}
                />
              </div>
            </div>}
        />
        <div className='row'>
          {filter.show && (
            <form
              className="col-12 filters"
              onSubmit={(event) => {
                event.preventDefault();
                this.setState({
                  filter: {
                    ...filter,
                    skip: 0,
                    show: false,
                  },
                }, () => {
                  const pars = parseToPars({ ...filter, skip: 0, show: undefined });

                  this.props.history.push(this.props.history.location.pathname + pars);
                });
              }}
            >
              <div className="kt-portlet kt-portlet--height-fluid">
                <div className='kt-portlet__body'>
                  <div className='row'>
                    <Form.Group className="col-xl-4 col-sm-6 col-12 kt-mb-5">
                      <Form.Label>
                        {lang.ROLE}
                      </Form.Label>
                      <MySelect
                        name="role"
                        isMulti
                        className="kt-mb-15"
                        value={selectedRoles}
                        options={[ { label: 'Все', value: '' },
                          ...intersection(getRolesFromGroupsUsers(this.props.groupsRoles), executorsRoles).map((role) => ({
                            value: role,
                            label: roles[role].title,
                          })),
                        ]}
                        onChange={(value) => {
                          this.setState({
                            selectedRoles: value,
                            filter: {
                              ...filter,
                              role: value ? value.map((item) => item.value) : [],
                            },
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className='col-xl-4 col-sm-6 col-12 kt-mb-5'>
                      <Form.Label>
                        {lang['CUSTOMER.USERNAME']}
                      </Form.Label>
                      <MySelect
                        options={this.props.freelancers.map((user) => ({
                          value: user.id,
                          label: `${user.username}`,
                        }))}
                        value={filterUser()}
                        onChange={(value) => {
                          this.setState({
                            filter: {
                              ...filter,
                              id: value.value,
                              name: value.label,
                            },
                            filterUser: value,
                          });
                        }}
                      />
                    </Form.Group>
                    <Form.Group className='col-xl-4 col-sm-6 col-12 kt-mb-5'>
                      <Form.Label>
                        {lang['GLOBAL.STATUS']}
                      </Form.Label>
                      <div className='form-control'>
                        <Form.Check
                          inline
                          label={lang['CUSTOMER.ACTIVES']}
                          type='radio'
                          checked={filter.inactive === false || filter.inactive === 0}
                          onChange={(event) => {
                            if (event.target.checked) {
                              this.setState({
                                filter: {
                                  ...filter,
                                  inactive: false,
                                },
                              });
                            }
                          }}
                        />
                        <Form.Check
                          inline
                          label={lang['CUSTOMER.BLOCKED']}
                          type='radio'
                          checked={filter.inactive === true || filter.inactive === 1}
                          onChange={(event) => {
                            if (event.target.checked) {
                              this.setState({
                                filter: {
                                  ...filter,
                                  inactive: true,
                                },
                              });
                            }
                          }}
                        />
                        <Form.Check
                          inline
                          label={lang['CUSTOMER.ALL']}
                          type='radio'
                          checked={filter.inactive === '' || filter.inactive === undefined || filter.inactive === null}
                          onChange={(event) => {
                            if (event.target.checked) {
                              this.setState({
                                filter: {
                                  ...filter,
                                  inactive: '',
                                },
                              });
                            }
                          }}
                        />
                      </div>
                    </Form.Group>
                    <div className='col-12 kt-mb-5'>
                      <div className='float-right kt-mt-20'>
                        <Button type='submit'>
                          {lang['GLOBAL.APPLY']}
                        </Button>
                        <Button
                          as='span'
                          className='kt-ml-20'
                          variant='secondary'
                          onClick={() => {
                            this.setState({
                              filter: initialFilter,
                              filterUser: {},
                              selectedRoles: [],
                            }, () => {
                              const pars = parseToPars({
                                ...initialFilter,
                                skip: 0,
                                show: undefined,
                              });

                              this.props.history.push(this.props.history.location.pathname + pars);

                              KTUtil.scrollTop(0, 300);
                            });
                          }}
                        >
                          {lang['GLOBAL.RESET']}
                        </Button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </div>
        {customersViewTable && !isMobile ? (
          <div className='kt-portlet mw-100 overflow-auto table-wrapper'>
            <Table
              striped
              className='vertical-align-middle text-center kt-font-md'
              size="sm"
            >
              <ListCustomersTableHeader
                sort={filter.sort}
                setSort={(sort) => {
                  this.setState({
                    filter: {
                      ...this.state.filter,
                      sort,
                    },
                  }, () => {
                    const pars = parseToPars({ ...this.state.filter, show: undefined });

                    this.props.history.push(this.props.history.location.pathname + pars);
                  });
                }}
              />
              <tbody>
                {this.listCustomers()
                  .slice(activeFilters.skip || 0, (activeFilters.skip || 0) + this.state.countOnPage)
                  .map((customer) => {
                    return (
                      <CustomerItemTable
                        key={customer.id}
                        customer={customer}
                        user={this.props.user}
                      />
                    );
                  })}
              </tbody>
            </Table>
          </div>
        ) : (
          <div className="row">
            {this.listCustomers()
              .slice(activeFilters.skip || 0, (activeFilters.skip || 0) + this.state.countOnPage)
              .map((customer) => {
                return (
                  <CustomerItem
                    key={customer.id}
                    customer={customer}
                    user={this.props.user}
                  />
                );
              })}
          </div>
        )}
        <Modal
          as='form'
          onSubmit={async (event) => {
            event.preventDefault();
            await this.postInvitation();
          }}
          title={lang['CUSTOMER.INVITE']}
          show={this.state.modal.show}
          close={() => {
            this.setState({
              modal: {
                ...this.state.modal,
                show: false,
              },
            });
          }}
          bodyStyle={{ overflow: 'visible' }}
          footer={
            <>
              <Button
                variant="secondary"
                onClick={() => {
                  this.setState({
                    modal: {
                      ...this.state.modal,
                      show: false,
                    },
                  });
                }}
              >{lang['GLOBAL.CLOSE']}</Button>
              <Button type='submit' variant="primary">
                {lang['CUSTOMER.SEND_NOTIFICATION']}
              </Button>
            </>
          }
        >
          <div style={{ margin: '2%' }}>
            <Link
              to={urlPageInvitations()}
              className="btn btn-outline-secondary btn-sm btn-block"
            >
              {lang['CUSTOMER.LIST_ALL_INVITES']}
            </Link>
          </div>
          <div className="kt-portlet__body">
            <div className="form-group form-group-last">
              <div className="alert alert-secondary">
                <div className="alert-icon">
                  <i className="flaticon-warning kt-font-brand" />
                </div>
                <div className="alert-text">
                  {lang['CUSTOMER.FOR_INVITE']}
                </div>
              </div>
            </div>
            <Form.Group className="row kt-mb-15">
              <Form.Label column className="col-4">
                {lang['GLOBAL.EMAIL']}
              </Form.Label>
              <div className="col-8 d-flex align-items-center">
                <Form.Control
                  type="email"
                  value={this.state.modal.email}
                  isInvalid={!this.state.modal.email && this.state.modal.isInvalid}
                  onChange={(event) => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        email: event.target.value,
                      },
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {lang['GLOBAL.FIELD_REQUIRED']}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="row kt-mb-15">
              <Form.Label column className="col-4">
                {lang.ROLE}
              </Form.Label>
              <div className="col-8 d-flex align-items-center">
                <MySelect
                  name="role"
                  isMulti
                  className="kt-mb-15"
                  isInvalid={!this.state.modal.inviteRole && this.state.modal.isInvalid}
                  value={this.state.modal.inviteRole || []}
                  options={realRoles.map((role) => ({
                    value: role,
                    label: roles[role].title,
                  }))}
                  onChange={(value) => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        inviteRole: value,
                      },
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {lang['GLOBAL.FIELD_REQUIRED']}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="row kt-mb-15">
              <Form.Label column className="col-4">
                {lang['CUSTOMER.TIME_LIVE_LINK']}
              </Form.Label>
              <div className="col-8 d-flex flex-column">
                <MyDatePicker
                  className='w-100'
                  selected={this.state.modal.expires ? formatStringToDate(this.state.modal.expires) : ''}
                  minDate={new Date()}
                  onChange={(date) => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        expires: formatDateToString(date),
                      },
                    });
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {lang['GLOBAL.FIELD_REQUIRED']}
                </Form.Control.Feedback>
              </div>
            </Form.Group>
            <Form.Group className="row kt-mb-0">
              <Form.Label column className="col-4">
                {lang['CUSTOMER.EMPLOYEE']}
              </Form.Label>
              <div className="col-8 d-flex align-items-center">
                <Form.Check
                  type="checkbox"
                  checked={this.state.modal.employee}
                  onChange={(event) => {
                    this.setState({
                      modal: {
                        ...this.state.modal,
                        employee: event.target.checked,
                      },
                    });
                  }}
                />
              </div>
            </Form.Group>
          </div>
        </Modal>
      </>
    );
  }

  componentDidMount () {
    if (!isEmptyObject(this.props.users)) {
      this.props.history.push('?inactive=false&skip=0');
      this.setComponent();
    }
  }

  componentDidUpdate (prevProps) {
    if (isEmptyObject(this.props.location.search)) {
      this.props.history.push('?inactive=false&skip=0');
    }
    if (prevProps.users.length < this.props.users.length) {
      this.setComponent();
    }

    if (this.props.location.search !== prevProps.location.search) {
      if (this.props.history.location.search) {
        const pars = parseFromPars(this.props.history.location.search);

        if (pars.inactive !== undefined) {
          pars.inactive = !!pars.inactive;
        }

        this.props.setFreelancers(this.state.freelancersInitial);
        this.props.filterFreelancers(pars);

        this.setState({
          filter: {
            project_type: '',
            status: '',
            ...pars,
          },
          modal: {
            ...this.state.modal,
            show: false,
          },
        });
      } else {
        this.props.setFreelancers(this.state.freelancersInitial);
        this.setState({
          selectedRoles: [],
          filter: {
            ...initialFilter,
            project_type: '',
            status: '',
          },
          modal: {
            ...this.state.modal,
            show: false,
          },
        });
      }
    } else if (
      this.props.match.params.status !== prevProps.match.params.status || this.props.match.params.type !== prevProps.match.params.type
    ) {
      this.setState({
        modal: {
          ...this.state.modal,
          show: false,
        },
      });
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.eventResize);
  }

  setComponent = () => {
    if (this.props.location.search) {
      const pars = parseFromPars(this.props.history.location.search);

      if (typeof pars.inactive === 'boolean') {
        pars.inactive = !!pars.inactive;
      }
      this.setState({
        filter: {
          ...initialFilter,
          ...pars,
        },
        filterUser: {},
      }, async () => {
        this.getFreelancers();
      });
    } else {
      this.getFreelancers();
    }

    window.addEventListener('resize', this.eventResize);
  };

  eventResize = () => {
    const isMd = isScreenMd();

    if (this.state.isMobile && !isMd) {
      this.setState({
        isMobile: false,
      });
    } else if (!this.state.isMobile && isMd) {
      this.setState({
        isMobile: true,
      });
    }
  };

  getFreelancers = () => {
    const params = this.props.history.location.search ? parseFromPars(this.props.history.location.search) : {};

    this.setState({
      LOAD_FREELANCERS: true,
    });

    this.props.getFreelancers(params).then((response) => {
      this.setState({
        LOAD_FREELANCERS: false,
        freelancersInitial: response,
      });
    })
      .catch(() => {
        this.setState({
          LOAD_FREELANCERS: false,
        });
      });
  };

  postInvitation = () => {
    const { modal } = this.state;
    const invitation = {
      email: modal.email,
      expires: modal.expires,
      role: modal.inviteRole.map((item) => item.value),
      is_employee: modal.employee,
    };

    if (!modal.email || !modal.inviteRole || !modal.expires) {
      this.setState({
        modal: {
          ...modal,
          isInvalid: true,
        },
      });

      return;
    }
    this.setState({
      LOAD: true,
      modal: {
        ...modal,
        isInvalid: false,
      },
    });

    axiosApiInstance.post('invitations', invitation)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_SEND_INVITE', 'success');

        this.setState({
          modal: {
            show: false,
            isInvalid: false,
            email: '',
            inviteRole: [],
            expires: this.state.modal.expires,
            employee: false,
          },
          LOAD: false,
        });
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_SEND_INVITE', 'error');

          this.setState({
            LOAD: false,
          });
        }
      });
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
    groupsRoles: store.user.groups,
    freelancers: store.freelancers.freelancers,
    users: store.users,
    user: store.user.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
    getFreelancers: bindActionCreators(freelancerAction.getFreelancers, dispatch),
    filterFreelancers: bindActionCreators(freelancerAction.filterFreelancers, dispatch),
    setFreelancers: bindActionCreators(freelancerAction.setFreelancers, dispatch),
    getUsersAll: bindActionCreators(getUsersAll, dispatch),
  };
};

ListCustomers.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(ListCustomers);
