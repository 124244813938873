import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { actions } from '../ducks/builder';
import { LayoutSplashScreen } from './LayoutContext';

/**
 * Used to synchronize current layout `menuConfig`, `layoutConfig` and
 * `htmlClassService` with redux store.
 */
export default function LayoutInitializer ({
  children,
  layoutConfig,
  htmlClassService,
}) {
  const dispatch = useDispatch();
  const builderState = useSelector(({ builder }) => builder);

  useEffect(() => {
    if (layoutConfig.demo !== builderState.layoutConfig.demo) {
      dispatch(actions.setLayoutConfigs(layoutConfig));
    }
  }, [ dispatch, builderState, layoutConfig ]);

  useEffect(() => {
    dispatch(actions.setHtmlClassService(htmlClassService));
  }, [ dispatch, htmlClassService ]);

  return htmlClassService === builderState.htmlClassServiceObjects ? (
  // Render content when `htmlClassService` synchronized with redux store.
    <>{children}</>
  ) : (
  // Otherwise sow loading splash screen.
    <LayoutSplashScreen />
  );
}
