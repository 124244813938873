/**
 * Types comments
 *
 * @type {{refuseTask: number, chat: number, createProject: number, reopenTask: number}}
 */
const typesComments = {
  reopenTask: 0,
  refuseTask: 1,
  createProject: 2,
  chat: 3,
};

/**
 * Transform type comment to color name
 *
 * @param {number} type
 * @returns {string}
 */
export const typeCommentToColor = (type) => {
  switch (type) {
    case typesComments.reopenTask: {
      return 'danger';
    }
    case typesComments.refuseTask: {
      return 'warning';
    }
    case typesComments.createProject: {
      return 'success';
    }
    case typesComments.chat:
    default: {
      return 'brand';
    }
  }
};

export default typesComments;
