/**
 * return true if screen width <= 576
 *
 * @returns {boolean}
 */
export const isScreenSm = () => window.innerWidth <= 576;
/**
 * return true if screen width <= 768
 *
 * @returns {boolean}
 */
export const isScreenMd = () => window.innerWidth <= 768;
/**
 * return true if screen width <= 1024
 *
 * @returns {boolean}
 */
export const isScreenLg = () => window.innerWidth <= 1024;
/**
 * return true if screen width <= 1399
 *
 * @returns {boolean}
 */
export const isScreenXl = () => window.innerWidth <= 1399;
