import React from 'react';
import { typesFields } from '../projectTypes';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../../tasks/taskTypes';
import isCategoryInProjectCategories from '../helpers/isCategoryInProjectCategories';
import roles from '../../customers/roles/roles';
import { complexityLevels } from '../../tasks/complexityLevels';

/**
 * Selects input depending on type and puts it in the necessary container
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {string} prefix
 * @param {boolean} required
 * @returns {null|*}
 */
// eslint-disable-next-line consistent-return
export default function _switchInput (field, value = null, onChange = null, project, prefix, required) {
  switch (field.typeFields) {
    case typesFields.input: {
      return (
        <div className={field.isFull ? 'col-xl-3 col-md-6 col-12' : 'col-12'}>
          {this._buildInput(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    case typesFields.options: {
      if (
        field.key === complexityLevels.contour_complexity_level
        || field.key === complexityLevels.color_complexity_level
      ) {
        return null;
      }
      return (
        <div className={field.isFull ? 'col-xl-3 col-md-6 col-12' : 'col-12'}>
          {this._buildSelector(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    case typesFields.optionsSearch: {
      if (field.subgroup === 'executors') {
        field = {
          ...field,
          options: this.props.users.filter((user) => {
            return checkRole(user.roles, [ taskTypes[field.key || field.keys[0]].role ]) && !user.inactive;
          })
            .sort((first, second) => {
              return first.firstname.localeCompare(second.firstname);
            })
            .map((item) => {
              return {
                value: item.id,
                label: `${item.username}`,
              };
            }),
        };
      }
      if (field.key === 'category_id') {
        field = {
          ...field,
          options: this.props.categories.filter((category) => {
            return !isCategoryInProjectCategories(project, category);
          }).map((category) => {
            return {
              value: category.id,
              label: category.name,
            };
          }),
        };
      }
      return (
        <div className={field.isFull ? 'col-xl-3 col-md-6 col-12' : 'col-12'}>
          {this._buildSelectorSearch(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    case typesFields.date: {
      return (
        <div className={field.isFull ? 'col-xl-3 col-md-6 col-12' : 'col-12'}>
          {this._buildDate(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    case typesFields.textarea: {
      return (
        <div className='col-12'>
          {this._buildTextarea(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    case typesFields.htmlEditor: {
      return (
        <div className='col-12'>
          {this._buildHtmlEditor(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    case typesFields.inputSelect: {
      if (field.key === 'tags') {
        return (
          <div className='col-12'>
            {this._buildInputSelect(field, [], value, onChange, project, prefix, required)}
          </div>
        );
      }
      if (field.key === 'categories' && (checkRole(this.props.cRoles, [ roles['content-manager'].key ]))) {
        return (
          <div className='col-12'>
            {this._buildInputSelect(field, [], value, onChange, project, prefix, required)}
          </div>
        );
      }
      break;
    }
    case typesFields.switch: {
      return (
        <div className='col-12'>
          {this._buildSwitch(field, value, onChange, project, prefix, required)}
        </div>
      );
    }
    default: {
      return null;
    }
  }
}
