import React from 'react';
import ImgList from '../../../widgets/ImgList';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';

/**
 * Builds blocks with preview images for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { lang } = this.props;
  const { previewImages, referencePreviewImages, sizing } = this.computed;

  return (
    <>
      {(previewImages.length > 0) && (
        <ImgList
          classNameBody={`col-xl-${sizing.xl} col-lg-${sizing.lg} col-md-${sizing.md} col-sm-${sizing.sm} col-12`}
          title={lang['GLOBAL.IMAGE_PREVIEW']}
          files={previewImages.map((file) => ({
            name: file.name || file.prefix,
            link: (file.data && file.data.preview) || file.link,
          }))}
        />
      )}
      {checkRole(this.props.userRoles, [ roles['content-manager'].key ]) && referencePreviewImages.length > 0 && (
        <ImgList
          classNameBody={`col-xl-${sizing.xl} col-lg-${sizing.lg} col-md-${sizing.md} col-sm-${sizing.sm} col-12`}
          title={lang['GLOBAL.REFERENCE_IMAGES']}
          files={referencePreviewImages.map((file) => ({
            name: file.name,
            link: file.link,
          }))}
        />
      )}
    </>
  );
}
