const CURRENT_URL = window.location.origin;

const baseUrlHasSlash = String(CURRENT_URL).endsWith('/');

if (baseUrlHasSlash) {
  throw new ReferenceError(`baseUrl (${CURRENT_URL}) has trailing slash`);
}

export default (builder) => (...paramObjects) => {
  const url = builder(Object.assign({}, ...paramObjects));

  const urlHasSlash = String(url).startsWith('/');

  if (!baseUrlHasSlash && urlHasSlash) {
    return url;
  }

  if (!urlHasSlash) {
    throw new ReferenceError(`url (${url}) not start with slash`);
  }

  if (baseUrlHasSlash && !urlHasSlash) {
    return url;
  }

  if (baseUrlHasSlash && urlHasSlash) {
    return url.slice(0);
  }

  return url;
};
