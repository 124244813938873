import ReactTour from 'reactour';
import Button from 'react-bootstrap/Button';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock/lib/bodyScrollLock';
import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import tasks from './tasks';



const propTypes = {
  lang: PropTypes.object.isRequired,
  setStep: PropTypes.any.isRequired,
  end: PropTypes.any.isRequired,
  selectFiles: PropTypes.any.isRequired,
  finishTask: PropTypes.any.isRequired,
};

class Tour extends React.Component {
  state = {
    maskSpace: 10,
    rounded: 5,
  };

  refTour = React.createRef();

  step = 0;

  render () {
    const { lang } = this.props;

    return (
      <ReactTour
        ref={this.refTour}
        rounded={this.state.rounded}
        maskSpace={this.state.maskSpace}
        inViewThreshold={10}
        isOpen
        showNumber={false}
        showNavigation={false}
        showNavigationScreenReaders={false}
        disableInteraction
        closeWithMask={false}
        showCloseButton={false}
        disableKeyboardNavigation
        nextButton={<></>}
        prevButton={<></>}
        className={'my-tour my-tour--tasks'}
        getCurrentStep={(step) => {
          this.step = step;
        }}
        steps={[
          {
            content: (data) => {
              return [
                <div
                  key='step1'
                  className='text-dark'
                >
                  <h4 className='text-center'>{lang['TOUR.TASKS.WELCOME']}</h4>
                  <h4 className='text-center kt-font-bold'>Bee Freelancer</h4>
                  <div
                    className='text-center kt-font-regular kt-mb-10'
                  >{lang['TOUR.TASKS.WELCOME_INFO']}</div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
            position: 'center',
          },
          {
            selector: '[data-tour="aside-tasks"]',
            content: (data) => {
              return [
                <div
                  className='text-dark kt-font-regular'
                  key='step2'
                >
                  <div className='text-center kt-mb-10'>
                    {lang['TOUR.TASKS.FOR_START']}
                  </div>
                  <div className='text-center kt-mb-10'>
                    {lang['TOUR.TASKS.FOR_START_2']}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
            action: (item) => {
              item.classList.add('kt-menu__item--hover');
            },
          },
          {
            selector: '[data-tour="aside-tasks"] > .kt-menu__submenu',
            content: (data) => {
              return [
                <div
                  className='text-dark kt-font-regular'
                  key='step3'
                >
                  <div className='kt-mb-10'>
                    {lang['TOUR.TASKS.TASKS_TYPES']}:
                  </div>
                  <ul>
                    <li>
                      <b>{lang['MENU.AVAILABLE']}</b>
                      <span className='kt-ml-5'>-</span>
                      <span className='kt-ml-5'>{lang['TOUR.TASKS.TASKS_TYPES.AVAILABLE']}</span>
                    </li>
                    <li>
                      <b>{lang['MENU.MY']}</b>
                      <span className='kt-ml-5'>-</span>
                      <span className='kt-ml-5'>{lang['TOUR.TASKS.TASKS_TYPES.MY']}</span>
                    </li>
                    <li>
                      <b>{lang['MENU.REVIEW']}</b>
                      <span className='kt-ml-5'>-</span>
                      <span className='kt-ml-5'>{lang['TOUR.TASKS.TASKS_TYPES.REVIEW']}</span>
                    </li>
                    <li>
                      <b>{lang['MENU.FINISHED']}</b>
                      <span className='kt-ml-5'>-</span>
                      <span className='kt-ml-5'>{lang['TOUR.TASKS.TASKS_TYPES.FINISHED']}</span>
                    </li>
                  </ul>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
          },
          {
            position: 'right',
            selector: '[data-tour="aside-tasks-available"]',
            stepInteraction: true,
            style: {
              borderLeft: '1px solid rgba(0,0,0,0.7)',
              borderRadius: 5,
            },
            content: () => {
              return [
                <div
                  key='step4'
                  className='text-dark kt-font-regular'
                >
                  <div className='text-center kt-mb-0'>
                    {lang['TOUR.TASKS.GO_TO_LIST']}
                                        &nbsp;
                    <b>
                      {lang['TOUR.TASKS.GO_TO_LIST2']}
                    </b>
                  </div>
                </div>,
              ];
            },
            action: () => {
              const item = document.querySelector('[data-tour="aside-tasks"]');

              item.addEventListener('mouseleave', this.mouseLeaveFromAsideItem);
              const link = document.querySelector('[data-tour="aside-tasks-available"] > a');

              link.addEventListener('click', this.clickAvailableTasks);
              if (this.state.maskSpace !== 0 || this.state.rounded !== 0) {
                this.setState({
                  maskSpace: 0,
                  rounded: 0,
                });
              }
            },
          },
          {
            position: 'top',
            selector: '#kt_content',
            stepInteraction: false,
            style: {
              maxWidth: '700px',
              width: '100%',
              borderBottom: '1px solid rgba(0,0,0,0.7)',
              borderRadius: 5,
            },
            content: (data) => {
              return [
                <div
                  key='step5'
                  className='text-dark kt-font-regular'
                >
                  <div className='kt-mb-0'>
                    {lang['TOUR.TASKS.LIST']}
                                        &nbsp;
                    <div className='kt-font-boldest kt-mb-0 d-inline'>
                      {` (${tasks.artist.map((task) => task.title).join(', ')}).`}
                    </div>
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                        this.props.setStep('1');
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
            action: () => {
              const item = document.querySelector('[data-tour="aside-tasks"]');

              item.removeEventListener('mouseleave', this.mouseLeaveFromAsideItem);
              const link = document.querySelector('[data-tour="aside-tasks-available"] > a');

              link.removeEventListener('click', this.clickAvailableTasks);
            },
          }, {
            selector: '#kt_content',
            position: 'top',
            style: {
              maxWidth: '700px',
              width: '100%',
              borderBottom: '1px solid rgba(0,0,0,0.7)',
              borderRadius: 5,
            },
            content: (data) => {
              return [
                <div
                  key='step6'
                  className='text-dark kt-font-regular'
                >
                  <div className='text-center kt-mb-10'>
                    {lang['TOUR.TASKS.PAGE_TASK']}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
          },
          {
            selector: '.kt-widget__action > button.btn.btn-brand',
            position: 'bottom',
            stepInteraction: true,
            content: () => {
              return [
                <div
                  key='step7'
                  className='text-dark kt-font-regular'
                >
                  <div className='text-center kt-mb-0'>
                    {`${lang['TOUR.TASKS.START_WORK']} "${lang['GLOBAL.START_WORK']}"`}
                  </div>
                </div>,
              ];
            },
            action: () => {
              if (this.state.maskSpace !== 10 || this.state.rounded !== 5) {
                this.setState({
                  maskSpace: 10,
                  rounded: 5,
                });
              }
            },
          },
          {
            selector: '.kt-portlet.kt-bg-success',
            position: 'top',
            content: (data) => {
              return [
                <div
                  key='step8'
                  className='text-dark kt-font-regular'
                >
                  <div className='text-center kt-mb-10'>
                    {lang['TOUR.TASKS.AFTER_START']}.
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                        this.props.selectFiles();
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
          },
          {
            selector: '.kt-portlet.kt-bg-success',
            position: 'right',
            content: (data) => {
              return [
                <div
                  key='step9'
                  className='text-dark kt-font-regular'
                >
                  <div className='text-center kt-mb-10'>
                    {lang['TOUR.TASKS.ADDITIONAL_INFO']}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='info'
                      size='sm'
                      onClick={() => {
                        data.goTo(data.step);
                      }}
                    >
                      {lang['GLOBAL.NEXT']}
                    </Button>
                  </div>
                </div>,
              ];
            },
          },
          {
            selector: '',
            position: 'center',
            content: () => {
              return [
                <div
                  className='text-dark'
                  key='step10'
                >
                  <h3 className='text-center kt-font-bold'>
                    {lang['TOUR.TASKS.FINISH']}
                  </h3>
                  <div className='text-center kt-font-regular kt-mb-10'>
                    {lang['TOUR.TASKS.FINISH2']}
                  </div>
                  <div className='d-flex justify-content-center'>
                    <Button
                      variant='success'
                      size='sm'
                      onClick={() => {
                        this.props.end();
                      }}
                    >
                      {lang['TOUR.TASKS.TO_WORK']}
                    </Button>
                  </div>
                </div>,
              ];
            },
            action: () => {
              if (!this.finishTask) {
                this.finishTask = true;
                this.props.finishTask();
              }
            },
          },
        ]}
        scrollDuration={50}
        onAfterOpen={() => {
          disableBodyScroll(window.root);
        }}
        onBeforeClose={() => {
          enableBodyScroll(window.root);
        }}
      />
    );
  }

  componentWillUnmount () {
    enableBodyScroll(window.root);
    const item = document.querySelector('[data-tour="aside-tasks"]');

    item.removeEventListener('mouseleave', this.mouseLeaveFromAsideItem);
    const link = document.querySelector('[data-tour="aside-tasks-available"] > a');

    link.removeEventListener('click', this.clickAvailableTasks);
  }

  mouseLeaveFromAsideItem = (event) => {
    event.stopPropagation();
    event.preventDefault();
    event.target.classList.add('kt-menu__item--hover');
  };

  clickAvailableTasks = (event) => {
    event.stopPropagation();
    event.preventDefault();
    const item = document.querySelector('[data-tour="aside-tasks"]');

    item.removeEventListener('mouseleave', this.mouseLeaveFromAsideItem);
    item.classList.remove('kt-menu__item--hover');
    const link = document.querySelector('[data-tour="aside-tasks-available"] > a');

    link.removeEventListener('click', this.clickAvailableTasks);
    this.props.setStep('list');
    this.refTour.current.gotoStep(this.step + 1);
  };

  nextStep = () => {
    this.refTour.current.gotoStep(this.step + 1);
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userId: store.user.user.id,
  };
};

Tour.propTypes = propTypes;

export default connect(mapStoreToProps, null, null, { forwardRef: true })(Tour);
