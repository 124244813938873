import React from 'react';

/**
 * Adds calculated properties that are calculated only when
 * the state properties or props used in the calculated function change.
 *
 * For use add field "computed"
 *
 * @abstract
 */
class MyComponent extends React.PureComponent {
  /**
   * Updates properties for which dependencies have changed.
   *
   * @param {any} state
   * @param {any} props
   * @private
   * @returns {*}
   */
  _computedData = (state, props) => {
    this.__computedUpdate = {};
    this.__keysComputed.forEach((key) => {
      this.computed[key] = this.__computed[key](state, props, key);
    });
  };

  /**
   * Substitutes getters that calculate the value, cache and determine
   * dependencies instead of functions in the properties
   *
   * @returns {*}
   */
  UNSAFE_componentWillMount () {
    this.__computed = this.computed ? { ...this.computed } : {};
    this.__keysComputed = Object.keys(this.__computed);
    this.__computedUpdate = {};

    const state = {};
    const props = {};

    const keys = {
      state: {},
      props: {},
      computed: {},
    };

    Object.keys(this.state || {}).forEach((key) => {
      Object.defineProperty(state, key, {
        get: () => {
          keys.state[key] = true;
          return this.state[key];
        },
      });
    });

    Object.keys(this.props || []).forEach((key) => {
      Object.defineProperty(props, key, {
        get: () => {
          keys.props[key] = true;
          return this.props[key];
        },
      });
    });

    this._computed = {};

    this.__keysComputed.forEach((key) => {
      keys.state = [];
      keys.props = [];
      keys.computed = [];

      this._computed[key] = this.__computed[key](state, props);

      this.computed[key] = this._computed[key];

      Object.defineProperty(this.computed, key, {
        get: () => {
          keys.computed[key] = true;
          return this._computed[key];
        },
      });

      keys.state = Object.keys(keys.state);
      keys.props = Object.keys(keys.props);
      keys.computed = Object.keys(keys.computed);

      const _keys = { ...keys };
      const func = this.__computed[key];

      this.__computed[key] = (state, props, name) => {
        let value = false;

        // eslint-disable-next-line no-restricted-syntax,fp/no-loops
        for (const key of _keys.state) {
          if (state[key] !== this.state[key]) {
            value = true;
            break;
          }
        }

        if (!value) {
          // eslint-disable-next-line no-restricted-syntax,fp/no-loops
          for (const key of _keys.props) {
            if (props[key] !== this.props[key]) {
              value = true;
              break;
            }
          }
        }

        if (!value) {
          // eslint-disable-next-line no-restricted-syntax,fp/no-loops
          for (const key of _keys.computed) {
            if (this.__computedUpdate[key]) {
              value = true;
              break;
            }
          }
        }

        if (value) {
          this.__computedUpdate[name] = true;
          return func(state, props);
        }
        return this.computed[name];
      };
    });

    this.computed = {};
    this.__keysComputed.forEach((key) => {
      this.computed[key] = this._computed[key];
    });

    // eslint-disable-next-line fp/no-delete
    delete this._computed;
  }

  /**
   * If changes state or props updates properties for which dependencies have changed
   *
   * @param {any} nextProps
   * @param {any} nextState
   * @returns {void}
   */
  UNSAFE_componentWillUpdate (nextProps, nextState) {
    if (nextProps !== this.props || this.state !== nextState) {
      this._computedData(nextState, nextProps);
    }
  }
}

export default MyComponent;
