import { entityTypes } from '../../config/config';



export const transformToRequest = (data, tagGroup) => {
  return {
    name: data.name,
    type: entityTypes.tag,
    parents: [ data.parent?.value, tagGroup.id ].filter((item) => !!item),
    children: [],
    options: [],
  };
};

export const transformChildToRequest = (data, parentId) => {
  return {
    name: data.name,
    type: entityTypes.tag,
    parents: [ ...data.parent, parentId ].filter((item) => !!item),
    children: [],
    options: [],
  };
};
