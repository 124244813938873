import React from 'react';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import PropTypes, { shape } from 'prop-types';
import checkRole from '../../../../utils/checkRole';
import roles from '../../../customers/roles/roles';
import { defaultCategoryValue, mapCategoryIdToName } from '../../../../requests/categories';
import { priorityToColor } from '../../../projects/priorities';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../../utils/formats';
import canEditImgType from '../../helpers/canEditTypeImage';
import urlPageFreelancerDetails from '../../../../urls/urlPageFreelancerDetails';
import { getClassesForDeadline } from '../../../../utils/getClassesForDeadline';
import groupsRoles from '../../../customers/roles/groupsRoles';



const WidgetContent = ({ task, userId, showModal, showContourComplexityLevelModal, showColorComplexityLevelModal }) => {
  const lang = useSelector((store) => store.language.lang);
  const userRoles = useSelector((store) => store.user.roles);
  const categories = useSelector((store) => store.categories);

  const canSeeComplexityLevels = checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, ...groupsRoles.editors.all ]);

  return (
    <div className="kt-widget__content">
      <div className="kt-widget__info row">
        <div className='col-xl-5'>
          <div
            className="kt-widget__desc"
            style={{ fontFamily: '\'Open Sans\', \'Helvetica Neue\', Helvetica, Arial, sans-serif' }}
          >
            <div className="kt-widget__head">
              <a className="kt-widget__title no-hover kt-font-dark">{(task.project && task.project.title) || `Task #${task.project_id}`}</a>
            </div>
            {task.executor && (
              <div className="kt-widget__subhead">
                <Link
                  className='font-weight-bold kt-font-dark'
                  to={urlPageFreelancerDetails({ freelancerId: task.executor.id })}
                >
                  <i className="flaticon-user" />
                  {task.executor?.username}
                </Link>
              </div>
            )}
            {task.type && (
              <div className="kt-widget__subhead">
                <span className='no-hover font-weight-bold kt-font-dark'>
                  {lang[`TASK.TYPES.${task.type}`]}
                </span>
              </div>
            )}
            {(task.project && task.project.description) && (
              <div className="kt-widget__subhead kt-font-dark">
                {(task.project && task.project.description)}
              </div>
            )}
            {(checkRole(userRoles, [ roles['coloring-designer'].key, roles['coloring-editor'].key, roles.manager.key, roles['content-manager'].key ]) && task.project.options && task.project.options.resourceLink) && (
              <div className="kt-widget__subhead">
                <a rel="noreferrer" href={task.project.options.resourceLink} target="_blank" className="kt-nav__link" style={{ color: '#2c77f4' }}>
                  {lang['GLOBAL.RESOURCE_LINK']}
                </a>
              </div>
            )}
          </div>
        </div>
        <div className='col-xl-7'>
          <div className="row">
            <div className="col-sm-4">
              {task.project && task.project.priority && (
                <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                  <span className="kt-widget__date col-7">
                    {lang['GLOBAL.PRIORITY']}
                  </span>
                  <div className="kt-widget__label col-5">
                    <span className={`btn btn-label-${priorityToColor(task.project.priority)} font-weight-bold btn-sm bg-transparent`}>
                      {lang[`GLOBAL.PRIORITY.${task.project.priority}`]}
                    </span>
                  </div>
                </div>
              )}
              <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                <span className="kt-widget__date col-7">
                  {lang['TASK.BEGIN']}
                </span>
                <div className="kt-widget__label col-5">
                  <span className="btn btn-sm btn-bold btn-upper bg-transparent">
                    {formatToPrettyDate(task.started_at) || '---'}
                  </span>
                </div>
              </div>
              { checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.editors.all ]) && <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                <span className="kt-widget__date col-7">
                  {lang['PROJECT.DEADLINE']}
                </span>
                <div className="kt-widget__label col-5">
                  <span className={getClassesForDeadline(task.project.deadline_to)}>
                    {formatToPrettyDateOrTime(task.project.deadline_to) || '---'}
                  </span>
                </div>
              </div>}
              <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                <span className="kt-widget__date col-7">
                  {lang['PROJECT.DIFFICULTY']}
                </span>
                <div className="kt-widget__label col-5">
                  <span className="btn btn-sm btn-bold btn-upper bg-transparent">
                    {task.project.options.difficult || '---'}
                  </span>
                </div>
              </div>

            </div>
            <div className="col-sm-4 offset-2">
              {task.project && categories && (
                <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                  <span className="kt-widget__date col-7">
                    {lang['GLOBAL.CATEGORY']}
                  </span>
                  <div className="kt-widget__label col-5">
                    <span className="btn btn-sm btn-bold btn-upper bg-transparent kt-pl-0">
                      {mapCategoryIdToName(categories, task.project.options.category_id) || defaultCategoryValue}
                    </span>
                  </div>
                </div>
              )}
              {task.project && task.project.options && task.project.options.type && (
                <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                  <span className="kt-widget__date col-7">
                    {lang['PROJECT.FIELDS.type']}
                  </span>
                  <div className="kt-widget__label col-5">
                    <Button
                      as={canEditImgType(userRoles, task, userId) ? 'button' : 'span'}
                      variant={'label-brand'}
                      size={'sm'}
                      className="btn-bold btn-upper"
                      onClick={() => {
                        if (canEditImgType(userRoles, task, userId)) {
                          showModal(true, task.id);
                        }
                      }}
                    >
                      {lang[`PROJECT.FIELDS.type.${task.project.options.type.toLowerCase()}`].toUpperCase()}
                    </Button>
                  </div>
                </div>
              )}
              {canSeeComplexityLevels && task.project.options?.contour_complexity_level && (<div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                <span className="kt-widget__date col-7">
                  {lang['PROJECT.FIELDS.contour_complexity_level']}
                </span>
                <div className="kt-widget__label col-5">
                  <Button
                    as={canEditImgType(userRoles, task, userId) ? 'button' : 'span'}
                    variant="label-brand"
                    size="sm"
                    className="btn-bold btn-upper"
                    onClick={() => {
                      if (canEditImgType(userRoles, task, userId)) {
                        showContourComplexityLevelModal(true, task.id);
                      }
                    }}
                  >
                    {task.project.options?.contour_complexity_level}
                  </Button>
                </div>
              </div>)}
              {canSeeComplexityLevels && task.project.options?.color_complexity_level && (<div className="kt-widget__item d-flex align-items-baseline kt-mb-10 ">
                <span className="kt-widget__date col-7">
                  {lang['PROJECT.FIELDS.color_complexity_level']}
                </span>
                <div className="kt-widget__label col-5">
                  <Button
                    as={canEditImgType(userRoles, task, userId) ? 'button' : 'span'}
                    variant="label-brand"
                    size="sm"
                    className="btn-bold btn-upper"
                    onClick={() => {
                      if (canEditImgType(userRoles, task, userId)) {
                        showColorComplexityLevelModal(true, task.id);
                      }
                    }}
                  >
                    {task.project.options.color_complexity_level}
                  </Button>
                </div>
              </div>)}
              <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                <span className="kt-widget__date col-7">
                  {lang['PROJECT.TAPS']}
                </span>
                <div className="kt-widget__label col-5">
                  <span className="btn btn-sm btn-bold btn-upper bp-transparent kt-pl-0">
                    {task.project.options.taps || '---'}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

WidgetContent.propTypes = {
  task: shape({
    project: PropTypes.object,
    executor: PropTypes.object,
  }).isRequired,
  userId: PropTypes.number,
  showModal: PropTypes.func.isRequired,
  showContourComplexityLevelModal: PropTypes.func.isRequired,
  showColorComplexityLevelModal: PropTypes.func.isRequired,
};

export default WidgetContent;
