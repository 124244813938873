import { actionsNotifications } from '../store/notification';
import { NOTIFICATIONS_TYPES } from '../widgets/Notification';



export const notification = (data, notificationType, optionalMessages = []) => (dispatch, getState) => {
  const store = getState();

  try {
    dispatch(actionsNotifications.add(store.language.lang[data], NOTIFICATIONS_TYPES[notificationType], optionalMessages));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export default notification;
