import url from './url';



export default url(({ queryParams }) => {
  const url = '/tag-system/templates';

  if (queryParams) {
    return `${url}?${queryParams}`;
  }

  return url;
});
