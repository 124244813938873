import axios from 'axios';
import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';
import { API_URL } from '../../utils/gettersUrl';
import termActions from '../term/actions';
import policyActions from '../policy/actions';
import axiosApiInstance from '../../requests/utils/api';
// eslint-disable-next-line import/no-named-as-default
import notification from '../../requests/notifications';
import { getErrorMessage } from '../../utils/getErrorMessage';
import config from '../../../config/app';



const actions = {
  LOADING: 'LOADING',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SET_USER: 'SET_USER',
  SET_ROLES: 'SET_ROLES',
  SET_CURRENT_ROLE: 'SET_CURRENT_ROLE',
  READ_NOTIFICATIONS: 'READ_NOTIFICATIONS',
  ADD_NOTIFICATION: 'ADD_NOTIFICATION',
  login: (payload) => ({ type: actions.LOGIN, payload }),
  logout: () => ({ type: actions.LOGOUT }),
  setLoading: (payload) => ({ type: actions.LOADING, payload }),
  setRoles: (payload) => ({ type: actions.SET_ROLES, payload }),
  setCurrentRole: (payload) => ({ type: actions.SET_CURRENT_ROLE, payload }),
  setUser: (payload) => ({ type: actions.SET_USER, payload }),
  markAsReadNotifications: (payload) => ({ type: actions.READ_NOTIFICATIONS, payload }),
  addNotification: (payload) => ({ type: actions.ADD_NOTIFICATION, payload }),
  readNotifications: (notificationIds) => async (dispatch, getState, api) => {
    const URL = 'comments/notifications/mark';
    const store = getState();

    try {
      await api.post(URL, {
        notification_ids: notificationIds,
      });

      dispatch(actions.markAsReadNotifications(notificationIds));
    } catch {
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_MARK_AS_READ_COMMENT'], NOTIFICATIONS_TYPES.error));
    }
  },
  registerUser: (data) => async (dispatch) => {
    try {
      dispatch(actions.setLoading(true));
      await axiosApiInstance.post('register', data);

      window.location.href = '/login';
    } catch (error) {
      if (error.response?.status === 422) {
        dispatch(notification('REGISTRATION.ERROR', NOTIFICATIONS_TYPES.error, getErrorMessage(error.response.data)));
      } else {
        dispatch(notification('REGISTRATION.ERROR', NOTIFICATIONS_TYPES.error));
      }

    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  loginUser: (data) => async (dispatch, getState) => {
    const store = getState();

    try {
      dispatch(actions.setLoading(true));
      const response = await axios.post(`${API_URL}login`, data, {
        headers: {
          'X-Provider': config.x_provider,
        },
      });

      if (response.data.token) {
        const token = `${response.data.token_type} ${response.data.token}`;
        const now = Date.now();
        const expiresAtMilliseconds = now + response.data.expires_at * 60 * 1000; // convert minutes to milliseconds

        dispatch(actions.login({
          authToken: token,
          roles: response.data.roles,
          isRemember: data.isRemember,
          expiresAt: expiresAtMilliseconds,
        }));
      }
    } catch (error) {
      dispatch(actionsNotifications.add(
        error?.response?.data?.message === 'User is blocked'
          ? store.language.lang['LOGIN.BLOCKED_USER']
          : store.language.lang['LOGIN.LOGIN_ERROR'],
        NOTIFICATIONS_TYPES.error
      ));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  resetPassword: (data, callback) => async (dispatch, getState) => {
    const store = getState();

    try {
      dispatch(actions.setLoading(true));
      await axios.post(`${API_URL}restore`, data, {
        headers: {
          'X-Provider': config.x_provider,
        },
      });

      if (typeof callback === 'function') {
        callback();
      }
    } catch {
      dispatch(actionsNotifications.add(
        store.language.lang['LOGIN.UPDATE_PASSWORD_ERROR'],
        NOTIFICATIONS_TYPES.error
      ));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  getProfile: () => async (dispatch, getState, api) => {
    const store = getState();

    try {
      dispatch(actions.setLoading(true));
      const URL = 'profile';
      const response = await api.get(URL);
      const { data } = response;

      if (data && data.user && data.additionalData) {
        data.additionalData.task_stat.prev.forEach((task) => {
          task.day = Number(task.day);
        });

        data.additionalData.task_stat.now.forEach((task) => {
          task.day = Number(task.day);
        });

        dispatch(termActions.setTerm(data.additionalData.terms));
        dispatch(policyActions.setPolicy(data.additionalData.policy));

        dispatch(actions.setUser({
          ...data.user,
          personal_info: data.user.personal_info || {},
          document: data.user.document || {},
          finance_info: data.user.finance_info || {},
          contract: {
            term: data.additionalData.terms.term,
            policy: data.additionalData.policy.policy,
          },
          additionalData: {
            tasksStat: data.additionalData.task_stat,
            finance: {
              now: Number(data.additionalData.finance.now),
              prev: Number(data.additionalData.finance.prev),
            },
          },
        }));
      }
    } catch {
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.FETCH_USER_PROFILE_FAILED'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  updateAvatar: (file) => async (dispatch, getState, api) => {
    dispatch(actions.setLoading(true));
    const fd = new FormData();
    const { language } = getState();

    try {
      fd.append('avatar', file, 'avatar');
      const response = await api.post('users/avatar', fd);

      dispatch(actions.setUser({
        avatar: response.data.url,
      }));
      dispatch(actionsNotifications.add(language.lang['NOTIFICATION.SUCCESS_CHANGE_AVATAR'], NOTIFICATIONS_TYPES.success));
    } catch {
      dispatch(actionsNotifications.add(language.lang['NOTIFICATION.ERROR_CHANGE_AVATAR'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
