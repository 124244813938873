import isDisabledColorComplexity from '../../tasks/helpers/isDisabledContourComplexity';
import getTaskByTypeFromProject from './getTaskByTypeFromProject';



const isDisabledProjectColorComplexity = (project, type, userRoles) => {
  return (
    getTaskByTypeFromProject(project, type)
      && isDisabledColorComplexity(getTaskByTypeFromProject(project, type), userRoles)
  );
};

export default isDisabledProjectColorComplexity;
