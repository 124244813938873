export const LOW = '1 - 699';
export const MEDIUM = '700-999';
export const HIGH = '1000 +';

/**
 * Difficulty types
 *
 * @type {string[]}
 */
const difficultyTypes = [ LOW, MEDIUM, HIGH ];

export default difficultyTypes;
