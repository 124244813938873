import taskTypes from '../taskTypes';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const isDisabledContourComplexity = (task, userRoles) => {
  return (userRoles && checkRole(userRoles, [ roles['content-manager'].key ])) || (
    task.paid_at !== null
      && (
        (task.type === taskTypes.artist_drawing.type)
          ||
          (task.type === taskTypes.designer_coloring.type)
      )
  );
};

export default isDisabledContourComplexity;
