import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import imgURL from '../../../utils/imgURL';
import Chat from '../../../widgets/Chat';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import userActions from '../../../store/user/actions';



const Comments = ({
  sizingBlocks,
  comments,
  task,
  onCreate,
  onUpdate,
}) => {
  const { lang, user, currentRole } = useSelector(({ language, user }) => ({
    lang: language.lang,
    user: user.user,
    currentRole: user.currentRole,
  }));
  const dispatch = useDispatch();
  const refComments = useRef(null);

  useEffect(() => {
    if (user.notifications) {
      const notificationIds = user?.notifications?.filter((notification) => {
        return notification.project_id === task.project.id && !notification.is_read;
      }).map((notification) => notification.id);

      if (notificationIds?.length > 0) {
        dispatch(userActions.readNotifications(notificationIds));
      }
    }

  }, [ user.notifications ]);

  /**
   * Update comment
   *
   * @param {int} commentId
   * @param {string} comment
   * @param {string} updated_at
   * @returns {void}
   * @private
   */
  const handleUpdate = async (commentId, comment, updated_at) => {
    try {
      const data = { comment, updated_at };

      await axiosApiInstance.put(`comments/${commentId}`, data);
      const updatedComment = task.project.comments.find((item) => item.id === commentId);

      onUpdate({
        ...updatedComment,
        comment,
        updated_at,
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(notification('NOTIFICATION.ERROR_UPDATE_COMMENT', 'error'));
    }
  };

  /**
   * Delete comment
   *
   * @param {int} commentId
   * @returns {void}
   * @private
   */
  const handleDelete = async (commentId) => {
    try {
      await axiosApiInstance.delete(`comments/${commentId}`, {});
      const deleteComment = task.project.comments.find((item) => item.id === commentId);

      onUpdate({
        ...deleteComment,
        deleted_at: new Date(),
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(notification('NOTIFICATION.ERROR_DELETE_COMMENT', 'error'));
    }
  };

  /**
   * Add comment
   *
   * @param {string} comment
   * @returns {void}
   * @private
   */
  const handleCreate = async (comment) => {
    const data = {
      task_id: task.id,
      project_id: task.project_id,
      current_role: currentRole,
      comment,
    };

    try {
      const res = await axiosApiInstance.post('comments', data);

      onCreate({
        id: res.data.id,
        user_id: user.id,
        project_id: task.project_id,
        task_id: task.id,
        comment: res.data,
        created_at: new Date(),
        updated_at: null,
        user: {
          username: user.username,
          avatar: user.avatar,
        },
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      dispatch(notification('NOTIFICATION.ERROR_SEND_COMMENT', 'error'));
    }
  };

  return (
    <div className={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.lg} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}>
      <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label d-flex align-items-center justify-content-between w-100">
            <h3 className="kt-portlet__head-title font-weight-bold">
              {lang['PROJECT.COMMENTS']}
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body kt-portlet__body--fullheight">
          <Chat
            ref={refComments}
            messages={comments.map((comment) => {
              return {
                id: comment.id,
                user_id: comment.user_id,
                avatar: (comment.user && comment.user.avatar) || '',
                name: (comment.user ? comment.user.username : `User #${comment.user_id}`),
                time: comment.created_at,
                isYou: comment.user_id === user.id,
                updated_at: comment.updated_at,
                deleted_at: comment.deleted_at,
                status: comment.status,
                message: (
                  <>
                    {comment.comment}
                    {(comment.files || []).map((file) => {
                      return (
                        <div key={uuidv4()}>
                          <p className='w-100 d-block kt-m-0'>
                            <a
                              className='font-weight-bold'
                              target="_blank"
                              rel="noreferrer"
                              href={imgURL(file.link || file)}
                            >
                              {` ${lang['PROJECT.ATTACHED_FILE']}`}
                            </a>
                          </p>
                        </div>
                      );
                    })}
                  </>
                ),
              };
            })}
            onSend={handleCreate}
            onUpdateComment={handleUpdate}
            onDeleteComment={handleDelete}
          />
        </div>
      </div>
    </div>
  );
};

Comments.propTypes = {
  sizingBlocks: PropTypes.object.isRequired,
  comments: PropTypes.array.isRequired,
  onCreate: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  task: PropTypes.object.isRequired,
};

export default Comments;
