/**
 * create random hex value
 *
 * @returns {string}
 */
const getRandomHEX = () => Math.round(Math.random() * 210 + 45).toString(16);

/**
 * create random color
 *
 * @returns {string}
 */
const getRandomColor = () => `#${getRandomHEX()}${getRandomHEX()}${getRandomHEX()}`;

export default getRandomColor;
