import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import queryString from 'query-string';
import lodashFilter from 'lodash/filter';
import { orderBy } from 'lodash';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import Preload from '../../../widgets/Preload';
import templateActions from '../../../store/tagSystem/templates/actions';
import tagGroupActions from '../../../store/tagSystem/groups/actions';
import usePagination from '../../../hooks/usePagination';
import { useToggle } from '../../../hooks/useToggle';
import useQueryParams from '../../../hooks/useQueryParams';
import GroupEntity from '../model/GroupEntity';
import GroupsTableHeader from './GroupsTableHeader';
import GroupsTableItem from './GroupsTableItem';
import DeleteModal from './modals/Delete';
import GroupsFilter from './components/GroupsFilter';



const Groups = () => {
  const history = useHistory();
  const location = useLocation();
  const currentQuery = queryString.parse(location.search);
  const dispatch = useDispatch();
  const updateQueryParams = useQueryParams();

  const { lang, tagGroups, loading, templates, loadingTemplates } = useSelector(({ language, tagSystemGroups, tagSystemTemplates }) => ({
    lang: language.lang,
    tagGroups: tagSystemGroups.tagGroups,
    loading: tagSystemGroups.loading,
    templates: tagSystemTemplates.templates,
    loadingTemplates: tagSystemTemplates.loadingTemplates,
  }), shallowEqual);

  const [ deletedId, setDeletedId ] = useState(null);
  const [ showConfirmationModal, toggleConfirmationModal ] = useToggle();
  const [ currentItems, setCurrentItems ] = useState([]);
  const [ filter, setFilter ] = useState({
    page: 1,
    limit: 20,
    search: '',
    template: null,
    orderBy: '',
    sortedBy: '',
  });

  const {
    currentPage,
    itemsPerPage,
    goToPage,
    PaginationComponent,
  } = usePagination({ totalItems: currentItems?.length, itemsPerPage: filter.limit }); // TODO totalItems показывает неверное кол-во страниц в случае поиска. Подумать как поправить

  useEffect(async () => {
    dispatch(templateActions.getTemplates());
    dispatch(tagGroupActions.getGroups());
  }, []);

  const applyFilter = (filter) => {
    let items = tagGroups || [];

    if (filter.search) {
      items = tagGroups.filter((item) => item.name.toLowerCase().includes(filter.search.toLowerCase()));
    }

    if (filter.template) {
      items = lodashFilter(items, (item) => lodashFilter(item?.parents, { id: filter.template?.value }).length > 0);
    }

    if (filter?.orderBy && filter?.sortedBy) {
      items = orderBy(items, [ filter.orderBy ], [ filter.sortedBy ]);
    }

    setCurrentItems(items);
  };

  useEffect(() => {
    setFilter((prevState) => {
      const newState = { ...prevState };

      if (prevState.page !== currentPage) {
        newState.page = currentPage;
      }
      if (prevState.limit !== itemsPerPage) {
        newState.limit = itemsPerPage;
      }

      applyFilter(newState);

      return newState;
    });
  }, [ currentPage, itemsPerPage ]);

  useEffect(() => {
    if (!loading && !loadingTemplates) {
      const newFilter = {
        page: 1,
        limit: 20,
        search: '',
        template: null,
        orderBy: '',
        sortedBy: '',
      };

      Object.keys(newFilter).forEach((key) => {
        if (currentQuery[key] !== undefined && currentQuery[key] !== '') {
          let value = currentQuery[key];

          if (key === 'template') {
            const template = templates?.find((template) => template.id === Number(currentQuery[key]));

            if (template) {
              value = {
                label: template.name,
                value: Number(currentQuery[key]),
              };
            }
          } else if (key === 'page' || key === 'limit') {
            value = Number(currentQuery[key]);
          }

          newFilter[key] = value;
        }
      });

      setFilter(newFilter);
      applyFilter(newFilter);
    }
  }, [ loading, loadingTemplates ]);

  const openDeleteConfirmationModal = (id) => {
    setDeletedId(id);
    toggleConfirmationModal();
  };

  const handleDelete = () => {
    dispatch(tagGroupActions.deleteGroup(deletedId));
    setDeletedId(null);
  };

  const navigateToCreateGroupPage = () => {
    history.push(`${location.pathname}/new`);
  };

  const goToEditGroupPage = (id) => {
    history.push(`${location.pathname}/${id}`);
  };

  const onFilterChange = (field, value) => {
    setFilter((prevState) => {
      let newState = {
        ...prevState,
        [field]: value,
      };

      if (field === 'template' || field === 'search') {
        newState.page = 1;
        goToPage(1); // TODO создает неоднозначное поведение , когда преезаписывае query param в 1 при перезагрузке страницы
      }

      if (field === 'orderBy') {
        const newSort = {};

        if (!filter.sortedBy) {
          newSort.sortedBy = 'desc';
          newSort.orderBy = value;
        } else if (filter.sortedBy === 'desc') {
          newSort.sortedBy = 'asc';
          newSort.orderBy = value;
        } else if (filter.sortedBy === 'asc') {
          newSort.sortedBy = '';
          newSort.orderBy = '';
        }

        newState = { ...newState, ...newSort };
      }

      const newQueryParams = { ...newState, template: newState.template?.value };

      updateQueryParams(newQueryParams);
      applyFilter(newState);

      return newState;
    });
  };

  return (
    <>
      <SubHeader
        title={`${lang['TAG_SYSTEM.GROUPS.TITLE']}`}
      />
      {(loading) ? <Preload />
        : (
          <>
            <div className='d-flex justify-content-between my-2 groups-list'>
              <div className='d-flex'>
                <GroupsFilter
                  onFilterChange={onFilterChange}
                  filter={filter}
                />

                <Button
                  variant="success"
                  size="sm"
                  className="text-nowrap kt-margin"
                  onClick={navigateToCreateGroupPage}
                >
                  {lang['TAG_SYSTEM.GROUPS.ADD_GROUP']}
                </Button>
              </div>
              <PaginationComponent />
            </div>
            <div className='kt-portlet mw-100 overflow-auto table-wrapper custom-table'>
              <Table
                striped
                className='vertical-align-middle text-center kt-font-md'
                size="sm"
              >
                <GroupsTableHeader
                  sort={{
                    orderBy: filter?.orderBy,
                    sortedBy: filter?.sortedBy,
                  }}
                  onChangeSort={onFilterChange}
                />
                <tbody>
                  {currentItems.slice((filter.page - 1) * filter.limit, filter.page * filter.limit).map((group) => {
                    return (
                      <GroupsTableItem
                        key={group?.id}
                        group={new GroupEntity(group)}
                        onEdit={() => goToEditGroupPage(group?.id)}
                        onDelete={() => openDeleteConfirmationModal(group?.id)}
                      />
                    );
                  })}
                </tbody>
              </Table>
            </div>
            <DeleteModal
              show={showConfirmationModal}
              onClose={toggleConfirmationModal}
              onDelete={handleDelete}
            />
          </>
        )
      }
    </>
  );
};

export default Groups;
