import React, { useEffect } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Col, Form, Row } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { object, string } from 'yup';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import actions from '../../../store/tagSystem/templates/actions';
import { Loader } from '../../../components/Loader';
import getTemplatesLink from '../helpers/GetTemplatesLink';
import { latinAndSymbolsRegExp } from '../../../utils/checker';
import urlPageTagSystemGroups from '../../../urls/urlPageTagSystemGroups';
import useGlobalGroups from '../hooks/useGlobalGroups';
import { tagConfig } from '../../../../config/tag';
import { transformToRequest } from '../api/transformers/template';
import OptionList from '../templates/components/OptionList';
import { entitySubtypes } from '../config/config';
import GlobalGroupsList from './components/GlobalGroupsList';



const AddEditTemplate = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const [ loading, globalGroups ] = useGlobalGroups();

  const { lang, loadingTemplate, template } = useSelector(({ language, tagSystemTemplates }) => ({
    lang: language.lang,
    loadingTemplate: tagSystemTemplates.loadingTemplate,
    loadingTemplateForm: tagSystemTemplates.loadingTemplateForm,
    template: tagSystemTemplates.template,
  }), shallowEqual);

  const validationSchema = object().shape({
    name: string().matches(latinAndSymbolsRegExp).trim().min(1).max(100).required(),
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (id === 'new') {
      dispatch(actions.setLoadingTemplate(false));
    } else {
      dispatch(actions.getTemplate(id));
    }
  }, [ id ]);

  useEffect(() => {
    if (id === 'new' && !loading && globalGroups?.length > 0) {
      globalGroups.forEach((gg) => {
        const group = tagConfig.template.subtypes.default.attach_global_groups.find((grp) => grp.name === gg.name);

        if (group) {
          setValue(`global_group_${gg.id}_${gg.name}`, true);
        }
      });
    }
  }, [ loading ]);

  const localGroups = () => {
    if (template?.children) {
      return template.children
        ?.filter((group) => {
          const globalOption = group.options?.find((option) => option.name === 'is_global');

          return !(globalOption && globalOption?.value);
        })
        ?.sort((first, second) => first.name?.localeCompare(second?.name, undefined, { sensitivity: 'base' })) || [];
    }

    return tagConfig.template.subtypes.default.create_groups;
  };

  useEffect(() => {
    if (template.id && !loadingTemplate && !loading) {
      const defaultValues = {
        name: template.name,
      };

      localGroups()?.forEach((child) => {
        if (child?.subtype !== entitySubtypes.global && child?.id) {
          defaultValues[`local_group_${child.id}_${child.name}`] = true;
        }
      });

      globalGroups.forEach((gg) => {
        const group = template?.children?.find((grp) => grp.name === gg.name);

        if (group) {
          defaultValues[`global_group_${gg.id}_${gg.name}`] = true;
        }
      });

      template.options?.forEach((option) => {
        if (option.readonly) {
          return;
        }
        defaultValues[`option_${option.name}`] = option.value;
      });

      reset(defaultValues);
    }
  }, [ template, loadingTemplate, loading ]);

  useEffect(() => {
    tagConfig.template.subtypes.default.options
      .forEach((option) => setValue(`option_${option.name}`, true));

    return () => {
      dispatch(actions.setTemplate({}));
    };
  }, []);

  const onClose = () => {
    history.goBack();
  };

  const isEdit = id && id !== 'new';

  const saveTemplate = (data) => {
    const action = isEdit ? 'updateTemplate' : 'createTemplate';
    const body = transformToRequest({
      ...data,
      subtype: template?.subtype,
      id,
    });

    dispatch(actions[action](body, () => history.goBack()));
  };

  const isLoading = (loadingTemplate || loading);

  const getSubtitle = () => {
    const link = (
      <Link
        key="group-list-key"
        className='font-weight-semibold'
        to={urlPageTagSystemGroups({ queryParams: `skip=0&template_id=${template?.id}` })}
      >
        {template?.name}
      </Link>
    );

    return isEdit
      ? [ (<span key={'separator'} className="subtitle-separator" />), isLoading ? <span key={'empty-key'}>-</span> : link ]
      : [];
  };

  return (
    <div className="position-relative">
      <SubHeader
        title={[ getTemplatesLink(lang) ]}
        subtitle={getSubtitle()}
        info={isEdit ? lang['GLOBAL.EDIT'] : lang['GLOBAL.CREATE']}
      />
      <div className='kt-portlet mw-100 overflow-auto table-wrapper'>
        { isLoading ? <Loader centered /> : (
          <div className='pl-4 text-black col-lg-12'>
            <h4 className='text-black-50 kt-m-30'>{isEdit ? lang['TAG_SYSTEM.TEMPLATES.EDIT_TEMPLATE'] : lang['TAG_SYSTEM.TEMPLATES.ADD_TEMPLATE']}</h4>
            <div className='pl-5 w-50'>
              <Form.Group className='' required>
                <Form.Label required column={'sm'} className="d-flex pl-0">
                  <strong>{lang['GLOBAL.NAME']}</strong>
                  <span style={{ color: 'red' }}>{' *'}</span>
                </Form.Label>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={!!errors.name}
                    />
                  )}
                />
                <Form.Control.Feedback type="invalid">
                  {lang['TAG_SYSTEM.ERRORS.NAME_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
              <div className='w-100'>
                <strong className="d-flex mb-4">{lang['TAG_SYSTEM.TEMPLATES.LOCAL_GROUPS']}</strong>
                <Row>
                  {
                    localGroups().map((group, index) => {
                      return (
                        <Col key={index.toString()} sm={4}>
                          <Form.Group className="d-flex">
                            <Controller
                              name={`local_group_${group.id}_${group.name}`}
                              control={control}
                              render={({ field }) => (
                                <Form.Check
                                  {...field}
                                  type='checkbox'
                                  role='button'
                                  id={`local_group_${group.id}_${group.name}`}
                                  name={group.name}
                                  checked={group?.id ? field.value : true}
                                  disabled
                                />
                              )}
                            />
                            <Form.Label
                              htmlFor={`local_group_${group.id}_${group.name}`}
                              role="button"
                              className='w-100'
                            >
                              {group.name}
                            </Form.Label>
                          </Form.Group>
                        </Col>
                      );
                    })
                  }
                </Row>
              </div>
              <GlobalGroupsList template={template} isEdit={isEdit} items={globalGroups ?? []} control={control} />
              <OptionList items={tagConfig.template.subtypes.default.options ?? []} control={control} />

              <div className='d-flex justify-content-center py-5'>
                <Button variant='secondary' className='mr-4' onClick={onClose}>{lang['GLOBAL.CANCEL']}</Button>
                <Button variant='success' onClick={handleSubmit(saveTemplate)}>{lang['GLOBAL.SAVE']}</Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AddEditTemplate;
