import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import urlPageTagSystemGroups from '../../../urls/urlPageTagSystemGroups';



const TemplatesTableItem = ({
  template,
  onEdit,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  return (
    <tr>
      <td className='text-center'>
        {template.id}
      </td>
      <td className='text-left'>
        <Link
          className='font-weight-bold'
          to={urlPageTagSystemGroups({ queryParams: `template=${template.id}` })}
        >
          {template.name}
        </Link>
      </td>
      <td className='text-center'>
        <ButtonGroup>
          <Button
            id={'edit-group'}
            title={lang['GLOBAL.EDIT']}
            variant='success'
            size='sm'
            onClick={() => onEdit(template.id)}
          >
            <i className="fa fa-edit kt-m-0" />
          </Button>
        </ButtonGroup>
      </td>
    </tr>
  );
};


TemplatesTableItem.propTypes = {
  template: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
};

export default TemplatesTableItem;
