import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/Button';
import { orderBy } from 'lodash';
import queryString from 'query-string';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import actions from '../../../store/tagSystem/templates/actions';
import { Loader } from '../../../components/Loader';
import useQueryParams from '../../../hooks/useQueryParams';
import usePagination from '../../../hooks/usePagination';
import urlPageTagSystemTemplatesCreate from '../../../urls/urlPageTagSystemTemplatesCreate';
import urlPageTagSystemTemplatesEdit from '../../../urls/urlPageTagSystemTemplatesEdit';
import TemplatesTableItem from './TemplatesTableItem';
import TemplatesTableHeader from './TemplatesTableHeader';



const Templates = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const updateQueryParams = useQueryParams();

  const [ filter, setFilter ] = useState({
    page: 1,
    limit: 20,
    search: '',
    orderBy: '',
    sortedBy: '',
  });
  const currentQuery = queryString.parse(location.search);
  const [ currentItems, setCurrentItems ] = useState([]);

  const { lang, loadingTemplates, templates } = useSelector(({ language, tagSystemTemplates }) => ({
    lang: language.lang,
    loadingTemplates: tagSystemTemplates.loadingTemplates,
    templates: tagSystemTemplates.templates,
  }), shallowEqual);

  const {
    currentPage,
    itemsPerPage,
    goToPage,
    PaginationComponent,
  } = usePagination({ totalItems: currentItems.length, itemsPerPage: filter.limit });

  useEffect(() => {
    dispatch(actions.getTemplates());
  }, []);

  const applyFilter = (filter) => {
    let items = templates || [];

    if (filter.search) {
      items = templates.filter((item) => item.name.toLowerCase().includes(filter.search.toLowerCase()));
    }

    if (filter?.orderBy && filter?.sortedBy) {
      items = orderBy(items, [ filter.orderBy ], [ filter.sortedBy ]);
    }

    setCurrentItems(items);
  };

  useEffect(() => {
    setFilter((prevState) => {
      const newState = { ...prevState };

      if (prevState.page !== currentPage) {
        newState.page = currentPage;
      }

      if (prevState.limit !== itemsPerPage) {
        newState.limit = itemsPerPage;
      }

      applyFilter(newState);

      return newState;
    });
  }, [ currentPage, itemsPerPage ]);

  useEffect(() => {
    if (!loadingTemplates) {
      const newFilter = {
        page: 1,
        limit: 20,
        search: '',
        orderBy: '',
        sortedBy: '',
      };

      Object.keys(newFilter).forEach((key) => {
        if (currentQuery[key] !== undefined && currentQuery[key] !== '') {
          let value = currentQuery[key];

          if (key === 'page' || key === 'limit') {
            value = Number(currentQuery[key]);
          }

          newFilter[key] = value;
        }
      });

      setFilter(newFilter);
      applyFilter(newFilter);
    }
  }, [ loadingTemplates ]);

  const onFilterChange = (field, value) => {
    setFilter((prevState) => {
      let newState = {
        ...prevState,
        [field]: value,
      };

      if (field === 'search') {
        newState.page = 1;
        goToPage(1);
      }

      if (field === 'orderBy') {
        const newSort = {};

        if (!filter.sortedBy) {
          newSort.sortedBy = 'desc';
          newSort.orderBy = value;
        } else if (filter.sortedBy === 'desc') {
          newSort.sortedBy = 'asc';
          newSort.orderBy = value;
        } else if (filter.sortedBy === 'asc') {
          newSort.sortedBy = '';
          newSort.orderBy = '';
        }

        newState = { ...newState, ...newSort };
      }

      updateQueryParams(newState);
      applyFilter(newState);

      return newState;
    });
  };

  const navigateToCreatePage = () => {
    history.push(urlPageTagSystemTemplatesCreate());
  };

  const navigateToEditPage = (id) => {
    history.push(urlPageTagSystemTemplatesEdit({ id }));
  };

  return (
    <>
      <SubHeader
        title={`${lang['TAG_SYSTEM.TEMPLATES.TITLE']}`}
      />
      <>
        <div className='d-flex justify-content-between my-2'>
          <div className='d-flex'>
            <input
              value={filter.search}
              className="form-control mr-2"
              type="text"
              placeholder={lang['GLOBAL.SEARCH']}
              onChange={(event) => onFilterChange('search', event.target.value.trim())}
              style={{ width: '200px' }}
            />
            <Button
              variant="success"
              size="sm"
              className="text-nowrap kt-margin"
              onClick={navigateToCreatePage}
            >
              {lang['TAG_SYSTEM.TEMPLATES.ADD_TEMPLATE']}
            </Button>
          </div>
          <div className='kt-ml-5 d-flex'>
            <PaginationComponent />
          </div>
        </div>
        <div className='kt-portlet mw-100 overflow-auto table-wrapper custom-table position-relative'>
          { (loadingTemplates) && <Loader centered />}
          <Table
            striped
            className='vertical-align-middle text-center kt-font-md'
            size="sm"
          >
            <TemplatesTableHeader
              sort={{
                orderBy: filter.orderBy,
                sortedBy: filter.sortedBy,
              }}
              onChangeSort={onFilterChange}
            />
            <tbody>
              {currentItems.slice((filter.page - 1) * filter.limit, filter.page * filter.limit).map((template) => {
                return (
                  <TemplatesTableItem
                    key={template.id}
                    template={template}
                    onEdit={navigateToEditPage}
                  />
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    </>
  );
};

export default Templates;
