import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../../widgets/Img';
import getLinkToIcon from '../../projects/linksToIconFiles';
import imgURL from '../../../utils/imgURL';
import Tabs from '../../../widgets/Tabs';



const propTypes = {
  lang: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  taskFiles: PropTypes.arrayOf(PropTypes.object),
  sourceFiles: PropTypes.array,
  viewFileRight: PropTypes.bool,
  sizingBlocks: PropTypes.object,
};

/**
 * File List Block Component of the task page
 *
 * @returns {*}
 */
const TaskInfoListFilesBlock = ({ task, lang, taskFiles, viewFileRight, sizingBlocks, sourceFiles }) => {
  return (!viewFileRight && (taskFiles.length > 0 || (task.project && task.project.files)) && (
    <div className={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.lg} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}>
      <Tabs
        fluid
        stickyHeader={false}
        title={lang['GLOBAL.LIST_FILES']}
        styleBody={{
          maxHeight: 690,
          overflow: 'auto',
        }}
        tabs={[
          taskFiles.length > 0 && {
            title: lang['TASK.LOAD_FILES'],

            body: () => (
              <div className="kt-widget4" key="1">
                {taskFiles.map((file) => (
                  <div className="kt-widget4__item" key={file.id}>
                    <div className="kt-widget4__pic kt-widget4__pic--pic">
                      <Img src={getLinkToIcon(file.prefix)} />
                    </div>
                    <div className="kt-widget4__info">
                      {file.link ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          download={file.name || file.prefix || 'file'}
                          href={imgURL(file.link)}
                          className="kt-widget4__username"
                        >
                          {(file.name || 'file') + (file.prefix ? `(${file.prefix.replace('_', '.')})` : '')}
                          {file.required && (
                            <span style={{ color: 'red' }}>*</span>
                          )}
                        </a>
                      ) : (
                        <span className="kt-widget4__username no-hover">
                          {(file.name || 'file') + (file.prefix ? ` (${file.prefix.replace('_', '.')})` : '')}
                          {file.required && (
                            <span style={{ color: 'red' }}>*</span>
                          )}
                        </span>
                      )}
                    </div>
                    {!file.uploaded && file.status === 0 ? (
                      <Badge variant="primary">
                        {lang['GLOBAL.FILES.NO_LOAD']}
                      </Badge>
                    ) : (file.valid || file.status === 1 ? (
                      <Badge variant="success">
                        {lang['GLOBAL.FILES.VALID']}
                      </Badge>
                    ) : (
                      <Badge variant="danger">
                        {lang['GLOBAL.FILES.NO_VALID']}
                      </Badge>
                    )
                    )}
                  </div>
                ))}
              </div>
            ),
          },
          (sourceFiles.length > 0) && {
            title: lang['TASK.SOURCE_FILES'],

            body: () => (
              <div className="kt-widget4" key="3">
                {sourceFiles.map((file) => (
                  <div className="kt-widget4__item" key={file.id}>
                    <div className="kt-widget4__pic kt-widget4__pic--pic">
                      <Img src={getLinkToIcon(file.prefix)} />
                    </div>
                    <div className="kt-widget4__info">
                      {file.link ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          download={file.name || 'file'}
                          href={imgURL(file.link)}
                          className="kt-widget4__username"
                        >
                          {file.name ? file.name.split('?')[0] : 'file'}
                        </a>
                      ) : (
                        <span className="kt-widget4__username no-hover">
                          {file.name || file.prefix || 'file'}
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            ),
          },
        ]}
      />
    </div>
  )
  );
};


export const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  userToken: store.user.user.token,
  userId: store.user.user.id,
});

TaskInfoListFilesBlock.propTypes = propTypes;

export default connect(mapStoreToProps)(TaskInfoListFilesBlock);

