/**
 * Transform employee status to badge color
 *
 * @param {string} isEmployee
 * @returns {string}
 */
const employeeToColor = (isEmployee) => {
  switch (isEmployee) {
    case 'true': {
      return 'success';
    }
    case 'false': {
      return 'info';
    }
    default: {
      return 'secondary';
    }
  }
};

export default employeeToColor;
