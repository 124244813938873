export default {
  // eslint-disable-next-line no-process-env
  tinyEditorApiKey: process.env.REACT_APP_TINYMCE_EDITOR_API_KEY,
  // eslint-disable-next-line no-process-env
  env: process.env.REACT_APP_ENVIRONMENT,
  // eslint-disable-next-line no-process-env
  x_provider: process.env.REACT_APP_X_PROVIDER,
  // eslint-disable-next-line no-process-env
  managerId: Number(process.env.MANAGER_ID),
  // eslint-disable-next-line no-process-env
  test_mode_token: process.env.TEST_MODE_TOKEN,
  // eslint-disable-next-line no-process-env
  tagsEnabled: localStorage.hasOwnProperty('tags_enabled') ? localStorage.getItem('tags_enabled') === 'true' : process.env.TAGS_ENABLED === 'true', // TODO remove localstorage after testing
  // eslint-disable-next-line no-process-env
  hideTagSystemById: Number(process.env.HIDE_TAG_SYSTEM_BY_ID),
};
