export const ADD_DEFAULT_DEADLINE_DAYS = 0;
export const HC_RELEASE_DATE_AFTER_DEADLINE_DAYS = 14;

export const REGEXP_NUM_VALUE = '^(?=.*\\d)\\d*(?:\\.\\d{0,2})?$';

export const TRANSIT_SWIFT_MAP = [ 'AKBBBY', 'OLMPBY2X', 'TECNBY22', 'BPSBBY2X', 'BLNBBY2X', 'BLBBBY2X', 'AEBKBY2X', 'UNBSBY2X' ];

export const BELARUS_INTERNATION_COUNTY_CODE = 112;
export const RUSSIA_INTERNATION_COUNTY_CODE = 643;
export const UKRAINE_INTERNATION_COUNTY_CODE = 804;
