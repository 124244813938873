import axios from 'axios';
import { DEFAULT_LANG } from '../store/lang/language';



export const nameLanguage = 'nameLanguage';

/**
 * Gets language data
 *
 * @param {any} lang
 * @returns {Promise<{}|{data: *, status: number}>}
 */
// eslint-disable-next-line consistent-return
export const getLanguage = async (lang = DEFAULT_LANG) => {
  const URL = `/langs/${lang}.json`;

  try {
    return await axios.get(URL, {})
      .then((response) => response.data)
      .catch((error) => {
        if (error.response) {
          // eslint-disable-next-line no-console
          console.error(error.response);
        }
      });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};
