/**
 * Checks roles so that there are no forbidden roles between them and there is at least one expected role
 *
 * @param {string[]} roles - roles that are checked
 * @param {string[]} acceptRoles - expected roles
 * @param {string[]} antiRoles - forbidden roles
 * @returns {boolean}
 */

const checkRole = (roles, acceptRoles, antiRoles = []) => (
  roles.findIndex((role) => antiRoles.includes(role)) !== -1 ? false
    : (roles.findIndex((role) => acceptRoles.includes(role)) !== -1)
);

export default checkRole;
