import axios from 'axios';
import { isEmpty } from 'lodash';
import { API_URL } from '../../utils/gettersUrl';
import store from '../../store/store';
import userActions from '../../store/user/actions';
import urlPageError from '../../urls/urlPageError';
import urlPageError403 from '../../urls/urlPageError403';
import appConfig from '../../../config/app';
import getTestModeParams from '../../utils/getTestModeParams';
import { cache } from './cacheHandler';



const whiteList = [ 'categories'/* , 'users/all' */ ];

/** isURLInWhiteList func
 *
 * @param {string} url
 * @returns {*}
 */
const isURLInWhiteList = (url) => {
  whiteList.includes(url);
};

/** requestHandler func
 *
 * @param {any} request
 * @returns {*}
 */
const requestHandler = (request) => {
  if (request.method.toLowerCase() === 'get' /* && !request.isCached */) {
    const checkIsValidResponse = cache.isValid(request.url || '') && isURLInWhiteList(request.url);

    if (checkIsValidResponse) {
      request.headers.cached = true;
      request.data = JSON.parse(cache.get(request.url) || '{}');

      return Promise.reject(request);
    }
  }
  return request;
};

/** resolveErrorResponse func
 *
 * @param {any} error
 * @returns {*}
 */
const resolveErrorResponse = (error) => {
  const originalRequest = error.config;

  if (error.response) {
    if (error.response.status === 403 && !originalRequest._retry) {
      window.location.replace(urlPageError403());
    }
    if (error.response.status === 404 && !originalRequest._retry) {
      window.location.replace(urlPageError());
    }
    if (error.request.status === 401) {
      if (!localStorage.getItem('test_mode_params')) {
        store.dispatch(userActions.logout());
      } else {
        window.location.replace(urlPageError());
      }
    }
  }
};

/** errorHandler func
 *
 * @param {any} error
 * @returns {*}
 */
const errorHandler = (error) => {
  resolveErrorResponse(error);
  if (error.headers && error.headers.cached === true) {
    return Promise.resolve(error);
  }

  return Promise.reject(error);
};

/** responseHandler func
 *
 * @param {any} response
 * @returns {*}
 */
const responseHandler = (response) => {
  if (response.config.method.toLowerCase() === 'get' /* && !response.config.isCached */) {
    if (response.config.url && isURLInWhiteList(response.config.url) && !cache.isValid(response.config.url || '')) {
      cache.set(response.config.url, JSON.stringify(response.data));
    }
  }
  return response;
};

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const authToken = localStorage.getItem('authToken');

    config.headers = {
      Authorization: authToken,
      Accept: 'application/json',
      'X-Provider': appConfig.x_provider,
      'Cache-Control': 'no-cache',
    };
    config.baseURL = API_URL;
    // config.isCached = false;
    const testersModeParams = getTestModeParams();

    if (!isEmpty(testersModeParams)) {
      const { is_developer, developer_id } = testersModeParams;

      config.headers['x-api-developer'] = is_developer;
      config.headers['x-api-developer-id'] = developer_id;
    }

    return requestHandler(config);
  },
  (error) => {
    Promise.reject(error);
  },
);

axiosApiInstance.interceptors.response.use((response) => responseHandler(response), async (error) => errorHandler(error));

export default axiosApiInstance;
