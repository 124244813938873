import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { v4 as uuidv4 } from 'uuid';
import { useSelector } from 'react-redux';



const propTypes = {
  tabs: PropTypes.array,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

/*
 * Component for view and control right menu
 */
const RightMenu = ({ isOpen, tabs, onClose }) => {
  const [ show, setShow ] = useState(isOpen);
  const [ curTab, setCurTab ] = useState(0);
  const refBody = useRef(null);
  const lang = useSelector((store) => store.language.lang);

  const eventWheel = (event) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const eventClick = (event) => {
    const path = event.path || (event.composedPath && event.composedPath()) || [];

    if (path.indexOf(refBody.current) === -1) {
      document.removeEventListener('click', eventClick);
    }
  };

  useEffect(() => {
    refBody.current.addEventListener('wheel', eventWheel, { passive: false });

    return () => {
      document.removeEventListener('click', eventClick);
      refBody.current?.removeEventListener('wheel', eventWheel);
    };
  }, []);

  const onMenuClose = () => {
    document.removeEventListener('click', eventClick);
    setShow(false);
    if (tabs[curTab].title === lang['PROJECT.COMMENTS']) {
      onClose();
    }
  };

  return (<>
    {tabs.length > 0
    && <ul className='kt-sticky-toolbar border border-secondary border-right-0'>
      {tabs.map((tab, index) => {
        return (
          <li
            key={uuidv4()}
            className={`kt-sticky-toolbar__item kt-sticky-toolbar__item--${tab.color || 'success'}`}
          >
            <span
              role="link"
              tabIndex={0}
              onKeyDown={() => {}}
              className='border border-secondary'
              onClick={() => {
                document.addEventListener('click', eventClick);
                setShow(true);
                setCurTab(index);
              }}
              title={tab.title}
            >
              <i className={tab.icon} />
            </span>
          </li>
        );
      })}
    </ul>
    }
    <div
      ref={refBody}
      className={`kt-demo-panel d-flex flex-column h-100 overflow-hidden ${tabs.length > 0 && show ? 'kt-demo-panel--on' : ''}`}
    >
      {tabs.length > 0 && (<>
        <div className='kt-demo-panel__head'>
          <h3 className='kt-demo-panel__title'>{tabs[curTab].title}</h3>
          <a
            role="link"
            tabIndex={0}
            onKeyDown={() => {}}
            className='kt-demo-panel__close'
            onClick={onMenuClose}
          >
            <i className="flaticon2-delete" />
          </a>
        </div>
        <div
          className='kt-demo-panel__body d-inline-flex'
        >
          {tabs[curTab].scroll ? (
            <PerfectScrollbar
              options={{
                suppressScrollX: true,
              }}
              className='kt-scroll kt-scroll--pull h-100 w-100'
            >
              {tabs[curTab].body ? tabs[curTab].body() : <div />}
            </PerfectScrollbar>
          ) : (
            tabs[curTab].body ? tabs[curTab].body() : <div />
          )}
        </div>
      </>)}
    </div>
  </>);
};

RightMenu.defaultProps = {
  tabs: [],
  isOpen: false,
  onClose: () => {},
};

RightMenu.propTypes = propTypes;

export default RightMenu;
