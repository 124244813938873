import actions from './actions';



const initialState = {
  loading: false,
  editorsStatistics: [],
};

export const statisticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.SET_EDITORS_STATISTICS: {
      return {
        ...state,
        editorsStatistics: action.payload,
      };
    }
    default:
      return state;
  }
};
