export const toAbsoluteUrl = (pathname) => pathname;

// eslint-disable-next-line no-process-env
const toAmazonUrl = process.env.REACT_APP_URL_AMAZON;

export const toImgUrl = (pathname) => toAmazonUrl + (pathname[0] === '/' ? '' : '/') + pathname;

// eslint-disable-next-line no-process-env
export const API_URL = process.env.REACT_APP_URL_API;

export const toApiUrl = (pathname) => API_URL + pathname;
