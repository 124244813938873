import React from 'react';
import { Link } from 'react-router-dom';
import urlPageTagSystemTags from '../../../urls/urlPageTagSystemTags';



const getTagLink = (name, groupId) => {
  return (
    <Link
      key={`${name}_${groupId}`}
      className='font-weight-semibold'
      to={urlPageTagSystemTags({ groupId })}
    >
      {name}
    </Link>
  );
};

export default getTagLink;
