import url from './url';



export default url(({ id = null }) => {
  let url = '/projects/edit/reopened';

  if (id) {
    url += `?id=${id}`;
  }

  return url;
});
