import { groupBy, maxBy, orderBy } from 'lodash';
import store from '../../store/store';

/**
 * List of image types and their names
 *
 * @type {{
 * icon332_png: string,
 * preview: string,
 * preview1024: string,
 * jpg: string,
 * icon468c: string,
 * svg: string,
 * gif: string,
 * texture: string,
 * png: string,
 * eps: string,
 * "icon332.png": string}}
 */
export const previewFilesImages = {
  eps: 'EPS',
  icon332_png: 'ICON 332px PNG',
  'icon332.png': 'ICON 332px PNG',
  icon468c: 'ICON 468px COLORED',
  preview: 'PREVIEW',
  preview1024: 'PREVIEW1024',
  texture: 'TEXTURE',
  svg: 'SVG',
  png: 'PNG',
  jpg: 'JPG',
  gif: 'GIF',
};

/**
 * Gets the group with the latest version
 *
 * @param {object} groups
 * @returns {number}
 */
const getMaxVersion = (groups) => {
  let maxVersion = -1;

  Object.keys(groups).forEach((key) => {
    const v = Number(key);

    if (v > maxVersion || maxVersion === -1) {
      maxVersion = key;
    }
  });

  return maxVersion;
};

/**
 * Searches for a group of files with the desired version;
 * if version = null, it searches for files with the maximum version
 *
 * @param {object}project
 * @param {number|null}version
 * @returns {[]}
 */
export const getPreviewFilesFromProject = (project, version = null) => {
  let _previewFiles = [];

  if (project && project.files && project.files.length > 0) {
    const groups = groupBy(project.files, 'version_id');

    _previewFiles = groups[version || getMaxVersion(groups)] || [];
  }

  return _previewFiles;
};

/**
 * Gets a list of files that are expected in the project and their status
 *
 * @param {object} project
 * @returns {Array}
 */
export const getListFilesFromProject = (project) => {
  const _previewFiles = [];

  if (project) {
    const type = store.getState().config[project.type];

    if (type) {
      let files;

      if (project.type === 'coloring-any' || project.type === 'coloring-artist' || project.type === 'coloring') {
        files = type.files[project.options.type];
      } else {
        files = type.files;
      }

      files?.forEach((file) => {
        const all = project.files.filter((item) => item.prefix === file.prefix);

        if (all.length > 0) {
          _previewFiles.push({
            ...file,
            ...(maxBy(all, (item) => item.version_id)),
          });
        } else {
          _previewFiles.push({
            status: 0,
            ...file,
          });
        }
      });
    }
  }

  return orderBy(_previewFiles, [ (file) => file.status ], [ 'desc' ]);
};

/**
 * Gets a list of files that are expected in the task or expected in the task of the executor and their status
 *
 * @param {object} task
 * @returns {Array}
 */
export const getListFilesFromTask = (task, config) => {
  const _previewFiles = [];

  if (task && task.project) {
    const type = config[task.project.type];

    if (type) {
      let files = [];

      if (task.project.type === 'coloring-any' || task.project.type === 'coloring-artist' || task.project.type === 'coloring') {
        files = type.files[task.project.options.type];
      } else {
        files = type.files;
      }

      files = files.filter((file) => file.step === task.type);

      if (files.length === 0) {
        let indexStep = type.steps.findIndex((step) => step.type === task.type) - 1;

        // eslint-disable-next-line fp/no-loops
        while (files.length === 0 && indexStep >= 0) {
          if (task.project.type === 'coloring-any' || task.project.type === 'coloring-artist' || task.project.type === 'coloring') {
            files = type.files[task.project.options.type];
          } else {
            files = type.files;
          }

          // eslint-disable-next-line no-loop-func
          files = files.filter((file) => file.step === type.steps[indexStep].type);
          indexStep -= 1;
        }
      }

      files.forEach((file) => {
        const all = task.project.files.filter((item) => item.prefix === file.prefix);

        if (all.length > 0) {
          _previewFiles.push({
            ...(maxBy(all, (file) => file.version_id)),
            ...file,
          });
        } else {
          _previewFiles.push({
            status: 0,
            ...file,
          });
        }
      });
    }
  }

  return orderBy(_previewFiles, [ (file) => file.status ], [ 'desc' ]);
};

/**
 * Verifies that the link leads to the image.
 *
 * @param {object} file
 * @returns {boolean}
 */
export const checkImageType = (file) => {
  const type = file.link.slice(file.link.lastIndexOf('.') + 1).toLowerCase();

  return type === 'png' || type === 'jpg' || type === 'jpeg' || type === 'gif' || type === 'bmp' || type === 'svg';
};

/**
 * Gets a list of images from the project for preview
 *
 * @param {object} project
 * @returns {[]}
 */
export const getPreviewImagesFromProject = (project) => {
  const prevImages = [];

  if (project && project.preview) {
    prevImages.push({
      name: project.preview.slice(project.preview.lastIndexOf('/') + 1),
      link: project.preview,
    });
  }

  return prevImages;
};

export const _getPreviewImagesFromProject = (project, previewFiles) => {
  const prevImages = [];

  previewFiles.forEach((file) => {
    if (previewFilesImages[(file.prefix || '').toLowerCase()] && file.link) {
      prevImages.push(file);
    }
  });

  return prevImages;
};

export const getPreviewImagesFromTask = (task) => {
  const prevImages = [];

  if (task && task.preview) {
    if (task.preview instanceof Object) {
      Object.entries(task.preview).forEach((file) => {
        if (file[0] === 'lg') {
          prevImages.push({
            name: file[1].slice(file[1].lastIndexOf('/') + 1),
            link: file[1],
          });
        }
      });
    } else {
      prevImages.push({
        name: task.preview.slice(task.preview.lastIndexOf('/') + 1),
        link: task.preview,
      });
    }
  }

  return prevImages;
};

/**
 * Gets the address for the preview of the desired size
 *
 * @param {
 *     {
 *         lg: string,
 *         sm: string,
 *     } |
 *     string |
 *     null
 * } preview
 * @param {string} size
 * @returns {string}
 */
const getPreview = (preview, size) => {
  if (!preview) {
    return '';
  }

  if (typeof preview === 'string') {
    return preview;
  }
  return preview[size] ? preview[size] : '';
};

/**
 * Gets the address for a large preview
 *
 * @param {
 *     {
 *         lg: string,
 *         sm: string,
 *     } |
 *     string |
 *     null
 * } preview
 * @returns {string}
 */
export const getPreviewLg = (preview) => getPreview(preview, 'lg');

/**
 * Gets the address for a small preview
 *
 * @param {
 *     {
 *         lg: string,
 *         sm: string,
 *     } |
 *     string |
 *     null
 * } preview
 * @returns {string}
 */
export const getPreviewSm = (preview) => getPreview(preview, 'sm');
