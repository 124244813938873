import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Table } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Preload from '../../widgets/Preload';
import statisticsActions from '../../store/statistics/actions';
import { BLEND_TYPE, CARGO_TYPE, SHARP_TYPE } from '../projects/image_type';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';
import { isSameDomain } from '../../utils/isSameDomain';



const ReportsByEditors = () => {
  const dispatch = useDispatch();
  const { lang, statistics, user } = useSelector(({ language, users, statistics, user }) => ({
    lang: language.lang,
    users,
    statistics,
    user: user.user,
  }), shallowEqual);

  const [ showRow, setShowRow ] = useState({});
  const [ showTotalRow, setShowTotalRow ] = useState(false);
  const [ showCategoryRow, setShowCategoryRow ] = useState(false);
  const [ showTagRow, setShowTagRow ] = useState(false);
  const toggleRow = (id) => {
    setShowRow((prevShownRows) => ({
      ...prevShownRows,
      [id]: !prevShownRows[id],
    }));
  };

  const formatDate = (date) => {
    const dateObject = new Date(date);
    const month = dateObject.getMonth() + 1;
    const formattedMonth = month < 10 ? `0${month}` : month;

    return `${formattedMonth}.${dateObject.getFullYear()}`;
  };

  useEffect(
    () => {
      dispatch(statisticsActions.getEditorsStatistics());
    },
    []
  );
  const getDesignerTasksByType = (stats, editor, date, type) => {
    return stats?.checkingDesigners[editor]?.find((item) => {
      return item.date === date && item.image_type === type;
    })?.total_tasks || 0;
  };
  const getSortedEditors = (stats) => {
    stats.editors?.sort((s1, s2) => {
      return s1.localeCompare(s2, 'en', { ignorePunctuation: true });
    })?.forEach((editor, indexEditor, object) => {
      let artistTasks = 0;

      let coloristTasks = 0;

      stats?.date?.forEach((date, index) => {
        if (index <= 1) {
          artistTasks += stats?.checkingArtists[editor]?.find((item) => {
            return item.date === date;
          })?.total_tasks || 0;

          const sharpValue = getDesignerTasksByType(stats, editor, date, SHARP_TYPE);
          const blendValue = getDesignerTasksByType(stats, editor, date, BLEND_TYPE);
          const cargoValue = getDesignerTasksByType(stats, editor, date, CARGO_TYPE);

          coloristTasks += sharpValue + blendValue + cargoValue;
        }
      });
      if (artistTasks === 0 && coloristTasks === 0) {
        object.splice(indexEditor, 1);
        stats.editors.push(editor);
      }
    });
    return stats.editors;
  };

  const tableHeader = () => {
    return (<tr>
      <th style={{ minWidth: '100px' }} />
      <th style={{ minWidth: '100px' }} />
      {statistics.editorsStatistics?.date?.map((item) => {
        return <th style={{ minWidth: '100px' }} key={item}>{formatDate(item)}</th>;
      })}
    </tr>);
  };

  const tableBody = () => {
    const stats = statistics.editorsStatistics;
    const total = {};
    const sortedEditors = getSortedEditors(stats);

    return sortedEditors?.map((editor) => (<tr key={editor} className={editor}>
      <td className="font-weight-bolder px-2">
        <p className="mb-0">
          {isSameDomain(user.provider_id, stats?.editorIds[editor].provider_id) ? (
            <Link to={urlPageFreelancerDetails({ freelancerId: stats?.editorIds[editor].id })} className="kt-widget__username" title={editor}>
              {editor}
            </Link>) : <span className="kt-widget__username">{editor}</span>}
        </p>
      </td>
      <td>
        <div
          className="py-2 pl-1 text-left"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => toggleRow(editor)}
        >
          Colorist
          {showRow[editor] ? (
            <i className="fa fa-angle-down pl-2" />
          ) : (
            <i className="fa fa-angle-right pl-2" />
          )}
        </div>
        {showRow[editor] && (
          <p className="image-types">
            <div className="py-2 pr-3 text-right">sharp</div>
            <div className="py-2 pr-3 text-right">blend</div>
            <div className="py-2 pr-3 text-right">cargo</div>
          </p>
        )}
        <div className="py-2 pl-1 text-left">Artist</div>
      </td>
      {stats?.date?.map((date) => {
        const sharpValue = getDesignerTasksByType(stats, editor, date, SHARP_TYPE);
        const blendValue = getDesignerTasksByType(stats, editor, date, BLEND_TYPE);
        const cargoValue = getDesignerTasksByType(stats, editor, date, CARGO_TYPE);
        const coloristValue = sharpValue + blendValue + cargoValue;

        const artistValue = stats?.checkingArtists[editor]?.find((item) => {
          return item.date === date;
        })?.total_tasks || 0;

        if (!total[date]?.hasOwnProperty('totalArtist')) {
          total[date] = {
            totalArtist: 0,
            totalColorist: 0,
            totalSharp: 0,
            totalBlend: 0,
            totalCargo: 0,
          };
        }

        total[date].totalArtist += artistValue;
        total[date].totalColorist += coloristValue;
        total[date].totalSharp += sharpValue;
        total[date].totalBlend += blendValue;
        total[date].totalCargo += cargoValue;

        return (<td key={editor + date}>
          <div className="py-2 ">{coloristValue}</div>
          {showRow[editor] && (
            <p className="image-types">
              <div className="py-2 ">{sharpValue}</div>
              <div className="py-2 ">{blendValue}</div>
              <div className="py-2 ">{cargoValue}</div>
            </p>
          )}
          <div className="py-2 ">{artistValue}</div>
        </td>);
      })}
    </tr>)).concat([ (<tr className="bg-secondary" key={'total'}>
      <td className="font-weight-bolder">
        {lang['REPORTS.TOTAL']}
      </td>
      <td>
        <div
          className="py-2 pl-1 text-left"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            setShowTotalRow(!showTotalRow);
          }}
        >
          Colorist
          {showTotalRow ? (
            <i className="fa fa-angle-down pl-2" />
          ) : (
            <i className="fa fa-angle-right pl-2" />
          )}
        </div>
        {showTotalRow && (
          <p className="image-types">
            <div className="py-2 pr-3 text-right">sharp</div>
            <div className="py-2 pr-3 text-right">blend</div>
            <div className="py-2 pr-3 text-right">cargo</div>
          </p>
        )}
        <div className="py-2 pl-1 text-left">Artist</div>
      </td>
      {stats?.date?.map((date) => {
        return (<td key={`total${date}`}>
          <div className="py-2">{total[date].totalColorist}</div>
          {showTotalRow && (
            <p className="image-types">
              <div className="py-2">{total[date].totalSharp}</div>
              <div className="py-2">{total[date].totalBlend}</div>
              <div className="py-2">{total[date].totalCargo}</div>
            </p>
          )}
          <div className="py-2">{total[date].totalArtist}</div>
        </td>);
      })}
    </tr>) ]);
  };

  const categoryRow = () => {
    const stats = statistics.editorsStatistics;
    const categories = [
      'Marvel',
      'Disney',
      'Licenses',
      'Collections',
      'Good Cause',
      'Stories',
      'Art',
    ];

    return (<tr className="category-row">
      <td className="font-weight-bolder">
        <div
          className="py-2 p-3 border-0 text-left"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            setShowCategoryRow(!showCategoryRow);
          }}
        >
          {lang['GLOBAL.CATEGORY']}
          {showCategoryRow ? (
            <i className="fa fa-angle-down pl-2" />
          ) : (
            <i className="fa fa-angle-right pl-2" />
          )}
        </div>
      </td>
      {showCategoryRow && (
        <td>
          {categories.map((category, index) => {
            return (
              <div className={`py-2 ${index % 2 === 0 ? 'report-category-odd' : 'report-category-even'}`} key={category}>
                {category}
              </div>
            );
          })}
        </td>
      )}
      {showCategoryRow && stats?.date?.map((date) => {
        return (<td key={date}>
          {categories.map((category, index) => {
            return (
              <div
                className={`py-2 ${index % 2 === 0 ? 'report-category-odd' : 'report-category-even'}`}
                key={category}
              >{stats?.categories[category]?.find((item) => {
                  return item.date === date;
                })?.count || 0}</div>);
          })}
        </td>);
      })}
    </tr>);
  };

  const tagRow = () => {
    const stats = statistics.editorsStatistics;
    const tags = [
      'Mystery',
      'Nolines',
      'Silhouette',
    ];

    return (<tr className="category-row">
      <td className="font-weight-bolder">
        <div
          className="py-2 p-3 border-0 text-left"
          role="button"
          tabIndex={0}
          onKeyDown={() => {}}
          onClick={() => {
            setShowTagRow(!showTagRow);
          }}
        >
          {lang['TAGS.TAG']}
          {showTagRow ? (
            <i className="fa fa-angle-down pl-2" />
          ) : (
            <i className="fa fa-angle-right pl-2" />
          )}
        </div>
      </td>
      {showTagRow && (
        <td>
          {tags.map((tag, index) => {
            return (
              <div className={`py-2 ${index % 2 === 0 ? 'report-category-even' : 'report-category-odd'}`} key={tag}>
                {tag}
              </div>
            );
          })}
        </td>
      )}
      {showTagRow && stats?.date?.map((date) => {
        return (<td key={date}>
          {tags.map((tag, index) => {
            return (
              <div
                className={`py-2 ${index % 2 === 0 ? 'report-category-even' : 'report-category-odd'}`}
                key={tag}
              >{stats?.categories[tag]?.find((item) => {
                  return item.date === date;
                })?.count || 0}</div>);
          })}
        </td>);
      })}
    </tr>);
  };

  return statistics.loading ? (
    <Preload />
  ) : (
    <>
      <SubHeader
        title={lang['REPORT.TYPE.report_by_editors']}
      />
      <div className="kt-portlet">
        <div className="kt-portlet__body">
          <div className="kt-widget14 kt-p-0">
            <div className='kt-portlet mw-100 overflow-auto table-wrapper'>
              <Table
                responsive
                striped
                className='vertical-align-middle text-center editors-statistics'
              >
                <thead>
                  {tableHeader()}
                </thead>
                <tbody>
                  {tableBody()}
                  {categoryRow()}
                  {tagRow()}
                </tbody>
              </Table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportsByEditors;
