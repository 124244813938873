import React from 'react';
import Table from 'react-bootstrap/Table';
import PropTypes from 'prop-types';



const TableStat = ({ title, data }) => {
  return (
    <Table className='vertical-align-unset table-stat'>
      <thead>
        <tr>
          <th colSpan={2}>
            <h5>{title}</h5>
          </th>
        </tr>
      </thead>
      <tbody>
        {data.filter((item) => item).map((item) => {
          return (
            <tr className="stat-row" key={`${item.name}_${item.value}`}>
              {item.name && <td>{item.name}</td>}
              <td colSpan={!item.name ? 2 : 1}>{item.value}</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

TableStat.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.array.isRequired,
};

TableStat.defaultProps = {
  data: [],
};

export default TableStat;
