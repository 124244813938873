// eslint-disable-next-line import/no-namespace
import * as Yup from 'yup';
import { isBYPersonalData, isRU, isSwiftRU, isTransitIban } from '../../../utils/checker';
import { isXFLOW } from '../../../utils/isFabros';



export const employeeValidationSchema = Yup.object().shape({
  username: Yup.string().min(6).max(30).matches(/^[a-zA-Z0-9._-]*$/).required(),
  firstname: Yup.string().matches(/^[a-zA-Z ]*$/).required(),
});

const cyrillicNameRegExp = /^[а-яёА-ЯЁ ]*$/;
const generalRegExp = /^[а-яёА-ЯЁa-zA-Z\d- ]*$/;
const latinNameRegExp = /^[a-zA-Z\d- ]*$/;

export const freelancerValidationSchema = Yup.lazy((values) => {
  if (isXFLOW) {
    return Yup.object().shape({
      username: Yup.string().min(6).max(30).matches(/^[a-zA-Z0-9._-]*$/).required(),
      firstname: Yup.string().max(50).matches(isBYPersonalData(values) ? /^[а-яёА-ЯЁ ]*$/ : /^[a-zA-Z\d- ]+$/).required(),
      patronymic: Yup.string().matches(isBYPersonalData(values) ? /^[а-яёА-ЯЁ ]*$/ : /^[a-zA-Z\d- ]*$/).nullable(),
      lastname: Yup.string().matches(isBYPersonalData(values) ? /^[а-яёА-ЯЁ ]*$/ : /^[a-zA-Z\d- ]+$/).required(),
      address: Yup.string().trim().min(1).max(250).matches(isBYPersonalData(values) ? /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/ : /^[a-zA-Z0-9.,\-/ ]+$/).required(),
      city: Yup.string().trim().min(1).max(250).matches(isBYPersonalData(values) ? /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/ : /^[a-zA-Z0-9.,\-/ ]+$/).required(),
      phone: Yup.string().trim().min(1).max(20).matches(/^[^*]+$/).required(),
      telegram: Yup.string().trim().min(4).nullable(),
      birthday: Yup.string().required(),
      passport_number: Yup.string().trim().max(250).matches(/^[^*]+$/).nullable(),
      expiration_date: Yup.string().required(),
      iban: Yup.string().trim().min(1).max(34).matches(/^[a-zA-Z\d]*$/).required(),
      transit_iban: Yup.string().nullable().test(
        'transit_iban',
        'Wrong transit iban',
        (val) => {
          if (val && isTransitIban(values)) {
            return val.trim().length >= 2 && val.trim().length <= 50 && !val.includes('*');
          }

          return true;
        },
      ),
      swift: Yup.string().trim().min(8).max(11).required(),
      country: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      }).required(),
      country_issuing_document: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      }).required(),
      bic_number: Yup.string().nullable().test(
        'bic_number',
        'Wrong bic number',
        (val) => {
          if (isSwiftRU(values)) {
            const pattern = /^\d{9}$/;

            return pattern.test(val);
          }

          return true;
        },
      ),
      bank: Yup.string().max(190).matches(/^[/a-zA-Z\d.\-_ ']+$/).required(),
      bank_country: Yup.object().shape({
        value: Yup.number().required(),
        label: Yup.string().required(),
      }).required(),
      bank_account_name: Yup.string().max(191).matches(/^[a-zA-Z\d-_ ']+$/).required(),
      tin_country: Yup.object().shape({
        value: Yup.string().length(2).required(),
        label: Yup.string().required(),
      }).required(),
      tin_number: Yup.string().trim().max(190).required(),
      post_code: Yup.string().trim().max(190).required(),
    });
  }

  return Yup.object().shape({
    citizenship: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    residence: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).test(
      'residence',
      'Field should not be equals',
      (val) => {
        return !(values.has_residence_confirmation.value && !val) && val?.value !== values.citizenship?.value;
      }
    ).nullable(),
    email: Yup.string().email().required(),
    username: Yup.string().min(6).max(30).matches(/^[a-zA-Z0-9._-]*$/).required(),
    firstname: Yup.string().max(50).required().test(
      'firstname',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return cyrillicNameRegExp.test(val);
        }
        if (isRU(values)) {
          return generalRegExp.test(val);
        }

        return latinNameRegExp.test(val);
      }
    ),
    patronymic: Yup.string().nullable().test(
      'patronymic',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return cyrillicNameRegExp.test(val);
        }
        if (isRU(values)) {
          return generalRegExp.test(val);
        }

        return latinNameRegExp.test(val);
      }
    ),
    lastname: Yup.string().required().test(
      'lastname',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return cyrillicNameRegExp.test(val);
        }
        if (isRU(values)) {
          return generalRegExp.test(val);
        }

        return latinNameRegExp.test(val);
      }
    ),
    address: Yup.string().trim().min(1).max(96).required().test(
      'address',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/.test(val);
        }
        if (isRU(values)) {
          return /^[а-яёйА-ЯЁЙa-zA-Z0-9.,\-/ ]+$/.test(val);
        }

        return /^[a-zA-Z0-9.,\-/ ]+$/.test(val);
      }
    ),
    city: Yup.string().trim().min(1).max(250).required().test(
      'city',
      'Check city field',
      (val) => {
        if (isBYPersonalData(values)) {
          return /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/.test(val);
        }
        if (isRU(values)) {
          return /^[а-яёйА-ЯЁЙa-zA-Z0-9.,\-/ ]+$/.test(val);
        }

        return /^[a-zA-Z0-9.,\-/ ]+$/.test(val);
      }
    ),
    phone: Yup.string().trim().min(1).max(20).matches(/^[^*]+$/).required(),
    telegram: Yup.string().trim().min(4).nullable(),
    birthday: Yup.string().required(),
    personal_number: Yup.string().nullable().test(
      'personal_number',
      'Field should not be empty',
      (val) => {
        if (isBYPersonalData(values)) {
          return val.trim().length === 14 && !val.includes('*');
        }

        return true;
      }
    ),
    passport_number: Yup.string().trim().max(250).matches(/^[^*]+$/).nullable(),
    organization: Yup.string().trim().max(250).matches(/^[^*]+$/).required(),
    date_of_issue: Yup.string().required(), // todo check
    expiration_date: Yup.string().required(),
    insurance_number: Yup.string().max(25).matches(/^[^*]*$/).nullable(),
    iban: Yup.string().trim().min(1).max(34).matches(/^[a-zA-Z\d]*$/).required(),
    transit_iban: Yup.string().nullable().test(
      'transit_iban',
      'Wrong transit iban',
      (val) => {
        if (val && isTransitIban(values)) {
          return val.trim().length >= 2 && val.trim().length <= 50 && !val.includes('*');
        }

        return true;
      },
    ),
    swift: Yup.string().trim().min(8).max(11).required(),
    country: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    has_been_on_territory: isBYPersonalData(values) ? Yup.object().shape({
      value: Yup.boolean().transform((value) => {
        return !!value;
      }),
    }).required() : Yup.object().shape({
      value: Yup.boolean().transform((value) => {
        return !!value;
      }),
    }).nullable(),
    has_residence_confirmation: isBYPersonalData(values) ? Yup.object().shape({
      value: Yup.boolean().transform((value) => {
        return !!value;
      }),
    }).required() : Yup.object().shape({
      value: Yup.boolean().transform((value) => {
        return !!value;
      }),
    }).nullable(),
    bic_number: Yup.string().nullable().test(
      'bic_number',
      'Wrong bic number',
      (val) => {
        if (isSwiftRU(values)) {
          const pattern = /^\d{9}$/;

          return pattern.test(val);
        }

        return true;
      },
    ),
    inn: isRU(values) ? Yup.string().required().test(
      'inn',
      'Wrong INN',
      (val) => {
        const pattern = /^\d{12}$/;

        return pattern.test(val);
      },
    ) : Yup.string().nullable(),
  });
});
