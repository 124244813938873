import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';


/**
 * Component for tabs
 *
 * @returns {*}
 */
class Tabs extends React.PureComponent {
  state = {
    curTab: 0,
  };

  isRenderTab = true;

  render () {
    const { tabs = [], fluid, stickyHeader } = this.props;
    const _tabs = tabs.filter((tab) => tab);

    return (
      <div className={`kt-portlet kt-portlet--tabs ${stickyHeader ? 'kt-portlet--sticky__tabs-header' : ''} ${fluid ? ' kt-portlet--height-fluid' : ''}`}>
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title font-weight-bold">
              {this.props.title || ''}
            </h3>
          </div>
          <div className="kt-portlet__head-toolbar">
            <ul className="nav nav-tabs nav-tabs-line d-flex justify-content-end">
              {_tabs.map(((tab, index) => {
                return (
                  <li
                    key={uuidv4()}
                    className='nav-item kt-m-0 kt-ml-15'
                  >
                    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                    <span
                      style={{
                        cursor: 'pointer',
                      }}
                      className={`nav-link text-right${index === this.state.curTab ? ' active' : ''}`}
                      onClick={() => {
                        if (_tabs[this.state.curTab].beforeDestroy) {
                          _tabs[this.state.curTab].beforeDestroy();
                        }
                        if (_tabs[index].beforeRender) {
                          _tabs[index].beforeRender();
                        }
                        this.isRenderTab = true;
                        this.setState({
                          curTab: index,
                        });
                      }}
                    >
                      {tab.icon && <i className={tab.icon} />} {tab.title || 'tab'}
                    </span>
                  </li>
                );
              }))}
            </ul>
          </div>
        </div>
        <div
          className='kt-portlet__body'
          style={this.props.styleBody || {}}
        >
          <div className='tab-content'>
            <div className={`tab-panel active ${_tabs[this.state.curTab]?.scrollable ? 'tabs-table-wrapper' : ''}`}>
              {_tabs[this.state.curTab] && _tabs[this.state.curTab].body && _tabs[this.state.curTab].body()}
            </div>
          </div>
        </div>
      </div>
    );
  }

  componentDidMount () {
    this.setActiveTab();
    this.afterRenderCall();
  }

  componentDidUpdate () {
    this.setActiveTab();
    this.afterRenderCall();
  }

  setActiveTab () {
    const index = this.props.tabs.findIndex((tab) => tab.active);

    if (index === -1) {
      return;
    }
    this.setState({ curTab: index });
  }

  afterRenderCall = () => {
    const { tabs = [] } = this.props;
    const _tabs = tabs.filter((tab) => tab);

    if (this.isRenderTab) {
      if (_tabs[this.state.curTab] && _tabs[this.state.curTab].afterRender) {
        _tabs[this.state.curTab].afterRender();
      }

      this.props.onTabChange();

      this.isRenderTab = false;
    }
  };
}

Tabs.propTypes = {
  title: PropTypes.string,
  fluid: PropTypes.bool,
  stickyHeader: PropTypes.bool,
  styleBody: PropTypes.object,
  onTabChange: PropTypes.func,
  tabs: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      afterRender: PropTypes.func,
      beforeRender: PropTypes.func,
      body: PropTypes.func.isRequired,
      icon: PropTypes.string,
      title: PropTypes.string,
      name: PropTypes.string,
      active: PropTypes.bool,
      scrollable: PropTypes.bool,
    }),
    PropTypes.any,
  ])),
};

Tabs.defaultProps = {
  stickyHeader: true,
  onTabChange: () => {},
};

export default Tabs;
