import taskTypes from '../taskTypes';
import {
  getPriceByColorComplexityLevel,
  getPriceByContourComplexityLevel,
  highestComplexityLevel,
} from '../../projects/projectPrices';



const calculateTaskPriceByUserRate = (task, user, projectOptions) => {
  switch (task.type) {
    case taskTypes.artist_drawing.type:
      if (Number(projectOptions.contour_complexity_level) === highestComplexityLevel) {
        return task.price;
      }
      return user?.rates?.artist?.[`c${projectOptions.contour_complexity_level}`]
        || getPriceByContourComplexityLevel(projectOptions.contour_complexity_level);
    case taskTypes.designer_coloring.type:
      if (Number(projectOptions.color_complexity_level) === highestComplexityLevel) {
        return task.price;
      }
      return user?.rates?.colorist?.[`c${projectOptions.color_complexity_level}`]
        || getPriceByColorComplexityLevel(projectOptions.color_complexity_level);
    default:
      return task.price;
  }
};

export default calculateTaskPriceByUserRate;
