import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import Modal from '../../../../widgets/Modal';



const DeleteGroup = ({
  show,
  onClose,
  onDelete,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const handleDelete = () => {
    onDelete();
    onClose();
  };

  return (
    <Modal
      title={lang['TAG_SYSTEM.GROUPS.YOU_SURE_DELETE']}
      show={show}
      bodyStyle={{ overflow: 'unset' }}
      close={onClose}
      footer={(
        <>
          <Button variant='secondary' onClick={onClose}>{lang['GLOBAL.CANCEL']}</Button>
          <Button variant='danger' onClick={handleDelete}>{lang['GLOBAL.YES_DELETE']}</Button>
        </>
      )}
    >
      <div>{lang['GLOBAL.YOU_SURE_DELETE_SUB']}</div>
    </Modal>
  );
};

DeleteGroup.propTypes = {
  show: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

DeleteGroup.defaultProps = {
  show: false,
  onClose: () => {},
};

export default DeleteGroup;
