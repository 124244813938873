import getTesterSalaryByTask from '../utils/getTesterSalaryByTask';



export const SET_BONUSES = 'SET_BONUSES';
export const CREATE_BONUS = 'CREATE_BONUS';
export const UPDATE_BONUS = 'UPDATE_BONUS';
export const CHANGE_BONUS_STATUS = 'CHANGE_BONUS_STATUS';
export const DELETE_BONUS = 'DELETE_BONUS';
export const UPDATE_TESTER_TIME = 'UPDATE_TESTER_TIME';
export const APPROVE_TESTER = 'APPROVE_TESTER';

export const updateTesterTimeAction = (payload) => ({
  type: UPDATE_TESTER_TIME,
  payload,
});

export const approveTesterAction = (payload) => ({
  type: APPROVE_TESTER,
  payload,
});

export const setBonusesAction = (bonuses) => ({
  type: SET_BONUSES,
  payload: bonuses,
});

export const updateBonusAction = (taskId, bonusAmount, bonusId, reason, bonusStatus) => ({
  type: UPDATE_BONUS,
  taskId,
  bonusAmount,
  bonusId,
  reason,
  bonusStatus,
});

export const createBonusAction = (bonusAmount, taskId, reason, bonusId, bonusStatus) => ({
  type: CREATE_BONUS,
  bonusAmount,
  taskId,
  reason,
  bonusId,
  bonusStatus,
});

export const deleteBonusAction = (bonusId) => ({
  type: DELETE_BONUS,
  bonusId,
});

export const changeBonusStatusAction = (taskId, bonusId, bonusStatus) => ({
  type: CHANGE_BONUS_STATUS,
  taskId,
  bonusId,
  bonusStatus,
});

const initialState = [];

export const bonusesReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_BONUSES: {
      return [ ...action.payload ];
    }
    case UPDATE_TESTER_TIME: {
      const index = state.findIndex((bonus) => {
        return bonus.tasks.find(((task) => task.id === action.payload.taskId));
      });

      if (index === -1) {
        return state;
      }

      const bonuses = [ ...state ];

      bonuses[index].tasks.forEach(((task) => {
        if (task.id === action.payload.taskId) {
          task.testing_time = Number(action.payload.time);
        }
      }));

      return bonuses;
    }
    case APPROVE_TESTER: {
      const index = state.findIndex((bonus) => {
        return bonus.users.find(((user) => user.id === action.payload));
      });

      if (index === -1) {
        return state;
      }

      const bonuses = [ ...state ];

      bonuses[index].tasks.forEach(((task) => {
        task.price = getTesterSalaryByTask(task.testing_time);
      }));

      return bonuses;
    }
    case CREATE_BONUS:
    case UPDATE_BONUS: {
      const index = state.findIndex((bonus) => {
        return bonus.tasks.find(((task) => task.id === action.taskId));
      });

      if (index === -1) {
        return state;
      }

      const bonuses = [ ...state ];

      bonuses[index].tasks.map(((task) => {
        if (task.id === action.taskId) {
          task.bonus.id = (task.bonus.id && task.bonus.id === action.bonusId) ? task.bonus.id : Number(action.bonusId);
          task.bonus.amount = Number(action.bonusAmount);
          task.bonus.status = Number(action.bonusStatus);
          task.bonus.reason = action.reason;
        }
        return task;
      }));

      return bonuses;
    }
    case CHANGE_BONUS_STATUS: {
      const index = state.findIndex((bonus) => {
        return bonus.tasks.find(((task) => task.id === action.taskId));
      });

      if (index === -1) {
        return state;
      }

      const bonuses = [ ...state ];

      bonuses[index].tasks.map(((task) => {
        if (task.bonus.id === action.bonusId) {
          task.bonus.id = Number(action.bonusId);
          task.bonus.status = Number(action.bonusStatus);
        }
        return task;
      }));

      return bonuses;
    }
    case DELETE_BONUS: {
      let currentUserBonus = 0;

      const index = state.findIndex((bonus) => {
        return bonus.tasks.find(((task) => task.bonus.id === action.bonusId));
      });

      if (index === -1) {
        return state;
      }

      const bonuses = [ ...state ];

      bonuses[index].tasks.map(((task) => {
        if (task.bonus.id === action.bonusId) {
          task.bonus.id = null;
          task.bonus.amount = null;
        }
        return task;
      }));

      bonuses[index].tasks.map(((task) => {
        currentUserBonus += Number(task.bonus.amount);
        return currentUserBonus;
      }));

      bonuses[index].user.cur_bonuse = currentUserBonus;

      state = [ ...bonuses ];

      return state;
    }

    default: {
      return state;
    }
  }
};
