import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import PropTypes from 'prop-types';
import MySelect from '../../../widgets/MySelect';
import { DatePickerForwardRef as MyDatePicker } from '../../../widgets/DatePicker';
import { isBYCitizenship, isBYPersonalData } from '../../../utils/checker';
import getCountriesOptionsList from '../../../utils/getCountriesOptionsList';
import getResidenceOptionsList from '../../../utils/getResidenceOptionsList';
import { isFabros } from '../../../utils/isFabros';



const PersonalInformationTab = ({ isDisabled, control, errors, formValues }) => {
  const { lang, countries, user } = useSelector(({ language, countries, user }) => ({
    lang: language.lang,
    countries: countries.countries,
    user: user.user,
  }), shallowEqual);

  return (
    <>
      {!user.is_employee && (
        <div className="row">
          {isFabros && <>
            <Controller
              name="citizenship"
              control={control}
              render={({ field }) => (<>
                <Form.Group className="col-xl-4">
                  <label>{lang['REGISTRATION.CITIZENSHIP']}</label>
                  <MySelect
                    {...field}
                    isClearable
                    disabled={isDisabled}
                    isInvalid={!!errors.citizenship}
                    options={getCountriesOptionsList(countries)}
                  />
                  <Form.Text>
                    {lang['REGISTRATION.COUNTRY_CITIZENSHIP_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.COUNTRY_CITIZENSHIP_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              </>)}
            />
            <Controller
              name="residence"
              control={control}
              render={({ field }) => (<>
                <Form.Group data-tour="country" className="kt-mb-0-xl kt-mb-15 col-xl-4">
                  <Form.Label>
                    {lang['REGISTRATION.COUNTRY_RESIDENCE']}
                  </Form.Label>
                  <MySelect
                    {...field}
                    isClearable
                    disabled={isDisabled}
                    isInvalid={!!errors.residence}
                    options={getCountriesOptionsList(countries)}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.COUNTRY_RESIDENCE_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.COUNTRY_RESIDENCE_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              </>)}
            />
          </>}
          <Controller
            name="birthday"
            control={control}
            render={({ field }) => (<>
              <Form.Group className={isFabros ? 'col-xl-4' : 'col-12'}>
                <Form.Label>
                  {lang['REGISTRATION.BIRTHDAY']}
                </Form.Label>
                <MyDatePicker
                  {...field}
                  disabled={isDisabled}
                  className='w-100'
                  isInvalid={!!errors.birthday}
                  maxDate={new Date()}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.BIRTHDAY_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.BIRTHDAY_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            </>)}
          />
        </div>
      )}
      {isBYCitizenship(formValues) && isFabros && (
        <>
          <div className="row">
            <Controller
              name="has_residence_confirmation"
              control={control}
              render={({ field }) => (
                <Form.Group data-tour="has_residence_confirmation" className="kt-mb-15-xl col-12">
                  <Form.Label>
                    {lang['REGISTRATION.RESIDENCE_CONFIRMATION']}
                  </Form.Label>
                  <MySelect
                    {...field}
                    disabled={isDisabled}
                    name="has_residence_confirmation"
                    isInvalid={!!errors.has_residence_confirmation}
                    options={getResidenceOptionsList(lang)}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.RESIDENCE_CONFIRMATION_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.RESIDENCE_CONFIRMATION_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              )}
            />
          </div>

          {!!formValues?.has_residence_confirmation?.value && isFabros && (
            <div className="row">
              <Controller
                name="has_been_on_territory"
                control={control}
                render={({ field }) => (
                  <Form.Group data-tour="has_been_on_territory" className="kt-mb-15-xl col-12">
                    <Form.Label>
                      {lang['REGISTRATION.HAS_BEEN_ON_TERRITORY']}
                    </Form.Label>
                    <MySelect
                      {...field}
                      disabled={isDisabled}
                      name="has_been_on_territory"
                      isInvalid={!!errors.has_been_on_territory}
                      options={getResidenceOptionsList(lang)}
                    />
                    <Form.Text className="text-muted">
                      {lang['REGISTRATION.RESIDENCE_CONFIRMATION_PLEASE']}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {lang['REGISTRATION.RESIDENCE_CONFIRMATION_ERROR']}
                    </Form.Control.Feedback>
                  </Form.Group>
                )}
              />
            </div>
          )}
        </>
      )}
      <div className="row">
        <Controller
          name="username"
          control={control}
          render={({ field }) => (
            <Form.Group className={user.is_employee ? 'col-lg-6' : 'col-lg-3'}>
              <Form.Label>
                {lang['REGISTRATION.USERNAME']}
              </Form.Label>
              <Form.Control
                {...field}
                type="text"
                disabled={isDisabled}
                isInvalid={!!errors.username}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.USERNAME_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.USERNAME_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
        <Controller
          name="firstname"
          control={control}
          render={({ field }) => (
            <Form.Group className={user.is_employee ? 'col-lg-6' : 'col-lg-3'}>
              <Form.Label>
                {lang['REGISTRATION.FIRST_NAME']}
              </Form.Label>
              <Form.Control
                {...field}
                type="text"
                disabled={isDisabled}
                isInvalid={!!errors.firstname}
              />
              <Form.Text className="text-muted">
                {isBYPersonalData(formValues) ? lang['REGISTRATION.FIRST_NAME_PLEASE_RUSSIA'] : lang['REGISTRATION.FIRST_NAME_PLEASE_LATIN']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {isBYPersonalData(formValues) ? lang['REGISTRATION.FIRST_NAME_ERROR_RUSSIAN'] : lang['REGISTRATION.FIRST_NAME_ERROR_LATIN']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
        {!user.is_employee && (
          <Controller
            name="lastname"
            control={control}
            render={({ field }) => (
              <Form.Group className={user.is_employee ? 'col-lg-6' : 'col-lg-3'}>
                <Form.Label>
                  {lang['REGISTRATION.LAST_NAME']}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="text"
                  disabled={isDisabled}
                  isInvalid={!!errors.lastname}
                />
                <Form.Text className="text-muted">
                  {isBYPersonalData(formValues) ? lang['REGISTRATION.LAST_NAME_PLEASE_RUSSIA'] : lang['REGISTRATION.LAST_NAME_PLEASE_LATIN']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {isBYPersonalData(formValues) ? lang['REGISTRATION.LAST_NAME_ERROR_RUSSIA'] : lang['REGISTRATION.LAST_NAME_ERROR_LATIN']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        )}
        {!user.is_employee && (
          <Controller
            name="patronymic"
            control={control}
            render={({ field }) => (
              <Form.Group className="col-lg-3">
                <Form.Label>
                  {lang['REGISTRATION.PATRONYMIC_NAME']}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="text"
                  disabled={isDisabled}
                  isInvalid={!!errors.patronymic}
                />
                <Form.Text className="text-muted">
                  {isBYPersonalData(formValues) ? lang['REGISTRATION.PATRONYMIC_NAME_PLEASE_RUSSIA'] : lang['REGISTRATION.PATRONYMIC_NAME_PLEASE_LATIN']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {isBYPersonalData(formValues) ? lang['REGISTRATION.PATRONYMIC_NAME_ERROR_RUSSIA'] : lang['REGISTRATION.PATRONYMIC_NAME_ERROR_LATIN']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        )}
      </div>
      <div className="row">
        {!user.is_employee && (
          <Controller
            name="phone"
            control={control}
            render={({ field }) => (
              <Form.Group className="col-lg-4">
                <Form.Label>
                  {lang['REGISTRATION.PHONE']}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="text"
                  disabled={isDisabled}
                  isInvalid={!!errors.phone}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.PHONE_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.PHONE_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        )}
        {!user.is_employee && (
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <Form.Group className={user.is_employee ? 'col-lg-6' : 'col-lg-4'}>
                <Form.Label>
                  {lang['CUSTOMER.EMAIL']}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="email"
                  disabled={isDisabled}
                  isInvalid={!!errors.email}
                />
                <Form.Text className="text-muted">
                  {lang['CUSTOMER.PLEASE_ENTER_EMAIL']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['CUSTOMER.ERROR.EMAIL']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        )}
        {!user.is_employee && (
          <Controller
            name="telegram"
            control={control}
            render={({ field }) => (
              <Form.Group className={'col-lg-4'}>
                <Form.Label>
                  {lang['REGISTRATION.TELEGRAM']}
                </Form.Label>
                <Form.Control
                  {...field}
                  type="text"
                  disabled={isDisabled}
                  isInvalid={!!errors.telegram}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.TELEGRAM_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.TELEGRAM_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />)
        }
      </div>
    </>
  );
};

PersonalInformationTab.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default PersonalInformationTab;
