if (!Promise.allSettled) {
  /**
   * Get allSettled promise array response
   *
   * @param  {Promise[]} promises
   * @returns {Promise<*[]>}
   */
  Promise.allSettled = (promises) => {
    return Promise.all(promises
      .map((promise) => Promise.resolve(promise)
        .then(
          (val) => ({ status: 'fulfilled', value: val }),
          (error) => ({ status: 'rejected', reason: error }),
        )));
  };
}
