import React from 'react';
import { connect } from 'react-redux';
import {
  NavLink, Redirect, Route, Switch,
} from 'react-router-dom';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import imgURL from '../../utils/imgURL';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import checkRole from '../../utils/checkRole';
import Preload from '../../widgets/Preload';
import Img from '../../widgets/Img';
import Modal from '../../widgets/Modal';
import DropFile from '../../widgets/DropFile';
import userActions from '../../store/user/actions';
import { notification } from '../../requests/notifications';
import urlPageProfile from '../../urls/urlPageProfile';
import urlPagePersonalInfo from '../../urls/urlPagePersonalInfo';
import urlPageUserContract from '../../urls/urlPageUserContract';
import urlPageChangePassword from '../../urls/urlPageChangePassword';
import urlPageTutorial from '../../urls/urlPageTutorial';
import urlPageUserPolicy from '../../urls/urlPageUserPolicy';
import DocumentPreview from '../../components/Document/DocumentPreview';
import { formatDate } from '../../utils/formats';
import { isFabros } from '../../utils/isFabros';
import roles from './roles/roles';
import ChangePassword from './ChangePassword';
import PersonalInformation from './PersonalInformation';
import Dashboard from './Dashboard';
import icons from './profileCategoriesIcons';



const propTypes = {
  lang: PropTypes.object.isRequired,
  curLang: PropTypes.string.isRequired,
  notification: PropTypes.func.isRequired,
  user: PropTypes.any.isRequired,
  userRoles: PropTypes.array.isRequired,
  multipleRoles: PropTypes.array.isRequired,
  updateAvatar: PropTypes.func.isRequired,
  userLoading: PropTypes.bool.isRequired,
};
/**
 * Component for user profile
 */

class MyProfile extends React.Component {
  MOBILE = 1024;

  state = {
    showMenu: false,
    isMobile: window.innerWidth <= this.MOBILE,
    showModal: false,
    LOAD: false,
    newAvatar: {},

  };

  render () {
    const { LOAD } = this.state;
    const { user, userRoles, multipleRoles, lang, curLang, userLoading } = this.props;

    return LOAD || userLoading ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={
            <>
              <button
                className="kt-subheader__mobile-toggle"
                onClick={() => {
                  this.setState({
                    showMenu: !this.state.showMenu,
                  });
                }}
              >
                <span />
              </button>
              {lang['CUSTOMER.VIEW_PROFILE']}
            </>
          }
        />
        <div className='kt-grid kt-grid--desktop kt-grid--ver kt-grid--ver-desktop kt-app'>
          <div className={`kt-grid__item kt-app__toggle kt-app__aside no-print kt-app__aside--fit kt-subheader--fixed${this.state.showMenu ? ' kt-app__aside--on' : ''}`}>
            <button
              className="kt-app__aside-close"
              onClick={() => {
                this.setState({
                  showMenu: !this.state.showMenu,
                });
              }}
            >
              <i className="la la-close" />
            </button>
            <div className="kt-portlet">
              <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-1 kt-pb-0">
                  <div className="kt-widget__head">
                    <div className="kt-widget__media">
                      <Img
                        src={imgURL(user?.avatar)}
                        style={{ cursor: 'pointer' }}
                        onClick={() => {
                          this.setState({
                            showModal: true,
                          });
                        }}
                      />
                    </div>
                    <div className="kt-widget__content">
                      <div className="kt-widget__section">
                        <span className="kt-widget__username no-hover">
                          {user?.username}
                        </span>
                        <span className="kt-widget__subtitle">
                          {multipleRoles.length ? (
                            <>
                              {multipleRoles.map((item, index) => (
                                <div key={item[index]}>
                                  {roles[multipleRoles[index]].title}
                                </div>
                              ))}
                            </>
                          ) : (
                            <>
                              {userRoles.length > 0 && roles[userRoles[userRoles.length - 1]].title}
                            </>
                          )}
                        </span>
                        {user?.first_signed_at && (
                          <div
                            className="kt-widget__info kt-mt-15"
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                            }}
                          >
                            <span className="kt-widget__data">{lang['GLOBAL.JOINED_AT']}:&nbsp;&nbsp;</span>
                            <span className="kt-widget__data text-right">
                              {formatDate(user?.first_signed_at)}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget__body">
                    <div className="kt-widget__content">
                      {user?.email && <div className="kt-widget__info">
                        <span className="kt-widget__label">{lang['CUSTOMER.EMAIL']}:</span>
                        <span className="kt-widget__data text-right">
                          {user?.email || lang['GLOBAL.NOT_ENTERED']}
                        </span>
                      </div>}
                      {checkRole(userRoles, [ roles.freelancer.key ], [ roles['lead-coloring-tester'].key ]) && user?.phone && <div className="kt-widget__info">
                        <span className="kt-widget__label">{lang['CUSTOMER.PHONE']}:</span>
                        <span className="kt-widget__data">
                          {user?.personal_info.phone || lang['GLOBAL.NOT_ENTERED']}
                        </span>
                      </div>}
                      {user?.financeInformation && user?.financeInformation.address && (
                        <div className="kt-widget__info">
                          <span className="kt-widget__label">
                            {lang['CUSTOMER.ADDRESS']}:
                          </span>
                          <span className="kt-widget__data">
                            {user?.financeInformation.address || lang['GLOBAL.NOT_ENTERED']}
                          </span>
                        </div>
                      )}
                    </div>
                    <div className="kt-widget__items">
                      {checkRole(userRoles, [ roles.freelancer.key ], [ roles['coloring-editor'].key, roles['lead-coloring-tester'].key ]) && (
                        <NavLink
                          to={urlPageProfile()} exact className="kt-widget__item"
                          activeClassName='kt-widget__item--active'
                        >
                          <span className="kt-widget__section">
                            <span className="kt-widget__icon">{icons.dashboard}</span>
                            <span className="kt-widget__desc">
                              {lang['CUSTOMER.DASHBOARD']}
                            </span>
                          </span>
                        </NavLink>
                      )}

                      <NavLink
                        to={urlPagePersonalInfo()} className="kt-widget__item"
                        activeClassName='kt-widget__item--active'
                      >
                        <span className="kt-widget__section">
                          <span className="kt-widget__icon">{icons.personalInformation}</span>
                          <span className="kt-widget__desc">
                            {lang['CUSTOMER.PERSONAL_INFO']}
                          </span>
                        </span>
                      </NavLink>

                      <NavLink
                        to={urlPageChangePassword()} className="kt-widget__item"
                        activeClassName='kt-widget__item--active'
                      >
                        <span className="kt-widget__section">
                          <span className="kt-widget__icon">{icons.changePassword}</span>
                          <span className="kt-widget__desc">
                            {lang['CUSTOMER.CHANGE_PASSWORD']}
                          </span>
                        </span>
                      </NavLink>

                      {userRoles.indexOf('lead-coloring-tester') === -1 && (
                        <>
                          <NavLink
                            to={urlPageUserContract()} className="kt-widget__item"
                            activeClassName='kt-widget__item--active'
                          >
                            <span className="kt-widget__section">
                              <span className="kt-widget__icon">{icons.myContacts}</span>
                              <span className="kt-widget__desc">
                                {lang['CUSTOMER.MY_CONTRACT']}
                              </span>
                            </span>
                          </NavLink>
                          <NavLink
                            to={urlPageUserPolicy()} className="kt-widget__item"
                            activeClassName='kt-widget__item--active'
                          >
                            <span className="kt-widget__section">
                              <span className="kt-widget__icon">{icons.myContacts}</span>
                              <span className="kt-widget__desc">
                                {lang[isFabros ? 'CUSTOMER.MY_POLICY' : 'CUSTOMER.MY_POLICY_XFLOW']}
                              </span>
                            </span>
                          </NavLink>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-grid__item kt-grid__item--fluid kt-app__content">
            <Switch>
              <Route path={urlPagePersonalInfo()} component={PersonalInformation} />
              <Route path={urlPageChangePassword()} component={ChangePassword} />
              <Route path={urlPageUserContract()} render={(props) => <DocumentPreview {...props} document={user?.contract?.term[curLang]} type="term" />} />
              <Route path={urlPageUserPolicy()} render={(props) => <DocumentPreview {...props} document={user?.contract?.policy[curLang]} type="policy" />} />
              {checkRole(userRoles, [ roles.freelancer.key ], [ roles['coloring-editor'].key, roles['lead-coloring-tester'].key ]) ? ([
                <Route exact key={1} path={urlPageProfile()} component={Dashboard} />,
                <Route exact key={3} path={urlPageTutorial()} component={Dashboard} />,
              ]) : (
                <Redirect from={urlPageProfile()} to={urlPagePersonalInfo()} />
              )}
            </Switch>
          </div>
        </div>
        <Modal
          title={lang['CUSTOMER.CHANGE_AVATAR']}
          show={this.state.showModal}
          close={() => {
            this.setState({
              showModal: false,
            });
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({
                    showModal: false,
                  });
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-success"
                disabled={!this.state.newAvatar.file || !this.state.newAvatar.preview}
                onClick={this.updateAvatar}
              >{lang['GLOBAL.SAVE']}</button>
            </>
          }
        >
          <DropFile
            multiple={false}
            accept={'image/*'}
            info={lang['CUSTOMER.MAX_SIZE']}
            addFile={(file) => {
              if (file.file.size > 1048576) {
                this.props.notification('CUSTOMER.ERROR_SIZE_AVATAR', 'error');
              } else {
                this.setState({
                  newAvatar: file,
                });
              }
            }}
          />

          <div className='kt-widget__head d-flex justify-content-center kt-mt-20'>
            <Img
              style={{
                width: 90,
                height: 90,
                objectFit: 'cover',
                marginRight: 15,
                borderRadius: 8,
              }}
              src={this.state.newAvatar.preview ? this.state.newAvatar.preview : imgURL(user?.avatar)}
            />
            <Img
              style={{
                width: 90,
                height: 90,
                objectFit: 'cover',
                borderRadius: '50%',
              }}
              src={this.state.newAvatar.preview ? this.state.newAvatar.preview : imgURL(user?.avatar)}
            />
          </div>
        </Modal>
      </>);
  }

  updateAvatar = async () => {
    if (!this.state.newAvatar.file || !this.state.newAvatar.preview) {
      return;
    }

    await this.props.updateAvatar(this.state.newAvatar.file);
    this.setState({
      showModal: false,
      newAvatar: {},
    });
  };
}

const mapStateToProps = (store) => {
  return {
    userLoading: store.user.loading,
    user: store.user.user,
    userRoles: store.user.roles,
    multipleRoles: store.user.multipleRoles,
    lang: store.language.lang,
    curLang: store.language.cur,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAvatar: bindActionCreators(userActions.updateAvatar, dispatch),
    notification: bindActionCreators(notification, dispatch),
  };
};

MyProfile.propTypes = propTypes;

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile);
