import React, { useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import Preload from '../../../widgets/Preload';
import tagSystemPictureActions from '../../../store/tagSystem/pictures/actions';
import projectActions from '../../../store/projects/actions';
import urlPageProjectDetails from '../../../urls/urlPageProjectDetails';
import urlPageTagSystemGroups from '../../../urls/urlPageTagSystemGroups';
import urlPageProjectsList from '../../../urls/urlPageProjectsList';
import { transformPictureData } from './helpers/transformers';
import PictureTagForm from './components/PictureTagForm';



const TagPicture = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams();

  const {
    lang,
    picture = {},
    loading,
    loading_structure,
    pictureStructures = [],
    project = {},
  } = useSelector(({
    language,
    tagSystemPictures,
    projects }) => (
    {
      lang: language.lang,
      loading: tagSystemPictures.loading,
      loading_structure: tagSystemPictures.loading_structure,
      picture: tagSystemPictures.picture,
      pictureStructures: tagSystemPictures.pictureStructures,
      project: projects.project,
    }
  ), shallowEqual);

  useEffect(() => {
    dispatch(tagSystemPictureActions.getPictureStructures());
    dispatch(tagSystemPictureActions.getPicture(id));
    dispatch(projectActions.getProject(id));
  }, []);

  const handleSave = (data) => {
    const requestBody = transformPictureData(data, {
      project,
      templates: pictureStructures,
    });

    dispatch(tagSystemPictureActions.updatePicture(requestBody)).then(() => {
      if (typeof location.key !== 'undefined') {
        history.goBack();
      }
    });
  };

  const loadingData = loading || loading_structure;

  return (
    <>
      <SubHeader
        subtitle={[
          <Link
            key={'project-details-key-1'}
            target="_blank"
            to={urlPageProjectsList({ type: 'coloring' })}
            className='hover'
          >
            {lang['MENU.PROJECTS']}
          </Link>,
          (<span key={'separator'} className="subtitle-separator" />),
          <Link
            key={'project-details-key-2'}
            target="_blank"
            to={urlPageProjectDetails({ projectId: project?.id })}
            className='hover'
          >
            {project?.id ?? '#'}
          </Link>,
          (<span key={'separator2'} className="subtitle-separator" />),
          <Link
            key={'group-tags'}
            target="_blank"
            to={urlPageTagSystemGroups()}
            className='hover'
          >
            {lang['MENU.TAG_SYSTEM']}
          </Link>,
        ]}
      />
      {loadingData ? <Preload />
        : (
          <div className='kt-portlet'>
            <div className='kt-portlet__body'>
              <PictureTagForm
                isLoading={loadingData}
                picture={picture}
                project={project}
                pictureStructures={pictureStructures}
                onSubmit={handleSave}
              />
            </div>
          </div>
        )
      }
    </>
  );
};

TagPicture.propTypes = {
  history: PropTypes.object.isRequired,
};
export default TagPicture;
