import React from 'react';
import Badge from 'react-bootstrap/Badge';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import Img from '../../../widgets/Img';
import imgURL from '../../../utils/imgURL';
import { getPreviewSm } from '../../projects/getPreviewFromProject';
import roles from '../roles/roles';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import { isSameDomain } from '../../../utils/isSameDomain';



const propTypes = {
  customer: PropTypes.any,
  user: PropTypes.object.isRequired,
};

/**
 * Component of a row of a table with a list of users
 *
 * @returns {*}
 */
const CustomerItemTable = ({ customer, user }) => {
  const customerHasIndividualRates = () => {
    return customer.rates && Object.values(customer.rates).some(
      (entry) => entry.c1 || entry.c2 || entry.c3
    );
  };

  return (
    <tr>
      <td>
        <span className="kt-media kt-media--xxxl">
          <Img src={imgURL(getPreviewSm(customer.avatar))} />
        </span>
      </td>
      <td>
        {isSameDomain(user.provider_id, customer.provider_id) ? (
          <Link
            to={urlPageFreelancerDetails({ freelancerId: customer.id })}
            className="text-dark hover"
          >
            {customer.username}
          </Link>) : <span>{customer.username}</span>}
      </td>
      <td>
        {customer.roles && customer.roles.length > 0 ? customer.roles.filter((role) => (role !== 'user' && role !== 'freelancer'))
          .map((role) => {
            return <p key={role}>{roles[role]?.title}</p>;
          }) : ''}
      </td>
      <td>
        <Badge
          as="checkbox"
          variant="success"
        >
          {customerHasIndividualRates() ? '✓' : ''}
        </Badge>
      </td>
      <td>
        <Badge
          as={customer.draft ? Link : 'span'}
          variant="dark"
          to={customer.draft
            ? `/tasks/status/draft?executor=${customer.id}&for_executor=true`
            : ''}
        >
          {customer.draft || 0}
        </Badge>
      </td>
      <td>
        <Badge
          as={customer.backlog ? Link : 'span'}
          variant="info"
          to={customer.backlog
            ? `/tasks/status/waiting?executor=${customer.id}&for_executor=true`
            : ''}
        >
          {customer.backlog || 0}
        </Badge>
      </td>
      <td>
        <Badge
          as={customer.in_work ? Link : 'span'}
          variant="danger"
          to={customer.in_work
            ? `/tasks/status/process?executor=${customer.id}&for_executor=true`
            : ''}
        >
          {customer.in_work || 0}
        </Badge>
      </td>
      <td>
        <Badge
          as={customer.done ? Link : 'span'}
          variant="success"
          to={customer.done
            ? `/tasks/status/finished?executor=${customer.id}&sort[checked_at]=desc&for_executor=true`
            : ''}
        >
          {customer.done || 0}
        </Badge>
      </td>
    </tr>
  );
};

CustomerItemTable.propTypes = propTypes;

export default CustomerItemTable;
