import { parseFromPars, parseToPars } from '../../../utils/parseUrlParams';
import { status } from '../../../utils/statusToColor';



export const getTypeTaskUrl = (typeTask, type, options = {}) => {

  let paramsStatus = options.paramsStatus;
  const coloringEditor = options.coloring_editor;
  const params = parseFromPars(window.location.search);

  if (paramsStatus === 'draft') {
    paramsStatus = 'all';
  } else if (paramsStatus === 'process') {
    paramsStatus = 'my';
  } else if (paramsStatus === 'waiting') {
    paramsStatus = 'available';
  }

  let url = `tasks/list/${paramsStatus}`;

  if (!paramsStatus) {
    const pathname = window.location.pathname;

    if (pathname.includes('available')) {
      url = `tasks/list/available${window.location.search}`;
    } else if (pathname.includes('all')) {
      url = `tasks/list/all${window.location.search}`;
    } else if (pathname.includes('my')) {
      url = `tasks/list/my${window.location.search}`;
    } else {
      url = window.location.pathname;
    }
  } else {
    if (window.location.pathname.includes('editor')) {
      url = `tasks/list/all?status=${options.paramsStatus}&editors=true`;
    }
  }

  if (window.location.search) {
    if (params.for_executor) {
      Object.keys(params).forEach((key) => {
        if (key === 'for_executor') {
          // eslint-disable-next-line fp/no-delete
          delete params[key];
        }
      });

      const added = parseToPars(
        {
          ...params,
          skip: null,
        }
      );

      if (added) {
        url += `${added}&`;
      }
    }
  }

  let added = '';

  if (params.executor) {
    if (paramsStatus !== status.process || typeTask === 'editor') {
      if (!url.includes('executor')) {
        added += `&executor=${params.executor}`;
      }
    } else {
      if (!url.includes('executor')) {
        added = `executor=${params.executor}`;
      }
    }
  }

  if (paramsStatus === 'all') {
    added += 'status=draft';
  }

  if (url.includes('?')) {
    url += added;
  } else {
    url += `?${added}`;
  }

  if (typeTask && typeTask !== 'editor') {
    url += `&type_task=${typeTask}`;
  }

  if (coloringEditor) {
    url += `&manager=${coloringEditor}`;
  }

  return url;
};
