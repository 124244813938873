import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';
import imgURL from '../../utils/imgURL';
import Img from '../../widgets/Img';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';
import { isSameDomain } from '../../utils/isSameDomain';



const propTypes = {
  lang: PropTypes.object.isRequired,
  customer: PropTypes.any.isRequired,
  user: PropTypes.object.isRequired,
};

/**
 * Component for user card on group page
 */

export const GroupCustomerItem = ({ customer, lang, user }) => {
  const showUsername = () => {
    if (isSameDomain(user.provider_id, customer.provider_id)) {
      return (
        <Link to={urlPageFreelancerDetails({ freelancerId: customer.id })} className="kt-widget__username margin_right" title={customer.username}>
          {customer.username}
        </Link>
      );
    }

    return <span className="kt-widget__username margin_right">{customer.username}</span>;
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-2 ">
            <div className="kt-widget__head">
              <div className="kt-widget__media">
                <Img className="kt-widget__img" src={imgURL(customer.avatar)} />
              </div>
              <div className="kt-widget__info">
                {showUsername()}
                <span className={`kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-${customer.inactive ? 'danger' : 'success'}`}>
                  {customer.inactive ? lang['CUSTOMER.LOCKED'] : lang['CUSTOMER.ACTIVE']}
                </span>
                <span className="kt-widget__desc">{lang[`CUSTOMER.TYPE.${customer.type}`]}</span>
              </div>
            </div>
            <div className="kt-widget__body">
              <div className="kt-widget__item" />
              <div className="kt-section__content kt-section__content--solid d-flex">
                <Button
                  variant="outline-secondary"
                  block
                  active
                  className="my-0 p-1"
                  style={{ background: 'transparent', cursor: 'default' }}
                >
                  {lang['GLOBAL.STATUS.in_line']}
                  <br />
                  <Badge
                    variant="info"
                    as={customer.tasks_count.waiting ? Link : 'span'}
                    to={customer.tasks_count.waiting
                      ? `/tasks/status/waiting?executor=${customer.id}&for_executor=true`
                      : ''}
                  >
                    {customer.tasks_count.waiting || 0}
                  </Badge>
                </Button>
                <Button
                  variant="outline-secondary"
                  block
                  active
                  className="mx-1 my-0 p-1"
                  style={{ background: 'transparent', cursor: 'default' }}
                >
                  {lang['GLOBAL.STATUS.in_work']}
                  <br />
                  <Badge
                    as={customer.tasks_count.process ? Link : 'span'}
                    to={customer.tasks_count.process
                      ? `/tasks/status/process?executor=${customer.id}`
                      : ''}
                    variant="danger"
                  >
                    {customer.tasks_count.process || 0}
                  </Badge>
                </Button>
                <Button
                  variant="outline-secondary"
                  block
                  active
                  className="my-0 p-1"
                  style={{ background: 'transparent', cursor: 'default' }}
                >
                  {lang['GLOBAL.STATUS.done']}
                  <br />
                  <Badge
                    as={customer.tasks_count.done ? Link : 'span'}
                    to={customer.tasks_count.done
                      ? `/tasks/status/finished?executor=${customer.id}`
                      : ''}
                    variant="dark"
                  >
                    {customer.tasks_count.done || 0}
                  </Badge>
                </Button>
              </div>
            </div>
            <div className="kt-widget__footer">
              {isSameDomain(user.provider_id, customer.provider_id) ? (
                <Link to={urlPageFreelancerDetails({ freelancerId: customer.id })} className="btn btn-label-primary btn-lg btn-upper">{lang['CUSTOMER.VIEW_PROFILE']}</Link>)
                : <span className="btn btn-label-primary btn-lg btn-upper disabled">{lang['CUSTOMER.VIEW_PROFILE']}</span>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  user: store.user.user,
});

GroupCustomerItem.propTypes = propTypes;

export default connect(mapStoreToProps)(GroupCustomerItem);
