import React from 'react';
import {
  Redirect, Route, Switch, useHistory,
} from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { useLastLocation } from 'react-router-last-location';
import HomePage from '../pages/home/HomePage';
import ErrorsPage from '../pages/errors/ErrorsPage';
import LogoutPage from '../pages/authorization/Logout';
import { LayoutContextProvider } from '../../_metronic';
import Layout from '../../_metronic/layout/Layout';
import Login from '../pages/authorization/Login';
import Registration from '../pages/authorization/Registration';
import Term from '../pages/customers/Term';
import Notification from '../widgets/Notification';
import urlPageLogout from '../urls/urlPageLogout';
import Privacy from '../pages/customers/components/Privacy';
import PublicOffer from '../pages/publicOffer/PublicOffer';
import urlPageError from '../urls/urlPageError';
import userActions from '../store/user/actions';
import store from '../store/store';
import usePageVisibility from '../hooks/usePageVisibility';
import { saveLastLocation, getLastLocation } from './RouterHelpers';



export const Routes = () => {
  const lastLocation = useLastLocation();
  const history = useHistory();
  const isPageVisible = usePageVisibility();

  if (isPageVisible) {
    const tokenExpiresAt =  localStorage.getItem('tokenExpiresAt');

    if (tokenExpiresAt && Date.now() >= tokenExpiresAt) {
      store.dispatch(userActions.logout());
    }
  }
  saveLastLocation(lastLocation);
  const { isAuthorized, menuConfig, term, policy } = useSelector(
    ({ user, builder: { menuConfig }, term, policy }) => ({
      menuConfig,
      isAuthorized: !!user.authToken,
      userLastLocation: getLastLocation(),
      term,
      policy,
      user: user.user,
    }),
    shallowEqual,
  );

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      { !term.isAccepted ? (
        <Term />
      ) : !policy.isAccepted ? (
        <Privacy />
      ) : (
        <>
          <Switch>
            <Route path={urlPageLogout()} component={LogoutPage} />

            {!isAuthorized ? [
              <Route key="registration" path="/registration" component={Registration} />,
              <Route key="login" path="/login" component={Login} />,
              <Route key="publicOffer" path={'/public-offer'} component={PublicOffer} />,
              <Route key="error" path={urlPageError()} component={ErrorsPage} />,
              <Redirect key="redirect-login" to="/login" />,
            ] : (
              <Layout>
                <HomePage userLastLocation="/" />
              </Layout>
            )}
          </Switch>
          <Notification />
        </>
      )}
    </LayoutContextProvider>
  );
};
