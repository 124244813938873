import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import PropTypes from 'prop-types';



const propTypes = {
  active: PropTypes.number,
  count: PropTypes.number,
  select: PropTypes.func,
};

/**
 * Component for create pagination
 *
 * @returns {*}
 */
const Paginator = ({ active = 0, count = 0, select }) => {
  const [ first, setFirst ] = useState(0);
  const [ last, setLast ] = useState(1);

  const handlePages = () => {
    let firstL = active - 2 >= 0 ? active - 2 : 0;
    const lastL = firstL + 4 <= count ? firstL + 4 : count;

    firstL = lastL - 4 >= 0 ? lastL - 4 : 0;

    setFirst(firstL);
    setLast(lastL);
  };

  useEffect(() => {
    handlePages();
  }, [ active ]);

  useEffect(() => {
    if (window.innerWidth > 1366) {
      handlePages();
    }
  }, []);

  const getBtns = () => {
    const btns = [];

    if (first < last) {
      // eslint-disable-next-line fp/no-loops
      for (let i = first; i <= last && i <= count; i += 1) {
        btns.push(
          <Pagination.Item
            key={i}
            active={i === active}
            className="d-flex align-items-center"
            onClick={() => {
              if (i !== active && select) {
                select(i);
              }
            }}
          >
            {i + 1}
          </Pagination.Item>,
        );
      }
    }

    return btns;
  };

  useEffect(() => {
    const resizeListener = (event) => {
      if (event.srcElement.innerWidth < 1366) {
        setFirst(0);
        setLast(1);
      } else {
        handlePages();
      }
    };

    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  return (
    <Pagination className="kt-mb-0">
      {active !== 0 && count > 0 && (
        <>
          <Pagination.First
            className="d-flex align-items-center"
            onClick={() => {
              if (select) {
                select(0);
              }
            }}
          />
        </>
      )}

      {getBtns()}

      {active !== count && count > 0 && (
        <>
          <Pagination.Last
            className="d-flex align-items-center"
            onClick={() => {
              if (select) {
                select(count);
              }
            }}
          />
        </>
      )}
    </Pagination>
  );
};

Paginator.propTypes = propTypes;

export default Paginator;
