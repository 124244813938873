import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Alert, Form, Button } from 'react-bootstrap';
import MySelect from '../../widgets/MySelect';
import getUsersOptionsList from '../../utils/getUsersOptionsList';
import config from '../../../config/app';



const FormTestersMode = ({
  usersList,
  isActiveTestMode,
  testModeParams,
}) => {
  const [ executor, setExecutor ] = useState({ value: testModeParams?.developer_id, label: testModeParams?.username });
  const handleApplyTestMode = (executor) => {
    const requestParams = { is_developer: config.test_mode_token, developer_id: executor.value, username: executor.label };

    localStorage.setItem('test_mode_params', JSON.stringify(requestParams));
    localStorage.removeItem('selected_role');
    window.location.reload();
  };

  const handleStopTestMode = () => {
    localStorage.removeItem('test_mode_params');
    window.location.reload();
  };

  return (
    <>
      <Form>
        <Alert key="info" variant="outline-warning">
          Users test mode is sharing between browser tabs. You can stop test manually or it will be automatically stopped
          after application re-login.
        </Alert>
        <Form.Group>
          <Form.Label><strong>User:</strong></Form.Label>
          <MySelect
            className="kt-mb-15"
            isClearable
            options={getUsersOptionsList(usersList)}
            value={executor}
            onChange={(selectedExecutor) => {
              setExecutor({
                value: selectedExecutor?.value,
                label: selectedExecutor?.label,
              });
            }}
          />
        </Form.Group>
      </Form>
      <div className="text-center">
        {isActiveTestMode && (
          <Button
            variant="outline-danger"
            className="mr-5"
            onClick={() => {
              handleStopTestMode();
            }}
          >
            Stop test
          </Button>
        )}
        <Button
          variant="outline-info"
          type="submit"
          disabled={!usersList || !executor?.value}
          onClick={() => {
            handleApplyTestMode(executor);
          }}
        >
          Run test
        </Button>
      </div>
    </>
  );
};

FormTestersMode.propTypes = {
  usersList: PropTypes.array,
  isActiveTestMode: PropTypes.bool.isRequired,
  testModeParams: PropTypes.object.isRequired,
};

export default FormTestersMode;
