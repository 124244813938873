import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { uniqBy } from 'lodash';
import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';
import imgURL from '../../../../utils/imgURL';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../../utils/formats';
import Img from '../../../../widgets/Img';
import statusToColor, { status } from '../../../../utils/statusToColor';
import { priorityToColor } from '../../priorities';
import { getPreviewLg, getPreviewSm } from '../../getPreviewFromProject';
import { mapCategoryIdToName, defaultCategoryValue } from '../../../../requests/categories';
import checkRole from '../../../../utils/checkRole';
import roles from '../../../customers/roles/roles';
import { isEmptyObject } from '../../../../utils/checker';
import groupsRoles from '../../../customers/roles/groupsRoles';
import urlPageProfile from '../../../../urls/urlPageProfile';
import urlPageProjectDetails from '../../../../urls/urlPageProjectDetails';
import urlPageFreelancerDetails from '../../../../urls/urlPageFreelancerDetails';
import { getBadgeVariantForDeadline } from '../../../../utils/getClassesForDeadline';
import { isSameDomain } from '../../../../utils/isSameDomain';



const propTypes = {
  lang: PropTypes.object.isRequired,
  users: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  project: PropTypes.object.isRequired,
  isColoring: PropTypes.bool.isRequired,
  canSplit: PropTypes.bool,
  canSelected: PropTypes.bool.isRequired,
  selected: PropTypes.bool,
  onSelect: PropTypes.func,
  setModalCategories: PropTypes.func.isRequired,
  userRoles: PropTypes.any,
  setProjectId: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  user: PropTypes.object,
  listPreview: PropTypes.array,
  setCategoryId: PropTypes.func.isRequired,
  isDashboardProjectItems: PropTypes.bool.isRequired,
  showPreviousExecutor: PropTypes.bool.isRequired,
  setUpdatedProject: PropTypes.func,
  canChangeCategory: PropTypes.bool,
  isSelectedStage: PropTypes.bool,
};

/**
 * Project line component for the table list of projects
 */

class ProjectItemTable extends React.PureComponent {
  state = {
    tasks: [],
  };

  showExecutor = (curExecutor) => {
    if (!isEmptyObject(curExecutor) && !this.props.showPreviousExecutor) {
      return isSameDomain(this.props.user?.provider_id, curExecutor.provider_id) ?
        <Link
          to={curExecutor.id === this.props.user?.id ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: curExecutor.id })}
          className='font-weight-bolder kt-font-md text-dark hover'
        >
          {curExecutor.username}
        </Link> : <span>{curExecutor.username}</span>;
    }

    if (this.props.showPreviousExecutor && this.props.project.last_executor) {
      return isSameDomain(this.props.user?.provider_id, this.props.project.last_executor.provider_id) ?
        <Link
          to={urlPageFreelancerDetails({ freelancerId: this.props.project.last_executor.id })}
          className='font-weight-bolder kt-font-md text-dark hover'
        >
          {this.props.project.last_executor.username}
        </Link> : <span>{this.props.project.last_executor.username}</span>;
    }

    return '';
  };

  render () {
    const {
      project,
      project: { tasks },
      lang,
      users,
      isColoring,
      canSplit,
      canSelected,
      selected,
      isDashboardProjectItems,
      canChangeCategory,
      isSelectedStage,
    } = this.props;

    let executors = [];

    if (tasks) {
      tasks.forEach((task) => {
        const id = task.executor_id || (task.executor ? task.executor.id : -1);

        if (id) {
          executors.push(id);
        }
      });
    }

    executors = uniqBy(executors, (id) => id).map((id) => users.find((user) => id === user.id)).filter((user) => !!user);

    const curExecutor = this._getExecutor(project.executor);

    if (curExecutor) {
      executors = executors.filter((executor) => executor.id !== curExecutor.id);
    }

    return (
      <>
        <tr className="project project--table">
          {canSelected && (
          // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions
            <td
              onClick={() => {
                if (this.props.onSelect) {
                  this.props.onSelect(this.props.project.id);
                }
              }}
            >
              <label className="kt-checkbox kt-checkbox__wrapper_unset">
                <input
                  defaultChecked={selected}
                  type={'checkbox'}
                  aria-label="..."
                />
              </label>
            </td>
          )}
          <td>
            <span className="kt-media kt-media--xxxl bg">
              <Img
                src={imgURL(getPreviewSm(project.preview || project?.links))}
                current={{
                  id: project.id,
                  link: getPreviewLg(project.preview || project?.links),
                }}
                files={this.props.listPreview}
                canFull
              />
            </span>
          </td>
          <td>
            <Link
              to={urlPageProjectDetails({ projectId: project.id })}
              className='text-dark hover'
            >
              {project.id}
            </Link>
          </td>
          <td>
            <Link
              to={urlPageProjectDetails({ projectId: project.id })}
              className='text-dark hover'
            >
              {project.title ? (
                <>
                  {project.title}
                </>
              ) : `Project #${project.id}`
              }
            </Link>
          </td>
          {checkRole(this.props.userRoles, [ roles['content-manager'].key, roles.administrator.key, ...groupsRoles.editors.all ]) && (
            <>
              <td>
                <Badge variant='secondary'>{project.options.taps || '---'}</Badge>
              </td>
            </>
          )}

          <td>{lang[`PROJECT.TYPE.${project.type}`]}</td>
          {isColoring && (
            <td>
              {project.options.type && (
                <Badge variant='secondary'>
                  {(lang[`PROJECT.FIELDS.type.${(project.options.type || '').toLowerCase()}`] || '').toUpperCase()}
                </Badge>
              )}
            </td>
          )}
          {canSplit && this.props.categories && (
            <td>
              {checkRole(this.props.userRoles, [ roles['content-manager'].key, roles.administrator.key ]) ? (
                <Badge
                  variant='info'
                  className={'cursor-pointer'}
                  onClick={() => {
                    if (!canChangeCategory) {
                      return false;
                    }
                    this.props.setModalCategories(true);
                    this.props.setProjectId(project.id);
                    this.props.setCategoryId(project.options.category_id);
                    this.props.setUpdatedProject(project);

                    return true;
                  }}
                >
                  {mapCategoryIdToName(this.props.categories, project.options.category_id) || defaultCategoryValue}
                </Badge>
              )
                : <Badge variant='info'>
                  {mapCategoryIdToName(this.props.categories, project.options.category_id) || defaultCategoryValue}
                </Badge>
              }
            </td>
          )}

          <td>
            <Badge variant='info'>
              {formatToPrettyDate(project.started_at) || '---'}
            </Badge>
          </td>
          {!isDashboardProjectItems && (
            <td>
              <Badge variant={getBadgeVariantForDeadline(project.task_deadline_to)}>
                {formatToPrettyDateOrTime(project.task_deadline_to) || '---'}
              </Badge>
            </td>
          )}
          <td>
            <Badge variant={getBadgeVariantForDeadline(project.deadline_to)}>
              {formatToPrettyDateOrTime(project.deadline_to) || '---'}
            </Badge>
          </td>
          <td>
            <Badge variant={getBadgeVariantForDeadline(project.hc_release_date)}>
              {formatToPrettyDateOrTime(project.hc_release_date) || '---'}
            </Badge>
          </td>
          <td>
            <Badge variant='info'>
              {project.hc_content_type?.toUpperCase() || '---'}
            </Badge>
          </td>
          <td>
            <Badge variant={priorityToColor(project.priority)}>
              {lang[`GLOBAL.PRIORITY.${project.priority}`]}
            </Badge>
          </td>
          <td>
            {isSelectedStage ? (
              <Badge variant={statusToColor(status[project.project_step_status])}>
                {(lang[`GLOBAL.STATUS.${status[project.project_step_status]}`] || '') || '---'}
              </Badge>
            ) : (
              <Badge style={{ whiteSpace: 'unset' }} variant={statusToColor(status[project.status])}>
                {(lang[`GLOBAL.STATUS.${status[project.status]}`] || '') || '---'}
              </Badge>
            )}
          </td>
          <td>
            {this.showExecutor(curExecutor)}
          </td>
        </tr>
      </>
    );
  }

  _getExecutor = (executorId) => {
    return this.props.users && executorId ? this.props.users.find((user) => user.id === executorId) : {};
  };
}

ProjectItemTable.defaultProps = {
  isDashboardProjectItems: false,
  showPreviousExecutor: false,
  setModalCategories: () => {},
  setProjectId: () => {},
  setCategoryId: () => {},
};

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    user: store.user.user,
    userRoles: store.user.roles,
    categories: store.categories,
    users: store.users,
  };
};

ProjectItemTable.propTypes = propTypes;

export default connect(mapStoreToProps)(ProjectItemTable);
