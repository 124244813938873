import React from 'react';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';



const TagSystemPictureTagsWidget = ({ tags = [], limit = 5, handleAction, showIcon = true }) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);
  const isActionVisible = tags.length > 0;

  const visibleTags = tags.slice(0, limit);

  const renderTags = () => {
    return visibleTags.map((tag) => {
      return (
        <button key={tag.id} className={'kt-ml-5 btn btn-label-dark-o2 btn-sm kt-mb-5 kt-mt-5'}>
          {tag.name}
        </button>
      );
    });
  };

  return (
    <div className="kt-widget__item flex-grow-0 justify-content-end kt-pr-0">
      {showIcon && <div className="kt-widget__icon">
        <i className="flaticon2-tag" />
      </div>}
      <div className="kt-widget__details">
        <span className="kt-widget__title">
          {`${lang['MENU.TAG_SYSTEM']}: `}
          {renderTags()}
          {isActionVisible && (
            <Button
              variant="info"
              size="sm"
              className="kt-ml-5 kt-mb-5 kt-mt-5 d-inline-flex align-items-center"
              onClick={handleAction}
            >
              <i className='flaticon-more-1 kt-m-0' />
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

TagSystemPictureTagsWidget.propTypes = {
  limit: PropTypes.number,
  tags: PropTypes.array.isRequired,
  handleAction: PropTypes.func,
  showIcon: PropTypes.bool,
};

export default TagSystemPictureTagsWidget;
