import { formatStringToDate } from '../utils/formats';



export const tasksTypes = {
  available: 'available',
  my: 'my',
  all: 'all',
  review: 'review',
  reopened: 'reopened',
  finished: 'finished',
  waiting: 'video-waiting',
  process: 'process',
  status_waiting: 'status_waiting',
  status_process: 'status_process',
  status_draft: 'status_draft',
  status_finished: 'status_finished',
};

export const SET_TASKS = 'SET_TASKS';
export const UPDATE_TASK = 'UPDATE_TASKS';
export const FILTER_TASKS = 'FILTER_TASKS';

export const setTasksAction = (tasks, tasksType) => ({
  type: SET_TASKS,
  payload: tasks,
  tasksType,
});

export const updateTaskAction = (task, categoryId, tasksType) => ({
  type: UPDATE_TASK,
  payload: task,
  categoryId,
  tasksType,
});

export const filterTasksAction = (params, tasksType) => ({
  type: FILTER_TASKS,
  payload: params,
  tasksType,
});

const initialState = {
  available: [],
  my: [],
  all: [],
  review: [],
  reopened: [],
  finished: [],
  waiting: [],
  process: [],
  status_waiting: [],
  status_process: [],
  status_draft: [],
  status_finished: [],
};

export const tasksReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TASKS: {
      const tasks = {
        ...state,
      };

      if (action.tasksType in tasksTypes) {
        tasks[action.tasksType] = action.payload;
      }

      return tasks;
    }
    case UPDATE_TASK: {
      return {
        ...state,
        [action.tasksType]: state[action.tasksType].map((task) => {
          if (task.id === action.payload.id) {
            return {
              ...task,
              category_id: action.categoryId,
            };
          }

          return task;
        }),
      };
    }

    case FILTER_TASKS: {
      const queryParamsToExclude = [ 'show', 'skip', 'sort', 'tags_id', 'isInitialTasks', 'for_executor' ];

      const queryParamsMapper = {
        project: 'project_id',
        executor: 'executor_id',
      };

      let filteredTasks = state[action.tasksType];

      if (Object.keys(action.payload).length) {
        Object.keys(action.payload).filter((param) => !queryParamsToExclude.includes(param)).forEach(
          (param) => {
            switch (param) {
              case 'executor':
                filteredTasks = filteredTasks.filter((task) => {
                  return task.executor_id === action.payload[param];
                });
                break;
              case 'tags': {
                filteredTasks = filteredTasks.filter(
                  (task) => action.payload[param].every((tag) => {
                    const preparedTags = task.tags.map((item) => item.slug).join(',');

                    return preparedTags.includes(tag);
                  })
                );
                break;
              }
              case 'taps': {
                filteredTasks = filteredTasks.filter(
                  (task) => {
                    if (action.payload[param].to === 10000) {
                      return task.taps >= action.payload[param].from;
                    }
                    return action.payload[param].from <= task.taps && task.taps <= action.payload[param].to;
                  }
                );
                break;
              }
              case 'deadline': {
                const from = action.payload[param].from ? formatStringToDate(`${action.payload[param].from} 00:00:00`).valueOf() : '';
                const to = action.payload[param].to ? formatStringToDate(`${action.payload[param].to} 23:59:59`).valueOf() : '';

                if (from && to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.deadline_to).valueOf() >= from && new Date(task.deadline_to).valueOf() <= to)
                  );
                }

                if (from && !to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.deadline_to).valueOf() >= from)
                  );
                }

                if (!from && to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.deadline_to).valueOf() <= to)
                  );
                }
                break;
              }
              case 'project_hc_release_date': {
                const from = action.payload[param].from ? formatStringToDate(`${action.payload[param].from} 00:00:00`).valueOf() : '';
                const to = action.payload[param].to ? formatStringToDate(`${action.payload[param].to} 23:59:59`).valueOf() : '';

                if (from && to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.project_hc_release_date).valueOf() >= from && new Date(task.project_hc_release_date).valueOf() <= to)
                  );
                }

                if (from && !to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.project_hc_release_date).valueOf() >= from)
                  );
                }

                if (!from && to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.project_hc_release_date).valueOf() <= to)
                  );
                }
                break;
              }
              case 'finished_at': {
                const from = action.payload[param].from ? formatStringToDate(`${action.payload[param].from} 00:00:00`).valueOf() : '';
                const to = action.payload[param].to ? formatStringToDate(`${action.payload[param].to} 23:59:59`).valueOf() : '';

                if (from && to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.finished_at).valueOf() >= from && new Date(task.finished_at).valueOf() <= to)
                  );
                }

                if (from && !to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.finished_at).valueOf() >= from)
                  );
                }

                if (!from && to) {
                  filteredTasks = filteredTasks.filter(
                    (task) => (new Date(task.finished_at).valueOf() <= to)
                  );
                }
                break;
              }
              case 'category_id': {
                filteredTasks = filteredTasks.filter(
                  (task) => (action.payload[param] === 'null'
                    ? String(task[param]) === String(action.payload[param])
                    : String(task[param]) === String(action.payload[param]))
                );
                break;
              }
              case 'title': {
                filteredTasks = filteredTasks.filter(
                  (task) => task[param] && task[param].toLowerCase().indexOf(String(action.payload[param]).toLowerCase()) !== -1
                );
                break;
              }
              case 'project': {
                filteredTasks = filteredTasks.filter((task) => {
                  if (Array.isArray(action.payload[param])) {
                    return action.payload[param].includes(String(task.project_id));
                  }
                  return String(action.payload[param]).split(/[ ,\t]+/).includes(String(task.project_id));
                });
                break;
              }
              default: {
                filteredTasks = filteredTasks.filter(
                  (task) => (task[queryParamsMapper[param]] || task[param]) === action.payload[param]
                );
                break;
              }
            }
          }
        );
        initialState[action.tasksType] = filteredTasks;

        return {
          ...initialState,
        };
      }

      return state;
    }
    default: {
      return state;
    }
  }
};
