import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React from 'react';
import Img from '../../../widgets/Img';
import imgURL from '../../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../getPreviewFromProject';
import { priorityToColor } from '../priorities';
import {
  formatToPrettyDate,
  formatToPrettyDateOrTime,
} from '../../../utils/formats';
import { defaultCategoryValue, mapCategoryIdToName } from '../../../requests/categories';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import TagsWidget from '../../../components/Tags/TagsWidget';
import taskTypes from '../../tasks/taskTypes';
import isDisabledProjectContourComplexity from '../helpers/isDisabledProjectContourComplexity';
import isDisabledProjectColorComplexity from '../helpers/isDisabledProjectColorComplexity';
import CategoriesWidget from '../../../components/Categories/CategoriesWidget';
import getTaskByTypeFromProject from '../helpers/getTaskByTypeFromProject';
import { getClassesForDeadline } from '../../../utils/getClassesForDeadline';
import TagSystemPictureTagsWidget from '../../../components/TagSystem/TagSystemPictureTagsWidget';

/**
 * Builds the main block for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { project, group } = this.state;
  const { lang, userRoles } = this.props;
  const {
    uniqTags,
    canSetTags,
    canSeeComplexityLevels,
    projectCategories,
    canSetCategories,
    getArtistDeadline,
    getColoristDeadline,
    canSetProjectDeadline,
    canSetArtistDeadline,
    canSetColoristDeadline,
  } = this.computed;

  let preview = project.preview;

  if (!preview) {
    const file = project.files?.find((item) => {
      return item.status === 1 && !item.link.includes('.eps');
    });

    if (file) {
      preview = file.link;
    }
  }

  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-3">
          <div className="kt-widget__top">
            <div className="kt-widget__media">
              <Img
                src={imgURL(getPreviewSm(preview))}
                current={{
                  id: project.id,
                  link: getPreviewLg(preview),
                }}
                canFull
              />
            </div>
            <div className="kt-widget__content">
              <div className="kt-widget__info row">
                <div className="col-xl-5">
                  <div className="kt-widget__desc">
                    <div className="kt-widget__head">
                      <span className="kt-widget__title no-hover">
                        {project.title ? (
                          <>
                            {`${project.title} `}
                            <span className="text-muted">{`ID:${project.id}`}</span>
                          </>
                        )
                          : `Project #${project.id}`}
                      </span>
                    </div>
                    <div className="kt-widget__subhead">
                      {/* TODO убрать сужность юзера */}
                      {project.manager && (
                        <Link to={urlPageFreelancerDetails({ freelancerId: project.manager.id })}>
                          <i className="flaticon-user" />
                          {project.manager?.username}
                        </Link>
                      )}
                      <div className="kt-widget__label kt-mt-10 font-weight-bold">
                        <span>
                          {project.started_at ? formatToPrettyDate(project.started_at) : '---'}
                        </span>
                      </div>
                    </div>
                    {(project.description) && (
                      <div className="kt-widget__subhead">
                        {project.description}
                      </div>
                    )}
                    {(project.options && project.options.resourceLink) && (
                      <div className="kt-widget__subhead">
                        <a rel="noreferrer" href={project.options.resourceLink} target="_blank" className="kt-nav__link" style={{ color: '#2c77f4' }}>
                          {lang['GLOBAL.RESOURCE_LINK']}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
                <div className="col-xl-7">
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-7">
                          {lang['GLOBAL.PRIORITY']}
                        </span>
                        <div className="kt-widget__label offset-1 col-5 kt-pl-0">
                          <span className={`btn btn-sm disabled kt-opacity-1 btn-label-${priorityToColor(project.priority)} kt-font-bolder bg-transparent`}>
                            {lang[`GLOBAL.PRIORITY.${project.priority || ''}`]}
                          </span>
                        </div>
                      </div>
                      {getTaskByTypeFromProject(project, taskTypes.artist_drawing.type) && (
                        <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['TASK.ARTIST_DEADLINE']}
                          </span>
                          <div className="kt-widget__label offset-1 col-5">
                            {canSetArtistDeadline ? (
                              <Button
                                variant="label-brand"
                                size="sm"
                                className="btn-bold btn-upper"
                                onClick={() => {
                                  if (canSetArtistDeadline) {
                                    this.setState({
                                      showModalEditArtistDeadline: true,
                                    });
                                  }
                                }}
                              >
                                {getArtistDeadline ? formatToPrettyDateOrTime(getArtistDeadline) : '---'}
                              </Button>
                            ) : (
                              <span className={`${getClassesForDeadline(getArtistDeadline)} kt-pl-0`}>
                                {getArtistDeadline ? formatToPrettyDateOrTime(getArtistDeadline) : '---'}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      {getTaskByTypeFromProject(project, taskTypes.designer_coloring.type) && (
                        <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                          <span className="kt-widget__date col-7">
                            {lang['TASK.COLORIST_DEADLINE']}
                          </span>
                          <div className="kt-widget__label offset-1 col-5">
                            {canSetColoristDeadline ? (
                              <Button
                                variant="label-brand"
                                size="sm"
                                className="btn-bold btn-upper"
                                onClick={() => {
                                  if (canSetColoristDeadline) {
                                    this.setState({
                                      showModalEditColoristDeadline: true,
                                    });
                                  }
                                }}
                              >
                                {getColoristDeadline ? formatToPrettyDateOrTime(getColoristDeadline) : '---'}
                              </Button>
                            ) : (
                              <span className={`${getClassesForDeadline(getColoristDeadline)} kt-pl-0`}>
                                {getColoristDeadline ? formatToPrettyDateOrTime(getColoristDeadline) : '---'}
                              </span>
                            )}
                          </div>
                        </div>
                      )}
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-7">
                          {lang['PROJECT.DEADLINE']}
                        </span>
                        <div className="kt-widget__label offset-1 col-5">
                          {canSetProjectDeadline ? (
                            <Button
                              variant="label-brand"
                              size="sm"
                              className="btn-bold btn-upper"
                              onClick={() => {
                                if (canSetProjectDeadline) {
                                  this.setState({
                                    showModalEditProjectDeadline: true,
                                  });
                                }
                              }}
                            >
                              {project.deadline_to ? formatToPrettyDateOrTime(project.deadline_to) : '---'}
                            </Button>
                          ) : (
                            <span className={`${getClassesForDeadline(project.deadline_to)} kt-pl-0`}>
                              {project.deadline_to ? formatToPrettyDateOrTime(project.deadline_to) : '---'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-7">
                          {lang['PROJECT.FIELDS.hc_release_date']}
                        </span>
                        <div className="kt-widget__label offset-1 col-5">
                          {canSetProjectDeadline ? (
                            <Button
                              variant="label-brand"
                              size="sm"
                              className="btn-bold btn-upper"
                              onClick={() => {
                                if (canSetProjectDeadline) {
                                  this.setState({
                                    showModalEditProjectHCReleaseDate: true,
                                  });
                                }
                              }}
                            >
                              {project.hc_release_date ? formatToPrettyDateOrTime(project.hc_release_date) : '---'}
                            </Button>
                          ) : (
                            <span className={`${getClassesForDeadline(project.hc_release_date)} kt-pl-0`}>
                              {project.hc_release_date ? formatToPrettyDateOrTime(project.hc_release_date) : '---'}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-7">
                          {lang['PROJECT.DIFFICULTY']}
                        </span>
                        <div className="kt-widget__label offset-1 col-5 kt-pl-0">
                          <span className="btn disabled kt-opacity-1 kt-font-bolder bg-transparent">
                            {project?.options?.difficult || '---'}
                          </span>
                        </div>
                      </div>

                    </div>
                    <div className="col-sm-6 offset-sm-1">
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-5">
                          {lang['GLOBAL.CATEGORY']}
                        </span>
                        <div className="kt-widget__label col-5">
                          <span className="kt-font-bolder bg-transparent">
                            {project?.options?.category_id ? mapCategoryIdToName(this.props.categories, project.options.category_id) : defaultCategoryValue}
                          </span>
                        </div>
                      </div>
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-5">
                          {lang['PROJECT.FIELDS.type']}
                        </span>
                        <div className="kt-widget__label col-5">
                          <Button
                            variant="label-brand"
                            size="sm"
                            className="btn-bold btn-upper"
                            onClick={() => {
                              this.setState({
                                showModalEditType: true,
                              });
                            }}
                          >
                            {lang[`PROJECT.FIELDS.type.${project.options.type.toLowerCase()}`].toUpperCase()}
                          </Button>
                        </div>
                      </div>
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-5">
                          {lang['PROJECT.FIELDS.hc_content_type']}
                        </span>
                        <div className="kt-widget__label col-5">
                          {canSetProjectDeadline ? (
                            <Button
                              variant="label-brand"
                              size="sm"
                              className="btn-bold btn-upper"
                              onClick={() => {
                                if (canSetProjectDeadline) {
                                  this.setState({
                                    showModalEditProjectHCContentType: true,
                                  });
                                }
                              }}
                            >
                              {project.hc_content_type ? project.hc_content_type : '---'}
                            </Button>
                          ) : (
                            <span className="btn disabled kt-opacity-1 bg-transparent kt-pl-0">
                              {project.hc_content_type ? project.hc_content_type : '---'}
                            </span>
                          )}
                        </div>
                      </div>
                      {canSeeComplexityLevels && project.options?.contour_complexity_level && (<div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-5">
                          {lang['PROJECT.FIELDS.contour_complexity_level']}
                        </span>
                        <div className="kt-widget__label col-5">
                          {!isDisabledProjectContourComplexity(project, taskTypes.artist_drawing.type, userRoles) ? (
                            <Button
                              variant="label-brand"
                              size="sm"
                              className="btn-bold btn-upper"
                              onClick={() => {
                                this.setState({
                                  showModalEditContourComplexityLevel: true,
                                });
                              }}
                            >
                              {project.options?.contour_complexity_level}
                            </Button>
                          ) : (
                            <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent kt-pl-0">
                              {project.options?.contour_complexity_level}
                            </span>
                          )}
                        </div>
                      </div>)}
                      {canSeeComplexityLevels && project.options?.color_complexity_level && (<div className="kt-widget__item d-flex align-items-baseline kt-mb-10 ">
                        <span className="kt-widget__date col-5">
                          {lang['PROJECT.FIELDS.color_complexity_level']}
                        </span>
                        <div className="kt-widget__label col-5">
                          {!isDisabledProjectColorComplexity(project, taskTypes.designer_coloring.type, userRoles) ? (
                            <Button
                              variant="label-brand"
                              size="sm"
                              className="btn-bold btn-upper"
                              onClick={() => {
                                this.setState({
                                  showModalEditColorComplexityLevel: true,
                                });
                              }}
                            >
                              {project.options?.color_complexity_level}
                            </Button>
                          ) : (
                            <span className="btn disabled kt-opacity-1 btn-sm btn-bold btn-upper bg-transparent kt-pl-0">
                              {project.options?.color_complexity_level}
                            </span>
                          )}
                        </div>
                      </div>)}
                      <div className="kt-widget__item d-flex align-items-baseline kt-mb-10">
                        <span className="kt-widget__date col-5">
                          {lang['PROJECT.TAPS']}
                        </span>
                        <div className="kt-widget__label col-5">
                          <span className="btn disabled kt-opacity-1 bg-transparent" style={{ paddingLeft: 0 }}>
                            {project.options.taps || '---'}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="kt-widget__bottom justify-content-between">
            <div className="d-flex w-100 flex-wrap justify-content-between">
              <div className="flex-grow-1 d-flex justify-content-between">
                {group && (
                  <div className="kt-widget__item flex-grow-0">
                    <div className="kt-widget__icon">
                      <i className="flaticon-users-1" />
                    </div>
                    <div className="kt-widget__details">
                      <span className="kt-widget__title">{`${lang['PROJECT.GROUP']}: `}</span>
                      <span className="kt-widget__value">
                        {group.name}
                      </span>
                    </div>
                  </div>
                )}
                <span />
                <div className="d-flex flex-column">
                  <TagSystemPictureTagsWidget
                    handleAction={() => {
                      this._setModalTagSystemPictureTags(true);
                    }}
                    tags={this._uniqTagsFromTagSystemPicture()}
                  />
                  <TagsWidget
                    canSetTags={canSetTags}
                    handleAction={() => {
                      this.setState({
                        showModalTags: true,
                      });
                    }}
                    tags={uniqTags}
                  />
                  <CategoriesWidget
                    handleAction={() => {
                      this.setState({
                        showModalCategories: true,
                      });
                    }}
                    canSetCategories={canSetCategories}
                    categories={projectCategories}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
