import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import urlPageError from '../../urls/urlPageError';
import urlPageFreelancers from '../../urls/urlPageFreelancers';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';
import checkRole from '../../utils/checkRole';
import ListCustomers from './list/ListCustomers';
import Profile from './Profile';
import groupsRoles from './roles/groupsRoles';
import roles from './roles/roles';

/**
 * Component for routing a list of users
 *
 * @returns {*}
 */
const Freelancers = ({ userRoles }) => {
  return (
    <Switch>
      {checkRole(userRoles, [
        roles.administrator.key,
        ...groupsRoles.managers.coloring,
        ...groupsRoles.editors.all,
      ]) && (
        <>
          <Route path={urlPageFreelancerDetails()} component={Profile} />
          <Route path={urlPageFreelancers()} component={ListCustomers} />
        </>
      )}
      <Redirect to={urlPageError()} />
    </Switch>
  );
};


Freelancers.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const mapStateToProps = (store) => ({
  userRoles: store.user.roles,
});

export default connect(mapStateToProps)(Freelancers);
