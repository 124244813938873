import React from 'react';
import Button from 'react-bootstrap/Button';
import Modal from '../../../widgets/Modal';



export default function ArchiveModal () {
  const {
    lang,
  } = this.props;

  return (
    <>
      <Modal
        title={lang['PROJECT.YOU_SURE_ARCHIVE']}
        show={this?.state?.modalArchive}
        close={() => {
          this.setState({
            modalArchive: false,
            disableArchive: false,
          });
        }}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalArchive: false,
                  disableArchive: false,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </Button>
            <Button
              disabled={this.state?.disableArchive}
              variant="warning"
              onClick={async () => {
                this._archiveProject();
              }}
            >
              {lang['PROJECT.ARCHIVE_PROJECT']}
            </Button>
          </>
        )}
      />
    </>
  );
}
