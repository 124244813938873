import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { filter } from 'lodash';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import { useHistory, useParams } from 'react-router-dom';
import InputSelector from '../../widgets/InputSelector';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import roles from '../customers/roles/roles';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../customers/roles/groupsRoles';
import Preload from '../../widgets/Preload';
import urlPageGroupDetails from '../../urls/urlPageGroupDetails';
import actions from '../../store/groups/actions';



const GroupEdit = () => {
  const params = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { lang, loading, users, group } = useSelector(({ language, groups, users }) => ({
    lang: language.lang,
    loading: groups.loading,
    group: groups.group,
    users,
  }), shallowEqual);
  const [ newGroup, setNewGroup ] = useState({
    name: '',
    description: '',
    editor: null,
    artists: [],
    designers: [],
  });
  const [ showInvalid, setShowInvalid ] = useState(false);

  const check = () => {
    return newGroup.name.trim()
      && newGroup.description.trim()
      && !!newGroup.editor
      && newGroup.artists.length > 0
      && newGroup.designers.length > 0;
  };

  useEffect(() => {
    dispatch(actions.fetchGroup(params.id));
  }, []);

  useEffect(() => {
    setNewGroup(group);
  }, [ group ]);

  const updateGroup = async () => {
    if (check()) {
      await dispatch(actions.updateGroup(params.id, newGroup));
      history.push(urlPageGroupDetails({ groupId: params.id }));
    } else {
      setShowInvalid(true);
    }
  };

  const handleInputChange = (event) => {
    const { name: field, value } = event.target;

    setNewGroup((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const handleAddOnInputSelector = (value, field) => {
    setNewGroup((prevState) => ({
      ...prevState,
      [field]: [
        ...prevState[field],
        value,
      ],
    }));
  };

  const handleRemoveOnInputSelector = (value, field) => {
    setNewGroup((prevState) => ({
      ...prevState,
      [field]: filter(prevState[field], (item) => item !== value),
    }));
  };

  return (<>
    {loading ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={lang['GROUP.EDIT']}
          toolbar={
            <Button
              variant='success'
              className="kt-font-bold"
              onClick={updateGroup}
            >
              {lang['GLOBAL.SAVE']}
            </Button>
          }
        />
        <div className="kt-portlet">
          <div className="kt-portlet__body">
            <div className='row'>
              <div className='col-lg-6'>
                <Form.Group>
                  <Form.Label>{lang['GROUP.NAME']}</Form.Label>
                  <Form.Control
                    name="name"
                    type="text"
                    isInvalid={showInvalid && !newGroup.name.trim()}
                    value={newGroup.name}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{lang['GROUP.DESCRIPTION']}</Form.Label>
                  <Form.Control
                    as='textarea'
                    type="text"
                    name="description"
                    isInvalid={showInvalid && !newGroup.description.trim()}
                    value={newGroup.description}
                    onChange={handleInputChange}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className='col-lg-6'>
                <Form.Group>
                  <Form.Label>{lang['GROUP.EDITOR']}</Form.Label>
                  <Form.Control
                    as='select'
                    name="editor"
                    isInvalid={showInvalid && !newGroup.editor}
                    value={newGroup.editor || lang['GLOBAL.CHOOSE']}
                    onChange={handleInputChange}
                  >
                    {!newGroup.editor
                      && <option disabled>
                        {lang['GLOBAL.CHOOSE']}
                      </option>}
                    {users.filter((user) => {
                      return checkRole(user.roles, groupsRoles.editors.all);
                    }).map((editor) => (
                      <option
                        key={editor.id}
                        value={editor.id}
                      >
                        {`${editor.username} (${editor.inactive ? lang['CUSTOMER.LOCKED'].toLowerCase()
                          : lang['CUSTOMER.ACTIVE'].toLowerCase()})`}
                      </option>))}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                  <Form.Text type="text-muted">
                    {lang['GROUP.EDITOR_CHOOSE']}
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{lang['GROUP.ARTIST']}</Form.Label>
                  <InputSelector
                    list={users
                      .filter((user) => {
                        return checkRole(user.roles, [ roles['coloring-artist'].key ]);
                      })
                      .map((artist) => ({
                        value: artist.id,
                        title: `${artist.username} (${artist.inactive ? lang['CUSTOMER.LOCKED'].toLowerCase()
                          : lang['CUSTOMER.ACTIVE'].toLowerCase()})`,
                      }))}
                    isInvalid={showInvalid && newGroup.artists.length === 0}
                    cur={newGroup.artists}
                    add={(artist) => handleAddOnInputSelector(artist, 'artists')}
                    remove={(artist) => handleRemoveOnInputSelector(artist, 'artists')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                  <Form.Text type="text-muted">
                    {lang['GROUP.ARTIST_CHOOSE']}
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>{lang['GROUP.DESIGNER']}</Form.Label>
                  <InputSelector
                    list={users
                      .filter((user) => {
                        return checkRole(user.roles, [ roles['coloring-designer'].key ]);
                      })
                      .map((designer) => ({
                        value: designer.id,
                        title: `${designer.username} (${designer.inactive ? lang['CUSTOMER.LOCKED'].toLowerCase() : lang['CUSTOMER.ACTIVE'].toLowerCase()})`,
                      }))}
                    isInvalid={showInvalid && newGroup.designers.length === 0}
                    cur={newGroup.designers}
                    add={(designer) => handleAddOnInputSelector(designer, 'designers')}
                    remove={(designer) => handleRemoveOnInputSelector(designer, 'designers')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                  <Form.Text type="text-muted">
                    {lang['GROUP.DESIGNER_CHOOSE']}
                  </Form.Text>
                </Form.Group>
              </div>
            </div>
          </div>
        </div>
      </>)
    }
  </>);
};

export default GroupEdit;
