import React, { useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';



const TagsTableHeader = ({ sort, handleSort, currentEntity }) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const headerItems = useMemo(() => [
    {
      id: 1,
      name: 'id',
      translation: lang['GLOBAL.ID'],
      width: '3%',
      sortable: true,
    },
    {
      id: 2,
      name: 'name',
      translation: lang['GLOBAL.NAME'],
      width: '10%',
      sortable: true,
    },
    {
      id: 3,
      name: 'parent',
      translation: lang['TAG_SYSTEM.TAGS.PARENT'],
      width: '10%',
      sortable: false,
    },
    {
      id: 4,
      name: 'children',
      translation: lang['TAG_SYSTEM.TAGS.CHILDREN'],
      width: '10%',
    },
  ], [ lang, currentEntity.isParent ]);

  return (
    <thead>
      <tr>
        {
          headerItems.map((item, index) => {
            return (
              <th
                key={item.id}
                style={{ width: item.width, cursor: item.sortable ? 'pointer' : 'default' }}
                onClick={() => handleSort('orderBy', item.name)}
              >
                <div
                  className={index === 0 ? 'd-flex justify-content-center' : 'd-flex justify-content-start'}
                >
                  <div className={'d-flex text-center justify-content-start mr-3'}>
                    <span>{item.translation}</span>
                  </div>
                  {
                    item.sortable && (
                      sort?.sortedBy !== '' && sort?.orderBy === item.name ? (
                        sort?.sortedBy === 'asc' ? <i className='flaticon2-up' /> : <i className='flaticon2-down' />
                      ) : <i className='flaticon2-sort' />
                    )
                  }
                </div>
              </th>
            );
          }).filter((item) => item)
        }
        <th
          key={5}
          className={'text-right'}
          style={{ width: '6%' }}
        >
          <div
            className={'d-flex justify-content-center'}
          >
            <span>{lang['GLOBAL.ACTIONS']}</span>
          </div>
        </th>
      </tr>
    </thead>
  );
};


TagsTableHeader.propTypes = {
  sort: PropTypes.shape({
    sortedBy: PropTypes.string,
    orderBy: PropTypes.string,
  }).isRequired,
  handleSort: PropTypes.func.isRequired,
  currentEntity: PropTypes.object,
};
export default TagsTableHeader;
