import store from '../store/store';



export const isGroupExist = (id) => {
  const { groups: { groups } } = store.getState();

  return !!(groups && groups.find((group) => group.id === id));
};

export default isGroupExist;
