import { connect } from 'react-redux';
import React from 'react';
import PropTypes from 'prop-types';



const propTypes = {
  lang: PropTypes.object.isRequired,
  setSort: PropTypes.func,
  sort: PropTypes.any,
};

/**
 * Component for table header with user list
 *
 * @returns {*}
 */
const ListCustomersTableHeader = ({ lang, sort, setSort }) => {
  const _setSort = (key) => {
    if (setSort) {
      const newSort = {};

      if (!sort[key]) {
        newSort[key] = 'desc';
      } else if (sort[key] === 'desc') {
        newSort[key] = 'asc';
      } else if (sort[key] === 'asc') {
        newSort[key] = '';
      }

      setSort(newSort);
    }
  };

  return (
    <thead>
      <tr>
        <th />
        <th
          onClick={() => {
            _setSort('username');
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{lang['CUSTOMER.USERNAME']}</span>
          {sort.username ? (sort.username === 'asc' ? (
            <i className='flaticon2-up' />
          ) : (
            <i className='flaticon2-down' />
          )) : (
            <i className='flaticon2-sort' />
          )}
        </th>
        <th>{lang.ROLE}</th>
        <th
          onClick={() => {
            _setSort('rates');
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{lang['CUSTOMER.RATES']}</span>
          {sort.rates ? (sort.rates === 'asc' ? (
            <i className='flaticon2-up' />
          ) : (
            <i className='flaticon2-down' />
          )) : (
            <i className='flaticon2-sort' />
          )}
        </th>
        <th
          onClick={() => {
            _setSort('draft');
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{lang['GLOBAL.STATUS.draft']}</span>
          {sort.draft ? (sort.draft === 'asc' ? (
            <i className='flaticon2-up' />
          ) : (
            <i className='flaticon2-down' />
          )) : (
            <i className='flaticon2-sort' />
          )}
        </th>
        <th
          onClick={() => {
            _setSort('backlog');
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{lang['GLOBAL.STATUS.in_line']}</span>
          {sort.backlog ? (sort.backlog === 'asc' ? (
            <i className='flaticon2-up' />
          ) : (
            <i className='flaticon2-down' />
          )) : (
            <i className='flaticon2-sort' />
          )}
        </th>
        <th
          onClick={() => {
            _setSort('in_work');
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{lang['GLOBAL.STATUS.in_work']}</span>
          {sort.in_work ? (sort.in_work === 'asc' ? (
            <i className='flaticon2-up' />
          ) : (
            <i className='flaticon2-down' />
          )) : (
            <i className='flaticon2-sort' />
          )}
        </th>
        <th
          onClick={() => {
            _setSort('done');
          }}
          style={{ cursor: 'pointer' }}
        >
          <span>{lang['GLOBAL.STATUS.finished']}</span>
          {sort.done ? (sort.done === 'asc' ? (
            <i className='flaticon2-up' />
          ) : (
            <i className='flaticon2-down' />
          )) : (
            <i className='flaticon2-sort' />
          )}
        </th>
        <th />
      </tr>
    </thead>
  );
};


ListCustomersTableHeader.defaultProps = {
  setSort: ((sort) => sort),
  sort: {},
};
const mapStoreToProps = (store) => ({
  lang: store.language.lang,
});

ListCustomersTableHeader.propTypes = propTypes;

export default connect(mapStoreToProps)(ListCustomersTableHeader);
