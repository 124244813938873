import React from 'react';
import PropTypes from 'prop-types';



const StatNumber = ({ children, color, prefix }) => {
  return (
    <div
      className="kt-widget__value"
      style={{
        fontSize: '2.5em',
        display: 'block',
        marginTop: '1em',
        color: color || 'inherit',
      }}
    >
      {prefix && <span>{prefix}</span>}
      {children}
    </div>
  );
};

StatNumber.propTypes = {
  color: PropTypes.any,
  children: PropTypes.any,
  prefix: PropTypes.string,
};

export default StatNumber;
