import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Modal from '../../../widgets/Modal';



const DeleteCommentConfirmationModal = ({ toggleModal, onModalConfirm, onModalCancel }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <Modal
      title={lang['PROJECT.COMMENT_CONFIRM_TO_DELETE']}
      show={toggleModal}
      close={() => {
        onModalCancel();
      }}
      footer={
        <>
          <button
            className="btn btn-secondary"
            onClick={() => {
              onModalCancel();
            }}
          >{lang['GLOBAL.CANCEL']}</button>
          <button
            className="btn btn-success"
            onClick={() => {
              onModalConfirm();
            }}
          >{lang['GLOBAL.APPLY']}</button>
        </>
      }
    />
  );
};

DeleteCommentConfirmationModal.propTypes = {
  onModalConfirm: PropTypes.func.isRequired,
  onModalCancel: PropTypes.func.isRequired,
  toggleModal: PropTypes.bool.isRequired,
};

export default DeleteCommentConfirmationModal;
