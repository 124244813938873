import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { selectors } from '../../ducks/builder';
import { toAbsoluteUrl } from '../../../app/utils/gettersUrl';
import urlPageDashboard from '../../../app/urls/urlPageDashboard';
import {isFabros} from "../../../app/utils/isFabros";
import config from '../../../config/app';



class Brand extends React.Component {
  getLogoPath() {
    let logo = '/media/logo.png';

    if (config.env === 'dev') {
      if (!isFabros) {
        logo = '/media/logo_bee.webp';
      }
    }

    return logo;
  }

  render () {
    return (
      <div
        className={`kt-aside__brand kt-grid__item ${this.props.brandClasses}`}
        id="kt_aside_brand"
      >
        <div className="kt-aside__brand-logo">
          <Link to={urlPageDashboard()}>
            <img alt="logo" src={toAbsoluteUrl(this.getLogoPath())} />
          </Link>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (store) => {
  return {
    brandClasses: selectors.getClasses(store, {
      path: 'brand',
      toString: true,
    }),
  };
};

export default connect(mapStateToProps)(Brand);
