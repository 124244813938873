import PropTypes from 'prop-types';
import MyComponent from '../../../utils/MyComponent';
import { getUser } from '../../../requests/users';
import getStatus from '../getStatus';



class TaskItem extends MyComponent {
  static propTypes = {
    task: PropTypes.shape({
      prev_executor: PropTypes.number,
      executor_id: PropTypes.number,
    }),
    isChecked: PropTypes.bool,
  };

  static defaultProps = {
    showExecutor: true,
    task: {},
  };

  state = {
    executor: null,
    prevExecutor: null,
  };

  computed = {
    status: (state, { task, showStatus }) => showStatus ? getStatus(task) : null,
  };

  componentDidMount () {
    if (this.props.task.executor_id) {
      this._getExecutor();
    }
    if (this.props.isChecked && this.props.task.prev_executor) {
      this._getPrevExecutor();
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.task.executor_id !== this.props.task.executor_id) {
      this._getExecutor();
    }

    if (this.props.isChecked && prevProps.task.prev_executor !== this.props.task.prev_executor) {
      this._getPrevExecutor();
    }
  }

  _getExecutor = () => {
    this.setState({
      executor: getUser(this.props.task.executor_id),
    });
  };

  _getPrevExecutor = () => {
    this.setState({
      prevExecutor: getUser(this.props.task.prev_executor),
    });
  };
}

export default TaskItem;
