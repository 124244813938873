import { actionsNotifications } from '../store/notification';
import { NOTIFICATIONS_TYPES } from '../widgets/Notification';
import { approveTesterAction, updateTesterTimeAction } from '../store/bonuses';
import { prevMonthFirstDate, prevMonthLastDate } from '../../const/date';
import axiosApiInstance from './utils/api';



export const updateTesterTrackingTime = (taskId, time) => {
  return async (dispatch, getState) => {
    const successStatus = 200;
    const URL = `tasks/${taskId}/tester`;
    const store = getState();

    try {
      const response = await axiosApiInstance.post(URL, {
        time,
      });

      if (response.status === successStatus) {
        dispatch(updateTesterTimeAction({ taskId, time }));
        dispatch(actionsNotifications.add(store.language.lang['TASK.SUCCESS_UPDATE_TRACKING_TIME'], NOTIFICATIONS_TYPES.success));
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(store.language.lang['TASK.ERROR_UPDATE_TRACKING_TIME'], NOTIFICATIONS_TYPES.error));
        // eslint-disable-next-line no-console
        console.error(error.response.data);
      }
    }
  };
};

export const approveTester = (id) => {
  return async (dispatch, getState) => {
    const successStatus = 200;
    const URL = `tester/payment/${id}`;
    const store = getState();

    try {
      const response = await axiosApiInstance.post(URL, {
        from: prevMonthFirstDate,
        to: prevMonthLastDate,
      });

      if (response.status === successStatus) {
        dispatch(approveTesterAction(id));
        dispatch(actionsNotifications.add(store.language.lang['TASK.SUCCESS_APPROVE_TESTER'], NOTIFICATIONS_TYPES.success));
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(store.language.lang['TASK.ERROR_APPROVE_TESTER'], NOTIFICATIONS_TYPES.error));
        // eslint-disable-next-line no-console
        console.error(error.response.data);
      }
    }
  };
};
