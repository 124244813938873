import React from 'react';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SPECIAL_TAGS } from '../../../const/project';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../../pages/customers/roles/groupsRoles';



const TagsWidget = ({ tags = [], limit = 5, canSetTags, showAction, handleAction, showIcon = true }) => {
  const { lang, userRoles } = useSelector(({ language, user }) => ({
    lang: language.lang,
    userRoles: user.roles,
  }), shallowEqual);

  const showWidget = tags.length > 0 || canSetTags;
  const isActionVisible = typeof showAction === 'undefined' ? showWidget : showAction;

  if (!showWidget) {
    return null;
  }

  const visibleTags = tags.sort((firstTag, secondTag) => {
    firstTag = SPECIAL_TAGS.includes(firstTag.name) ? 1 : 0;
    secondTag = SPECIAL_TAGS.includes(secondTag.name) ? 1 : 0;

    return secondTag - firstTag;
  }).slice(0, limit);

  const renderTags = () => {
    return visibleTags.map((tag) => {
      let tagClass = 'btn-label-dark-o2';

      if (
        SPECIAL_TAGS.includes(tag.name) &&
        checkRole(userRoles, [
          ...groupsRoles.admin,
          ...groupsRoles.managers.all,
          ...groupsRoles.editors.all,
          ...groupsRoles.testers.all ])
      ) {
        tagClass = 'btn-danger';
      }

      return (
        <button key={tag.id} className={`kt-ml-5 btn ${tagClass} btn-sm kt-mb-5 kt-mt-5`}>
          {tag.name}
        </button>
      );
    });
  };

  return (
    <div className="kt-widget__item flex-grow-0 kt-pr-0 justify-content-end">
      {showIcon && <div className="kt-widget__icon">
        <i className="flaticon2-list-3" />
      </div>}
      <div className="kt-widget__details">
        <span className="kt-widget__title">
          {`${lang['MENU.TAGS']}: `}
          {renderTags()}
          {isActionVisible && (
            <Button
              variant="info"
              size="sm"
              className="kt-ml-5 kt-mb-5 kt-mt-5 d-inline-flex align-items-center"
              onClick={handleAction}
            >
              <i className='flaticon-more-1 kt-m-0' />
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

TagsWidget.propTypes = {
  limit: PropTypes.number,
  tags: PropTypes.array.isRequired,
  canSetTags: PropTypes.bool.isRequired,
  handleAction: PropTypes.func,
  showAction: PropTypes.bool,
  showIcon: PropTypes.bool,
};

export default TagsWidget;
