import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Badge } from 'react-bootstrap';
import { uniq } from 'lodash';
import imgURL from '../../../utils/imgURL';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../utils/formats';
import statusToColor, { status } from '../../../utils/statusToColor';
import { priorityToColor } from '../../projects/priorities';
import Img from '../../../widgets/Img';
import { getPreviewLg, getPreviewSm } from '../../projects/getPreviewFromProject';
import urlPageProfile from '../../../urls/urlPageProfile';
import urlPageProjectDetails from '../../../urls/urlPageProjectDetails';
import urlPageTaskDetails from '../../../urls/urlPageTaskDetails';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import TagsWidget from '../../../components/Tags/TagsWidget';
import { isSameDomain } from '../../../utils/isSameDomain';
import calculateTaskPriceByUserRate from '../helpers/calculateTaskPriceByUserRate';
import TaskItem from './TaskItem';

/**
 * Task card component for the task list
 */
class TaskItemCard extends TaskItem {
  render () {
    const { executor, prevExecutor } = this.state;
    const {
      task, lang, toProject, showStatus, isChecked, showPrevExecutor, user,
    } = this.props;
    const { status: _status } = this.computed;

    this.getTaskPrice = () => {
      if (task.status === status.waiting && !user.is_employee) {
        return calculateTaskPriceByUserRate(task, user, task.project_options);
      }
      return task.price;
    };

    return (
      <div className="col-xl-4 col-lg-6 col-12">
        <div
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className={`kt-portlet kt-portlet--height-fluid project ${this.props.border ? 'border' : ''} ${this.props.selected ? 'project--select' : ''}`}
          onClick={() => {
            if (this.props.onSelect) {
              this.props.onSelect(this.props.task);
            }
          }}
        >
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-widget kt-widget--project-1">
              <div className="kt-widget__head kt-pt-10 kt-pb-10 align-items-start flex-nowrap">
                <div className="kt-widget__label">
                  <div className="kt-widget__media">
                    <span className="kt-media kt-media--xxxl">
                      <Img
                        src={imgURL(getPreviewSm(task.preview || task.links))}
                        current={{
                          id: task.id,
                          link: getPreviewLg(task.preview || task.links),
                        }}
                        canFull
                        files={this.props.listPreview}
                      />
                    </span>
                  </div>
                  <div
                    className="kt-widget__info kt-mr-5 d-flex flex-wrap align-items-center kt-pt-0"
                  >
                    <Link
                      className="kt-widget__title"
                      to={toProject ?
                        urlPageProjectDetails({ projectId: task.project_id }) :
                        urlPageTaskDetails({ taskId: task.id })
                      }
                    >
                      {task.title ? (
                        <>
                          {`${task.title} `}
                          <span className="text-muted">{`ID:${task.project_id}`}</span>
                        </>
                      ) : `Task #${task.project_id}`}
                    </Link>
                    <span className="kt-widget__desc font-weight-bold w-100">
                      {lang[`TASK.TYPES.${task.type}`]}
                    </span>
                  </div>
                </div>
                {task.image_type && (
                  <Badge variant="dark" className="kt-font-bold">
                    {(task.image_type || '').toUpperCase()}
                  </Badge>
                )}
              </div>

              <div className="kt-widget__body kt-pb-10 d-flex flex-column">
                <span
                  className="kt-widget__text kt-mt-0 kt-pt-0 flex-grow-1 flex-shrink-0"
                  style={{ overflowWrap: 'break-word' }}
                >
                  {(task.description === 'null' || !task.description) ? '' : task.description}
                </span>

                <hr className="w-100" />

                <div className="kt-widget__stats container-fluid d-block">
                  <div className="row">
                    {isChecked && showPrevExecutor && prevExecutor && (
                      <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                        <span className="kt-widget__date kt-p-0 font-weight-bold">
                          {lang.GLOBAL.PREV_EXECUTOR}
                        </span>
                        <div className="kt-widget__label">
                          <Badge
                            variant="secondary"
                            as={isSameDomain(this.props.user.provider_id, prevExecutor.provider_id) ? Link : 'span'}
                            to={task.prev_executor === this.props.userId ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: task.prev_executor })}
                          >
                            {prevExecutor.username}
                          </Badge>
                        </div>
                      </div>
                    )}
                    {showStatus && (
                      <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                        <span className="kt-widget__date kt-p-0 font-weight-bold">
                          {lang['GLOBAL.STATUS']}
                        </span>
                        <div className="kt-widget__label">
                          <Badge variant={statusToColor(status[_status])}>
                            {(lang[`GLOBAL.STATUS.${status[_status]}`] || '').toLowerCase() || ''}
                          </Badge>
                        </div>
                      </div>
                    )}
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.PRIORITY']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant={priorityToColor(task.priority)}>
                          {lang[`GLOBAL.PRIORITY.${task.priority}`]}
                        </Badge>
                      </div>
                    </div>
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.BEGIN']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant="info">
                          {formatToPrettyDate(task.started_at) || '---'}
                        </Badge>
                      </div>
                    </div>
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.DEADLINE']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant="danger">
                          {formatToPrettyDateOrTime(task.deadline_to) || '---'}
                        </Badge>
                      </div>
                    </div>
                    {this.props.showProjectDeadline && (
                      <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                        <span className="kt-widget__date kt-p-0 font-weight-bold">
                          {lang['PROJECT.DEADLINE']}
                        </span>
                        <div className="kt-widget__label">
                          <Badge variant="danger">
                            {formatToPrettyDateOrTime(task.project_deadline_to) || '---'}
                          </Badge>
                        </div>
                      </div>
                    )}
                    <hr className="col-12 kt-ml-0 kt-mr-0 kt-p-0 w-100" />

                    <TagsWidget
                      canSetTags={false}
                      showAction={false}
                      showIcon={false}
                      tags={uniq(task.tags || [])}
                    />
                  </div>
                </div>
              </div>

              <div className="kt-widget__footer">
                <div
                  className="kt-widget__wrapper kt-pt-10 kt-pb-10 d-flex justify-content-between flex-nowrap"
                  style={{ minHeight: 42 }}
                >
                  <div className="kt-widget__section flex-grow-1 flex-shrink-0">
                    <div className="kt-widget__blog kt-p-0">
                      <span className="kt-widget__value kt-p-0">
                        {`${lang['TASK.PRICE']}: ${this.getTaskPrice()}€`}
                      </span>
                    </div>
                  </div>

                  <div className="kt-widget__section kt-pr-0">
                    <div className="kt-widget__blog d-flex justify-content-end kt-pl-0">
                      {(task.status === status.draft || task.status === status.waiting) && task.executor_id === this.props.userId && (
                        <Badge variant="success">
                          {lang['TASK.FOR_YOU']}
                        </Badge>
                      )}
                      {(task.status === status.draft || task.status === status.waiting || task.status === status.process || task.status === status.finished)
                       && task.executor_id !== this.props.userId
                       && executor
                       && this.props.showExecutor
                       && (
                         <div className="kt-list-pics kt-list-pics--sm kt-pl-0 d-flex justify-content-end flex-wrap">
                           {isSameDomain(this.props.user.provider_id, executor.provider_id) ? (
                             <Link
                               to={task.executor_id === this.props.userId ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: task.executor_id })}
                               className="font-weight-bolder kt-font-md text-dark hover"
                             >
                               {executor.username}
                               <Img
                                 className="kt-ml-5"
                                 src={imgURL(executor.avatar)}
                                 alt={executor.username}
                               />
                             </Link>) : <span>{executor.username}</span>}
                         </div>
                       )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  userId: store.user.user.id,
  user: store.user.user,
});

export default connect(mapStoreToProps)(TaskItemCard);
