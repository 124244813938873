import React from 'react';
import PropTypes from 'prop-types';

/**
 * Component for switcher
 *
 * @returns {*}
 */
const Switch = ({ checked, onChange }) => {
  return (
    <span className="kt-switch kt-switch--outline kt-switch--icon kt-switch--success">
      <input
        required=""
        type="checkbox"
        checked={checked}
        onChange={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
      />
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <span
        onClick={() => {
          if (onChange) {
            onChange(!checked);
          }
        }}
      />
    </span>
  );
};

Switch.propTypes = {
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Switch;
