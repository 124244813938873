import React, { useEffect, useRef, useState, forwardRef } from 'react';
import { Form } from 'react-bootstrap';
// eslint-disable-next-line id-length
import $ from 'jquery';
import '../../libs/air-datepicker/dist/js/datepicker.js';
import '../../libs/air-datepicker/dist/css/datepicker.css';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import useForceUpdate from '../hooks/useForceUpdate';



const propTypes = {
  position: PropTypes.string,
  placeholder: PropTypes.string,
  isInvalid: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.string,
  minMinutes: PropTypes.number,
  maxMinutes: PropTypes.number,
  onChange: PropTypes.func,
  range: PropTypes.bool,
  selected: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.string,
  ]),
  value: PropTypes.oneOfType([
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.string,
  ]),
  curLang: PropTypes.any,
  todayButton: PropTypes.bool,
};

let picker = null;

/**
 * Component for datepicker
 */
// eslint-disable-next-line react/display-name
export const DatePickerForwardRef = forwardRef((props, ref) => {
  const { curLang } = useSelector(({ language }) => ({
    curLang: language.cur,
  }), shallowEqual);
  const innerRef = useRef();
  const refInput = ref?.current || innerRef;
  const [ canEvent, setCanEvent ] = useState(true);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    setCanEvent(false);
    picker = $(refInput.current).datepicker({
      todayButton: !!props.todayButton,
      dateFormat: 'dd.mm.yyyy',
      dateTimeSeparator: ', ',
      multipleDatesSeparator: ' - ',
      timeFormat: 'hh:ii',
      language: localStorage.getItem('lang') || 'en',
      classes: `${
        (
          (props.minMinutes || 0)
            >= (props.maxMinutes || props.maxMinutes === 0 ? props.maxMinutes : 59)
            ? 'datepicker--hidden-minutes'
            : ''
        )
      }`,
      onSelect: (format, date) => {
        if (canEvent && props.onChange) {
          props.onChange(date);
        }
      },
      ...props,
    }).data('datepicker');

    picker?.selectDate(props.selected);
    setCanEvent(true);
  }, []);

  //ugly hack for rerendering of component for fixing issue on ProjectCreate page
  useEffect(() => {
    forceUpdate();
  }, []);

  useEffect(() => {
    if (props.range ? props.selected.length > 1 : true) {
      picker?.selectDate(props.selected);
    }

  }, [ curLang ]);

  return (React.createElement(Form.Control, {
    ref: refInput,
    'data-position': props.position || 'top left',
    readOnly: 'readonly',
    placeholder: props.placeholder,
    value: props.value ? DateTime.fromJSDate(new Date(Date.parse(props.value))).toFormat('dd.MM.yyyy') : props.selected,
    isInvalid: props.isInvalid,
    disabled: props.disabled,
    size: props.size,
  })
  );
});

DatePickerForwardRef.propTypes = propTypes;

class DatePicker extends React.Component {
  refInput = React.createRef();

  canEvent = true;

  render () {
    return (React.createElement(Form.Control, {
      ref: this.refInput,
      'data-position': this.props.position || 'top left',
      readOnly: 'readonly',
      value: this.props.selected || '',
      placeholder: this.props.placeholder,
      isInvalid: this.props.isInvalid,
      disabled: this.props.disabled,
      size: this.props.size,
    })
    );
  }

  componentDidMount () {
    this.canEvent = false;
    this.picker = $(this.refInput.current).datepicker({
      autoClose: true,
      todayButton: !!this.props.todayButton,
      dateFormat: 'dd.mm.yyyy',
      dateTimeSeparator: ', ',
      multipleDatesSeparator: ' - ',
      timeFormat: 'hh:ii',
      language: this.props.curLang,
      classes: `${
        (
          (this.props.minMinutes || 0)
          >= (this.props.maxMinutes || this.props.maxMinutes === 0 ? this.props.maxMinutes : 59)
            ? 'datepicker--hidden-minutes'
            : ''
        )
      }`,
      onSelect: (format, date) => {
        if (this.canEvent && this.props.onChange) {
          this.props.onChange(date);
        }
      },
      ...this.props,
    }).data('datepicker');
    this.picker.selectDate(this.props.selected);
    this.canEvent = true;
  }

  componentDidUpdate (prevProps) {
    this.canEvent = false;
    if (this.props.range ? this.props.selected.length > 1 : true) {
      this.picker.selectDate(this.props.selected);
    }
    if (this.props.curLang !== prevProps.curLang) {
      this.picker.update('language', this.props.curLang);
    }
    this.canEvent = true;
  }
}

DatePicker.propTypes = propTypes;

export default DatePicker;
