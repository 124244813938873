import React from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import MySelect from '../../../widgets/MySelect';
import getCountriesOptionsList from '../../../utils/getCountriesOptionsList';
import { isBYPersonalData, isRU, isSwiftRU, isTransitIban } from '../../../utils/checker';
import { isFabros, isXFLOW } from '../../../utils/isFabros';
import getCountryCodeOptionsList from '../../../utils/getCountryCodeOptionsList';
import { getLabelText } from '../../../utils/getLabelText';



const FinancialInformationTab = ({ step, control, errors, formValues }) => {
  const { lang, countries } = useSelector(({ language, countries }) => ({
    lang: language.lang,
    countries: countries.countries,
  }), shallowEqual);

  const currentStep = 3;

  return (
    <>
      <div
        className="kt-wizard-v4__content"
        data-ktwizard-type="step-content"
        data-ktwizard-state={step === currentStep && 'current'}
      >
        <div className="kt-heading kt-heading--md">
          {lang['REGISTRATION.ENTER_FINANCE_INFORMATION']}
        </div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v4__form">
            <div className="row" data-tour="fi-finance-data">

              <Form.Group className="col-xl-4 kt-mb-15" data-tour="fi-country">
                <Form.Label>
                  {lang['REGISTRATION.COUNTRY']}
                </Form.Label>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <MySelect
                      {...field}
                      autoComplete="off"
                      isClearable
                      isInvalid={!!errors.country}
                      options={getCountriesOptionsList(countries)}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.COUNTRY_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.COUNTRY_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-xl-4 kt-mb-15" data-tour="fi-city">
                <Form.Label>{lang['REGISTRATION.CITY']}</Form.Label>
                <Controller
                  name="city"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.city}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.CITY_PLEASE_RUSSIAN'],
                    general: lang['REGISTRATION.CITY_PLEASE_GENERAL'],
                    latin: lang['REGISTRATION.CITY_PLEASE_LATIN'],
                  })}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.CITY_ERROR_RU'],
                    general: lang['REGISTRATION.CITY_ERROR_GENERAL'],
                    latin: lang['REGISTRATION.CITY_ERROR_LATIN'],
                  })}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-xl-4 kt-mb-15" data-tour="fi-address">
                <Form.Label>{lang['REGISTRATION.ADDRESS']}</Form.Label>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={!!errors.address}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.ADDRESS_PLEASE_RUSSIAN'],
                    general: lang['REGISTRATION.ADDRESS_PLEASE_GENERAL'],
                    latin: isXFLOW ? lang['REGISTRATION.ADDRESS_PLEASE_LATIN_XFLOW'] : lang['REGISTRATION.ADDRESS_PLEASE_LATIN'],
                  })} {isFabros ? lang['REGISTRATION.ADDRESS_MAX_CHARACTERS'] : ''}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.ADDRESS_ERROR_RU'],
                    general: lang['REGISTRATION.ADDRESS_ERROR_GENERAL'],
                    latin: lang['REGISTRATION.ADDRESS_ERROR_LATIN'],
                  })}
                  <br />
                  {isFabros ? lang['REGISTRATION.ADDRESS_ERROR_MAX_CHARACTERS'] : ''}
                </Form.Control.Feedback>
              </Form.Group>
              {isFabros && isRU(formValues) && <>
                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.INN']}</Form.Label>
                  <Controller
                    name="inn"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.inn}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.INN_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.INN_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              </>}

              {isXFLOW && <>
                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.POST_CODE']}</Form.Label>
                  <Controller
                    name="post_code"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.post_code}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.POST_CODE_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.POST_CODE_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.TIN_COUNTRY']}</Form.Label>
                  <Controller
                    name="tin_country"
                    control={control}
                    render={({ field }) => (
                      <MySelect
                        {...field}
                        autoComplete="off"
                        isClearable
                        isInvalid={!!errors.tin_country}
                        options={getCountryCodeOptionsList(countries)}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.TIN_COUNTRY_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.TIN_COUNTRY_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.TIN_NUMBER']}</Form.Label>
                  <Controller
                    name="tin_number"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.tin_number}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.TIN_NUMBER_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.TIN_NUMBER_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              </>}

              {isXFLOW && <>
                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.BANK_NAME']}</Form.Label>
                  <Controller
                    name="bank"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.bank}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.BANK_NAME_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.BANK_NAME_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.BANK_COUNTRY']}</Form.Label>
                  <Controller
                    name="bank_country"
                    control={control}
                    render={({ field }) => (
                      <MySelect
                        {...field}
                        autoComplete="off"
                        isClearable
                        isInvalid={!!errors.bank_country}
                        options={getCountriesOptionsList(countries)}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.BANK_COUNTRY_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.BANK_COUNTRY_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>{lang['REGISTRATION.BANK_ACCOUNT_NAME']}</Form.Label>
                  <Controller
                    name="bank_account_name"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.bank_account_name}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.BANK_ACCOUNT_NAME_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.BANK_ACCOUNT_NAME_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              </>}

              <Form.Group className="col-xl-4 kt-mb-15">
                <Form.Label>{lang['REGISTRATION.ACCOUNT_NUMBER']}</Form.Label>
                <Controller
                  name="iban"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={!!errors.iban}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.ACCOUNT_NUMBER_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.ACCOUNT_NUMBER_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-xl-4 kt-mb-15">
                <Form.Label>
                  {lang['REGISTRATION.SWIFT']}
                </Form.Label>
                <Controller
                  name="swift"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      type="text"
                      isInvalid={!!errors.swift}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.SWIFT_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.SWIFT_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              {isSwiftRU(formValues) && (
                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>
                    {lang['REGISTRATION.BIC']}
                  </Form.Label>
                  <Controller
                    name="bic_number"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.bic_number}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.BIC_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.BIC_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {isTransitIban(formValues) && (
                <Form.Group className="col-xl-4 kt-mb-15">
                  <Form.Label>
                    {lang['REGISTRATION.TRANSIT_IBAN']}
                  </Form.Label>
                  <Controller
                    name="transit_iban"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        type="text"
                        isInvalid={!!errors.transit_iban}
                      />
                    )}
                  />
                  <Form.Text className="text-muted">
                    {lang['REGISTRATION.TRANSIT_IBAN_PLEASE']}
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {lang['REGISTRATION.TRANSIT_IBAN_ERROR']}
                  </Form.Control.Feedback>
                </Form.Group>
              )}

              {isBYPersonalData(formValues) && (
                <Form.Group className="row kt-mt-20">
                  <div className="d-flex align-items-center" style={{ paddingLeft: '20px' }}>
                    <Controller
                      name="is_allow_conversion"
                      control={control}
                      render={({ field }) => (
                        <Form.Check
                          {...field}
                          type='checkbox'
                          isInvalid={!!errors.is_allow_conversion}
                        />
                      )}
                    />
                    <Form.Label column>
                      {lang['REGISTRATION.ALLOW_CONVERSION_TERM']}
                    </Form.Label>
                  </div>
                  {!!errors.is_allow_conversion && (
                    <Form.Text className="text-danger" style={{ paddingLeft: '45px' }}>
                      {lang['REGISTRATION.ALLOW_CONVERSION_ERROR']}
                    </Form.Text>
                  )}
                </Form.Group>
              )}
            </div>
          </div>
        </div>
        {isXFLOW && <div>
          <div className="kt-form__section kt-form__section--first">
            {lang['REGISTRATION.FINANCE_INFO']}
          </div>
        </div>}
      </div>

    </>
  );
};

FinancialInformationTab.propTypes = {
  step: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default FinancialInformationTab;
