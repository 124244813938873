import axiosApiInstance from '../../requests/utils/api';
import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';
import getGroupObject from '../../utils/getGroupObject';
import { buildUsersObject } from '../../requests/users';
import typesUsers from '../../pages/customers/typesUsers';
import trimObjectStringProps from '../../utils/trimObjectStringProps';



const actions = {
  LOADING: 'LOADING_GROUP',
  SET_GROUP: 'SET_GROUP',
  SET_GROUPS: 'SET_GROUPS',
  ADD_GROUP: 'ADD_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  setLoading: (payload) => ({
    type: actions.LOADING,
    payload,
  }),
  setGroup: (payload) => ({
    type: actions.SET_GROUP,
    payload,
  }),
  setGroups: (payload) => ({
    type: actions.SET_GROUPS,
    payload,
  }),
  addGroup: (payload) => ({
    type: actions.ADD_GROUP,
    payload,
  }),
  removeGroup: (payload) => ({
    type: actions.DELETE_GROUP,
    payload,
  }),
  fetchGroup: (id) => async (dispatch, _getState, api) => {
    const URL = `groups/${id}`;

    try {
      dispatch(actions.setLoading(true));
      const response = await api.get(URL);

      let group = response;

      if (response.data.group) {
        response.data.group.users = buildUsersObject(response.data.group.users);
        response.data.group = {
          ...response.data.group,
          users: response.data.group.users,
        };
        group = response.data.group;
      }

      dispatch(actions.setGroup({
        name: group.name,
        description: group.description,
        editor: group.users.find((user) => user.type === typesUsers.editor.key).id,
        artists: group.users.filter((user) => user.type === typesUsers.artist.key).map((user) => user.id),
        designers: group.users.filter((user) => user.type === typesUsers.designer.key).map((user) => user.id),
      }));
    } catch {
      // todo
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  getGroups: () => async (dispatch) => {
    try {
      const URL = 'groups';
      const data = await axiosApiInstance.get(URL);

      if (data.data.groups) {
        dispatch(actions.setGroups(data.data.groups));
      }
    } catch {
      // TODO show notification about failed groups
    }
  },
  createGroup: (newGroup, callback) => async (dispatch, getState) => {
    const store = getState();

    try {
      const URL = 'groups';
      const data = await axiosApiInstance.post(URL, trimObjectStringProps(newGroup));
      const groupId = data.data;

      dispatch(actions.addGroup(getGroupObject(newGroup, groupId.id, store)));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_GROUP_CREATE'], NOTIFICATIONS_TYPES.success));

      if (callback) {
        callback();
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_GROUP_CREATE'], NOTIFICATIONS_TYPES.error));
      }
    }
  },
  updateGroup: (groupId, newGroup) => async (dispatch, getState) => {
    const store = getState();

    try {
      const URL = `groups/${groupId}`;

      await axiosApiInstance.put(URL, trimObjectStringProps(newGroup));

      dispatch({
        type: actions.UPDATE_GROUP,
        payload: getGroupObject(newGroup, Number(groupId), store),
      });
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_GROUP_EDIT'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_GROUP_EDIT'], NOTIFICATIONS_TYPES.error));
      }
    }
  },
  deleteGroup: (groupId) => async (dispatch, getState) => {
    const store = getState();

    try {
      const URL = `groups/${groupId}`;

      await axiosApiInstance.delete(URL);
      dispatch(actions.removeGroup(Number(groupId)));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_GROUP_DELETE'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_GROUP_DELETE'], NOTIFICATIONS_TYPES.error));
      }
    }
  },
};

export default actions;
