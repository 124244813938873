import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urlPageCategoriesStatistic from '../../urls/urlPageCategoriesStatistic';
import urlPageError_v4 from '../../urls/urlPageError_v4';
import CategoriesStatistics from './CategoriesStatistic';



/**
 * Component for routing in categories
 */

export const Categories = () => {
  return (
    <Switch>
      <Route path={urlPageCategoriesStatistic()} component={CategoriesStatistics} />
      <Redirect to={urlPageError_v4()} />
    </Switch>
  );
};

export default Categories;
