const SortCaseInsensitive = (sortFields) => {
  return sortFields.map((column) => {
    return (row) => {
      const value = row[column];

      if (typeof value === 'string') {
        return value.toLowerCase();
      }
      return value;
    };
  });
};

export default SortCaseInsensitive;
