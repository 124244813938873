import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { filter } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import InputSelector from '../../widgets/InputSelector';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import roles from '../customers/roles/roles';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../customers/roles/groupsRoles';
import actions from '../../store/groups/actions';
import urlPageGroups from '../../urls/urlPageGroups';



const propTypes = {
  lang: PropTypes.object.isRequired,
  users: PropTypes.any.isRequired,
  history: PropTypes.any.isRequired,
};

/**
 * Component for group creation page
 */

const GroupCreate = ({}) => {
  const lang = useSelector((store) => store.language.lang);
  const users = useSelector((store) => store.users);
  const history = useHistory();
  const dispatch = useDispatch();

  const [ curStep, setCurStep ] = useState(0);
  const [ showInvalid, setShowInvalid ] = useState(false);
  const [ newGroup, setNewGroup ] = useState({
    name: '',
    description: '',
    editor: null,
    artists: [],
    designers: [],
  });

  const steps = [
    {
      check: () => {
        return newGroup.name.trim() && newGroup.description.trim();
      },
    }, {
      check: () => {
        return !!newGroup.editor;
      },
    }, {
      check: () => {
        return newGroup.artists.length > 0;
      },
    }, {
      check: () => {
        return newGroup.designers.length > 0;
      },
    },
  ];

  const activeUsers = () => users.filter((user) => user.inactive === false);

  const handleInputChange = (event) => {
    const name = event.target.name;

    let value = event.target.value;

    if (name === 'editor') {
      value = Number(value);
    }

    setNewGroup((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getEditorListOption = () => {
    return activeUsers()
      .filter((user) => checkRole(user.roles, groupsRoles.editors.all))
      .map((editor) => (
        <option
          key={editor.id}
          value={editor.id}
        >
          {editor.username}
        </option>
      ));
  };

  const getExecutorList = (role) => {
    const acceptRoles = role === 'artist' ? [ roles['coloring-artist'].key ] : [ roles['coloring-designer'].key ];

    return activeUsers()
      .filter((user) => checkRole(user.roles, acceptRoles))
      .map((user) => ({
        value: user.id,
        title: user?.username,
      }));
  };

  const handleExecutorChange = (action, role) => {
    return (userId) => {
      if (action === 'add') {
        setNewGroup((prevState) => ({
          ...prevState,
          [role]: [
            ...prevState[role],
            userId,
          ],
        }));
      } else if (action === 'remove') {
        setNewGroup((prevState) => ({
          ...prevState,
          [role]: filter(prevState[role], (value) => value !== userId),
        }));
      }
    };
  };

  const prevStep = () => {
    setCurStep((prevState) => (prevState - 1));
    setShowInvalid(false);
  };

  const nextStep = () => {
    if (steps[curStep].check ? steps[curStep].check() : true) {
      setCurStep((prevState) => (prevState + 1));
      setShowInvalid(false);
    } else {
      setShowInvalid(true);
    }
  };

  const createGroup = () => {
    if (steps[curStep].check ? steps[curStep].check() : true) {
      dispatch(actions.createGroup(newGroup, () => history.push(urlPageGroups())));
    } else {
      setShowInvalid(true);
    }
  };

  return (<>
    <SubHeader
      title={lang['GROUP.CREATING_NEW']}
    />
    <div className="kt-portlet">
      <div className="kt-portlet__body kt-portlet__body--fit">
        <div
          className="kt-grid  kt-wizard-v2 kt-wizard-v2--white"
          data-ktwizard-state={curStep === 0 ? 'first' : curStep === steps.length - 1 ? 'last' : 'between'}
        >
          <div className="kt-grid__item kt-wizard-v2__aside">
            {/* begin: Form Wizard Nav */}
            <div className="kt-wizard-v2__nav">
              <div className="kt-wizard-v2__nav-items">
                <div
                  className="kt-wizard-v2__nav-item"
                  data-ktwizard-state={curStep === 0 ? 'current' : 'padding'}
                >
                  <div className="kt-wizard-v2__nav-body">
                    <div className="kt-wizard-v2__nav-icon">
                      <i className="flaticon-exclamation" />
                    </div>
                    <div className="kt-wizard-v2__nav-label">
                      <div className="kt-wizard-v2__nav-label-title">
                        {lang['GROUP.MAIN_SETTINGS']}
                      </div>
                      <div className="kt-wizard-v2__nav-label-desc">
                        {lang['GROUP.NAME_AND_DESCRIPTION']}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="kt-wizard-v2__nav-item"
                  data-ktwizard-state={curStep === 1 ? 'current' : 'padding'}
                >
                  <div className="kt-wizard-v2__nav-body">
                    <div className="kt-wizard-v2__nav-icon">
                      <i className="la la-edit" />
                    </div>
                    <div className="kt-wizard-v2__nav-label">
                      <div className="kt-wizard-v2__nav-label-title">
                        {lang['GROUP.EDITOR']}
                      </div>
                      <div className="kt-wizard-v2__nav-label-desc">
                        {lang['GROUP.EDITOR_CHOOSE']}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="kt-wizard-v2__nav-item"
                  data-ktwizard-state={curStep === 2 ? 'current' : 'padding'}
                >
                  <div className="kt-wizard-v2__nav-body">
                    <div className="kt-wizard-v2__nav-icon">
                      <i className="la la-paint-brush" />
                    </div>
                    <div className="kt-wizard-v2__nav-label">
                      <div className="kt-wizard-v2__nav-label-title">
                        {lang['GROUP.ARTIST']}
                      </div>
                      <div className="kt-wizard-v2__nav-label-desc">
                        {lang['GROUP.ARTIST_CHOOSE']}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="kt-wizard-v2__nav-item"
                  data-ktwizard-state={curStep === 3 ? 'current' : 'padding'}
                >
                  <div className="kt-wizard-v2__nav-body">
                    <div className="kt-wizard-v2__nav-icon">
                      <i className="flaticon2-drop" />
                    </div>
                    <div className="kt-wizard-v2__nav-label">
                      <div className="kt-wizard-v2__nav-label-title">
                        {lang['GROUP.DESIGNER']}
                      </div>
                      <div className="kt-wizard-v2__nav-label-desc">
                        {lang['GROUP.DESIGNER_CHOOSE']}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* end: Form Wizard Nav */}
          </div>
          <div className="kt-grid__item kt-grid__item--fluid kt-wizard-v2__wrapper">
            {/* begin: Form Wizard Form */}
            <div className="kt-form">

              {/* begin: Form Wizard Step 1 */}
              {curStep === 0 && (
                <div className="kt-wizard-v2__content">
                  <div
                    className="kt-heading kt-heading--md"
                  >{lang['GROUP.ENTER_MAIN_INFO']}</div>
                  <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                      <div className="form-group">
                        <Form.Group>
                          <Form.Label>{lang['GROUP.NAME']}</Form.Label>
                          <Form.Control
                            name="name"
                            type="text"
                            isInvalid={showInvalid && !newGroup.name.trim()}
                            value={newGroup.name}
                            onChange={handleInputChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {lang['GLOBAL.FIELD_REQUIRED']}
                          </Form.Control.Feedback>
                          <Form.Text type="text-muted" />
                        </Form.Group>
                      </div>
                      <div className="form-group">
                        <Form.Group>
                          <Form.Label>{lang['GROUP.DESCRIPTION']}</Form.Label>
                          <Form.Control
                            as='textarea'
                            type="text"
                            name="description"
                            isInvalid={showInvalid && !newGroup.description.trim()}
                            value={newGroup.description}
                            onChange={handleInputChange}
                          />
                          <Form.Control.Feedback type="invalid">
                            {lang['GLOBAL.FIELD_REQUIRED']}
                          </Form.Control.Feedback>
                          <Form.Text type="text-muted" />
                        </Form.Group>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* end: Form Wizard Step 1 */}

              {/* begin: Form Wizard Step 2 */}
              {curStep === 1 && (
                <div className="kt-wizard-v2__content">
                  <div className="kt-heading kt-heading--md">
                    {lang['GROUP.EDITOR_CHOOSE']}
                  </div>
                  <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="form-group">
                            <Form.Group>
                              <Form.Control
                                as='select'
                                name="editor"
                                isInvalid={showInvalid && !newGroup.editor}
                                value={newGroup.editor || lang['GLOBAL.CHOOSE']}
                                onChange={handleInputChange}
                              >
                                {!newGroup.editor && (
                                  <option disabled>
                                    {lang['GLOBAL.CHOOSE']}
                                  </option>)}
                                {getEditorListOption()}
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                {lang['GLOBAL.FIELD_REQUIRED']}
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* end: Form Wizard Step 2 */}

              {/* begin: Form Wizard Step 3 */}
              {curStep === 2 && (
                <div className="kt-wizard-v2__content">
                  <div className="kt-heading kt-heading--md">
                    {lang['GROUP.ARTIST_CHOOSE']}
                  </div>
                  <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                      <Form.Group>
                        <InputSelector
                          list={getExecutorList('artist')}
                          isInvalid={showInvalid && newGroup.artists.length === 0}
                          cur={newGroup.artists}
                          add={handleExecutorChange('add', 'artists')}
                          remove={handleExecutorChange('remove', 'artists')}
                        />
                        <Form.Control.Feedback type="invalid">
                          {lang['GLOBAL.FIELD_REQUIRED']}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              )}
              {/* end: Form Wizard Step 3 */}

              {/* begin: Form Wizard Step 4 */}
              {curStep === 3 && (
                <div className="kt-wizard-v2__content">
                  <div
                    className="kt-heading kt-heading--md"
                  >{lang['GROUP.DESIGNER_CHOOSE']}</div>
                  <div className="kt-form__section kt-form__section--first">
                    <div className="kt-wizard-v2__form">
                      <Form.Group>
                        <InputSelector
                          list={getExecutorList('designer')}
                          isInvalid={showInvalid && newGroup.designers.length === 0}
                          cur={newGroup.designers}
                          add={handleExecutorChange('add', 'designers')}
                          remove={handleExecutorChange('remove', 'designers')}
                        />
                        <Form.Control.Feedback type="invalid">
                          {lang['GLOBAL.FIELD_REQUIRED']}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </div>
                  </div>
                </div>
              )}
              {/* end: Form Wizard Step 4 */}

              <div className="kt-form__actions">
                {curStep > 0 && (
                  <button
                    className="btn btn-secondary btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    data-ktwizard-type="action-prev"
                    onClick={prevStep}
                  >
                    {lang['GLOBAL.BACK']}
                  </button>
                )}
                {curStep < steps.length - 1 ? (
                  <button
                    className="btn btn-brand btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    data-ktwizard-type="action-next"
                    onClick={nextStep}
                  >
                    {lang['GLOBAL.NEXT']}
                  </button>
                ) : (
                  <button
                    className="btn btn-success btn-md btn-tall btn-wide kt-font-bold kt-font-transform-u"
                    data-ktwizard-type="action-submit"
                    onClick={createGroup}
                  >
                    {lang['GLOBAL.CREATE']}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
};

GroupCreate.propTypes = propTypes;

export default GroupCreate;
