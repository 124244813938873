import { projectTypes } from '../projectTypes';

/**
 * Checks if all fields for the project are entered correctly
 *
 * @this {ProjectCreate}
 * @param {any} project
 * @returns {boolean}
 */
export default function (project) {
  const { config } = this.props;

  const projectType = projectTypes.find((type) => type.key === project.project_type);

  if (!projectType) {
    return false;
  }

  // eslint-disable-next-line fp/no-loops
  for (let i = 0; i < projectType.generalSettings.length; i++) {
    if (!this._checkField(projectType.generalSettings[i], project)) {
      return false;
    }
  }

  if (projectType.projectSettings) {
    // eslint-disable-next-line fp/no-loops
    for (let i = 0; i < projectType.projectSettings.length; i++) {
      if (!this._checkField(projectType.projectSettings[i], project)) {
        return false;
      }
    }
  }

  const type = config[project.project_type] || {};

  const prices = [];

  type.steps?.forEach((step) => {
    prices[step.type] = step.price;
  });

  if (prices.length) {
    // eslint-disable-next-line consistent-return
    Object.keys(prices || {}).forEach((key) => {
      if (!(project.prices[key] || project.prices[key] === 0)) {
        return false;
      }
    });
  }

  return Boolean(project.project_type);
}
