import { createContext, useContext } from 'react';



export const ProjectsListContext = createContext(null);

export const useProjectsList = () => {
  return useContext(ProjectsListContext);
};

