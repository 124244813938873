import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React from 'react';
import Modal from '../../../widgets/Modal';
import {
  colorComplexityLevels,
  getPriceByColorComplexityLevel,
} from '../../projects/projectPrices';
import rateTypes from '../rateTypes';
import rateComplexityTypes from '../rateComplexityTypes';



export default function ColoristRatesModal () {
  const { lang } = this.props;

  return (
    <Modal
      title={lang['CUSTOMER.CHANGE_COLORIST_RATE']}
      show={this.state.showColoristRatesModal}
      close={this.toggleColoristRatesModal}
      footer={(
        <>
          <Button
            variant="secondary"
            onClick={this.toggleColoristRatesModal}
          >
            {lang['GLOBAL.CANCEL']}
          </Button>
          <Button
            variant="success"
            onClick={async () => {
              this.updateRates();
            }}
          >
            {lang['GLOBAL.SAVE']}
          </Button>
        </>
      )}
    >
      <Form>
        <Form.Group>
          <Form.Label>
            {lang['PROJECT.FIELDS.color_complexity_level']} C1
            ({getPriceByColorComplexityLevel(colorComplexityLevels[0])}€)
          </Form.Label>
          <Form.Control
            type="text"
            value={this.state.newRates?.colorist?.c1}
            onChange={(event) => {
              this.handleRateChange(rateTypes.colorist.key, rateComplexityTypes.c1.key, event);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {lang['PROJECT.FIELDS.color_complexity_level']} C2
            ({getPriceByColorComplexityLevel(colorComplexityLevels[1])}€)
          </Form.Label>
          <Form.Control
            type="text"
            value={this.state.newRates?.colorist?.c2}
            onChange={(event) => {
              this.handleRateChange(rateTypes.colorist.key, rateComplexityTypes.c2.key, event);
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>
            {lang['PROJECT.FIELDS.color_complexity_level']} C3
            ({getPriceByColorComplexityLevel(colorComplexityLevels[2])}€)
          </Form.Label>
          <Form.Control
            type="text"
            value={this.state.newRates?.colorist?.c3}
            onChange={(event) => {
              this.handleRateChange(rateTypes.colorist.key, rateComplexityTypes.c3.key, event);
            }}
          />
        </Form.Group>
      </Form>
    </Modal>
  );
}

