import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';
import groupsRoles from '../customers/roles/groupsRoles';
import urlPageManuals from '../../urls/urlPageManuals';
import urlPageManualsCreate from '../../urls/urlPageManualsCreate';
import urlPageError_v4 from '../../urls/urlPageError_v4';
import urlPageManualEdit from '../../urls/urlPageManualEdit';
import urlPageManualDetails from '../../urls/urlPageManualDetails';
import ListManuals from './ListManuals';
import ManualCreate from './ManualCreate';
import ManualView from './ManualView';



const propTypes = {
  userRoles: PropTypes.array.isRequired,
};
/**
 * The routing component for manuals
 */

const Manuals = ({ userRoles }) => {
  return (
    <Switch>
      {checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['lead-coloring-editor'].key ]) && [
        <Route key={1} path={urlPageManualsCreate()} component={ManualCreate} />,
        <Route exact key={2} path={urlPageManualEdit()} component={ManualCreate} />,
      ]}
      <Route exact path={urlPageManualDetails()} component={ManualView} />
      <Route path={urlPageManuals()} component={ListManuals} />
      <Redirect to={urlPageError_v4()} />
    </Switch>
  );
};

const mapStoreToProps = (store) => ({
  userRoles: store.user.roles,
});

Manuals.propTypes = propTypes;

export default connect(mapStoreToProps)(Manuals);
