import getCountryName from './getCountryName';



const getCountriesOptionsList = (countries) => {
  return countries.map((country) => ({
    value: country.code,
    label: getCountryName(country),
  })).sort((firstCountry, secondCountry) => {
    return firstCountry.label.localeCompare(secondCountry.label);
  });
};

export default getCountriesOptionsList;
