import React, {} from 'react';
import { useSelector } from 'react-redux';
import Modal from '../../../widgets/Modal';



const MakeVersionActualModal = ({ makeVersionActual, makeVersionActualFn, setModalMakeVersionActual }) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <Modal
      title={lang['SOFT.YOU_SURE_MAKE_VERSION_ACTUAL']}
      show={!!makeVersionActual}
      close={() => {
        setModalMakeVersionActual(false);
      }}
      footer={
        <>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setModalMakeVersionActual(false);
            }}
          >
            {lang['GLOBAL.CANCEL']}
          </button>

          <button
            className="btn btn-success"
            onClick={() => {
              makeVersionActualFn(makeVersionActual);
            }}
          >
            {lang['GLOBAL.YES']}
          </button>
        </>
      }
    />
  );
};

export default MakeVersionActualModal;
