export const entityTypes = {
  template: 'template',
  group: 'group',
  tag: 'tag',
};

export const entitySubtypes = {
  default: 'default',
  global: 'global',
  local: 'local',
  main: 'main',
  usual: 'usual',
  cloud: 'cloud',
};
