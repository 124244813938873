import React from 'react';
import { Link } from 'react-router-dom';
import urlPageTagSystemTemplates from '../../../urls/urlPageTagSystemTemplates';



const getTemplatesLink = (lang) => {
  return (
    <Link
      key={'template-list-key'}
      className='font-weight-semibold'
      to={urlPageTagSystemTemplates()}
    >
      {lang['TAG_SYSTEM.TEMPLATES.TITLE']}
    </Link>
  );
};

export default getTemplatesLink;
