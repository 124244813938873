import React from 'react';
import { Form } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import MySelect from '../../../widgets/MySelect';
import { DatePickerForwardRef as MyDatePicker } from '../../../widgets/DatePicker';
import getCountriesOptionsList from '../../../utils/getCountriesOptionsList';
import { isBYCitizenship } from '../../../utils/checker';
import { formatDate } from '../../../utils/formats';
import { isFabros } from '../../../utils/isFabros';
import { getLabelText } from '../../../utils/getLabelText';



const PersonalInformationTab = ({ step, control, errors, formValues }) => {
  const { lang, countries } = useSelector(({ language, countries }) => ({
    lang: language.lang,
    countries: countries.countries,
  }), shallowEqual);

  const residenceAddDataOptions = [
    { value: 0, label: lang['REGISTRATION.RESIDENCE_CONFIRMATION_NO'] },
    { value: 1, label: lang['REGISTRATION.RESIDENCE_CONFIRMATION_YES'] },
  ];

  return (
    <>
      <div
        className="kt-wizard-v4__content"
        data-ktwizard-type="step-content"
        data-ktwizard-state={step === 1 && 'current'}
      >
        <div className="kt-heading kt-heading--md">
          {lang['REGISTRATION.ENTER_ACCOUNT_DETAILS']}
        </div>
        <div className="kt-form__section kt-form__section--first">
          <div className="kt-wizard-v4__form">
            <div className="row">
              {isFabros && <Form.Group data-tour="country" className="col-xl-6">
                <Form.Label>
                  {lang['REGISTRATION.CITIZENSHIP']}
                </Form.Label>
                <Controller
                  name="citizenship"
                  control={control}
                  render={({ field }) => (<>
                    <MySelect
                      {...field}
                      name="citizenship"
                      isClearable
                      isInvalid={!!errors.citizenship}
                      options={getCountriesOptionsList(countries)}
                    />
                  </>)}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.COUNTRY_CITIZENSHIP_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.COUNTRY_CITIZENSHIP_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>}

              {isFabros && <Form.Group data-tour="country" className="col-xl-6">
                <Form.Label>
                  {lang['REGISTRATION.COUNTRY_RESIDENCE']}
                </Form.Label>
                <Controller
                  name="residence"
                  control={control}
                  render={({ field }) => (<>
                    <MySelect
                      {...field}
                      name="residence"
                      isClearable
                      isInvalid={!!errors.residence}
                      options={getCountriesOptionsList(countries)}
                    />
                  </>
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.COUNTRY_RESIDENCE_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.COUNTRY_RESIDENCE_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>}

              {isBYCitizenship(formValues) && isFabros && (
                <>
                  <Form.Group data-tour="has_residence_confirmation" className="col-12">
                    <Form.Label>
                      {lang['REGISTRATION.RESIDENCE_CONFIRMATION']}
                    </Form.Label>
                    <Controller
                      name="has_residence_confirmation"
                      control={control}
                      render={({ field }) => (
                        <MySelect
                          {...field}
                          name="has_residence_confirmation"
                          className="kt-mb-15"
                          isInvalid={!!errors.has_residence_confirmation}
                          options={residenceAddDataOptions}
                        />
                      )}
                    />
                    <Form.Text className="text-muted">
                      {lang['REGISTRATION.RESIDENCE_CONFIRMATION_PLEASE']}
                    </Form.Text>
                    <Form.Control.Feedback type="invalid">
                      {lang['REGISTRATION.RESIDENCE_CONFIRMATION_ERROR']}
                    </Form.Control.Feedback>
                  </Form.Group>

                  {!!formValues.has_residence_confirmation?.value && (
                    <Form.Group data-tour="has_been_on_territory" className="col-12">
                      <Form.Label>
                        {lang['REGISTRATION.HAS_BEEN_ON_TERRITORY']}
                      </Form.Label>
                      <Controller
                        name="has_been_on_territory"
                        control={control}
                        render={({ field }) => (
                          <MySelect
                            {...field}
                            name="has_been_on_territory"
                            className="kt-mb-15"
                            isInvalid={!!errors.has_been_on_territory}
                            options={residenceAddDataOptions}
                          />
                        )}
                      />
                      <Form.Text className="text-muted">
                        {lang['REGISTRATION.RESIDENCE_CONFIRMATION_PLEASE']}
                      </Form.Text>
                      <Form.Control.Feedback type="invalid">
                        {lang['REGISTRATION.RESIDENCE_CONFIRMATION_ERROR']}
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                </>
              )}

              <Form.Group className="col-12 col-xl-4">
                <Form.Label>
                  {lang['REGISTRATION.USERNAME']}
                </Form.Label>
                <Controller
                  name="username"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.username}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.USERNAME_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.USERNAME_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-12 col-xl-4" data-tour="password">
                <Form.Label>
                  {lang['REGISTRATION.PASSWORD']}
                </Form.Label>
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="password"
                      readOnly
                      onFocus={(event) => {
                        event.target.removeAttribute('readonly');
                      }}
                      isInvalid={!!errors.password}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.PASSWORD_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.PASSWORD_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-12 col-xl-4">
                <Form.Label>
                  {lang['REGISTRATION.REPEAT_PASSWORD']}
                </Form.Label>
                <Controller
                  name="cpassword"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="password"
                      readOnly
                      placeholder={lang['REGISTRATION.REPEAT_PASSWORD_PLEASE']}
                      onFocus={(event) => {
                        event.target.removeAttribute('readonly');
                      }}
                      isInvalid={!!errors.cpassword}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.REPEAT_PASSWORD_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.REPEAT_PASSWORD_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row">
              <Form.Group className="col-12 col-xl-4" data-tour="name">
                <Form.Label>
                  {lang['REGISTRATION.FIRST_NAME']}
                </Form.Label>
                <Controller
                  name="firstname"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.firstname}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.FIRST_NAME_PLEASE_RUSSIA'],
                    general: lang['REGISTRATION.FIRST_NAME_FOR_RU_PLEASE_RUSSIA'],
                    latin: lang['REGISTRATION.FIRST_NAME_PLEASE_LATIN'],
                  })}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.FIRST_NAME_ERROR_RUSSIAN'],
                    general: lang['REGISTRATION.FIRST_NAME_FOR_RU_ERROR_LATIN'],
                    latin: lang['REGISTRATION.FIRST_NAME_ERROR_LATIN'],
                  })}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-12 col-xl-4">
                <Form.Label>
                  {lang['REGISTRATION.LAST_NAME']}
                </Form.Label>
                <Controller
                  name="lastname"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.lastname}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.LAST_NAME_PLEASE_RUSSIA'],
                    general: lang['REGISTRATION.LAST_NAME_FOR_RU_PLEASE_LATIN'],
                    latin: lang['REGISTRATION.LAST_NAME_PLEASE_LATIN'],
                  })}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.LAST_NAME_ERROR_RUSSIA'],
                    general: lang['REGISTRATION.LAST_NAME_FOR_RU_ERROR_LATIN'],
                    latin: lang['REGISTRATION.LAST_NAME_ERROR_LATIN'],
                  })}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-12 col-xl-4">
                <Form.Label>
                  {lang['REGISTRATION.PATRONYMIC_NAME']}
                </Form.Label>
                <Controller
                  name="patronymic"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.patronymic}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.PATRONYMIC_NAME_PLEASE_RUSSIA'],
                    general: lang['REGISTRATION.PATRONYMIC_NAME_PLEASE_GENERAL'],
                    latin: lang['REGISTRATION.PATRONYMIC_NAME_PLEASE_LATIN'],
                  })}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {getLabelText(formValues, {
                    russian: lang['REGISTRATION.PATRONYMIC_NAME_ERROR_RUSSIA'],
                    general: lang['REGISTRATION.PATRONYMIC_NAME_ERROR_GENERAL'],
                    latin: lang['REGISTRATION.PATRONYMIC_NAME_ERROR_LATIN'],
                  })}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

            <div className="row" data-tour="contact">
              <Form.Group data-tour="birthday" className="col-xl-4">
                <Form.Label>
                  {lang['REGISTRATION.BIRTHDAY']}
                </Form.Label>
                <Controller
                  name="birthday"
                  control={control}
                  render={({ field }) => {
                    return (
                      <MyDatePicker
                        {...field}
                        maxDate={new Date()}
                        value={formatDate(formValues.birthday)}
                        className='w-100'
                        isInvalid={!!errors.birthday}
                      />
                    );
                  }}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.BIRTHDAY_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.BIRTHDAY_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-xl-4">
                <Form.Label>
                  {lang['REGISTRATION.PHONE']}
                </Form.Label>
                <Controller
                  name="phone"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="tel"
                      isInvalid={!!errors.phone}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.PHONE_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.PHONE_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group className="col-xl-4 kt-mb-0-xl kt-mb-15">
                <Form.Label>
                  {lang['REGISTRATION.TELEGRAM']}
                </Form.Label>
                <Controller
                  name="telegram"
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      {...field}
                      autoComplete="off"
                      type="text"
                      isInvalid={!!errors.telegram}
                    />
                  )}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.TELEGRAM_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.TELEGRAM_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            </div>

          </div>
        </div>
      </div>
    </>
  );
};

PersonalInformationTab.propTypes = {
  step: PropTypes.number.isRequired,
  control: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default PersonalInformationTab;
