import taskTypes from '../taskTypes';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import { status } from '../../../utils/statusToColor';



const canSetTags = (task, userId) => {
  const type = taskTypes[task.type];

  if (!type) {
    return false;
  }
  return checkRole(groupsRoles.coloring, [ type.role ])
    && task.type !== taskTypes.device_testing.type
    && task.executor_id === userId
    && task.status !== status.finished
    && task.status !== status.review;
};

export default canSetTags;
