import React from 'react';
import { useSelector } from 'react-redux';
import KtContent from '../../../_metronic/layout/KtContent';
import Modal from '../../widgets/Modal';
import { useGlobalModalContext } from '../GlobalModal';


// Modal for showing Terms&Conditions and Privacy Policy
const ContractModal = () => {
  const lang = useSelector(({ language }) => language.lang);
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { contract } = modalProps || {};

  const handleModalToggle = () => {
    hideModal();
  };

  return (
    <Modal
      title={lang['TOUR.REGISTRATION.PRIVACY_POLICY']}
      show
      full
      close={handleModalToggle}
      footer={
        <button
          className="btn btn-secondary"
          onClick={handleModalToggle}
        >
          {lang['GLOBAL.CANCEL']}
        </button>
      }
    >
      <div
        id="kt_content_privacy"
        className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
      >
        <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
          <KtContent>
            <div className="row">
              <div className="col-xl-12">
                <div className="kt-portlet kt-portlet--height-fluid">
                  <div className="kt-portlet__body">
                    <div className="row w-100">
                      <div
                        className="col-lg-12"
                        dangerouslySetInnerHTML={{ __html: contract }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </KtContent>
        </div>
      </div>
    </Modal>
  );
};

export default ContractModal;
