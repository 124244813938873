import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import Badge from 'react-bootstrap/Badge';
import PropTypes from 'prop-types';
import imgURL from '../../../utils/imgURL';
import roles from '../roles/roles';
import Img from '../../../widgets/Img';
import employeeToColor from '../../../utils/employeeBadgeColor';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import { isSameDomain } from '../../../utils/isSameDomain';



const propTypes = {
  lang: PropTypes.object.isRequired,
  customer: PropTypes.any,
  user: PropTypes.object.isRequired,
};

/**
 * Component for user card in user list
 *
 * @returns {*}
 */
const CustomerItem = ({ customer, lang, user }) => {
  const getWidgetDesc = () => {
    return (
      <span className="kt-widget__desc">
        {customer.roles && customer.roles.length > 0 ? customer.roles.filter((role) => (role !== 'user' && role !== 'freelancer'))
          .map((role) => {
            return <p style={{ marginBottom: 0 }} key={role}>{roles[role]?.title || ''}</p>;
          }) : ''}
      </span>);
  };

  return (
    <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
      <div className="kt-portlet kt-portlet--height-fluid">
        <div className="kt-portlet__body">
          <div className="kt-widget kt-widget--user-profile-2">
            <div className="kt-widget__head">
              <div className="kt-widget__media">
                <Img className="kt-widget__img" src={imgURL(customer.avatar)} />
              </div>
              <div className="kt-widget__info kt-widget__ellipsis">
                {isSameDomain(user.provider_id, customer.provider_id) ? (
                  <Link to={urlPageFreelancerDetails({ freelancerId: customer.id })} className="kt-widget__username" title={customer.username}>
                    {customer.username}
                  </Link>) : <span className="kt-widget__username">{customer.username}</span>}
                {getWidgetDesc()}
              </div>
            </div>
            <div className="kt-widget__body">
              <div className="kt-widget__item">
                <div className="kt-widget__contact">
                  <span className="kt-widget__label">{lang.ROLE}: </span>
                  <span
                    className="kt-widget__data text-truncate"
                    title={customer.is_employee || '--'}
                  >
                    <Badge
                      as='span'
                      variant={employeeToColor(customer.is_employee)}
                    >
                      {lang[`CUSTOMER.IS_EMPLOYEE.${customer.is_employee}`]}
                    </Badge>
                  </span>
                </div>
              </div>
              <div className="kt-widget__item">
                <div className="kt-widget__contact">
                  <span className="kt-widget__label">{lang['GLOBAL.JOINED']}: </span>
                  <span
                    className="kt-widget__data text-truncate"
                    title={customer.first_signed_at || '--'}
                  >
                    <Badge
                      as='span'
                      variant='secondary'
                    >
                      {customer.first_signed_at || '--'}
                    </Badge>
                  </span>
                </div>
              </div>
              {customer.inactive && (
                <div className="kt-widget__item">
                  <div className="kt-widget__contact">
                    <span className="kt-widget__label">{lang['GLOBAL.BLOCKED_AT']}: </span>
                    <span
                      className="kt-widget__data text-truncate"
                      title={customer.inactivated_at || '---'}
                    >
                      <Badge
                        as='span'
                        variant='secondary'
                      >
                        {customer.inactivated_at || '---'}
                      </Badge>
                    </span>
                  </div>
                </div>
              )}
              <div className="kt-section__content kt-section__content--solid d-flex mb-1 pt-4">
                <Button
                  as={Link}
                  variant="outline-secondary"
                  block
                  className="mr-1 p-1"
                  active={!customer.draft}
                  style={!customer.draft ? {
                    background: 'transparent',
                    cursor: 'default',
                  } : {}}
                  to={`/tasks/status/draft?executor=${customer.id}&for_executor=true`}
                >
                  {lang['GLOBAL.STATUS.draft']}
                  <br />
                  <Badge variant="dark">{customer.draft || 0}</Badge>
                </Button>
                <Button
                  as={Link}
                  variant="outline-secondary"
                  block
                  className="my-0 p-1"
                  active={!customer.backlog}
                  style={!customer.backlog ? {
                    background: 'transparent',
                    cursor: 'default',
                  } : {}}
                  to={`/tasks/status/waiting?executor=${customer.id}&for_executor=true`}
                >
                  {lang['GLOBAL.STATUS.in_line']}
                  <br />
                  <Badge variant="info">{customer.backlog || 0}</Badge>
                </Button>
              </div>
              <div className="kt-section__content kt-section__content--solid d-flex">
                <Button
                  as={Link}
                  variant="outline-secondary"
                  block
                  className="mr-1 my-0 p-1"
                  active={!customer.in_work}
                  style={!customer.in_work ? {
                    background: 'transparent',
                    cursor: 'default',
                  } : {}}
                  to={`/tasks/status/process?executor=${customer.id}&for_executor=true`}
                >
                  {lang['GLOBAL.STATUS.in_work']}
                  <br />
                  <Badge variant="danger">{customer.in_work || 0}</Badge>
                </Button>
                <Button
                  as={Link}
                  variant="outline-secondary"
                  block
                  className="my-0 p-1"
                  active={!customer.done}
                  style={!customer.done ? {
                    background: 'transparent',
                    cursor: 'default',
                  } : {}}
                  to={`/tasks/status/finished?executor=${customer.id}&sort[checked_at]=desc&for_executor=true`}
                >
                  {lang['GLOBAL.STATUS.finished']}
                  <br />
                  <Badge variant="success">{customer.done || 0}</Badge>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
});

CustomerItem.propTypes = propTypes;

export default connect(mapStoreToProps)(CustomerItem);
