import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import Table from 'react-bootstrap/Table';
import Img from '../../../widgets/Img';
import { projectStatuses, projectTypes } from '../projectTypes';
import taskTypes from '../../tasks/taskTypes';
import {
  colorComplexityLevels,
  contourComplexityLevels,
  getPriceByColorComplexityLevel,
  getPriceByContourComplexityLevel,
} from '../projectPrices';

/**
 * Builds tables to view entered project data
 *
 * @this {ProjectCreate}
 * @param {any} project
 * @returns {*}
 */
export default function _buildViewProject (project) {
  const { lang, config } = this.props;
  const type = projectTypes.find((item) => item.key === project.project_type);
  const cType = config[project.project_type] || {};

  const prices = [];

  if (typeof cType === 'object' && cType && typeof cType.steps === 'object') {
    Object.values(cType.steps).forEach((value) => {
      if (!project.hasOwnProperty('projectTypeChanged') && project.project_status === projectStatuses.reopened && value.type === 'designer_coloring') {
        prices.push({
          key: value.type,
          val: 7,
        });
      } else {
        const highestContourComplexityLevel = contourComplexityLevels[contourComplexityLevels.length - 1];
        const highestColorComplexityLevel = colorComplexityLevels[colorComplexityLevels.length - 1];

        switch (value.type) {
          case taskTypes.artist_drawing.type:
            prices.push({
              key: value.type,
              val: Number(project.contour_complexity_level) === Number(highestContourComplexityLevel)
                ? project.artist_price
                : String(getPriceByContourComplexityLevel(project.contour_complexity_level)),
            });
            break;
          case taskTypes.designer_coloring.type:
            prices.push({
              key: value.type,
              val: Number(project.color_complexity_level) === Number(highestColorComplexityLevel)
                ? project.designer_price
                : String(getPriceByColorComplexityLevel(project.color_complexity_level)),
            });
            break;
          default:
            prices.push({
              key: value.type,
              val: 0,
            });
        }
      }
    });
  }

  const fields = [ {
    name: lang['PROJECT.TYPE'],
    value: type.title,
  } ];

  // eslint-disable-next-line no-unused-expressions
  type.generalSettings && type.generalSettings.forEach((field, index) => {
    this._buildView(fields, field, index, null, project);
  });

  // eslint-disable-next-line no-unused-expressions
  type.projectSettings && type.projectSettings.forEach((field, index) => {
    const projectView = { ...project };

    if (project.visibility === 'on' && project.project_type === 'coloring-artist') {
      // eslint-disable-next-line fp/no-delete
      delete projectView.artist_drawing;
    }
    this._buildView(fields, field, index, null, projectView);
  });

  return (
    <>
      <div className='col-lg-6 offset-lg-0 col-md-6 offset-md-3 col-12'>
        <Table borderless striped className="fixed-layout-table">
          <thead>
            <tr>
              <th colSpan={2} className='kt-opacity-0'>
                _
              </th>
            </tr>
          </thead>
          <tbody>
            {/* eslint-disable-next-line consistent-return,array-callback-return */}
            {fields.map((field, index) => {
              if (index % 2 === 0) {
                return (
                  <tr key={uuidv4()}>
                    <th>{field.name}</th>
                    <td className="ellipsis-cell" title={field.value}>{field.value}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </div>
      <div className='col-lg-6 offset-lg-0 col-md-6 offset-md-3 col-12'>
        <Table borderless striped className="fixed-layout-table">
          <thead>
            <tr>
              <th colSpan={2} className='kt-opacity-0'>
                _
              </th>
            </tr>
          </thead>
          <tbody>
            {/* eslint-disable-next-line array-callback-return,consistent-return */}
            {fields.map((field, index) => {
              if (index % 2 === 1) {
                return (
                  <tr key={uuidv4()}>
                    <th>{field.name}</th>
                    <td className="ellipsis-cell" title={field.value}>{field.value}</td>
                  </tr>
                );
              }
            })}
          </tbody>
        </Table>
      </div>
      <div className='col-lg-12 offset-lg-0 col-md-6 offset-md-3 col-12'>
        {project.sourceFile && project.sourceFile.length > 0 && <>
          <div className="kt-wizard-v1__review-item">
            <div className="kt-wizard-v1__review-title kt-mt-20">
              {lang['GLOBAL.FILES']}
            </div>
            <div
              className="kt-wizard-v1__review-content dropzone dropzone-default border-0 kt-p-0"
              style={{ cursor: 'default' }}
            >
              <div className='row'>
                <div className='col-lg-6'>
                  {/* eslint-disable-next-line array-callback-return,consistent-return */}
                  {project.sourceFile.map((file, index) => {
                    if (index % 2 === 0) {
                      return (
                        <div
                          key={uuidv4()}
                          className='dz-preview dz-processing dz-image-preview dz-error dz-complete mx-2 w-100 d-flex mw-100 d-flex justify-content-between align-items-center kt-mb-10'
                        >
                          <div className='d-flex align-items-center'>
                            <div className={`dz-image ${file.preview ? '' : 'border-0'}`}>
                              {file.preview && (
                                <Img
                                  alt={file.file.name}
                                  src={file.preview}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}
                            </div>
                            <div>{file.file.name}</div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className='col-lg-6'>
                  {/* eslint-disable-next-line array-callback-return,consistent-return */}
                  {project.sourceFile.map((file, index) => {
                    if (index % 2 === 1) {
                      return (
                        <div
                          key={uuidv4()}
                          className='dz-preview dz-processing dz-image-preview dz-error dz-complete mx-2 w-100 d-flex mw-100 d-flex justify-content-between align-items-center kt-mb-10'
                        >
                          <div className='d-flex align-items-center'>
                            <div className={`dz-image ${file.preview ? '' : 'border-0'}`}>
                              {file.preview && (
                                <Img
                                  alt={file.file.name}
                                  src={file.preview}
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    objectFit: 'cover',
                                  }}
                                />
                              )}
                            </div>
                            <div>{file.file.name}</div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
              </div>
            </div>
          </div>
        </>}
      </div>
    </>);
}
