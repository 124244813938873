import ru from '../../langs/countries/ru';
import store from '../store/store';



const langs = {
  ru,
};

/**
 * Gets the name of the country in a language other than English if it is, otherwise returns in English
 *
 * @param {object} country
 * @returns {string}
 */
export default (country) => {
  const state = store.getState();

  if (langs[state.language.cur] && langs[state.language.cur][country.code]) {
    return langs[state.language.cur][country.code];
  }
  return country.name;
};
