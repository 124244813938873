import { Form } from 'react-bootstrap';
import React from 'react';
import { getCheckedValue } from '../../../utils/getCheckedValue';

/**
 * Builds input for a text field
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {string} prefix
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, value = null, onChange = null, project = null, prefix = null, required) {
  if (!project) {
    project = this.state;
  }
  const { lang } = this.props;

  const checkFieldValue = () => {
    const isInvalid = this.state.showInvalid
      && field.isRequired
      && required
      && (field.check ?
        !field.check(getCheckedValue(value === null ? project[field.key] : value))
        : !getCheckedValue(value === null ? project[field.key] : value)
      );

    if (field.key === 'title') {
      if (!value) {
        return isInvalid;
      }
      if (this.state.invalidProjectTitles?.includes(value.trim())) {
        return true;
      }

      return isInvalid || !field.pattern?.test(value);
    }

    return isInvalid;
  };

  const handleInputChange = (event) => {
    const value = field.key === 'title' ? event.target.value.replace(/[А-Яа-я]/, '') : event.target.value;

    if (onChange === null) {

      this.setState({
        project: {
          ...project,
          [field.key]: value,
        },
      });
    } else {
      onChange(value);
    }
  };

  return (
    <Form.Group
      as={Form.Row} key={field.key}
      style={{
        marginBottom: '1rem',
        ...(field.isFull ? {} : {
          maxWidth: 1024,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      }}
    >
      <div className={field.isColumn ? 'col-12' : 'col-lg-3 col-12'}>
        <Form.Label column>
          {(lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '')}
          {field.isRequired && required && <span style={{ color: 'red' }}>{' *'}</span>}
          {prefix && <span style={{ float: 'right' }}>{prefix}</span>}
        </Form.Label>
      </div>
      <div className={field.isColumn ? 'col-12 d-flex flex-column' : 'col-lg-9 col-12 d-flex flex-column'} title={field.altTitle || null}>
        <Form.Control
          type={field.type || 'text'}
          min={field.type === 'number' ? field.min : null}
          step={field.type === 'number' ? field.step : null}
          placeholder={field.placeholder}
          pattern={field.pattern || null}
          isInvalid={checkFieldValue()}
          value={(value === null ? project[field.key] : value) || ''}
          onChange={handleInputChange}
        />
        {field.muted && (
          <Form.Text className="text-muted">
            {field.muted}
          </Form.Text>
        )}
        {(field.key === 'title' && this.state.invalidProjectTitles?.includes(project[field.key]?.trim())) ? (
          <Form.Control.Feedback type="invalid" className="title-unique-validation">
            {lang['PROJECT.TITLE_UNIQUE_VALIDATION_ERROR']}
          </Form.Control.Feedback>
        ) : (
          <Form.Control.Feedback type="invalid">
            {lang['GLOBAL.FIELD_REQUIRED']}
          </Form.Control.Feedback>
        )}
      </div>
    </Form.Group>
  );
}
