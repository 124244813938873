import { tagConfig } from '../../../../config/tag';



export const getOptions = (type, subtype) => {
  if (!type || !subtype) {
    return [];
  }

  if (!tagConfig.hasOwnProperty(type)) {
    return [];
  }

  if (!tagConfig[type].subtypes.hasOwnProperty(subtype)) {
    return [];
  }

  return tagConfig[type].subtypes[subtype]?.options
    .filter((option) => !option.readonly);
};
