import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Badge from 'react-bootstrap/Badge';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { formatToPrettyDate } from '../../../utils/formats';
import statusToColor from '../../../utils/statusToColor';
import Paginator from '../../../widgets/Paginator';
import KTUtil from '../../../../_metronic/_assets/js/util';



const PayoutsTable = ({ items, onClickRow }) => {
  const itemsPerPage = 50;
  const [ skip, setSkip ] = useState(0);
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const getPaginator = (items) => {
    const count = Math.floor(items.length / itemsPerPage) - (items.length % itemsPerPage === 0 ? 1 : 0);

    return (
      <div className="d-flex justify-content-end">
        <Paginator
          active={(skip / itemsPerPage)}
          count={count}
          select={(newPage) => {
            setSkip(newPage * itemsPerPage);
            KTUtil.scrollTop(0, 300);
          }}
        />
      </div>
    );
  };

  return (
    <>
      {getPaginator(items)}
      <div className='mw-100 overflow-auto'>
        <Table className='vertical-align-middle text-center table-hover'>
          <thead className='thead-light'>
            <tr>
              <th>{lang['BILLING.INVOICE_ID']}</th>
              <th>{lang['BILLING.TITLE']}</th>
              <th>{lang['BILLING.FROM']}</th>
              <th>{lang['BILLING.TO']}</th>
              <th>{lang['BILLING.TOTAL']}</th>
              <th>{lang['GLOBAL.STATUS']}</th>
            </tr>
          </thead>
          <tbody>
            {items.slice(skip, skip + itemsPerPage).map((bill) => {
              return (
                <tr
                  key={uuidv4()}
                  style={{ cursor: 'pointer' }}
                  onClick={() => {
                    onClickRow(bill);
                  }}
                >
                  <td>{bill.id}</td>
                  <td>{bill.name}</td>
                  <td>{formatToPrettyDate(bill.from)}</td>
                  <td>{formatToPrettyDate(bill.to)}</td>
                  <td>{`${bill.price} ${bill.data.rate || '€'}`}</td>
                  <td>
                    <Badge
                      as='span'
                      variant={statusToColor(bill.status)}
                    >
                      {bill.status ? lang['GLOBAL.STATUS.paid'] : lang['GLOBAL.STATUS.unpaid']}
                    </Badge>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
      </div>
      {getPaginator(items)}
    </>
  );
};

PayoutsTable.propTypes = {
  items: PropTypes.array.isRequired,
  onClickRow: PropTypes.func.isRequired,
};

export default PayoutsTable;
