import React from 'react';
import { connect } from 'react-redux';
import objectPath from 'object-path';
import { withRouter } from 'react-router-dom';
import * as builder from '../../ducks/builder';
import PropTypes from 'prop-types';
import { isScreenMd } from '../../../app/utils/size';
import Button from 'react-bootstrap/Button';

class SubHeader extends React.Component {
    state = {
        isMobile: isScreenMd(),
        showMenu: false
    };

    _subheader = React.createRef();

    render () {
      const {
        subheaderCssClasses,
        subheaderContainerCssClasses,
        subheaderMobileToggle,
        title,
        info,
        subtitle,
        main,
        mainMobileLg,
        mainMobileSm,
        toolbar,
        size,
      } = this.props;

        const {
            isMobile,
            showMenu
        } = this.state;

        return (
            <div
                id="kt_subheader"
                ref={this._subheader}
                className={`kt-subheader ${ subheaderCssClasses } ${ size && size !== 'md' ? 'kt-subheader--' + size : '' } kt-grid__item no-print ${ (isMobile && (showMenu ? 'h-auto' : 'overflow-hidden')) || '' }`}
            >
                <div className={`kt-container ${ subheaderContainerCssClasses }`}>
                    <div className="kt-subheader__main">
                        {subheaderMobileToggle && (
                            <button className="kt-subheader__mobile-toggle kt-subheader__mobile-toggle--left">
                                <span/>
                            </button>
                        )}

              <h3 className="kt-subheader__title font-weight-semibold">
                {title}
                {subtitle && <span className='text-muted kt-ml-5 font-weight-semibold d-flex align-items-center'>{subtitle}</span>}
              </h3>
              {!isMobile && (<>
                {(title || subtitle) && (info || main) && (
                  <span className="kt-subheader__separator kt-subheader__separator--v" />
                )}
                {info && (
                  <span className="kt-subheader__desc">{info}</span>
                )}
                {(info) && (main) && (
                  <span className="kt-subheader__separator kt-subheader__separator--v" />
                )}
                {main}
              </>)}
              {isMobile && mainMobileSm && (<>
                {(title || subtitle) && (mainMobileSm) && (
                  <span className="kt-subheader__separator kt-subheader__separator--v" />
                )}
                {mainMobileSm}
              </>)}
            </div>

                    <div className="kt-subheader__toolbar">
                        {isMobile ? (
                            (info || (mainMobileSm ? mainMobileLg : main) || toolbar) && (
                                <Button
                                    variant='secondary'
                                    // size={this.props.size}
                                    onClick={() => {
                                        this.setState({
                                            showMenu: !showMenu
                                        });
                                    }}
                                >
                                    <i className='flaticon2-down kt-p-0' style={{
                                        transition: '0.1s ease all',
                                        marginRight: 0,
                                        marginTop: showMenu ? 0 : 2,
                                        marginBottom: showMenu ? 2 : 0,
                                        transform: showMenu ? 'rotate(180deg)' : 'rotate(0deg)'
                                    }}/>
                                </Button>
                            )
                        ) : (
                            <div className="kt-subheader__wrapper">
                                {toolbar}
                            </div>
                        )}
                    </div>
                    {isMobile && (
                        <div className={'w-100' + (showMenu ? '' : ' d-none')}>
                            <div className='row'>
                                {info && (
                                    <div className='col-12 kt-pb-10'>
                                        <span className="kt-subheader__desc">{info}</span>
                                    </div>
                                )}
                                {(mainMobileSm ? mainMobileLg : main) && (
                                    <div className='col-12 d-flex justify-content-end kt-pb-10'>
                                        {mainMobileSm ? mainMobileLg : main}
                                    </div>
                                )}
                                {toolbar && (
                                    <div className='col-12 d-flex justify-content-end flex-wrap kt-pb-10'>
                                        {toolbar}
                                    </div>
                                )}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        );
    }

    componentDidMount () {
      window.addEventListener('resize', this._eventResize);

      if (this.state.isMobile) {
        window.addEventListener('click', this._eventClick);
      }
    }

    componentWillUnmount () {
      window.removeEventListener('resize', this._eventResize);
      window.removeEventListener('click', this._eventClick);
    }

    _eventResize = () => {
        const isMd = isScreenMd();
        if (this.state.isMobile && !isMd) {
            window.removeEventListener('click', this._eventClick);
            this.setState({
                isMobile: false,
                showMenu: false
            });
        } else if (!this.state.isMobile && isMd) {
            window.addEventListener('click', this._eventClick);
            this.setState({
                isMobile: true,
                showMenu: false
            });
        }
    };

    _eventClick = (event) => {
        const path = event.path || (event.composedPath && event.composedPath()) || [];
        if (path.indexOf(this._subheader.current) === -1) {
            this.setState({
                showMenu: false
            });
        }
    }
}

SubHeader.propTypes = {
    subheaderCssClasses: PropTypes.string,
    subheaderContainerCssClasses: PropTypes.string,
    subheaderMobileToggle: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    subtitle: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    info: PropTypes.string,
    main: PropTypes.node,
    mainMobileLg: PropTypes.node,
    mainMobileSm: PropTypes.node,
    toolbar: PropTypes.node,
    size: PropTypes.oneOf(['sm', 'md'])
};

const mapStateToProps = store => ({
    subheaderCssClasses: builder.selectors.getClasses(store, {
        path: 'subheader',
        toString: true
    }),
    subheaderContainerCssClasses: builder.selectors.getClasses(store, {
        path: 'subheader_container',
        toString: true
    }),
    config: store.builder.layoutConfig,
    menuConfig: store.builder.menuConfig,
    subheaderMobileToggle: objectPath.get(
        store.builder.layoutConfig,
        'subheader.mobile-toggle'
    )
});

export default withRouter(connect(mapStateToProps)(SubHeader));
