import React from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { formatToPrettyDate } from '../utils/formats';
import { typeCommentToColor } from '../utils/typesComments';
import HtmlEditor from '../components/HtmlEditor';
import DeleteCommentConfirmationModal from '../pages/tasks/modal/DeleteCommentConfirmationModal';
import { getUser } from '../requests/users';
import roles from '../pages/customers/roles/roles';
import config from '../../config/app';



class Chat extends React.Component {
  scroll = null;

  messages = React.createRef();

  state = {
    newMsg: '',
    editMsg: '',
    msgId: null,
    editMsgUpdatedAt: null,
    msgDeletedAt: null,
    showDeleteMessageConfirmationModal: false,
  };

  getUserRoles = (user) => {
    return user?.roles.filter((role) => (![
      'administrator',
      'user',
      'freelancer',
      'manager',
      'content-manager',
      'lead-coloring-tester',
      'coloring-tester',
      'remote-coloring-editor',
      'coloring-editor',
      'lead-coloring-editor',
    ].includes(role)))
      .map((role) => roles[role].title).join(', ');
  };

  render () {
    const { messages, lang, canComment } = this.props;
    const { editMsg, newMsg, msgId, showDeleteMessageConfirmationModal } = this.state;

    return (
      <>
        <DeleteCommentConfirmationModal
          onModalConfirm={async () => {
            this.deleteMessage(msgId);
          }}
          toggleModal={showDeleteMessageConfirmationModal}
          onModalCancel={() => {
            this._setModalConfirmDeleteMessage(false);
          }}
        />
        <div className='kt-chat w-100 d-flex flex-column justify-content-between' style={{ maxHeight: '690px' }}>
          <PerfectScrollbar
          // eslint-disable-next-line no-return-assign
            containerRef={(ref) => this.scroll = ref}
            options={{ suppressScrollX: true }}
            className='kt-scroll kt-scroll--pull mb-2'
          >
            <div
              className='kt-chat__messages kt-chat__messages--solid'
              ref={this.messages}
            >
              {messages.map((msg) => {
                const user = getUser(msg.user_id);

                return (
                  <div
                    key={msg.time}
                    className={`kt-chat__message ${msg.isYou ? 'kt-chat__message--right' : ''} kt-chat__message--${typeCommentToColor(msg.status)}`}
                  >
                    <div className='kt-chat__user d-flex align-items-start justify-content-between'>
                      <>
                        <div className='kt-chat__user d-flex flex-column align-items-left'>
                          <span className='d-inline-flex flex-wrap align-items-center justify-content-space-between'>
                            <span className="kt-media kt-chat__username no-hover px-0">
                              {user?.username}
                            </span>
                          </span>
                          <div>{this.getUserRoles(user)}</div>
                        </div>
                        <div className='kt-chat__user justify-content-end'>
                          <span className="kt-chat__datetime justify-content-end">
                            {formatToPrettyDate(msg.time)} {msg.isYou && !msg.deleted_at && (
                              <>
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                <a
                                  onKeyDown={() => {}}
                                  className='kt-ml-5 badge badge-secondary'
                                  onClick={() => this.editMessage(msg)}
                                >
                                  <i className="flaticon-edit" />
                                </a>
                                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                                <a
                                  onKeyDown={() => {}}
                                  className='kt-ml-5 badge badge-secondary'
                                  onClick={() => this._setModalConfirmDeleteMessage(true, msg.id)}
                                >
                                  <i className="flaticon-delete" />
                                </a>
                              </>
                            )}
                          </span>
                        </div>
                      </>
                    </div>
                    {!msg.deleted_at ? (
                      <>
                        <div
                          className="kt-chat__text w-100"
                          dangerouslySetInnerHTML={{ __html: msg.message?.props?.children[0] }}
                        />
                        <div>{ msg.message?.props?.children[1] }</div>
                      </>
                    ) : (
                      <span className='d-flex justify-content-end'>{lang['PROJECT.COMMENT_DELETED']}</span>
                    )}
                    {msg.updated_at && !msg.deleted_at && (
                      <span className='d-flex justify-content-end'>{lang['PROJECT.COMMENT_EDITED']}</span>
                    )}
                  </div>
                );
              })}
            </div>
          </PerfectScrollbar>
          {canComment && (
            <div style={{ minHeight: '230px' }}>
              <HtmlEditor
                onChange={this.onMessageChange}
                initialValue={editMsg || newMsg}
                init={{
                  license_key: config.tinyEditorApiKey,
                  plugins: 'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link table charmap pagebreak nonbreaking advlist lists wordcount help charmap emoticons',
                  toolbar: 'undo redo | bold italic underline fontsizeselect numlist bullist | fontselect formatselect strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview',
                  toolbar_mode: 'sliding',
                  menubar: false,
                  content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                }}
              />
              <div className="kt-chat__toolbar">
                <div className="kt_chat__tools" />
                <div className="kt_chat__actions text-right kt-mt-10">
                  <button
                    type="submit"
                    className="btn btn-brand btn-md  btn-font-sm btn-upper btn-bold kt-chat__reply"
                    disabled={editMsg ? editMsg.trim().length === 0 : newMsg.trim().length === 0}
                    onClick={this.onMessageSubmit}
                  >
                    {lang['GLOBAL.SUBMIT']}
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }

  componentDidMount () {
    this.scrollToBottom();
  }

  scrollToBottom = () => {
    if (this.scroll && this.messages.current) {
      this.scroll.scrollTop = this.messages.current.offsetHeight;
    }
  };

  /**
   * Edit message
   *
   * @param {object} msg
   * @returns {void}
   */
  editMessage = (msg) => {
    this.setState({
      msgId: msg.id,
      editMsgUpdatedAt: msg.updated_at,
      editMsg: msg.message.props.children[0],
      editMsgCopy: msg.message.props.children[0],
    });
  };

  /**
   * Delete message
   *
   * @param {int} msgId
   * @returns {void}
   */
  deleteMessage = (msgId) => {
    this.setState({
      msgId,
      msgDeletedAt: new Date(),
      showDeleteMessageConfirmationModal: false,
    });

    event.preventDefault();
    if (this.scroll && this.messages.current) {
      this.scroll.scrollTop = this.messages.current.offsetHeight;
    }
    this.props.onDeleteComment(msgId);
  };

  _setModalConfirmDeleteMessage = (value, msgId) => {
    this.setState({
      msgId,
      showDeleteMessageConfirmationModal: value,
    });
  };

  resetEditMessage = () => {
    this.setState({
      editMsg: '',
      editMsgCopy: '',
    });
  };

  /**
   * On message change
   *
   * @param {string} value
   * @returns {void}
   */
  onMessageChange = (value) => {
    if (this.state.editMsg) {
      this.setState({
        editMsg: value,
        editMsgUpdatedAt: new Date(),
      });
    } else {
      this.setState({
        newMsg: value,
      });
    }
  };

  /**
   * On message submit
   *
   * @param {Event} event
   * @returns {void}
   */
  onMessageSubmit = async (event) => {
    event.preventDefault();
    // if (this.scroll && this.messages.current) {
    //   this.scroll.scrollTop = this.messages.current.offsetHeight;
    // }
    const { newMsg, editMsg, msgId, editMsgUpdatedAt } = this.state;

    if (this.props.onSend) {
      if (newMsg.trim().length > 0) {
        await this.props.onSend(newMsg.trim());
      }
      if (editMsg) {
        await this.props.onUpdateComment(msgId, editMsg, editMsgUpdatedAt);
      }
      this.scrollToBottom();
      this.setState({
        newMsg: '',
        editMsg: '',
        editMsgCopy: '',
        msgId: null,
        editMsgUpdatedAt: null,
      });
    }
  };

  //@TODO: Need refactoring
  shouldComponentUpdate () {
    //   let shouldUpdate = false;
    //
    //   console.log('nextProps', nextProps);
    //   console.log('nextState', nextState);
    //   nextProps.messages.forEach((item) => {
    //   const updatedMsg = this.props.messages.find((msg) => (msg.updated_at !== item.updated_at || msg.message.props.children[0] !== item.message.props.children[0]) && msg.id === item.id);
    //
    //   if (updatedMsg) {
    //     shouldUpdate = true;
    //   }
    // });
    // return shouldUpdate ||
    //   this.state.newMsg !== nextState.newMsg
    //   || this.state.editMsg !== nextState.editMsg
    //   || this.props.messages.length !== nextProps.messages.length;
    return true;
  }
}

Chat.defaultProps = {
  canComment: true,
};

Chat.propTypes = {
  messages: PropTypes.arrayOf(PropTypes.shape({
    isYou: PropTypes.bool,
    status: PropTypes.number,
    time: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ]),
    updated_at: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ]),
    deleted_at: PropTypes.oneOfType([ PropTypes.string, PropTypes.instanceOf(Date) ]),
    name: PropTypes.string,
    avatar: PropTypes.string,
    message: PropTypes.node,
  })).isRequired,
  users: PropTypes.array.isRequired,
  canComment: PropTypes.bool,
  lang: PropTypes.object,
  onSend: PropTypes.func.isRequired,
  onUpdateComment: PropTypes.func.isRequired,
  onDeleteComment: PropTypes.func.isRequired,
};

const mapStateToProps = (store) => {
  return {
    lang: store.language.lang,
    users: store.users,
  };
};

export default connect(mapStateToProps, null, null, { forwardRef: true })(Chat);
