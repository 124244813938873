import React from 'react';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';



const FormActions = ({ step, lastStep, onStepChange, onSubmit }) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  return (
    <div className="kt-form__actions" style={{ maxWidth: 1024, margin: 'auto' }}>
      {(step > 0) && (
        <Button
          name="prev_step"
          variant="secondary"
          className="kt-font-bold kt-font-transform-u"
          onClick={onStepChange}
        >
          {lang['GLOBAL.BACK']}
        </Button>
      )}
      {(step === lastStep) ? (
        <Button
          name="create_project"
          variant="success"
          className="kt-font-bold kt-font-transform-u"
          style={{ marginLeft: 'auto' }}
          onClick={onSubmit}
        >
          {lang['GLOBAL.CREATE']}
        </Button>
      ) : (
        <Button
          name="next_step"
          variant="brand"
          className="kt-font-bold kt-font-transform-u"
          style={{ marginLeft: 'auto' }}
          onClick={onStepChange}
        >
          {lang['GLOBAL.NEXT']}
        </Button>
      )}
    </div>
  );
};

FormActions.propTypes = {
  step: PropTypes.number.isRequired,
  lastStep: PropTypes.number.isRequired,
  onStepChange: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default FormActions;
