import React from 'react';
import { connect } from 'react-redux';



class HMenuItemInner extends React.Component {

  itemCssClassWithBullet (item, parentItem) {
    if (item.bullet) {
      return `kt-menu__link-bullet--${item.bullet}`;
    }

    if (parentItem && parentItem.bullet) {
      return `kt-menu__link-bullet--${parentItem.bullet}`;
    }

    return '';
  }

  render () {
    const { item, parentItem, lang } = this.props;

    return (
      <>
        {/* if menu item has icon */}
        {item.icon && (
          <i className={`kt-menu__link-icon ${item.icon}`} />
        )}

        {/* if menu item using bullet */}
        {(!item.icon && (item.bullet || (parentItem && parentItem.bullet))) && (
          <i className={`kt-menu__link-bullet ${this.itemCssClassWithBullet(item, parentItem)}`}><span /></i>
        )}

        {!item.badge ? (
          <>
            <span className="kt-menu__item-here" />
            {/* menu item title text */}
            <span className="kt-menu__link-text">{lang[item.translate] || item.title}</span>
          </>
        ) : (
          <>
            {/* menu item with badge */}
            <span className="kt-menu__link-text">{lang[item.translate] || item.title}</span>
            <span className="kt-menu__link-badge">
              <span className={`kt-badge kt-badge--brand kt-badge--inline kt-badge--pill ${item.badge.type} `}>
                {item.badge.value}
              </span>
            </span>
          </>
        )}
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};


export default connect(mapStoreToProps)(HMenuItemInner);
