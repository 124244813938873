import { filter } from 'lodash';
import React from 'react';
import { projectStatuses, projectTypes, typesFields } from '../projectTypes';
import DropFile from '../../../widgets/DropFile';
import taskTypes from '../../tasks/taskTypes';
import { getPriceByColorComplexityLevel, getPriceByContourComplexityLevel } from '../projectPrices';

/**
 * Builds data entry fields for creating a project
 *
 * @this {ProjectCreate}
 * @param {{}}project
 * @param {Function}setProject
 * @param {boolean}required
 * @returns {*}
 */
export default function BuildProject (project, setProject, required = true) {
  const { lang, config } = this.props;
  const projectType = projectTypes.find((type) => type.key === project.project_type);

  const requiredGeneralSettings = true;
  const type = config[project.project_type] || {};

  const prices = {};

  if (type && type.steps) {
    Object.values(type.steps).forEach((step, key) => {
      switch (step) {
        case taskTypes.artist_drawing.type:
          prices[key] = String(getPriceByContourComplexityLevel(project.contour_complexity_level));
          break;
        case taskTypes.designer_coloring.type:
          prices[key] = String(getPriceByColorComplexityLevel(project.color_complexity_level));
          break;
        default:
          prices[key] = 0;
      }
    });
  }

  return (
    <div
      className='row'
    >
      <div className='col-12'>
        <div
          className="kt-heading kt-heading--md kt-pl-10 kt-pr-10 w-100 kt-mt-20 kt-mb-20 kt-ml-a kt-mr-a"
          style={{ maxWidth: 1024 }}
        >
          {`${lang['PROJECT.GENERAL_SETTINGS']}`}
        </div>
      </div>
      <div className='col-xl-12'>
        <div className='kt-section__body'>
          <div className='row'>
            {projectType && (
              <>
                {([
                  ...projectType.generalSettings
                    .reduce((prev, cur) => {
                      if (cur.key === 'source') {
                        return [ ...prev, ...cur.addedFields.filter((item) => item.value === project.source) ];
                      }
                      prev.push(cur);
                      return prev;
                    }, [])
                    .map((field) => {
                      return this._buildField(field, project[field.key], (val, addedField) => {
                        let fieldKey = field.key;

                        if (addedField) {
                          fieldKey = addedField;
                        }

                        setProject({
                          ...project,
                          [fieldKey]: val,
                        });
                      }, project, null, requiredGeneralSettings);
                    }),
                ])}
              </>
            )}
            {projectType && (project.project_type === 'coloring-any' ? project.source : true) && (<>
              {projectType && projectType.projectSettings.map((field) => {
                if (project.project_type === 'coloring-artist' && project.visibility === 'on' && field.key === 'artist_drawing') {
                  return null;
                }
                return this._buildField(field, project[field.key], (val, addedField) => {
                  let fieldKey = field.key;

                  if (addedField) {
                    fieldKey = addedField;
                  }

                  const newProject = {
                    ...project,
                    [fieldKey]: val,
                  };

                  if (field.key === 'visibility' && val === 'on' && project.project_type === 'coloring-artist') {
                    // eslint-disable-next-line fp/no-delete
                    delete newProject.artist_drawing;
                  }

                  if (project.project_status === projectStatuses.reopened && field.key === 'color_complexity_level') {
                    newProject.projectTypeChanged = true;
                  }
                  setProject(newProject);
                }, project, null, required);
              })}
              <div className='col-12'>
                <div
                  className="form-group form-row row"
                  style={{
                    marginBottom: '1rem',
                    maxWidth: 1024,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                  }}
                >
                  <div className='col-lg-3 col-12'>
                    <label className="form-label col-form-label col">
                      {lang['PROJECT.SOURCE_FILES']}
                    </label>
                  </div>
                  <div className="col-12" style={{ paddingLeft: '15px' }}>
                    <DropFile
                      curFiles={project.sourceFile}
                      addFile={(file) => {
                        setProject({
                          ...project,
                          sourceFile: [ ...(project.sourceFile || []), file ],
                        });
                      }}
                      removeFile={(file) => {
                        setProject({
                          ...project,
                          sourceFile: filter(project.sourceFile, (item) => item !== file),
                        });
                      }}
                    />
                  </div>
                </div>
              </div>
              {prices.length > 0 && <>
                <div className='col-12'>
                  <div
                    className="kt-heading kt-heading--md kt-pl-10 kt-pr-10 w-100 kt-mt-20 kt-mb-20 kt-ml-a kt-mr-a"
                    style={{ maxWidth: 1024 }}
                  >
                    {`${lang['PROJECT.PRICE_SETTINGS']}`}
                  </div>
                </div>
                {prices.map((key, price) => {
                  return this._buildField(
                    {
                      key,
                      isRequired: true,
                      type: 'number',
                      typeFields: typesFields.input,
                      isFull: false,
                      title: `${lang[`CUSTOMER.TYPE.${price}`]}`,
                      muted: `${lang['PROJECT.PLEASE_ENTER_PRICE']}`,
                    },
                    project.prices[price],
                    (value) => {
                      setProject({
                        ...project,
                        prices: {
                          ...project.prices,
                          [price]: value,
                        },
                      });
                    },
                    project,
                    '€',
                    required
                  );
                })}
              </>}
            </>)}
          </div>
        </div>
      </div>
    </div>
  );
}
