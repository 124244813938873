import React from 'react';
import { orderBy } from 'lodash';
import PropTypes from 'prop-types';



const dateFormat = new Intl.DateTimeFormat('ru', {
  year: '2-digit',
  month: 'short',
  day: '2-digit',
});

const timeFormat = new Intl.DateTimeFormat('ru', {
  hour: '2-digit',
  minute: '2-digit',
});

/**
 * Component for history
 *
 * @returns {*}
 */
const History = ({ history = [], showDate }) => {
  return (
    <>
      <div className={`kt-timeline-v2 ${!showDate && 'kt-timeline-v2--no-date'}`}>
        <div className="kt-timeline-v2__items">
          {orderBy(history, [ (i) => (i.date ? i.date.getTime() : i.id), (i) => i.id ], [ 'desc', 'desc' ]).map((item, index) => {
            return (
              <div key={item.id || `i${index}`} className="kt-timeline-v2__item">
                <div className="kt-timeline-v2__item-cricle">
                  <i className={`fa fa-genderless kt-font-${item.color || 'dark'}`} />
                </div>
                {showDate && (
                  <span className="kt-timeline-v2__item-time">
                    <span>{`${timeFormat.format(item.date)}  `}</span>
                    <span className="kt-font-sm">{dateFormat.format(item.date)}</span>
                  </span>
                )}
                <div className="kt-timeline-v2__item-text  kt-pt-5">
                  <span className="text-muted" style={{ fontSize: '0.75rem' }}>
                    {item.title && `${item.title}${item.info ? ': ' : ''}`}
                  </span>
                  {item.info || ''}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

History.propTypes = {
  history: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.instanceOf(Date),
    id: PropTypes.number,
    color: PropTypes.oneOfType([ PropTypes.string, PropTypes.bool ]),
    title: PropTypes.string,
    info: PropTypes.node,
  })),
  showDate: PropTypes.bool,
};

History.defaultProps = {
  showDate: true,
};

export default History;
