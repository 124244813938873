import { providerIds } from '../../const/providerIds';
import appConfig from '../../config/app';



const getUsersOptionsList = (users) => {
  return users.filter((user) => {
    return !user.inactive && user.provider_id === providerIds[appConfig.x_provider];
  }).map((user) => ({
    value: user.id,
    label: user.username,
  })).sort((firstUser, secondUser) => {
    return firstUser.label.localeCompare(secondUser.label);
  });
};

export default getUsersOptionsList;
