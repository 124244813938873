import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import React from 'react';
import Modal from '../../../widgets/Modal';
import imageType from '../image_type';
import MyDatePicker from '../../../widgets/DatePicker';
import { formatDateTimeToString, formatDateToString, formatStringToDate } from '../../../utils/formats';
import MySelect from '../../../widgets/MySelect';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../../tasks/taskTypes';
import { addTagsToProject, removeTagsFromProject } from '../../../requests/tags';
import { addCategoriesToProject, removeCategoriesFromProject } from '../../../requests/categories';
import SelectTag from '../../../widgets/SelectTag';
import SelectCategories from '../../../widgets/SelectCategories';
import {
  colorComplexityLevels,
  contourComplexityLevels,
  getPriceByColorComplexityLevel,
  getPriceByContourComplexityLevel,
} from '../projectPrices';
import { floatValidator } from '../../../utils/validators';
import HtmlEditor from '../../../components/HtmlEditor';
import getTaskByTypeFromProject from '../helpers/getTaskByTypeFromProject';
import hcContentTypes from '../hcContentTypes';
import TagSystemPictureTagsModal from '../../tasks/modal/TagSystemPictureTagsModal';

/**
 * Builds modal windows for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
export default function Modals () {
  const { project, users, restart, archiveComment, unArchiveComment } = this.state;
  const { lang, user, categories } = this.props;
  const {
    uniqTags,
    canSetTags,
    projectCategories,
    canSetCategories,
    getArtistDeadline,
    getColoristDeadline,
  } = this.computed;

  const today = new Date();
  const highestComplexityLevel = 4;

  return (
    <>
      <Modal
        title={lang['PROJECT.CHANGE_CONTOUR_COMPLEXITY_LEVEL']}
        show={this.state.showModalEditContourComplexityLevel}
        close={() => {
          this.setState({
            showModalEditContourComplexityLevel: false,
            newContourComplexityLevel: project.options?.contour_complexity_level || '',
            newArtistPrice: typeof project.options?.artist_price === 'undefined' ? getPriceByContourComplexityLevel(project.options?.contour_complexity_level) : project.options?.artist_price,
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditContourComplexityLevel: false,
                  newContourComplexityLevel: project.options?.contour_complexity_level || '',
                  newArtistPrice: typeof project.options?.artist_price === 'undefined' ? getPriceByContourComplexityLevel(project.options?.contour_complexity_level) : project.options?.artist_price,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={(Number(this.state.newContourComplexityLevel) === Number(project.options?.contour_complexity_level) && Number(this.state.newContourComplexityLevel) !== highestComplexityLevel) || (Number(this.state.newContourComplexityLevel) === highestComplexityLevel && Number(this.state.newArtistPrice) === Number(project.options?.artist_price) && Number(this.state.newContourComplexityLevel) === Number(project.options?.contour_complexity_level))}
              onClick={() => {
                this._updateComplexityLevel(this.state.newContourComplexityLevel, 'contour_complexity_level', parseFloat(this.state.newArtistPrice));
                this.setState({
                  showModalEditContourComplexityLevel: false,
                  newContourComplexityLevel: this.state.newContourComplexityLevel,
                  newArtistPrice: parseFloat(this.state.newArtistPrice),
                  project: {
                    ...project,
                    options: {
                      ...project.options,
                      artist_price: parseFloat(this.state.newArtistPrice),
                    },
                  },
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['PROJECT.FIELDS.contour_complexity_level']}</Form.Label>
          <Form.Control
            as="select"
            value={this.state.newContourComplexityLevel}
            onChange={(event) => {
              this.setState({
                newContourComplexityLevel: event.target.value,
              });
            }}
          >
            {contourComplexityLevels.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
          {Number(this.state.newContourComplexityLevel) === highestComplexityLevel && (
            <>
              <Form.Label>{lang['PROJECT.FIELDS.artist_price']}</Form.Label>
              <Form.Control
                value={this.state.newArtistPrice}
                onChange={(event) => {
                  this.setState({
                    newArtistPrice: floatValidator(event.target.value),
                  });
                }}
              />
            </>
          )}
        </Form.Group>
      </Modal>

      <Modal
        title={lang['PROJECT.CHANGE_COLOR_COMPLEXITY_LEVEL']}
        show={this.state.showModalEditColorComplexityLevel}
        close={() => {
          this.setState({
            showModalEditColorComplexityLevel: false,
            newColorComplexityLevel: project.options?.color_complexity_level || '',
            newDesignerPrice: typeof project.options?.designer_price === 'undefined' ? getPriceByColorComplexityLevel(project.options?.color_complexity_level) : project.options?.designer_price,
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditColorComplexityLevel: false,
                  newColorComplexityLevel: project.options?.color_complexity_level || '',
                  newDesignerPrice: typeof project.options?.designer_price === 'undefined' ? getPriceByColorComplexityLevel(project.options?.color_complexity_level) : project.options?.designer_price,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={(Number(this.state.newColorComplexityLevel) === Number(project.options.color_complexity_level) && Number(this.state.newColorComplexityLevel) !== highestComplexityLevel) || (Number(this.state.newColorComplexityLevel) === highestComplexityLevel && (Number(this.state.newDesignerPrice) === Number(project.options?.designer_price) && Number(this.state.newColorComplexityLevel) === Number(project.options.color_complexity_level)))}
              onClick={() => {
                this._updateComplexityLevel(this.state.newColorComplexityLevel, 'color_complexity_level', parseFloat(this.state.newDesignerPrice));
                this.setState({
                  showModalEditColorComplexityLevel: false,
                  newColorComplexityLevel: this.state.newColorComplexityLevel,
                  newDesignerPrice: parseFloat(this.state.newDesignerPrice),
                  project: {
                    ...project,
                    options: {
                      ...project.options,
                      designer_price: parseFloat(this.state.newDesignerPrice),
                    },
                  },
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['PROJECT.FIELDS.color_complexity_level']}</Form.Label>
          <Form.Control
            as="select"
            value={this.state.newColorComplexityLevel}
            onChange={(event) => {
              this.setState({
                newColorComplexityLevel: event.target.value,
              });
            }}
          >
            {colorComplexityLevels.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
          {Number(this.state.newColorComplexityLevel) === highestComplexityLevel && (
            <>
              <Form.Label>{lang['PROJECT.FIELDS.designer_price']}</Form.Label>
              <Form.Control
                value={this.state.newDesignerPrice}
                onChange={(event) => {
                  this.setState({
                    newDesignerPrice: floatValidator(event.target.value),
                  });
                }}
              />
            </>
          )}
        </Form.Group>
      </Modal>

      <Modal
        title={lang['PROJECT.CHANGE_IMAGE_TYPE']}
        show={this.state.showModalEditType}
        close={() => {
          this.setState({
            showModalEditType: false,
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditType: false,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={!this.state.newType || this.state.newType === project.options.type}
              onClick={() => {
                if (this.state.newType && this.state.newType !== project.options.type) {
                  this._editImageCategoryProject(this.state.newType);
                  this.setState({
                    showModalEditType: false,
                    newType: '',
                  });
                }
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['PROJECT.FIELDS.type']}</Form.Label>
          <Form.Control
            as="select"
            value={this.state.newType || (project.options && project.options.type)}
            onChange={(event) => {
              this.setState({
                newType: event.target.value,
              });
            }}
          >
            {imageType.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal>
      <Modal
        title={lang['MENU.TAGS']}
        show={!!this.state.showModalTags}
        close={() => {
          this.setState({
            showModalTags: false,
          });
        }}
        bodyStyle={{ overflow: 'unset' }}
        footer={(
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({
                showModalTags: false,
              });
            }}
          >
            OK
          </Button>
        )}
      >
        <div className="d-flex justify-content-center flex-wrap">
          {uniqTags.map((tag) => (
            <Button
              key={tag.id}
              variant="label-dark-o2"
              size="sm"
              className="kt-ml-5 kt-mb-5 d-flex align-items-center"
            >
              {tag.name}
              {canSetTags && (
                <i
                  role="link"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="fa fa-times-circle hover kt-mr-0 kt-ml-5"
                  onClick={async () => {
                    await removeTagsFromProject([ tag.id ], project.id);
                    this.setState({
                      project: {
                        ...project,
                        tags: project.tags.filter((item) => item.id !== tag.id),
                      },
                    });
                  }}
                />
              )}
            </Button>
          ))}
        </div>
        {canSetTags && (
          <>
            <hr />
            <div className="d-flex flex-column">
              <Form.Group className="kt-mb-0">
                <Form.Label>{lang['TAGS.ADD']}</Form.Label>
                <SelectTag
                  filter={(tag) => project.tags.findIndex((item) => item.id === tag.value) === -1}
                  value={[]}
                  onChange={async (values, tags) => {
                    await addTagsToProject(values, project.id);

                    if (tags.length) {
                      this.setState({
                        project: {
                          ...project,
                          tags: [ {
                            user_id: user.id,
                            id: tags[0]?.value,
                            name: tags[0]?.label,
                            slug: tags[0]?.slug,
                          }, ...project.tags ],
                        },
                      });
                    }
                  }}
                />
              </Form.Group>
            </div>
          </>
        )}
      </Modal>
      <Modal
        title={lang['PROJECT.YOU_SURE_REMOVE']}
        show={this.state.modalRemove}
        close={() => {
          this.setState({
            modalRemove: false,
          });
        }}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalRemove: false,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </Button>
            <Button
              variant="danger"
              onClick={async () => {
                this._deleteProject();
              }}
            >
              {lang['PROJECT.CLOSE']}
            </Button>
          </>
        )}
      />
      <Modal
        title={lang['PROJECT.RESTARTING']}
        show={this.state.modalRestart}
        close={() => {
          this.setState({
            modalRestart: false,
          });
        }}
        footer={(
          <>
            {!project.is_published && restart.tasks?.find((item) => {
              const task = project.tasks?.find((_t) => _t.id === item.id);

              return task.executor_id && !item.executor_id;
            }) && (
              <Badge
                variant="info"
                className="text-wrap text-left d-flex align-items-center justify-content-start"
              >
                <i className="flaticon2-information kt-font-xl kt-mr-10" />
                <span>{lang['PROJECT.RESTARTING_ALERT']}</span>
              </Badge>
            )}
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalRestart: false,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </Button>
            <Button
              variant="warning"
              disabled={!restart.deadline_to}
              onClick={() => {
                this._restartProject();
              }}
            >
              {lang['PROJECT.RESTART']}
            </Button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>
            {lang['GLOBAL.DEADLINE']}
            <span style={{ color: 'red' }}>{' *'}</span>
          </Form.Label>
          <div>
            <MyDatePicker
              selected={restart.deadline_to ? formatStringToDate(restart.deadline_to) : ''}
              minDate={new Date()}
              minHours={formatDateToString(restart.deadline_to) === formatDateToString(today)
                ? today.getHours()
                : 0}
              position="bottom left"
              timepicker
              maxMinutes={0}
              onChange={(date) => {
                if (date) {
                  const time = date.getTime() % 86400000;

                  if (time < Date.now() % 86400000 && formatDateToString(date) === formatDateToString(new Date())) {
                    date = this._getDefaultDeadline();
                  } else {
                    date = formatDateTimeToString(date);
                  }
                  this.setState({
                    restart: {
                      ...restart,
                      deadline_to: date,
                    },
                  });
                } else {
                  this.setState({
                    restart: {
                      ...restart,
                      deadline_to: '',
                    },
                  });
                }
              }}
            />
          </div>
        </Form.Group>
        {restart.tasks && restart.tasks.map((task, index) => {
          const user = users.find((user) => user.id === task.executor_id);
          const value = user ? {
            value: user.id,
            label: user.username,
          } : '';

          return (
            <Form.Group key={task.id}>
              <Form.Label>{lang[`TASK.TYPES.${task.type}`]}</Form.Label>
              <MySelect
                isClearable
                value={value}
                options={users.filter((user) => checkRole(user.roles, [ taskTypes[task.type].role ])).map((user) => ({
                  value: user.id,
                  label: user.username,
                }))}
                onChange={(value) => {
                  const tasks = [ ...restart.tasks ];

                  tasks[index] = {
                    ...task,
                    executor_id: value ? value.value : '',
                  };

                  this.setState({
                    restart: {
                      ...restart,
                      tasks,
                    },
                  });
                }}
              />
            </Form.Group>
          );
        })}
      </Modal>
      <Modal
        title={lang['PROJECT.YOU_SURE_ARCHIVE']}
        show={this.state.modalArchive}
        close={() => {
          this.setState({
            modalArchive: false,
          });
        }}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalArchive: false,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </Button>
            <Button
              variant="warning"
              onClick={async () => {
                this._archiveProject();
              }}
            >
              {lang['PROJECT.ARCHIVE_PROJECT']}
            </Button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>
            {lang['GLOBAL.COMMENT']}
          </Form.Label>
          <HtmlEditor
            onChange={(value) => {
              this.setState({
                archiveComment: value,
              });
            }}
            initialValue={archiveComment || ''}
            init={{
              plugins: 'fullscreen',
              toolbar_mode: 'sliding',
              menubar: false,
            }}
          />
        </Form.Group>
      </Modal>
      <Modal
        title={lang['PROJECT.YOU_SURE_UNARCHIVE']}
        show={this.state.modalUnArchive}
        close={() => {
          this.setState({
            modalUnArchive: false,
          });
        }}
        footer={(
          <>
            <Button
              variant="secondary"
              onClick={() => {
                this.setState({
                  modalUnArchive: false,
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </Button>
            <Button
              variant="warning"
              onClick={async () => {
                this._unArchiveProject();
              }}
            >
              {lang['PROJECT.UNARCHIVE_PROJECT']}
            </Button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>
            {lang['GLOBAL.COMMENT']}
          </Form.Label>
          <HtmlEditor
            onChange={(value) => {
              this.setState({
                unArchiveComment: value,
              });
            }}
            initialValue={unArchiveComment || ''}
            init={{
              plugins: 'fullscreen',
              toolbar_mode: 'sliding',
              menubar: false,
            }}
          />
        </Form.Group>
      </Modal>
      <Modal
        title={lang['MENU.CATEGORIES']}
        show={!!this.state.showModalCategories}
        close={() => {
          this.setState({
            showModalCategories: false,
          });
        }}
        bodyStyle={{ overflow: 'unset' }}
        footer={(
          <Button
            variant="secondary"
            onClick={() => {
              this.setState({
                showModalCategories: false,
              });
            }}
          >
            OK
          </Button>
        )}
      >
        <div className="d-flex justify-content-center flex-wrap">
          {projectCategories.map((category) => (
            <Button
              key={category.id}
              variant="label-dark-o2"
              size="sm"
              className="kt-ml-5 kt-mb-5 d-flex align-items-center"
            >
              {category.name}
              {canSetCategories && (
                <i
                  role="link"
                  tabIndex={0}
                  onKeyDown={() => {}}
                  className="fa fa-times-circle hover kt-mr-0 kt-ml-5"
                  onClick={async () => {
                    const response = await removeCategoriesFromProject([ category.id ], project.id, this.props.notification);

                    if (response) {
                      this.setState({
                        project: {
                          ...project,
                          categories: projectCategories.filter((item) => item.id !== category.id),
                        },
                      });
                    }
                  }}
                />
              )}
            </Button>
          ))}
        </div>
        {canSetCategories && (
          <>
            <hr />
            <div className="d-flex flex-column">
              <Form.Group className="kt-mb-0">
                <Form.Label>{lang['TAGS.ADD_CATEGORY']}</Form.Label>
                <SelectCategories
                  filter={(category) => (categories.findIndex(
                    () => (category.value === project.options?.category_id)
                  ) === -1) && projectCategories.findIndex((item) => item.id === category.value) === -1}
                  value={[]}
                  onChange={async (values, projectCategories) => {
                    let response;

                    if (projectCategories.length) {
                      response = await addCategoriesToProject(values, project.id, this.props.notification);
                    }
                    if (response) {
                      this.setState({
                        project: {
                          ...project,
                          categories: [ {
                            user_id: user.id,
                            id: projectCategories[0]?.value,
                            name: projectCategories[0]?.label,
                            slug: projectCategories[0]?.slug,
                          }, ...project.categories ],
                        },
                      });
                    }
                  }}
                />
              </Form.Group>
            </div>
          </>
        )}
      </Modal>
      <Modal
        title={lang['PROJECT.YOU_SURE_CHANGE_PROJECT_DEADLINE']}
        show={this.state.showModalEditProjectDeadline}
        close={() => {
          this.setState({
            showModalEditProjectDeadline: false,
            newProjectDeadline: '',
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditProjectDeadline: false,
                  newProjectDeadline: '',
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={!this.state.newProjectDeadline}
              onClick={() => {
                this._updateProjectDeadline(this.state.newProjectDeadline);
                this.setState({
                  showModalEditProjectDeadline: false,
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['PROJECT.DEADLINE']}</Form.Label>
          <MyDatePicker
            selected={this.state.newProjectDeadline || (project.deadline_to ? formatStringToDate(project.deadline_to) : '')}
            minDate={new Date()}
            onChange={(date) => {
              if (date) {
                this.setState({
                  newProjectDeadline: date,
                });
              }
            }
            }
          />
        </Form.Group>
      </Modal>
      <Modal
        title={lang['TASK.YOU_SURE_CHANGE_ARTIST_DEADLINE']}
        show={this.state.showModalEditArtistDeadline}
        close={() => {
          this.setState({
            showModalEditArtistDeadline: false,
            newArtistDeadline: '',
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditArtistDeadline: false,
                  newArtistDeadline: '',
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={!this.state.newArtistDeadline}
              onClick={() => {
                this._updateTaskDeadline(
                  getTaskByTypeFromProject(project, taskTypes.artist_drawing.type)?.id,
                  this.state.newArtistDeadline,
                  taskTypes.artist_drawing.type
                );
                this.setState({
                  showModalEditArtistDeadline: false,
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['TASK.ARTIST_DEADLINE']}</Form.Label>
          <MyDatePicker
            selected={this.state.newArtistDeadline || getArtistDeadline}
            minDate={new Date()}
            maxDate={formatStringToDate(project.deadline_to)}
            onChange={(date) => {
              if (date) {
                this.setState({
                  newArtistDeadline: date,
                });
              }
            }
            }
          />
        </Form.Group>
      </Modal>
      <Modal
        title={lang['TASK.YOU_SURE_CHANGE_COLORIST_DEADLINE']}
        show={this.state.showModalEditColoristDeadline}
        close={() => {
          this.setState({
            showModalEditColoristDeadline: false,
            newColoristDeadline: '',
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditColoristDeadline: false,
                  newColoristDeadline: '',
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={!this.state.newColoristDeadline}
              onClick={() => {
                this._updateTaskDeadline(
                  getTaskByTypeFromProject(project, taskTypes.designer_coloring.type)?.id,
                  this.state.newColoristDeadline,
                  taskTypes.designer_coloring.type
                );
                this.setState({
                  showModalEditColoristDeadline: false,
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['TASK.COLORIST_DEADLINE']}</Form.Label>
          <MyDatePicker
            selected={this.state.newColoristDeadline || getColoristDeadline}
            minDate={new Date()}
            maxDate={formatStringToDate(project.deadline_to)}
            onChange={(date) => {
              if (date) {
                this.setState({
                  newColoristDeadline: date,
                });
              }
            }
            }
          />
        </Form.Group>
      </Modal>
      <Modal
        title={lang['PROJECT.YOU_SURE_CHANGE_HC_RELEASE_DATE']}
        show={this.state.showModalEditProjectHCReleaseDate}
        close={() => {
          this.setState({
            showModalEditProjectHCReleaseDate: false,
            newHCReleaseDate: '',
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditProjectHCReleaseDate: false,
                  newHCReleaseDate: '',
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              disabled={!this.state.newHCReleaseDate}
              onClick={() => {
                this._updateProjectHCReleaseDate(this.state.newHCReleaseDate);
                this.setState({
                  showModalEditProjectHCReleaseDate: false,
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['PROJECT.FIELDS.hc_release_date']}</Form.Label>
          <MyDatePicker
            selected={this.state.newHCReleaseDate || (project.hc_release_date ? formatStringToDate(project.hc_release_date) : '')}
            minDate={new Date()}
            onChange={(date) => {
              if (date) {
                this.setState({
                  newHCReleaseDate: date,
                });
              }
            }
            }
          />
        </Form.Group>
      </Modal>
      <Modal
        title={lang['PROJECT.YOU_SURE_CHANGE_HC_CONTENT_TYPE']}
        show={this.state.showModalEditProjectHCContentType}
        close={() => {
          this.setState({
            showModalEditProjectHCContentType: false,
            newHCContentType: '',
          });
        }}
        footer={(
          <>
            <button
              className="btn btn-secondary"
              onClick={() => {
                this.setState({
                  showModalEditProjectHCContentType: false,
                  newHCContentType: '',
                });
              }}
            >
              {lang['GLOBAL.CANCEL']}
            </button>
            <button
              className="btn btn-success"
              onClick={() => {
                this._updateProjectHCContentType(this.state.newHCContentType);
                this.setState({
                  showModalEditProjectHCContentType: false,
                });
              }}
            >
              {lang['GLOBAL.EDIT']}
            </button>
          </>
        )}
      >
        <Form.Group>
          <Form.Label>{lang['PROJECT.FIELDS.hc_content_type']}</Form.Label>
          <Form.Control
            as="select"
            value={this.state.newHCContentType}
            onChange={(event) => {
              this.setState({
                newHCContentType: event.target.value,
              });
            }}
          >
            <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
            {hcContentTypes.map((type) => (
              <option key={type} value={type}>{type}</option>
            ))}
          </Form.Control>
        </Form.Group>
      </Modal>
      <TagSystemPictureTagsModal
        showModalTagSystemPictureTags={this.state.showModalTagSystemPictureTags}
        setModalTagSystemPictureTags={this._setModalTagSystemPictureTags}
        picture={this._getTagSystemPicture()}
      />
    </>
  );
}
