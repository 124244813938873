import { typesFields } from '../projectTypes';
import {
  formatToPrettyDate,
} from '../../../utils/formats';
import { mapCategoryIdToName } from '../../../requests/categories';

/**
 * Iterates over a list of fields recursively and builds an array with their names and values
 *
 * @this ProjectCreate
 * @param {[]}fields
 * @param {any} field
 * @param {number} index
 * @param {any} value
 * @param {any} project
 * @returns {*}
 */
// eslint-disable-next-line no-unused-vars
export default function (fields, field, index = null, value = null, project = null) {
  if (!project) {
    project = this.state.project;
  }
  const { lang } = this.props;

  let _value = (value === null ? project[field.key || field.keys[0]] : value) || '';

  switch (field.typeFields) {
    case typesFields.options: {
      _value = lang[`PROJECT.FIELDS.${field.key || field.keys[0]}.${_value}`] || _value;
      break;
    }
    case typesFields.date: {
      _value = formatToPrettyDate(_value) || _value;
      break;
    }
    case typesFields.inputSelect: {
      if (field.key === 'tags') {
        _value = this.props.tags.filter((tag) => _value.includes(tag.id)).map((tag) => tag.name).join(', ');
      }
      if (field.key === 'categories') {
        _value = this.props.categories.filter((category) => _value.includes(category.id)).map((category) => category.name).join(', ');
      }
      break;
    }
    case typesFields.switch: {
      if (!_value) {
        _value = 'off';
      }
      if (field.key === 'visibility') {
        if (_value === 'off') {
          _value = lang['PROJECT.VISIBLE.PRIVATE'];
        } else if (_value === 'on') {
          _value = lang['PROJECT.VISIBLE.PUBLIC'];
        }
      }
      break;
    }
    case typesFields.optionsSearch: {
      if (field.key === 'category_id') {
        _value = mapCategoryIdToName(this.props.categories, _value);
      }

      const user = this.props.users.find((user) => user.id === _value);

      if (user) {
        _value = user.username;
      }

      break;
    }
    default: {
      break;
    }
  }

  fields.push({
    name: lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '',
    value: _value || '---',
  });

  // eslint-disable-next-line no-unused-expressions
  field.addedFields && this._filterAddedFields(field, project).map((field, index) => {
    return this._buildView(fields, field, index, null, project);
  });

  return fields;
}
