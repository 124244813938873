import React from 'react';

/**
 * Component for icon loading
 *
 * @returns {*}
 */
const IconLoad = () => {
  return (
    <svg
      className="spinner kt-ml-5"
      viewBox="0 0 50 50"
      style={{
        width: 15,
        height: 15,
      }}
    >
      <circle
        className="path"
        cx="25"
        cy="25"
        r="20"
        fill="none"
        style={{
          stroke: '#fff',
        }}
        strokeWidth="5"
      />
    </svg>
  );
};

export default IconLoad;
