import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';



const actions = {
  SET_COUNTRIES: 'SET COUNTRIES',
  setCountries: (payload) => ({
    type: actions.SET_COUNTRIES,
    payload,
  }),
  fetchCountries: () => async (dispatch, getStore, api) => {
    const store = getStore();

    try {
      const response = await api.get('countries');

      dispatch(actions.setCountries(response.data.countries));
    } catch {
      dispatch(actionsNotifications.add(
        store.language.lang['NOTIFICATION.FETCH_COUNTRIES_FAILED'],
        NOTIFICATIONS_TYPES.error
      ));
    }
  },
};

export default actions;

