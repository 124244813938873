import { complexityLevels } from '../complexityLevels';
import isDisabledProjectContourComplexity from '../../projects/helpers/isDisabledProjectContourComplexity';
import taskTypes from '../taskTypes';
import isDisabledProjectColorComplexity from '../../projects/helpers/isDisabledProjectColorComplexity';



const isDisabledComplexityChange = (task, type) => {
  return (
    type === complexityLevels.contour_complexity_level && isDisabledProjectContourComplexity(task.project, taskTypes.artist_drawing.type)
        ||
        type === complexityLevels.color_complexity_level && isDisabledProjectColorComplexity(task.project, taskTypes.designer_coloring.type)
  );
};

export default isDisabledComplexityChange;
