import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React from 'react';
import PropTypes from 'prop-types';
import { arrayOfIntValidatorWithoutSplit } from '../../../utils/validators';
import MyDatePicker from '../../../widgets/DatePicker';
import { formatDateToString, formatStringToDate } from '../../../utils/formats';
import priorities, { prioritiesMap } from '../../projects/priorities';
import MySelect from '../../../widgets/MySelect';
import imageType from '../../projects/image_type';
import SelectTag from '../../../widgets/SelectTag';
import { mapCategoryIdToName, mapCategoryNameToId } from '../../../requests/categories';
import { editorFilterTasksMap } from '../taskTypes';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import roles from '../../customers/roles/roles';
import { initialFilter } from '../../projects/list';
import hcContentTypes from '../../projects/hcContentTypes';

/**
 * Component for a block with filters for a page with a list of tasks
 *
 * @returns {*}
 */
export default function TasksListFilters () {
  const { filter } = this.state;

  const { lang, users } = this.props;

  const {
    isColoring,
    executors,
    showFilterFinishedAt,
    showFilterPrevExecutor,
  } = this.computed;

  const categoryIdValue = mapCategoryIdToName(this.props.categories, filter.category_id);

  return (filter.show && (
    <form
      className="col-12 filters kt-portlet kt-portlet--height-fluid"
      onSubmit={(event) => {
        event.preventDefault();
        this._applyFilters();
      }}
    >
      <div className="kt-portlet__body">
        <div className="row">
          <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
            <Form.Label>ID</Form.Label>
            <Form.Control
              type="text"
              value={filter.project}
              onChange={(event) => {
                this.setState({
                  filter: {
                    ...filter,
                    project: arrayOfIntValidatorWithoutSplit(event.target.value),
                  },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
            <Form.Label>{lang['GLOBAL.TITLE']}</Form.Label>
            <Form.Control
              type="text"
              value={filter.title}
              onChange={(event) => {
                this.setState({
                  filter: {
                    ...filter,
                    title: event.target.value,
                  },
                });
              }}
            />
          </Form.Group>
          <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
            <Form.Label>{lang['TASK.DEADLINE']}</Form.Label>
            <div className="row">
              <div className="col-6">
                <MyDatePicker
                  selected={filter.deadline.from ? formatStringToDate(filter.deadline.from) : ''}
                  placeholder={lang['GLOBAL.FROM']}
                  position="bottom left"
                  minDate={new Date(2017, 0, 1)}
                  onChange={(date) => {
                    this.setState({
                      filter: {
                        ...filter,
                        deadline: {
                          ...filter.deadline,
                          from: formatDateToString(date),
                          to: (date > new Date(filter.deadline.to)) ? formatDateToString(date) : filter.deadline.to,
                        },
                      },
                    });
                  }}
                />
              </div>
              <div className="col-6">
                <MyDatePicker
                  selected={filter.deadline.to ? formatStringToDate(filter.deadline.to) : ''}
                  placeholder={lang['GLOBAL.TO']}
                  position="bottom right"
                  minDate={new Date(2017, 0, 1)}
                  onChange={(date) => {
                    this.setState({
                      filter: {
                        ...filter,
                        deadline: {
                          ...filter.deadline,
                          to: formatDateToString(date),
                          from: (date < new Date(filter.deadline.from)) ? formatDateToString(date) : filter.deadline.from,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
            <Form.Label>{lang['PROJECT.FIELDS.hc_release_date']}</Form.Label>
            <div className="row">
              <div className="col-6">
                <MyDatePicker
                  selected={filter.project_hc_release_date?.from ? formatStringToDate(filter.project_hc_release_date.from) : ''}
                  placeholder={lang['GLOBAL.FROM']}
                  position="bottom right"
                  onChange={(date) => {
                    this.setState({
                      filter: {
                        ...filter,
                        project_hc_release_date: {
                          ...filter.project_hc_release_date,
                          from: formatDateToString(date),
                          to: (date > new Date(filter.project_hc_release_date.to)) ? formatDateToString(date) : filter.project_hc_release_date.to,
                        },
                      },
                    });
                  }}
                />
              </div>
              <div className="col-6">
                <MyDatePicker
                  selected={filter.project_hc_release_date?.to ? formatStringToDate(filter.project_hc_release_date.to) : ''}
                  placeholder={lang['GLOBAL.TO']}
                  position="bottom right"
                  onChange={(date) => {
                    this.setState({
                      filter: {
                        ...filter,
                        project_hc_release_date: {
                          ...filter.project_hc_release_date,
                          to: formatDateToString(date),
                          from: (date < new Date(filter.project_hc_release_date.from)) ? formatDateToString(date) : filter.project_hc_release_date.from,
                        },
                      },
                    });
                  }}
                />
              </div>
            </div>
          </Form.Group>
          <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
            <Form.Label>{lang['PROJECT.FIELDS.hc_content_type']}</Form.Label>
            <Form.Control
              as="select"
              value={filter.project_hc_content_type}
              onChange={(event) => {
                this.setState({
                  filter: {
                    ...filter,
                    project_hc_content_type: event.target.value,
                  },
                });
              }}
            >
              <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
              {hcContentTypes.map((hcContentType) => (
                <option
                  key={hcContentType}
                  value={hcContentType}
                >
                  {lang[`PROJECT.FIELDS.hc_content_type.${hcContentType}`]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {showFilterFinishedAt && (
            <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
              <Form.Label>{lang['GLOBAL.FINISHED_AT']}</Form.Label>
              <div className="row">
                <div className="col-6">
                  <MyDatePicker
                    selected={filter.finished_at?.from ? formatStringToDate(filter.finished_at.from) : ''}
                    placeholder={lang['GLOBAL.FROM']}
                    position="bottom left"
                    minDate={new Date(2017, 0, 1)}
                    onChange={(date) => {
                      this.setState({
                        filter: {
                          ...filter,
                          finished_at: {
                            ...filter.finished_at,
                            from: formatDateToString(date),
                          },
                        },
                      });
                    }}
                  />
                </div>
                <div className="col-6">
                  <MyDatePicker
                    selected={filter.finished_at?.to ? formatStringToDate(filter.finished_at.to) : ''}
                    placeholder={lang['GLOBAL.TO']}
                    position="bottom right"
                    minDate={new Date(2017, 0, 1)}
                    onChange={(date) => {
                      this.setState({
                        filter: {
                          ...filter,
                          finished_at: {
                            ...filter.finished_at,
                            to: formatDateToString(date),
                          },
                        },
                      });
                    }}
                  />
                </div>
              </div>
            </Form.Group>
          )}
          <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
            <Form.Label>{lang['GLOBAL.PRIORITY']}</Form.Label>
            <Form.Control
              as="select"
              value={filter.priority}
              onChange={(event) => {
                this.setState({
                  filter: {
                    ...filter,
                    priority: event.target.value,
                  },
                });
              }}
            >
              <option>{lang['GLOBAL.NO_CHOOSE']}</option>
              {priorities.map((priority) => (
                <option key={priority} value={prioritiesMap[priority]}>
                  {lang[`GLOBAL.PRIORITY.${prioritiesMap[priority]}`]}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
          {this.props.categories && (
            <Form.Group className="col-xl-3 col-lg-6 col-md-6 col-12 kt-mb-5">
              <Form.Label>
                {lang['GLOBAL.CATEGORY']}
              </Form.Label>
              <Form.Control
                as="select"
                value={categoryIdValue}
                onChange={(event) => {
                  const categoryId = mapCategoryNameToId(this.props.categories, event.target.value);

                  this.setState({
                    filter: {
                      ...filter,
                      category_id: categoryId,
                    },
                  });
                }}
              >
                <option value="" />
                {this.props.categories.map((item) => (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}
          {showFilterPrevExecutor && (
            <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
              <Form.Label>{lang['GLOBAL.EXECUTOR']}</Form.Label>
              <MySelect
                isClearable
                value={(() => {
                  if (!filter.prev_executor) {
                    return '';
                  }
                  const user = executors.find((user) => user.id === filter.prev_executor);

                  if (user) {
                    return {
                      value: user.id,
                      label: user.username,
                    };
                  }

                  return '';
                })()}
                options={executors.map((user) => ({
                  value: user.id,
                  label: user.username,
                }))}
                onChange={(value) => {
                  this.setState({
                    filter: {
                      ...filter,
                      prev_executor: value ? Number(value.value) : '',
                    },
                  });
                }}
              />
            </Form.Group>
          )}
          {this.props.match.params.status && (
            <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
              <Form.Label>{lang['GLOBAL.EXECUTOR']}</Form.Label>
              <MySelect
                isClearable
                value={(() => {
                  if (!filter.executor) {
                    return '';
                  }

                  const user = users.find((user) => user.id === filter.executor);

                  if (user) {
                    return {
                      value: user.id,
                      label: user.username,
                    };
                  }

                  return '';
                })()}
                options={users.map((user) => ({
                  value: user.id,
                  label: user.username,
                }))}
                onChange={(value) => {
                  this.setState({
                    filter: {
                      ...filter,
                      executor: value ? Number(value.value) : '',
                    },
                  });
                }}
              />
            </Form.Group>
          )}
          {isColoring && (
            <>
              <Form.Group className="col-lg-3 col-md-6 col-12 kt-mb-5">
                <Form.Label>{lang['PROJECT.FIELDS.type']}</Form.Label>
                <Form.Control
                  as="select"
                  value={filter.image_type}
                  onChange={(event) => {
                    this.setState({
                      filter: {
                        ...filter,
                        image_type: event.target.value,
                      },
                    });
                  }}
                >
                  <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                  {imageType.map((cat) => (
                    <option key={cat} value={cat}>
                      {lang[`PROJECT.FIELDS.type.${cat}`]}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>
              <Form.Group
                className={`${this.props.match.params.status
                  ? (showFilterPrevExecutor
                    ? 'col-lg-3 col-md-6'
                    : 'col-lg-6 col-md-12'
                  )
                  : (showFilterPrevExecutor
                    ? 'col-lg-6 col-md-12'
                    : 'col-lg-9 col-md-6'
                  )
                } col-12 kt-mb-5`}
              >
                <Form.Label>{lang['MENU.TAGS']}</Form.Label>
                <SelectTag
                  canCreate={false}
                  value={filter.tags_id}
                  onChange={(id, tags) => {
                    this.setState({
                      filter: {
                        ...filter,
                        tags: tags.map((tag) => tag.slug),
                        tags_id: id,
                      },
                    });
                  }}
                />
              </Form.Group>
            </>
          )}
          {checkRole(this.props.userRoles, [ ...groupsRoles.admin, ...groupsRoles.editors.all ]) && (
            <Form.Group className="col-xl-3 col-lg-6 col-md-6 col-12 kt-mb-5">
              <Form.Label>{lang['TASK.STATUS']}</Form.Label>
              <Form.Control
                as="select"
                value={filter.type}
                onChange={(event) => {
                  this.setState({
                    filter: {
                      ...filter,
                      type: event.target.value,
                    },
                  });
                }}
              >
                <option value="">{lang['GLOBAL.NO_CHOOSE']}</option>
                {editorFilterTasksMap.map((taskType) => (
                  <option key={taskType} value={taskType || null}>
                    {lang[`TASK.TYPES.${taskType}`]}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          {checkRole(this.props.userRoles, [ roles['content-manager'].key, roles.administrator.key, ...groupsRoles.editors.all ]) && (
            <Form.Group className="col-xl-3 col-lg-6 col-md-6 col-12 kt-mb-5">
              <Form.Label>{lang['PROJECT.TAPS_COUNT']}</Form.Label>
              <Form.Control
                as="select"
                value={`${filter.taps.from}-${filter.taps.to}`}
                onChange={(event) => {
                  const preparedFilter = { ...filter };

                  if (!event.target.value) {
                    preparedFilter.taps = { ...initialFilter.taps };
                  } else {
                    const per = event.target.value.split('-');

                    preparedFilter.taps = {
                      from: per[0],
                      to: per[1],
                    };
                  }

                  this.setState({
                    filter: preparedFilter,
                  });
                }}
              >
                <option value="-">{lang['GLOBAL.NO_CHOOSE']}</option>
                <option value="1-300">1-300</option>
                <option value="301-500">301-500</option>
                <option value="501-700">501-700</option>
                <option value="701-1000">701-1000</option>
                <option value="1001-10000">1001 {lang['GLOBAL.AND_MORE']}</option>
              </Form.Control>
            </Form.Group>
          )}

          <div className="col-12 kt-mb-5">
            <div className="float-right kt-mt-20">
              <Button type="submit">
                {lang['GLOBAL.APPLY']}
              </Button>
              <Button
                as="span"
                className="kt-ml-20"
                variant="secondary"
                onClick={() => {
                  this._resetFilters();
                }}
              >
                {lang['GLOBAL.RESET']}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
  );
}

export const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  categories: store.categories,
  users: store.users,
  tags: store.tags,
  userRoles: store.user.roles,
});

TasksListFilters.propTypes = {
  lang: PropTypes.object,
  isColoring: PropTypes.any,
  showFilterPrevExecutor: PropTypes.any,
  executors: PropTypes.any,
  filter: PropTypes.any,
  users: PropTypes.any,
  setFilter: PropTypes.any,
  applyFilters: PropTypes.any,
  resetFilters: PropTypes.any,
  showFilterFinishedAt: PropTypes.any,
  categories: PropTypes.any,
  match: PropTypes.any,
  userRoles: PropTypes.any,
};
