import { sortBy } from 'lodash';
import actions from './actions';



const initialState = {
  loading: false,
  manual: {},
  manuals: [],
};

export const manualsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.SET_MANUAL: {
      return {
        ...state,
        manual: action.payload,
      };
    }
    case actions.SET_MANUALS: {
      const items = [ ...action.payload ];

      return { ...state, manuals: sortBy(items, [ (item) => item.published_at ]) };
    }
    case actions.UPDATE_MANUAL: {
      return {
        ...state,
        manuals: state.manuals.map((manual) => {
          if (manual.id === action.payload.id) {
            return {
              ...manual,
              ...action.payload,
            };
          }

          return manual;
        }),
      };
    }
    case actions.DELETE_MANUAL: {
      return { ...state, manuals: state.manuals.filter((manual) => Number(manual.id) !== Number(action.payload)) };
    }
    default: {
      return state;
    }
  }
};
