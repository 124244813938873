import { array, object } from 'yup';
import { orderBy, uniqBy } from 'lodash';
import sortCaseInsensitive from '../../../../utils/SortCaseInsensitive';
import { entityTypes } from '../../config/config';



const getTemplateOptions = (templates) => {
  if (!templates || !templates.length) {
    return [];
  }
  return orderBy(templates, sortCaseInsensitive([ 'template_name' ]), [ 'asc' ]).map((template) => ({
    value: template?.id,
    label: template?.template_name,
  }));
};

const getGroupOptions = (items, formValues) => {
  if (!items || !items.length) {
    return [];
  }

  return items
    // filter child tags. Show it in options only if parent tag is selected in form
    .filter((item) => {
      if (!item.parent_id) {
        return true;
      }
      if (item.parent_id) {
        let isFound = false;

        Object.entries(formValues).forEach(([ key, value ]) => {
          if (key === 'templates' || isFound) {
            return;
          }
          if (Array.isArray(value)) {
            value.forEach((formItem) => {
              if (Number(formItem?.value) === Number(item?.parent_id)) {
                isFound = true;
              }
            });
          } else if (Number(value?.value) === Number(item?.parent_id)) {
            isFound = true;
          }
        });

        return isFound;
      }

      return true;
    })
    .sort((first, second) => first.name.localeCompare(second.name, undefined, { numeric: true, sensitivity: 'base' }))
    .map((item) => ({
      value: item?.id,
      label: item?.name,
    }));
};

const getAIOptions = (formValues, aiTags, group) => {
  if (!(group.subtype in aiTags)) return [];

  const formKey = Object.keys(formValues).find((key) => key.includes(`${group.id}_${group.name}`));

  return aiTags[group.subtype]
    ?.filter((item) => {
      if (!formValues[formKey]) return true;

      if (Array.isArray(formValues[formKey])) {
        return !formValues[formKey]?.some((formItem) => formItem.label === item);
      }

      return item !== formValues[formKey]?.label;
    })
    .filter((item) => {
      // ugly clean up
      if (item === 'uncategorized') {
        return false;
      }
      return group.tags?.some((gTag) => gTag.name === item);
    })
    .slice(0, 5);
};

const globalGroups = (data, formValues) => {
  const selectedTemplates = formValues?.templates?.map((item) => item.value) || [];
  const templates = data.filter((template) => selectedTemplates.includes(template.id));
  const filteredGroups = [];

  templates?.forEach((template) => {
    template.groups?.forEach((group) => {
      const globalOption = group.options?.find((option) => option.name === 'is_global');

      if (globalOption && globalOption?.value) {
        filteredGroups.push(group);
      }
    });
  });

  return filteredGroups.filter((group, index, self) => index === self.findIndex((gr) => gr.id === group.id));
};

const currentTemplates = (data, formValues) => {
  const selectedTemplates = formValues?.templates?.map((item) => item.value) || [];

  return data.filter((template) => selectedTemplates.includes(template.id));
};

const templateLocalGroups = (groups) => {
  return groups.filter((group) => !group.isGlobal);
};

const getAllTags = (entities) => {
  const tags = [];

  const findTags = (items) => {
    items.forEach((item) => {
      if (item.type === entityTypes.tag) {
        tags.push(item);
      }
      if (item.children) {
        findTags(item.children); // Рекурсивно обходим вложенные элементы
      }
    });
  };

  findTags(entities);

  return uniqBy(tags, 'id');
};

const getUniqTagsFromTagSystemPicture = (tagSystemPictureTags) => {
  if (!tagSystemPictureTags?.entities?.length) {
    return [];
  }

  return getAllTags(tagSystemPictureTags.entities);
};

export const isOptionEnabled = (options, optionName) => {
  const option = options?.find((option) => option.name === optionName);

  return !!(option && option.value);
};

const prepareValidationRules = (formValues, pictureStructures) => {
  const validationRules = {};

  formValues.templates?.forEach((template) => {
    const templateData = pictureStructures.find((tmpl) => Number(tmpl.id) === Number(template.value));

    Object.entries(formValues).forEach(([ key ]) => {
      if (key !== 'templates') {
        const parts = key.split('_'); // todo improve this check
        const group = templateData?.groups?.find((group) => Number(group.id) === Number(parts[1]));

        if (!group) {
          return;
        }

        if (isOptionEnabled(group?.options || [], 'is_required')) {
          if (isOptionEnabled(group?.options || [], 'is_multiple')) {
            validationRules[key] = array().required();
          } else {
            validationRules[key] = object().required();
          }
        }
      }
    });
  });

  return validationRules;
};

const mapChildrenToParent = (curTemplates, formItem, parentsChildMap) => {
  let isFound = false;

  if (!formItem?.value) {
    return;
  }

  curTemplates?.forEach((template) => {
    if (isFound) {
      return;
    }
    template?.groups?.forEach((group) => {
      if (isFound) {
        return;
      }
      group?.tags?.forEach((tag) => {
        if (Number(tag.id) === Number(formItem.value) && tag.parent_id) {
          if (!parentsChildMap[tag.parent_id]) {
            parentsChildMap[tag.parent_id] = [];
          }
          parentsChildMap[tag.parent_id].push(tag.id);
          isFound = true;
        }
      });
    });
  });
};

const cleanUpFormValues = (formValues, parentChildrenMap, setValue) => {
  const cleanedFormValues = { ...formValues };

  Object.entries(parentChildrenMap).forEach(([ parent, children ]) => {
    let isFound = false;

    Object.entries(formValues).forEach(([ key, value ]) => {
      if (key === 'templates' || isFound) {
        return;
      }
      if (Array.isArray(value)) {
        value.forEach((formItem) => {
          if (Number(parent) === Number(formItem?.value)) {
            isFound = true;
          }
        });
      } else if (Number(parent) === Number(value?.value)) {
        isFound = true;
      }
    });

    if (!isFound) {
      children.forEach((child) => {
        Object.entries(cleanedFormValues).forEach(([ key, value ]) => {
          if (key === 'templates' || isFound) {
            return;
          }
          if (Array.isArray(value)) {
            const cleanedFormValue = value.filter((formItem) => {
              return Number(child) !== Number(formItem?.value);
            });

            setValue(key, cleanedFormValue, { shouldDirty: true });
          } else if (Number(child) === Number(value?.value)) {
            setValue(key, null, { shouldDirty: true });
          }
        });
      });
    }
  });

  return cleanedFormValues;
};

const cleanUpFormValuesAfterTemplateRemoved = (formValues, pictureStructures, setValue) => {
  if (!formValues.templates?.length) {
    Object.entries(formValues).forEach(([ key, _value ]) => {
      if (key === 'templates') {
        return;
      }

      setValue(key, null, { shouldDirty: true });
    });
  } else {
    Object.entries(formValues).forEach(([ key, value ]) => {
      let isFound = false;

      if (key === 'templates' && value) {
        return;
      }
      const keyParts = key.split('_');
      const groupID = Number(keyParts[1] ?? 0);

      formValues.templates?.forEach((template) => {
        const curTemplate = pictureStructures.find((tpl) => tpl.id === Number(template.value));

        if (curTemplate) {
          curTemplate?.groups?.forEach((group) => {
            let search = null;

            if (isFound || group.id !== groupID) {
              return;
            }

            if (Array.isArray(value)) {
              search = Number(value[0]?.value);
            } else {
              search = Number(value?.value);
            }
            const tag = group?.tags?.find((tag) => tag.id === search);

            if (tag) {
              isFound = true;
            }
          });
        }
      });

      if (!isFound) {
        setValue(key, null, { shouldDirty: true });
      }
    });
  }
};

const cleanUpChildTags = (formValues, pictureStructures, setValue) => {
  const curTemplates = currentTemplates(pictureStructures, formValues);

  const entries = Object.entries(formValues || []);
  const parentChildrenMap = {};

  entries.forEach(([ key, value ]) => {
    if (key === 'templates') {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((formItem) => {
        mapChildrenToParent(curTemplates, formItem, parentChildrenMap);
      });
    } else {
      mapChildrenToParent(curTemplates, value, parentChildrenMap);
    }
  });

  cleanUpFormValues(formValues, parentChildrenMap, setValue);
  cleanUpFormValuesAfterTemplateRemoved(formValues, pictureStructures, setValue);
};

const getValuesFromFormField = (value) => {
  const result = [];

  if (!value) {
    return result;
  }

  if (Array.isArray(value)) {
    value.forEach((item) => {
      result.push(item?.value);
    });
  } else {
    result.push(value?.value);
  }

  return result;
};

export {
  currentTemplates,
  getTemplateOptions,
  getGroupOptions,
  getAIOptions,
  globalGroups,
  templateLocalGroups,
  prepareValidationRules,
  cleanUpChildTags,
  getUniqTagsFromTagSystemPicture,
  getValuesFromFormField,
};
