import checkRole from '../../utils/checkRole';
import actions from './actions';



const initialState = {
  loading: false,
  freelancers: [],
};

export const freelancersReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.SET_FREELANCERS:
      return { ...state, freelancers: action.payload };
    case actions.FILTER_FREELANCERS:
      if (Object.keys(action.payload).length) {
        const queryParamsToExclude = [ 'skip', 'id', 'sort' ];

        let filteredFreelancers = state.freelancers;

        Object.keys(action.payload).filter((param) => !queryParamsToExclude.includes(param)).forEach(
          (param) => {
            switch (param) {
              case 'name':
                filteredFreelancers = filteredFreelancers.filter(
                  (freelancer) => `${freelancer.username}`.toLowerCase() === action.payload[param].toLowerCase(),
                );
                break;
              case 'country':
                filteredFreelancers = filteredFreelancers.filter(
                  (freelancer) => freelancer.personal_info.country === action.payload[param],
                );
                break;
              case 'inactive':
                filteredFreelancers = filteredFreelancers.filter(
                  (freelancer) => freelancer.inactive === Boolean(action.payload[param]),
                );
                break;
              case 'role':
                filteredFreelancers = filteredFreelancers.filter(
                  (freelancer) => {
                    const res = action.payload[param].map((allowedRole) => {
                      return checkRole(freelancer.roles, allowedRole);
                    });

                    return res.indexOf(false) === -1;
                  },
                );
                break;

              default:
                filteredFreelancers = filteredFreelancers.filter(
                  (freelancer) => (freelancer[param]) === action.payload[param],
                );
                break;
            }
          },
        );

        return { ...state, freelancers: filteredFreelancers };
      }

      return state;
    default:
      return state;
  }
};
