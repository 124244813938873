import { entitySubtypes } from '../config/config';



class GroupEntity {
  constructor (entity) {
    this.entity = entity;

    // Прокси для динамической обработки геттеров
    // eslint-disable-next-line no-constructor-return
    return new Proxy(this, {
      get: (target, prop) => {
        // Если у класса есть собственный метод или свойство, вернуть его
        if (prop in target) {
          return target[prop];
        }

        // Если свойство находится в entity, вернуть его значение
        if (prop in target.entity) {
          return target.entity[prop];
        }

        // Если свойство не найдено, вернуть undefined
        return undefined;
      },
    });
  }

  get deletable () {
    if ([ entitySubtypes.main, entitySubtypes.usual, entitySubtypes.cloud ].includes(this.entity.subtype)) {
      return false;
    }

    const canBeDeleted = this.entity.options?.find((option) => option.name === 'can_be_deleted');

    return (this.entity?.children || [])?.length === 0 && canBeDeleted?.value;
  }

  get protected () {
    return [ entitySubtypes.main, entitySubtypes.usual, entitySubtypes.cloud ].includes(this.entity.subtype);
  }

  get hasChildren () {
    return this.entity?.children?.length > 0;
  }

  get isGlobal () {
    return this.#filterBy('is_global');
  }

  get creatable () {
    return this.#filterBy('allow_fast_creation');
  }

  get isDependent () {
    return this.#filterBy('is_dependent');
  }

  get isMultiple () {
    return this.#filterBy('is_multiple');
  }

  get isRequired () {
    return this.#filterBy('is_required');
  }

  get useAiSuggestions () {
    return this.#filterBy('use_ai_suggestions');
  }

  get fieldName () {
    return `${this.entity.id}_${this.entity.name}`;
  }

  get parentSubtype () {
    if (this.entity.subtype === entitySubtypes.usual) {
      return entitySubtypes.main;
    }

    if (this.entity.subtype === entitySubtypes.cloud) {
      return entitySubtypes.usual;
    }

    return null;
  }

  #filterBy (fieldName) {
    const option = this.entity?.options?.find((option) => option.name === fieldName);

    return !!(option && option.value);
  }
}

export default GroupEntity;
