/**
 * List of user roles
 *
 * @type {{[key: string]: {
 *     key: string,
 *     title: string,
 * }}}
 */
const roles = {
  administrator: {
    key: 'administrator',
    title: 'Administrator',
  },
  user: {
    key: 'user',
    title: 'User',
  },
  employee: {
    key: 'employee',
    title: 'Employee',
  },
  manager: {
    key: 'manager',
    title: 'Manager',
  },
  freelancer: {
    key: 'freelancer',
    title: 'Freelancer',
  },
  'content-manager': {
    key: 'content-manager',
    title: 'Content Manager',
  },
  'coloring-artist': {
    key: 'coloring-artist',
    title: 'Artist',
  },
  'coloring-designer': {
    key: 'coloring-designer',
    title: 'Colorist',
  },
  'coloring-tester': {
    key: 'coloring-tester',
    title: 'Tester',
  },
  'lead-coloring-tester': {
    key: 'lead-coloring-tester',
    title: 'Lead Tester',
  },
  'remote-coloring-editor': {
    key: 'remote-coloring-editor',
    title: 'Remote Editor',
  },
  'coloring-editor': {
    key: 'coloring-editor',
    title: 'Editor',
  },
  'lead-coloring-editor': {
    key: 'lead-coloring-editor',
    title: 'Lead Editor',
  },
};

const roleKeys = [];

Object.keys(roles).forEach((key) => {
  roleKeys.push(key);
});

export const realRoles = [
  roles['content-manager'].key,
  roles['coloring-artist'].key,
  roles['coloring-designer'].key,
  roles['coloring-tester'].key,
  roles['remote-coloring-editor'].key,
  roles['coloring-editor'].key,
  roles['lead-coloring-editor'].key,
];

export const executorsRoles = [
  roles['coloring-artist'].key,
  roles['coloring-designer'].key,
  roles['coloring-tester'].key,
  roles['remote-coloring-editor'].key,
  roles['coloring-editor'].key,
  roles['lead-coloring-editor'].key,
];

export const rolesKeys = roleKeys;

export default roles;
