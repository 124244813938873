import React, { useState } from 'react';
import { Form } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';



const BonusForm = ({ onSubmit, onCancel, bonus = { reason: '', amount: 0 } }) => {
  const lang = useSelector((state) => state.language.lang);
  const [ formData, setFormData ] = useState(bonus);

  const handleInputChange = (event) => {
    event.persist();
    setFormData((prevState) => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));
  };

  const submitForm = () => {
    onSubmit(formData);
  };

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Label>{lang.BONUS}</Form.Label>
          <Form.Control
            name="amount"
            type="number"
            min={1}
            value={formData.amount}
            onChange={handleInputChange}
          />
        </Form.Group>
        <Form.Group>
          <Form.Label>{lang['TASK.BONUS_REASON']}</Form.Label>
          <Form.Control
            name="reason"
            type="text"
            disabled={!formData.amount}
            className="kt-mb-15"
            value={formData.reason}
            onChange={handleInputChange}
          />
        </Form.Group>
      </Form>
      <div className="modal-footer">
        <button
          className="btn btn-secondary"
          onClick={onCancel}
        >
          {lang['GLOBAL.CANCEL']}
        </button>
        <button
          className="btn btn-info"
          disabled={Number(formData.amount) <= 0 || !formData.reason}
          onClick={submitForm}
        >{bonus.id ? lang['GLOBAL.EDIT'] : lang['GLOBAL.CREATE']}</button>
      </div>
    </>
  );
};

BonusForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  bonus: PropTypes.object,
};

export default BonusForm;
