import React, { useEffect, useState } from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Form from 'react-bootstrap/Form';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import useQueryParams from './useQueryParams';



const usePagination = ({ totalItems, itemsPerPage = 10, initialPage = 1, pageRangeDisplayed = 5 }) => {
  const updateQueryParams = useQueryParams();
  const location = useLocation();
  const currentQuery = queryString.parse(location.search);
  const [ currentPage, setCurrentPage ] = useState(initialPage);
  const [ itemsPerPageState, setItemsPerPage ] = useState(itemsPerPage);

  const totalPages = totalItems ? Math.ceil(totalItems / itemsPerPageState) : 1;

  useEffect(() => {
    const params = { page: 1, limit: itemsPerPage };

    if (currentQuery.page) {
      params.page = Number(currentQuery.page);
      setCurrentPage(params.page);
    }

    if (currentQuery.limit) {
      params.limit = Number(currentQuery.limit);
      setItemsPerPage(params.limit);
    }

    updateQueryParams(params);
  }, []);

  const nextPage = () => {
    setCurrentPage((prevPage) => {
      const page = Math.min(prevPage + 1, totalPages);

      updateQueryParams({ page });
      return page;
    });
  };

  const prevPage = () => {
    setCurrentPage((prevPage) => {
      const page = Math.max(prevPage - 1, 1);

      updateQueryParams({ page });
      return page;
    });
  };

  const goToPage = (pageNumber) => {
    const page = Math.max(1, Math.min(pageNumber, totalPages));

    updateQueryParams({ page });
    setCurrentPage(page);
  };

  const changeItemsPerPage = (newItemsPerPage) => {
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
    updateQueryParams({ limit: newItemsPerPage, page: 1 });
  };

  const getPageNumbers = () => {
    const pages = [];

    // Если страниц меньше, чем pageRangeDisplayed, показываем все страницы
    if (totalPages <= pageRangeDisplayed) {
      // eslint-disable-next-line fp/no-loops
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    } else {
      const startPage = Math.max(1, currentPage - Math.floor(pageRangeDisplayed / 2));
      const endPage = Math.min(totalPages, currentPage + Math.floor(pageRangeDisplayed / 2));

      // Добавляем первую страницу
      if (startPage > 1) {
        pages.push(1);
        if (startPage > 2) pages.push('ellipsis-start');
      }

      // Добавляем диапазон страниц
      // eslint-disable-next-line fp/no-loops
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }

      // Добавляем последнюю страницу
      if (endPage < totalPages) {
        if (endPage < totalPages - 1) pages.push('ellipsis-end');
        pages.push(totalPages);
      }
    }

    return pages;
  };

  const PaginationComponent = () => (
    <div className="d-flex justify-content-between align-items-center">
      <Pagination className="mb-0">
        <Pagination.Prev
          key={'prev'}
          onClick={prevPage}
          disabled={currentPage === 1}
        />

        {getPageNumbers().map((page) => {
          if (page === 'ellipsis-start' || page === 'ellipsis-end') {
            return <Pagination.Ellipsis key={page} />;
          }
          return (
            <Pagination.Item
              key={page}
              active={currentPage === page}
              onClick={() => goToPage(page)}
            >
              {page}
            </Pagination.Item>
          );
        })}

        <Pagination.Next
          key="next"
          onClick={nextPage}
          disabled={currentPage === totalPages}
        />
      </Pagination>

      <Form.Control
        as="select"
        size="sm"
        value={itemsPerPageState}
        onChange={(ev) => changeItemsPerPage(Number(ev.target.value))}
        className="ml-3"
        style={{ width: 'auto' }}
      >
        <option value={10}>10</option>
        <option value={20}>20</option>
        <option value={50}>50</option>
        <option value={100}>100</option>
      </Form.Control>
    </div>
  );

  return {
    currentPage,
    itemsPerPage: itemsPerPageState,
    totalPages,
    setCurrentPage,
    nextPage,
    prevPage,
    goToPage,
    changeItemsPerPage,
    PaginationComponent,
  };
};

export default usePagination;
