import axiosApiInstance from '../../../requests/utils/api';
import { actionsNotifications } from '../../notification';
import { NOTIFICATIONS_TYPES } from '../../../widgets/Notification';
import trimObjectStringProps from '../../../utils/trimObjectStringProps';
import { tagSystemPath } from '../helpers';
import handleResponseMessage from '../../../utils/handleResponseMessage';



const actions = {
  LOADING_TEMPLATE: 'LOADING_TEMPLATE',
  LOADING_TEMPLATES: 'LOADING_TEMPLATES',
  LOADING_TEMPLATE_FORM: 'LOADING_TEMPLATE_FORM',
  ADD_TEMPLATE: 'ADD_TEMPLATE',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_TEMPLATES: 'SET_TEMPLATES',
  SET_TEMPLATE_FORM: 'SET_TEMPLATE_FORM',
  UPDATE_TEMPLATE: 'UPDATE_TEMPLATE',
  DELETE_TEMPLATE: 'DELETE_TEMPLATE',
  setLoadingTemplate: (payload) => ({
    type: actions.LOADING_TEMPLATE,
    payload,
  }),
  setLoadingTemplates: (payload) => ({
    type: actions.LOADING_TEMPLATES,
    payload,
  }),
  setLoadingTemplateForm: (payload) => ({
    type: actions.LOADING_TEMPLATE_FORM,
    payload,
  }),
  addTemplate: (payload) => ({
    type: actions.ADD_TEMPLATE,
    payload,
  }),
  setTemplate: (payload) => ({
    type: actions.SET_TEMPLATE,
    payload,
  }),
  setTemplates: (payload) => ({
    type: actions.SET_TEMPLATES,
    payload,
  }),
  setTemplateForm: (payload) => ({
    type: actions.SET_TEMPLATE_FORM,
    payload,
  }),
  removeTemplate: (payload) => ({
    type: actions.DELETE_TEMPLATE,
    payload,
  }),
  getTemplate: (id) => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoadingTemplate(true));
      const URL = `${tagSystemPath}/entities/${id}?include_children=true`;
      const data = await axiosApiInstance.get(URL);

      dispatch(actions.setTemplate(data.data));
    } catch {
      const store = getState();

      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_TEMPLATES'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoadingTemplate(false));
    }
  },
  getTemplates: () => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoadingTemplates(true));
      const URL = `${tagSystemPath}/entities?type=template`;
      const data = await axiosApiInstance.get(URL);

      if (data.data) {
        dispatch(actions.setTemplates(data.data));
      }
    } catch {
      const store = getState();

      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_TEMPLATES'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoadingTemplates(false));
    }
  },
  getTemplateForm: () => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoadingTemplateForm(true));
      const URL = `${tagSystemPath}/templates/form`;
      const data = await axiosApiInstance.get(URL);

      if (data.data?.templateForm) {
        dispatch(actions.setTemplateForm(data.data.templateForm));
        dispatch(actions.setLoadingTemplateForm(false));
      }
    } catch {
      const store = getState();

      dispatch(actions.setLoadingTemplateForm(false));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_TEMPLATES'], NOTIFICATIONS_TYPES.error));
    }
  },
  createTemplate: (newTemplate, callback) => async (dispatch, getState) => {
    const store = getState();

    try {
      dispatch(actions.setLoadingTemplate(true));
      const URL = `${tagSystemPath}/entities`;
      const data = await axiosApiInstance.post(URL, trimObjectStringProps(newTemplate));

      dispatch(actions.addTemplate(data.data));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_TEMPLATE_CREATE'], NOTIFICATIONS_TYPES.success));

      if (callback) {
        callback();
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_TEMPLATE_CREATE']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    } finally {
      dispatch(actions.setLoadingTemplate(false));
    }
  },
  updateTemplate: (newTemplate, callback) => async (dispatch, getState, api) => {
    dispatch(actions.setLoadingTemplates(true));
    const store = getState();

    try {
      const URL = `${tagSystemPath}/entities/${newTemplate.id}`;

      const updatedTemplate = await api.put(URL, trimObjectStringProps(newTemplate));

      dispatch({
        type: actions.UPDATE_TEMPLATE,
        payload: updatedTemplate.data,
      });
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_TEMPLATE_EDIT'], NOTIFICATIONS_TYPES.success));
      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_TEMPLATE_EDIT']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    } finally {
      dispatch(actions.setLoadingTemplates(false));
    }
  },
  deleteTemplate: (templateId) => async (dispatch, getState) => {
    const store = getState();

    try {
      const URL = `${tagSystemPath}/templates/${templateId}`;

      await axiosApiInstance.delete(URL);
      dispatch(actions.removeTemplate(templateId));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_TEMPLATE_DELETE'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_TEMPLATE_DELETE']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    }
  },
};

export default actions;
