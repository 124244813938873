import React from 'react';
import PropTypes from 'prop-types';
import './TasksWidget.css';
import { Loader } from '../../../../components/Loader';



const TasksWidget = ({ children, loading }) => {
  return (
    <div className="tasks-widget">
      {loading ? <Loader /> : (<div>
        {children}
      </div>)}
    </div>
  );
};

TasksWidget.Item = ({ children }) => {
  return (
    <div className="tasks-widget-item">
      {children}
    </div>
  );
};

TasksWidget.Item.displayName = 'tasks-widget-item';
TasksWidget.Item.propTypes = {
  children: PropTypes.any.isRequired,
};

TasksWidget.propTypes = {
  children: PropTypes.any.isRequired,
  loading: PropTypes.bool,
};

export default TasksWidget;
