const actions = {
  LOADING: 'LOADING STATISTICS',
  SET_EDITORS_STATISTICS: 'SET_EDITORS_STATISTICS',
  setLoading: (payload) => ({
    type: actions.LOADING,
    payload,
  }),
  setEditorsStatistics: (payload) => ({
    type: actions.SET_EDITORS_STATISTICS,
    payload,
  }),
  getEditorsStatistics: () => async (dispatch, _getState, api) => {
    try {
      dispatch(actions.setLoading(true));

      const URL = 'reports/editors';
      const response = await api.get(URL);

      dispatch(actions.setEditorsStatistics(response.data?.statistics || []));
    } catch {
      // todo add notification
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
