import { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import tagGroupActions from '../../../store/tagSystem/groups/actions';



const useGlobalGroups = () => {
  const dispatch = useDispatch();
  const [ globalGroups, setGlobalGroups ] = useState([]);

  const { loading, groups } = useSelector(({ tagSystemGroups }) => ({
    loading: tagSystemGroups.loading,
    groups: tagSystemGroups.tagGroups,
  }), shallowEqual);

  useEffect(() => {
    dispatch(tagGroupActions.getGroups());
  }, []);

  useEffect(() => {
    const gGroups = groups.filter((group) => {
      const globalOption = group.options?.find((option) => option.name === 'is_global');

      return !!(globalOption && globalOption?.value);
    });

    setGlobalGroups(gGroups);
  }, [ groups ]);

  return [ loading, globalGroups ];
};

export default useGlobalGroups;
