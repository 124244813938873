import React from 'react';
import { Chart } from 'chart.js';
import { connect } from 'react-redux';
import { orderBy, uniq } from 'lodash';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { formatToPrettyDate, toStrMoney } from '../../utils/formats';
import Preload from '../../widgets/Preload';
import urlPageTaskDetails from '../../urls/urlPageTaskDetails';



const propTypes = {
  lang: PropTypes.object.isRequired,
  additionalData: PropTypes.any,
  nowColor: PropTypes.any,
  prevColor: PropTypes.any,
  shape2Color: PropTypes.any,
  shape3Color: PropTypes.any,
};

const DAY = 1000 * 60 * 60 * 24;
const TODAY = new Date();

/**
 * Component for user dashboard page
 */
class Dashboard extends React.Component {
  ref = React.createRef();

  state = {
    tasks: [],
    tasksStat: {
      prev: [],
      now: [],
    },
    finance: {
      now: 0,
      prev: 0,
    },
    LOAD: false,
  };

  render () {
    const { lang } = this.props;
    const { finance, tasks } = this.state;

    let proportion = Number(((finance.now / (finance.prev || 0.001) * 100) || 0).toFixed(2)) || 0;

    proportion = (proportion > 100) ? 100 : (proportion);

    const datePay = new Date(TODAY.getFullYear(), TODAY.getDate() > 15 ? TODAY.getMonth() + 1 : TODAY.getMonth(), 15);

    return (
      <>
        {this.state.LOAD ? (
          <Preload />
        ) : (
          <div className="row">
            <div className="col-xl-6">
              <div className="kt-portlet">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title font-weight-bold">
                      {lang['CUSTOMER.TASKS_STATISTICS']}
                    </h3>
                  </div>
                </div>
                <div className="kt-portlet__body kt-portlet__body--fluid">
                  <div className="kt-widget12">
                    <div className="kt-widget12__content">
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span
                            className="kt-widget12__desc"
                          >{lang['CUSTOMER.CURRENT_BALANCE']}</span>
                          <span
                            className="kt-widget12__value"
                          >€{toStrMoney(finance.now || 0)}</span>
                        </div>
                        <div className="kt-widget12__info">
                          <span
                            className="kt-widget12__desc"
                          >{lang['CUSTOMER.PAYOUT_DATE']}</span>
                          <span
                            className="kt-widget12__value"
                          >{formatToPrettyDate(datePay)}</span>
                        </div>
                      </div>
                      <div className="kt-widget12__item">
                        <div className="kt-widget12__info">
                          <span
                            className="kt-widget12__desc"
                          >{lang['CUSTOMER.EARNED_IN_LAST_MONTH']}</span>
                          <span
                            className="kt-widget12__value"
                          >€{toStrMoney(finance.prev || 0)}</span>
                        </div>
                        <div className="kt-widget12__info">
                          <span
                            className="kt-widget12__desc"
                          >{lang['CUSTOMER.PART_REVENUE']}</span>
                          <div className="kt-widget12__progress">
                            <div className="progress kt-progress--sm">
                              <div
                                className="progress-bar kt-bg-brand"
                                style={{ width: `${proportion > 100 ? 100 : proportion}%` }}
                              />
                            </div>
                            <span className="kt-widget12__stat">{proportion}%</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="kt-widget12__chart" style={{
                        height: '250px',
                      }}
                    >
                      <div className="chartjs-size-monitor">
                        <div className="chartjs-size-monitor-expand">
                          <div className="" />
                        </div>
                        <div className="chartjs-size-monitor-shrink">
                          <div className="" />
                        </div>
                      </div>
                      <canvas
                        ref={this.ref}
                        style={{
                          display: 'block',
                          height: '250px',
                          width: '100%',
                        }}
                        width="1356"
                        height="500" className="chartjs-render-monitor"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-6">
              <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
                <div className="kt-portlet__head">
                  <div className="kt-portlet__head-label">
                    <h3 className="kt-portlet__head-title">
                      {lang['CUSTOMER.MY_TASK_IN_WORK']}
                    </h3>&nbsp;
                    <span className="text-small text-muted"> ({lang['CUSTOMER.DEADLINE_FIRST']})</span>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="tab-content">
                    <div className="tab-pane active" id="kt_widget2_tab1_content">
                      <div className="kt-widget2">
                        {orderBy(tasks, [ (task) => task.deadline_to ? task.deadline_to.getTime() : 0 ], [ 'asc' ]).map((task) => {

                          let status = 'info';

                          if (task.deadline_to) {
                            if (task.deadline_to.getTime() - TODAY.getTime() <= DAY * 5) {
                              status = 'danger';
                            } else if (task.deadline_to.getTime() - TODAY.getTime() <= DAY * 15) {
                              status = 'warning';
                            }
                          }

                          return (
                            <div
                              key={task.id}
                              className={`kt-widget2__item kt-widget2__item--${status}`}
                            >
                              <div className="kt-widget2__checkbox" />
                              <div className="kt-widget2__info">
                                <Link
                                  to={urlPageTaskDetails({ taskId: task.task_id })}
                                  className="kt-widget2__title"
                                >
                                  <strong>{task.name || `Task #${task.id}`}</strong>
                                </Link>
                                <span
                                  className="kt-widget2__type"
                                >
                                  {lang[`TASK.TYPES.${task.type}`]}
                                </span>
                              </div>
                              <div className="kt-widget2__actions">
                                {task.deadline_to && formatToPrettyDate(task.deadline_to)}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>);
  }

  componentDidMount () {
    if (this.props.additionalData) {
      // eslint-disable-next-line react/no-did-mount-set-state
      this.setState({
        ...this.props.additionalData,
      }, this.buildChar);
    }
  }

  componentDidUpdate (prevProps) {
    if (prevProps.additionalData !== this.props.additionalData) {
      this.setState({
        ...this.props.additionalData,
      }, this.buildChar);
    }
  }

  componentWillUnmount () {
    if (this.chart) {
      this.chart.destroy();
    }
  }

  /**
   * Build/rebuild chart
   *
   * @private
   * @returns {*}
   */
  buildChar = () => {
    const {
      nowColor, prevColor, shape2Color, shape3Color, lang,
    } = this.props;
    const { tasksStat } = this.state;

    if (this.chart) {
      this.chart.destroy();
      this.chart = null;
    }
    if (this.ref.current) {
      const labels = orderBy(uniq([ ...tasksStat.prev.map((task) => task.day), ...tasksStat.now.map((task) => task.day) ]), [ (task) => task ]);

      const prev = [];

      labels.forEach((label) => {
        const val = tasksStat.prev.find((task) => task.day === label);

        prev.push(val ? val.count : 0);
      });

      const now = [];

      labels.forEach((label) => {
        const val = tasksStat.now.find((task) => task.day === label);

        now.push(val ? val.count : 0);
      });

      this.chart = new Chart(this.ref.current, {
        data: {
          labels,
          datasets: [
            {
              label: lang['USER_PROFILE.CUR_MONTH'],
              fill: true,
              backgroundColor: Chart.helpers
                .color(nowColor)
                .alpha(0.6)
                .rgbString(),

              borderColor: Chart.helpers
                .color(nowColor)
                .alpha(0)
                .rgbString(),

              pointHoverRadius: 4,
              pointHoverBorderWidth: 12,
              pointBackgroundColor: Chart.helpers
                .color('#000000')
                .alpha(0)
                .rgbString(),
              pointBorderColor: Chart.helpers
                .color('#000000')
                .alpha(0)
                .rgbString(),
              pointHoverBackgroundColor: nowColor,
              pointHoverBorderColor: Chart.helpers
                .color('#000000')
                .alpha(0.1)
                .rgbString(),

              data: now,
            },
            {
              label: lang['USER_PROFILE.PREV_MONTH'],
              fill: true,
              backgroundColor: Chart.helpers
                .color(prevColor)
                .alpha(0.6)
                .rgbString(),
              borderColor: Chart.helpers
                .color(prevColor)
                .alpha(0)
                .rgbString(),

              pointHoverRadius: 4,
              pointHoverBorderWidth: 12,
              pointBackgroundColor: Chart.helpers
                .color('#000000')
                .alpha(0)
                .rgbString(),
              pointBorderColor: Chart.helpers
                .color('#000000')
                .alpha(0)
                .rgbString(),
              pointHoverBackgroundColor: prevColor,
              pointHoverBorderColor: Chart.helpers
                .color('#000000')
                .alpha(0.1)
                .rgbString(),

              data: prev,
            },
          ],
        },
        type: 'line',
        options: {
          responsive: true,
          maintainAspectRatio: false,
          legend: {
            display: true,
            position: 'bottom',
          },
          scales: {
            xAxes: [
              {
                categoryPercentage: 0.35,
                barPercentage: 0.7,
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Month',
                },
                gridLines: false,
                ticks: {
                  display: true,
                  beginAtZero: true,
                  fontColor: shape3Color,
                  fontSize: 13,
                  padding: 10,
                },
              },
            ],
            yAxes: [
              {
                categoryPercentage: 0.35,
                barPercentage: 0.7,
                display: true,
                scaleLabel: {
                  display: false,
                  labelString: 'Value',
                },
                gridLines: {
                  color: shape2Color,
                  drawBorder: false,
                  offsetGridLines: false,
                  drawTicks: false,
                  borderDash: [ 3, 4 ],
                  zeroLineWidth: 1,
                  zeroLineColor: shape2Color,
                  zeroLineBorderDash: [ 3, 4 ],
                },
                ticks: {
                  display: true,
                  beginAtZero: true,
                  fontColor: shape3Color,
                  fontSize: 13,
                  padding: 10,
                },
              },
            ],
          },
          title: {
            display: false,
          },
          hover: {
            mode: 'ErrorsPage.js',
          },
          tooltips: {
            enabled: true,
            intersect: false,
            mode: 'nearest',
            bodySpacing: 5,
            yPadding: 10,
            xPadding: 10,
            caretPadding: 0,
            displayColors: false,
            backgroundColor: nowColor,
            titleFontColor: '#ffffff',
            cornerRadius: 4,
            footerSpacing: 0,
            titleSpacing: 0,
          },
          layout: {
            padding: {
              left: 0,
              right: 0,
              top: 5,
              bottom: 5,
            },
          },
        },
      });
    }
  };
}

const mapStateToProps = (store) => {
  return {
    nowColor: store.builder.layoutConfig.colors.state.brand,
    prevColor: store.builder.layoutConfig.colors.state.warning,
    shape2Color: store.builder.layoutConfig.colors.base.shape[2],
    shape3Color: store.builder.layoutConfig.colors.base.shape[3],
    additionalData: store.user.user.additionalData,
    lang: store.language.lang,
  };
};

Dashboard.propTypes = propTypes;
export default connect(mapStateToProps)(Dashboard);
