import store from '../store';
import { getLanguage } from '../../requests/getLanguage';
import { DEFAULT_LANG, setLang } from './language';



let lastLang = null;

/**
 * downloads data for the language and accepts it
 *
 * @param {string}lang
 * @returns {Promise<void>}
 */
const setLanguage = async (lang) => {
  lastLang = lang;
  if (lang === DEFAULT_LANG) {
    if (lastLang === lang) {
      store.dispatch(setLang(lang));
    }
  } else {
    const data = await getLanguage(lang);

    if (lastLang === lang) {
      store.dispatch(setLang(lang, data));
    }
  }
};

export default setLanguage;
