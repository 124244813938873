// eslint-disable-next-line deprecate/import
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist';
import thunk from 'redux-thunk';
import axiosApiInstance from '../requests/utils/api';
import { rootReducer } from './rootDuck';



const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ trace: true, traceLimit: 25 }) || compose;

const store = createStore(
  rootReducer,
  composeEnhancers(
    applyMiddleware(thunk.withExtraArgument(axiosApiInstance)),
  ),
);

export const persistor = persistStore(
  store,
);

export default store;
