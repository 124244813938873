import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import React from 'react';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import roles from '../../customers/roles/roles';
import ViewFilters from '../../../widgets/ViewFilters';
import Paginator from '../../../widgets/Paginator';
import MyComponent from '../../../utils/MyComponent';
import { parseFromPars } from '../../../utils/parseUrlParams';
import isMobileBrowser from '../../../utils/isMobileBrowser';
import taskTypes from '../taskTypes';
import urlPageTasksGroupChoosing from '../../../urls/urlPageTasksGroupChoosing';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import ArchiveModal from './ArchiveModal';



/**
 * Subheader component for task list
 *
 * @returns {MyComponent}
 */
class TasksListSubheader extends MyComponent {
  computed = {
    editors: (state, { users, userId }) => users.filter((user) => (
      user.id !== userId && checkRole(user.roles, groupsRoles.editors.all)
    )),
    activeFilters: (state, { location }) => parseFromPars(location.search),
    filterExecutor: (state, { users, filter }) => users.find((user) => user.id === filter.executor),
    filterPrevExecutor: (state, { users, filter }) => users.find((user) => user.id === filter.prev_executor),
    isTester: (state, { userRoles }) => {
      return (
        checkRole(userRoles, groupsRoles.testers.all) && this.props.match.params.type === 'my' && isMobileBrowser
      );
    },
    isLeadColoringEditor: (state, { userRoles }) => {
      return (
        checkRole(userRoles, [ roles['lead-coloring-editor'].key ])
      );
    },
    isColoringEditor: (state, { userRoles }) => {
      return (
        checkRole(userRoles, [ roles['coloring-editor'].key ])
      );
    },
    isChooseGroup: () => {
      return window.location.pathname.indexOf(taskTypes.artists_group_choosing.type) !== -1 || window.location.pathname.indexOf(taskTypes.designers_group_choosing.type) !== -1;
    },
  };

  _archiveProject = () => {
    this.setState({
      disableArchive: true,
    });
    const data = { projects: this.props.selectedProjects.map((id) => ({ id })) };

    axiosApiInstance.post('projects/archive', data)
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECTS_ARCHIVE', 'success');
        window.location.reload();
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECTS_ARCHIVE', 'error');
          this.setState({
            disableArchive: false,
          });
        }
      });
  };

  ArchiveModal = ArchiveModal.bind(this);

  render () {
    const {
      lang,
      userRoles,
      filter,
      isMobile,
      taskViewTable,
      total,
      countOnPage,
      setPage,
      addFilter,
      removeFilter,
      turnViewGrid,
      turnViewFilters,
      turnCountOnPage,
      selectedTasks,
      canSelected,
      requestForExecutor,
    } = this.props;

    const {
      editors,
      activeFilters,
      filterExecutor,
      filterPrevExecutor,
      isTester,
    } = this.computed;

    const takeToWorkBtn = (
      <Link
        className="btn btn-label-success btn-bold"
        to={{
          pathname: '/tasks/accept',
          state: { ids: selectedTasks },
          search: `?id[]=${selectedTasks.join('&id[]=')}`,
        }}
      >
        {`${lang['GLOBAL.TAKE_TO_WORK']} (${selectedTasks.length})`}
      </Link>
    );

    const archiveBtn = (
      <Button
        variant="label-warning"
        className="btn-bold"
        onClick={() => {
          this.setState({
            modalArchive: true,
          });
        }}
      >
        {`${lang['PROJECT.ARCHIVE_PROJECT']} (${selectedTasks.length})`}
      </Button>
    );

    const _getToolbarActions = () => {
      if (canSelected && selectedTasks.length > 0) {
        if (this.computed.isColoringEditor) {
          return (this.computed.isChooseGroup ? <>
            <Link
              className="btn btn-label-success btn-bold"
              to={{
                pathname: urlPageTasksGroupChoosing(),
                state: { selectedTasks },
              }}
            >{`${lang['GLOBAL.TAKE_TO_WORK']} (${selectedTasks.length})`}</Link>
          </> : <>
            {takeToWorkBtn}
          </>);
        }
        return (<>
          {takeToWorkBtn}
          <Button
            as={Link}
            variant={'label-danger'}
            className="btn-bold"
            to={{
              pathname: '/tasks/return',
              state: { ids: selectedTasks },
              search: `?id[]=${selectedTasks.join('&id[]=')}`,
            }}
          >
            {lang['GLOBAL.RETURN']}
          </Button>
          {archiveBtn}
        </>);
      }

      return null;
    };

    return (
      <SubHeader
        size="sm"
        title={(this.props.match.params.status || this.props.match.params.type) ? lang[`MENU.${(this.props.match.params.status || this.props.match.params.type).toUpperCase()}`] : lang['TASK.LIST']}
        info={`${lang['GLOBAL.TOTAL']}: ${total}`}
        main={(
          Object.keys(activeFilters).length > 0 || (this.props.match.params.status
                        && checkRole(userRoles, [ ...groupsRoles.admin, roles['lead-coloring-editor'].key ])) || isTester) &&
                        <>
                          <ViewFilters
                            filters={activeFilters}
                            options={{
                              executor: filterExecutor,
                              prev_executor: filterPrevExecutor,
                            }}
                            onRemoveFilter={(key) => {
                              if (key === 'tags') {
                                removeFilter(key, 'tags_id');
                              } else {
                                removeFilter(key);
                              }
                            }}
                          />
                          {this.props.match.params.status && checkRole(userRoles, [ ...groupsRoles.admin, roles['lead-coloring-editor'].key ]) &&
                          <Form.Group as={Form.Row} className='flex-nowrap m-0 ml-3'>
                            <Form.Label className="d-flex align-items-center kt-mr-5 kt-mb-0">
                              {lang['CUSTOMER.TYPE.editor']}
                            </Form.Label>
                            <Form.Control
                              as={'select'}
                              disabled={requestForExecutor}
                              value={filter.executor}
                              onChange={(event) => {
                                const newFilter = { ...filter, executor: event.target.value };

                                addFilter(newFilter);
                              }}
                            >
                              <option value=''>{lang['GLOBAL.NO_CHOOSE']}</option>
                              {editors.map((user) => {
                                return (
                                  <option key={user.id} value={user.id}>
                                    {user.username}
                                  </option>
                                );
                              })}
                            </Form.Control>
                          </Form.Group>}
                          {isTester && (
                            <Button
                              variant={'info'}
                              as={'a'}
                              target='_blank'
                              href={`coloring://q?testing_token=${this.props.userToken}`}
                              className='d-inline-flex align-items-center'
                            >
                              <i className="la la-mobile-phone kt-font-lg" />
                              <span>{lang['TASK.TEST_DEV']}</span>
                            </Button>
                          )}
                        </>}
        mainMobileSm={isTester && (
          <Button
            variant={'info'}
            as={'a'}
            target='_blank'
            size="sm"
            href={`coloring://q?testing_token=${this.props.userToken}`}
            className='d-inline-flex align-items-center'
          >
            <i className="la la-mobile-phone kt-font-lg" />
            <span>{lang['TASK.TEST_DEV']}</span>
          </Button>
        )}
        toolbar={
          <div className='d-flex align-items-center'>
            {_getToolbarActions()}
            {!isMobile && (
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={'taskViewTable'}>
                    {lang[taskViewTable ? 'GLOBAL.CARDS_MODE' : 'GLOBAL.TABLE_MODE']}
                  </Tooltip>
                }
              >
                <Button
                  variant={'label-info'}
                  className="btn-bold"
                  onClick={turnViewGrid}
                >
                  {taskViewTable
                    ? (<i className='flaticon-squares-1 kt-m-0 kt-p-0' />)
                    : (<i className='fa fa-th-list kt-m-0 kt-p-0' />)
                  }
                </Button>
              </OverlayTrigger>
            )}
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={'FILTERS'}>
                  {lang['GLOBAL.FILTERS']}
                </Tooltip>
              }
            >
              <Button
                variant={'label-info'}
                className="btn-bold"
                disabled={requestForExecutor}
                active={filter.show}
                onClick={turnViewFilters}
              >
                <i className='fa fa-filter kt-m-0 kt-p-0' />
              </Button>
            </OverlayTrigger>
            <div className='kt-ml-5'>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip id={'COUNT_ON_PAGE'}>
                    {lang['GLOBAL.COUNT_ON_PAGE']}
                  </Tooltip>
                }
              >
                <Form.Group className='d-flex kt-m-0 kt-ml-5 align-items-center'>
                  <Form.Control
                    size="sm"
                    as={'select'}
                    value={countOnPage}
                    onChange={(event) => {
                      turnCountOnPage(event.target.value);
                    }}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Control>
                </Form.Group>
              </OverlayTrigger>
            </div>
            <div className='kt-ml-5'>
              <Paginator
                active={(filter.skip / countOnPage)}
                count={Math.floor(total / countOnPage) - (total % countOnPage === 0 ? 1 : 0)}
                select={setPage}
              />
            </div>
            {this.ArchiveModal()}
          </div>
        }
      />
    );
  }
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userToken: store.user.user.token,
    userRoles: store.user.roles,
    userId: store.user.user.id,
    users: store.users,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(TasksListSubheader));
