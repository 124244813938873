import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { findLastIndex, uniqBy } from 'lodash';
import Badge from 'react-bootstrap/Badge';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import imgURL from '../../../../utils/imgURL';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../../utils/formats';
import Img from '../../../../widgets/Img';
import { globalTypesProject, projectTypeByKey } from '../../projectTypes';
import statusToColor, { status } from '../../../../utils/statusToColor';
import { priorityToColor } from '../../priorities';
import typesComments from '../../../../utils/typesComments';
import roles from '../../../customers/roles/roles';
import { getPreviewLg, getPreviewSm } from '../../getPreviewFromProject';
import MyComponent from '../../../../utils/MyComponent';
import checkRole from '../../../../utils/checkRole';
import groupsRoles from '../../../customers/roles/groupsRoles';
import urlPageProjectDetails from '../../../../urls/urlPageProjectDetails';
import urlPageFreelancerDetails from '../../../../urls/urlPageFreelancerDetails';
import { isSameDomain } from '../../../../utils/isSameDomain';

/**
 * Project card component for the project list
 */
class ProjectItemCard extends MyComponent {
  state = {
    tasks: [],
  };

  computed = {
    preview: (state, { project }) => imgURL(getPreviewSm(project.preview || project.links)),
    fullPreview: (state, { project }) => ({
      id: project.id,
      link: getPreviewLg(project.preview || project.links),
    }),
  };

  render () {
    const {
      project, project: { tasks }, lang, users, showPreviousExecutor, user,
    } = this.props;

    const indexActiveTask = tasks && findLastIndex(tasks, (task) => task.status === status.finished) + 1;

    const activeTask = tasks && indexActiveTask >= 0 && tasks[indexActiveTask];

    let executors = [];

    if (tasks) {
      tasks.forEach((task) => {
        const id = task.executor_id || (task.executor ? task.executor.id : -1);

        if (id) {
          executors.push(id);
        }
      });
    }

    executors = uniqBy(executors, (id) => id).map((id) => users.find((user) => id === user.id)).filter((user) => !!user);

    const curExecutor = activeTask ? executors.find((user) => user.id === (activeTask.executor_id || (activeTask.executor ? activeTask.executor.id : -1))) : null;

    if (curExecutor) {
      executors = executors.filter((executor) => executor.id !== curExecutor.id);
    }

    const comments = project.comment && project.comments.filter((comment) => comment.status !== typesComments.refuseTask);

    const showProgress = checkRole(this.props.userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.admin ]);

    return (
      <div className="col-xl-4 col-lg-6 col-12">
        <div
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className={`project kt-portlet kt-portlet--height-fluid ${this.props.selected ? 'project--select' : ''}`}
          onClick={() => {
            if (typeof this.props?.onSelect === 'function') {
              this.props.onSelect(this.props.project.id);
            }
          }}
        >
          <div className="kt-portlet__body kt-portlet__body--fit">
            <div className="kt-widget kt-widget--project-1">
              <div className="kt-widget__head kt-pt-10 kt-pb-10 align-items-start flex-nowrap">
                <div className="kt-widget__label">
                  <div className="kt-widget__media">
                    <span className="kt-media kt-media--xxxl">
                      <Img
                        src={this.computed.preview}
                        current={this.computed.fullPreview}
                        files={this.props.listPreview}
                        canFull
                      />
                    </span>
                  </div>
                  <div className="kt-widget__info kt-mt-5">
                    <Link to={urlPageProjectDetails({ projectId: project.id })} className="kt-widget__title">
                      {project.title ? (
                        <>
                          {`${project.title} `}
                          <span className='text-muted'>{`ID:${project.id}`}</span>
                        </>
                      ) : `Project #${project.id}`}
                    </Link>
                    <span className="kt-widget__desc font-weight-bold">
                      {lang[`PROJECT.TYPE.${project.type}`]}
                    </span>
                  </div>
                </div>
                <Badge
                  as='a'
                  href='#'
                  variant='secondary'
                  className='kt-font-bold'
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                    if (this.props.onClickType) {
                      this.props.onClickType(project.options.type);
                    }
                  }}
                >{(project.options.type || '').toUpperCase()}</Badge>
              </div>

              <div className="kt-widget__body kt-pb-10  d-flex flex-column">
                <span
                  className="kt-widget__text kt-mt-0 kt-pt-0 flex-grow-1 flex-shrink-0"
                  style={{ overflowWrap: 'break-word' }}
                >
                  {project.description}
                </span>
                <hr className='w-100' />
                <div className="kt-widget__stats container-fluid d-block">
                  <div className='row'>
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.PRIORITY']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant={priorityToColor(project.priority)}>
                          {lang[`GLOBAL.PRIORITY.${project.priority}`]}
                        </Badge>
                      </div>
                    </div>
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.STATUS']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant={statusToColor(status[project.status])}>
                          {(lang[`GLOBAL.STATUS.${status[project.status]}`] || '').toLowerCase() || ''}
                        </Badge>
                      </div>
                    </div>
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.BEGIN']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant='primary'>
                          {formatToPrettyDate(project.started_at) || '---'}
                        </Badge>
                      </div>
                    </div>
                    {projectTypeByKey[project.type] && projectTypeByKey[project.type].type === globalTypesProject.COLORING && project.status !== status.finished && (
                      <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                        <span className="kt-widget__date kt-p-0 font-weight-bold">
                          {lang['GLOBAL.PERSONAL_DEADLINE']}
                        </span>
                        <div className="kt-widget__label">
                          <Badge variant='warning'>
                            {formatToPrettyDateOrTime(project.task_deadline_to) || '---'}
                          </Badge>
                        </div>
                      </div>
                    )}
                    <div className="col-12 kt-widget__item kt-p-0 kt-m-0 d-flex justify-content-between align-items-center kt-mb-5">
                      <span className="kt-widget__date kt-p-0 font-weight-bold">
                        {lang['GLOBAL.GLOBAL_DEADLINE']}
                      </span>
                      <div className="kt-widget__label">
                        <Badge variant='danger'>
                          {formatToPrettyDateOrTime(project.deadline_to) || '---'}
                        </Badge>
                      </div>
                    </div>

                    {(showProgress || (project.tags && project.tags.length > 0)) && (
                      <hr className='col-12 kt-ml-0 kt-mr-0 kt-p-0 w-100' />
                    )}

                    <div
                      className="kt-widget__container flex-wrap kt-m-0 overflow-hidden"
                      style={{ height: 36 }}
                    >
                      <span className="kt-widget__subtitel font-weight-bold kt-mb-5">
                        {lang['MENU.TAGS']}:
                      </span>
                      {project.tags && project.tags.length > 0 && (
                        uniqBy(project.tags, (item) => item.id).map((tag) => {
                          return (
                            <button
                              key={tag}
                              className="kt-ml-5 kt-mb-5 btn btn-secondary btn-sm"
                              style={{
                                backgroundColor: 'rgba(40, 42, 60, 0.1)',
                                color: '#282a3c',
                              }}
                              onClick={() => {
                                if (this.props.onClickTag) {
                                  this.props.onClickTag(tag);
                                }
                              }}
                            >{tag}</button>
                          );
                        })
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="kt-widget__footer">
                <div className="kt-widget__wrapper kt-pt-10 kt-pb-10 d-flex justify-content-between flex-nowrap">
                  <div className="kt-widget__section flex-grow-1 flex-shrink-0">
                    <div
                      role="link"
                      tabIndex={0}
                      onKeyDown={() => {}}
                      className="kt-widget__blog kt-p-0"
                      style={{ cursor: comments && comments.length > 0 ? 'pointer' : '' }}
                      onClick={() => {
                        if (this.props.onClickComments && comments && comments.length > 0) {
                          this.props.onClickComments(comments);
                        }
                      }}
                    >
                      <i className="flaticon2-talk" />
                      <span className="kt-widget__value">
                        {`${lang['GLOBAL.COUNT_COMMENTS']}: ${comments ? comments.length : ''}`}
                      </span>
                    </div>
                  </div>

                  <div className="kt-widget__section kt-pr-0">
                    <div className="kt-widget__blog d-flex justify-content-end kt-pl-0">
                      <div
                        className='kt-list-pics kt-list-pics--sm kt-pl-0 d-flex justify-content-end flex-wrap align-items-center'
                        style={{ minHeight: 36 }}
                      >
                        {curExecutor && !showPreviousExecutor && (
                          <OverlayTrigger
                            key={curExecutor.id}
                            placement="left"
                            overlay={
                              <Tooltip id={`executor-name-${curExecutor.id}`}>
                                {lang['PROJECT.CUR_EXECUTOR']}
                              </Tooltip>
                            }
                          >
                            {isSameDomain(user.provider_id, curExecutor.provider_id) ? (
                              <Link
                                to={urlPageFreelancerDetails({ freelancerId: curExecutor.id })}
                                style={{ color: '#646c9a' }}
                                className={'hover d-flex align-items-center'}
                              >
                                <span className='d-inline-flex flex-column'>
                                  <span className='kt-mr-5 font-weight-bold'>
                                    {curExecutor.username}
                                  </span>
                                  <span className='kt-mr-5 text-muted kt-font-sm'>
                                    {roles[curExecutor.roles[curExecutor.roles.length - 1]].title}
                                  </span>
                                </span>
                                <span
                                  className='border shadow-sm border-success'
                                  style={{ marginRight: -2 }}
                                >
                                  <Img src={imgURL(curExecutor.avatar)} />
                                </span>
                              </Link>) : <span>{curExecutor.username}</span>}
                          </OverlayTrigger>
                        )}
                        {showPreviousExecutor && project.last_executor && (
                          <OverlayTrigger
                            key={project.last_executor.id}
                            placement="left"
                            overlay={
                              <Tooltip id={`executor-name-${project.last_executor.id}`}>
                                {lang['PROJECT.PREVIOUS_EXECUTOR']}
                              </Tooltip>
                            }
                          >
                            {isSameDomain(user.provider_id, project.last_executor.provider_id) ? (
                              <Link
                                to={urlPageFreelancerDetails({ freelancerId: project.last_executor.id })}
                                style={{ color: '#646c9a' }}
                                className={'hover d-flex align-items-center'}
                              >
                                <span className='d-inline-flex flex-column'>
                                  <span className='kt-mr-5 font-weight-bold'>
                                    {project.last_executor.username}
                                  </span>
                                </span>
                                <span
                                  className='border shadow-sm border-success'
                                  style={{ marginRight: -2 }}
                                >
                                  <Img src={imgURL(project.last_executor.avatar)} />
                                </span>
                              </Link>) : <span>{project.last_executor.username}</span>}
                          </OverlayTrigger>
                        )}
                        {executors.length > 0 && (
                          <div className="kt-list-pics kt-list-pics--sm kt-ml-10">
                            {executors.map((executor) => {
                              return (
                                <OverlayTrigger
                                  key={executor.id}
                                  placement="bottom"
                                  overlay={
                                    <Tooltip id={`executor-name-${executor.id}`}>
                                      <div className='d-flex flex-column'>
                                        <b>{executor.username}</b>
                                        <span className='text-muted kt-font-sm'>
                                          {roles[executor.roles[executor.roles.length - 1]].title}
                                        </span>
                                      </div>
                                    </Tooltip>
                                  }
                                >
                                  <Link to={urlPageFreelancerDetails({ freelancerId: executor.id })}>
                                    <Img src={imgURL(executor.avatar)} />
                                  </Link>
                                </OverlayTrigger>
                              );
                            })}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
    user: store.user.user,
    users: store.users,
  };
};

export default connect(mapStoreToProps)(ProjectItemCard);
