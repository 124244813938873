import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import urlPageTagSystemTagsChildren from '../../../urls/urlPageTagSystemTagsChildren';
import { entityTypes } from '../config/config';
import { deletable } from '../helpers/Deletable';



const TagsTableItem = ({
  tag,
  tags,
  onDelete,
  onCreate,
  currentEntity,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const child = () => {
    if (!tag.children?.length) {
      return '---';
    }

    let dependentGroup = null;

    if (tag.type === entityTypes.tag) {
      const child = tags.find((item) => item.id === tag.children[0]?.id);

      dependentGroup = child.parents?.find((parent) => parent.type === entityTypes.group);
    } else {
      dependentGroup = currentEntity.getDependentGroup();
    }

    if (!dependentGroup?.id) {
      return '---';
    }

    return (
      <Link
        className='font-weight-bold'
        to={urlPageTagSystemTagsChildren({ groupId: dependentGroup?.id, tagId: tag.id })}
      >
        {tag.children.length} {tag.children.length > 1 ? lang['TAG_SYSTEM.TAGS.TAGS'] : lang['TAG_SYSTEM.TAGS.TAG']}
      </Link>
    );
  };

  const parentTag = () => {
    if (!tag.parents?.length) {
      return '---';
    }

    const parent = tag.parents.find((parent) => parent.type === entityTypes.tag);

    if (!parent) {
      return '---';
    }

    return (
      <span>{parent?.name}</span>
    );
  };

  return (
    <>
      <tr>
        <td className='text-center mr-3'>
          <span className='mr-3'>{tag.id}</span>
        </td>
        <td className='text-left'>
          {tag.name}
        </td>
        <td className='text-left'>{parentTag()}</td>
        <td className='text-left'>
          {child()}
        </td>
        <td className='text-center'>
          <ButtonGroup>
            {currentEntity.isParent && (
              <Button
                title={lang['TAG_SYSTEM.TAGS.ADD_CHILD_TAG']}
                size="sm"
                onClick={() => onCreate(tag.id)}
              >
                <i className="flaticon2-plus kt-m-0" />
              </Button>
            )}
            <Button
              disabled={!deletable(tag)}
              id="delete-group"
              title={lang['GLOBAL.REMOVE']}
              variant='danger'
              size='sm'
              onClick={() => onDelete(tag.id)}
            >
              <i className="fa fa-trash kt-m-0" />
            </Button>
          </ButtonGroup>
        </td>
      </tr>
    </>
  );
};


TagsTableItem.propTypes = {
  tag: PropTypes.object.isRequired,
  tags: PropTypes.array.isRequired,
  onDelete: PropTypes.func,
  onCreate: PropTypes.func,
  currentEntity: PropTypes.object.isRequired,
};

export default TagsTableItem;
