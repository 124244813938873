import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import React from 'react';
import Img from '../../../widgets/Img';
import imgURL from '../../../utils/imgURL';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import { formatDate } from '../../../utils/formats';
import statusToColor, { status } from '../../../utils/statusToColor';
import urlPageTaskDetails from '../../../urls/urlPageTaskDetails';
import urlPageFreelancerDetails from '../../../urls/urlPageFreelancerDetails';
import { isSameDomain } from '../../../utils/isSameDomain';

/**
 * Builds a block with a list of tasks for the project information page
 *
 * @this ProjectInfo
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { project } = this.state;
  const { lang, userRoles } = this.props;
  const {
    sizing,
  } = this.computed;

  const showExecutor = (executor) => {
    if (executor && isSameDomain(this.props.user.provider_id, executor.provider_id)) {
      return (
        <span>
          <Link
            to={urlPageFreelancerDetails({ freelancerId: executor.id })}
            className="kt-widget4__text"
          >
            {executor.username}
          </Link>
        </span>
      );
    }

    return <span>{executor?.username}</span>;
  };

  return (
    <div
      className={`col-xl-${sizing.xl} col-lg-${sizing.lg} col-md-${sizing.md} col-sm-${sizing.sm} col-12`}
    >
      <div className="kt-portlet kt-portlet--tabs kt-portlet--height-fluid">
        <div className="kt-portlet__head">
          <div className="kt-portlet__head-label">
            <h3 className="kt-portlet__head-title font-weight-bold">
              {lang['MENU.TASKS']}
            </h3>
          </div>
        </div>
        <div className="kt-portlet__body">
          <div className="tab-content">
            <div className="tab-panel active">
              <div className="kt-widget4">
                {project.tasks.sort((first, second) => {
                  return first.id - second.id;
                }).map((task) => (
                  // TODO убрать сужность юзера
                  <div
                    className={`kt-widget4__item${task.has_burned ? ' kt-bg-light-danger' : ''}`}
                    style={{
                      marginLeft: '-15px',
                      marginRight: '-15px',
                      paddingLeft: '15px',
                      paddingRight: '15px',
                    }}
                    key={task.id}
                  >
                    {task.executor && (
                      <div className="kt-widget4__pic kt-widget4__pic--pic">
                        <Img src={imgURL(task.executor.avatar)} />
                      </div>
                    )}
                    <div className="kt-widget4__info">
                      <span
                        className="kt-widget4__username"
                      >
                        {checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ]) ? (
                          <Link
                            to={urlPageTaskDetails({ taskId: task.id })}
                            className="kt-widget4__username"
                          >
                            {lang[`TASK.TYPES.${task.type}`]}
                          </Link>
                        ) : (
                          <span
                            className="kt-widget4__username no-hover"
                          >
                            {lang[`TASK.TYPES.${task.type}`]}
                          </span>
                        )}
                        <small className="text-muted">
                          <a href={urlPageTaskDetails({ taskId: task.id })}>{` ID: ${task.id}`}</a>
                        </small>
                      </span>
                      {showExecutor(task.executor)}
                    </div>
                    <Badge
                      variant="danger"
                      className="kt-mr-5"
                    >
                      {formatDate(task.finished_at || task.deadline_to, 'dd.MM.yyyy')}
                    </Badge>
                    <Badge variant={statusToColor(status[task.status])}>
                      {lang[`GLOBAL.STATUS.${status[task.status]}`]}
                    </Badge>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
