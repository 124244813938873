import React from 'react';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { getTasksByIds } from '../../requests/tasks';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import Img from '../../widgets/Img';
import imgURL from '../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../projects/getPreviewFromProject';
import Preload from '../../widgets/Preload';
import Modal from '../../widgets/Modal';
import DropFile from '../../widgets/DropFile';
import axiosApiInstance from '../../requests/utils/api';
import { notification } from '../../requests/notifications';
import HtmlEditor from '../../components/HtmlEditor';
import config from '../../../config/app';



const propTypes = {
  lang: PropTypes.object,
  match: PropTypes.any,
  location: PropTypes.object,
  history: PropTypes.any,
  notification: PropTypes.any,
};
/**
 * Return to task group revision page
 */

class TasksReturn extends React.Component {
  state = {
    tasks: [],
    data: [],
    LOAD: true,
    showModal: false,
  };

  render () {
    const { tasks, LOAD } = this.state;

    const { lang } = this.props;

    let disabled = false;

    // eslint-disable-next-line no-restricted-syntax,fp/no-loops
    for (const data of this.state.data) {
      if ((!data.message || data.message.trim().length < 11) || !data.returnFile) {
        disabled = true;
      }
    }

    return LOAD
      ? (<Preload />)
      : (<>
        <SubHeader
          toolbar={(<>
            <Button
              variant='danger'
              className='mx-1'
              disabled={disabled}
              onClick={() => {
                this.setState({
                  showModal: true,
                });
              }}
            >
              {lang['GLOBAL.RETURN']}
            </Button>
          </>)}
        />
        {tasks.map((task, index) => {
          const project = task.project;
          const { message, returnFile } = this.state.data[index];

          return (
            <div key={task.id} className="kt-portlet">
              <div
                className="kt-portlet__body" style={{
                  border: message.length < 11 || !returnFile ? '1px solid red' : 'none',
                }}
              >
                <div className="kt-widget kt-widget--user-profile-3">
                  <div className="kt-widget__top">
                    <div className="kt-widget__media">
                      <Img
                        src={imgURL(getPreviewSm(task.preview))}
                        current={{
                          id: task.id,
                          link: getPreviewLg(task.preview),
                        }}
                        canFull
                      />
                    </div>
                    <div className="kt-widget__content">
                      <h3 className='kt-mb-20'>{project.title || (`Task #${project.id}`)}</h3>
                      <Form.Group>
                        <Form.Label>
                          {lang['GLOBAL.COMMENT']}
                          <span style={{ color: 'red' }}>{' *'}</span>
                        </Form.Label>
                        <HtmlEditor
                          onChange={(value) => {
                            const nData = [ ...this.state.data ];

                            nData[index] = {
                              ...nData[index],
                              message: value,
                            };

                            this.setState({
                              data: nData,
                            });
                          }}
                          initialValue={message || ''}
                          init={{
                            license_key: config.tinyEditorApiKey,
                            plugins: 'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link table charmap pagebreak nonbreaking advlist lists wordcount help charmap emoticons',
                            toolbar: 'undo redo | bold italic underline fontsizeselect numlist bullist | fontselect formatselect strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview',
                            toolbar_mode: 'sliding',
                            menubar: false,
                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
                          }}
                        />
                      </Form.Group>
                      <Form.Group>
                        <Form.Control
                          as={DropFile}
                          multiple={false}
                          theme='danger'
                          className='h-auto'
                          info={lang['TASK.ONLY_ONE_FILE']}
                          curFiles={returnFile ? [ returnFile ] : []}
                          addFile={(result) => {
                            const nData = [ ...this.state.data ];

                            nData[index] = {
                              ...nData[index],
                              returnFile: result,
                            };

                            this.setState({
                              data: nData,
                            });
                          }}
                          removeFile={() => {
                            const nData = [ ...this.state.data ];

                            nData[index] = {
                              ...nData[index],
                              returnFile: null,
                            };

                            this.setState({
                              data: nData,
                            });
                          }}
                        />
                      </Form.Group>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        <Modal
          title={lang['TASK.YOU_SURE_RETURN_TASKS']}
          show={this.state.showModal}
          close={() => {
            this.setState({
              showModal: false,
            });
          }}
          footer={
            <>
              <button
                className="btn btn-secondary"
                onClick={() => {
                  this.setState({
                    showModal: false,
                  });
                }}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                className="btn btn-danger"
                onClick={() => {
                  this._returnTasks();
                }}
              >{lang['GLOBAL.RETURN']}</button>
            </>
          }
        />
      </>);
  }

  componentDidMount () {
    const ids = this.props.location.search?.slice(1)?.split('id[]=')?.filter((id) => id);

    getTasksByIds(ids).then((res) => { // false
      this.setState({
        tasks: res,
        data: res.map(() => ({ message: '', returnFile: null })),
        LOAD: false,
      });
    }).catch(() => {
      this.setState({
        LOAD: false,
      });
    });
  }

  _returnTasks = () => {
    this.setState({
      LOAD: true,
    });

    const fd = new FormData();

    this.state.tasks.forEach((task, index) => {
      fd.append(`tasks[${index}][id]`, task.id);
      fd.append(`tasks[${index}][message]`, this.state.data[index].message);
      if (this.state.data[index].returnFile) {
        fd.append(`tasks[${index}][files][${index}]`, this.state.data[index].returnFile.file, this.state.data[index].returnFile.file.name);
      }
    });

    axiosApiInstance.post('tasks/batch-reopen', fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_RETURN_TASKS', 'success');
        this.props.history.goBack();
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_RETURN_TASKS', 'error');
          this.setState({
            LOAD: false,
          });
        }
      });
  };
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

TasksReturn.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(TasksReturn);
