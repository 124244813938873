import actions from './actions';



const initialState = {
  stat: {
    count_days_left: 0,
    tasks: {
      checked: 0,
      finished: 0,
    },
    finance: {
      balance: 0,
    },
  },
  invoices: [],
  tasks: [],
  prevMonthTasks: [],
  loading: true,
};

export const billingReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING_BILLING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.UPDATE_TRACKING_TIME:
      return {
        ...state,
        tasks: action.payload,
      };
    case actions.UPDATE_TRACKING_TIME_PREV:
      return {
        ...state,
        prevMonthTasks: action.payload,
      };
    case actions.SET_BILLING_TASKS:
      return {
        ...state,
        ...action.payload,
      };
    case actions.SET_PREV_MONTH_BILLING:
      return {
        ...state,
        prevMonthTasks: action.payload.prevMonthTasks,
      };
    default:
      return state;
  }
};
