import { HTTP_UNPROCESSABLE_ENTITY } from './responseStatuses';



const handleResponseMessage = (response, fallback) => {
  if (response?.status === HTTP_UNPROCESSABLE_ENTITY) {
    return response.data?.message;
  }
  return fallback;
};

export default handleResponseMessage;
