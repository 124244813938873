import roles, { rolesKeys } from './roles';



const admin = [ roles.administrator.key ];

const coloring = [
  roles['content-manager'].key,
  roles['lead-coloring-editor'].key,
  roles['coloring-editor'].key,
  roles['remote-coloring-editor'].key,
  roles['coloring-artist'].key,
  roles['coloring-designer'].key,
  roles['coloring-tester'].key,
  roles['lead-coloring-tester'].key,
];

const managers = {
  coloring: [
    roles['content-manager'].key,
  ],
  all: [
    roles['content-manager'].key,
  ],
};

const editors = {
  coloring: [
    roles['lead-coloring-editor'].key,
    roles['coloring-editor'].key,
    roles['remote-coloring-editor'].key,
  ],
  all: [
    roles['lead-coloring-editor'].key,
    roles['coloring-editor'].key,
    roles['remote-coloring-editor'].key,
  ],
};

const executors = {
  all: [
    roles['coloring-artist'].key,
    roles['coloring-designer'].key,
    roles['coloring-tester'].key,
    // roles['lead-coloring-tester'].key,
  ],
  coloring: [
    roles['coloring-artist'].key,
    roles['coloring-designer'].key,
    roles['coloring-tester'].key,
    roles['lead-coloring-tester'].key,
  ],
  withoutTesters: [
    roles['coloring-artist'].key,
    roles['coloring-designer'].key,
  ],
};

const testers = {
  all: [
    roles['coloring-tester'].key,
    roles['lead-coloring-tester'].key,
  ],
  coloring: [
    roles['coloring-tester'].key,
    roles['lead-coloring-tester'].key,
  ],
};

const all = [ ...rolesKeys ];

/**
 * User role groups
 *
 * @type {{all: *[], testers: {all: [*, *], coloring: [*, *]}, admin: [*], executors: {all: (string)[], coloring: [*, *, *, *]}, coloring: (string)[], employee: {all: [string] }, managers: {all: [*, *, *, *, *], coloring: [*, *]}, editors: {all: [*, *, *, *, *], coloring: [*, *, *]}}}
 */
const groupsRoles = {
  admin,
  managers,
  executors,
  testers,
  all,
  editors,
  coloring,
};

export default groupsRoles;
