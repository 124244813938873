import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import React from 'react';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import statusToColor, { status } from '../../../utils/statusToColor';
import checkRole from '../../../utils/checkRole';
import taskTypes from '../taskTypes';
import groupsRoles from '../../customers/roles/groupsRoles';
import isMobileBrowser from '../../../utils/isMobileBrowser';
import MyComponent from '../../../utils/MyComponent';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import urlPageProjectEdit from '../../../urls/urlPageProjectEdit';
// eslint-disable-next-line import/no-named-as-default
import isGroupExist from '../../../utils/isGroupExist';
import roles from '../../customers/roles/roles';
import urlPageProjectDetails from '../../../urls/urlPageProjectDetails';
import urlPageTagSystemPictures from '../../../urls/urlPageTagSystemPictures';
import config from '../../../../config/app';

/**
 * Component of the subheader of the task page
 *
 * @returns {*}
 */
class TaskInfoSubheader extends MyComponent {
  subtitle = () => {
    if (checkRole(this.props.userRoles, [
      roles.manager.key,
      roles.administrator.key,
      roles['content-manager'].key,
      roles['remote-coloring-editor'].key,
      roles['coloring-editor'].key,
      roles['lead-coloring-editor'].key,
    ])) {
      return <Link to={urlPageProjectDetails({ projectId: this.props.task.project_id })}>{`#${this.props.task.project_id || ''}`}</Link>;
    }

    return `#${this.props.task.project_id || ''}`;
  };

  isManager = () => {
    return checkRole(this.props.userRoles, [
      roles.manager.key,
      roles['content-manager'].key,
    ]);
  };

  computed = {
    canOpenTesting: (state, { userRoles }) => {
      return (
        this._canOpenTestingEditor(userRoles)
        || this._canOpenTestingTester(userRoles)
        || this._canOpenTestingColorist(userRoles, this.props.user)
      );
    },
  };

  render () {
    const {
      userId,
      lang,
      userToken,
      task,
      taskFinishStatus,
      realStatus,
      isChoseExecutor,
      isChoseGroup,
      isWork,
      canBonus,
      canBeRefuse,
      setModalSetBonus,
      setModalDeleteBonus,
      setModalApplyTask,
      setModalReopenTask,
      startTask,
      canApplyTask,
      canStartWork,
      isAlreadyHaveBonus,
      isArtistTaskPaid,
      tagSystemPictureTags,
    } = this.props;

    const {
      canOpenTesting,
    } = this.computed;

    const isTutorial = window.location.pathname === '/tutorial';

    return (
      <SubHeader
        size="sm"
        title={lang['PROJECT.PROJECT']}
        subtitle={this.subtitle()}
        info={task.title}
        main={canOpenTesting && (
          <Button
            variant={'info'}
            as={'a'}
            target='_blank'
            href={`coloring://q?testing_token=${userToken}`}
            className='d-inline-flex align-items-center'
          >
            <i className="la la-mobile-phone kt-font-lg" />
            <span>{lang['TASK.TEST_DEV']}</span>
          </Button>
        )}
        mainMobileSm={canOpenTesting && (
          <Button
            variant={'info'}
            as={'a'}
            size="sm"
            target='_blank'
            href={`coloring://q?testing_token=${userToken}`}
            className='d-inline-flex align-items-center'
          >
            <i className="la la-mobile-phone kt-font-lg" />
            <span>{lang['TASK.TEST_DEV']}</span>
          </Button>
        )}

        toolbar={<div className="kt-widget__action">
          {this._canTagPictureOnTagSystem() && realStatus === status.process && task.executor_id === userId && (
            <Button
              variant={tagSystemPictureTags.length > 0 ? 'warning' : 'success'}
              className='mx-1 text-white'
              onClick={() => {
                this.props.history.push(urlPageTagSystemPictures({ projectId: task?.project.id }));
              }}
            >
              {lang['TAG_SYSTEM.TAG_PICTURE']}
            </Button>
          )}
          {(canStartWork || isTutorial) && (
            <Button
              variant={'brand'}
              className='mx-1'
              onClick={startTask}
            >
              {lang['GLOBAL.START_WORK']}
            </Button>
          )}
          {
            (task.status === status.process && task.executor_id === userId &&
              checkRole([ taskTypes[task.type].role ], [ ...groupsRoles.managers.all, ...groupsRoles.editors.all ])) && (
              <Button
                as={Link}
                to={`${urlPageProjectEdit({ projectId: task.project.id })}?task=${task.id}`}
                variant="secondary"
                className='mx-1'
              >
                {lang['PROJECT.CHANGE']}
              </Button>
            )}
          {canBeRefuse && (realStatus === status.process) && task.executor_id === userId && (
            <Button
              variant={'warning'}
              className='mx-1'
              onClick={this._refuseTask}
            >
              {lang['GLOBAL.REFUSE']}
            </Button>
          )}
          {task.status === status.finished && (
            <Button
              as='span'
              variant={`label-${statusToColor(status[realStatus])}`}
              className='mx-1'
            >
              {lang[`GLOBAL.STATUS.${status[realStatus]}`]}
            </Button>
          )}
          {canBonus && (task.type !== taskTypes.artist_choosing.type) && (
            <>
              <Button
                variant="info"
                className='mx-1'
                onClick={() => {
                  setModalSetBonus(true);
                }}
              >
                {isAlreadyHaveBonus ? lang['BONUSES.CHANGE_AWARD'] : lang['BONUSES.ADD_AWARD']}
              </Button>

              {isAlreadyHaveBonus && (
                <Button
                  variant="dark"
                  className='mx-1'
                  onClick={() => {
                    setModalDeleteBonus(true);
                  }}
                >
                  {lang['BONUSES.REMOVE']}
                </Button>
              )}
            </>
          )}
          {taskFinishStatus && canApplyTask && (
            <Button
              variant='success'
              className='mx-1'
              onClick={() => {
                setModalApplyTask(true);
              }}
            >
              {lang['GLOBAL.ACCEPT']}
            </Button>
          )}
          {realStatus === status.process && task.executor_id === userId &&
          (!isChoseExecutor || (isChoseExecutor && !isGroupExist(task.project.options.chosen_group))) &&
            !isChoseGroup && !isWork && (
            <Button
              variant='danger'
              className='mx-1'
              disabled={this.isManager() && isArtistTaskPaid}
              onClick={() => {
                setModalReopenTask(true);
              }}
            >
              {lang['GLOBAL.RETURN']}
            </Button>
          )}
        </div>
        }
      />
    );
  }

  _refuseTask = async () => {
    try {
      this.props.setTaskLoad(true);

      await axiosApiInstance.put(`tasks/${this.props.task.id}/refuse`);
      this.props.notification('NOTIFICATION.SUCCESS_TASK_REFUSE', 'success');
      this.props.history.goBack();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      if (error.response) {
        this.props.notification('NOTIFICATION.ERROR_TASK_REFUSE', 'error');
        this.props.setTaskLoad(false);
      }
    } finally {
      this.props.setTaskLoad(false);
    }
  };

  _canOpenTestingEditor = (userRoles) => {
    return (
      checkRole(userRoles, groupsRoles.editors.all)
      && this.props.task.type === taskTypes.editor_checking_designer.type
      && isMobileBrowser
    );
  };

  _canTagPictureOnTagSystem = () => {
    if (!config.tagsEnabled) {
      return false;
    }

    return (
      checkRole(this.props.userRoles, [
        ...groupsRoles.editors.all,
        roles.manager.key,
        roles.administrator.key,
        roles['content-manager'].key ])
      && this.props.task.type === taskTypes.editor_checking_designer.type
    );
  };

  _canOpenTestingTester = (userRoles) => {
    return (
      checkRole(userRoles, groupsRoles.testers.all)
      && this.props.match.params.type === 'my'
      && isMobileBrowser
    );
  };

  _canOpenTestingColorist = (userRoles, user) => {
    return (
      checkRole(
        userRoles,
        [ roles['coloring-designer'].key ]
      )
      && user.is_employee
      && this.props.task.status === status.process
      && this.props.task.files.length > 0
      && this.props.task.type === taskTypes.designer_coloring.type
      && isMobileBrowser
    );
  };
}

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userToken: store.user.user.token,
    userId: store.user.user.id,
    userRoles: store.user.roles,
    user: store.user.user,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(withRouter(TaskInfoSubheader));
