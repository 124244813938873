import { DEFAULT_LANG, languages } from '../store/lang/language';

/**
 * Searches for a language in a browser that supports the site
 *
 * @returns {string}
 */
const getBrowserLanguage = () => {
  let lang = DEFAULT_LANG;

  // eslint-disable-next-line fp/no-loops
  for (let i = 0; i < window.navigator.languages.length; i += 1) {
    const nLang = languages.find((lang) => lang.lang === window.navigator.languages[i]);

    if (nLang) {
      lang = window.navigator.languages[i];
      break;
    }
  }

  return lang;
};

export default getBrowserLanguage;
