import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import urlPageGroupsCreate from '../../urls/urlPageGroupsCreate';
import urlPageGroupDetails from '../../urls/urlPageGroupDetails';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';



const propTypes = {
  lang: PropTypes.object.isRequired,
  groups: PropTypes.any,
};

/**
 * Component for group list page
 */

export const ListGroups = ({ lang, groups }) => {
  return (
    <>
      <SubHeader
        title={lang['GROUP.CUSTOMERS']}
        info={`${lang['GLOBAL.TOTAL']}: ${groups.length}`}
        toolbar={(
          <Link to={urlPageGroupsCreate()} className="btn btn-bold btn-label-brand">
            {lang['GROUP.NEW']}
          </Link>
        )}
      />
      <div className="row">
        {groups.map((group) => (
          <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12" key={group.id}>
            <div className="kt-portlet kt-portlet--height-fluid">
              <div className="kt-portlet__body">
                <div className="kt-widget kt-widget--user-profile-2">
                  <div className="kt-widget__head">
                    <div
                      className="kt-widget__info"
                      style={{ margin: 'auto', textAlign: 'center' }}
                    >
                      <Link to={urlPageGroupDetails({ groupId: group.id })} className="kt-widget__titel">
                        {group.name}
                      </Link>
                      <Link
                        to={urlPageFreelancerDetails({ freelancerId: group.editor_id })}
                        className="kt-widget__desc hover"
                      >
                        {lang['ROLE.coloring-editor']}
                        <span style={{ fontWeight: 600 }}>
                          {group.editor}
                        </span>
                      </Link>
                    </div>
                  </div>
                  <div className="kt-widget__body">
                    <div className="kt-widget__section" style={{ fontFamily: 'Roboto' }}>
                      {group.description}
                    </div>
                    <div className="kt-widget__item">
                      <div className="kt-widget__contact">
                        <span className="kt-widget__label">
                          {lang['CUSTOMER.TYPE.count_artists']}
                        </span>
                        <span className="kt-widget__data">
                          {group.count_artists}
                        </span>
                      </div>
                      <div className="kt-widget__contact">
                        <span className="kt-widget__label">
                          {lang['CUSTOMER.TYPE.count_designers']}
                        </span>
                        <span className="kt-widget__data">
                          {group.count_designers}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="kt-widget__footer">
                    <Button
                      as={Link}
                      variant="label-warning"
                      to={urlPageGroupDetails({ groupId: group.id })}
                      className="btn-upper font-weight-bold"
                    >
                      {lang['GROUP.VIEW']}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const mapStoreToProps = (store) => ({
  lang: store.language.lang,
  groups: store.groups.groups,
});

ListGroups.propTypes = propTypes;

export default connect(mapStoreToProps)(ListGroups);
