import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Table from 'react-bootstrap/Table';
import Modal from '../../widgets/Modal';
import BillingHeaderTable from './BillingHeaderTable';
import BillingItemTable from './BillingItemTable';



const propTypes = {
  lang: PropTypes.object.isRequired,
  showBillingItemModal: PropTypes.bool.isRequired,
  setBillingItemModal: PropTypes.func.isRequired,
  data: PropTypes.array,
  skip: PropTypes.number,
  countOnPage: PropTypes.number,
  invoiceStatus: PropTypes.any,
  invoiceDate: PropTypes.object.isRequired,
};

const unpaidInvoiceStatus = 0;

/**
 * Component of billing modal item
 *
 * @returns {*}
 */
const BillingItemModal = ({
  lang,
  setBillingItemModal,
  showBillingItemModal,
  data,
  invoiceDate,
  invoiceStatus,
}) => {
  return (
    <Modal
      close={() => {
        setBillingItemModal(false);
      }}
      full
      autoheight
      title={`${lang[invoiceStatus === unpaidInvoiceStatus ?
        'BILLING.BILLING_READY_TO_PAY_ITEM_HEADER' :
        'BILLING.BILLING_PAYED_ITEM_HEADER']} ${invoiceDate.from} - ${invoiceDate.to}`}
      subtitle={invoiceStatus === unpaidInvoiceStatus ? '' :
        `${lang['BILLING.BILLING_PAYED_ITEM_SUBHEADER']} - ${data.length}`}
      show={showBillingItemModal}
      bodyStyle={{ overflowY: 'auto' }}
      showFooter={false}
    >
      <div className='mw-100 overflow-auto'>
        <Table
          striped
          className='vertical-align-middle text-center kt-font-md'
          size="sm"
        >
          <BillingHeaderTable lang={lang} />
          <tbody>
            {data.map((task) => {
              return (
                <BillingItemTable
                  key={task.id}
                  task={task}
                />
              );
            })}
          </tbody>
        </Table>
      </div>
    </Modal>
  );
};

export const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

BillingItemModal.propTypes = propTypes;

export default connect(mapStoreToProps)(BillingItemModal);
