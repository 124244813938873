import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import { useHistory, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import Preload from '../../../widgets/Preload';
import KTUtil from '../../../../_metronic/_assets/js/util';
import Paginator from '../../../widgets/Paginator';
import { parseFromPars, parseToPars } from '../../../utils/parseUrlParams';
import projectsAction from '../../../store/projects/actions';
import { arrayOfIntValidator } from '../../../utils/validators';
import { formatDateTimeToString, formatDateToString, setDateHours } from '../../../utils/formats';
import { mapCategoryIdToName, mapCategoryNameToId } from '../../../requests/categories';
import { globalTypesProject, projectStatuses } from '../projectTypes';
import { status } from '../../../utils/statusToColor';
import Modal from '../../../widgets/Modal';
import isCategoryInProjectCategories from '../helpers/isCategoryInProjectCategories';
import ProjectsList from './components/ProjectsList';
import { ProjectsListContext } from './components/ProjectsListContext';
import ProjectsFilter from './components/ProjectsFilter';
import ProjectsListSubheader from './components/ProjectsListSubheader';



export const initialFilter = {
  show: false,
  project_ids: [],
  created: {
    from: '',
    to: '',
  },
  deadline: {
    from: '',
    to: '',
  },
  hc_release_date: {
    from: '',
    to: '',
  },
  hc_content_type: '',
  taps: {
    from: '',
    to: '',
  },
  tags: [],
  category_id: '',
  image_type: '',
  priority: '',
  executor: '',
  paid: '',
  title: '',
  stage: '',
  step_status: '',
  sort: {
    orderBy: '',
    sortedBy: '',
  },
  status: '',
};

const Index = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { type } = useParams();
  const [ paginator, setPaginator ] = useState({
    page: 1,
    limit: 50,
  });
  const [ selectedProjects, setSelectedProjects ] = useState([]);
  const [ categoryId, setCategoryId ] = useState('');
  const [ showCategoriesModal, setShowCategoriesModal ] = useState(false);

  const [ updatedProject, setUpdatedProject ] = useState({});

  const [ projectId, setProjectId ] = useState('');
  const [ filter, setFilter ] = useState(initialFilter);
  const [ tableView, setTableView ] = useState(!!Number(localStorage.getItem('project_type_view')));
  const { loading, lang, categories, totalProjects } = useSelector(({ projects, language, categories }) => ({
    projects: projects.projects,
    totalProjects: projects[`total_${type}`],
    loading: projects.isLoading,
    categories,
    lang: language.lang,
  }), shallowEqual);

  const categoryIdValue = mapCategoryIdToName(categories, categoryId);

  useEffect(async () => {
    const queryParams = parseFromPars(history.location.search);

    setPaginator({
      page: queryParams?.page || 1,
      limit: queryParams?.limit || 50,
    });
    setFilter((prevState) => ({
      ...prevState,
      ...queryParams,
    }));
  }, []);

  useEffect(() => {
    const queryParams = parseFromPars(history.location.search);

    queryParams.limit = paginator.limit;
    queryParams.page = paginator.page;
    history.push(history.location.pathname + parseToPars(queryParams));
  }, [ paginator.page, paginator.limit ]);

  const getProjects =  () => {
    const queryParams = parseFromPars(history.location.search);
    const updatedQueryParams = type === projectStatuses.archived
      ? { ...queryParams, status: projectStatuses.archived }
      : queryParams;

    dispatch(projectsAction.fetchProjects({
      queryParams: updatedQueryParams,
      type,
    }));
  };

  useEffect(() => {
    const queryParams = parseFromPars(history.location.search);

    setPaginator({
      page: 1,
      limit: paginator.limit,
    });
    queryParams.page = 1;
    queryParams.limit = paginator.limit;
    queryParams.stage = filter.stage;
    queryParams.step_status = filter.step_status;

    history.push(history.location.pathname + parseToPars(queryParams));
  }, [ filter.stage, filter.step_status ]);

  useEffect(() => {
    const queryParams = parseFromPars(history.location.search);
    const pars = parseToPars({
      ...queryParams,
      sort: {
        ...filter.sort,
      },
      page: queryParams?.page || 1,
      limit: paginator.limit,
      show: undefined,
    });

    history.push(history.location.pathname + pars);
  }, [ filter.sort ]);

  useEffect(() => {
    const queryParams = parseFromPars(history.location.search);

    setPaginator({
      page: queryParams?.page || 1,
      limit: queryParams?.limit || 50,
    });
    setFilter({
      ...initialFilter,
      ...queryParams,
    });

    if (!loading) {
      getProjects();
    }
  }, [ history.location.search ]);

  const resetFilter = () => {
    const queryParams = parseFromPars(history.location.search);

    setFilter(({
      ...initialFilter,
      stage: queryParams.stage,
      step_status: queryParams.step_status,
    }));

    setPaginator({
      limit: paginator.limit,
      page: 1,
    });

    history.push(history.location.pathname + parseToPars({
      ...initialFilter,
      limit: paginator.limit,
      page: 1,
      show: undefined,
      stage: queryParams.stage,
      step_status: queryParams.step_status,
    }));
  };

  const toggleFilter = useCallback(() => {
    if (!filter.show) {
      KTUtil.scrollTop(0, 300);
    }
    setFilter((prevState) => ({
      ...prevState,
      show: !prevState.show,
    }));
  }, [ filter.show ]);

  const onFilterApply = async () => {
    toggleFilter();

    const pars = parseToPars({
      ...filter,
      page: 1,
      limit: paginator.limit,
      show: undefined,
    });

    history.push(history.location.pathname + pars);
  };

  const onFilterRemove = (filterItem) => {
    setFilter((prevState) => ({
      ...prevState,
      [filterItem]: initialFilter[filterItem],
    }));

    const queryParams = parseFromPars(history.location.search);

    // eslint-disable-next-line fp/no-delete
    delete queryParams[filterItem];
    history.push(history.location.pathname + parseToPars(queryParams));
  };

  const onFilterChange = (event) => {
    const field = event?.target?.name;
    const value = event?.target?.value;

    switch (event?.target?.name) {
      case 'project_ids':
        setFilter((prevState) => ({
          ...prevState,
          [field]: arrayOfIntValidator(value),
        }));
        break;
      case 'stage':
      case 'step_status':
      case 'title':
      case 'priority':
      case 'executor':
      case 'image_type':
      case 'hc_content_type':
        setFilter((prevState) => ({
          ...prevState,
          [field]: value,
        }));
        break;
      case 'sort':
        // eslint-disable-next-line no-case-declarations
        const order = Object.entries(value);

        setFilter((prevState) => ({
          ...prevState,
          [field]: {
            orderBy: order.length ? order[0][0] : '',
            sortedBy: order.length ? order[0][1] : '',
          },
        }));

        break;
      case 'created_from':
      case 'created_to':
      case 'deadline_from':
      case 'deadline_to':
        // eslint-disable-next-line no-case-declarations
        const parts = field.split('_');

        setFilter((prevState) => ({
          ...prevState,
          [parts[0]]: {
            ...prevState[parts[0]],
            [parts[1]]: formatDateToString(value),
          },
        }));
        break;
      case 'hc_release_date_from':
        setFilter((prevState) => ({
          ...prevState,
          hc_release_date: {
            ...prevState.hc_release_date,
            'from': formatDateTimeToString(setDateHours(value)),
          },
        }));
        break;
      case 'hc_release_date_to':
        setFilter((prevState) => ({
          ...prevState,
          hc_release_date: {
            ...prevState.hc_release_date,
            'to': formatDateTimeToString(setDateHours(value)),
          },
        }));
        break;
      case 'taps':
        setFilter((prevState) => {
          const preparedFilter = { ...prevState };

          if (!value) {
            preparedFilter.taps = { ...initialFilter.taps };
          } else {
            const per = event.target.value.split('-');

            preparedFilter.taps = {
              from: per[0],
              to: per[1],
            };
          }

          return {
            ...preparedFilter,
          };
        });
        break;
      case 'tags_id':
        setFilter((prevState) => ({
          ...prevState,
          tags: value.tags.map((tag) => tag.slug),
          tags_id: value.id,
        }));
        break;
      case 'category_id':
        setFilter((prevState) => ({
          ...prevState,
          [field]: mapCategoryNameToId(categories, value),
        }));
        break;
      default:
        break;
    }
  };

  const setPage = useCallback(async (page) => {
    await setPaginator((prevState) => ({
      ...prevState,
      page: page + 1,
    }));
  }, [ paginator.page ]);

  const onItemsPerPageChange = useCallback((event) => {
    setPaginator((prevState) => ({
      ...prevState,
      limit: event.target.value,
    }));
  }, [ paginator.filter ]);

  const startProjects = async () => {
    const queryParams = parseFromPars(window.location.search);

    await dispatch(projectsAction.startProjects({
      ids: selectedProjects,
      queryParams,
      type,
    }));
    setSelectedProjects([]);
  };

  const updateProjectCategory = () => {
    dispatch(projectsAction.updateProjectCategory({
      projectId,
      project: updatedProject,
      categoryId,
      projectType: type,
    }));
  };

  const setModalCategories = (val) => {
    setShowCategoriesModal(val);
  };

  const toggleTableView = () => {
    localStorage.setItem('project_type_view', tableView ? '0' : '1');
    setTableView((prevState) => !prevState);
  };

  return loading ? (
    <Preload />
  ) : (
    <ProjectsListContext.Provider
      value={{
        filter,
        type,
        canSplit: type === globalTypesProject.COLORING,
        isSelectedStage: !isEmpty(filter.stage),
        selectedProjects,
        canSelected: filter.status === status.draft,
        onSelectProject: setSelectedProjects,
        onFilterChange,
        tableView,
        setShowCategoriesModal: setModalCategories,
      }}
    >
      <ProjectsListSubheader
        toggleFilter={toggleFilter}
        total={totalProjects}
        toggleViewType={toggleTableView}
        removeFilter={onFilterRemove}
        onFilterChange={onFilterChange}
        startProjects={startProjects}
        paginator={(
          <>
            <div className="kt-ml-5">
              <OverlayTrigger
                placement="bottom"
                overlay={(
                  <Tooltip id="COUNT_ON_PAGE">
                    {lang['GLOBAL.COUNT_ON_PAGE']}
                  </Tooltip>
                )}
              >
                <Form.Group className="d-flex kt-m-0 align-items-center">
                  <Form.Control
                    size="sm"
                    as="select"
                    title={(
                      <Tooltip id="COUNT_ON_PAGE">
                        {lang['GLOBAL.COUNT_ON_PAGE']}
                      </Tooltip>
                    )}
                    value={paginator.limit}
                    onChange={onItemsPerPageChange}
                  >
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </Form.Control>
                </Form.Group>
              </OverlayTrigger>
            </div>
            <div className="kt-ml-5">
              <Paginator
                active={paginator.page - 1}
                count={Math.floor(totalProjects / paginator.limit) - (totalProjects % paginator.limit === 0 ? 1 : 0)}
                select={setPage}
              />
            </div>
          </>
        )}
      />
      <div className="row">
        <ProjectsFilter
          toggleFilter={toggleFilter}
          fromStatsPage={false}
          onFilterApply={onFilterApply}
          onFilterChange={onFilterChange}
          resetFilter={resetFilter}
        />
        <ProjectsList
          setProjectId={setProjectId}
          setCategoryId={setCategoryId}
          setUpdatedProject={setUpdatedProject}
        />
      </div>

      <Modal
        title={lang['GLOBAL.CATEGORY']}
        show={showCategoriesModal}
        bodyStyle={{ overflow: 'unset' }}
        close={() => {
          setShowCategoriesModal(false);
        }}
        footer={(
          <button
            className="btn btn-secondary"
            onClick={() => {
              setShowCategoriesModal(false);
              updateProjectCategory();
            }}
          >
            OK
          </button>
        )}
      >
        <div className="d-flex flex-column">
          <Form.Group className="kt-mb-0">
            <Form.Label>
              {lang['GLOBAL.CATEGORY_ADD']}
            </Form.Label>
            <Form.Control
              as="select"
              value={categoryIdValue}
              onChange={(event) => {
                const categoryId = mapCategoryNameToId(categories, event.target.value);

                setCategoryId(categoryId);
              }}
            >
              <option value="" />
              {categories.map((item) => {
                if (updatedProject && isCategoryInProjectCategories(updatedProject, item)) {
                  return;
                }
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>
      </Modal>
    </ProjectsListContext.Provider>
  );
};

export default Index;
