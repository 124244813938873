import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';



const TasksGroupChooseSubheader = ({ isStarted, selectedGroup, groups, onGroupChanged, applyGroup, startTasks }) => {
  const lang = useSelector((store) => store.language.lang);
  const userToken = useSelector((store) => store.user.user.token);
  const canOpenTesting = false;

  const groupsOptionsList = () => {
    const options = [ <option key="0" value="" disabled>{lang['GLOBAL.CHOOSE_GROUP']}</option> ];

    if (!groups || groups.length === 0) {
      return options;
    }

    return options.concat(groups.map((group) => {
      return <option key={group.id} value={group.id}>{group.name}</option>;
    }));
  };

  return (
    <SubHeader
      size="sm"
      title={lang['TASK.TASK']}
      info=""
      main={canOpenTesting && (
        <Button
          variant={'info'}
          as={'a'}
          target='_blank'
          href={`coloring://q?testing_token=${userToken}`}
          className='d-inline-flex align-items-center'
        >
          <i className="la la-mobile-phone kt-font-lg" />
          <span>{lang['TASK.TEST_DEV']}</span>
        </Button>
      )}
      mainMobileSm={canOpenTesting && (
        <Button
          variant={'info'}
          as={'a'}
          size="sm"
          target='_blank'
          href={`coloring://q?testing_token=${userToken}`}
          className='d-inline-flex align-items-center'
        >
          <i className="la la-mobile-phone kt-font-lg" />
          <span>{lang['TASK.TEST_DEV']}</span>
        </Button>
      )}

      toolbar={<div className="kt-widget__action">
        <div className='kt-ml-8 kt-container kt-container--fluid'>
          {isStarted ? <>
            <OverlayTrigger
              placement="bottom"
              overlay={
                <Tooltip id={'CHOOSE_GROUP'}>
                  {lang['GLOBAL.CHOOSE_GROUP']}
                </Tooltip>
              }
            >
              <Form.Group className='d-flex kt-m-0 kt-ml-5 align-items-center'>
                <Form.Control
                  size="sm"
                  as={'select'}
                  value={selectedGroup}
                  onChange={(event) => onGroupChanged(event.target.value)}
                >
                  {groupsOptionsList()}
                </Form.Control>
              </Form.Group>
            </OverlayTrigger>
            <Button
              variant="label-success"
              size="md"
              disabled={selectedGroup === ''}
              className='btn-bold'
              onClick={applyGroup}
            >
              <span>{lang['GLOBAL.APPLY']}</span>
            </Button></>
            : (<Button variant="info" size="md" onClick={startTasks}><span>{lang['GLOBAL.TAKE_TO_WORK']}</span></Button>)}
        </div>
      </div>
      }
    />
  );
};

TasksGroupChooseSubheader.propTypes = {
  isStarted: PropTypes.bool.isRequired,
  selectedGroup: PropTypes.string.isRequired,
  groups: PropTypes.array.isRequired,
  onGroupChanged: PropTypes.func.isRequired,
  applyGroup: PropTypes.func.isRequired,
  startTasks: PropTypes.func.isRequired,
};

export default TasksGroupChooseSubheader;

