import actions from './actions';



const initialState = {
  loadingTemplate: false,
  loadingTemplates: false,
  loadingTemplateForm: false,
  template: {},
  templates: [],
  templateForm: [],
};

export const TagSystemTemplatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING_TEMPLATES: {
      return {
        ...state,
        loadingTemplates: action.payload,
      };
    }
    case actions.LOADING_TEMPLATE: {
      return {
        ...state,
        loadingTemplate: action.payload,
      };
    }
    case actions.LOADING_TEMPLATE_FORM: {
      return {
        ...state,
        loadingTemplateForm: action.payload,
      };
    }
    case actions.SET_TEMPLATE: {
      return {
        ...state,
        template: action.payload,
      };
    }
    case actions.SET_TEMPLATES: {
      return {
        ...state,
        templates: action.payload,
      };
    }
    case actions.SET_TEMPLATE_FORM: {
      return {
        ...state,
        templateForm: action.payload,
      };
    }
    case actions.ADD_TEMPLATE: {
      return {
        ...state,
        templates: [ action.payload, ...state.templates ],
      };
    }
    case actions.UPDATE_TEMPLATE: {
      return {
        ...state,
        templates: state.templates.map((template) => {
          if (template.id === action.payload.id) {
            return {
              ...template,
              ...action.payload,
            };
          }
          return template;
        }),
      };
    }
    case actions.DELETE_TEMPLATE: {
      return {
        ...state,
        templates: state.templates.filter((template) => template.id !== action.payload),
      };
    }
    default: {
      return state;
    }
  }
};
