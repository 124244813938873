// eslint-disable-next-line import/no-namespace
import * as Yup from 'yup';
import { isBYPersonalData, isRU, isSwiftRU, isTransitIban } from '../../../utils/checker';


// const commonFields = {
//   personal: [
//     'username',
//     'password',
//     'cpassword',
//     'firstname',
//     'patronymic',
//     'lastname',
//     'birthday',
//     'telegram',
//     'phone',
//   ],
//   document: [
//     'passport_number',
//     'expiration_date',
//   ],
//   finance: [],
// };

// ugly hack with lang for correct translation
export const fabrosTabs = (lang) => ([
  {
    step: 1,
    title: lang['REGISTRATION.PERSONAL_INFORMATION'],
    fields: [
      // common fields
      'username',
      'password',
      'cpassword',
      'firstname',
      'patronymic',
      'lastname',
      'birthday',
      'telegram',
      'phone',
      // end common
      'citizenship',
      'residence',
      'has_residence_confirmation',
      'has_been_on_territory',
    ],
  },
  {
    step: 2,
    title: lang['REGISTRATION.DOCUMENT_INFORMATION'],
    fields: [
      'personal_number',
      'passport_number',
      'organization',
      'date_of_issue',
      'expiration_date',
      'insurance_number',
    ],
  },
  {
    step: 3,
    title: lang['REGISTRATION.FINANCE_INFORMATION'],
    fields: [
      'country',
      'city',
      'address',
      'iban',
      'swift',
      'transit_iban',
      'bic_number',
      'is_allow_conversion',
    ],
  },
  {
    step: 4,
    title: lang['REGISTRATION.COMPLETE'],
    fields: [],
  },
]);

export const xflowTabs = (lang) => ([
  {
    step: 1,
    title: lang['REGISTRATION.PERSONAL_INFORMATION'],
    fields: [
      'username',
      'password',
      'cpassword',
      'firstname',
      'patronymic',
      'lastname',
      'birthday',
      'telegram',
      'phone',
      // end common fields
      // 'citizenship',
      // 'residence',
    ],
  },
  {
    step: 2,
    title: lang['REGISTRATION.DOCUMENT_INFORMATION'],
    fields: [
      // common fields
      'passport_number',
      'expiration_date',
      // end common
      'country_issuing_document',
    ],
  },
  {
    step: 3,
    title: lang['REGISTRATION.FINANCE_INFORMATION'],
    fields: [
      'country',
      'city',
      'address',
      'post_code',
      'tin_country',
      'tin_number',
      'iban',
      'swift',
      'transit_iban',
      'bank',
      'bank_country',
      'bank_account_name',
      'bic_number',
      'inn',
    ],
  },
  {
    step: 4,
    title: lang['REGISTRATION.COMPLETE'],
    fields: [],
  },
]);

export const fabrosDefaultFormValues = (lang) => ({
  password: '',
  cpassword: '',
  username: '',
  firstname: '',
  lastname: '',
  patronymic: '',
  telegram: '',
  phone: '',
  citizenship: '',
  residence: '',
  has_residence_confirmation: {
    value: 0,
    label: lang['REGISTRATION.RESIDENCE_CONFIRMATION_NO'],
  },
  has_been_on_territory: {
    value: 0,
    label: lang['REGISTRATION.RESIDENCE_CONFIRMATION_NO'],
  },
  birthday: '',

  personal_number: '',
  passport_number: '',
  organization: '',
  date_of_issue: '',
  expiration_date: '',
  insurance_number: '',

  country: '',
  city: '',
  address: '',
  iban: '',
  swift: '',
  bic_number: '',
  transit_iban: '',
  is_allow_conversion: false,
  is_employee: false,
  is_privacy_policy_applied: false,
});

export const xflowDefaultFormValues = (_lang) => ({
  password: '',
  cpassword: '',
  username: '',
  firstname: '',
  lastname: '',
  patronymic: '',
  telegram: '',
  phone: '',
  birthday: '',

  passport_number: '',
  expiration_date: '',
  country_issuing_document: '',

  country: '',
  city: '',
  address: '',
  iban: '',
  transit_iban: '',
  swift: '',
  bic_number: '',
  bank: '',
  bank_country: '',
  bank_account_name: '',
  tin_country: '',
  tin_number: '',
  post_code: '',
  is_allow_conversion: false,
  is_employee: false,
  is_privacy_policy_applied: false,
  is_term_applied: false,
  inn: '',
});

const cyrillicNameRegExp = /^[а-яёА-ЯЁ ]*$/;
const ruNameRegExp = /^[а-яёА-ЯЁa-zA-Z\d- ]*$/;
const latinNameRegExp = /^[a-zA-Z\d- ]*$/;

export const fabrosValidationSchema = Yup.lazy((values) => {
  return Yup.object().shape({
    password: Yup.string().trim().min(8).required(),
    cpassword: Yup.string().test(
      'check re-entered password',
      'Wrong re-entered password',
      (val) => {
        return val === values.password;
      },
    ).required(),
    citizenship: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    residence: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).nullable().test(
      'residence',
      'This field is required',
      (val) => {
        return !(values.has_residence_confirmation.value && !val);
      }
    ),
    username: Yup.string().min(6).max(30).matches(/^[a-zA-Z0-9._-]*$/).required(),
    firstname: Yup.string().max(50).required().test(
      'firstname',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return cyrillicNameRegExp.test(val);
        }
        if (isRU(values)) {
          return ruNameRegExp.test(val);
        }

        return latinNameRegExp.test(val);
      }
    ),
    patronymic: Yup.string().nullable().test(
      'patronymic',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return cyrillicNameRegExp.test(val);
        }
        if (isRU(values)) {
          return ruNameRegExp.test(val);
        }

        return latinNameRegExp.test(val);
      }
    ),
    lastname: Yup.string().required().test(
      'lastname',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return cyrillicNameRegExp.test(val);
        }
        if (isRU(values)) {
          return ruNameRegExp.test(val);
        }

        return latinNameRegExp.test(val);
      }
    ),
    address: Yup.string().trim().min(1).max(96).required().test(
      'address',
      'Check this field',
      (val) => {
        if (isBYPersonalData(values)) {
          return /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/.test(val);
        }
        if (isRU(values)) {
          return /^[а-яёйА-ЯЁЙa-zA-Z0-9.,\-/ ]+$/.test(val);
        }

        return /^[a-zA-Z0-9.,\-/ ]+$/.test(val);
      }
    ),
    city: Yup.string().trim().min(1).max(250).required().test(
      'city',
      'Check city field',
      (val) => {
        if (isBYPersonalData(values)) {
          return /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/.test(val);
        }
        if (isRU(values)) {
          return /^[а-яёйА-ЯЁЙa-zA-Z0-9.,\-/ ]+$/.test(val);
        }

        return /^[a-zA-Z0-9.,\-/ ]+$/.test(val);
      }
    ),
    phone: Yup.string().trim().min(1).max(20).matches(/^[^*]+$/).required(),
    telegram: Yup.string().trim().min(4).nullable(),
    birthday: Yup.string().required(),
    personal_number: Yup.string().nullable().test(
      'personal_number',
      'Field should not be empty',
      (val) => {
        if (isBYPersonalData(values)) {
          return val.trim().length === 14 && !val.includes('*');
        }

        return true;
      }
    ),
    passport_number: Yup.string().trim().max(250).matches(/^[^*]+$/).nullable(),
    organization: Yup.string().trim().max(250).matches(/^[^*]+$/).required(),
    date_of_issue: Yup.string().required(),
    expiration_date: Yup.string().required(),
    insurance_number: Yup.string().max(25).nullable(),
    iban: Yup.string().trim().min(1).max(34).matches(/^[A-Za-z0-9]+$/).required(),
    transit_iban: Yup.string().nullable().test(
      'transit_iban',
      'Wrong transit iban',
      (val) => {
        if (val && isTransitIban(values)) {
          return val.trim().length >= 2 && val.trim().length <= 50 && !val.includes('*');
        }

        return true;
      },
    ),
    swift: Yup.string().trim().min(8).max(11).required(),
    country: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    is_allow_conversion: Yup.mixed().required().oneOf(isBYPersonalData(values) ? [ true ] : [ true, false ]),
    has_been_on_territory: isBYPersonalData(values) ? Yup.object().shape({
      value: Yup.number().oneOf([ 0, 1 ]).required(),
    }).required() : Yup.object().shape({
      value: Yup.number().oneOf([ 0, 1 ]),
    }).nullable(),
    has_residence_confirmation: isBYPersonalData(values) ? Yup.object().shape({
      value: Yup.number().oneOf([ 0, 1 ]).required(),
    }).required() : Yup.object().shape({
      value: Yup.boolean().transform((value) => {
        return !!value;
      }),
    }).nullable(),
    bic_number: Yup.string().nullable().test(
      'bic_number',
      'Wrong bic number',
      (val) => {
        if (isSwiftRU(values)) {
          const pattern = /^\d{9}$/;

          return pattern.test(val);
        }

        return true;
      },
    ),
    is_privacy_policy_applied: Yup.boolean().required(),
    inn: isRU(values) ? Yup.string().required().test(
      'inn',
      'Wrong INN',
      (val) => {
        const pattern = /^\d{12}$/;

        return pattern.test(val);
      },
    ) : Yup.string().nullable(),
  });
});

export const xflowValidationSchema = Yup.lazy((values) => {
  return Yup.object().shape({
    password: Yup.string().trim().min(8).required(),
    cpassword: Yup.string().test(
      'check re-entered password',
      'Wrong re-entered password',
      (val) => {
        return val === values.password;
      },
    ).required(),
    username: Yup.string().min(6).max(30).matches(/^[a-zA-Z0-9._-]*$/).required(),
    firstname: Yup.string().max(50).matches(isBYPersonalData(values) ? cyrillicNameRegExp : latinNameRegExp).required(),
    patronymic: Yup.string().matches(isBYPersonalData(values) ? cyrillicNameRegExp : latinNameRegExp).nullable(),
    lastname: Yup.string().matches(isBYPersonalData(values) ? cyrillicNameRegExp : latinNameRegExp).required(),
    address: Yup.string().trim().min(1).max(250).matches(isBYPersonalData(values) ? /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/ : /^[a-zA-Z0-9.,\-/ ]+$/).required(),
    city: Yup.string().trim().min(1).max(250).matches(isBYPersonalData(values) ? /^[а-яёйА-ЯЁЙ0-9.,\-/ ]+$/ : /^[a-zA-Z0-9.,\-/ ]+$/).required(),
    phone: Yup.string().trim().min(1).max(20).matches(/^[^*]+$/).required(),
    telegram: Yup.string().trim().min(4).nullable(),
    birthday: Yup.string().required(),
    passport_number: Yup.string().trim().max(250).matches(/^[^*]+$/).nullable(),
    expiration_date: Yup.string().required(),
    iban: Yup.string().trim().min(1).max(34).matches(/^[A-Za-z0-9]+$/).required(),
    transit_iban: Yup.string().nullable().test(
      'transit_iban',
      'Wrong transit iban',
      (val) => {
        if (val && isTransitIban(values)) {
          return val.trim().length >= 2 && val.trim().length <= 50 && !val.includes('*');
        }

        return true;
      },
    ),
    swift: Yup.string().trim().min(8).max(11).required(),
    country: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    is_allow_conversion: isBYPersonalData(values) ? Yup.boolean().required() : Yup.boolean().nullable(),
    country_issuing_document: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    bic_number: Yup.string().nullable().test(
      'bic_number',
      'Wrong bic number',
      (val) => {
        if (isSwiftRU(values)) {
          const pattern = /^\d{9}$/;

          return pattern.test(val);
        }

        return true;
      },
    ),
    bank: Yup.string().max(190).matches(/^[/a-zA-Z\d.\-_ ']+$/).required(),
    bank_country: Yup.object().shape({
      value: Yup.number().required(),
      label: Yup.string().required(),
    }).required(),
    bank_account_name: Yup.string().max(191).matches(/^[a-zA-Z\d-_ ']+$/).required(),
    tin_country: Yup.object().shape({
      value: Yup.string().length(2).required(),
      label: Yup.string().required(),
    }).required(),
    tin_number: Yup.string().trim().max(190).required(),
    post_code: Yup.string().trim().max(190).required(),
    is_privacy_policy_applied: Yup.boolean().required(),
    is_term_applied: Yup.boolean().required(),
  });
});

