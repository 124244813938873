import { checkDelay, setDelay } from './delay';



const setItem = (key, value) => {
  localStorage.setItem(key, value);
};

const getItem = (key) => {
  return localStorage.getItem(key);
};

const removeItem = (key) => {
  localStorage.removeItem(key);
};

const isValid = (key) => {
  return Boolean(getItem(key) && checkDelay(key));
};

const get = (key) => {
  if (checkDelay(key)) {
    return getItem(key);
  }

  removeItem(key);

  return null;
};

const set = (key, value) => {
  setDelay(key);
  setItem(key, value);
};

export const cache = {
  get, set, isValid,
};
