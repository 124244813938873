import React from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import objectPath from 'object-path';
import { selectors } from '../../../ducks/builder';
import KTMenu from '../../../_assets/js/menu';
import KTOffcanvas from '../../../_assets/js/offcanvas';
import { toAbsoluteUrl } from '../../../../app/utils/gettersUrl';
import checkRole from '../../../../app/utils/checkRole';
import HMenuItem from './HMenuItem';
import urlPageSuggestion from '../../../../app/urls/urlPageSuggestion';



const offcanvasOptions = {
  overlay: true,
  baseClass: 'kt-header-menu-wrapper',
  closeBy: 'kt_header_menu_mobile_close_btn',
  toggleBy: {
    target: 'kt_header_mobile_toggler',
    state: 'kt-header-mobile__toolbar-toggler--active',
  },
};

class HMenu extends React.Component {
    offCanvasCommonRef = React.createRef();

    ktMenuCommonRef = React.createRef();

    getHeaderLogo () {
      let result = 'logo-light.png';

      if (this.props.headerSelfSkin && this.props.headerSelfSkin !== 'dark') {
        result = 'logo-dark.png';
      }
      return toAbsoluteUrl(`/media/logos/${result}`);
    }

    get currentUrl () {
      return this.props.location.pathname.split(/[?#]/)[0];
    }

    componentDidMount () {
      // Canvas
      this.initOffCanvas();
      // Menu
      this.initKTMenu();
    }

    initOffCanvas = () => {
      // eslint-disable-next-line no-undef
      new KTOffcanvas(this.offCanvasCommonRef.current, offcanvasOptions);
    };

    initKTMenu = () => {
      const menuOptions = {
        submenu: {
          desktop: 'dropdown',
          tablet: 'accordion',
          mobile: 'accordion',
        },
        accordion: {
          slideSpeed: 200, // accordion toggle slide speed in milliseconds
          expandAll: false, // allow having multiple expanded accordions in the menu
        },
        dropdown: {
          timeout: 50,
        },
      };

      let menuDesktopMode = 'accordion';

      if (
        this.ktMenuCommonRef.current.getAttribute('data-ktmenu-dropdown') === '1'
      ) {
        menuDesktopMode = 'dropdown';
      }

      if (typeof objectPath.get(menuOptions, 'submenu.desktop') === 'object') {
        menuOptions.submenu.desktop = {
          default: menuDesktopMode,
        };
      }

      // eslint-disable-next-line no-undef
      new KTMenu(this.ktMenuCommonRef.current, menuOptions);
    };

    render () {
      const {
        disabledAsideSelfDisplay,
        ktMenuClasses,
        ulClasses,
        rootArrowEnabled,
        roles,
        pages,
      } = this.props;

      return (
        <>
          <div
            className="kt-header-menu-wrapper"
            id="kt_header_menu_wrapper"
            ref={this.offCanvasCommonRef}
          >
            <button
              className="kt-header-menu-wrapper-close"
              id="kt_header_menu_mobile_close_btn"
            >
              <i className="la la-close" />
            </button>
            {disabledAsideSelfDisplay && (
              <div className="kt-header-logo">
                <Link to="/">
                  <img alt="logo" src={this.getHeaderLogo()} />
                </Link>
              </div>
            )}

            <div
              id="kt_header_menu"
              className={`kt-header-menu kt-header-menu-mobile ${ktMenuClasses}`}
              ref={this.ktMenuCommonRef}
            >
              <ul className={`kt-menu__nav ${ulClasses}`}>
                {pages.filter((p) => (
                  p.header &&
                                ((p.condition && typeof p.condition === 'function') ? p.condition() : true) &&
                                checkRole(roles, p.roles, p.antiRoles || [])
                )).map((item, index) => {

                  return (
                    <HMenuItem
                      key={index}
                      item={{ ...item, alignment: 'left', icon: null }}
                      currentUrl={this.currentUrl}
                      rootArrowEnabled={rootArrowEnabled}
                    />
                  );
                })}
                <HMenuItem
                  item={{
                    title: 'Идеи и предлжения',
                    root: true,
                    alignment: 'left',
                    page: urlPageSuggestion(),
                    translate: 'MENU.SUGGESTION',
                  }}
                  currentUrl={this.currentUrl}
                  rootArrowEnabled={rootArrowEnabled}
                />
              </ul>
            </div>
          </div>
        </>
      );
    }
}

const mapStateToProps = (store) => ({
  roles: store.user.roles,
  config: store.builder.layoutConfig,
  ktMenuClasses: selectors.getClasses(store, {
    path: 'header_menu',
    toString: true,
  }),
  rootArrowEnabled: selectors.getConfig(
    store,
    'header.menu.self.root-arrow',
  ),
  headerSelfSkin: selectors.getConfig(store, 'header.self.skin'),
  ulClasses: selectors.getClasses(store, {
    path: 'header_menu_nav',
    toString: true,
  }),
  disabledAsideSelfDisplay: objectPath.get(store.builder.layoutConfig, 'aside.self.display') === false,
  pages: store.pages,
});

export default withRouter(connect(mapStateToProps)(HMenu));
