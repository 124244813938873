import React from 'react';
import PropTypes from 'prop-types';
import WidgetContent from './components/WidgetContent';
import WidgetMedia from './components/WidgetMedia';
import WidgetBottom from './components/WidgetBottom';



const TaskInfo = ({
  task,
  showTagsModal,
  showImageTypeModal,
  showContourComplexityLevelModal,
  showColorComplexityLevelModal,
  userId,
  openChat,
  removeTask,
  isStarted,
}) => {
  return (
    <div className="kt-portlet">
      <div className="kt-portlet__body">
        <div className="kt-widget kt-widget--user-profile-3 relative">
          <a
            role="link"
            tabIndex={0}
            onKeyDown={() => {}}
            className='kt-demo-panel__close float-right'
            onClick={() => {
              removeTask(task.id);
            }}
          >
            <i className="flaticon2-delete" />
          </a>
          <div className="kt-widget__top">
            <WidgetMedia task={task} />
            <WidgetContent
              task={task}
              userId={userId}
              showModal={showImageTypeModal}
              showContourComplexityLevelModal={showContourComplexityLevelModal}
              showColorComplexityLevelModal={showColorComplexityLevelModal}
              removeTask={removeTask}
            />
          </div>
          <WidgetBottom
            userId={userId}
            task={task}
            showModal={showTagsModal}
            openChat={openChat}
            isStarted={isStarted}
          />
        </div>
      </div>
    </div>
  );
};

TaskInfo.propTypes = {
  task: PropTypes.object.isRequired,
  showTagsModal: PropTypes.func.isRequired,
  showImageTypeModal: PropTypes.func.isRequired,
  showContourComplexityLevelModal: PropTypes.func.isRequired,
  showColorComplexityLevelModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  openChat: PropTypes.func.isRequired,
  removeTask: PropTypes.func.isRequired,
  isStarted: PropTypes.bool.isRequired,
};

TaskInfo.defaultProps = {
  userId: 0,
};

export default TaskInfo;
