import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Form from 'react-bootstrap/Form';
import Modal from '../../../widgets/Modal';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const ApplyTaskModal = ({ canBonus, canSetTags, finishTask, setModalApplyTask, showModalApplyTask }) => {
  const lang = useSelector((state) => state.language.lang);
  const userRoles = useSelector((store) => store.user.roles);
  const [ time, setTime ] = useState(null);

  return (
    <Modal
      title={(canBonus && canSetTags) ? lang['TASK.YOU_SURE_ACCEPT_BONUS_TAGS'] : (
        canBonus ? lang['TASK.YOU_SURE_ACCEPT_BONUS'] : (
          canSetTags ? lang['TASK.YOU_SURE_ACCEPT_TAGS'] : lang['TASK.YOU_SURE_ACCEPT']))}
      show={showModalApplyTask}
      close={() => {
        setModalApplyTask(false);
      }}
      footer={
        <>
          <button
            className="btn btn-secondary"
            onClick={() => {
              setModalApplyTask(false);
            }}
          >{lang['GLOBAL.CANCEL']}</button>
          <button
            className="btn btn-success"
            disabled={checkRole(userRoles, [ roles['coloring-tester'].key ]) && !time}
            onClick={() => finishTask(time)}
          >{lang['GLOBAL.ACCEPT']}</button>
        </>
      }
    >
      {checkRole(userRoles, [ roles['coloring-tester'].key ]) && (
        <Form>
          <Form.Label htmlFor="tracking-time">
            {lang['TASK.TIME_TRACKING']}
          </Form.Label>
          <Form.Control
            id="tracking-time"
            type="number"
            min={1}
            value={time}
            onChange={(event) => {
              setTime(Number(event.target.value));
            }}
          />
        </Form>
      )}
    </Modal>
  );
};

ApplyTaskModal.propTypes = {
  canBonus: PropTypes.bool.isRequired,
  canSetTags: PropTypes.bool,
  finishTask: PropTypes.func.isRequired,
  setModalApplyTask: PropTypes.func.isRequired,
  showModalApplyTask: PropTypes.bool.isRequired,
};

export default ApplyTaskModal;
