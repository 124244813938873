import actions from './actions';

/**
 * Default countries state
 *
 * @type {{countries: []}}
 */
const initialState = {
  countries: [],
};

/**
 * Reducer for a list of countries
 *
 * @param {any} state
 * @param {any} action
 * @returns {{countries: *[]}|{countries: *}}
 */
export const countriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_COUNTRIES: {
      return {
        countries: action.payload,
      };
    }

    default:
      return state;
  }
};

