import { Form } from 'react-bootstrap';
import React from 'react';
import { getCheckedValue } from '../../../utils/getCheckedValue';

/**
 * Builds a textarea for a field
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, value = null, onChange = null, project = null, required) {
  if (!project) {
    project = this.state;
  }
  const { lang } = this.props;

  return (
    <Form.Group
      as={Form.Row} key={field.key}
      style={{
        marginBottom: '1rem',
        ...(field.isFull ? {} : {
          maxWidth: 1024,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      }}
    >
      <div className={field.isColumn ? 'col-12' : 'col-lg-3 col-12'}>
        <Form.Label column>
          {(lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '')}
          {field.isRequired && required && (
            <span style={{ color: 'red' }}>{' *'}</span>
          )}
        </Form.Label>
      </div>
      <div className={field.isColumn ? 'col-12 d-flex flex-column' : 'col-lg-9 col-12 d-flex flex-column'}>
        <Form.Control
          as={'textarea'}
          type={field.type || 'text'}
          isInvalid={this.state.showInvalid && field.isRequired && required && !getCheckedValue(value === null ? project[field.key] : value)}
          value={(value === null ? project[field.key] : value) || ''}
          onChange={(event) => {
            if (onChange === null) {
              this.setState({
                project: {
                  ...project,
                  [field.key]: event.target.value,
                },
              });
            } else {
              onChange(event.target.value);
            }
          }}
        />
        {field.muted && (
          <Form.Text className="text-muted">
            {field.muted}
          </Form.Text>
        )}
        <Form.Control.Feedback type="invalid">
          {lang['GLOBAL.FIELD_REQUIRED']}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}
