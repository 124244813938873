import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import userActions from '../../store/user/actions';
import Preload from '../../widgets/Preload';
import { STORAGE_TIMES_REQUESTS } from '../../requests/utils/delay';



const propTypes = {
  hasAuthToken: PropTypes.bool.isRequired,
  logout: PropTypes.func.isRequired,
};
/**
 * Component for user logout, clears the cache and store
 */

class Logout extends React.Component {
  constructor (props) {
    super(props);
    this.logout = this.logout.bind(this);
  }

  render () {
    return (<Preload />);
  }

  componentDidUpdate () {
    const { hasAuthToken } = this.props;

    if (!hasAuthToken) {
      window.location.href = '/login';
    }
  }

  componentDidMount () {
    if (window.caches) {
      try {
        window.caches.delete(window.location.origin).then(this.logout).catch(this.logout);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        this.logout();
      }
    } else {
      this.logout();
    }
  }

  logout = () => {
    this.props.logout();
    localStorage.removeItem(STORAGE_TIMES_REQUESTS);
    sessionStorage.clear();
    if (this.props.hasAuthToken) {
      window.location.href = '/login';
    }
  };
}

Logout.propTypes = propTypes;

export default connect(({ user }) => ({ hasAuthToken: Boolean(user.authToken) }), userActions)(Logout);
