import { typesFields } from '../projectTypes';

/**
 * Checks if all fields for the project are entered correctly
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} project
 * @returns {*}
 */
export default function (field, project = null) {
  if (!project) {
    project = this.state.project;
  }

  return field.addedFields.filter((item) => {
    return !item.value || (
      field.typeFields === typesFields.switch
        ? ((!project[field.key] || project[field.key] === 'off' ? 'off' : 'on') === item.value)
        : (item.value === project[field.key])
    );
  });
}
