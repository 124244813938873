import React from 'react';
import PropTypes from 'prop-types';
import ImgList from '../../../widgets/ImgList';

/**
 *Component of video preview blocks and task page images
 *
 * @returns {*}
 */
const TaskInfoPreviewBlocks = ({ lang, computed, task, userRoles, deleteReferenceFile }) => {
  const { previewImages, previewReferences, sizingBlocks, canDeleteReferenceFile } = computed;

  return (
    <>
      {(previewImages.length > 0) && (
        <ImgList
          classNameBody={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.lg} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}
          title={lang['GLOBAL.IMAGE_PREVIEW_RESULT']}
          files={previewImages.map((file) => ({
            name: file.name || file.prefix,
            link: (file.data && file.data.preview) || file.link,
          }))}
        />
      )}
      {(previewReferences.length > 0) && (
        <ImgList
          classNameBody={`col-xl-${sizingBlocks.xl} col-lg-${sizingBlocks.lg} col-md-${sizingBlocks.md} col-sm-${sizingBlocks.sm} col-12`}
          title={lang['GLOBAL.REFERENCE_IMAGES']}
          canDeleteReferenceFile={canDeleteReferenceFile}
          deleteReferenceFile={deleteReferenceFile}
          task={task}
          userRoles={userRoles}
          lang={lang}
          files={previewReferences.map((file) => ({
            name: file.name,
            link: file.link,
            id: file.id,
            uploadStage: file.upload_stage,
          }))}
        />
      )}
    </>
  );
};

export const mapStoreToProps = (store) => ({
  lang: store.language.lang,
});

TaskInfoPreviewBlocks.propTypes = {
  lang: PropTypes.object,
  task: PropTypes.object,
  deleteReferenceFile: PropTypes.func,
  userRoles: PropTypes.array,
  computed: PropTypes.shape({
    previewImages: PropTypes.array,
    previewReferences: PropTypes.array,
    sizingBlocks: PropTypes.object,
    canDeleteReferenceFile: PropTypes.bool,
  }),
};

export default TaskInfoPreviewBlocks;
