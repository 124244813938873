import React, { useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Button from 'react-bootstrap/Button';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import ApproveBonusesModal from './modal/ApproveBonusesModal';



const StatsWidget = ({ amount, color, title }) => {
  return (
    <div>
      <h5 style={{ whiteSpace: 'nowrap' }}>{title}</h5>
      <div
        className="kt-widget__value"
        style={{
          fontSize: '2em',
          display: 'block',
          marginTop: '0.2em',
          color: color || 'inherit',
        }}
      >
        {amount}
      </div>
    </div>
  );
};

StatsWidget.propTypes = {
  amount: PropTypes.number.isRequired,
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
};

const BonusesStatsWidget = ({ stats, confirm, confirmationDisabled, userRoles }) => {
  const { lang } = useSelector(
    ({ language }) => ({
      lang: language.lang,
    }),
    shallowEqual,
  );
  const [ showApproveBonusesModal, setShowApproveBonusesModal ] = useState(false);

  const toggleApproveBonusesModal = () => {
    setShowApproveBonusesModal((showApproveBonusesModal) => !showApproveBonusesModal);
  };

  return (
    <div className='kt-portlet'>
      <div className="kt-portlet--head-lg px-4 pt-3">
        {lang['BONUSES.AWARDS_FULL']}
      </div>
      <div className='px-4 pb-3 pt-2'>
        <div className='kt-widget kt-widget--user-profile-3'>
          <div className='kt-widget__top'>
            <div className='row kt-container kt-container--fluid kt-p-0'>
              <div className='col-xs-6 col-sm-2 text-center'>
                <StatsWidget amount={stats.pending + stats.approved + stats.canceled} title={lang['BONUSES.TOTAL']} />
              </div>
              <div className='col-xs-6 col-sm-2 text-center'>
                <StatsWidget amount={stats.pending} title={lang['BONUSES.WAITING']} />
              </div>
              <div className='col-xs-6 col-sm-2 text-center'>
                <StatsWidget amount={stats.approved} title={lang['BONUSES.APPROVED']} color={'rgb(36, 183, 68)'} />
              </div>
              <div className='col-xs-6 col-sm-2 text-center'>
                <StatsWidget amount={stats.canceled} title={lang['BONUSES.CANCELED']} color='red' />
              </div>
              <div className='col-xs-12 col-sm-2 offset-sm-1 text-center'>
                <Button
                  variant='warning'
                  className="btn-bold w-100"
                  disabled={confirmationDisabled}
                  onClick={toggleApproveBonusesModal}
                >
                  {(confirmationDisabled && !checkRole(userRoles, roles.administrator.key)) ? lang['BONUSES.AGREED'] : lang['BONUSES.AGREE']}
                </Button>
              </div>
              <ApproveBonusesModal
                confirm={confirm}
                showApproveBonusesModal={showApproveBonusesModal}
                toggleApproveBonusesModal={toggleApproveBonusesModal}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BonusesStatsWidget.propTypes = {
  stats: PropTypes.shape({
    pending: PropTypes.number.isRequired,
    approved: PropTypes.number.isRequired,
    canceled: PropTypes.number.isRequired,
  }).isRequired,
  confirm: PropTypes.func.isRequired,
  confirmationDisabled: PropTypes.bool.isRequired,
  userRoles: PropTypes.array.isRequired,
};

export default BonusesStatsWidget;
