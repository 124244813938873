import { buildUsersObject } from '../../requests/users';
import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';
import { getErrorMessage } from '../../utils/getErrorMessage';



const actions = {
  LOADING: 'SET_LOADING',
  SET_FREELANCERS: 'SET_FREELANCERS',
  FILTER_FREELANCERS: 'FILTER_FREELANCERS',
  setLoading: (payload) => ({
    type: actions.LOADING,
    payload,
  }),
  setFreelancers: (payload) => ({
    type: actions.SET_FREELANCERS,
    payload,
  }),
  filterFreelancers: (payload) => ({
    type: actions.FILTER_FREELANCERS,
    payload,
  }),
  getFreelancers: (params) => async (dispatch, _getState, api) => {
    dispatch(actions.setLoading(true));
    const url = 'freelancers';

    try {
      const response = await api.get(url);

      if (response.data) {
        const customers = buildUsersObject(response.data.users);

        dispatch(actions.setFreelancers(customers));
        dispatch(actions.filterFreelancers(params));
        return customers;
      }
      return response;
    } catch {
      // todo add notification
      return [];
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  updateFreelancer: (freelancerData) => async (dispatch, getState, api) => {
    dispatch(actions.setLoading(true));
    const store = getState();
    const { lang } = store.language;

    try {
      await api.post('users', freelancerData);
      // todo fix
      // dispatch(actions.getFreelancer(userData.id));
      dispatch(actionsNotifications.add(lang['NOTIFICATION.SUCCESS_PERSONAL_INFO_EDIT'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response?.status === 422) {
        dispatch(actionsNotifications.add(lang['NOTIFICATION.ERROR_PERSONAL_INFO_EDIT'], NOTIFICATIONS_TYPES.error, getErrorMessage(error?.response?.data)));
      } else {
        dispatch(actionsNotifications.add(lang['NOTIFICATION.ERROR_PERSONAL_INFO_EDIT'], NOTIFICATIONS_TYPES.error));
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
