export const getErrorMessage = (msg) => {
  const messages = [];

  if (msg?.message) {
    messages.push(msg.message);
  }

  if (msg?.errors) {
    Object.keys(msg.errors).forEach((key) => {
      messages.push(msg.errors[key]);
    });
  }

  if (typeof msg === 'object' && !msg.message && !msg.errors) {
    Object.keys(msg).forEach((key) => {
      if (Array.isArray(msg[key])) {
        messages.push(msg[key].join(' '));
      } else if (typeof msg[key] === 'string') {
        messages.push(msg[key]);
      }
    });
  }

  return messages;
};
