import React from 'react';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../../pages/customers/roles/groupsRoles';



const CategoriesWidget = ({ categories = [], limit = 5, canSetCategories, handleAction, showIcon = true }) => {
  const { lang, userRoles } = useSelector(({ language, user }) => ({
    lang: language.lang,
    userRoles: user.roles,
  }), shallowEqual);

  const showWidget = checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all ]);
  const isActionVisible = categories.length > 0 || canSetCategories;

  if (!showWidget) {
    return null;
  }
  const visibleCategories = categories.slice(0, limit);

  const renderCategories = () => {
    return visibleCategories.map((category) => {
      return (
        <button key={category.id} className={'kt-ml-5 btn btn-label-dark-o2 btn-sm kt-mb-5 kt-mt-5'}>
          {category.name}
        </button>
      );
    });
  };

  return (
    <div className="kt-widget__item flex-grow-0 justify-content-end">
      {showIcon && <div className="kt-widget__icon">
        <i className="far fa-list-alt" />
      </div>}
      <div className="kt-widget__details">
        <span className="kt-widget__title">
          {`${lang['MENU.CATEGORIES']}: `}
          {renderCategories()}
          {isActionVisible && (
            <Button
              variant="info"
              size="sm"
              className="kt-ml-5 kt-mb-5 kt-mt-5 d-inline-flex align-items-center"
              onClick={handleAction}
            >
              <i className='flaticon-more-1 kt-m-0' />
            </Button>
          )}
        </span>
      </div>
    </div>
  );
};

CategoriesWidget.propTypes = {
  limit: PropTypes.number,
  categories: PropTypes.array.isRequired,
  canSetCategories: PropTypes.bool.isRequired,
  handleAction: PropTypes.func,
  showIcon: PropTypes.bool,
};

export default CategoriesWidget;
