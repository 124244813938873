import axiosApiInstance from '../../../requests/utils/api';



const getTasks = async (tasksIds) => {
  const data = await Promise.all(tasksIds.map(async (taskId) => {
    const res = await axiosApiInstance.get(`tasks/${taskId}`);

    return res.data;
  }));

  return data.map((item) => item.task);
};

export default getTasks;
