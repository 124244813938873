import axiosApiInstance from '../../../requests/utils/api';



const startTasks = (tasks) => {
  return Promise.all(tasks.map(async (task) => {
    const res = await axiosApiInstance.put(`tasks/${task.id}/start`);

    return res.data;
  }));
};

export default startTasks;
