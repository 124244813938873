import Badge from 'react-bootstrap/Badge';
import React from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { shallowEqual, useSelector } from 'react-redux';
import RightMenu from '../../../widgets/RightMenu';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';
import imgURL from '../../../utils/imgURL';
import History from '../../../widgets/History';
import VersionsHistory from '../../../widgets/VersionsHistory';
import Img from '../../../widgets/Img';
import getLinkToIcon from '../../projects/linksToIconFiles';



const propTypes = {
  task: PropTypes.any,
  taskFiles: PropTypes.any,
  comments: PropTypes.array,
  viewFileRight: PropTypes.any,
  canViewSourceFiles: PropTypes.bool,
  textureSize: PropTypes.number,
  getImage: PropTypes.func,
};


const TaskInfoRightMenu = ({
  task,
  taskFiles,
  viewFileRight,
  canViewSourceFiles,
  textureSize,
  getImage,
}) => {
  const { lang, userRoles } = useSelector(({ language, user }) => ({
    lang: language.lang,
    userRoles: user.roles,
  }), shallowEqual);

  return (
    <RightMenu
      isOpen={false}
      tabs={[
        checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.editors.all, ...groupsRoles.admin ]) && {
          icon: 'flaticon-users-1',
          color: 'success',
          title: lang['TASK.EXECUTORS'],
          scroll: true,
          body: () => {
            return (
              <History
                showDate={false}
                history={((task.project && task.project.tasks) || []).map((item) => {
                  return {
                    id: item.id,
                    color: item.id === task.id && 'success',
                    title: lang[`TASK.TYPES.${item.type}`],
                    info: item.executor_id ? item.executor_id.username : '---',
                  };
                })}
              />
            );
          },
        },
        checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.editors.all, ...groupsRoles.testers.all, ...groupsRoles.admin ]) && {
          icon: 'flaticon2-files-and-folders',
          color: 'danger',
          title: lang['PROJECT.HISTORY_FILES'],
          scroll: true,
          body: () => {
            return (
              <VersionsHistory
                comments={task.project.comments}
                images={task.project.files}
              />
            );
          },
        },
        viewFileRight && (taskFiles.length > 0 || (task.project && task.project.options && task.project.files && task.project.files.length > 0)) && {
          title: lang['GLOBAL.LIST_FILES'],
          icon: 'flaticon2-google-drive-file',
          color: 'warning',
          scroll: true,
          body: () => {
            return (
              <div className='w-100'>
                {taskFiles.length > 0 && (
                  <div className='w-100'>
                    <h5 className='kt-mb-20'>{lang['TASK.LOAD_FILES']}</h5>
                    <div className="kt-widget4 w-100">
                      {taskFiles.map((file) => {
                        if (file.prefix === 'texture') {
                          getImage(file);
                        }

                        return (
                          <div className='kt-widget4__item' key={uuidv4()}>
                            <div className="kt-widget4__pic kt-widget4__pic--pic">
                              <Img src={getLinkToIcon(file.prefix)} />
                            </div>
                            <div className="kt-widget4__info">
                              {file.link ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  download={file.name || file.prefix || 'file'}
                                  href={imgURL(file.link)}
                                  className="kt-widget4__username"
                                >
                                  {(file.name?.split('?')[0] || 'file') + (file.prefix ? `(${file.prefix})` : '')}
                                  {file.required && (
                                    <span style={{ color: 'red' }}>*</span>
                                  )} {textureSize > 0 && file.prefix === 'texture' ? (`${textureSize} Mb`) : null}
                                </a>
                              ) : (
                                <span
                                  className="kt-widget4__username no-hover"
                                >
                                  {(file.name || 'file') + (file.prefix ? ` (${file.prefix})` : '')}
                                  {file.required && (
                                    <span style={{ color: 'red' }}>*</span>
                                  )}
                                </span>
                              )}
                            </div>
                            {!file.uploaded && file.status === 0 ? (
                              <Badge variant='primary'>
                                {lang['GLOBAL.FILES.NO_LOAD']}
                              </Badge>
                            ) : (file.valid || file.status === 1 ? (
                              <Badge variant='success'>
                                {lang['GLOBAL.FILES.VALID']}
                              </Badge>
                            ) : (
                              <Badge variant='danger'>
                                {lang['GLOBAL.FILES.NO_VALID']}
                              </Badge>
                            )
                            )}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <span className='kt-pb-20 kt-mt-20 kt-pt-20 w-100 d-block' />
                {task.project && task.project.files && task.project.files.length > 0 && canViewSourceFiles && (
                  <div>
                    <h5 className='kt-mb-20'>
                      {lang['TASK.SOURCE_FILES']}
                    </h5>
                    <div className="kt-widget4">
                      {task.project.files.filter((file) => file.prefix === 'source').map((file) => {
                        const name = file.link.split('?')[0].slice(file.link.lastIndexOf('/') + 1);

                        const prefix = name.slice(name.lastIndexOf('.') + 1);

                        return (
                          <div className='kt-widget4__item' key={uuidv4()}>
                            <div className="kt-widget4__pic kt-widget4__pic--pic">
                              <Img src={getLinkToIcon(prefix)} />
                            </div>
                            <div className="kt-widget4__info">
                              {file.link ? (
                                <a
                                  target="_blank"
                                  rel="noreferrer"
                                  download={name || 'file'}
                                  href={imgURL(file.link)}
                                  className="kt-widget4__username"
                                >
                                  {name || 'file'}
                                  {file.required && (
                                    <span style={{ color: 'red' }}>*</span>
                                  )}
                                </a>
                              ) : (
                                <span
                                  className="kt-widget4__username no-hover"
                                >
                                  {file.name || file.prefix || 'file'}
                                  {file.required && (
                                    <span style={{ color: 'red' }}>*</span>
                                  )}
                                </span>
                              )}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </div>
            );
          },
        },
      ].filter((tab) => !!tab)}
    />
  );
};

TaskInfoRightMenu.propTypes = propTypes;

export default TaskInfoRightMenu;
