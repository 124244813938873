import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import urlPageReportsCategory from '../../urls/urlPageReportsCategory';
import ReportsByEditors from './ReportsByEditors';
import ReportsUserAnalyze from './userAnalyze/ReportsUserAnalyze';

/**
 * Component for routing in reports
 */

export const Reports = () => {
  return (
    <Switch>
      <Route path={urlPageReportsCategory({ category: 'editors' })} component={ReportsByEditors} />
      <Route path={urlPageReportsCategory({ category: 'artist' })} component={ReportsUserAnalyze} />
      <Redirect to={urlPageReportsCategory({ category: 'editors' })} />
    </Switch>
  );
};

export default Reports;
