const rateTypes = {
  artist: {
    key: 'artist',
  },
  colorist: {
    key: 'colorist',
  },
};

export default rateTypes;
