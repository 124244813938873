import { useEffect, useState } from 'react';
import { isScreenMd } from '../utils/size';



export default function useMobileScreen () {
  const [ isMobile, setIsMobile ] = useState(isScreenMd());

  const handleResizeEvent = () => {
    const isMd = isScreenMd();

    if (isMobile && !isMd) {
      setIsMobile(false);
    } else if (!isMobile && isMd) {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    window.addEventListener('resize', handleResizeEvent);

    return () => {
      window.removeEventListener('resize', handleResizeEvent);
    };
  });

  return [ isMobile ];
}
