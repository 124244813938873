import React, {useState, useEffect} from 'react';
import {shallowEqual, useSelector} from 'react-redux';
import objectPath from 'object-path';
import ScrollTop from '../partials/layout/ScrollTop';
import Header from './header/Header';
import HeaderMobile from './header/HeaderMobile';
import AsideLeft from './aside/AsideLeft';
import Footer from './footer/Footer';
import HTMLClassService from './HTMLClassService';
import LayoutConfig from './LayoutConfig';
import LayoutInitializer from './LayoutInitializer';
import KtContent from './KtContent';
import './assets/Base.scss';
import axiosApiInstance from "../../app/requests/utils/api";
import {MODAL_TYPES, useGlobalModalContext} from "../../app/components/GlobalModal";



const htmlClassService = new HTMLClassService();

function Layout (
  {
    children,
  },
) {
  const {curLang, layoutConfig, asideDisplay} = useSelector(({ builder: { layoutConfig }, language }) => ({
    curLang: language.cur,
    layoutConfig,
    asideDisplay: objectPath.get(layoutConfig, 'aside.self.display'),
  }), shallowEqual);

  const [policy, setPolicy] = useState({});
  const [terms, setTerms] = useState({});
  const { showModal } = useGlobalModalContext();

  htmlClassService.setConfig(layoutConfig);

  const contentCssClasses = htmlClassService.classes.content.join(' ');

  const getPrivacyPolicy = async () => {
    try {
      const response = await axiosApiInstance.get('privacies');

      setPolicy(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  const getTerms = async () => {
    try {
      const response = await axiosApiInstance.get('terms');

      setTerms(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }

  useEffect(() => {
    Promise.all([getPrivacyPolicy(), getTerms()])
  }, []);

  const termModal = () => {
    showModal(MODAL_TYPES.CONTRACT_MODAL, {
      contract: terms[curLang],
    });
  }

  const policyModal = () => {
    showModal(MODAL_TYPES.CONTRACT_MODAL, {
      contract: policy[curLang],
    });
  }

  return (
    <LayoutInitializer
      styles={[]}
      layoutConfig={LayoutConfig}
      htmlClassService={htmlClassService}
    >
      {/* <!-- begin:: Header Mobile --> */}
      <HeaderMobile />
      {/* <!-- end:: Header Mobile --> */}

      <div className="kt-grid kt-grid--hor kt-grid--root">
        {/* <!-- begin::Body --> */}
        <div className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page">
          {/* <!-- begin:: Aside Left --> */}
          {asideDisplay && (
            <>
              <AsideLeft />
            </>
          )}
          {/* <!-- end:: Aside Left --> */}
          <div
            className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper"
            id="kt_wrapper"
          >
            {/* <!-- begin:: Header READY --> */}

            <Header />
            {/* <!-- end:: Header --> */}

            {/* <!-- begin:: Content --> */}
            <div
              id="kt_content"
              className={`kt-content ${contentCssClasses} kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor pb-4`}
            >
              {/* <!-- begin:: Content Body --> */}
              <KtContent>{children}</KtContent>
              {/*<!-- end:: Content Body -->*/}
            </div>
            {/* <!-- end:: Content --> */}
            <Footer
              showTerms={termModal}
              showPrivacy={policyModal} />
          </div>
        </div>
        {/* <!-- end:: Body --> */}
      </div>
      <ScrollTop />
    </LayoutInitializer>
  );
}

export default Layout;
