import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import KtContent from '../../../../_metronic/layout/KtContent';
import LanguageSelector from '../../../../_metronic/partials/layout/LanguageSelector';
import policyActions from '../../../store/policy/actions';
import Preload from '../../../widgets/Preload';



const Privacy = () => {
  const dispatch = useDispatch();
  const { lang, curLang, policy: { policy_id, policy, isLoading } } = useSelector(
    ({ language, policy }) => ({
      lang: language.lang,
      curLang: language.cur,
      policy,
    }),
    shallowEqual,
  );

  const acceptPrivacyPolicy = () => {
    dispatch(policyActions.acceptPolicy(policy_id));
  };

  return (<>{isLoading ? (
    <Preload />
  ) : (
    <div
      id="kt_content_privacy"
      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
    >
      <div className="kt-header__topbar" style={{ justifyContent: 'flex-end' }}>
        <LanguageSelector iconType="" />
      </div>
      <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
        <KtContent>
          <div className="row">
            <div className="col-xl-12">
              <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head no-print w-100">
                  <div className="kt-portlet__head-label no-print w-100">
                    <h1 className="text-center w-100">
                      {lang['PRIVACY.YOU_SHOULD']}
                    </h1>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="row w-100">
                    <div
                      className="col-lg-12"
                      dangerouslySetInnerHTML={{ __html: policy && policy?.hasOwnProperty(curLang) ? policy[curLang] : '' }}
                    />
                    <div className='col-12 d-flex justify-content-center kt-mt-30'>
                      <Button
                        variant='success'
                        onClick={acceptPrivacyPolicy}
                      >{lang['REGISTRATION.PRIVACY_POLICY']}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KtContent>
      </div>
    </div>)}
  </>);
};


export default Privacy;
