const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

/**
 * return month by id
 *
 * @param {number} id
 * @returns {string}
 */
export default (id) => months[id % 12];
