import { orderBy } from 'lodash';
import {
  changeBonusStatusAction,
  createBonusAction,
  deleteBonusAction,
  setBonusesAction,
  updateBonusAction,
} from '../store/bonuses';
import { formatStringToDate } from '../utils/formats';
import { actionsNotifications } from '../store/notification';
import { NOTIFICATIONS_TYPES } from '../widgets/Notification';
import { historyFrom, historyTo, prevMonthFirstDate, prevMonthLastDate } from '../../const/date';
import { getBatchTasksForBonuses, getTasksForBonuses } from './tasks';
import { buildResponseUser } from './users';
import axiosApiInstance from './utils/api';



export const getBonuses = (type) => async () => {
  const URL = `bonuses?from=${prevMonthFirstDate}&to=${prevMonthLastDate}&type=${type}`;

  try {
    const response = await axiosApiInstance.get(URL, {});

    return response.data;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);

    return [];
  }
};

export const sendBonusesApprovedConfirmation = () => async () => {
  const URL = 'bonuses/notifications/approved';

  try {
    await axiosApiInstance.post(URL);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const createBonus = (data) => async (dispatch, getState) => {
  const URL = 'bonuses';
  const store = getState();

  try {
    const response = await axiosApiInstance.post(URL, data, {});

    if (response.data.id) {
      dispatch(createBonusAction(data.amount, data.task_id, data.reason, response.data.id, data.bonusStatus));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_SET_BONUS'], NOTIFICATIONS_TYPES.success));
    }
  } catch (error) {
    if (error.response) {
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_SET_BONUS'], NOTIFICATIONS_TYPES.error));
      // eslint-disable-next-line no-console
      console.error(error.response.data);
    }
  }
};

export const updateBonus = (data) => async (dispatch, getState) => {
  const URL = `bonuses/${data.bonusId}`;
  const store = getState();

  try {
    const response = await axiosApiInstance.put(URL, data);

    if (response.data.id) {
      dispatch(updateBonusAction(data.task_id, data.amount, response.data.id, data.reason, data.bonusStatus));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_SET_BONUS'], NOTIFICATIONS_TYPES.success));
    }
  } catch (error) {
    if (error.response) {
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_SET_BONUS'], NOTIFICATIONS_TYPES.error));
      // eslint-disable-next-line no-console
      console.error(error.response.data);
    }
  }
};

export const changeBonusStatus = (data) => async (dispatch, getState) => {
  const URL = `bonuses/${data.bonus_id}/statuses`;

  const store = getState();

  try {
    const response = await axiosApiInstance.post(URL, {
      status: data.status,
    });

    if (response.data) {
      dispatch(changeBonusStatusAction(data.task_id, data.bonus_id, data.status));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_CHANGE_BONUS'], NOTIFICATIONS_TYPES.success));
    }
  } catch (error) {
    if (error.response) {
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_REMOVE_BONUS'], NOTIFICATIONS_TYPES.error));
      // eslint-disable-next-line no-console
      console.error(error.response.data);
    }
  }
};

export const deleteBonus = (bonusId) => async (dispatch, getState) => {
  const URL = `bonuses/${bonusId}`;

  const store = getState();

  try {
    const response = await axiosApiInstance.delete(URL);

    if (response.data) {
      dispatch(deleteBonusAction(bonusId));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_REMOVE_BONUS'], NOTIFICATIONS_TYPES.success));
    }
  } catch (error) {
    if (error.response) {
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_REMOVE_BONUS'], NOTIFICATIONS_TYPES.error));
      // eslint-disable-next-line no-console
      console.error(error.response.data);
    }
  }
};

export const setBonuses = (bonuses) => (dispatch) => {
  try {
    dispatch(setBonusesAction(bonuses));
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

export const buildBonuses = async (bonuses, tasks) => {
  const fixedTasks = tasks.map(
    (userTasks) => {
      return userTasks.length && userTasks.map(
        (task) => {
          task.finished_at = formatStringToDate(task.finished_at);
          return task;
        }
      );
    }
  );

  return bonuses.users.map(
    (user, key) => {
      user.tasks = fixedTasks[key];
      user.tasks = orderBy(
        user.tasks,
        [ (task) => (!!task.bonus), (task) => (task.finished_at ? task.finished_at.getTime() : 0) ],
        [ 'desc', 'desc' ]
      );

      let history = [];

      if (bonuses.history[user.user.id]) {
        history = bonuses.history[user.user.id].map((month) => ({
          userId: user.user.id,
          amount: Number(month.amount),
          date: new Date(Number(month.year), Number(month.month) - 1),
        }));
      }

      if (bonuses.paymentHistory[user.user.id]) {
        bonuses.paymentHistory[user.user.id].forEach((month) => {
          if (history.length === 0) {
            history.push({
              userId: user.user.id,
              paymentAmount: Number(month.amount),
              date: new Date(Number(month.year), Number(month.month) - 1),
            });

            return;
          }

          let isFound = false;

          history = history.map((elem) => {
            if (elem.date.getTime() === (new Date(Number(month.year), Number(month.month) - 1)).getTime()) {
              isFound = true;

              return {
                ...elem,
                paymentAmount: month.amount,
              };
            }

            return elem;
          });

          if (!isFound) {
            history.push({
              userId: user.user.id,
              paymentAmount: Number(month.amount),
              date: new Date(Number(month.year), Number(month.month) - 1),
            });
          }
        });
      }

      history = orderBy(history.filter((month) => (historyFrom.getTime() <= month.date.getTime() && month.date.getTime() < historyTo.getTime())), (month) => month.date.getTime());

      user.user.history = bonuses.history[user.user.id] ? orderBy(
        bonuses.history[user.user.id].map((month) => ({
          amount: Number(month.amount),
          date: new Date(Number(month.year), Number(month.month) - 1),
        })).filter((month) => (historyFrom.getTime() <= month.date.getTime() && month.date.getTime() < historyTo.getTime())),
        (month) => month.date.getTime()
      ) : [];

      user.user.comboHistory = history;

      return buildResponseUser(user, 'user');
    }
  );
};

export const getTasksBonuses = async (data) => {
  if (data.users) {
    return (await Promise.allSettled(data.users.map((user) => getTasksForBonuses(user.user.id, user.tasks))))
      .map((res) => res.value);
  }
  return null;
};

export const getBatchTasksBonuses = async (data) => {
  if (!data.length) {
    return [];
  }

  return getBatchTasksForBonuses(data);
};

export const getBonusesApprovedStagesRequest = async () => {
  const URL = 'bonuses/approved-stages';

  return axiosApiInstance.get(URL);
};

export default getBonuses;
