import GroupEntity from '../../model/GroupEntity';
import { entitySubtypes, entityTypes } from '../../config/config';
import { getValuesFromFormField, isOptionEnabled } from './helpers';



const prepareEntities = (data) => {
  const tagIds = [];

  Object.entries(data).forEach(([ key, value ]) => {
    if ((key.startsWith('global_') || key.startsWith('local_')) && value) {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          tagIds.push(item?.value);
        });
      } else {
        tagIds.push(value?.value);
      }
    }
  });

  return tagIds;
};

const transformPictureData = (data, general) => {
  return {
    picture_id: null,
    project_id: general?.project?.id,
    url: general?.project?.preview,
    templates: data?.templates?.map((template) => template.value) || [],
    entities: prepareEntities(data),
  };
};

const findTemplate = (templates, itemId, itemType) => {
  return templates?.find((tmpl) => Number(tmpl.id) === Number(itemId) && itemType === entityTypes.template);
};

const findGroup = (templates, item, template, chItemId) => {
  let group = null;

  if (item.type === entityTypes.group) {
    templates?.forEach((tmpl) => {
      if (group) {
        return;
      }
      group = tmpl?.groups?.find((group) => group.id === item.id);
    });
  } else {
    group = template?.groups?.find((group) => group.id === chItemId);
  }

  return group;
};

const addDefaultValue = (defaultValues, fieldName, label, value, isMultiple) => {
  if (isMultiple) {
    if (!defaultValues[fieldName]) {
      defaultValues[fieldName] = [];
    }
    defaultValues[fieldName].push({ label, value });
  } else {
    defaultValues[fieldName] = { label, value };
  }
};


const transformPictureToDefaultValues = (data, templates) => {
  if (!data?.entities || !templates?.length) {
    return {};
  }

  const content = data?.entities || [];
  const defaultValues = {
    templates: [],
  };

  content
    .filter((item) => item.type !== entityTypes.tag)
    .forEach((item) => {
      const template = findTemplate(templates, item.id, item.type);

      if (template?.id) {
        defaultValues.templates.push({
          label: template?.template_name,
          value: item.id,
        });
      }

      item?.children?.forEach((chItem) => {
        const group = findGroup(templates, item, template, chItem?.id);

        if (!group) return;

        const isGlobal = isOptionEnabled(group.options || [], 'is_global');
        const prefix = isGlobal ? entitySubtypes.global : entitySubtypes.local;
        const fieldName = `${prefix}_${group.id}_${group.name}`;
        const isMultiple = isOptionEnabled(group.options || [], 'is_multiple');

        if (chItem.type === entityTypes.group) {
          chItem?.children?.forEach((itemTag) => {
            addDefaultValue(defaultValues, fieldName, itemTag?.name, itemTag?.id, isMultiple);
          });
        } else if (chItem.type === entityTypes.tag) {
          addDefaultValue(defaultValues, fieldName, chItem?.name, chItem?.id, isMultiple);
        }
      });
    });

  return defaultValues;
};

const transformFormToRequestSimilarPictures = (projectId, formValues = {}) => {
  const { templates = [], ...otherFields } = formValues;

  const requestBody = {
    project_id: Number(projectId),
    templates: templates?.map((template) => template?.value) || [],
    primary_tags: [],
    type_tags: [],
    additional_tags: [],
  };

  Object.entries(otherFields).forEach(([ key, value ]) => {
    const values = getValuesFromFormField(value);

    switch (true) {
      case key.includes('main_tags') || key.includes('usual_tags'):
        requestBody.primary_tags = requestBody.primary_tags.concat(values);
        break;

      case key.endsWith('_genre') || key.endsWith('_technique'):
        requestBody.type_tags = requestBody.type_tags.concat(values);
        break;

      default:
        requestBody.additional_tags = requestBody.additional_tags.concat(values);
        break;
    }
  });

  return requestBody;
};

const transformToGroupEntities = (groups) => {
  return groups.map((group) => {
    return new GroupEntity(group);
  });
};

export {
  transformToGroupEntities,
  transformPictureData,
  transformPictureToDefaultValues,
  transformFormToRequestSimilarPictures,
};
