import React from 'react';
import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Modal from '../../../widgets/Modal';
import { mapCategoryIdToName, mapCategoryNameToId } from '../../../requests/categories';
import SelectTag from '../../../widgets/SelectTag';
import { addTagsToProject, removeTagsFromProject } from '../../../requests/tags';



/**
 * Builds modal windows for a page with a list of projects
 *
 * @this TasksList
 * @returns {*}
 */
export default function TaskListModal () {
  const {
    lang,
    userId,
    categories,
  } = this.props;

  const { category_id, updated_task, showModalCategories, showModalTags, task } = this.state;
  const { uniqTags } = this.computed;

  const categoryIdValue = mapCategoryIdToName(categories, category_id);

  return (
    <>
      <Modal
        title={lang['GLOBAL.CATEGORY']}
        show={showModalCategories}
        bodyStyle={{ overflow: 'unset' }}
        close={() => {
          this.setState({
            showModalCategories: false,
          });
        }}
        footer={
          <button
            className="btn btn-secondary"
            onClick={() => {
              this.setState({
                showModalCategories: false,
              });
              this._saveCategory();
            }}
          >OK</button>
        }
      >
        <div className='d-flex flex-column'>
          <Form.Group className="kt-mb-0">
            <Form.Label>
              {lang['GLOBAL.CATEGORY_ADD']}
            </Form.Label>
            <Form.Control
              as="select"
              value={categoryIdValue}
              onChange={(event) => {
                const categoryId = mapCategoryNameToId(categories, event.target.value);

                this.setState({
                  category_id: categoryId,
                });
              }}
            >
              {categories.map((item) => {
                if (updated_task && !!updated_task.categories?.find((categoryId) => item.id === categoryId)) {
                  return;
                }
                return (
                  <option key={item.id} value={item.name}>
                    {item.name}
                  </option>
                );
              })}
            </Form.Control>
          </Form.Group>
        </div>
      </Modal>

      {/*Modal Tags*/}
      <Modal
        title={lang['MENU.TAGS']}
        show={showModalTags}
        bodyStyle={{ overflow: 'unset' }}
        close={() => {
          this.setState({
            showModalTags: false,
          });
        }}
        footer={
          <button
            className="btn btn-secondary"
            onClick={() => {
              this.setState({
                showModalTags: false,
              });
              this._getTasks();
              // window.location.reload();
            }}
          >OK</button>
        }
      >
        <div className='d-flex justify-content-center flex-wrap'>
          {uniqTags.map((tag) => {
            return (
              <Button
                key={tag.id}
                variant="label-dark-o2"
                size="sm"
                className="kt-ml-5 kt-mb-5 d-flex align-items-center"
              >
                {tag.name}
                <i
                  role="link"
                  tabIndex={0}
                  onKeyDown={() => {
                  }}
                  className="fa fa-times-circle hover kt-mr-0 kt-ml-5"
                  onClick={async () => {
                    await removeTagsFromProject([ tag.id ], updated_task.project_id);
                    this._setTags(task.project.tags.filter((item) => item.id !== tag.id));
                  }}
                />
              </Button>
            );
          })}
        </div>
        <>
          <hr />
          <div className='d-flex flex-column'>
            <Form.Group className="kt-mb-0">
              <Form.Label>{lang['TAGS.ADD']}</Form.Label>
              <SelectTag
                value={[]}
                canCreate={false}
                isClearable={false}
                onChange={async (values, tags) => {
                  await addTagsToProject(values, updated_task.project_id);

                  this._setTags([ ...task.project.tags, {
                    user_id: userId,
                    id: tags[0].value,
                    name: tags[0].label,
                    slug: tags[0].slug,
                  } ]);
                }}
              />
            </Form.Group>
          </div>
        </>
      </Modal>
    </>
  );
}
