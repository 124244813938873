/**
 * Priorities
 *
 * @type {{high: number, low: number, medium: number}}
 */
export const prioritiesMap = {
  high: 3,
  medium: 2,
  low: 1,
};

const priorities = Object.keys(prioritiesMap);

/**
 * Gets the color name for priority
 *
 * @param {number} priority
 * @returns {string}
 */
export const priorityToColor = (priority) => {
  if (!priority) {
    return 'dark';
  }

  switch (priority) {
    case prioritiesMap.high: {
      return 'danger';
    }
    case prioritiesMap.medium: {
      return 'secondary';
    }
    case prioritiesMap.low: {
      return 'success';
    }
    default:
      return 'dark';
  }
};

export default priorities;
