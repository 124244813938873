import React from 'react';
import Table from 'react-bootstrap/Table';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { parseFromPars } from '../../../../utils/parseUrlParams';
import groupsUsers from '../../../customers/roles/groupsUsers';
import checkRole from '../../../../utils/checkRole';
import roles from '../../../customers/roles/roles';
import useMobileScreen from '../../../../hooks/useMobileScreen';
import { projectStatuses } from '../../projectTypes';
import ProjectItem from './ProjectItemCard';
import ProjectItemTable from './ProjectItemTable';
import ProjectHeaderTable from './ProjectHeaderTable';
import { useProjectsList } from './ProjectsListContext';



const TableView = ({ setProjectId, setCategoryId, setUpdatedProject }) => {
  const {
    filter,
    type,
    canSplit,
    isSelectedStage,
    selectedProjects,
    canSelected,
    onSelectProject,
    onFilterChange,
    setShowCategoriesModal,
  } = useProjectsList();
  const { userGroups, projects, cRoles } = useSelector(({ projects, user }) => ({
    userGroups: user.groups,
    projects: projects.projects,
    cRoles: user.roles,
  }), shallowEqual);

  const isColoring = userGroups.findIndex((group) => group === groupsUsers.coloring) !== -1;
  const showPreviousExecutor = (parseFromPars(location.search).status === projectStatuses.finished)
    && (checkRole(cRoles, [ roles['content-manager'].key ], [ roles['coloring-editor'].key ]));

  const handleSelectProject = (project) => (id) => {
    const selected = canSelected && selectedProjects.findIndex((id) => id === project.id) !== -1;

    if (!canSelected) {
      return null;
    }

    return onSelectProject((prevProps) => {
      return selected ? prevProps.filter((id) => id !== project.id) : [ ...prevProps, id ];
    });
  };

  return (
    <div className="col-12 kt-portlet mw-100 overflow-auto kt-font-md table-wrapper">
      <Table
        striped
        className="vertical-align-middle text-center"
        size="sm"
      >
        <ProjectHeaderTable
          isColoring={isColoring}
          canSort
          sort={{
            [filter.sort?.orderBy]: filter.sort?.sortedBy,
          }}
          canSelected={canSelected}
          isSelectedStage={isSelectedStage}
          showPreviousExecutor={showPreviousExecutor}
          canSplit={canSplit}
          setSort={(sort) => {
            onFilterChange({
              target: {
                name: 'sort',
                value: sort,
              },
            });
          }}
        />
        <tbody>
          {projects[type]?.map((project) => {
            const selected = canSelected && selectedProjects.findIndex((id) => id === project.id) !== -1;

            return (
              <ProjectItemTable
                key={project.id}
                project={project}
                listPreview={[] /*listPreview*/}
                showPreviousExecutor={showPreviousExecutor}
                isColoring={isColoring}
                selected={selected}
                isSelectedStage={isSelectedStage}
                canSelected={canSelected}
                setModalCategories={setShowCategoriesModal}
                setProjectId={setProjectId}
                setCategoryId={setCategoryId}
                setUpdatedProject={setUpdatedProject}
                canSplit={canSplit}
                canChangeCategory
                onSelect={handleSelectProject(project)}
              />
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

TableView.propTypes = {
  setProjectId: PropTypes.func.isRequired,
  setCategoryId: PropTypes.func.isRequired,
  setUpdatedProject: PropTypes.func.isRequired,
};

const GridView = () => {
  const { filter, type, canSplit, selectedProjects, canSelected, onFilterChange } = useProjectsList();
  const { projects, cRoles } = useSelector(({ projects, user }) => ({
    projects: projects.projects,
    cRoles: user.roles,
  }), shallowEqual);

  const showPreviousExecutor = (parseFromPars(location.search).status === 'finished')
    && (checkRole(cRoles, [ roles['content-manager'].key ], [ roles['coloring-editor'].key ]));

  const handleClickImageType = (type) => {
    onFilterChange({
      target: {
        name: 'image_type',
        value: type,
      },
    });
  };

  const handleClickTag = (tag) => {
    if (filter.tags[0] !== tag || filter.tags.length !== 1) {
      onFilterChange({
        target: {
          name: 'tags',
          value: [ tag ],
        },
      });
    }
  };

  if (!projects[type]) {
    return null;
  }

  return (projects[type]?.map((project) => {
    const selected = canSelected && selectedProjects.findIndex((id) => id === project.id) !== -1;

    return (
      <ProjectItem
        key={project.id}
        project={project}
        listPreview={[]}
        selected={selected}
        showPreviousExecutor={showPreviousExecutor}
        canSplit={canSplit}
        onClickType={handleClickImageType}
        onClickTag={handleClickTag}
        onClickComments={() => {}}
      />
    );
  })
  );
};


const ProjectsList = (props) => {
  const { tableView } = useProjectsList();
  const [ isMobile ] = useMobileScreen();
  const showTableView = tableView && !isMobile;

  return showTableView ? (
    <TableView {...props} />
  ) : (
    <GridView {...props} />
  );
};

ProjectsList.propTypes = {
  setProjectId: PropTypes.func.isRequired,
  setCategoryId: PropTypes.func.isRequired,
  setUpdatedProject: PropTypes.func.isRequired,
};

export default ProjectsList;

