import React from 'react';
import { connect } from 'react-redux';
import { orderBy, uniqBy } from 'lodash';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import { parseFromPars, parseToPars } from '../../../utils/parseUrlParams';
import checkRole from '../../../utils/checkRole';
import groupsUsers from '../../customers/roles/groupsUsers';
import groupsRoles from '../../customers/roles/groupsRoles';
import Preload from '../../../widgets/Preload';
import { isScreenMd } from '../../../utils/size';
import { getPreviewLg } from '../../projects/getPreviewFromProject';
import MyComponent from '../../../utils/MyComponent';
import { status } from '../../../utils/statusToColor';
import KTUtil from '../../../../_metronic/_assets/js/util';
import roles from '../../customers/roles/roles';
import { addCategories } from '../../../requests/categories';
import axiosApiInstance from '../../../requests/utils/api';
import { notification } from '../../../requests/notifications';
import { filterTasks, getTasks, getTasksTypeForExecutor, setTasks, updateTask } from '../../../requests/tasks';
import { isEmptyObject } from '../../../utils/checker';
import { getTypeTaskUrl } from '../helpers/getTypeTaskUrl';
import SetGroupConfirmModal from '../modal/SetGroupConfirmModal';
import taskTypes from '../taskTypes';
import ChangeImageTypeModal from '../modal/ChangeImageTypeModal';
import { tasksTypes } from '../../../store/tasks';
import TasksListSubheader from './TasksListSubheader';
import TasksFilters from './TasksListFilters';
import TaskListModal from './TaskListModal';
import TasksTable from './TasksTable';



export const initFilter = {
  show: false,
  task: '',
  project: '',
  deadline: {
    from: '',
    to: '',
  },
  project_hc_release_date: {
    from: '',
    to: '',
  },
  project_hc_content_type: '',
  finished_at: {
    from: '',
    to: '',
  },
  tags_id: [],
  tags: [],
  image_type: '',
  priority: '',
  taps: {
    from: '',
    to: '',
  },
  type: '',
  prev_executor: '',
  executor: '',
  paid: '',
  coloring_editor: '',
  title: '',
  sort: {},
  skip: 0,
  category_id: '',
};


export class TasksList extends MyComponent {
  state = {
    total: 0,
    task: {},
    filter: { ...initFilter },
    currentPath: '',
    errorLoadPreview: [],
    LOAD_TASKS: true,
    taskViewTable: !!Number(localStorage.getItem('tasks_type_view')),
    isMobile: isScreenMd(),
    countOnPage: Number(localStorage.getItem('countTasksOnPage') || 50),
    selectedTasks: [],
    selectedProjects: [],
    selectedGroup: '',
    showModalCategories: false,
    showChangeImageTypeModal: false,
    changedTask: {},
    showModalTags: false,
    project_id: '',
    updated_task: {},
    category_id: '',
    LOAD_CATEGORY: false,
    tasksInitial: [],
    canChangeCategory: true,
    requestForExecutor: false,
    executorsTasks: [],
    showModalForSetGroup: false,
  };

  constructor (props) {
    super(props);

    if (props.history.location.search) {
      this.state.filter = {
        ...this.state.filter,
        ...parseFromPars(props.history.location.search),
      };
    }
  }

  computed = {
    isColoring: (state, { userGroups }) => userGroups.findIndex((group) => group === groupsUsers.coloring) !== -1,
    listTasks: ({
      executorsTasks, taskViewTable, filter, filter: { sort }, countOnPage,
    }, {
      userRoles, match: { params }, userId, tasks, multipleRoles, currentRole,
    }) => {
      const isMultipleRole = multipleRoles.length > 0;

      if (isEmptyObject(executorsTasks)) {
        const type = params.type;
        const status = params.status;
        const taskAttribute = type || (status ? `status_${status}` : null);

        if (taskAttribute) {
          tasks = params.type_task && params.type_task !== 'editor'
            ? tasks[taskAttribute].filter((task) => task.type === params.type_task)
            : isMultipleRole ?
              tasks[taskAttribute].filter((task) => taskTypes[task.type].role === currentRole)
              : tasks[taskAttribute];
        } else {
          tasks = [];
        }
      }

      if (taskViewTable && sort && Object.keys(sort).length > 0) {
        const keys = Object.keys(sort);
        const iterable = [ ...keys.map((key) => {
          switch (key) {
            case 'priority': {
              return (task) => task.priority;
            }
            case 'title': {
              return (task) => task.title;
            }
            default: {
              return key;
            }
          }
        }), 'is_reopened' ];

        return orderBy(
          !isEmptyObject(executorsTasks) && executorsTasks || tasks,
          iterable,
          [ ...keys.map((key) => sort[key]), 'desc' ]
        ).slice(filter.skip, filter.skip + countOnPage);
      }

      return checkRole(userRoles, [ ...groupsRoles.managers.coloring, ...groupsRoles.editors.coloring ])
        ? (!isEmptyObject(executorsTasks) && executorsTasks || tasks).slice(filter.skip, filter.skip + countOnPage)
        : orderBy(
          !isEmptyObject(executorsTasks) && executorsTasks || tasks,
          [
            (task) => params.type === tasksTypes.finished ? (task.finished_at ? (new Date(task.finished_at)).getTime() : Infinity) : 0,
            (task) => task.executor_id && task.executor_id === userId ? 1 : 0,
            (task) => task.priority,
            (task) => task.deadline_to ? task.deadline_to.getTime() : Infinity,
          ],
          [ 'desc', 'desc', 'desc', 'asc' ]
        ).slice(filter.skip, filter.skip + countOnPage);
    },
    listPreview: () => {
      return this.computed.listTasks.map((task) => ({
        id: task.id,
        link: getPreviewLg(task.preview),
      })).filter((i) => i.link);
    },
    uniqTags: ({ task }) => {
      return (task.project && task.project.tags) ? uniqBy(task.project.tags, (item) => item.slug) : [];
    },
    isChecked: (state, { userRoles, match }) => {
      return !!(match.params.state || checkRole(userRoles, [
        ...groupsRoles.editors.coloring,
        ...groupsRoles.managers.coloring,
      ]));
    },
    showPrevExecutor: (state, { match: { params }, userRoles }) => {
      if (checkRole(userRoles, roles['content-manager'].key) && params.type === tasksTypes.reopened) {
        return false;
      }
      return params.status ? (params.status === status.waiting || params.status === status.finished) : true;
    },
    showFilterPrevExecutor: (state, { userRoles }) => checkRole(
      userRoles,
      [
        ...groupsRoles.editors.coloring,
        ...groupsRoles.managers.coloring,
      ]
    ),
    executors: (state, { users, userRoles }) => {
      if (this.computed.showPrevExecutor) {
        if (checkRole(userRoles, roles['content-manager'].key)) {
          return users.filter((user) => checkRole(user.roles, roles['coloring-artist'].key));
        }

        return users.filter((user) => checkRole(user.roles, groupsRoles.executors.withoutTesters));
      }

      return [];
    },
    canSelected: (state, { match: { params }, userRoles }) => {
      const isExecutorsChoosingType = params.type_task === taskTypes.designer_choosing.type || params.type_task === taskTypes.artist_choosing.type;
      const isGroupExecutorsChoosingType = params.type_task === taskTypes.designers_group_choosing.type || params.type_task === taskTypes.artists_group_choosing.type;

      return ([ 'all', 'my', 'available' ].includes(params.type)
          && (isGroupExecutorsChoosingType || isExecutorsChoosingType)
          && checkRole(userRoles, [ roles['lead-coloring-editor'].key, roles['coloring-editor'].key ]))
        || ([ 'my', 'available' ].includes(params.type) && checkRole(userRoles, [ roles['content-manager'].key ]));
    },

    totalTasks: (state, { match: { params }, tasks }) => {
      if (!state.executorsTasks.length) {
        const type = params.type;
        const status = params.status;
        const taskAttribute = type || (status ? `status_${status}` : null);

        if (taskAttribute && tasks[taskAttribute]?.length) {
          tasks = params.type_task && params.type_task !== 'editor'
            ? tasks[taskAttribute].filter((task) => task.type === params.type_task)
            : tasks[taskAttribute];
          return tasks.length;
        }
        return tasks[taskAttribute].length;
      }
      return state.executorsTasks.length;
    },

    showTaskDeadline: (state, { userRoles }) => {
      return checkRole(userRoles, groupsRoles.coloring);
    },
    showProjectDeadline: (state, { userRoles }) => {
      return checkRole(userRoles, [ ...groupsRoles.managers.all, ...groupsRoles.editors.all ]);
    },
    showFilterFinishedAt: (state, { match }) => {
      return match.params.type === 'finished';
    },
    isContentManager: (state, { userRoles }) => {
      return checkRole(userRoles, [ roles['content-manager'].key ]);
    },
  };

  TasksFilters = TasksFilters.bind(this);

  TaskListModal = TaskListModal.bind(this);

  TasksTable = TasksTable.bind(this);


  render () {
    const { canSelected } = this.computed;

    return this.state.LOAD_TASKS ? (
      <Preload />
    ) : (
      <>
        <TasksListSubheader
          filter={this.state.filter}
          isMobile={this.state.isMobile}
          taskViewTable={this.state.taskViewTable}
          total={this.computed.totalTasks}
          countOnPage={this.state.countOnPage}
          selectedTasks={this.state.selectedTasks}
          selectedProjects={this.state.selectedProjects}
          canSelected={canSelected}
          selectedGroup={this.state.selectedGroup}
          setPage={this._setPage}
          addFilter={this._addFilter}
          removeFilter={this._removeFilter}
          turnViewGrid={this._turnViewGrid}
          turnViewFilters={this._turnViewFilters}
          turnCountOnPage={this._turnCountOnPage}
          requestForExecutor={this.state.requestForExecutor}
          groups={this.props.groups}
          showModalForSelectGroup={this._toggleModal}
        />
        <div className="row">
          {this.TasksFilters()}
          {this.TasksTable()}
        </div>
        {this.TaskListModal()}
        <SetGroupConfirmModal
          isActive={this.state.showModalForSetGroup}
          toggleModal={this._toggleModal}
          onConfirm={this._setGroupForSelectedTasks}
          title={this.props.lang['TASK.CONFIRM_GROUP_FOR_TASKS']}
          body={this._getSelectGroupModalBody()}
        />
        <ChangeImageTypeModal
          isActive={this.state.showChangeImageTypeModal}
          task={this.state.changedTask}
          onConfirm={this._onImageTypeChange}
          showModal={this._toggleChangeImageTypeModal}
        />
      </>
    );
  }

  componentDidMount () {
    if (this.props.users.length) {
      this._getTasks();
    }

    window.addEventListener('resize', this._eventResize);
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this._eventResize);
  }

  componentWillReceiveProps (nextProps, _nextContext) {
    if (!this.state.LOAD && this.state.tasksInitial.length === 0 && nextProps.users.length) {
      this._getTasks();
    }
  }

  componentDidUpdate (prevProps) {
    if (
      this.props.match.params.status !== prevProps.match.params.status
      || this.props.match.params.type !== prevProps.match.params.type
      || this.props.match.params.type_task !== prevProps.match.params.type_task
    ) {
      this.setState({
        filter: {
          ...initFilter,
          ...(window.location.search ? parseFromPars(window.location.search) : {}),
        },
        selectedTasks: [],
        selectedProjects: [],
      }, this._getTasks);
    } else if (this.props.location.search !== prevProps.location.search) {
      this.setState({
        filter: {
          ...initFilter,
          ...(window.location.search ? parseFromPars(window.location.search) : {}),
        },
      });
    }
  }

  _groupsOptionsList = () => {
    const options = [ <option key="0" value="" disabled>{this.props.lang['GLOBAL.CHOOSE_GROUP']}</option> ];

    if (!this.props.groups || this.props.groups.length === 0) {
      return options;
    }

    return options.concat(this.props.groups.map((group) => {
      return <option key={group.id} value={group.id}>{group.name}</option>;
    }));
  };

  _getSelectGroupModalBody = () => {
    return (
      <div className='kt-ml-5'>
        <Form.Group className='d-flex kt-m-0 kt-ml-5 align-items-center'>
          <Form.Control
            size="sm"
            as={'select'}
            value={this.state.selectedGroup}
            onChange={(event) => this._handleGroupChange(event.target.value)}
          >
            {this._groupsOptionsList()}
          </Form.Control>
        </Form.Group>
      </div>
    );
  };

  _resetSelectedGroup = () => {
    this.setState({ selectedGroup: 0 });
  };

  _setGroupForSelectedTasks = async () => {
    try {
      await axiosApiInstance.put('/tasks/group', {
        tasks_ids: this.state.selectedTasks,
        group_id: this.state.selectedGroup,
      });
      this.props.notification('NOTIFICATION.SUCCESS_ASSIGN_MULTIPLE_TASKS_FOR_GROUP', 'success');
      this.setState({
        selectedTasks: [],
        selectedProjects: [],
        selectedGroup: '',
      });
      await this._getTasks();
    } catch (error) {
      this.props.notification('NOTIFICATION.ERROR_ASSIGN_MULTIPLE_TASKS_FOR_GROUP', 'error');
      this._resetSelectedGroup();
      // eslint-disable-next-line no-console
      console.error(error);
    }

    this._toggleModal();
  };

  _toggleModal = () => {
    this.setState((prevState) => ({
      showModalForSetGroup: !prevState.showModalForSetGroup,
    }));
  };

  _toggleChangeImageTypeModal = () => {
    this.setState((prevState) => ({
      showChangeImageTypeModal: !prevState.showChangeImageTypeModal,
    }));
  };

  /**
   * Show change image type modal
   *
   * @param {any} task
   * @returns {void}
   * @private
   */
  _showChangeImageTypeModal = (task) => {
    this._toggleChangeImageTypeModal();
    this.setState({
      changedTask: task,
    });
  };

  /**
   * On image type change
   *
   * @param {int} projectId
   * @param {string} imageType
   * @returns {Promise<void>}
   * @private
   */
  _onImageTypeChange = async (projectId, imageType) => {
    this.setState({
      LOAD_TASKS: true,
    });
    const data = {
      image_type: imageType,
    };

    try {
      await axiosApiInstance.put(`projects/${projectId}/image-type`, data);
      this.props.notification('NOTIFICATION.SUCCESS_PROJECT_EDIT_IMAGE_CATEGORY', 'success');
      await this._getTasks();
      this.setState({
        showChangeImageTypeModal: false,
        changedTask: {},
      });
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.props.notification('NOTIFICATION.ERROR_PROJECT_EDIT_IMAGE_CATEGORY', 'error');
      this.setState({
        LOAD_TASKS: false,
      });
    }
  };

  /**
   * Handle group change
   *
   * @param {int|string} groupId
   * @private
   * @returns {void}
   */
  _handleGroupChange = (groupId) => {
    this.setState({
      selectedGroup: groupId,
    });
  };

  _saveCategory = () => {
    this.setState({
      LOAD_CATEGORY: true,
    });

    addCategories(this.state.category_id, this.state.project_id)
      .then(() => {
        const type = this.props.match.params.type;

        this.props.updateTask(this.state.updated_task, this.state.category_id, type);
        this.props.notification('NOTIFICATION.SUCCESS_CATEGORY_EDIT', 'success');
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_CATEGORY_EDIT', 'error');
        }
      });

    this.setState({
      LOAD_CATEGORY: false,
    });
  };

  /**
   * Trigger modal
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalCategories = (val) => {
    this.setState({
      showModalCategories: val,
    });
  };

  /**
   * Set modal tags
   *
   * @param {boolean} val
   * @returns {void}
   * @private
   */
  _setModalTags = (val) => {
    this.setState({
      showModalTags: val,
    });
  };

  /**
   * Set project id
   *
   * @param {int} val
   * @returns {void}
   * @private
   */
  _setProjectId = (val) => {
    this.setState({
      project_id: val,
    });
  };

  /**
   * Set updated task
   *
   * @param {any} val
   * @returns {void}
   * @private
   */
  _setUpdatedTask = (val) => {
    this.setState({
      updated_task: val,
    });
  };

  /**
   * Set category id
   *
   * @param {int} val
   * @returns {void}
   * @private
   */
  _setCategoryId = (val) => {
    this.setState({
      category_id: val,
    });
  };

  /**
   * Set tags
   *
   * @param {Array} tags
   * @private
   * @returns {void}
   */
  _setTags = (tags) => {
    const { task } = this.state;

    this.setState({
      task: {
        ...task,
        project: {
          ...task.project,
          tags,
        },
      },
    });
  };

  _eventResize = () => {
    const isMd = isScreenMd();

    if (this.state.isMobile && !isMd) {
      this.setState({
        isMobile: false,
      });
    } else if (!this.state.isMobile && isMd) {
      this.setState({
        isMobile: true,
      });
    }
  };

  _getTasks = () => {
    this.setState({
      LOAD_TASKS: true,
      LOAD: true,
    }, async () => {
      let tasksInitial = {};
      const typeTask = this.props.match.params.type_task;

      const type = this.props.match.params.type;
      const tasksAttribute = this._getTasksAttribute();

      const queryParams = window.location.search;

      const url = getTypeTaskUrl(typeTask, type, {
        paramsStatus: this.props.match.params.status,
        coloringEditor: this.state.coloring_editor,
      });

      const params = queryParams ? parseFromPars(queryParams) : {};

      if (params.for_executor && !this.state.requestForExecutor) {
        await getTasksTypeForExecutor(url).then((response) => {
          this.setState({
            executorsTasks: response.tasks,
            requestForExecutor: true,
            canChangeCategory: false,
          });
        }).catch(() => {
          this.setState({
            requestForExecutor: false,
          });
        }).finally(() => {
          this.setState({
            LOAD_TASKS: false,
          });
        });
      } else {
        const filteredParams = parseFromPars(queryParams);

        await this.props.getTasks(url, tasksAttribute, filteredParams).then((response) => {
          tasksInitial = typeTask && typeTask !== 'editor' ? response.filter((task) => task.type === typeTask) : response;

          this.setState({
            canChangeCategory: true,
            tasksInitial,
            requestForExecutor: false,
          });
        }).catch(() => {
          this.setState({
            total: 0,
          });
        }).finally(() => {
          this.setState({
            LOAD_TASKS: false,
          });
        });
      }
    });
  };

  _getTasksAttribute = () => {
    const type = this.props.match.params.type;
    const status = this.props.match.params.status;

    return type || (status ? `status_${status}` : null);
  };

  /**
   * Add filter
   *
   * @param {object} filter
   * @private
   * @returns {void}
   */
  _addFilter = (filter) => {
    const tasksAttribute = this._getTasksAttribute();

    this.props.setTasks(this.state.tasksInitial, tasksAttribute);

    this.setState({
      filter: {
        ...this.state.filter,
        skip: 0,
        ...filter,
      },
      canChangeCategory: false,
    }, () => {
      const pars = parseToPars({
        ...this.state.filter,
        show: undefined,
      });

      this.props.history.push(this.props.history.location.pathname + pars);
      this.props.filterTasks(parseFromPars(pars), tasksAttribute);
    });
  };

  _removeFilter = (...keys) => {
    if (this.state.requestForExecutor) {
      const newFilter = { ...this.state.filter, skip: 0, for_executor: undefined };

      if (keys) {
        keys.forEach((key) => {
          // eslint-disable-next-line fp/no-delete
          delete newFilter[key];
        });
      }

      this.setState({
        executorsTasks: {},
        filter: newFilter,
        canChangeCategory: true,
      }, () => {
        const pars = parseToPars({ ...newFilter, show: undefined });

        this.props.history.push(this.props.history.location.pathname + pars);
        this._getTasks();
      });
    } else {
      const tasksAttribute = this._getTasksAttribute();

      this.props.setTasks(this.state.tasksInitial, tasksAttribute);
      const newFilter = { ...this.state.filter, skip: 0 };

      if (keys) {
        keys.forEach((key) => {
          // eslint-disable-next-line fp/no-delete
          delete newFilter[key];
        });
      }

      this.setState({
        filter: newFilter,
        canChangeCategory: true,
      }, () => {
        const pars = parseToPars({ ...newFilter, show: undefined });

        this.props.history.push(this.props.history.location.pathname + pars);
        this.props.filterTasks(parseFromPars(pars), tasksAttribute);
      });
    }
  };

  _applyFilters = () => {
    const tasksAttribute = this._getTasksAttribute();

    this.props.setTasks(this.state.tasksInitial, tasksAttribute);
    this.setState({
      filter: {
        ...this.state.filter,
        skip: 0,
        show: false,
      },
      canChangeCategory: true,
    }, () => {
      const pars = parseToPars({
        ...this.state.filter,
        show: undefined,
      });

      this.props.history.push(this.props.history.location.pathname + pars);
      this.props.filterTasks(parseFromPars(pars), tasksAttribute);
    });
  };

  _resetFilters = () => {
    const tasksAttribute = this._getTasksAttribute();

    this.setState({
      filter: { ...initFilter },
      canChangeCategory: true,
    }, () => {
      this.props.history.push(this.props.history.location.pathname);
      this.props.setTasks(this.state.tasksInitial, tasksAttribute);
    });
  };

  /**
   * Set current page
   *
   * @param {int} newPage
   * @private
   * @returns {void}
   */
  _setPage = (newPage) => {
    this.setState({
      filter: {
        ...this.state.filter,
        skip: newPage * this.state.countOnPage,
        show: false,
      },
    }, () => {
      const pars = parseToPars({ ...this.state.filter, show: undefined });

      this.props.history.push(this.props.history.location.pathname + pars);
    });
  };

  _turnViewGrid = () => {
    localStorage.setItem('tasks_type_view', this.state.taskViewTable ? '0' : '1');

    this.setState({
      taskViewTable: !this.state.taskViewTable,
    });
  };

  _turnViewFilters = () => {
    if (!this.state.filter.show) {
      KTUtil.scrollTop(0, 300);
    }

    this.setState({
      filter: {
        ...this.state.filter,
        show: !this.state.filter.show,
      },
    });
  };

  /**
   * Set count task on page
   *
   * @param {int|string} count
   * @private
   * @returns {void}
   */
  _turnCountOnPage = (count) => {
    localStorage.setItem('countTasksOnPage', count);

    this.setState({
      filter: {
        ...this.state.filter,
        skip: 0,
      },
      countOnPage: Number(count),
    }, () => {
      const pars = parseToPars({ ...this.state.filter, show: undefined });

      this.props.history.push(this.props.history.location.pathname + pars);
      KTUtil.scrollTop(0, 300);
    });
  };

  /**
   * Start task
   *
   * @param {int} id
   * @returns {void}
   * @private
   */
  _startTask = (id) => {
    this.setState({
      LOAD_TASKS: true,
    });
    const fd = new FormData();

    fd.append('_method', 'PUT');

    axiosApiInstance.post(`tasks/${id}/start`, fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_TASK_START', 'success');
        this._getTasks();
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_TASK_START', 'error');
        }
      });

    this.setState({
      LOAD_TASKS: false,
    });
  };
}

export const mapStoreToProps = (store) => {
  return {
    userGroups: store.user.groups,
    userId: store.user.user.id,
    userRoles: store.user.roles,
    multipleRoles: store.user.multipleRoles,
    currentRole: store.user.currentRole,
    lang: store.language.lang,
    tasks: store.tasks,
    tags: store.tags,
    categories: store.categories,
    users: store.users,
    groups: store.groups.groups,
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    getTasks: bindActionCreators(getTasks, dispatch),
    setTasks: bindActionCreators(setTasks, dispatch),
    updateTask: bindActionCreators(updateTask, dispatch),
    filterTasks: bindActionCreators(filterTasks, dispatch),
    notification: bindActionCreators(notification, dispatch),
  };
};

export default connect(mapStoreToProps, mapDispatchToProps)(TasksList);
