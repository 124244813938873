import React from 'react';
import PropTypes from 'prop-types';



const propTypes = {
  parent: PropTypes.any,
  project: PropTypes.any,
  index: PropTypes.any,
};
/**
 * Component-wrapper to be rebuilt only when this project is changed and not any
 */

// eslint-disable-next-line react/prefer-stateless-function
class Project extends React.PureComponent {
  render () {
    /**
     *@type {ProjectCreate}
     */
    const parent = this.props.parent;
    const { project, index } = this.props;

    return (
      <>
        <div
          className='w-100 d-flex align-items-center kt-ml-a kt-mr-a'
          style={{ maxWidth: 1024 }}
        >
          <span
            className='w-100 d-block bg-dark border-bottom border-top'
          />
          <h2 className='kt-pl-10 kt-pr-10 font-weight-bold kt-mb-0'>{index + 1}</h2>
          <span
            className='w-100 d-block bg-dark border-bottom border-top'
          />
        </div>
        {parent._buildProject(
          { ...parent.state.project, ...project, index, prices: { ...parent.state.project.prices, ...project.prices } },
          (pr) => {
            const { project } = parent.state;

            Object.keys(pr).forEach((key) => {
              if (key === 'prices') {
                Object.keys(pr.prices).forEach((key) => {
                  if (pr.prices[key] === project.prices[key]) {
                    // eslint-disable-next-line fp/no-delete
                    delete pr.prices[key];
                  }
                });
              } else if (pr[key] === project[key]) {
                // eslint-disable-next-line fp/no-delete
                delete pr[key];
              }
            });

            const prjcts = [ ...parent.state.projects ];

            prjcts[index] = pr;

            parent.setState({
              projects: prjcts,
            });
          },
        )}
      </>);
  }
}

Project.propTypes = propTypes;

/**
 * Builds a step on which information is entered for each created project individually
 *
 * @this {ProjectCreate}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { projects } = this.state;

  return (
    <div className='kt-wizard-v1__content'>
      <div className='kt-section'>
        <div className='kt-wizard-v1__form'>
          {projects.slice(0, Number(this.state.countProjects)).map((pr, index) => {
            return (
              <Project
                key={pr[index]}
                project={pr}
                index={index}
                parent={this}
                showInvalid={this.state.showInvalid}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
