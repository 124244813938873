import { Form } from 'react-bootstrap';
import { filter } from 'lodash';
import Button from 'react-bootstrap/Button';
import React from 'react';
import DropFile from '../../../widgets/DropFile';



export default function UploadReferenceBlock (showUploadButton) {
  const { selectedRefFiles } = this.state;

  const { lang } = this.props;

  return (
    <>
      <Form.Label className="w-100 kt-mb-15">
        {`${lang['TASK.AVAILABLE_REF_FILES']}: jpg, jpeg, png, bmp, gif, svg, webp.`}
      </Form.Label><Form.Group className="kt-mb-20">
        <Form.Control
          as={DropFile}
          className="h-auto"
          theme="brand"
          hasCustomText
          customInnerText={lang['GLOBAL.DROP_REFERENCE_FILES']}
          showPreview={false}
          styles={{ minHeight: 110 }}
          curFiles={selectedRefFiles}
          addFiles={(result) => {
            this.setState({
              selectedRefFiles: [ ...selectedRefFiles, ...result ],
            });
          }}
          removeFile={(file) => {
            this.setState({
              selectedRefFiles: filter(selectedRefFiles, (item) => item !== file),
            });
          }}
        />
      </Form.Group>
      <div className="d-flex justify-content-center kt-mt-a">
        { showUploadButton && (
          <Button
            variant="light"
            className="btn-upper mx-1 font-weight-bold"
            disabled={selectedRefFiles.length === 0}
            type="submit"
            onClick={() => {
              if (!this.state.selectedRefFiles) {
                this.props.notification('TASK.ERROR_FILES', 'error');
              } else {
                this._loadRefFiles(selectedRefFiles, null, () => {
                  this.setState({
                    selectedRefFiles: [],
                    showLoadFiles: false,
                  });
                });
              }
            }}
          >
            {lang['GLOBAL.UPLOAD_REFERENCES']}
          </Button>
        )}
      </div>
    </>
  );
}

