import { useState } from 'react';



const useToggle = () => {
  const [ isActive, setIsActive ] = useState(false);

  const toggle = () => {
    setIsActive((prevState) => !prevState);
  };

  return [ isActive, toggle ];
};

export {
  useToggle,
};
