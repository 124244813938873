import React from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Button from 'react-bootstrap/Button';
import KtContent from '../../../_metronic/layout/KtContent';
import LanguageSelector from '../../../_metronic/partials/layout/LanguageSelector';
import termActions from '../../store/term/actions';
import Preload from '../../widgets/Preload';



/**
 * Component for the page for accepting the user contract if he did not accept it
 */

const Term = () => {
  const dispatch = useDispatch();
  const { term: { term_id, term, loading }, lang, curLang } = useSelector(({ term, language }) => ({
    term,
    lang: language.lang,
    curLang: language.cur,
  }), shallowEqual);

  const acceptTerm = () => {
    dispatch(termActions.acceptTerm(term_id));
  };

  return (<>{loading ? (
    <Preload />
  ) : (
    <div
      id="kt_content"
      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
    >
      <div className="kt-header__topbar" style={{ justifyContent: 'flex-end' }}>
        <LanguageSelector iconType="" />
      </div>
      <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
        <KtContent>
          <div className="row">
            <div className="col-xl-12">
              <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head no-print w-100">
                  <div className="kt-portlet__head-label no-print w-100">
                    <h1 className="text-center w-100">
                      {lang['TERM.YOU_SHOULD']}
                    </h1>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="row w-100">
                    <div
                      className="col-lg-12"
                      dangerouslySetInnerHTML={{ __html: term && term?.hasOwnProperty(curLang) ? term[curLang] : '' }}
                    />
                    <div className='col-12 d-flex justify-content-center'>
                      <Button
                        variant='success'
                        onClick={acceptTerm}
                      >{lang['GLOBAL.ACCEPT']}</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KtContent>
      </div>
    </div>
  )}
  </>);
};

export default Term;
