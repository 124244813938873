import { toAbsoluteUrl } from '../../utils/gettersUrl';

/**
 * List of icons for file types
 *
 * @type {{jpg: string, zip: string, psd: string, css: string, svg: string, gif: string, csv: string, png: string, ai: string, js: string, eps: string, javascript: string, mp4: string, file: string, pdf: string, ico: string, mov: string, xml: string, doc: string, json: string, html: string}}
 */
export const linksToIconsFiles = {
  svg: toAbsoluteUrl('/media/files/svg.svg'),
  css: toAbsoluteUrl('/media/files/css.svg'),
  csv: toAbsoluteUrl('/media/files/csv.svg'),
  doc: toAbsoluteUrl('/media/files/doc.svg'),
  file: toAbsoluteUrl('/media/files/file.svg'),
  gif: toAbsoluteUrl('/media/files/gif.svg'),
  html: toAbsoluteUrl('/media/files/html.svg'),
  js: toAbsoluteUrl('/media/files/javascript.svg'),
  javascript: toAbsoluteUrl('/media/files/javascript.svg'),
  jpg: toAbsoluteUrl('/media/files/jpg.svg'),
  mov: toAbsoluteUrl('/media/files/mov.svg'),
  mp4: toAbsoluteUrl('/media/files/mp4.svg'),
  pdf: toAbsoluteUrl('/media/files/pdf.svg'),
  png: toAbsoluteUrl('/media/files/png.svg'),
  xml: toAbsoluteUrl('/media/files/xml.svg'),
  zip: toAbsoluteUrl('/media/files/zip.svg'),
  ai: toAbsoluteUrl('/media/files/ai.svg'),
  eps: toAbsoluteUrl('/media/files/eps.svg'),
  psd: toAbsoluteUrl('/media/files/psd.svg'),
  ico: toAbsoluteUrl('/media/files/ico.svg'),
  json: toAbsoluteUrl('/media/files/json.svg'),
};

/**
 * Gets the icon for the file
 *
 * @param {string} prefix
 * @returns {string}
 */
const getLinkToIcon = (prefix) => linksToIconsFiles[prefix.slice((prefix.replace('_', '.').lastIndexOf('.')) + 1).toLowerCase()] || linksToIconsFiles.file;

export default getLinkToIcon;
