import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { parseFromPars } from '../../utils/parseUrlParams';
import getBrowserLanguage from '../../utils/getBrowserLanguage';
import setLanguage from '../../store/lang/setLanguage';
import axiosApiInstance from '../../requests/utils/api';
import { MODAL_TYPES, useGlobalModalContext } from '../../components/GlobalModal';
import RegistrationEmployee from './RegistrationEmployee';
import RegistrationUser from './RegistrationUser';
import Footer from './components/Footer';



/**
 * User registration component
 */

const Registration = () => {
  const location = useLocation();
  const history = useHistory();
  const { curLang } = useSelector(({ language }) => ({
    curLang: language.cur,
  }), shallowEqual);
  const { showModal } = useGlobalModalContext();

  const [ policy, setPolicy ] = useState({});
  const [ term, setTerm ] = useState({});
  const [ code, setCode ] = useState('');
  const [ isEmployee, setIsEmployee ] = useState(false);


  const setParams = () => {
    const parameters = parseFromPars(location.search);

    setCode(parameters.code);
    setIsEmployee(!!parameters.is_employee);
  };

  useEffect(() => {
    const parameters = parseFromPars(location.search);

    if (!parameters.code) {
      history.push('/login');
      return;
    }

    setParams();

    const lang = getBrowserLanguage();

    setLanguage(lang).then();
  }, []);

  const getTerm = async () => {
    try {
      const response = await axiosApiInstance.get('terms');

      await setTerm(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const getPolicy = async () => {
    try {
      const response = await axiosApiInstance.get('privacies');

      setPolicy(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(() => {
    Promise.allSettled([
      getPolicy(),
      getTerm(),
    ]);
  }, []);

  const termModal = () => {
    showModal(MODAL_TYPES.CONTRACT_MODAL, {
      contract: term[curLang],
    });
  };

  const policyModal = async () => {
    showModal(MODAL_TYPES.CONTRACT_MODAL, {
      contract: policy[curLang],
    });
  };

  return isEmployee ? (
    <RegistrationEmployee code={code} />
  ) : (
    <>
      <RegistrationUser
        code={code}
        showPrivacyPolicy={policyModal}
        policy={policy}
        term={term}
        showTerm={termModal}
      />
      <Footer />
    </>
  );
};

export default Registration;
