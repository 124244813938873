import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { SHOW_TASK_DETAILS_BONUSES_MAP, TASK_BONUS_STATUS } from '../../../../../const/tasks';
import taskTypes from '../../taskTypes';
import canSetTags from '../../helpers/canSetTags';
import uniqTags from '../../helpers/uniqTags';
import TagsWidget from '../../../../components/Tags/TagsWidget';



const WidgetBottom = ({
  task,
  groupName,
  userId,
  showModal,
  openChat,
  isStarted,
}) => {
  const lang = useSelector((store) => store.language.lang);

  const isChoseExecutor = () => {
    return task.type
      ? (taskTypes[task.type].type === taskTypes.designer_choosing.type || taskTypes[task.type].type === taskTypes.artist_choosing.type)
      : null;
  };

  const showBonusWidget = !isEmpty(task.bonus) && SHOW_TASK_DETAILS_BONUSES_MAP.includes(task.type)
    && task.bonus.status !== TASK_BONUS_STATUS.CANCELED;

  return (
    <div className="kt-widget__bottom justify-content-between">
      <div className="kt-widget__item flex-grow-0">
        <div className="kt-widget__icon">
          <i className="la la-money" />
        </div>
        <div className="kt-widget__details">
          <span className="kt-widget__title">{lang['TASK.PRICE']}</span>
          <span className="kt-widget__value">
            <span>€</span>
            {task.price}
          </span>
        </div>
      </div>

      {showBonusWidget && (
        <div className="kt-widget__item kt-ml-20">
          <div className="kt-widget__icon">
            <i className="la la-money" />
          </div>
          <OverlayTrigger
            placement="top"
            delay={{ show: 10, hide: 2000 }}
            overlay={
              <Tooltip id={'BONUS_VALUE'}>
                {task.bonus?.reason || '---'}
              </Tooltip>
            }
          >
            <div className="kt-widget__details">
              <span className="kt-widget__title">
                {lang['TASK.BONUS_VALUE']} &nbsp;
                <i className="far fa-question-circle kt-pl-3" style={{ color: '#2c77f4' }} />
              </span>
              <span className="kt-widget__value">
                <span>€</span>
                {task.bonus?.amount || 0}
              </span>
            </div>
          </OverlayTrigger>
        </div>
      )}

      <TagsWidget
        canSetTags={canSetTags(task, userId)}
        handleAction={() => {
          showModal(true, task.id);
        }}
        tags={uniqTags(task)}
      />

      <div className="kt-widget__item flex-grow-0">
        {isStarted && <div className="kt-widget__icon">
          <button
            className="btn" onClick={() => {
              openChat(true, task.id);
            }}
          ><i className="flaticon-chat" style={{ color: '#5578eb' }} /></button>
        </div>}
      </div>

      {groupName && isChoseExecutor() && (
        <div className="kt-widget__item flex-grow-0">
          <div className="kt-widget__icon">
            <i className="flaticon-users-1" />
          </div>
          <div className="kt-widget__details">
            <span className="kt-widget__title">{`${lang['PROJECT.GROUP']}: `}</span>
            <span className="kt-widget__value">
              {groupName}
            </span>
          </div>
        </div>
      )}
      {!groupName && isChoseExecutor() && (
        <div className="kt-widget__item flex-grow-0">
          <div className="kt-widget__icon">
            <i className="flaticon-users-1 danger" />
          </div>
          <div className="kt-widget__details">
            <span className="kt-widget__title danger">{lang['PROJECT.GROUP_WAS_REMOVED']}</span>
          </div>
        </div>
      )}
    </div>
  );
};

WidgetBottom.propTypes = {
  isStarted: PropTypes.bool.isRequired,
  task: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    executor_id: PropTypes.number,
    status: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
    tags: PropTypes.arrayOf(PropTypes.object).isRequired,
    project: PropTypes.shape({
      tags: PropTypes.array,
    }).isRequired,
    bonus: PropTypes.shape({
      amount: PropTypes.number,
      reason: PropTypes.string,
      status: PropTypes.number,
    }),
  }).isRequired,
  groupName: PropTypes.string,
  showModal: PropTypes.func.isRequired,
  userId: PropTypes.number.isRequired,
  openChat: PropTypes.func.isRequired,
};

export default WidgetBottom;
