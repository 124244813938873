import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { contourComplexityLevels } from '../../projects/projectPrices';
import Modal from '../../../widgets/Modal';



const highestComplexityLevel = 4;

const SetComplexityLevelModal = ({ task, showModal, isActive, onConfirm, type }) => {
  const [ project, setProject ] = useState({
    options: {
      contour_complexity_level: '2',
      color_complexity_level: '1',
      artist_price: '50',
      designer_price: '34',
    },
  });
  const [ price, setPrice ] = useState(type === 'contour' ? '50' : '34');
  const [ complexityLevel, setComplexityLevel ] = useState('2');
  const lang = useSelector((store) => store.language.lang);

  const getPriceField = () => {
    return type === 'contour' ? 'artist_price' : 'designer_price';
  };

  useEffect(() => {
    if (task && task.project) {
      setProject({
        ...task.project,
        options: {
          contour_complexity_level: '2',
          color_complexity_level: '3',
          artist_price: '50',
          designer_price: '34',
          ...task.project.options,
        },
      });

      if (task.project.options[`${type}_complexity_level`]) {
        setComplexityLevel(task.project.options[`${type}_complexity_level`]);
      }

      if (task.project.options[getPriceField()]) {
        setPrice(task.project.options[getPriceField()]);
      }

    }
  }, [ task.project ]);

  const onComplexityChange = (event) => {
    setComplexityLevel(event.target.value);
  };

  const onPriceChange = (event) => {
    setPrice(event.target.value);
  };

  return (
    <Modal
      title={lang[`PROJECT.CHANGE_${type.toUpperCase()}_COMPLEXITY_LEVEL`]}
      show={isActive}
      close={() => {
        showModal(false, task.id);
      }}
      footer={(
        <>
          <button
            className="btn btn-secondary"
            onClick={() => {
              showModal(false, task.id);
            }}
          >
            {lang['GLOBAL.CANCEL']}
          </button>
          <button
            className="btn btn-success"
            disabled={(Number(complexityLevel) === Number(project.options[`${type}_complexity_level`]) && Number(complexityLevel) !== highestComplexityLevel) || (Number(complexityLevel) === highestComplexityLevel && Number(price) === Number(project.options[getPriceField()]) && Number(complexityLevel) === Number(project.options[`${type}_complexity_level`]))}
            onClick={async () => {
              showModal(false, task.id);
              await setProject((prevState) => {
                return {
                  ...prevState,
                  options: {
                    ...prevState,
                    [getPriceField()]: price,
                    [`${type}_complexity_level`]: complexityLevel,
                  },
                };
              });
              onConfirm({
                type: `${type}_complexity_level`,
                complexityLevel,
                project,
                price,
              });
            }}
          >
            {lang['GLOBAL.EDIT']}
          </button>
        </>
      )}
    >
      <Form.Group>
        <Form.Label>{lang[`PROJECT.FIELDS.${type}_complexity_level`]}</Form.Label>
        <Form.Control
          as="select"
          value={complexityLevel}
          onChange={onComplexityChange}
        >
          {contourComplexityLevels.map((type) => (
            <option key={type} value={type}>{type}</option>
          ))}
        </Form.Control>
        {Number(complexityLevel) === highestComplexityLevel && (
          <>
            <Form.Label>{lang[`PROJECT.FIELDS.${getPriceField()}`]}</Form.Label>
            <Form.Control
              value={price}
              onChange={onPriceChange}
            />
          </>
        )}
      </Form.Group>
    </Modal>
  );
};

SetComplexityLevelModal.propTypes = {
  task: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};

export default SetComplexityLevelModal;
