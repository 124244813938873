import React from 'react';
import PropTypes from 'prop-types';
import { minBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import Img from '../../../widgets/Img';
import imgURL from '../../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../../projects/getPreviewFromProject';
import { formatStringToDate, formatToPrettyDate } from '../../../utils/formats';
import { defaultCategoryValue, mapCategoryIdToName } from '../../../requests/categories';
import { TASK_BONUS_STATUS } from '../../../../const/tasks';
import getStatus from '../../tasks/getStatus';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import roles from '../roles/roles';



const cellStyles = {
  textAlign: 'center',
};

const Row = ({ task, listPreview, categories, isListBonuses, isContentManager, lang, showStatus }) => {
  const status = showStatus ? getStatus(task) : null;

  return (<tr>
    <td style={cellStyles}>
      <span className="kt-media kt-media--xxxl">
        <Img
          src={imgURL(getPreviewSm(task.preview))}
          current={{
            id: task.id,
            link: getPreviewLg(task.preview),
          }}
          canFull
          files={listPreview}
        />
      </span>
    </td>
    <td style={cellStyles}>
      {task.project_id}
    </td>
    <td style={cellStyles}>
      {task.title}
    </td>
    <td style={cellStyles}>
      {categories && mapCategoryIdToName(categories, task.category_id) || defaultCategoryValue}
    </td>
    <td style={cellStyles}>
      {formatToPrettyDate(task.started_at) || '---'}
    </td>
    <td style={cellStyles}>
      {formatToPrettyDate(task.finished_at) || '---'}
    </td>
    <td style={cellStyles}>
      {formatToPrettyDate(task.paid_at) || '---'}
    </td>
    <td style={cellStyles}>
      {!isContentManager && (
        <>
          {`${task.price}€`}
          {!isListBonuses && task.bonus.status !== TASK_BONUS_STATUS.CANCELED && (
            <>
              <br />
              {task.bonus.amount ? `${task.bonus.amount}€` : ''}
            </>
          )}
        </>
      )}
    </td>
    <td style={cellStyles}>
      {(lang[`GLOBAL.STATUS.${status}`] || '') || ''}
    </td>
  </tr>);
};

Row.propTypes = {
  task: PropTypes.object.isRequired,
  listPreview: PropTypes.array.isRequired,
  categories: PropTypes.array,
  isListBonuses: PropTypes.bool,
  showStatus: PropTypes.bool,
  isContentManager: PropTypes.bool,
  lang: PropTypes.object.isRequired,
};

class ReportToExport extends React.Component {
  constructor (props) {
    super(props);
  }

  render () {
    if (!this.props.customer || !this.props.customer.tasks) {
      return '';
    }

    // eslint-disable-next-line consistent-return
    const startWork = this.props.customer.tasks ? minBy(this.props.customer.tasks, ((task) => {
      const time = formatStringToDate(task.started_at);

      if (time) {
        return time.getTime();
      }
    })) : '';

    return (
      <div style={{ margin: '1em 2em' }}>
        <div className='row'>
          <div className="col-6">
            <SubHeader
              title={this.props.lang['CUSTOMER.VIEW_PROFILE']}
              info={this.props.customer.username}
            />
            <div className='kt-portlet'>
              <div className='kt-portlet__body'>
                <div className='kt-widget kt-widget--user-profile-3'>
                  <div className='kt-widget__top'>
                    <div className='row kt-container kt-container--fluid kt-p-0'>
                      <div className='col-xl-8 d-inline-flex'>
                        <div className='kt-widget__media d-inline-block'>
                          <Img src={imgURL(this.props.customer.avatar)} style={{ maxWidth: '100%' }} />
                        </div>
                        <div className="kt-widget__content d-inline-block">
                          <div className="kt-widget__head">
                            <div className="kt-widget__user">
                              <p className="kt-widget__username no-hover kt-mb-0">
                                {this.props.customer.useEffect}
                              </p>
                              <span className={`kt-badge kt-badge--bolder kt-badge kt-badge--inline kt-badge--unified-${this.props.customer.inactive ? 'danger' : 'success'}`}>
                                {this.props.customer.inactive ? this.props.lang['CUSTOMER.LOCKED'] : this.props.lang['CUSTOMER.ACTIVE']}
                              </span>
                            </div>
                          </div>
                          {this.props.customer.roles && this.props.customer.roles.length > 0 && (
                            <div className="kt-widget__subhead no-hover kt-pb-0">
                              <a className='no-hover'>
                                <i className='flaticon2-group' />
                                {roles[this.props.customer.roles[this.props.customer.roles.length - 1]].title}
                              </a>
                            </div>
                          )}
                          <div className="kt-widget__subhead">
                            <a href={`mailto:${this.props.customer.email}`}>
                              <i className="flaticon2-new-email" />
                              {this.props.customer.email}
                            </a>
                          </div>
                          <div className="kt-widget__info">
                            <div className="kt-widget__desc">
                              {this.props.customer.description}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className='offset-xl-1 col-xl-3 col-md-4 offset-md-8 col-12'>
                        <div className="kt-widget1">
                          <div className="kt-widget1__item">
                            <div className="kt-widget1__info">
                              <h3 className="kt-widget1__title">{this.props.lang['CUSTOMER.START_WORK']}</h3>
                            </div>
                            <span className="kt-widget1__number">
                              {startWork && startWork.started_at ? formatToPrettyDate(startWork.started_at) || '---' : '---'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className="kt-portlet">
              <div className="kt-portlet__head">
                <div className="kt-portlet__head-label">
                  <h3 className="kt-portlet__head-title font-weight-bold">
                    {this.props.lang['CUSTOMER.CONTACT_INFORMATION']}
                  </h3>
                </div>
              </div>
              <div className="kt-form kt-form--label-right">
                <div className="kt-portlet__body">
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">{this.props.lang['CUSTOMER.USERNAME']}:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">
                        {this.props.customer.username}
                      </span>
                    </div>
                  </div>
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">{this.props.lang['CUSTOMER.EMAIL']}:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">
                        <a href={`mailto:${this.props.customer.email}`}>{this.props.customer.email || this.props.lang['GLOBAL.NOT_ENTERED']}</a>
                      </span>
                    </div>
                  </div>
                  <div className="form-group form-group-xs row">
                    <label className="col-4 col-form-label">{this.props.lang['CUSTOMER.TELEGRAM']}:</label>
                    <div className="col-8">
                      <span className="form-control-plaintext kt-font-bolder">
                        {this.props.customer && this.props.customer.telegram ?
                          <a href={`tg://resolve?domain=${this.props.customer.telegram}`}>{this.props.customer.telegram}</a>
                          : <span>{this.props.lang['GLOBAL.NOT_ENTERED']}</span>
                        }
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <table width="100%" cellPadding={4}>
          <thead>
            <tr>
              <th />
              <th style={cellStyles}>ID</th>
              <th style={cellStyles}>{this.props.lang['TASK.TITLE']}</th>
              <th style={cellStyles}>{this.props.lang['GLOBAL.CATEGORY']}</th>
              <th style={cellStyles}>{this.props.lang['GLOBAL.BEGIN']}</th>
              <th style={cellStyles}>{this.props.lang['GLOBAL.FINISHED_AT']}</th>
              <th style={cellStyles}>{this.props.lang['GLOBAL.PAID_AT']}</th>
              <th style={cellStyles}>{this.props.isListBonuses ? this.props.lang['TASK.PRICE'] : this.props.lang['TASK.PRICE_AWARD'] }</th>
              <th style={cellStyles}>{this.props.lang['GLOBAL.STATUS']}</th>
            </tr>
          </thead>
          <tbody>
            {this.props.tasks.map((task) => (
              <Row
                key={uuidv4()}
                showStatus={this.props.showStatus}
                isListBonuses={this.props.isListBonuses}
                categories={this.props.categories}
                listPreview={this.props.listPreview}
                task={task}
                lang={this.props.lang}
              />))}
          </tbody>
        </table>
      </div>
    );
  }
}

ReportToExport.propTypes = {
  tasks: PropTypes.array.isRequired,
  lang: PropTypes.object.isRequired,
  listPreview: PropTypes.array,
  showStatus: PropTypes.bool,
  isListBonuses: PropTypes.bool,
  categories: PropTypes.array,
  customer: PropTypes.object,
};

ReportToExport.defaultProps = {
  tasks: [],
};

export default ReportToExport;
