import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import { languages } from '../../../app/store/lang/language';
import setLanguage from '../../../app/store/lang/setLanguage';



class LanguageSelector extends React.Component {
  render () {
    const { lang, iconType } = this.props;
    const currentLanguage = languages.find((item) => item.lang === lang);

    return (
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--langs"
        drop="down"
        alignRight
      >
        <Dropdown.Toggle as={HeaderDropdownToggle} id="dropdown-toggle-my-cart">
          <span
            className={clsx('kt-header__topbar-icon', {
              'kt-header__topbar-icon--brand': iconType === 'brand',
            })}
          >
            <img src={currentLanguage.flag} alt={currentLanguage.name} />
          </span>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround">
          <ul className="kt-nav kt-mt-10 kt-mb-10">
            {languages.map((language) => (
              <li
                key={language.lang}
                className={clsx('kt-nav__item', {
                  'kt-nav__item--active': language.lang === currentLanguage.lang,
                })}
              >
                <span
                  onClick={async () => {
                    setLanguage(language.lang);
                  }}
                  className={clsx('kt-nav__link', {
                    'kt-nav__link--active': language.lang === currentLanguage.lang,
                  })}
                >
                  <span className="kt-nav__link-icon">
                    <img src={language.flag} alt={language.name} />
                  </span>
                  <span className="kt-nav__link-text">{language.name}</span>
                </span>
              </li>
            ))}
          </ul>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

const mapStateToProps = (store) => ({ lang: store.language.cur });


export default connect(mapStateToProps)(LanguageSelector);
