import React, { useState, createContext, useContext } from 'react';

import PropTypes from 'prop-types';
import ContractModal from '../Modals/ContractModal';
import BillingInfoModal from '../Modals/BillingInfoModal';
import ReturnTaskModal from '../Modals/ReturnTaskModal';



export const MODAL_TYPES = {
  CONTRACT_MODAL: 'CONTRACT_MODAL',
  BILLING_INFO_MODAL: 'BILLING_INFO_MODAL',
  RETURN_WORK: 'RETURN_WORK',
};

const MODAL_COMPONENTS = {
  [MODAL_TYPES.CONTRACT_MODAL]: ContractModal,
  [MODAL_TYPES.BILLING_INFO_MODAL]: BillingInfoModal,
  [MODAL_TYPES.RETURN_WORK]: ReturnTaskModal,
};

const initialState = {
  showModal: () => {},
  hideModal: () => {},
  store: {},
};

const GlobalModalContext = createContext(initialState);

export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal = ({ children }) => {
  const [ store, setStore ] = useState({});
  const { modalType, modalProps } = store;

  const showModal = (modalType, modalProps) => {
    setStore({
      ...store,
      modalType,
      modalProps,
    });
  };

  const hideModal = () => {
    setStore({
      ...store,
      modalType: null,
      modalProps: {},
    });
  };

  const renderComponent = () => {
    const ModalComponent = MODAL_COMPONENTS[modalType];

    if (!modalType || !ModalComponent) {
      return null;
    }

    return <ModalComponent id="global-modal" {...modalProps} />;
  };

  return (
    <GlobalModalContext.Provider value={{ store, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};

GlobalModal.propTypes = {
  children: PropTypes.any,
};
