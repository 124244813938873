import actions from './actions';



const initialState = {
  loading: false,
  tag: {},
  tags: [],
  ai_tags: [],
};

export const TagSystemTagsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.TAG_SYSTEM_SET_TAGS: {
      return {
        ...state,
        tags: action.payload,
      };
    }
    case actions.TAG_SYSTEM_SET_AI_TAGS: {
      return {
        ...state,
        ai_tags: action.payload,
      };
    }
    case actions.TAG_SYSTEM_ADD_TAG: {
      const tags = [ ...state.tags ];

      if (action.payload.parentID) {
        tags.forEach((tag) => {
          if (tag.id === action.payload.parentID) {
            if (!tag.children) {
              tag.children = [];
            }
            tag.children.push(tag);
          }
        });
      }

      return {
        ...state,
        tags: [ action.payload.tag, ...tags ],
      };
    }
    case actions.TAG_SYSTEM_UPDATE_TAG: {

      let tags = [ ...state.tags ];

      if (action.payload.parent_id) {
        tags = tags.map((tag) => {
          if (tag.id === action.payload.parent_id) {
            const childrenTags = tag.tags?.map((item) => {
              if (item.id === action.payload.id) {
                return { ...action.payload };
              }
              return item;
            });

            return {
              ...tag,
              tags: childrenTags,
            };
          }
          if (tag.id === action.payload.id) {
            return action.payload;
          }
          return tag;
        });
      } else {
        tags = tags.map((tag) => {
          if (tag.id === action.payload.id) {
            return { ...action.payload, tags: tag?.tags || [] };
          }
          return tag;
        });
      }
      return {
        ...state,
        tags: [ ...tags ],
      };
    }
    case actions.TAG_SYSTEM_DELETE_TAG: {
      const tags = state.tags.filter((item) => item.id !== action.payload.tagId)
        .map((item) => {
          if (item.children && item.children.length > 0) {
            const children = item.children?.filter((child) => child.id !== action.payload.tagId);

            return {
              ...item,
              children,
            };
          }

          return item;
        });

      return {
        ...state,
        tags,
      };
    }
    default: {
      return state;
    }
  }
};
