import { connect } from 'react-redux';
import { Button } from 'react-bootstrap';
import React from 'react';
import Form from 'react-bootstrap/Form';
import PropTypes from 'prop-types';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import MyDatePicker from '../../../widgets/DatePicker';
import { formatDateToString, formatStringToDate } from '../../../utils/formats';
import Preload from '../../../widgets/Preload';
import axiosApiInstance from '../../../requests/utils/api';
import { Artist } from './Artist';
import { Other } from './Other';



const propTypes = {
  lang: PropTypes.object,
};

const periods = {
  week: 'week',
  prevWeek: 'prevWeek',
  month: 'month',
  prevMonth: 'prevMonth',
  past3month: 'past3month',
  past6month: 'past6month',
};

const today = new Date();

const dayWeek = today.getDay() === 0 ? 7 : today.getDay();

const datesForPeriods = {
  [periods.week]: {
    from: formatDateToString(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayWeek + 1)),
    to: formatDateToString(today),
  },
  [periods.prevWeek]: {
    from: formatDateToString(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayWeek - 6)),
    to: formatDateToString(new Date(today.getFullYear(), today.getMonth(), today.getDate() - dayWeek)),
  },
  [periods.month]: {
    from: formatDateToString(new Date(today.getFullYear(), today.getMonth(), 1)),
    to: formatDateToString(today),
  },
  [periods.prevMonth]: {
    from: formatDateToString(new Date(today.getFullYear(), today.getMonth() - 1, 1)),
    to: formatDateToString(new Date(today.getFullYear(), today.getMonth(), 0)),
  },
  [periods.past3month]: {
    from: formatDateToString(new Date(today.getFullYear(), today.getMonth() - 3, 1)),
    to: formatDateToString(new Date(today.getFullYear(), today.getMonth(), 0)),
  },
  [periods.past6month]: {
    from: formatDateToString(new Date(today.getFullYear(), today.getMonth() - 6, 1)),
    to: formatDateToString(new Date(today.getFullYear(), today.getMonth(), 0)),
  },
};

/**
 * Types of User Analysis
 *
 * @type {{report_by_shutter: string, report_by_other: string, report_by_artist: string}}
 */
const types = {
  artist: 'artist',
  shutter: 'shutter',
  other: 'other',
};

/**
 * Component for user analysis page
 *
 * @returns {*}
 */
class ReportsUserAnalyze extends React.PureComponent {
  state = {
    type: types.artist,
    period: periods.week,
    from: datesForPeriods[periods.week].from,
    to: datesForPeriods[periods.week].to,
    data: {},
    currentSetup: {
      type: '',
      from: datesForPeriods[periods.week].from,
      to: datesForPeriods[periods.week].to,
    },
    LOAD: true,
  };

  render () {
    const { lang } = this.props;

    return this.state.LOAD
      ? (<Preload />)
      : (<>
        <SubHeader
          title={this.props.lang['REPORT.TYPE.report_users_analyze']}
          toolbar={
            <div className='d-flex align-items-center'>
              <div
                className='kt-mr-5'
                title={lang['REPORTS.TYPE_REPORT']}
              >
                <Form.Control
                  as='select'
                  size={'sm'}
                  value={this.state.type}
                  onChange={(event) => {
                    if (this.state.period !== event.target.value) {
                      this.setState({
                        type: event.target.value,
                      });
                    }
                  }}
                >
                  {Object.keys(types).map((type) => {
                    return (
                      <option
                        key={type}
                        value={type}
                      >
                        {lang[`REPORTS.TYPES_USER_ANALYZE.${type}`]}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
              <div
                className='kt-mr-5'
                title={lang[`REPORTS.PERIODS.${this.state.period}`]}
              >
                <Form.Control
                  as='select'
                  size={'sm'}
                  value={this.state.period}
                  onChange={(event) => {
                    if (this.state.period !== event.target.value) {
                      this.setState({
                        period: event.target.value,
                        ...(datesForPeriods[event.target.value]
                          ? datesForPeriods[event.target.value]
                          : {}
                        ),
                      });
                    }
                  }}
                >
                  {Object.keys(periods).map((period) => {
                    return (
                      <option
                        key={period}
                        value={period}
                      >
                        {lang[`REPORTS.PERIODS.${period}`]}
                      </option>
                    );
                  })}
                </Form.Control>
              </div>
              <div
                className='kt-mr-5'
              >
                <MyDatePicker
                  size="sm"
                  range
                  selected={this.state.from && this.state.to
                    ? [
                      formatStringToDate(this.state.from),
                      formatStringToDate(this.state.to),
                    ]
                    : (
                      this.state.from
                        ? [ formatStringToDate(this.state.from) ]
                        : (
                          this.state.to
                            ? [ formatStringToDate(this.state.to) ]
                            : []
                        )
                    )
                  }
                  maxDate={today}
                  position="bottom center"
                  style={{
                    maxWidth: '100px',
                  }}
                  onChange={(date) => {
                    this.setState({
                      from: formatDateToString(date[0]),
                      to: formatDateToString(date[1]),
                      period: periods.other,
                    });
                  }}
                />
              </div>
              <Button
                variant={'info'}
                onClick={this._getReports}
              >
                {lang['GLOBAL.APPLY']}
              </Button>
            </div>
          }
        />
        {this.state.currentSetup.type === types.artist && (
          <Artist
            data={this.state.data}
            lang={lang}
          />
        )}
        {this.state.currentSetup.type === types.shutter && (
          <Other
            data={this.state.data}
            lang={lang}
          />
        )}
        {this.state.currentSetup.type === types.other && (
          <Other
            data={this.state.data}
            lang={lang}
          />
        )}
      </>);
  }

  componentDidMount () {
    this._getReports();
  }

  _getReports = () => {
    this.setState({
      LOAD: true,
    });

    const url = `reports/${this.state.type}?from=${this.state.from}&to=${this.state.to}`;

    axiosApiInstance.get(url, {})
      .then((response) => {
        const msg = response.data;

        this.setState({
          LOAD: false,
          data: msg,
          currentSetup: {
            type: this.state.type,
            from: this.state.from,
            to: this.state.to,
          },
        });
      })
      .catch((error) => {
        // eslint-disable-next-line no-console
        console.error(error);
        if (error.response) {
          this.setState({
            LOAD: false,
            data: {},
            currentSetup: {
              type: '',
              from: '',
              to: '',
            },
          });
        }
      });
  };
}

const mapStateToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

ReportsUserAnalyze.propTypes = propTypes;

export default connect(mapStateToProps)(ReportsUserAnalyze);
