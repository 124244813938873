import groupsRoles from '../../pages/customers/roles/groupsRoles';
import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';



const actions = {
  LOADING: 'LOADING MANUALS',
  SET_MANUAL: 'SET_MANUAL',
  SET_MANUALS: 'SET_MANUALS',
  UPDATE_MANUAL: 'UPDATE_MANUAL',
  DELETE_MANUAL: 'DELETE_MANUAL',
  setLoading: (payload) => ({
    type: actions.LOADING,
    payload,
  }),
  setManual: (payload) => ({
    type: actions.SET_MANUAL,
    payload,
  }),
  setManuals: (payload) => ({
    type: actions.SET_MANUALS,
    payload,
  }),
  getManual: (url, history) => async (dispatch, _getState, api) => {
    try {
      dispatch(actions.setManual({}));
      dispatch(actions.setLoading(true));
      const response = await api.get(url);

      dispatch(actions.setManual(response.data.manual || {}));
    } catch (error) {
      if (error.response.status === 404) {
        history.goBack();
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  getManuals: () => async (dispatch, _getState, api) => {
    try {
      dispatch(actions.setLoading(true));
      const storedRole = localStorage.getItem('selected_role');

      let URL = 'manuals';

      if (groupsRoles.executors.all.includes(storedRole)) {
        URL += `?role=${storedRole}`;
      }

      const response = await api.get(URL);

      dispatch(actions.setManuals(response.data.manuals || []));
    } catch {
      // todo add notification
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  updateManual: (manual) => async (dispatch, getState, api) => {
    const store = getState();

    try {
      const URL = `manuals/${manual.id}/publish`;

      api.put(URL, {
        publish: manual.is_published,
      });

      dispatch({
        type: actions.UPDATE_MANUAL,
        payload: manual,
      });

      const notification = manual.is_published
        ? 'NOTIFICATION.SUCCESS_MANUAL_PUBLISH'
        : 'NOTIFICATION.SUCCESS_MANUAL_HIDDEN';

      dispatch(
        actionsNotifications.add(
          store.language.lang[notification],
          NOTIFICATIONS_TYPES.success
        )
      );
      dispatch(actions.setManual(manual));
    } catch (error) {
      if (error.response) {
        const notification = manual.is_published
          ? 'NOTIFICATION.ERROR_MANUAL_PUBLISH'
          : 'NOTIFICATION.ERROR_MANUAL_HIDDEN';

        dispatch(
          actionsNotifications.add(
            store.language.lang[notification],
            NOTIFICATIONS_TYPES.error
          )
        );
      }
    }
  },
  deleteManual: (id) => async (dispatch, getState, api) => {
    const URL = `manuals/${id}`;
    const store = getState();

    try {
      dispatch(actions.setLoading(true));
      await api.delete(URL);
      dispatch({
        type: actions.DELETE_MANUAL,
        payload: id,
      });
      dispatch(
        actionsNotifications.add(
          store.language.lang['NOTIFICATION.SUCCESS_MANUAL_DELETE'],
          NOTIFICATIONS_TYPES.success
        )
      );
    } catch {
      // todo add notification
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
