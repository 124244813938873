import React, { useState } from 'react';
import { Table } from 'react-bootstrap';
import { v4 as uuidv4 } from 'uuid';
import Badge from 'react-bootstrap/Badge';
import { useSelector } from 'react-redux';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { formatToPrettyDate } from '../../../utils/formats';
import Paginator from '../../../widgets/Paginator';
import KTUtil from '../../../../_metronic/_assets/js/util';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';
import { bonusStatusToColor, getTaskBonusStatus, TASK_BONUS_STATUS } from '../../../../const/tasks';
import getTesterSalaryByTask from '../../../utils/getTesterSalaryByTask';



const BillingTable = ({ items, isPrevMonth, onClickRow }) => {
  const lang = useSelector((store) => store.language.lang);
  const userRoles = useSelector((store) => store.user.roles);
  const [ skip, setSkip ] = useState(0);
  const [ onPage ] = useState(50);

  const getPrice = (task) => {
    if (checkRole(userRoles, [ roles['coloring-tester'].key ])) {
      if (task.finished_at && task.price > 0) {
        return task.tester_price;
      }
      return getTesterSalaryByTask(task.testing_time);
    }

    return task.price;
  };

  const getPrevMonthAmountSum = () => {
    return items.reduce((acc, item) => {
      let price = item.price;

      if (checkRole(userRoles, [ roles['coloring-tester'].key ])) {
        price = getPrice(item);
      }

      if (item.bonus_status === TASK_BONUS_STATUS.APPLIED) {
        return acc + parseFloat(item.bonus_amount) + parseFloat(price);
      }
      return acc + parseFloat(price);

    }, 0);
  };

  const getPaginator = (items) => {
    const count = Math.floor(items.length / onPage) - (items.length % onPage === 0 ? 1 : 0);

    return (
      <div className="d-flex justify-content-end">
        <Paginator
          active={(skip / onPage)}
          count={count}
          select={(newPage) => {
            setSkip(newPage * onPage);
            KTUtil.scrollTop(0, 300);
          }}
        />
      </div>
    );
  };

  const isTester = () => {
    return checkRole(userRoles, [ roles['coloring-tester'].key ]);
  };

  const getTestingTimeCell = (task) => {
    if (isPrevMonth) {
      return <span>{task.testing_time}</span>;
    }
    return (
      <input
        readOnly
        style={{ width: '50%' }}
        className="form-control"
        value={task.testing_time}
        onClick={() => {
          if (isTester()) {
            onClickRow({
              ...task,
              isPrevMonth,
            });
          }
        }}
      />);
  };

  return (
    <>
      {getPaginator(items)}
      <div className='mw-100 overflow-auto'>
        <Table className='vertical-align-middle text-center'>
          <thead className='thead-light'>
            <tr>
              <th>{lang['BILLING.TABLE_ID']}</th>
              <th>{lang['TASK.TITLE']}</th>
              {isTester() && <th>
                {lang['TASK.EXECUTION_TIME']}
              </th>}
              <th>{lang['TASK.PRICE']}</th>
              <th>{lang['BILLING.BONUS_VALUE']}</th>
              <th>{lang['BILLING.BEGIN']}</th>
              <th>{lang['BILLING.END']}</th>
              {!isPrevMonth && <th>{lang['BILLING.VERIFIED']}</th>}
              <th>{lang['BILLING.BONUS_STATUS']}</th>
            </tr>
          </thead>
          <tbody>
            {items.slice(skip, skip + onPage).map((task) => {
              return (
                <tr
                  key={uuidv4()}
                >
                  <td>{`${task.project_id}/${task.task_id}`}</td>
                  <td>{task.title || '---'}</td>
                  {isTester() &&
                    <td className={isTester() && 'hover'} style={{ display: 'flex', justifyContent: 'center' }}>
                      {getTestingTimeCell(task)}
                    </td>}
                  <td>{getPrice(task)}</td>
                  <td>{task.bonus_amount || '---'}</td>
                  <td>{formatToPrettyDate(task.started_at) || '---'}</td>
                  <td>{formatToPrettyDate(task.finished_at) || '---'}</td>
                  {!isPrevMonth && <td>
                    {task.checked_at ? (
                      <Badge as={'span'} variant={'success'}>
                        {lang['GLOBAL.YES']}
                      </Badge>
                    ) : (
                      <Badge as={'span'} variant={'dark'}>
                        {lang['GLOBAL.NO']}
                      </Badge>
                    )}
                  </td>}
                  <td>
                    <span className={`badge badge-${bonusStatusToColor(task.bonus_status)} badge__position`}>
                      {isEmpty(task.bonus_status) ? getTaskBonusStatus(lang, task.bonus_status) : '---'}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
          {isPrevMonth &&
          <tfoot>
            <tr>
              <td>
                <h5 className="kt-mt-20" style={{ color: '#48465b' }}>
                  <b>{lang['BILLING.TO_PAY']}:  {`${getPrevMonthAmountSum().toFixed(2)}€`}</b>
                </h5>
              </td>
            </tr>
            {getPrevMonthAmountSum() < 50 && (
              <tr>
                <td>
                  <span className="kt-mt-30" style={{ color: '#48465b' }}>
                    {lang['BILLING.CONDITION_TO_PAY']}
                  </span>
                </td>
              </tr>
            )}
          </tfoot>}
        </Table>
      </div>
      {getPaginator(items)}
    </>
  );
};

BillingTable.propTypes = {
  items: PropTypes.array.isRequired,
  isPrevMonth: PropTypes.bool,
  onClickRow: PropTypes.func,
};

BillingTable.defautlProps = {
  items: [],
  isPrevMonth: false,
  onClickRow: () => {},
};

export default BillingTable;
