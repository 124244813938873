import { Form } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import React from 'react';
import PropTypes from 'prop-types';
import DropFile from '../../widgets/DropFile';
import { versionValidator } from '../../utils/validators';

/**
 * Component for a block with form Sort loader
 *
 * @returns {*}
 */
export default function SoftLoaderForm () {
  const { isActiveForm, softFormValues } = this.state;
  const { lang } = this.props;

  return (isActiveForm && (
    <form
      className="col-12 filters kt-portlet kt-portlet--height-fluid"
      onSubmit={(event) => {
        event.preventDefault();
      }}
    >
      <div className="kt-portlet__body">
        <div className="row">
          <Form.Group className="col-md-6 kt-mb-5">
            <Form.Label>{lang['SOFT.PLATFORM']}*</Form.Label>
            <Form.Control
              as="select"
              value={softFormValues.platform}
              onChange={(event) => {
                this.setState({
                  softFormValues: {
                    ...softFormValues,
                    platform: event.target.value,
                  },
                });
              }}
            >
              <option value="mac">MacOs</option>
              <option value="win">Windows</option>
            </Form.Control>
          </Form.Group>
          <Form.Group className="col-md-6 kt-mb-5">
            <Form.Label>{lang['SOFT.VERSION']}*</Form.Label>
            <Form.Control
              type="text"
              value={softFormValues.version}
              onChange={(event) => {
                this.setState({
                  softFormValues: {
                    ...softFormValues,
                    version: versionValidator(event.target.value),
                  },
                });
              }}
            />
          </Form.Group>
        </div>

        <div className="row">
          <Form.Group className='col-6 col-md-4 kt-mt-20 kt-mb-5'>
            <div className="kt-checkbox-inline">
              <label className="kt-checkbox">
                <input
                  type="checkbox"
                  checked={softFormValues.is_actual}
                  onChange={() => {
                    this.setState({
                      softFormValues: {
                        ...softFormValues,
                        is_actual: Number(!softFormValues.is_actual),
                      },
                    });
                  }}
                />{lang['SOFT.IS_ACTUAL']}<span />
              </label>
            </div>
          </Form.Group>
        </div>

        <div className='col-12 kt-mt-30 kt-mb-20 text-danger text-center font-weight-bold'>
          {lang['SOFT.ONLY_ZIP']}
        </div>
        <div className='col-12 kt-mb-20'>
          <DropFile
            accept='application/zip'
            theme='success'
            multiple={false}
            fileTextPreview={softFormValues?.file ? softFormValues.file.name : ''}
            styles={{
              minHeight: 200,
            }}
            addFile={(file) => {
              this.setState({
                softFormValues: {
                  ...softFormValues,
                  file: file.file,
                },
              });
            }}
            info={
              <span
                className='font-weight-bold text-success'
                style={{ fontSize: '2rem' }}
              >
                {lang['SOFT.FILE_LOADER']}
              </span>
            }
          />
        </div>
        <div className="col-12 kt-mb-5">
          <div className="float-right kt-mt-20">
            <Button
              type="submit"
              variant="success"
              onClick={() => {
                this.postSoft(softFormValues);
              }}
            >
              {lang['GLOBAL.APPLY']}
            </Button>
          </div>
        </div>
      </div>
    </form>
  )
  );
}

export const mapStoreToProps = (store) => ({
  lang: store.language.lang,
});

SoftLoaderForm.propTypes = {
  lang: PropTypes.object,
};
