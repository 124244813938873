import axiosApiInstance from './utils/api';



export const nameCategories = 'categories';
export const defaultCategoryValue = 'uncategorized';

export const addCategories = async (categoryId, projectId) => {
  const URL = `projects/${projectId}/category`;

  return axiosApiInstance.put(URL, {
    category_id: categoryId,
  });
};

// eslint-disable-next-line consistent-return
export const mapCategoryNameToId = (categories, projectCategoryName) => {
  if (categories) {
    let projectCategory = 'null';

    Object.values(categories).forEach((category) => {
      if (category.name === projectCategoryName) {
        projectCategory = category.id;
        return projectCategory;
      }
      return projectCategory;
    });

    return projectCategory;
  }
};

// eslint-disable-next-line consistent-return
export const mapCategoryIdToName = (categories, projectCategoryId) => {
  if (categories) {
    let projectCategory = defaultCategoryValue;

    if (typeof projectCategoryId === 'undefined') {
      return `_${defaultCategoryValue}`;
    }

    Object.values(categories).forEach((category) => {
      if (category.id === String(projectCategoryId)) {
        projectCategory = category.name;
        return projectCategory;
      }
      return projectCategory;
    });

    return projectCategory;
  }
};

/**
 * Removes categories from a project
 *
 * @param {any} selectCategories
 * @param {number} projectId
 * @param {any} notification
 * @returns {Promise<Response>}
 */
// eslint-disable-next-line consistent-return
export const removeCategoriesFromProject = async (selectCategories, projectId, notification) => {
  const URL = `projects/${projectId}/remove-categories`;
  const fd = new FormData();

  fd.append('_method', 'DELETE');

  selectCategories.forEach((category, index) => {
    fd.append(`categories[${index}]`, category);
  });

  try {
    return await axiosApiInstance.post(URL, fd, {});
  } catch (error) {
    if (error.response) {
      notification('NOTIFICATION.ERROR_CATEGORY_DELETE', 'error');
    }
  }
};

/**
 * Add categories to a project
 *
 * @param {any} selectCategories
 * @param {number} projectId
 * @param {any} notification
 * @returns {Promise<Response>}
 */
// eslint-disable-next-line consistent-return
export const addCategoriesToProject = async (selectCategories, projectId, notification) => {
  const URL = `projects/${projectId}/add-categories`;
  const fd = new FormData();

  selectCategories.forEach((category, index) => {
    fd.append(`categories[${index}]`, category);
  });

  try {
    return await axiosApiInstance.post(URL, fd, {});
  } catch (error) {
    if (error.response) {
      notification('NOTIFICATION.ERROR_CATEGORY_ADD', 'error');
    }
  }
};
