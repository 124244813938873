import React from 'react';
import Button from 'react-bootstrap/Button';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Modal from '../../../widgets/Modal';
import TagSystemPictureTagsTreeWidget from '../../../components/TagSystem/TagSystemPictureTagsTreeWidget';



const TagSystemPictureTagsModal = ({
  showModalTagSystemPictureTags,
  setModalTagSystemPictureTags,
  picture,
}) => {
  const lang = useSelector((state) => state.language.lang);

  return (
    <Modal
      title={lang['MENU.TAG_SYSTEM']}
      show={showModalTagSystemPictureTags}
      close={() => {
        setModalTagSystemPictureTags(false);
      }}
      bodyStyle={{ overflow: 'scroll' }}
      footer={(
        <Button
          variant="secondary"
          onClick={() => {
            setModalTagSystemPictureTags(false);
          }}
        >
          OK
        </Button>
      )}
    >
      <div className="d-flex justify-content-left flex-wrap">
        <TagSystemPictureTagsTreeWidget
          picture={picture}
        />
      </div>
    </Modal>
  );
};

TagSystemPictureTagsModal.propTypes = {
  showModalTagSystemPictureTags: PropTypes.bool.isRequired,
  setModalTagSystemPictureTags: PropTypes.func.isRequired,
  picture: PropTypes.object.isRequired,
};

export default TagSystemPictureTagsModal;
