import { toAbsoluteUrl, toImgUrl } from './gettersUrl';



export const IMG_DUMMY = toAbsoluteUrl('/media/dummy.jpg');

/**
 * return full url to image or url to "/media/dummy.jpg"
 *
 * @param {string} url
 * @returns {string}
 */
const imgURL = (url) => {
  if (url && (url.startsWith('https://') || url.startsWith('http://'))) {
    return url;
  }

  return (url ? toImgUrl(url[0] === '/' ? url.slice(1) : url) : IMG_DUMMY);
};

export default imgURL;
