import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import checkRole from '../../utils/checkRole';
import roles from '../customers/roles/roles';
import urlPageTasks from '../../urls/urlPageTasks';
import urlPageTaskDetails from '../../urls/urlPageTaskDetails';
import urlPageTasksGroupChoosing from '../../urls/urlPageTasksGroupChoosing';
// eslint-disable-next-line import/no-named-as-default
import TasksList from './list/TasksList';
// eslint-disable-next-line import/no-named-as-default
import TaskInfo from './info/TaskInfo';
import TasksAccept from './TasksAccept';
import TasksReturn from './TasksReturn';
import TasksChooseGroup from './TasksChooseGroup';



const Tasks = ({ userRoles, currentRole }) => {

  return (
    <Switch>
      <Redirect exact from={urlPageTasks()} to="/tasks/list/all" />
      {checkRole(userRoles, [ roles['coloring-editor'].key ]) ? (
        <Route path="/tasks/type/:type_task/:type" component={TasksList} />
      ) : (
        <Route path="/tasks/type/:type/:type_task" component={TasksList} />
      )}
      {checkRole(userRoles, [ roles['coloring-editor'].key ]) && (
        <Route path={urlPageTasksGroupChoosing()} component={TasksChooseGroup} />
      )}
      <Route
        path={[
          '/tasks/list/:type',
          '/tasks/status/:status/:type_task',
          '/tasks/status/:status',
        ]} component={TasksList}
      />
      {checkRole(userRoles, [ roles['lead-coloring-editor'].key, roles['coloring-editor'].key, roles['content-manager'].key ]) && (
        [
          <Route key="accept" exact path="/tasks/accept" component={TasksAccept} />,
          <Route key="return" exact path="/tasks/return" component={TasksReturn} />,
        ]
      )}
      <Route
        path={urlPageTaskDetails()}
        render={(props) => (
          <TaskInfo
            {...props}
            currentRole={currentRole}
          />
        )}
      />
      <Route path={urlPageTasks()} component={TasksList} />
    </Switch>
  );
};

Tasks.propTypes = {
  userRoles: PropTypes.arrayOf(PropTypes.string).isRequired,
  currentRole: PropTypes.string,
};

Tasks.defaultProps = {
  currentRole: null,
};

export const mapStoreToProps = (store) => ({
  userRoles: store.user.roles,
  currentRole: store.user.currentRole,
});

export default connect(mapStoreToProps)(Tasks);
