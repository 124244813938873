import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { object as Yup_object, string } from 'yup';
import { useHistory } from 'react-router-dom';
import { toAbsoluteUrl } from '../../utils/gettersUrl';
import { emailCheck } from '../../utils/checker';
import IconLoad from '../../widgets/IconLoad';
import userActions from '../../store/user/actions';
import LanguageSelector from '../../../_metronic/partials/layout/LanguageSelector';
import Footer from './components/Footer';



const validationSchema = Yup_object().shape({
  username: string().required(),
  password: string().required(),
});

const Login = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { lang, loading } = useSelector(({ language, user }) => ({
    lang: language.lang,
    loading: user.loading,
  }), shallowEqual);
  const [ isForgot, setIsForgot ] = useState(false);
  const [ forgotEmail, setForgotEmail ] = useState('');
  const [ forgotEmailInvalid, setForgotEmailInvalid ] = useState(false);
  const [ forgotSend, setForgotSend ] = useState(false);
  const [ forgotError ] = useState('');

  const { control, watch, handleSubmit, formState: { errors } } = useForm({
    resolver: yupResolver(validationSchema),
    mode: 'onChange',
    defaultValues: {
      password: '',
      username: '',
      isRemember: true,
    },
  });
  const watchedFields = watch();

  const onSubmit = async (data) => {
    localStorage.removeItem('test_mode_params');
    await dispatch(userActions.loginUser(data));
    history.replace({ pathname: '/' });
  };

  const restorePassword = async () => {
    if (emailCheck(forgotEmail)) {
      setForgotEmailInvalid(false);

      await dispatch(userActions.resetPassword({
        email: forgotEmail,
      }, () => setForgotSend(true)));
    } else {
      setForgotEmailInvalid(true);
    }
  };

  return (
    <div
      className={`kt-grid kt-grid--hor kt-grid--root kt-login kt-login--v3 kt-login--${!isForgot ? 'signin' : 'forgot'}`}
    >
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
        style={{ backgroundImage: `url('${toAbsoluteUrl('media/bg/bg-3.jpg')}')` }}
      >
        <div className="kt-header__topbar d-flex justify-content-end">
          <LanguageSelector iconType="" />
        </div>
        <div className="kt-grid__item kt-grid__item--fluid kt-login__wrapper">
          <div className="kt-login__container">
            <div className="kt-login__logo">
              <img src={toAbsoluteUrl('media/logo-4.png')} alt="logo" />
            </div>
            <div className="kt-login__signin animated flipInX">
              <div className="kt-login__head">
                <h3 className="kt-login__title">{lang['LOGIN.SIGNIN']}</h3>
              </div>
              <form
                className="kt-form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Form.Group>
                  <Controller
                    name="username"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        placeholder={lang['CUSTOMER.USERNAME']}
                        autoComplete="off"
                        type="text"
                        isInvalid={!!errors.username}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['CUSTOMER.ERROR.USERNAME']}
                  </Form.Control.Feedback>
                </Form.Group>

                <Form.Group>
                  <Controller
                    name="password"
                    control={control}
                    render={({ field }) => (
                      <Form.Control
                        {...field}
                        placeholder={lang['GLOBAL.PASSWORD']}
                        autoComplete="off"
                        type="password"
                        isInvalid={!!errors.password}
                      />
                    )}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.ERROR_PASSWORD']}
                  </Form.Control.Feedback>
                </Form.Group>

                <div className="row kt-login__extra">
                  <div className="col">
                    <Controller
                      name="isRemember"
                      control={control}
                      render={({ field }) => (
                        <label className="kt-checkbox">
                          <input
                            {...field}
                            checked={watchedFields.isRemember}
                            type="checkbox"
                          />
                          {lang['LOGIN.REMEMBER_ME']}
                          <span />
                        </label>
                      )}
                    />
                  </div>

                  <div className="col kt-align-right">
                    <button
                      className="kt-login__button"
                      type="button"
                      onClick={() => {
                        setIsForgot((prevState) => !prevState);
                      }}
                    >
                      {lang['LOGIN.FORGOTTEN_PASSWORD']}
                    </button>
                  </div>
                </div>
                <div className="kt-login__actions">
                  <Button
                    variant="brand"
                    type="submit"
                    className="kt-login__btn-primary"
                    disabled={loading}
                  >
                    {lang['LOGIN.ENTER']}
                    {loading && (
                      <IconLoad />
                    )}
                  </Button>
                </div>
              </form>
            </div>
            <div className="kt-login__forgot animated flipInX">
              <div className="kt-login__head">
                <h3 className="kt-login__title">{lang['LOGIN.FORGOTTEN_PASSWORD']}</h3>
                <div className="kt-login__desc">
                  {forgotSend ? lang['LOGIN.FORGOT_INFO'] : lang['LOGIN.ENTER_EMAIL']}
                </div>
              </div>
              {!forgotSend && (
                <div className="kt-form">
                  <Form.Group>
                    <Form.Control
                      isInvalid={forgotEmailInvalid && !emailCheck(forgotEmail)}
                      value={forgotEmail}
                      onChange={(event) => {
                        setForgotEmail(event.target.value);
                      }}
                    />
                    <Form.Control.Feedback type="invalid">
                      {forgotError || lang['CUSTOMER.ERROR.EMAIL']}
                    </Form.Control.Feedback>
                    <div
                      className="invalid-feedback"
                      style={{ display: 'block' }}
                    >
                      {forgotError}
                    </div>
                  </Form.Group>
                  <div className="kt-login__actions">
                    <button
                      onClick={restorePassword}
                      disabled={loading}
                      className="btn btn-brand btn-elevate kt-login__btn-primary"
                    >
                      {lang['LOGIN.REESTABLISH']}
                      {loading && (
                        <IconLoad />
                      )}
                    </button>
                    &nbsp;&nbsp;
                    <button
                      className="btn btn-light btn-elevate kt-login__btn-secondary"
                      onClick={() => {
                        setIsForgot((prevState) => !prevState);
                      }}
                    >
                      {lang['GLOBAL.CANCEL']}
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>

          <Footer classes="kt-login__footer" />

        </div>
      </div>
    </div>
  );
};

export default Login;
