import React from 'react';



const EnXflowNotification = () => {
  return (
    <div><p>
      Dear Sir/Madam,
    </p><p>
      Due to the increasing number of economic and trade sanctions laws (“Sanctions”) enacted in all jurisdictions in which we operate, we are committed to complying with the relevant Sanctions and willing to protect our business from illegally engaging with any sanctioned companies, entities or individuals.
    </p><p>
      In order to perform the required due diligence procedures <b>we kindly ask you to provide us the copies of the following documents:</b>
    </p><p>
      (1) Copy of a valid passport or Government issued ID document (with photo)
    </p><p>
      (2) Selfie identity verification - photo of yourself while holding the passport or ID document.
    </p><p>
      (3) Proof of Address not older than 12 months issued on your name and showing your address such as: utility bill (water, electricity, gas, telephone, or Internet bill), or Bank statement, Bank reference letter, Official Letter / Documents from the Government.
    </p><p>
      We hope for your understanding and co-operation in performing the KYC procedures. Please be free to ask for any clarifications if needed.
    </p><p>
      <b>E-mail for the KYC procedures: kyc@bee-freelancer.app</b>
    </p>
    </div>
  );
};

export default EnXflowNotification;
