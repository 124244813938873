/**
 * Types users
 *
 * @type {{editor: {key: string}, artist: {key: string}, tester: {key: string}, designer: {key: string}}}
 */
const typesUsers = {
  editor: {
    key: 'editor',
  },
  artist: {
    key: 'artist',
  },
  designer: {
    key: 'designer',
  },
  tester: {
    key: 'tester',
  },
};

export default typesUsers;
