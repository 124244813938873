import React from 'react';
import PropTypes from 'prop-types';



const Loader = ({ centered = false, showImg = true }) => {
  return (
    <div
      className="d-flex flex-column justify-content-center align-items-center h-100"
      style={centered ? {
        position: 'absolute',
        left: showImg ? 'calc(50% - 75px)' : 'calc(50% - 25px)',
        top: showImg ? 'calc(%50 - 75px)' : '0px',
        zIndex: 10,
      } : {}}
    >
      {showImg && <img src="/media/logo.png" alt="Bee logo" style={{ width: 100, height: 100 }} />}
      <svg className="spinner kt-ml-0 " viewBox="0 0 50 50">
        <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="5" />
      </svg>
    </div>
  );
};

Loader.propTypes = {
  showImg: PropTypes.bool,
  centered: PropTypes.bool,
};

export default Loader;
