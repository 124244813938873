import React from 'react';
import { Form, Row } from 'react-bootstrap';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { entitySubtypes } from '../../config/config';



const OptionList = ({ group, options, control }) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  return (
    <Row>
      {options.filter((option) => {
        if (option.name === 'use_ai_suggestions') {
          return [ entitySubtypes.usual, entitySubtypes.cloud, entitySubtypes.main ].includes(group.subtype);
        }

        return true;
      }).map((option) => {
        const grOpt = group.options?.find((opt) => opt.name === option.name);

        return (
          <Form.Group key={option.name} className='d-flex align-items-center pl-4'>
            <Controller
              className='mr-3'
              name={`option_${option.name}`}
              control={control}
              render={({ field }) => {
                return (
                  <Form.Check
                    {...field}
                    disabled={grOpt?.name === 'is_dependent' && group?.children?.length}
                    type="checkbox"
                    role='button'
                    id={`option_${option.name}`}
                    checked={field.value}
                  />
                );
              }}
            />
            <Form.Label htmlFor={`option_${option.name}`} role='button' className='m-0'>{lang[`TAG_SYSTEM.OPTIONS.${option.name?.toUpperCase()}`]}</Form.Label>
          </Form.Group>
        );
      })}
    </Row>
  );
};

OptionList.propTypes = {
  group: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  control: PropTypes.any.isRequired,
};

export default OptionList;
