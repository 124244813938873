import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import axiosApiInstance from '../../../requests/utils/api';
import { parseFromPars } from '../../../utils/parseUrlParams';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const useWidgets = () => {
  const location = useLocation();
  const [ widgets, setWidgets ] = useState({
    overdue_tasks: 0,
    upcoming_deadline_tasks: 0,
    checking_executors_tasks: 0,
  });
  const [ isWidgetsLoading, setIsWidgetsLoading ] = useState(false);
  const { userRoles, user } = useSelector(({ user }) => ({
    userRoles: user.roles,
    user: user.user,
  }), shallowEqual);

  useEffect(async () => {
    setIsWidgetsLoading(true);
    const queryParams = parseFromPars(location.search);

    const isAdminOrLeadEditor = checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ]);
    const isGroupDefined = typeof queryParams.group_id !== 'undefined';
    const isUserMatchGroup = user.groups.includes(queryParams.group_id);

    if (isGroupDefined && (isAdminOrLeadEditor || isUserMatchGroup)) {
      const widgetsData = await axiosApiInstance.get(`/dashboards/widgets?group_id=${queryParams.group_id}`);

      setWidgets(widgetsData.data);
    }

    setIsWidgetsLoading(false);
  }, [ location ]);

  return [ widgets, isWidgetsLoading ];
};

export {
  useWidgets,
};
