import React from 'react';
import { connect } from 'react-redux';
import { orderBy } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropsTypes from 'prop-types';
import { formatToPrettyTime } from '../utils/formats';
import { actionsNotifications } from '../store/notification';



const propTypes = {
  notifications: PropsTypes.any,
  hiddenNotification: PropsTypes.any,
};
/**
 * Types notifications
 *
 * @type {{success: string, error: string, info: string}}
 */

export const NOTIFICATIONS_TYPES = {
  info: 'info',
  error: 'error',
  success: 'success',
};

/**
 * Component for displaying notifications within 10 seconds from the moment of their addition
 */
class Notification extends React.Component {
  render () {
    const { notifications } = this.props;

    const curTime = new Date().getTime();

    return (
      <div id="toast-container" className="toast-bottom-right">
        {orderBy(notifications.filter((notification) => curTime - notification.time.getTime() <= 11000 && !notification.isHidden), [ (notification) => notification.time.getTime() ], [ 'asc' ]).map((item) => {
          return (
            <div
              className={`toast toast-${item.type}`}
              key={uuidv4()}
              onDoubleClick={() => this.props.hiddenNotification(item.id)}
            >
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <div className="d-flex justify-content-end hover" onClick={() => this.props.hiddenNotification(item.id)}>
                <i className="la la-close" />
              </div>
              <div className="toast-message">{item.text}</div>
              {item.optionalMessages && <div className="toast-message">
                {typeof item.optionalMessages === 'string'
                  ? item.optionalMessages
                  : item.optionalMessages?.map(
                    (msg) => <p key={uuidv4()}>{typeof msg === 'string' ? msg : ''}</p>
                  )}
              </div>}
              <span className="toast-time">{formatToPrettyTime(item.time)}</span>
            </div>
          );
        })}
      </div>
    );
  }

  componentDidMount () {
    this.interval = setInterval(() => {
      this.forceUpdate();
    }, 1000);
  }

  componentWillUnmount () {
    clearInterval(this.interval);
  }
}

const mapStoreToProps = ({ notifications: { notifications } }) => ({
  notifications,
});

const mapDispatchToProps = (dispatch) => ({
  addNotification: bindActionCreators(actionsNotifications.add, dispatch),
  hiddenNotification: bindActionCreators(actionsNotifications.hidden, dispatch),
});

Notification.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(Notification);
