import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import PerfectScrollbar from 'react-perfect-scrollbar';
import imgURL from '../../../utils/imgURL';
import Chat from '../../../widgets/Chat';
import typesComments from '../../../utils/typesComments';



const SidebarChat = ({
  show,
  toggleChat,
  comments,
  userId,
  addComment,
  updateComment,
  deleteComment,
}) => {
  const chatRef = useRef(null);
  const lang = useSelector((store) => store.language.lang);
  const users = useSelector((store) => store.users);

  const getCommentsWithUsers = () => {
    const filteredComments = comments.filter((comment) => comment.status !== typesComments.refuseTask);

    if (filteredComments) {
      return comments.map(
        (comment) => {
          comment.user = users.find((user) => user.id === comment.user_id);
          return comment;
        }
      );
    }

    return [];
  };

  return (
    <div
      className={`kt-demo-panel d-flex flex-column h-100 overflow-hidden ${show ? 'kt-demo-panel--on' : ''}`}
    >
      <div className='kt-demo-panel__head'>
        <h3 className='kt-demo-panel__title'>{lang['PROJECT.COMMENTS']}</h3>
        <a
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className='kt-demo-panel__close'
          onClick={() => {
            toggleChat(false);
          }}
        >
          <i className="flaticon2-delete" />
        </a>
      </div>
      <div className='kt-demo-panel__body d-inline-flex'>
        <PerfectScrollbar
          options={{ suppressScrollX: true }}
          className='kt-scroll kt-scroll--pull h-100 w-100'
        >
          <Chat
            ref={chatRef}
            messages={getCommentsWithUsers().map((comment) => {
              return {
                id: comment.id,
                user_id: comment.user_id,
                avatar: (comment.user && comment.user.avatar) || '',
                name: (comment.user ? `${comment.user.username}` : `User #${comment.user_id}`),
                time: comment.created_at,
                updated_at: comment.updated_at,
                deleted_at: comment.deleted_at,
                isYou: comment.user_id === userId,
                status: comment.status,
                message: (
                  <>
                    {comment.comment}
                    {(comment.files || []).map((file) => {
                      return (
                        <div key={uuidv4()}>
                          <p className='w-100 d-block text-right kt-m-0'>
                            <a
                              className='font-weight-bold'
                              target="_blank"
                              rel="noreferrer"
                              href={imgURL(file.link || file)}
                            >
                              {lang['PROJECT.ATTACHED_FILE']}
                            </a>
                          </p>
                        </div>
                      );
                    })}
                  </>
                ),
              };
            })}
            onSend={addComment}
            onUpdateComment={updateComment}
            onDeleteComment={deleteComment}
          />
        </PerfectScrollbar>
      </div>
    </div>
  );
};

SidebarChat.propTypes = {
  show: PropTypes.bool.isRequired,
  toggleChat: PropTypes.func.isRequired,
  comments: PropTypes.array.isRequired,
  userId: PropTypes.number,
  addComment: PropTypes.func.isRequired,
  updateComment: PropTypes.func.isRequired,
  deleteComment: PropTypes.func.isRequired,
};

export default SidebarChat;
