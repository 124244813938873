import { HC_RELEASE_DATE_AFTER_DEADLINE_DAYS } from '../../const/system';
import { formatDateTimeToString, setDateHours } from './formats';



export const getProjectDeadlineByHCReleaseDate = (hcReleaseDate) => {
  const deadlineDate = new Date(hcReleaseDate);
  const today = new Date();

  let deadlineTo = new Date(deadlineDate.setDate(deadlineDate.getDate() - HC_RELEASE_DATE_AFTER_DEADLINE_DAYS));

  if (deadlineTo < today) {
    deadlineTo = today;
  }
  return formatDateTimeToString(setDateHours(new Date(deadlineTo)));
};

