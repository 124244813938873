import React from 'react';
import { connect } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import DropFile from '../../widgets/DropFile';
import Preload from '../../widgets/Preload';
import axiosApiInstance from '../../requests/utils/api';



const propTypes = {
  lang: PropTypes.any.isRequired,
  history: PropTypes.any,
};

/**
 * Component for the page for sending ideas and suggestions
 */

class Suggestion extends React.PureComponent {
  state = {
    msg: '',
    file: null,
    LOAD: false,
  };

  render () {
    const { lang } = this.props;

    return (<>
      <SubHeader />
      {this.state.LOAD ? (
        <Preload />
      ) : (
        <form
          className='kt-portlet kt-portlet--height-fluid'
          onSubmit={((event) => {
            event.preventDefault();
            if (this.state.msg.length >= 6) {
              this.putAdvice();
            }
          })}
        >
          <div className='kt-portlet__body'>
            <div className='row flex-grow-1'>
              <div
                className='col-md-8 col-12 offset-md-2 d-flex flex-wrap align-content-start'
                style={{ flexDirection: 'column' }}
              >
                <h3 className='text-center w-100'>{lang['MENU.SUGGESTION']}</h3>
                <p className='text-center w-100 mb-5'>{lang['SUGGESTION.MSG']}</p>
                <Form.Control
                  className='flex-grow-1 mb-4'
                  as={'textarea'}
                  style={{
                    resize: 'none',
                    minHeight: 350,
                  }}
                  value={this.state.msg}
                  onChange={(event) => {
                    this.setState({
                      msg: event.target.value,
                    });
                  }}
                />
                <DropFile
                  info={lang['SUGGESTION.FILE']}
                  multiple={false}
                  curFiles={this.state.file ? [ this.state.file ] : []}
                  addFile={(file) => {
                    this.setState({
                      file,
                    });
                  }}
                  removeFile={() => {
                    this.setState({
                      file: null,
                    });
                  }}
                />

                <div className='d-flex justify-content-center mt-5'>
                  <Button
                    disabled={this.state.msg.length < 6}
                    type='submit'
                  >{lang['GLOBAL.SUBMIT']}</Button>
                </div>
              </div>
            </div>
          </div>
        </form>
      )}
    </>);
  }

  putAdvice = async () => {
    this.setState({
      LOAD: true,
    });
    const fd = new FormData();

    fd.append('message', this.state.msg);

    if (this.state.file) {
      fd.append('file', this.state.file.file, this.state.file.file.name);
    }

    try {
      await axiosApiInstance.post('advice', fd, {});
      this.props.history.goBack();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      this.setState({
        LOAD: false,
      });
    }
    return true;
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
  };
};

Suggestion.propTypes = propTypes;

export default connect(mapStoreToProps)(Suggestion);
