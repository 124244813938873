import React, { useEffect } from 'react';
import { Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../customers/roles/groupsRoles';
import roles from '../customers/roles/roles';
import Preload from '../../widgets/Preload';
import manualActions from '../../store/manuals/actions';
import urlPageManuals from '../../urls/urlPageManuals';
import usePrevious from '../../hooks/usePrevious';



const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object,
};

/**
 * Component for manual viewing page
 *
 * @returns {*}
 */
const ManualView = ({ match, history }) => {
  const lang = useSelector((store) => store.language.lang);
  const { loading: LOAD, manual }  = useSelector((store) => store.manuals);
  const { roles: userRoles, currentRole, multipleRoles } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const prevRole = usePrevious(currentRole);

  useEffect(() => {
    dispatch(manualActions.getManual(match.url, history));
  }, []);

  useEffect(() => {
    if (multipleRoles.length > 1 && prevRole && prevRole !== currentRole) {
      history.push(urlPageManuals());
    }
  }, [ multipleRoles, currentRole, prevRole ]);

  const publishManual = () => {
    dispatch(manualActions.updateManual({
      ...manual,
      is_published: true,
    }));
  };

  const unpublishManual = () => {
    dispatch(manualActions.updateManual({
      ...manual,
      is_published: false,
    }));
  };

  return LOAD ? (
    <Preload />
  ) : (
    <>
      <SubHeader
        title={lang['MENU.MANUAL']}
        info={manual.name}
        toolbar={
          checkRole(userRoles, [
            ...groupsRoles.admin,
            ...groupsRoles.managers.all,
            roles['lead-coloring-editor'].key,
          ]) && (
            <>
              <Button
                as={Link}
                to={`/manuals/edit/${manual.id}`}
                variant={'secondary'}
              >
                {lang['GLOBAL.EDIT']}
              </Button>
              {manual.is_published ? (
                <Button
                  variant={'warning'}
                  onClick={unpublishManual}
                >
                  {lang['MANUAL.HIDDEN']}
                </Button>
              ) : (
                <Button
                  variant={'success'}
                  onClick={publishManual}
                >
                  {lang['MANUAL.PUBLISH']}
                </Button>
              )}
            </>
          )
        }
      />
      <div className="row">
        <div className="offset-lg-2 offset-xl-3 col-lg-8 col-xl-6">
          <div className="kt-portlet">
            <div
              className="kt-portlet__body document ql-editor"
              dangerouslySetInnerHTML={{ __html: manual.data }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

ManualView.propTypes = propTypes;

export default ManualView;
