import { uniq } from 'lodash';
import checkRole from '../../../utils/checkRole';
import groupsRoles from './groupsRoles';

/**
 * User Group Types
 *
 * @type {{coloring: string}}
 */
const groupsUsers = {
  coloring: 'coloring',
};

/**
 * Gets a list of user groups.
 *
 * @param {string[]}roles
 * @returns {string[]}
 */
export const getGroupUserByRoles = (roles) => {
  const groups = [];

  if (checkRole(roles, groupsRoles.coloring) || groups.length === 0) {
    groups.push(groupsUsers.coloring);
  }

  return groups;
};

/**
 * Gets a list of roles associated with group types.
 *
 * @param {string[]}groups
 * @returns {string[]}
 */
export const getRolesFromGroupsUsers = (groups) => {
  let roles = [];

  groups.forEach((group) => {
    roles = [ ...roles, ...groupsRoles[group] ];
  });

  return uniq(roles);
};

export default groupsUsers;
