/**
 * types tasks
 *
 * @type {{
 * [key: string]: {role: string, type: string},
 * }}
 */
const taskTypes = {
  // group_choosing: { type: 'group_choosing', role: 'lead-coloring-editor' }, # old type
  designers_group_choosing: { type: 'designers_group_choosing', role: 'lead-coloring-editor' },
  artists_group_choosing: { type: 'artists_group_choosing', role: 'lead-coloring-editor' },
  // executors_choosing: { type: 'executors_choosing', role: 'coloring-editor' }, # old type
  designer_choosing: { type: 'designer_choosing', role: 'coloring-editor' },
  artist_choosing: { type: 'artist_choosing', role: 'coloring-editor' },
  designer_coloring: { type: 'designer_coloring', role: 'coloring-designer' },
  editor_checking_designer: { type: 'editor_checking_designer', role: 'remote-coloring-editor' },
  device_testing: { type: 'device_testing', role: 'coloring-tester' },
  artist_drawing: { type: 'artist_drawing', role: 'coloring-artist' },
  editor_checking_artist: { type: 'editor_checking_artist', role: 'remote-coloring-editor' },
  manager_checking: { type: 'manager_checking', role: 'content-manager' },
};

export default taskTypes;

export const editorFilterTasksMap = [
  'designer_coloring',
  'editor_checking_designer',
  'device_testing',
  'artist_drawing',
  'editor_checking_artist',
];
