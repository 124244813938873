/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react';
import {Dropdown, Nav, Tab} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import HeaderDropdownToggle from '../content/CustomDropdowns/HeaderDropdownToggle';
import NotificationIcon from './icons/NotificationIcon';
import {useDispatch, useSelector} from "react-redux";
import {actionsNotifications} from "../../../app/store/notification";
import userActions from "../../../app/store/user/actions";
import NotificationList from "./NotificationList";


const perfectScrollbarOptions = {
  wheelSpeed: 2,
  wheelPropagation: false,
};

const UserNotifications = ({pulse, iconType, skin, bgImage, type, useSVG, icon}) => {
  const lang = useSelector((store) => store.language.lang);
  const user = useSelector((store) => store.user.user);
  const [notify, setNotify] = useState(false);
  const [unreadNotificationsCount, setUnreadNotificationsCount] = useState(0);
  const [key, setKey] = useState('Comments');
  const dispatch = useDispatch();

  useEffect(() => {
    if (user?.id) {
      window.Echo.private(`comments.${user.id}`)
        .listen('.comment.created', (event) => {
          const isFound = user.notifications.find((notification) => {
            return notification.id === event.comment.id;
          });

          if (typeof isFound === 'undefined') {
            dispatch(userActions.addNotification(event.comment));
            dispatch(actionsNotifications.add(lang['NOTIFICATION.NEW_MESSAGE_RECEIVED'], 'success'));
          }
        });
    }
  }, [user?.id]);

  useEffect(() => {
    const isUnreadCommentFound = user?.notifications?.find((notification) => {
      return !notification.is_read;
    });

    const unreadNotificationsCount = user?.notifications?.filter((notification) => !notification.is_read).length || 0;

    setUnreadNotificationsCount(unreadNotificationsCount)
    setNotify(!!isUnreadCommentFound);
  }, [user])

  const getHeaderTopBarCssClassList = () => {
    let result = 'kt-header__topbar-icon ';

    if (pulse && notify) {
      result += 'kt-pulse kt-pulse--brand ';
    }

    if (iconType) {
      result += `kt-header__topbar-icon--${iconType}`;
    }

    return result;
  };

  const getSvgCssClassList = () => {
    let result = 'kt-svg-icon ';

    if (iconType) {
      result += `kt-svg-icon--${iconType}`;
    }

    return result;
  };

  const getHetBackGroundCssClassList = () => {
    let result = '';

    if (skin) {
      result += `kt-head--skin-${skin} `;
    }

    result += 'kt-head--fit-x kt-head--fit-b';
    return result;
  };

  const backGroundStyle = () => {
    if (!bgImage) {
      return 'none';
    }

    return `url(${bgImage})`;
  };

  const userNotificationsButtonCssClassList = () => {
    let result = 'btn ';

    if (type) {
      result += `btn-label-${type} `;
    }

    result += 'btn-sm btn-bold btn-font-md';
    return result;
  };

  const ulTabsClassList = () => {
    let result = 'nav nav-tabs nav-tabs-line nav-tabs-bold nav-tabs-line-3x  ';

    if (type) {
      result += `nav-tabs-line-${type} `;
    }

    result += 'kt-notification-item-padding-x';
    return result;
  };

  return (
    <Dropdown className="kt-header__topbar-item" drop="down" alignRight>
      <Dropdown.Toggle
        as={HeaderDropdownToggle}
        id="dropdown-toggle-user-notifications"
      >
          <span className={getHeaderTopBarCssClassList()}>
            {!useSVG && <i className={icon} />}

            {useSVG && (
              <span className={getSvgCssClassList()}>
                <NotificationIcon/>
              </span>
            )}

            <span className="kt-pulse__ring" hidden={!pulse}/>
            <span className="kt-badge kt-badge--notify kt-badge--sm" hidden={!unreadNotificationsCount}>{unreadNotificationsCount}</span>

          </span>
      </Dropdown.Toggle>

      <Dropdown.Menu
        className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg">
        <form>
          {/** Head */}
          <div
            className={getHetBackGroundCssClassList()}
            style={{backgroundImage: backGroundStyle()}}
          >
            <Tab.Container
              defaultActiveKey={key}
              className={ulTabsClassList()}
            >
              <Nav
                className={ulTabsClassList()}
                onSelect={(_key) => setKey(_key)}
              >
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="Comments" className="nav-link show">
                    {lang['PROJECT.COMMENTS']}
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="Comments">
                  <PerfectScrollbar
                    options={perfectScrollbarOptions}
                    style={{maxHeight: '100vh', position: 'relative'}}
                  >
                    <NotificationList notifications={user?.notifications || []} />
                  </PerfectScrollbar>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </form>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default UserNotifications;
