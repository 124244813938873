import { NOTIFICATIONS_TYPES } from '../widgets/Notification';



export const actionTypes = {
  ADD: '[ADD NOTIFICATION] Action',
  HIDDEN: '[HIDDEN NOTIFICATION] Action',
};

/**
 * Default notifications state
 *
 * @type {{notifications: []}}
 */
const initialState = {
  notifications: [],
};

/**
 * Id new notification
 *
 * @type {number}
 */
let idNotification = 0;

/**
 * Reducer for a list of notifications
 *
 * @param {{notifications: []}} state
 * @param {{type: string, payload: {}}}action
 * @returns {{notifications: []}}}
 */
export const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ADD: {
      // eslint-disable-next-line no-return-assign
      return {
        notifications: [ ...state.notifications, {
          time: new Date(),
          text: action.payload.notification,
          type: action.payload.type,
          optionalMessages: action.payload.optionalMessages,
          id: idNotification += 1,
        } ],
      };
    }
    case actionTypes.HIDDEN: {
      const index = state.notifications.findIndex((notification) => notification.id === action.payload.id);

      if (index === -1) {
        return state;
      }

      const notifications = [ ...state.notifications ];

      notifications[index] = {
        ...notifications[index],
        isHidden: true,
      };

      return {
        notifications,
      };
    }

    default:
      return state;
  }
};

/**
 * Action creators for "notifications" store
 *
 * @type {{add: (function(*, *=): {payload: {notification: *, type: string}, type: string}), hidden: (function(*): {payload: {id: *}, type: string})}}
 */
export const actionsNotifications = {
  add: (notification, type = NOTIFICATIONS_TYPES.info, optionalMessages = []) => ({ type: actionTypes.ADD, payload: { notification, type, optionalMessages } }),
  hidden: (id) => ({ type: actionTypes.HIDDEN, payload: { id } }),
};
