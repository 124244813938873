import { buildUsersObject } from './users';
import axiosApiInstance from './utils/api';



export const nameGroups = 'groups';

// TODO move request without side effect to services

// eslint-disable-next-line consistent-return
export const getGroup = async (id) => {
  const URL = `groups/${id}`;

  try {
    const response = await axiosApiInstance.get(URL, {});

    if (response.data.group) {
      response.data.group.users = buildUsersObject(response.data.group.users);
      response.data.group = {
        ...response.data.group,
        users: response.data.group.users,
      };
      return response.data.group;
    }
    return response;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
  }
};

