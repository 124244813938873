import axiosApiInstance from '../../requests/utils/api';
import userActions from '../user/actions';



const actions = {
  SET_CONFIG: '[SET CONFIG] Action',
  setConfig: (config) => ({
    type: actions.SET_CONFIG,
    payload: config,
  }),
  getConfig: () => async (dispatch) => {
    try {
      const URL = 'configs';
      const response = await axiosApiInstance.get(URL);

      if (response.data?.config && response.data?.user) {
        dispatch(actions.setConfig(response.data.config));
        dispatch(userActions.setUser({ token: response.data.user.token }));
        dispatch(userActions.setRoles(response.data.user.roles));
      }
    } catch {
      // todo add notification
    }
  },
};

export default actions;
