const rateComplexityTypes = {
  c1: {
    key: 'c1',
  },
  c2: {
    key: 'c2',
  },
  c3: {
    key: 'c3',
  },
};

export default rateComplexityTypes;
