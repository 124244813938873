import React from 'react';
import { v4 as uuidv4 } from 'uuid';

/**
 * Builds a step on which all input information about the project is displayed.
 *
 * @this ProjectCreate
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function () {
  const { lang } = this.props;

  return (
    <div className='kt-wizard-v1__content'>
      <div
        className="kt-heading kt-heading--md"
        style={{ maxWidth: 1024, margin: 'auto' }}
      >
        {lang['PROJECT.REVIEW_AND_SUBMIT.FULL']}
      </div>
      <div className='kt-section'>
        <div className='kt-wizard-v1__form'>
          <div className='row'>
            <div className='col-xl-12'>
              <div className='kt-section__body'>
                <div className='kt-wizard-v1__review'>
                  <div className='row'>
                    {this.state.projects.slice(0, Number(this.state.countProjects)).map((project, index) => {
                      return (
                        <React.Fragment key={uuidv4()}>
                          <div className='w-100 d-flex align-items-center kt-ml-a kt-mr-a'>
                            <span className='w-100 d-block bg-dark border-bottom border-top' />
                            <h2 className='kt-pl-10 kt-pr-10 font-weight-bold kt-mb-0'>{index + 1}</h2>
                            <span className='w-100 d-block bg-dark border-bottom border-top' />
                          </div>
                          {this._buildViewProject({
                            ...this.state.project,
                            ...project,
                            prices: {
                              ...this.state.project.prices,
                              ...project.prices,
                            },
                          })}
                        </React.Fragment>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
