import React from 'react';
import { connect } from 'react-redux';
import checkRole from '../../../app/utils/checkRole';
import MenuSection from './MenuSection';
import MenuItemSeparator from './MenuItemSeparator';
import MenuItem from './MenuItem';
import roles from "../../../app/pages/customers/roles/roles";



class MenuList extends React.Component {
  render () {
    const { currentUrl, pages, layoutConfig, userRoles, user } = this.props;

    // hack to add employee role on the fly. Better solution to add employee role at backend side for user.
    if (user?.is_employee && checkRole(userRoles, [ roles['coloring-artist'].key, roles['coloring-designer'].key ])) {
      userRoles.push(roles.employee.key);
    }

    return (<>
      {pages.filter((item) => item.aside && (!item.roles || checkRole(userRoles, item.roles || [], item.antiRoles || []))).map((child, index) => {
        return (
          <React.Fragment key={`menuList${index}`}>
            {child.section && <MenuSection item={child} />}
            {child.separator && <MenuItemSeparator />}
            <MenuItem
              item={child}
              currentUrl={currentUrl}
              layoutConfig={layoutConfig}
            />
          </React.Fragment>
        );
      })}
    </>);
  }
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userRoles: store.user.roles,
    pages: store.pages,
    user: store.user.user,
  };
};

export default connect(mapStoreToProps)(MenuList);
