import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import axiosApiInstance from '../../../requests/utils/api';
import { parseFromPars } from '../../../utils/parseUrlParams';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const useStatistics = () => {
  const location = useLocation();
  const [ statistics, setStatistics ] = useState({
    artists: {
      tasks: {
        inactive_30_days: 0,
        finished_during_7_days: 0,
        finished_during_30_days: 0,
      },
    },
    designers: {
      tasks: {
        inactive_30_days: 0,
        finished_during_7_days: 0,
        finished_during_30_days: 0,
      },
    },
  });
  const [ isStatisticsLoading, setIsStatisticsLoading ] = useState(false);
  const { userRoles, user } = useSelector(({ user }) => ({
    userRoles: user.roles,
    user: user.user,
  }), shallowEqual);

  useEffect(async () => {
    setIsStatisticsLoading(true);
    const queryParams = parseFromPars(location.search);
    const isAdminOrLeadEditor = checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ]);
    const isGroupDefined = typeof queryParams.group_id !== 'undefined';
    const isUserMatchGroup = user.groups.includes(queryParams.group_id);

    if (isGroupDefined && (isAdminOrLeadEditor || isUserMatchGroup)) {
      const widgetsData = await axiosApiInstance.get(`/dashboards/statistics?group_id=${queryParams.group_id}`);

      setStatistics(widgetsData.data);
    }

    setIsStatisticsLoading(false);
  }, [ location ]);

  return [ statistics, isStatisticsLoading ];
};

export {
  useStatistics,
};
