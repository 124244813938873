import React from 'react';
import { connect } from 'react-redux';
import { selectors } from '../../ducks/builder';
import Brand from '../brand/Brand';
import KTOffcanvas from '../../_assets/js/offcanvas';
import Menu from './Menu';



class AsideLeft extends React.Component {
  asideOffCanvasRef = React.createRef();

  componentDidMount () {
    new KTOffcanvas(
      this.asideOffCanvasRef.current,
      this.props.menuCanvasOptions,
    );
  }

  render () {
    return (
      <>
        <div
          id="kt-aside"
          ref={this.asideOffCanvasRef}
          className={`kt-aside ${this.props.asideClassesFromConfig} kt-grid__item kt-grid kt-grid--desktop kt-grid--hor-desktop`}
        >
          <button className="kt-aside-close" id="kt_aside_close_btn">
            <i className="la la-close" />
          </button>
          <Brand />
          <div
            id="kt_aside_menu_wrapper"
            className="kt-aside-menu-wrapper kt-grid__item kt-grid__item--fluid"
          >
            <Menu />
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (store) => ({
  disableAsideSelfDisplay:
    selectors.getConfig(store, 'aside.self.display') === false,
  asideClassesFromConfig: selectors.getClasses(store, {
    path: 'aside',
    toString: true,
  }),
  menuCanvasOptions: {
    baseClass: 'kt-aside',
    overlay: true,
    closeBy: 'kt_aside_close_btn',
    toggleBy: {
      target: 'kt_aside_mobile_toggler',
      state: 'kt-header-mobile__toolbar-toggler--active',
    },
  },
});

export default connect(mapStateToProps)(AsideLeft);
