import getCountryName from './getCountryName';



const getCountryCodeOptionsList = (countries) => {
  return countries.map((country) => ({
    value: country.country_code,
    label: `${getCountryName(country)} (${country.country_code})`,
  })).sort((firstCountry, secondCountry) => {
    return firstCountry.label.localeCompare(secondCountry.label);
  });
};

export default getCountryCodeOptionsList;
