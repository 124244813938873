import React from 'react';
import { connect } from 'react-redux';
import Button from 'react-bootstrap/Button';
// eslint-disable-next-line deprecate/import
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import SubHeader from '../../../../_metronic/layout/sub-header/SubHeader';
import { ProjectSettings, ReviewAndSubmit } from '../icons';
import Preload from '../../../widgets/Preload';
import { notification } from '../../../requests/notifications';
import _checkProject from '../create/_checkProject';
import _stepBodyListProjects from '../create/_stepBodyListProjects';
import _stepBodyReviewAndSubmit from '../create/_stepBodyReviewAndSubmit';
import _buildViewProject from '../create/_buildViewProject';
import _buildView from '../create/_buildView';
import _filterAddedFields from '../create/_filterAddedFields';
import _buildField from '../create/_buildField';
import _switchInput from '../create/_switchInput';
import _buildInput from '../create/_buildInput';
import _buildInputSelect from '../create/_buildInputSelect';
import _buildTextarea from '../create/_buildTextarea';
import _buildHtmlEditor from '../create/_buildHtmlEditor';
import _buildDate from '../create/_buildDate';
import _buildSelector from '../create/_buildSelector';
import _buildSelectorSearch from '../create/_buildSelectorSearch';
import _buildSwitch from '../create/_buildSwitch';
import _addField from '../create/_addField';
import _buildProject from '../create/_buildProject';
import projectsAction from '../../../store/projects/actions';
import _checkField from '../create/_checkField';
import { toImgUrl } from '../../../utils/gettersUrl';
import { projectTypes } from '../projectTypes';
import axiosApiInstance from '../../../requests/utils/api';
import urlPageProjects from '../../../urls/urlPageProjects';
import { colorComplexityLevels, getPriceByColorComplexityLevel } from '../projectPrices';
import { IMG_DUMMY } from '../../../utils/imgURL';



const propTypes = {
  lang: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  config: PropTypes.object,
  notification: PropTypes.func,
  getReopenedProjects: PropTypes.func.isRequired,
  tags: PropTypes.array.isRequired,
  categories: PropTypes.array,
};

class ProjectEdit extends React.Component {
  constructor (props) {
    super(props);
  }

  state = {
    curStep: 0,
    showInvalid: false,
    project: {
      project_type: 'coloring-any',
      prices: {
        '0': '0',
        '1': '0',
        '2': '7',
        '3': '0',
        '4': '0',
      },
      color_complexity_level: colorComplexityLevels[0],
      designer_price: getPriceByColorComplexityLevel(Number(colorComplexityLevels[colorComplexityLevels.length - 1])),
    },
    projects: [],
    countProjects: 1,
    LOAD: true,
  };

  _buildProject = _buildProject.bind(this);

  _buildViewProject = _buildViewProject.bind(this);

  _buildView = _buildView.bind(this);

  _filterAddedFields = _filterAddedFields.bind(this);

  _buildField = _buildField.bind(this);

  _switchInput = _switchInput.bind(this);

  _buildInput = _buildInput.bind(this);

  _buildInputSelect = _buildInputSelect.bind(this);

  _buildTextarea = _buildTextarea.bind(this);

  _buildHtmlEditor = _buildHtmlEditor.bind(this);

  _buildDate = _buildDate.bind(this);

  _buildSelector = _buildSelector.bind(this);

  _buildSelectorSearch = _buildSelectorSearch.bind(this);

  _buildSwitch = _buildSwitch.bind(this);

  _addField = _addField.bind(this);

  _checkField = _checkField.bind(this);

  _checkProject = _checkProject.bind(this);

  _steps = [
    {
      check: () => {
        // eslint-disable-next-line fp/no-loops,no-restricted-syntax
        for (const project of this.state.projects) {
          if (!this._checkProject({
            ...this.state.project,
            ...project,
            prices: {
              ...this.state.project.prices,
            },
          })) {
            return false;
          }
        }

        return true;
      },
      icon: ProjectSettings,
      title: 'PROJECT.SETTINGS',
      body: _stepBodyListProjects.bind(this),
      preRender: () => {
        const projects = [ ...this.state.projects ];
        const countProjects = Number(this.state.countProjects);

        if (countProjects > projects.length) {
          // eslint-disable-next-line fp/no-loops
          for (let i = projects.length; i < countProjects; i += 1) {
            projects.push({
              prices: {},
            });
          }
        }

        this.setState({
          projects,
        });
      },
    },
    {
      icon: ReviewAndSubmit,
      title: 'PROJECT.REVIEW_AND_SUBMIT',
      body: _stepBodyReviewAndSubmit.bind(this),
    },
  ];

  shouldComponentUpdate (nextProps, _nextState, _nextContext) {
    return nextProps.categories.length !== this.props.categories && nextProps.categories.length > 0;
  }

  componentDidMount () {
    this.props.getReopenedProjects()
      .then((data) => {
        const projects = data.projects.map((project) => {
          return {
            id: project.id,
            category_id: project.options?.category_id,
            comment: '',
            description: project.description,
            deadline: project.deadline_to,
            difficult: project.options?.difficult,
            image_id: null,
            prices: [],
            priority: project.priority,
            project_type: project.type,
            project_status: project.status,
            source: 'any',
            status: 'on',
            tags: project.tags.map((tag) => tag.id),
            categories: project.categories.map((tag) => tag.id),
            files: project.files,
            sourceFile: project.files.map((file) => {
              const fileName = file.link.split('?')[0]?.slice(file.link.lastIndexOf('/') + 1);

              return {
                id: file.id,
                file: new File([ '' ], fileName ?? file.link), // ugly hack to show existed files
                preview: file.link.includes('.eps') ? IMG_DUMMY : toImgUrl(file.link),
              };
            }),
            color_complexity_level: project.options?.color_complexity_level || '1',
            designer_price: 34,
            title: project.title,
            type: project.options.type || 'sharp',
            visibility: project.is_public ? 'on' : 'off',
          };
        });

        this.setState({
          projects,
          countProjects: data.total,
          LOAD: false,
        });
      });
  }

  componentDidUpdate (prevProps) {
    if (prevProps.config !== this.props.config) {
      const { config } = this.props;
      const type = config[this.state.project.project_type];

      if (type && type.steps) {
        const prices = {};

        Object.values(type.steps).forEach((step, key) => {
          prices[key] = String(step.price);
        });

        this.setState({
          project: {
            ...this.state.project,
            prices,
          },
        });
      }
    }

    if (prevProps.tags.length !== this.props.tags.length) {
      this.setState({
        LOAD: false,
      });
    }
  }

  goBack = () => {
    this.props.history.goBack();
  };

  goBackButton = () => {
    return (
      <Button
        variant='default'
        className="btn-bold"
        onClick={this.goBack}
      >
        {this.props.lang['GLOBAL.BACK']}
      </Button>);
  };

  onNextStep = () => {
    const { curStep } = this.state;

    window.scrollTo(0, 0);
    if (curStep <= this._steps.length - 1) {
      if (this._steps[curStep].check ? this._steps[curStep].check() : true) {
        if (this._steps[curStep + 1].preRender) {
          this._steps[curStep + 1].preRender();
        }
        this.setState({
          curStep: curStep + 1,
          showInvalid: false,
        });
      } else {
        this.setState({
          showInvalid: true,
        });
      }
    }
  };

  /**
   * Get checked value
   *
   * @param {any} value
   * @returns {string|*}
   * @private
   */
  _getCheckedValue = (value) => {
    return typeof value === 'string' ? value.trim() : value;
  };

  updateProjects = () => {
    const fd = new FormData();

    fd.append('type', 'coloring-other');

    this.state.projects.forEach((project, index) => {
      project = {
        ...this.state.project,
        ...project,
        prices: {
          ...this.state.project.prices,
          ...project.prices,
        },
      };

      const type = projectTypes.find((item) => item.key === project.project_type);

      if (type.generalSettings) {
        type.generalSettings.forEach((field) => {
          this._addField(fd, field, project, `projects[${index}]`);
        });
      }

      if (type.projectSettings) {
        type.projectSettings.forEach((field) => {
          this._addField(fd, field, project, `projects[${index}]`);
        });
      }

      if (project.sourceFile) {
        project.sourceFile.forEach((file, i) => {
          fd.append(`projects[${index}][files][${i}]`, file.file, file.file.name || file.preview);
        });
      }

      fd.append(`projects[${index}][id]`, project.id);
      fd.append(`projects[${index}][updatePrice]`, project.projectTypeChanged ? 'on' : 'off');
    });

    this.setState({
      LOAD: true,
    });

    axiosApiInstance.post('projects/reopened/start', fd, {})
      .then(() => {
        this.props.notification('NOTIFICATION.SUCCESS_PROJECT_EDIT', 'success');

        this.props.history.push(urlPageProjects());
      })
      .catch((error) => {
        if (error.response) {
          this.props.notification('NOTIFICATION.ERROR_PROJECT_EDIT', 'error');
        }
      })
      .finally(() => {
        this.setState({
          LOAD: false,
        });
      });
  };

  render () {
    const { curStep } = this.state;
    const { lang } = this.props;

    return this.state.LOAD ? (
      <Preload />
    ) : (
      <>
        <SubHeader
          title={lang['PROJECT.EDIT_PROJECTS']}
          info={lang[this._steps[curStep].title]}
          toolbar={this.goBackButton()}
        />
        <div className='kt-portlet'>
          <div className='kt-portlet__body kt-portlet__body--fit'>
            <div className="kt-grid kt-wizard-v1 kt-wizard-v1--white">
              <div className='kt-grid__item  p-3 p-sm-5'>
                <div className='kt-wizard-v1__nav'>
                  <div className='kt-wizard-v1__nav-items'>
                    {this._steps.map((item, index) => {
                      return (
                        <div
                          key={item.title}
                          className="kt-wizard-v1__nav-item"
                          data-ktwizard-state={curStep >= index ? 'current' : 'pending'}
                        >
                          <div className="kt-wizard-v1__nav-body">
                            <div className="kt-wizard-v1__nav-icon">
                              {item.icon}
                            </div>
                            <div className="kt-wizard-v1__nav-label">
                              {lang[item.title]}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
              <div
                className='kt-grid__item kt-grid__item--fluid kt-wizard-v1__wrapper pl-3 pr-3 pb-3 pl-sm-5 pr-sm-5 pb-sm-5 pt-0'
              >
                <form className='kt-form w-100 pt-0 pb-0'>
                  {this._steps[curStep].body && this._steps[curStep].body()}
                  <div className="kt-form__actions" style={{ maxWidth: 1024, margin: 'auto' }}>
                    {curStep > 0 && (
                      <Button
                        variant="secondary"
                        className="kt-font-bold kt-font-transform-u"
                        onClick={() => {
                          window.scrollTo(0, 0);
                          if (curStep > 0) {
                            this.setState({
                              curStep: curStep - 1,
                              showInvalid: false,
                            });
                          }
                        }}
                      >
                        {lang['GLOBAL.BACK']}
                      </Button>
                    )}
                    {(curStep === this._steps.length - 1) ? (
                      <Button
                        variant="success"
                        className="kt-font-bold kt-font-transform-u"
                        style={{ marginLeft: 'auto' }}
                        onClick={this.updateProjects}
                      >
                        {lang['GLOBAL.STATUS.done']}
                      </Button>
                    ) : (
                      <Button
                        variant="brand"
                        className="kt-font-bold kt-font-transform-u"
                        style={{ marginLeft: 'auto' }}
                        onClick={this.onNextStep}
                      >
                        {lang['GLOBAL.NEXT']}
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    config: store.config,
    cRoles: store.user.roles,
    categories: store.categories,
    users: store.users,
    tags: store.tags,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    notification: bindActionCreators(notification, dispatch),
    getReopenedProjects: bindActionCreators(projectsAction.getReopenedProjects, dispatch),
  };
};

ProjectEdit.propTypes = propTypes;

export default connect(mapStoreToProps, mapDispatchToProps)(ProjectEdit);
