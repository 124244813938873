import Image from 'react-bootstrap/Image';
import { Form } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import Button from 'react-bootstrap/Button';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup/dist/yup';
import { array, object } from 'yup';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { IMG_DUMMY } from '../../../../utils/imgURL';
import MySelect from '../../../../widgets/MySelect';
import {
  cleanUpChildTags,
  currentTemplates,
  getTemplateOptions,
  globalGroups,
  prepareValidationRules,
} from '../helpers/helpers';
import {
  transformFormToRequestSimilarPictures,
  transformPictureToDefaultValues,
  transformToGroupEntities,
} from '../helpers/transformers';
import tagActions from '../../../../store/tagSystem/tags/actions';
import axiosApiInstance from '../../../../requests/utils/api';
import { NOTIFICATIONS_TYPES } from '../../../../widgets/Notification';
// eslint-disable-next-line import/no-named-as-default
import notification from '../../../../requests/notifications';
import { entitySubtypes } from '../../config/config';
import GroupList from './GroupList';
import LocalGroupsTags from './LocalGroupsTags';
import SimilarPictures from './SimilarPictures';
import { TagFormProvider } from './TagFormContext';



const PictureTagForm = ({ isLoading, picture, project, pictureStructures, onSubmit }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [ similarImages, setSimilarImages ] = useState([]);
  const [ similarImagesLoading, setSimilarImagesLoading ] = useState(false);
  const [ curForm, setCurForm ] = useState('');
  const latestAbortController = useRef(null);
  const { lang } = useSelector(({
    language,
  }) => ({
    lang: language.lang,
  }), shallowEqual);
  const [ validationSchema, setValidationSchema ] = useState(object().shape({
    templates: array().required(),
  }));

  const {
    aiTags,
  } = useSelector(({
    tagSystemTags,
  }) => (
    {
      aiTags: tagSystemTags.ai_tags,
    }
  ), shallowEqual);

  const {
    control,
    formState: { errors, isDirty },
    watch,
    handleSubmit,
    setValue,
    reset,
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(validationSchema),
  });
  const formValues = watch();

  useEffect(() => {
    if (formValues?.templates?.length && project?.preview) {
      dispatch(tagActions.getAITags({
        templates: formValues.templates.map((item) => item.value),
        image_url: project.preview,
      }));
    }
  }, [ formValues?.templates ]);

  const getSimilarImages = async (abortSignal) => {
    const data = transformFormToRequestSimilarPictures(picture?.project_id, formValues);

    try {
      const canGetSimilarPictures = data.additional_tags?.length > 0
        || data.primary_tags?.length > 0
        || data.type_tags?.length > 0;

      if (canGetSimilarPictures) {
        setSimilarImagesLoading(true);

        const res = await axiosApiInstance.post(
          'tag-system/pictures/similar',
          data,
          { signal: abortSignal }
        );

        setSimilarImages(res.data || []);
      } else {
        setSimilarImages([]);
      }
    } catch (err) {
      if (err.message === 'canceled') {
        setSimilarImages([]);
      } else {
        dispatch(notification('TAG_SYSTEM.PICTURE.GET_SIMILAR_PICTURES_FAILED', NOTIFICATIONS_TYPES.error));
      }
    } finally {
      setSimilarImagesLoading(false);
    }
  };

  useEffect(() => {
    const formJSON = JSON.stringify(
      formValues,
      (k, v) => (v === undefined ? null : v)
    );

    if (curForm !== formJSON) {
      cleanUpChildTags(formValues, pictureStructures, setValue);

      setValidationSchema(object().shape({
        templates: array().required(),
        ...prepareValidationRules(formValues, pictureStructures),
      }));

      setCurForm(formJSON);

      if (latestAbortController.current) {
        latestAbortController.current.abort();
      }

      const controller = new AbortController();

      latestAbortController.current = controller;

      getSimilarImages(controller.signal);
    }
  }, [ formValues ]);


  useEffect(() => {
    if (!isLoading) {
      reset(transformPictureToDefaultValues(picture, pictureStructures));
    }
  }, [ isLoading ]);

  const onCreateTag = (data, callback) => {
    dispatch(tagActions.createTag(data))
      .then((tag) => {
        if (typeof callback === 'function') {
          callback(tag);
        }
      });
  };

  const curTemplates = useMemo(() => {
    return currentTemplates(pictureStructures, formValues);
  }, [ pictureStructures, formValues ]);

  return (
    <Row className="flex">
      <Col xs={12} md={6}>
        <>
          <div>
            <div className='d-flex flex-column'>
              <Form.Group>
                <Form.Label>
                  <strong>
                    {lang['TAG_SYSTEM.TEMPLATES.TEMPLATE']}
                    <span style={{ color: 'red' }}>*</span>
                  </strong>
                </Form.Label>
                <Controller
                  name="templates"
                  control={control}
                  render={({ field }) => {
                    return (
                      <MySelect
                        {...field}
                        isClearable
                        isMulti
                        options={getTemplateOptions(pictureStructures)}
                        isInvalid={!!errors.templates}
                      />
                    );
                  }}
                />
                <Form.Control.Feedback type="invalid">
                  {lang['TAG_SYSTEM.TAGS.TEMPLATE_REQUIRED']}
                </Form.Control.Feedback>
              </Form.Group>
            </div>
            <TagFormProvider formValues={formValues} setValue={setValue} errors={errors} control={control} >
              <GroupList
                groups={transformToGroupEntities(globalGroups(pictureStructures, formValues))}
                onCreateTag={onCreateTag}
                type={entitySubtypes.global}
              />
              <LocalGroupsTags
                templates={curTemplates}
                aiTags={aiTags}
                onCreateTag={onCreateTag}
              />
            </TagFormProvider>
          </div>
          <div>
            {!!formValues?.templates?.length && (
              <div className='d-flex justify-content-end my-5 py-5'>
                <Button
                  variant='secondary'
                  className='mr-2'
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  {lang['GLOBAL.BACK']}
                </Button>
                <Button
                  disabled={!isDirty}
                  variant='success'
                  onClick={handleSubmit(onSubmit)}
                >{lang['GLOBAL.SAVE']}</Button>
              </div>
            )}
          </div>
        </>
      </Col>
      <Col xs={12} md={6} className="fixed position-relative">
        <Container
          style={{
            position: 'sticky',
            top: '135px',
          }}
        >
          <Image
            src={project?.preview || IMG_DUMMY}
            alt='img'
            className="mb-3 tag-picture-preview"
          />
          {!!formValues?.templates?.length && !!similarImages?.length && (
            <SimilarPictures
              isLoading={similarImagesLoading}
              images={formValues?.templates?.length && similarImages || []}
            />
          )}
        </Container>
      </Col>
    </Row>
  );
};

PictureTagForm.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  picture: PropTypes.object.isRequired,
  project: PropTypes.object.isRequired,
  pictureStructures: PropTypes.array.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

export default PictureTagForm;
