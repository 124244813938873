import store from '../store/store';
import { setUsersAction } from '../store/users';
import axiosApiInstance from './utils/api';



export const nameUsers = 'users';

export const getUsersAll = () => (dispatch) => {
  const URL = 'users/all';

  axiosApiInstance.get(URL, {})
    .then((response) => {
      if (response.data.users) {
        dispatch(setUsersAction(response.data.users));
      }
    })
    .catch((error) => {
      if (error.response) {
        // eslint-disable-next-line no-console
        console.error(error.response.data);
      }
    });
};

export const addUserRole = (data) => {
  const URL = 'users/roles';

  return axiosApiInstance.post(URL, data);
};

export const getUser = (id) => {
  const { users } = store.getState();

  return users ? users.find((user) => user.id === id) : {};
};

export const getUserRoles = (id) => {
  const { users } = store.getState();

  return users ? users.find((user) => user.id === id)?.roles : {};
};

export const getUserIds = (users = []) => {
  return users.map((user) => {
    return user.id;
  });
};

export const _getUsers = (users = []) => {
  return getUserIds(users).map((id) => {
    return getUser(id);
  });
};

export const getUsers = (userIds = []) => {
  return userIds.map((id) => {
    return getUser(id);
  });
};

// eslint-disable-next-line consistent-return
export const buildUsersObject = (usersFromRequest = []) => {
  const users = _getUsers(usersFromRequest);

  if (users.length === usersFromRequest.length) {
    // eslint-disable-next-line array-callback-return,consistent-return
    return users.map((user, key) => {
      if (Object.prototype.hasOwnProperty.call(usersFromRequest, key)) {
        return Object.assign(usersFromRequest[key] || {}, user);
      }
    });
  }
};

export const _buildUsersObjectFromAttribute = (usersFromRequest = [], userAttribute) => {
  const usersIds = usersFromRequest.map((item) => {
    return item[userAttribute];
  });

  return getUsers(usersIds);
};

// eslint-disable-next-line consistent-return
export const buildResponseUsers = (items = [], userAttribute) => {
  if (Array.isArray(items)) {
    const users = items.map((item) => {
      return item[userAttribute];
    });

    const _users = buildUsersObject(users);

    return items.map((item, key) => {
      if (Object.prototype.hasOwnProperty.call(_users, key)) {
        item[userAttribute] = _users[key];
        return item;
      }
      return item;
    });
  }
};

export const buildResponseUser = (item = [], userAttribute) => {
  if (item instanceof Object) {
    const user = getUser(item[userAttribute].id);

    Object.assign(item[userAttribute] || {}, user);

    return item;
  }
  return item;
};
export const _buildResponseUser = (item = [], userAttribute) => {
  if (item instanceof Object) {
    item[userAttribute] = getUser(item[userAttribute]);
    return item;
  }
  return item;
};

// eslint-disable-next-line consistent-return
export const buildResponseUsersFromAttribute = (items = [], userAttribute) => {
  if (Array.isArray(items)) {
    const _users = _buildUsersObjectFromAttribute(items, userAttribute);

    return items.map((item, key) => {
      if (Object.prototype.hasOwnProperty.call(_users, key)) {
        item[userAttribute] = _users[key];
        return item;
      }
      return item;
    });
  }
};

export const updateRatesRequest = (rates, userId) => async (dispatch) => {
  const URL = `users/${userId}/update-rate`;
  const response = await axiosApiInstance.put(URL, {
    rates,
  });

  if (response.status === 200) {
    dispatch(getUsersAll());
  }
};

export default getUsersAll;
