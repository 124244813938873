import { DateTime } from 'luxon';



export const dateFormat = new Intl.DateTimeFormat('ru', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
});

export const timeFormat = new Intl.DateTimeFormat('ru', {
  hour: '2-digit',
  minute: '2-digit',
});

export const dateTimeFormat = new Intl.DateTimeFormat('ru', {
  year: 'numeric',
  month: '2-digit',
  day: '2-digit',
  hour: '2-digit',
  minute: '2-digit',
});

export const formatDate = (date, format = 'yyyy-MM-dd') => {
  if (!date) {
    return null;
  }

  return DateTime.fromJSDate(new Date(Date.parse(date))).toFormat(format);
};

/**
 * converts date to type "YYYY-MM-DD"
 *
 * @param {string} date
 * @returns {string|null}
 */
export const formatDateToString = (date) => {
  if (!date) {
    return null;
  }
  try {
    let value = null;

    if (date instanceof Date) {
      value = date;
    } else {
      value = new Date(date);
    }

    let month = `${value.getMonth() + 1}`;

    let day = `${value.getDate()}`;
    const year = value.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [ year, month, day ].join('-');
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    return null;
  }
};

/**
 * converts date to type "YYYY-MM-DD hh:mm:ss"
 *
 * @param {string} date
 * @returns {string|null}
 */
export const formatDateTimeToString = (date) => {
  if (!date) {
    return null;
  }
  try {
    const value = new Date(date);

    let month = `${value.getMonth() + 1}`;

    let day = `${value.getDate()}`;
    const year = `${value.getFullYear()}`;

    let hours = `${value.getHours()}`;

    let minutes = `${value.getMinutes()}`;

    let seconds = `${value.getSeconds()}`;

    if (month.length < 2) month = `0${month}`;

    if (day.length < 2) day = `0${day}`;

    if (hours.length < 2) hours = `0${hours}`;

    if (minutes.length < 2) minutes = `0${minutes}`;

    if (seconds.length < 2) seconds = `0${seconds}`;

    return `${[ year, month, day ].join('-')} ${[ hours, minutes, seconds ].join(':')}`;
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    return null;
  }
};

/**
 * converts "YYYY-MM-DD hh:mm:ss.ms" format string to a date
 *
 * @param {string} str
 * @returns {null|any}
 */
export const formatStringToDate = (str) => {
  if (!str || str.includes('*')) return null;
  const value = str.split(/[.///,:\-\s]/g).map((item, index) => Number(index === 1 ? item - 1 : item));

  return new Date(...value);
};

/**
 * transform date to format
 *
 * @param {string|Date} date
 * @param {Intl.DateTimeFormat} format
 * @returns {string}
 * @private
 */
const formatToPretty = (date, format) => {
  if (!date) {
    return '';
  }
  try {
    if (typeof date === 'string' || date instanceof String) {
      date = formatStringToDate(date);
    }
    date = format.format(date);
    // eslint-disable-next-line no-unused-vars
  } catch (error) {
    return '';
  }

  return date;
};

/**
 * converts date to pretty date string
 *
 * @param {string|Date} date
 * @returns {string}
 */
export const formatToPrettyDate = (date) => formatToPretty(date, dateFormat);

/**
 * converts date to pretty date time string
 *
 * @param {string|Date} date
 * @returns {string}
 */
export const formatToPrettyDateTime = (date) => formatToPretty(date, dateTimeFormat);

/**
 * converts date to pretty time string
 *
 * @param {string|Date} date
 * @returns {string}
 */
export const formatToPrettyTime = (date) => formatToPretty(date, timeFormat);

/**
 * converts date to pretty date or time string
 * If today's date returns time, otherwise date
 *
 * @param {string|Date} date
 * @returns {string}
 */
export const formatToPrettyDateOrTime = (date) => formatToPretty(date, {
  format: (value) => {
    const date = dateFormat.format(value);

    if (date === dateFormat.format(Date.now())) {
      return timeFormat.format(value);
    }
    return date;
  },
});

/**
 * convert to pretty money string
 *
 * @param {number|string} money
 * @returns {string}
 */
export const toStrMoney = (money) => Number(money).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');

export const setDateHours = (date) => {
  return date.setHours(23, 59, 59);
};
