import { isBYPersonalData, isRU } from './checker';



export const getLabelText = (values, text) => {
  if (isBYPersonalData(values)) {
    return text.russian;
  }
  if (isRU(values)) {
    return text.general;
  }

  return text.latin;
};
