import actions from './actions';



const initialState = {
  isAccepted: true,
  term: null,
  term_id: null,
  loading: false,
};

/**
 * User agreement reducer
 *
 * @param {any} state
 * @param {any} action
 * @returns {object}
 */
export const termReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.TERMS_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case actions.SET_TERM: {
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

