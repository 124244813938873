import { TASK_BONUS_STATUS } from '../../../const/tasks';



const today = new Date();
const month = new Date(today.getFullYear(), today.getMonth() + 1);
const month2 = new Date(today.getFullYear(), today.getMonth() + 2);
const month3 = new Date(today.getFullYear(), today.getMonth() + 3);

export default {
  afterLoad: {
    artist: [
      {
        name: 'EPS',
        step: 'artist_drawing',
        prefix: 'eps',
        uploaded: true,
        required: true,
        data: {
          preview: '', // "/project_files/previews/191/1372_artist_drawing_preview_lg.png"
        },
        version_id: 1587378146,
        valid: true,
        link: '', // "project_files/191/1372/191_1372_1587378146.eps",
        preview: null,
        user: 117,
      },
    ],
  },
  artist: [
    {
      project_id: 1,
      executor_id: null,
      price: 21,
      type: 'artist_drawing',
      status: status.process,
      bonus: {
        status: TASK_BONUS_STATUS.APPLIED,
        amount: 8,
      },
      started_at: null,
      finished_at: null,
      paid_at: null,
      deadline_to: month,
      deleted_at: null,
      has_burned: false,
      bonuses: null,
      executor: null,
      id: 1,
      title: 'Task 1',
      priority: 3,
      preview: null,
      image_type: 'sharp',
      files: [],
      project: {
        manager_id: 1,
        manager: {
          email: 'backend@fabros-team.com',
          username: 'Manager 1',
          firstname: 'Илья',
          lastname: 'Закрута',
          id: 1,
          roles: [
            'administrator',
          ],
        },
        title: 'Task 1',
        description: '',
        options: {
          type: 'sharp',
          price: {
            artist: 21,
            editor: 0,
            designer: 10,
            tester: 0,
          },
        },
        status: status.process,
        priority: 3,
        type: 'coloring-artist',
        is_public: true,
        comments_count: 0,
        tags: [],
        history: [],
        comments: [],
        deadline_to: month,
        started_at: today,
        finished_at: null,
        updated_at: today,
        closed_at: null,
        deleted_at: null,
        id: 1,
        files: [],
      },
    },
    {
      project_id: 2,
      executor_id: null,
      price: 21,
      type: 'artist_drawing',
      status: status.process,
      bonus: {
        status: TASK_BONUS_STATUS.WAITING,
        amount: 20,
      },
      started_at: null,
      finished_at: null,
      paid_at: null,
      deadline_to: month2,
      deleted_at: null,
      has_burned: false,
      bonuses: null,
      executor: null,
      id: 2,
      title: 'Task 2',
      priority: 3,
      preview: null,
      image_type: 'sharp',
      files: [],
      project: {
        manager_id: 1,
        manager: {
          email: 'backend@fabros-team.com',
          username: 'Manager 1',
          firstname: 'Илья',
          lastname: 'Закрута',
          id: 1,
          roles: [
            'administrator',
          ],
        },
        title: 'Task 2',
        description: '',
        options: {
          type: 'sharp',
          price: {
            artist: 21,
            editor: 0,
            designer: 10,
            tester: 0,
          },
        },
        status: status.process,
        priority: 3,
        type: 'coloring-artist',
        is_public: true,
        comments_count: 0,
        tags: [],
        history: [],
        comments: [],
        deadline_to: month2,
        started_at: today,
        finished_at: null,
        updated_at: today,
        closed_at: null,
        deleted_at: null,
        id: 2,
        files: [],
      },
    },
    {
      project_id: 3,
      executor_id: null,
      price: 21,
      type: 'artist_drawing',
      status: status.process,
      bonus: {
        status: TASK_BONUS_STATUS.CANCELED,
        amount: 123,
      },
      started_at: null,
      finished_at: null,
      paid_at: null,
      deadline_to: month3,
      deleted_at: null,
      has_burned: false,
      bonuses: null,
      executor: null,
      id: 3,
      title: 'Task 3',
      priority: 3,
      preview: null,
      image_type: 'sharp',
      files: [],
      project: {
        manager_id: 1,
        manager: {
          email: 'backend@fabros-team.com',
          username: 'Manager 1',
          firstname: 'Илья',
          lastname: 'Закрута',
          id: 1,
          roles: [
            'administrator',
          ],
        },
        title: 'Task 3',
        description: '',
        options: {
          type: 'sharp',
          price: {
            artist: 21,
            editor: 0,
            designer: 10,
            tester: 0,
          },
        },
        status: 2,
        priority: 3,
        type: 'coloring-artist',
        is_public: true,
        comments_count: 0,
        tags: [],
        history: [],
        comments: [],
        deadline_to: month3,
        started_at: today,
        finished_at: null,
        updated_at: today,
        closed_at: null,
        deleted_at: null,
        id: 3,
        files: [],
      },
    },
  ],
  designer: [],
};
