import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import MyProfile from '../customers/MyProfile';
import TutorialTaskInfo from './TutorialTaskInfo';
import TourMain from './Tour';
import TutorialTasksList from './TutorialTasksList';



const propTypes = {
  history: PropTypes.any.isRequired,
  userId: PropTypes.any,
  location: PropTypes.any,
};

export const STORAGE_FINISHED_TUTORIAL = 'FINISHED_TUTORIAL';

class Tutorial extends React.PureComponent {
  state = {
    step: 'main',
    blocked: false,
  };

  refTaskInfo = React.createRef();

  refTour = React.createRef();

  render () {
    const { step } = this.state;

    return (<>
      {!this.state.blocked && (
        <div
          role="link"
          tabIndex={0}
          onKeyDown={() => {}}
          className='my-tour-btn-close'
          onClick={() => {
            this.end();
          }}
        >
          <i className='la la-close' />
        </div>
      )}
      <TourMain
        ref={this.refTour}
        setStep={this.setStep}
        end={this.end}
        selectFiles={() => {
          if (this.refTaskInfo.current) {
            this.refTaskInfo.current.selectFiles();
          }
        }}
        finishTask={() => {
          if (this.refTaskInfo.current) {
            this.refTaskInfo.current.finishTask();
          }
        }}
      />
      {step === 'main' && (
        <MyProfile
          history={this.props.history}
        />
      )}
      {step === 'list' && (
        <TutorialTasksList
          history={this.props.history}
          location={this.props.location}
          setStep={this.setStep}
          nextStepTour={() => {
            if (this.refTour.current) {
              this.refTour.current.nextStep();
            }
          }}
        />
      )}
      {step !== 'main' && step !== 'list' && (
        <TutorialTaskInfo
          ref={this.refTaskInfo}
          history={this.props.history}
          location={this.props.location}
          nextStepTour={() => {
            if (this.refTour.current) {
              this.refTour.current.nextStep();
            }
          }}
          id={Number(this.state.step)}
        />
      )}
    </>);
  }

  setStep = (step) => {
    this.setState({
      step,
    });
  };

  componentWillUnmount () {
    const item = document.querySelector('[data-tour="aside-tasks"]');

    item.removeEventListener('mouseleave', this.mouseLeaveFromAsideItem);
    item.classList.remove('kt-menu__item--hover');
    const link = document.querySelector('[data-tour="aside-tasks-available"] > a');

    link.removeEventListener('click', this.clickAvailableTasks);
  }

  end = () => {
    try {
      if (this.state.blocked) {
        const finishTutorial = localStorage.getItem(STORAGE_FINISHED_TUTORIAL)
          ? JSON.parse(localStorage.getItem(STORAGE_FINISHED_TUTORIAL))
          : [];

        if (finishTutorial.indexOf(this.props.userId) === -1) {
          finishTutorial.push(this.props.userId);
          localStorage.setItem(STORAGE_FINISHED_TUTORIAL, JSON.stringify(finishTutorial));

          window.location.reload();
          return;
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
    this.props.history.push('/');
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    userId: store.user.user.id,
  };
};

Tutorial.propTypes = propTypes;

export default connect(mapStoreToProps)(withRouter(Tutorial));
