import React from 'react';
import { Accordion, Card } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { templateLocalGroups } from '../helpers/helpers';
import { transformToGroupEntities } from '../helpers/transformers';
import GroupList from './GroupList';



const LocalGroupsTags = ({ templates, aiTags, onCreateTag }) => {
  return (
    templates.map((template) => {
      const templateAiTags = aiTags[template.id] || [];

      const groupEntities = transformToGroupEntities(template.groups || []);
      const groups = templateLocalGroups(groupEntities);

      return (
        <Accordion
          className='mt-5'
          activeKey={template.id}
          key={template.id}
        >
          <Card key={template.id}>
            <Accordion.Toggle
              as={Card.Header}
              eventKey={template.id}
              className='px-3 d-flex align-items-center'
              style={{ height: '40px' }}
            >
              <p className='text-dark font-weight-bolder mb-0'>{template?.template_name}</p>
            </Accordion.Toggle>
            <Accordion.Collapse
              eventKey={template.id}
              className='border-top'
            >
              <Card.Body>
                <GroupList
                  aiTags={templateAiTags}
                  groups={groups.sort((first, second) => first.id - second.id)}
                  onCreateTag={onCreateTag}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      );
    })
  );
};

LocalGroupsTags.propTypes = {
  templates: PropTypes.array.isRequired,
  aiTags: PropTypes.array,
  onCreateTag: PropTypes.func.isRequired,
};

export default LocalGroupsTags;
