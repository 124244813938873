import axiosApiInstance from '../../../requests/utils/api';
import { actionsNotifications } from '../../notification';
import { NOTIFICATIONS_TYPES } from '../../../widgets/Notification';
import trimObjectStringProps from '../../../utils/trimObjectStringProps';
import { tagSystemPath } from '../helpers';
import handleResponseMessage from '../../../utils/handleResponseMessage';



const actions = {
  LOADING: 'LOADING_GROUPS',
  ADD_GROUP: 'ADD_GROUP',
  SET_TAG_GROUPS: 'SET_TAG_GROUPS',
  SET_TAG_GROUP: 'SET_TAG_GROUP',
  UPDATE_GROUP: 'UPDATE_GROUP',
  DELETE_GROUP: 'DELETE_GROUP',
  ADD_TAG_TO_GROUP: 'ADD_TAG_TO_GROUP',
  REMOVE_TAG_FROM_GROUP: 'REMOVE_TAG_FROM_GROUP',
  setLoading: (payload) => ({
    type: actions.LOADING,
    payload,
  }),
  addGroup: (payload) => ({
    type: actions.ADD_GROUP,
    payload,
  }),
  setGroups: (payload) => ({
    type: actions.SET_TAG_GROUPS,
    payload,
  }),
  setGroup: (payload) => ({
    type: actions.SET_TAG_GROUP,
    payload,
  }),
  removeGroup: (payload) => ({
    type: actions.DELETE_GROUP,
    payload,
  }),
  addTagToGroup: (payload) => ({
    type: actions.ADD_TAG_TO_GROUP,
    payload: {
      group_id: payload.group_id,
      tag: payload.tag,
    },
  }),
  removeTagFromGroup: (payload) => ({
    type: actions.REMOVE_TAG_FROM_GROUP,
    payload: {
      groupId: payload.groupId,
      tagId: payload.tagId,
    },
  }),
  getGroups: () => {
    return async (dispatch, getState) => {
      try {
        dispatch(actions.setLoading(true));
        const URL = `${tagSystemPath}/entities?type=group&include_children=true`;
        const data = await axiosApiInstance.get(URL);

        dispatch(actions.setGroups(data.data));

        return data;
      } catch {
        const store = getState();

        dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_GROUPS'], NOTIFICATIONS_TYPES.error));
      } finally {
        dispatch(actions.setLoading(false));
      }
    };
  },
  getGroup: (id) => {
    return async (dispatch, getState) => {
      try {
        dispatch(actions.setLoading(true));
        const URL = `${tagSystemPath}/entities/${id}?include_children=true`;
        const data = await axiosApiInstance.get(URL);

        dispatch(actions.setGroup(data.data));
      } catch {
        const store = getState();

        dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_GROUP'], NOTIFICATIONS_TYPES.error));
      } finally {
        dispatch(actions.setLoading(false));
      }
    };
  },
  createGroup: (newGroup, callback) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true));
    const store = getState();

    try {
      const URL = `${tagSystemPath}/entities`;
      const data = await axiosApiInstance.post(URL, trimObjectStringProps(newGroup));
      const group = data.data;

      dispatch(actions.addGroup(group));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_GROUP_CREATE'], NOTIFICATIONS_TYPES.success));

      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GROUP_CREATE']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  updateGroup: (newGroup, callback) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true));
    const store = getState();

    try {
      const URL = `${tagSystemPath}/entities/${newGroup.id}`;

      await axiosApiInstance.put(URL, trimObjectStringProps(newGroup));

      dispatch({
        type: actions.UPDATE_GROUP,
        payload: newGroup,
      });
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_GROUP_EDIT'], NOTIFICATIONS_TYPES.success));

      if (typeof callback === 'function') {
        callback();
      }
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GROUP_EDIT']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  deleteGroup: (groupId) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true));
    const store = getState();

    try {
      const URL = `${tagSystemPath}/entities/${groupId}`;

      await axiosApiInstance.delete(URL);
      dispatch(actions.removeGroup(groupId));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_GROUP_DELETE'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GROUP_DELETE']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
