import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import imageType from '../../projects/image_type';
import Modal from '../../../widgets/Modal';



const getImageTypes = () => {
  return imageType.map((type) => {
    return (
      <option key={type} value={type}>
        {type}
      </option>
    );
  });
};

const ChangeImageTypeModal = ({ task, showModal, isActive, onConfirm }) => {
  const [ selectedImageType, setImageType ] = useState(imageType[0]);
  const lang = useSelector((store) => store.language.lang);

  useEffect(() => {
    setImageType(task.image_type);
  }, [ task.image_type ]);

  return (
    <Modal
      title={lang['PROJECT.CHANGE_IMAGE_TYPE']}
      show={isActive}
      close={() => {
        showModal(false, task.id);
      }}
      footer={
        <>
          <button
            className="btn btn-secondary"
            onClick={() => {
              showModal(false, task.id);
            }}
          >
            {lang['GLOBAL.CANCEL']}
          </button>
          <button
            className="btn btn-success"
            disabled={task.image_type === selectedImageType}
            onClick={() => {
              onConfirm(task.project_id, selectedImageType);
            }}
          >
            {lang['GLOBAL.EDIT']}
          </button>
        </>
      }
    >
      <Form.Group>
        <Form.Label>{lang['PROJECT.FIELDS.type']}</Form.Label>
        <Form.Control
          as={'select'}
          value={selectedImageType}
          onChange={(event) => {
            setImageType(event.target.value);
          }}
        >
          {getImageTypes()}
        </Form.Control>
      </Form.Group>
    </Modal>
  );
};

ChangeImageTypeModal.propTypes = {
  task: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default ChangeImageTypeModal;
