import React from 'react';
import '../../../_metronic/_assets/sass/pages/error/error-4.scss';
import PropTypes from 'prop-types';
import { toAbsoluteUrl } from '../../utils/gettersUrl';



export const ErrorPage = ({ code, title, description }) => {
  return (
    <div className="kt-grid kt-grid--ver kt-grid--root h-100 bg-white">
      <div
        className="kt-grid__item kt-grid__item--fluid kt-grid kt-error-v4"
        style={{
          backgroundImage: `url(${toAbsoluteUrl('/media/error/bg4.jpg')})`,
        }}
      >
        <div className="kt-error_container">
          <h1 className="kt-error_number">{code}</h1>
          <p className="kt-error_title">{(title || '').toUpperCase()}</p>
          <p className="kt-error_description">{description}</p>
        </div>
      </div>
    </div>
  );
};

ErrorPage.propTypes = {
  code: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
};

export default ErrorPage;
