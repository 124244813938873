import { orderBy } from 'lodash';
import {
  setTagsAction, addTagAction, updateTagAction, deleteTagAction,
} from '../store/tags';
import { NOTIFICATIONS_TYPES } from '../widgets/Notification';
import { actionsNotifications } from '../store/notification';
import { getErrorMessage } from '../utils/getErrorMessage';
import axiosApiInstance from './utils/api';



export const nameTags = 'tags';

export const getTags = () => (dispatch, getState) => {
  const store = getState();

  if (store.tags.length) {
    return;
  }

  const URL = 'tags';

  axiosApiInstance.get(URL, {})
    .then((response) => {
      if (response.data.tags) {
        response.data.tags = orderBy(response.data.tags, (tag) => tag.slug);
        dispatch(setTagsAction(response.data.tags));
      }
    })
    .catch((error) => {
      if (error.response) {
        // eslint-disable-next-line no-console
        console.error(error.response.data);
      }
    });
};

export const saveTag = (createTag) => (dispatch, getState) => {
  const URL = createTag.isEdit ? `tags/${createTag.id}` : 'tags';
  const store = getState();

  let method = 'post';

  if (createTag.isEdit) {
    method = 'put';
  }

  axiosApiInstance[method](URL, {
    name: createTag.name.trim(),
    slug: createTag.slug,
  })
    .then((response) => {
      const responseTag = response.data;

      const newTag = {
        id: createTag.isEdit ? createTag.id : responseTag.id,
        name: createTag.name.trim(),
        slug: createTag.slug,
      };

      // eslint-disable-next-line no-unused-expressions
      createTag.isEdit ? dispatch(updateTagAction(newTag)) : dispatch(addTagAction(newTag));

      // eslint-disable-next-line no-unused-expressions
      createTag.isEdit
        ? dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_TAG_EDIT'], NOTIFICATIONS_TYPES.success))
        : dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_TAG_CREATE'], NOTIFICATIONS_TYPES.success));
    })
    .catch((error) => {
      let messages = [];

      if (error.response) {
        messages = getErrorMessage(error?.response?.data);
      }

      const notificationType = createTag.isEdit
        ? `${store.language.lang['NOTIFICATION.ERROR_TAG_EDIT']}`
        : `${store.language.lang['NOTIFICATION.ERROR_TAG_CREATE']}`;

      dispatch(actionsNotifications.add(notificationType, NOTIFICATIONS_TYPES.error, messages.join(' ')));
    });
};

export const addTag = (fd, value, tag, slug) => (dispatch) => {
  const URL = 'tags';

  return axiosApiInstance.post(URL, fd, {})
    .then((response) => {
      const msg = response.data;

      const newValue = [ ...value, msg.id ];

      const newTag = {
        id: msg.id,
        name: tag.trim(),
        slug,
      };

      dispatch(addTagAction(newTag));

      return newValue;
    })
    .catch((error) => {
      if (error.response) {
        // eslint-disable-next-line no-console
        console.error(error.response.message);
      }
    });
};

export const deleteTag = (id) => (dispatch, getState) => {
  const URL = `tags/${id}`;
  const store = getState();

  axiosApiInstance.delete(URL)
    .then(() => {
      dispatch(deleteTagAction(id));
      dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.SUCCESS_TAG_DELETE'], NOTIFICATIONS_TYPES.success));
    })
    .catch((error) => {
      if (error.response) {
        // eslint-disable-next-line no-console
        console.error(error.response.message);
        dispatch(actionsNotifications.add(store.language.lang['NOTIFICATION.ERROR_TAG_DELETE'], NOTIFICATIONS_TYPES.error));
      }
    });
};

export const prepareTags = (tags) => {
  if (tags) {
    return tags.map((tag) => ({
      value: tag.id,
      label: tag.name,
      slug: tag.slug,
    }));
  }

  return null;
};

/**
 * Removes tags from a project
 *
 * @param {any} selectTags
 * @param {number} projectId
 * @returns {Promise<Response>}
 */
// eslint-disable-next-line consistent-return
export const removeTagsFromProject = async (selectTags, projectId) => {
  const URL = `projects/${projectId}/remove-tag`;
  const fd = new FormData();

  fd.append('_method', 'PUT');

  selectTags.forEach((tag, index) => {
    fd.append(`tags[${index}]`, tag);
  });

  try {
    return await axiosApiInstance.post(URL, fd, {});
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error.response) {
      // eslint-disable-next-line no-console
      console.error(error.response.data);
    }
  }
};

/**
 * Adds tags to a project
 *
 * @param {any} selectTags
 * @param {number} projectId
 * @returns {Promise<Response>}
 */
// eslint-disable-next-line consistent-return
export const addTagsToProject = async (selectTags, projectId) => {
  const URL = `projects/${projectId}/add-tag`;
  const fd = new FormData();

  selectTags.forEach((tag, index) => {
    fd.append(`tags[${index}]`, tag);
  });

  fd.append('_method', 'PUT');

  try {
    return await axiosApiInstance.post(URL, fd, {});
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (error.response) {
      // eslint-disable-next-line no-console
      console.error(error.response.data);
    }
  }
};

export default getTags;
