import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import Form from 'react-bootstrap/Form';
import Modal from '../../../widgets/Modal';



const TimeTrackerModal = ({
  onClose,
  isOpen,
  onSubmit,
  task,
}) => {
  const [ time, setTime ] = useState(task.testing_time);
  const lang = useSelector((store) => store.language.lang);
  const inputRef = useRef(null);

  useEffect(() => {
    setTime(task.testing_time);
  }, [ task.testing_time ]);

  useEffect(() => {
    if (isOpen && inputRef.current) {
      inputRef.current.focus();
    }
  }, [ isOpen ]);

  return (
    <Modal
      title={lang['TASK.UPDATE_TRACKING_TIME']}
      close={onClose}
      autoheight
      show={isOpen}
      bodyStyle={{ overflowY: 'auto' }}
      footer={false}
    >
      <div>
        <Form
          onSubmit={(event) => {
            event.preventDefault();
            onSubmit(time, task);
          }}
        >
          <Form.Control
            ref={inputRef}
            type="number"
            as="input"
            value={time}
            min={1}
            onChange={(event) => setTime(event.target.value)}
          />
          <div className="modal-footer">
            <>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={onClose}
              >{lang['GLOBAL.CANCEL']}</button>
              <button
                type="submit"
                disabled={time < 1}
                className="btn btn-success"
              >{lang['GLOBAL.APPLY']}</button>
            </>
          </div>
        </Form>
      </div>
    </Modal>
  );
};

TimeTrackerModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  task: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
};

TimeTrackerModal.defaultTypes = {
  task: {
    testing_time: 1,
  },
};

export default TimeTrackerModal;
