import React, { useCallback, useEffect } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import urlPageDashboard from '../../../urls/urlPageDashboard';
import { parseFromPars, parseToPars } from '../../../utils/parseUrlParams';
import checkRole from '../../../utils/checkRole';
import roles from '../../customers/roles/roles';



const GroupsNavigation = () => {
  const location = useLocation();
  const history = useHistory();
  const { lang, groups, user, userRoles } = useSelector(({ language, groups, user }) => ({
    lang: language.lang,
    groups: groups.groups,
    user: user.user,
    userRoles: user.roles,
  }), shallowEqual);

  useEffect(() => {
    const queryParams = parseFromPars(location.search);
    const isAdminOrLeadEditor = checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ]);
    const isGroupDefined = queryParams.hasOwnProperty('group_id');
    const isUserMatchGroup = user.groups.includes(queryParams.group_id);

    if (isGroupDefined && !isAdminOrLeadEditor && !isUserMatchGroup) {
      const pars = parseToPars({
        ...queryParams,
        group_id: user?.groups[0] ?? 0,
      });

      history.push(location.pathname + pars);
    } else if (!isGroupDefined) {
      const pars = parseToPars({
        ...queryParams,
        group_id: user?.groups[0] ?? 0,
      });

      history.push(location.pathname + pars);
    }
  }, []);

  const isLinkActive = useCallback((group) => {
    const queryParams = parseFromPars(location.search);

    if (typeof queryParams.group_id === 'undefined') {
      queryParams.group_id = 0;
    }
    return group === queryParams.group_id;
  }, [ location ]);

  return (
    <>
      {checkRole(userRoles, [ roles.administrator.key, roles['lead-coloring-editor'].key ]) && (
        <div className="d-flex flex-row justify-content-end p-3">
          <Link to={`${urlPageDashboard()}?group_id=0`} className="pr-2">
            {isLinkActive(0) ? <b>{lang['DASHBOARD.ALL_GROUPS']}</b> : lang['DASHBOARD.ALL_GROUPS']}</Link>
          {groups.map((group) => {
            return (
              <Link
                className="pr-2"
                to={`${urlPageDashboard()}?group_id=${group.id}`}
                key={group.id}
              >
                {' | '}{isLinkActive(group.id) ? <b>{group.name}</b> : group.name}
              </Link>);
          })}
        </div>
      )}
    </>
  );
};

export default GroupsNavigation;
