import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Form } from 'react-bootstrap';
import { filter } from 'lodash';
import { useSelector } from 'react-redux';
import { MIN_MESSAGE_LENGTH_OF_RETURN_WORk } from '../../../const/project';
import DropFile from '../../widgets/DropFile';
import checkRole from '../../utils/checkRole';
import taskTypes from '../../pages/tasks/taskTypes';
import groupsRoles from '../../pages/customers/roles/groupsRoles';
import Modal from '../../widgets/Modal';
import { useGlobalModalContext } from '../GlobalModal';



// TODO: replace modal in TaskInfoModals after TaskInfo will be rewritten with functional component
const ReturnTaskModal = () => {
  const lang = useSelector((store) => store.language.lang);
  const { hideModal, store } = useGlobalModalContext();
  const { modalProps } = store || {};
  const { onSubmit, reasons, task } = modalProps || {};

  const [ message, setMessage ] = useState('');
  const [ returnFiles, setReturnFiles ] = useState([]);
  const [ curReasons, setCurReasons ] = useState([]);
  const [ testingTime, setTestingTime ] = useState([]);

  const handleModalToggle = () => {
    hideModal();
  };

  const submit = (event) => {
    event?.preventDefault();
    hideModal();
    if (curReasons.length > 0 || (message && message.trim().length >= MIN_MESSAGE_LENGTH_OF_RETURN_WORk)) {
      onSubmit(
        (
          (
            curReasons.length > 0
              ? `${this.props.reasons
                .filter((reason, index) => curReasons.includes(index))
                .join('. ')}. `
              : ''
          ) + message
        ),
        returnFiles,
        testingTime
      );
    }
  };

  return (
    <Modal
      show
      title={lang['TASK.RETURN_WORK']}
      close={handleModalToggle}
      footer={
        <>
          <Button
            variant={'secondary'}
            onClick={handleModalToggle}
          >
            {lang['GLOBAL.CLOSE']}
          </Button>
          <Button
            variant={'danger'}
            disabled={this.isReopenTaskDisabled()}
            onClick={submit}
          >
            {lang['GLOBAL.RETURN']}
          </Button>
        </>
      }
    >
      <form onSubmit={submit} >
        <input type='submit' className='d-none' />
        <div className="kt-portlet__body kt-portlet__body--fullheight">
          {reasons.length > 0 && (
            <Form.Group>
              <div className='row'>
                {reasons.map((reason, index) => {
                  const checked = curReasons.includes(index);

                  return (
                    <div key={reason && index} className='col-sm-6 col-12 d-flex align-items-center'>
                      <Form.Label htmlFor={`reason_${index}`}>
                        <Form.Check
                          key={reason && index}
                          inline
                          id={`reason_${index}`}
                          label={reason}
                          type='checkbox'
                          checked={checked}
                          className='kt-m-0'
                          onChange={() => {
                            setCurReasons(checked ? curReasons.filter((reason) => reason !== index) : [ ...this.state.curReasons, index ]);
                          }}
                        />
                      </Form.Label>
                    </div>
                  );
                })}
              </div>
            </Form.Group>
          )}
          <Form.Group>
            <Form.Label> {lang['GLOBAL.LEAVE_COMMENT']}</Form.Label>
            <Form.Control
              as={'textarea'}
              cols="30"
              rows="4"
              value={message}
              onChange={(event) => setMessage(event.target.value)}
            />
            <Form.Text>{lang['GLOBAL.LEAVE_COMMENT_PLEASE']}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Control
              as={DropFile}
              multiple
              theme='danger'
              className='h-auto'
              info={lang['TASK.ONE_OR_MORE_FILES']}
              curFiles={returnFiles}
              addFiles={(result) => setReturnFiles([ ...returnFiles, ...result ])}
              removeFile={(file) => setReturnFiles(filter(returnFiles, (item) => item !== file))}
            />
          </Form.Group>
          {checkRole([ taskTypes[task.type].role ], [ ...groupsRoles.testers.all ]) && <Form.Group>
            <Form.Label> {lang['TASK.TIME_TRACKING']}</Form.Label>
            <Form.Control
              value={testingTime}
              type="number"
              min="0"
              onChange={(event) => setTestingTime(event.target.value)}
            />
          </Form.Group>}
        </div>
      </form>
    </Modal>
  );
};

export default ReturnTaskModal;
