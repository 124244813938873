import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import KtContent from '../../../_metronic/layout/KtContent';
import LanguageSelector from '../../../_metronic/partials/layout/LanguageSelector';
import axiosApiInstance from '../../requests/utils/api';



const PublicOffer = () => {
  const { lang, curLang } = useSelector(({ language }) => ({
    lang: language.lang,
    curLang: language.cur,
  }), shallowEqual);
  const [ term, setTerm ] = useState({
    en: '',
    ru: '',
  });

  const getPublicOffer = async () => {
    try {
      // todo refactor. move to actions
      const response = await axiosApiInstance.get('terms');

      setTerm(response.data);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  useEffect(async () => {
    await getPublicOffer();
  }, []);

  return (
    <div
      id="kt_content"
      className="kt-content kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor"
    >
      <div className="kt-header__topbar" style={{ justifyContent: 'flex-end' }}>
        <LanguageSelector iconType="" />
      </div>
      <div className="kt-grid kt-grid--ver kt-grid--root kt-page">
        <KtContent>
          <div className="row">
            <div className="col-xl-12">
              <div className="kt-portlet kt-portlet--height-fluid">
                <div className="kt-portlet__head no-print w-100">
                  <div className="kt-portlet__head-label no-print w-100">
                    <h1 className="text-center w-100">
                      {lang['TOUR.REGISTRATION.PUBLIC_OFFER']}
                    </h1>
                  </div>
                </div>
                <div className="kt-portlet__body">
                  <div className="row w-100">
                    <div
                      className="col-lg-12"
                      dangerouslySetInnerHTML={{ __html: term[curLang] }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </KtContent>
      </div>
    </div>
  );
};

export default PublicOffer;
