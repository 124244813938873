import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';



const actions = {
  SET_TERM: 'SET_TERM',
  TERMS_LOADING: 'TERMS LOADING',
  toggleLoadingTerms: (payload) => ({
    type: actions.TERMS_LOADING,
    payload,
  }),
  setTerm: (payload) => ({ type: actions.SET_TERM, payload }),
  acceptTerm: (id) => async (dispatch, getState, api) => {
    const { term, language: { lang } } = getState();

    try {
      dispatch(actions.toggleLoadingTerms(true));
      const URL = `terms/${id}/accept`;

      await api.post(URL);
      dispatch(actions.setTerm({
        ...term,
        isAccepted: true,
      }));
    } catch {
      // todo fix for routes layer
      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.ACCEPT_TERMS_FAILED'],
          NOTIFICATIONS_TYPES.error
        )
      );
    } finally {
      dispatch(actions.toggleLoadingTerms(false));
    }
  },
};

export default actions;

