import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Form } from 'react-bootstrap';
import Modal from '../../../../widgets/Modal';
import MySelect from '../../../../widgets/MySelect';



const CreateTagConfirmationModal = ({ show, onConfirm, onCancel, context }) => {
  const [ parentTag, setParentTag ] = useState(null);
  const lang = useSelector((state) => state.language.lang);

  useEffect(() => {
    return () => {
      setParentTag(null);
    };
  }, []);

  if (context.tagData?.group?.isDependent) {
    const parentOptions = () => {
      const parentSubtype = context.tagData.group?.parentSubtype;

      if (!parentSubtype) {
        return [];
      }

      const parentGroup = context.groups?.find((item) => item.subtype === parentSubtype);

      if (!parentGroup) {
        return [];
      }

      return parentGroup?.tags?.map((tag) => {
        return {
          label: tag?.name,
          value: tag?.id,
        };
      });
    };

    const onCreateChildTag = () => {
      onConfirm({
        parents: [ parentTag?.value, context.tagData?.group?.id ],
      });
    };

    return (
      <Modal
        title={lang['TAG_SYSTEM.PICTURE.CONFIRM_TO_CREATE_TAG']}
        show={show}
        close={onCancel}
        className="confirm-child-tag"
        footer={
          <>
            <button
              className="btn btn-secondary"
              onClick={onCancel}
            >{lang['GLOBAL.CANCEL']}</button>
            <button
              disabled={!parentTag}
              className="btn btn-success"
              onClick={onCreateChildTag}
            >{lang['GLOBAL.APPLY']}</button>
          </>
        }
      >
        <div style={{ position: 'relative', minHeight: '100px' }}>
          <div style={{ position: 'absolute', top: 0, left: 0, width: '100%' }}>
            <Form.Label>
              {lang['TAG_SYSTEM.PICTURE.PARENT_TAG']}
            </Form.Label>
            <MySelect
              onChange={(tag) => {
                setParentTag(tag);
              }}
              isClearable
              options={parentOptions()}
            />
          </div>
        </div>
      </Modal>
    );
  }

  return (
    <Modal
      title={lang['TAG_SYSTEM.PICTURE.CONFIRM_TO_CREATE_TAG']}
      show={show}
      close={onCancel}
      footer={
        <>
          <button
            className="btn btn-secondary"
            onClick={onCancel}
          >{lang['GLOBAL.CANCEL']}</button>
          <button
            className="btn btn-success"
            onClick={onConfirm}
          >{lang['GLOBAL.APPLY']}</button>
        </>
      }
    />
  );
};

CreateTagConfirmationModal.propTypes = {
  context: PropTypes.shape({
    tagData: PropTypes.shape({
      data: PropTypes.string,
      group: PropTypes.object,
      fieldName: PropTypes.string,
    }),
    groups: PropTypes.array.isRequired,
  }),
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
};

export default CreateTagConfirmationModal;
