import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { intersection } from 'lodash';
import PropTypes from 'prop-types';
import { getRolesFromGroupsUsers } from '../customers/roles/groupsUsers';
import roles, { realRoles } from '../customers/roles/roles';
import Preload from '../../widgets/Preload';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import axiosApiInstance from '../../requests/utils/api';
import { notification } from '../../requests/notifications';
import urlPageManualsCreate from '../../urls/urlPageManualsCreate';
import urlPageManualEdit from '../../urls/urlPageManualEdit';
import HtmlEditor from '../../components/HtmlEditor';
import manualActions from '../../store/manuals/actions';
import usePrevious from '../../hooks/usePrevious';



const propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
};

const initManual = {
  name: '',
  role: '',
  roles: [],
  data: '',
  is_published: false,
};

const ManualCreate = ({ match, history }) => {
  const { lang } = useSelector((store) => store.language);
  const { groups: groupsRoles } = useSelector((store) => store.user);
  const { loading: LOAD, manual } = useSelector((store) => store.manuals);
  const prevProps = usePrevious({
    url: match.url,
    paramId: match.params.id,
  });
  const dispatch = useDispatch();

  const [ newManual, setNewManual ] = useState({
    name: '',
    role: '',
    roles: [],
    data: '',
    is_published: false,
  });
  const [ isEdit, setIsEdit ] = useState(false);
  const [ showInvalid, setShowInvalid ] = useState(false);

  useEffect(async () => {
    if (match.path === urlPageManualEdit()) {
      setIsEdit(true);
      await dispatch(manualActions.getManual(`manuals/${match.params.id}`));
      setNewManual(manual);
    }
  }, []);

  useEffect(() => {
    if (match.url !== prevProps?.url) {
      if (match.path === urlPageManualEdit() && match.params.id !== prevProps?.paramId) {
        setIsEdit(true);
        setNewManual({ ...initManual });
        dispatch(manualActions.getManual(`manuals/${match.params.id}`));
      } else if (match.path === urlPageManualsCreate()) {
        setIsEdit(false);
        setNewManual({ ...initManual });
      }
    }
  }, [ match ]);

  const saveManual = async () => {
    const manual = newManual;
    const fd = new FormData();

    if (isEdit) {
      fd.append('_method', 'PUT');
      fd.append('type', 'form');
    }

    fd.append('name', manual.name);
    fd.append('role', manual.role);
    fd.append('data', manual.data);
    fd.append('is_published', manual.is_published ? '1' : '0');

    const url = isEdit ? `manuals/${manual.id}/form` : 'manuals';

    try {
      await axiosApiInstance.post(url, fd);
      const notificationMessage = (isEdit)
        ? 'NOTIFICATION.SUCCESS_MANUAL_EDIT' : 'NOTIFICATION.SUCCESS_MANUAL_CREATE';

      dispatch(notification(notificationMessage, 'success'));

      history.push(isEdit ? `/manuals/${manual.id}` : '/manuals');
    } catch (error) {
      if (error.response) {
        const notificationMessage = (isEdit)
          ? 'NOTIFICATION.ERROR_MANUAL_EDIT' : 'NOTIFICATION.ERROR_MANUAL_CREATE';

        dispatch(notification(notificationMessage, 'error'));
      }
    }
  };

  const onSubmit = async (event) => {
    event.preventDefault();
    if (newManual.name.trim() && newManual.role) {
      await saveManual();
      setShowInvalid(false);
    } else {
      setShowInvalid(true);
    }
  };

  return LOAD ? (
    <Preload />
  ) : (
    <>
      <SubHeader />
      <div className="row">
        <div className="offset-xl-3 col-xl-6 offset-lg-2 col-lg-8">
          <div className="kt-portlet">
            <form
              className="kt-form"
              onSubmit={onSubmit}
            >
              <div className="kt-portlet__body">
                <Form.Group>
                  <Form.Label>{lang['MANUAL.TITLE']}</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={showInvalid && !newManual.name.trim()}
                    value={newManual.name}
                    onChange={(event) => {
                      setNewManual((prevState) => ({
                        ...prevState,
                        name: event.target.value,
                      }));
                    }}
                  />
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                  <Form.Text type="text-muted">
                    {lang['MANUAL.TITLE_FOR_FREELANCERS']}
                  </Form.Text>
                </Form.Group>
                <Form.Group>
                  <Form.Label>
                    {lang['MANUAL.FOR_ROLE']}
                  </Form.Label>
                  <Form.Control
                    as={'select'}
                    isInvalid={!newManual.role && showInvalid}
                    value={newManual.role || ''}
                    onChange={(event) => {
                      setNewManual((prevState) => ({
                        ...prevState,
                        role: event.target.value,
                      }));
                    }}
                  >
                    <option disabled value=''>{lang['GLOBAL.CHOOSE']}</option>
                    {intersection(getRolesFromGroupsUsers(groupsRoles), realRoles).map((key) => {
                      return <option key={key} value={key}>{roles[key].title}</option>;
                    })}
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    {lang['GLOBAL.FIELD_REQUIRED']}
                  </Form.Control.Feedback>
                  <Form.Text type="text-muted">
                    {lang['MANUAL.ROLE_FOR_USEFUL']}
                  </Form.Text>
                </Form.Group>
                <div className='form-group row'>
                  <div className='col-12'>
                    <HtmlEditor
                      onChange={(value) => {
                        setNewManual((prevState) => ({
                          ...prevState,
                          data: value,
                        }));
                      }}
                      initialValue={newManual.data}
                    />
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">{lang['GLOBAL.STATUS']}:</label>
                  <div className="col-lg-6">
                    <div className="kt-checkbox-inline">
                      <label className="kt-checkbox">
                        <input
                          type="checkbox"
                          checked={newManual.is_published}
                          onChange={() => {
                            setNewManual((prevState) => ({
                              ...prevState,
                              is_published: !prevState.is_published,
                            }));
                          }}
                        /> {lang['MANUAL.PUBLISH']}<span />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="kt-portlet__foot">
                <div className="kt-form__actions">
                  <Button type='submit' variant='primary'>{lang['GLOBAL.SAVE']}</Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>);
};

ManualCreate.propTypes = propTypes;

export default ManualCreate;
