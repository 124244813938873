import queryString from 'query-string';
import axiosApiInstance from '../../../requests/utils/api';
import { actionsNotifications } from '../../notification';
import actionsPictures from '../pictures/actions';
import { NOTIFICATIONS_TYPES } from '../../../widgets/Notification';
import trimObjectStringProps from '../../../utils/trimObjectStringProps';
import { tagSystemPath } from '../helpers';
import handleResponseMessage from '../../../utils/handleResponseMessage';
import groupActions from '../groups/actions';



const actions = {
  LOADING: 'LOADING_TAGS',
  TAG_SYSTEM_ADD_TAG: 'TAG_SYSTEM_ADD_TAG',
  TAG_SYSTEM_SET_TAGS: 'TAG_SYSTEM_SET_TAGS',
  TAG_SYSTEM_SET_AI_TAGS: 'TAG_SYSTEM_SET_AI_TAGS',
  TAG_SYSTEM_UPDATE_TAG: 'TAG_SYSTEM_UPDATE_TAG',
  TAG_SYSTEM_DELETE_TAG: 'TAG_SYSTEM_DELETE_TAG',
  setLoading: (payload) => ({
    type: actions.LOADING,
    payload,
  }),
  addTag: (payload) => ({
    type: actions.TAG_SYSTEM_ADD_TAG,
    payload,
  }),
  setTags: (payload) => ({
    type: actions.TAG_SYSTEM_SET_TAGS,
    payload,
  }),
  setAITags: (payload) => ({
    type: actions.TAG_SYSTEM_SET_AI_TAGS,
    payload,
  }),
  removeTag: (payload) => ({
    type: actions.TAG_SYSTEM_DELETE_TAG,
    payload,
  }),
  getTags: () => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoading(true));
      const URL = `${tagSystemPath}/entities?type=tag&include_children=true`;
      const data = await axiosApiInstance.get(URL);

      dispatch(actions.setTags(data?.data || []));
    } catch {
      const store = getState();

      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_TAGS'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  getAITags: (params) => async (dispatch, getState) => {
    try {
      dispatch(actions.setLoading(true));
      const queryParams = queryString.stringify(params, { arrayFormat: 'bracket' });

      const URL = `${tagSystemPath}/ai/entities?${queryParams}`;
      const data = await axiosApiInstance.get(URL);

      dispatch(actions.setAITags(data?.data || []));
    } catch {
      const store = getState();

      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_GET_AI_TAGS'], NOTIFICATIONS_TYPES.error));
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  createTag: (newTag, groupID = null, parentID = null) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true));
    const store = getState();

    try {
      const URL = `${tagSystemPath}/entities`;
      const data = await axiosApiInstance.post(URL, trimObjectStringProps(newTag));
      const tag = data.data;

      dispatch(actions.addTag({
        tag,
        parentID,
      }));
      if (!parentID) {
        dispatch(groupActions.addTagToGroup({
          group_id: groupID,
          tag,
        }));
      }
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_TAG_CREATE'], NOTIFICATIONS_TYPES.success));

      if (groupID) {
        dispatch(actionsPictures.updatePictureStructures({ tag, groupID }));
      }

      if (newTag?.parents?.length > 0) {
        dispatch(actionsPictures.updatePictureStructures({ tag, groupID: newTag?.parents }));
      }

      return tag;
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_TAG_CREATE']),
          NOTIFICATIONS_TYPES.error
        ));
      }

      throw error;
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  updateTag: (newTag) => async (dispatch, getState) => {
    dispatch(actions.setLoading(true));
    const store = getState();

    try {
      const URL = `${tagSystemPath}/entities/${newTag.id}`;
      const updatedTag = await axiosApiInstance.put(URL, trimObjectStringProps(newTag));

      dispatch({
        type: actions.TAG_SYSTEM_UPDATE_TAG,
        payload: updatedTag.data,
      });
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_TAG_EDIT'], NOTIFICATIONS_TYPES.success));
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_TAG_EDIT']),
          NOTIFICATIONS_TYPES.error
        ));
      }

      throw error;
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
  deleteTag: (tagId, parentId) => async (dispatch, getState) => {
    const store = getState();

    dispatch(actions.setLoading(true));

    try {
      const URL = `${tagSystemPath}/entities/${tagId}`;

      await axiosApiInstance.delete(URL);
      dispatch(actions.removeTag({
        tagId,
      }));
      dispatch(groupActions.removeTagFromGroup({
        tagId,
        groupId: parentId,
      }));
      dispatch(actionsNotifications.add(store.language.lang['TAG_SYSTEM.NOTIFICATION.SUCCESS_TAG_DELETE'], NOTIFICATIONS_TYPES.success));

      return true;
    } catch (error) {
      if (error.response) {
        dispatch(actionsNotifications.add(
          handleResponseMessage(error.response, store.language.lang['TAG_SYSTEM.NOTIFICATION.ERROR_TAG_DELETE']),
          NOTIFICATIONS_TYPES.error
        ));
      }
    } finally {
      dispatch(actions.setLoading(false));
    }
  },
};

export default actions;
