export const CARGO_TYPE = 'cargo';
export const SHARP_TYPE = 'sharp';
export const BLEND_TYPE = 'blend';

/**
 * Image types
 *
 * @type {string[]}
 */
const imageType = [ SHARP_TYPE, BLEND_TYPE, CARGO_TYPE ];

export default imageType;
