import { getStorage, setStorage, removeStorage } from '../../_metronic';
import urlPageLogout from '../urls/urlPageLogout';



const localStorageLastLocationKey = 'demo1-lastLocation';

/**
 * acceptLocation func
 *
 * @param {object} lastLocation
 * @returns {*}
 */
const acceptLocation = (lastLocation) => {
  return !!(lastLocation
        && lastLocation.pathname
        && lastLocation.pathname !== '/'
        && lastLocation.pathname.indexOf('auth') === -1
        && lastLocation.pathname !== urlPageLogout());
};

/**
 * saveLastLocation func
 *
 * @param {object} lastLocation
 * @returns {*}
 */
export const saveLastLocation = (lastLocation) => {
  const localStorageLocations = getStorage(localStorageLastLocationKey);
  const useLocations = localStorageLocations
    ? JSON.parse(localStorageLocations)
    : [];

  if (acceptLocation(lastLocation)) {
    useLocations.push(lastLocation.pathname);
    setStorage(
      localStorageLastLocationKey,
      JSON.stringify(useLocations),
      120,
    );
  }
};

/**
 * forgotLastLocation func
 *
 * @returns {*}
 */
export const forgotLastLocation = () => {
  removeStorage(localStorageLastLocationKey);
};

/**
 * getLastLocation func
 *
 * @returns {*}
 */
export const getLastLocation = () => {
  const localStorageLocations = getStorage(localStorageLastLocationKey);

  if (!localStorageLocations) {
    return '/';
  }

  const userLocations = JSON.parse(localStorageLocations);

  return userLocations.length > 0 ? userLocations.pop() : '/';
};
