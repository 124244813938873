import requestPriority from '../_request_priority';



export const STORAGE_TIMES_REQUESTS = 'LAST_GETTING';

/**
 * Gets delays request from local storage
 *
 * @returns {object}
 */
const getTimesRequests = () => {
  try {
    return localStorage.getItem(STORAGE_TIMES_REQUESTS)
      ? JSON.parse(localStorage.getItem(STORAGE_TIMES_REQUESTS))
      : {};
  } catch (error) {
    // eslint-disable-next-line no-console
    return console.error(error);
  }
};

const TIMES_REQUESTS = getTimesRequests();

/**
 * Checks if the cache is expired
 *
 * @param {string} nameRequest
 * @param {string} url
 * @returns {boolean}
 */
export const checkDelay = (nameRequest, url = '') => {
  // eslint-disable-next-line no-process-env
  const low = process.env.REACT_APP_PRIORITIES_TIMES_LOW;
  // eslint-disable-next-line no-process-env
  const medium = process.env.REACT_APP_PRIORITIES_TIMES_MEDIUM;
  // eslint-disable-next-line no-process-env
  const high = process.env.REACT_APP_PRIORITIES_TIMES_HIGH;

  const PRIORITIES_TIMES = {
    low,
    medium,
    high,
  };

  // eslint-disable-next-line no-process-env
  const delay = PRIORITIES_TIMES[requestPriority[nameRequest]] || process.env.REACT_APP_PRIORITIES_TIMES_MEDIUM;
  const lastRequest = TIMES_REQUESTS[url] || 0;

  return lastRequest + delay < Date.now();
};

let update = null;

/**
 * Saves the date of the last request by url
 *
 * Writes to the store only 5 seconds after saving so
 * that a bunch of simultaneous requests do not parse
 * in JSON and do not overwrite the store
 *
 * @param {string} url
 */

export const setDelay = (url) => {
  TIMES_REQUESTS[url] = Date.now();
  if (update === null) {
    update = setTimeout(() => {
      localStorage.setItem(STORAGE_TIMES_REQUESTS, JSON.stringify(TIMES_REQUESTS));
      update = null;
    }, 5000);
  }
};
