import { entityTypes } from '../../../pages/tagSystem/config/config';
import actions from './actions';



const initialState = {
  loading: false,
  loading_structure: false,
  pictureStructures: [],
  picture: {},
};

export const TagSystemPicturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOADING: {
      return {
        ...state,
        loading: action.payload,
      };
    }
    case actions.LOADING_STRUCTURE: {
      return {
        ...state,
        loading_structure: action.payload,
      };
    }
    case actions.SET_PICTURE: {
      return {
        ...state,
        picture: action.payload,
      };
    }
    case actions.SET_PICTURE_STRUCTURE: {
      return {
        ...state,
        pictureStructures: action.payload,
      };
    }
    case actions.UPDATE_PICTURE_STRUCTURE: {
      const { tag } = action.payload;
      const tagGroup = tag.parents?.find((tag) => tag.type === entityTypes.group);
      const tagParent = tag.parents?.find((tag) => tag.type === entityTypes.tag);

      tag.parent_id = tagParent?.id;

      const structures = state.pictureStructures.map((item) => {
        const groups = item.groups.map((group) => {
          if (Number(group.id) === Number(tagGroup?.id)) {
            return {
              ...group,
              tags: [
                ...group.tags,
                tag,
              ],
            };
          }
          return group;
        });

        return {
          ...item,
          groups,
        };
      });

      return {
        ...state,
        pictureStructures: [ ...structures ],
      };
    }
    case actions.UPDATE_PICTURE: {
      return {
        ...state,
        tagGroups: state.tagGroups.map((group) => {
          if (group.id === action.payload.id) {
            return {
              ...group,
              ...action.payload,
            };
          }
          return group;
        }),
      };
    }
    default: {
      return state;
    }
  }
};
