import React from 'react';
import roles from "../../../app/pages/customers/roles/roles";
import taskTypes from "../../../app/pages/tasks/taskTypes";
import {v4 as uuidv4} from "uuid";
import {DateTime} from "luxon";
import {useSelector} from "react-redux";
import urlPageProjectDetails from "../../../app/urls/urlPageProjectDetails";
import urlPageTaskDetails from "../../../app/urls/urlPageTaskDetails";

const NotificationList = ({notifications}) => {
  const userRoles = useSelector((store) => store.user.roles);
  const currentRole = useSelector((store) => store.user.currentRole);
  const lang = useSelector((store) => store.language.lang);

  const getLink = (notification) => {
    if (userRoles.includes(roles['coloring-editor'].key) || userRoles.includes(roles['content-manager'].key)) {
      return urlPageProjectDetails({projectId: notification.project_id});
    }

    if (userRoles.includes(roles['coloring-tester'].key)) {
      return urlPageTaskDetails({taskId: notification.tasks[taskTypes.device_testing.type]});
    }

    return urlPageTaskDetails({taskId: notification.tasks[currentRole === roles['coloring-designer'].key ? taskTypes.designer_coloring.type : taskTypes.artist_drawing.type]});
  }

  return (
    <div
      className="kt-notification kt-mt-10 kt-mb-10"
      style={{maxHeight: '40vh', position: 'relative'}}
    >
      {
        notifications.filter((notification) => {
          if (currentRole === roles['coloring-designer'].key) {
            return !!notification.tasks[taskTypes.designer_coloring.type];
          } else if (currentRole === roles['coloring-artist'].key) {
            return !!notification.tasks[taskTypes.artist_drawing.type];
          }

          return true;
        }).map((notification) => {
          return (
            <div
              key={uuidv4()}
              className="kt-notification kt-mt-10 kt-mb-10 kt-scroll"
              data-scroll="true"
              data-height="300"
              data-mobile-height="200"
            >
              <a className="kt-notification__item" href={getLink(notification)}>
                <div className="kt-notification__item-icon">
                  <i
                    className={`fa ${notification.is_read ? 'fa-envelope-open kt-font-success' : 'fa-envelope kt-font-danger'}`}/>
                </div>
                <div className="kt-notification__item-details">
                  <div className="kt-notification__item-title">
                    {lang['NOTIFICATION.MESSAGE_FROM']}{' '}{notification.comment_from} ...
                  </div>
                  <div className="kt-notification__item-time">
                    {DateTime.fromSQL(notification.comment_created_at).toRelative()}
                  </div>
                </div>
              </a>
            </div>
          );
        })
      }
    </div>
  );
};

export default NotificationList;
