import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';



const actions = {
  SET_POLICY: 'SET POLICY',
  POLICY_LOADING: 'POLICY LOADING',
  setPolicy: (payload) => ({
    type: actions.SET_POLICY,
    payload,
  }),
  toggleLoadingPolicy: (payload) => ({
    type: actions.POLICY_LOADING,
    payload,
  }),
  acceptPolicy: (id) => async (dispatch, getState, api) => {
    const { policy, language: { lang } } = getState();

    try {
      dispatch(actions.toggleLoadingPolicy(true));

      await api.post(`policies/${id}/accept`);
      dispatch(actions.setPolicy({
        ...policy,
        isAccepted: true,
      }));
    } catch {
      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.ACCEPT_POLICY_FAILED'],
          NOTIFICATIONS_TYPES.error
        )
      );
    } finally {
      dispatch(actions.toggleLoadingPolicy(false));
    }
  },
};

export default actions;

