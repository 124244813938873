import React from 'react';
import PropTypes from 'prop-types';
import './MainStats.css';
import clsx from 'clsx';
import { Loader } from '../../../../components/Loader';



const variants = {
  primary: 'primary',
  danger: 'danger',
  warning: 'warning',
};

const MainStats = ({ value, title, variant, onClick, loading }) => {
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={`main-stat ml-2 mb-3 flex justify-content-center text-white ${clsx({
        'bg-primary': variant === variants.primary,
        'bg-danger': variant === variants.danger,
        'bg-warning': variant === variants.warning,
        'cursor-pointer': typeof onClick === 'function' && value > 0,
      })}`}
      onClick={() => {
        if (value > 0) {
          onClick();
        }
      }}
    >
      {loading ? <Loader /> : (
        <>
          <div>
            <p className="text-xxl text-center">{value}</p>
          </div>
          <div>
            <p className="text-md text-center">{title}</p>
          </div>
        </>
      )}
    </div>
  );
};

MainStats.propTypes = {
  value: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([ 'danger', 'warning', 'primary' ]),
  onClick: PropTypes.func,
  loading: PropTypes.bool,
};

MainStats.defaultProps = {
  variant: 'primary',
  loading: false,
};

export default MainStats;
