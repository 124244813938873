export const getPriceByContourComplexityLevel = (complexityLevel) => {
  switch (Number(complexityLevel)) {
    case 1:
      return 16;
    case 2:
      return 25;
    case 3:
      return 28;
    case 4:
      return 50;
    default:
      return 16;
  }
};

export const getPriceByColorComplexityLevel = (complexityLevel) => {
  switch (Number(complexityLevel)) {
    case 1:
      return 13;
    case 2:
      return 17;
    case 3:
      return 23;
    case 4:
      return 34;
    default:
      return 13;
  }
};

export const contourComplexityLevels = [ '1', '2', '3', '4' ];
export const colorComplexityLevels = [ '1', '2', '3', '4' ];
export const highestComplexityLevel = 4;
