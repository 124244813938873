import actions from './actions';



const initialState = {
  policy: null,
  isAccepted: true,
  policy_id: null,
  isLoading: false,
};

export const policyReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.POLICY_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case actions.SET_POLICY:
      return {
        ...state,
        ...action.payload,
      };

    default:
      return state;
  }
};
