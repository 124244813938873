import React from 'react';
import Table from 'react-bootstrap/Table';
import TaskHeaderTable from '../tasks/list/TaskHeaderTable';
import TaskItemTable from '../tasks/list/TaskItemTable';
import tasks from './tasks';



class TutorialTasksList extends React.Component {
  state = {
    tasksList: tasks.artist,
  };

  render () {
    const { tasksList } = this.state;

    return (
      <div className='kt-portlet mw-100 overflow-auto table-wrapper'>
        <Table
          striped
          className='vertical-align-middle text-center kt-font-md'
          size="sm"
        >
          <TaskHeaderTable
            canSort={false}
          />
          <tbody>
            {tasksList.map((task) => {
              return (
                <TaskItemTable
                  key={task.id}
                  task={task}
                  showExecutor={false}
                />
              );
            })}
          </tbody>
        </Table>
      </div>
    );
  }
}

export default TutorialTasksList;
