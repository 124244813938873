import { Form } from 'react-bootstrap';
import React from 'react';
import HtmlEditor from '../../../components/HtmlEditor';
import config from '../../../../config/app';

/**
 * Builds a textarea for a field
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, value = null, onChange = null, project = null, required) {
  if (!project) {
    project = this.state;
  }
  const { lang } = this.props;

  return (
    <Form.Group
      as={Form.Row} key={field.key}
      style={{
        marginBottom: '1rem',
        ...(field.isFull ? {} : {
          maxWidth: 1024,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      }}
    >
      <div className={field.isColumn ? 'col-12' : 'col-lg-3 col-12'}>
        <Form.Label column>
          {(lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '')}
          {field.isRequired && required && (
            <span style={{ color: 'red' }}>{' *'}</span>
          )}
        </Form.Label>
      </div>
      <div className={field.isColumn ? 'col-12 d-flex flex-column z-index-0' : 'col-lg-9 col-12 d-flex flex-column z-index-0'}>
        <HtmlEditor
          onChange={(value) => {
            if (onChange === null) {
              this.setState({
                project: {
                  ...project,
                  [field.key]: value,
                },
              });
            } else {
              onChange(value);
            }
          }}
          initialValue={(value === null ? project[field.key] : value) || ''}
          init={{
            license_key: config.tinyEditorApiKey,
            plugins: 'preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen link table charmap pagebreak nonbreaking advlist lists wordcount help charmap emoticons',
            toolbar: 'undo redo | bold italic underline fontsizeselect numlist bullist | fontselect formatselect strikethrough | alignleft aligncenter alignright alignjustify | outdent indent | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview',
            toolbar_mode: 'sliding',
            menubar: false,
            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          }}
        />
        {field.muted && (
          <Form.Text className="text-muted">
            {field.muted}
          </Form.Text>
        )}
        <Form.Control.Feedback type="invalid">
          {lang['GLOBAL.FIELD_REQUIRED']}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}
