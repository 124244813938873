import React from 'react';
import { Link } from 'react-router-dom';
import Badge from 'react-bootstrap/Badge';
import { uniq } from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import { shallowEqual, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Img from '../../../../widgets/Img';
import imgURL from '../../../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../../../projects/getPreviewFromProject';
import { formatToPrettyDate, formatToPrettyDateOrTime } from '../../../../utils/formats';
import { getBadgeVariantForDeadline } from '../../../../utils/getClassesForDeadline';
import { TASK_BONUS_STATUS } from '../../../../../const/tasks';
import { priorityToColor } from '../../../projects/priorities';
import urlPageTaskDetails from '../../../../urls/urlPageTaskDetails';
import { isSameDomain } from '../../../../utils/isSameDomain';
import urlPageProfile from '../../../../urls/urlPageProfile';
import urlPageFreelancerDetails from '../../../../urls/urlPageFreelancerDetails';



const TaskItem = ({ item }) => {
  const { lang, user, users } = useSelector(({ language, user, users }) => ({
    lang: language.lang,
    user: user.user,
    users,
  }), shallowEqual);

  const showPreviousExecutor = () => {
    const prevExecutor = users.find((prevUser) => prevUser.id === item.prev_executor);

    return isSameDomain(user?.provider_id, prevExecutor?.provider_id) ?
      <Link
        to={item.prev_executor === user.id ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: item.prev_executor })}
        className="font-weight-bolder kt-font-md text-dark hover"
      >
        {prevExecutor?.username}
      </Link> : <span>{prevExecutor?.username}</span>;
  };

  const showCurrentExecutor = () => {
    const executor = users.find((prevUser) => prevUser.id === item.executor_id);

    let executorElem = '';

    if (item.executor_id !== user.id) {
      executorElem = isSameDomain(user?.provider_id, executor?.provider_id) ? (
        <Link
          to={item.executor_id === user.id ? urlPageProfile() : urlPageFreelancerDetails({ freelancerId: item.executor_id })}
          className="font-weight-bolder kt-font-md text-dark hover"
        >
          {executor?.username}
        </Link>
      ) : <span>{executor?.username}</span>;
    }

    return (
      <>
        {item.executor_id === user.id && (
          <Badge variant="success">
            {this.props.lang['TASK.FOR_YOU']}
          </Badge>
        )}
        {executorElem}
      </>
    );
  };

  return (
    <tr>
      <td>
        <span className="kt-media kt-media--xxxl">
          <Img
            src={imgURL(getPreviewSm(item.preview || item.links))}
            current={{
              id: item.id,
              link: getPreviewLg(item.preview || item.links),
            }}
            canFull
          />
        </span>
      </td>
      <td>
        <Link to={urlPageTaskDetails({ taskId: item.id })} className="text-dark hover">
          {item.project_id}
        </Link>
      </td>
      <td>
        <Link to={urlPageTaskDetails({ taskId: item.id })} className="text-dark hover">
          {item.title}
        </Link>
      </td>
      <td className="kt-word-break">
        {lang[`TASK.TYPES.${item.type}`]}
      </td>
      <td>
        <Badge variant='secondary'>
          {item.taps || '---'}
        </Badge>
      </td>
      <td>
        <Badge variant='danger'>
          {item.difficult || '---'}
        </Badge>
      </td>
      <td>
        <Badge
          variant="dark"
        >
          {(lang[`PROJECT.FIELDS.type.${(item.image_type || '').toLowerCase()}`] || '').toUpperCase()}
        </Badge>
      </td>
      <td style={{ maxWidth: '200px' }}>
        {item.tags && uniq(item.tags).map((tag) => (
          <Badge
            variant="secondary"
            key={uuidv4()}
            className={'kt-mr-5 kt-mb-5'}
          >
            {tag.name}
          </Badge>
        ))}
      </td>
      <td>
        <Badge variant="info">
          {formatToPrettyDate(item.started_at) || '---'}
        </Badge>
      </td>
      <td>
        <Badge variant="info">
          {formatToPrettyDate(item.finished_at) || '---'}
        </Badge>
      </td>
      <td>
        <Badge variant="info">
          {formatToPrettyDate(item.paid_at) || '---'}
        </Badge>
      </td>
      <td>
        <Badge variant={getBadgeVariantForDeadline(item.deadline_to)}>
          {formatToPrettyDateOrTime(item.deadline_to) || '---'}
        </Badge>
      </td>
      <td>
        <Badge variant={getBadgeVariantForDeadline(item.project_deadline_to)}>
          {formatToPrettyDateOrTime(item.project_deadline_to) || '---'}
        </Badge>
      </td>
      <td>
        {item.bonus.status !== TASK_BONUS_STATUS.CANCELED && (
          <>
            <br />
            {item.bonus.amount ? `${item.bonus.amount}€` : ''}
          </>
        )}
      </td>
      <td>
        <Badge variant={priorityToColor(item.priority)}>
          {lang[`GLOBAL.PRIORITY.${item.priority}`]}
        </Badge>
      </td>
      <td>{showPreviousExecutor()}</td>
      <td>{showCurrentExecutor()}</td>
      <td>
        <OverlayTrigger
          placement="bottom"
          overlay={(
            <Tooltip id="taskViewTable">
              {lang['GLOBAL.VIEW']}
            </Tooltip>
          )}
        >
          <Button
            as={Link}
            to={urlPageTaskDetails({ taskId: item.id })}
            variant="primary"
            size="sm"
            className="text-nowrap kt-margin"
          >
            <i className="fa fa-eye kt-m-0" />
          </Button>
        </OverlayTrigger>
      </td>
    </tr>
  );
};

TaskItem.propTypes = {
  item: PropTypes.object.isRequired,
};

export default TaskItem;

