import React from 'react';
import PropTypes from 'prop-types';
import Img from '../../../../widgets/Img';
import imgURL from '../../../../utils/imgURL';
import { getPreviewLg, getPreviewSm } from '../../../projects/getPreviewFromProject';



const WidgetMedia = ({ task }) => {
  const preview = () => {
    if (!task.preview) {
      const files = task.project?.files;

      const sourceFile = files?.find((item) => {
        return item.link.includes('source');
      });

      if (sourceFile) {
        return sourceFile.link;
      }

      return '';
    }

    return task.preview;
  };

  return (
    <div className="kt-widget__media">
      <Img
        src={imgURL(getPreviewSm(preview()))}
        current={{
          id: task.id,
          link: getPreviewLg(task.preview),
        }}
        canFull
      />
    </div>
  );
};

WidgetMedia.propTypes = {
  task: PropTypes.shape({
    preview: PropTypes.string,
    id: PropTypes.number.isRequired,
    project: PropTypes.object.isRequired,
  }).isRequired,
};

export default WidgetMedia;
