import React, { Suspense, useEffect, useState } from 'react';
import {
  Redirect, Route, Switch,
} from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { LayoutSplashScreen } from '../../../_metronic';
import MyProfile from '../customers/MyProfile';
import checkRole from '../../utils/checkRole';
import Suggestion from '../suggestion/Suggestion';
import roles from '../customers/roles/roles';
import ContentManagerDashboard from '../customers/ContentManagerDashboard';
import Profile from '../customers/Profile';
import Invitation from '../customers/Invitation';
import groupsRoles from '../customers/roles/groupsRoles';
import Tutorial from '../tutorial/Tutorial';
import { getUsersAll } from '../../requests/users';
import { getTags } from '../../requests/tags';
import urlPageDashboard from '../../urls/urlPageDashboard';
import urlPageProfile from '../../urls/urlPageProfile';
import urlPageInvitations from '../../urls/urlPageInvitations';
import urlPageError from '../../urls/urlPageError';
import urlPageSuggestion from '../../urls/urlPageSuggestion';
import urlPageFreelancerDetails from '../../urls/urlPageFreelancerDetails';
import PublicOffer from '../publicOffer/PublicOffer';
import actions from '../../store/groups/actions';
import configActions from '../../store/config/actions';
import categoryActions from '../../store/categories/actions';
import userActions from '../../store/user/actions';
import countriesActions from '../../store/countries/actions';
import ErrorsPage from '../errors/ErrorsPage';
import urlPageTasksAvailable from '../../urls/urlPageTasksAvailable';
import urlPageTagSystemPictures from '../../urls/urlPageTagSystemPictures';
import TagPicture from '../tagSystem/picture/TagPicture';
import urlPageTagSystem from '../../urls/urlPageTagSystem';
import config from '../../../config/app';



const HomePage = () => {
  const dispatch = useDispatch();
  const { userRoles, pages, userId } = useSelector(
    ({ user, pages }) => ({
      pages,
      userRoles: user.roles,
      userId: user.user.id,
    }),
    shallowEqual,
  );

  const [ showTutorial ] = useState(false);

  useEffect(async () => {
    if (checkRole([ ...groupsRoles.editors.coloring, ...groupsRoles.managers.all, ...groupsRoles.admin ], userRoles)) {
      dispatch(actions.getGroups());
    }

    await dispatch(userActions.getProfile());

    await Promise.allSettled([
      dispatch(categoryActions.fetchCategories()),
      dispatch(configActions.getConfig()),
      dispatch(countriesActions.fetchCountries()),
      dispatch(getUsersAll()),
      dispatch(getTags()),
    ]);
  }, []);

  const standardPage = () => {
    if (checkRole(
      userRoles,
      [
        ...groupsRoles.admin,
        roles['content-manager'].key,
        ...groupsRoles.editors.all,
      ],
    )) {
      return urlPageDashboard();
    }
    if (checkRole(
      userRoles,
      groupsRoles.executors.all,
    )) {
      return urlPageTasksAvailable();
    }
    return urlPageProfile();
  };

  const isManager = checkRole(userRoles, [ roles['content-manager'].key ]);
  const isAdmin = checkRole(userRoles, [ roles.administrator.key ]);
  const isEmployee = checkRole(userRoles, [ roles.administrator.key, ...groupsRoles.managers.coloring, ...groupsRoles.editors.all, roles['lead-coloring-tester'].key ]);
  const canTagPicture = checkRole(userRoles, [ roles.administrator.key, ...groupsRoles.managers.coloring, ...groupsRoles.editors.all ]);

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      {showTutorial ? (
        <Tutorial />
      ) : (
        <Switch>
          <Redirect
            exact
            from='/'
            to={standardPage()}
          />
          {isAdmin && <Route path={urlPageInvitations()} component={Invitation} />}
          {isEmployee && <Route path={urlPageFreelancerDetails()} component={Profile} />}
          {isManager && <Route path={urlPageDashboard()} component={ContentManagerDashboard} />}
          <Route path={urlPageProfile()} component={MyProfile} />
          <Route path={urlPageSuggestion()} component={Suggestion} />
          <Route key="publicOffer" path={'/public-offer'} component={PublicOffer} />

          {pages.filter((page) => {
            if (page.roles && checkRole(userRoles, page.roles, page.antiRoles || [])) {
              return !(page.page === urlPageTagSystem() && config.hideTagSystemById === userId);
            }
            return false;
          }).map((page) => {
            return (
              <Route key={page.page} path={page.page} component={page.component} />
            );
          })}
          {canTagPicture && <Route exact path={urlPageTagSystemPictures()} component={TagPicture} />}
          <Route path={urlPageError()} component={ErrorsPage} />
          <Redirect to={urlPageError()} />
        </Switch>
      )}
    </Suspense>
  );
};

export default HomePage;
