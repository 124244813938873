import React from 'react';
import checkRole from '../../../utils/checkRole';
import roles from '../roles/roles';
import ArtistRatesModal from '../modal/ArtistRatesModal';
import ColoristRatesModal from '../modal/ColoristRatesModal';
import { positiveNumberValidator } from '../../../utils/validators';



export default function IndividualUserRates () {
  const { customer } = this.state;

  const { lang } = this.props;

  this.ArtistRatesModal = ArtistRatesModal.bind(this);

  this.ColoristRatesModal = ColoristRatesModal.bind(this);

  this.toggleArtistRatesModal = () => {
    this.setState({
      showArtistRatesModal: !this.state.showArtistRatesModal,
      newRates: {
        ...customer.rates,
        artist: customer.rates?.artist,
      },
    });
  };
  this.toggleColoristRatesModal = () => {
    this.setState({
      showColoristRatesModal: !this.state.showColoristRatesModal,
      newRates: {
        ...customer.rates,
        colorist: customer.rates?.colorist,
      },
    });
  };

  this.canUpdateUserRates = () => {
    return checkRole(this.props.userRoles, [
      roles.administrator.key,
      roles['lead-coloring-editor'].key,
    ]);
  };

  this.canViewUserRates = () => {
    return checkRole(this.props.userRoles, [
      roles.administrator.key,
      roles['content-manager'].key,
      roles['coloring-editor'].key,
      roles['lead-coloring-editor'].key,
    ]) && checkRole(customer.roles, [
      roles['coloring-artist'].key,
      roles['coloring-designer'].key,
    ]) && !customer.is_employee;
  };

  this.showArtistRates = () => {
    const artistRates = customer.rates?.artist;
    const artistC1 = artistRates?.c1 || '-';
    const artistC2 = artistRates?.c2 || '-';
    const artistC3 = artistRates?.c3 || '-';

    return (<span className="pl-3">
      {artistC1} | {artistC2} | {artistC3}
    </span>);
  };

  this.showColoristRates = () => {
    const coloristRates = customer.rates?.colorist;
    const coloristC1 = coloristRates?.c1 || '-';
    const coloristC2 = coloristRates?.c2 || '-';
    const coloristC3 = coloristRates?.c3 || '-';

    return (<span className="pl-3">
      {coloristC1} | {coloristC2} | {coloristC3}
    </span>);
  };

  this.handleRateChange = (type, complexity, event) => {
    this.setState({
      newRates: {
        ...customer.rates,
        [type]: {
          ...this.state.newRates?.[type],
          [complexity]: positiveNumberValidator(event.target.value),
        },
      },
    });
  };

  this.isNewRatesNotEmpty = () => {
    return this.state.newRates && Object.values(this.state.newRates).some(
      (entry) => entry.c1 || entry.c2 || entry.c3
    );
  };
  this.updateRates = async () => {
    this.setState({
      LOAD: true,
    });
    const newRates = this.isNewRatesNotEmpty() ? this.state.newRates : null;

    try {
      await this.props.updateRatesRequest(newRates, this.state.customer.id);
      this.setState({
        customer: {
          ...customer,
          rates: newRates,
        },
        showArtistRatesModal: false,
        showColoristRatesModal: false,
        LOAD: false,
      });
      this.props.notification('NOTIFICATION.SUCCESS_INDIVIDUAL_USER_RATES_UPDATED', 'success');
    } catch {
      this.setState({
        LOAD: false,
      });
      this.props.notification('NOTIFICATION.ERROR_INDIVIDUAL_USER_RATES_UPDATE', 'error');
    }
  };

  return (
    this.canViewUserRates() && (
      <div className="col-sm-12 col-md-3 col-xl-2">
        <div className="kt-widget__subhead kt-pb-0">
          <div className="kt-form kt-form">
            <div className="form-group mb-0 d-flex align-items-center">
              <label className="col-form-label">{lang['CUSTOMER.RATES']}</label>
            </div>
            {checkRole(customer.roles, [ roles['coloring-artist'].key ]) && (
              <div className="form-group mb-0 d-flex align-items-center">
                <label className="col-form-label">
                  {roles['coloring-artist'].title}:
                </label>
                {this.showArtistRates()}
                {this.canUpdateUserRates() &&
                  <button
                    className="ml-2 btn btn-primary btn-icon btn-xs"
                    onClick={this.toggleArtistRatesModal}
                  >
                    <i className="flaticon2-edit" />
                  </button>
                }
              </div>
            )}
            {checkRole(customer.roles, [ roles['coloring-designer'].key ]) && (
              <div className="form-group mb-0 d-flex align-items-center">
                <label className="col-form-label">
                  {roles['coloring-designer'].title}:
                </label>
                {this.showColoristRates()}
                {this.canUpdateUserRates() &&
                  <button
                    className="ml-2 btn btn-primary btn-icon btn-xs"
                    onClick={this.toggleColoristRatesModal}
                  >
                    <i className="flaticon2-edit" />
                  </button>
                }
              </div>
            )}
            {this.ArtistRatesModal()}
            {this.ColoristRatesModal()}
          </div>
        </div>
      </div>
    )
  );
}

