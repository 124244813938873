import { Form } from 'react-bootstrap';
import React from 'react';
import MySelect from '../../../widgets/MySelect';

/**
 * Builds an input with a drop-down list for the field
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} value
 * @param {Function} onChange
 * @param {any} project
 * @param {boolean} required
 * @returns {*}
 */
// eslint-disable-next-line react/display-name
export default function (field, value = null, onChange = null, project = null, required) {
  if (!project) {
    project = this.state;
  }
  const { lang } = this.props;

  value = (value === null ? project[field.key || field.keys[0]] : value) || '';

  value = field.options.find((v) => v.value === value);

  //TODO hack to fix issue with required executors -- needs refactoring
  if (project.visibility === 'off' && project.project_type === 'coloring-artist' && field.title === 'Executor') {
    field.isRequired = true;
    required = true;
  }

  return (
    <Form.Group
      as={Form.Row} key={field.key || field.keys[0]}
      style={{
        marginBottom: '1rem',
        ...(field.isFull ? {} : {
          maxWidth: 1024,
          marginLeft: 'auto',
          marginRight: 'auto',
        }),
      }}
    >
      <div className={field.isColumn ? 'col-12' : 'col-lg-3 col-12'}>
        <Form.Label column>
          {(lang[field.translate] || lang[`PROJECT.FIELDS.${field.key}`] || field.title || '')}
          {field.isRequired && required && (
            <span style={{ color: 'red' }}>{' *'}</span>
          )}
        </Form.Label>
      </div>
      <div className={field.isColumn ? 'col-12 d-flex flex-column' : 'col-lg-9 col-12 d-flex flex-column'}>
        <MySelect
          className="kt-mb-15"
          isClearable
          value={value}
          options={field.options}
          isInvalid={this.state.showInvalid && field.isRequired && required && (value === null ? !project[field.key] : !value)}
          onChange={(value) => {
            if (onChange === null) {
              if (field.key) {
                this.setState({
                  project: {
                    ...project,
                    [field.key]: value.value,
                  },
                });
              } else if (field.keys) {
                const _project = { ...project };

                field.keys.forEach((key) => {
                  _project[key] = value.value;
                });
                this.setState({
                  project: _project,
                });
              }
            } else {
              onChange(value.value);
            }
          }}
        />
        {field.muted && (
          <Form.Text className="text-muted">
            {field.muted}
          </Form.Text>
        )}
        <Form.Control.Feedback type="invalid">
          {lang['GLOBAL.FIELD_REQUIRED']}
        </Form.Control.Feedback>
      </div>
    </Form.Group>
  );
}
