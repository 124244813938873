import { status } from '../../../utils/statusToColor';
import checkRole from '../../../utils/checkRole';
import groupsRoles from '../../customers/roles/groupsRoles';



const canEditImgType = (userRoles, task, userId) => {
  return (
    task.status === status.process
    && task.executor_id === userId
    && checkRole(
      userRoles,
      [
        ...groupsRoles.managers.coloring,
        ...groupsRoles.admin,
        ...groupsRoles.editors.coloring,
      ]
    )
  );
};

export default canEditImgType;
