import { Col, Form, Row } from 'react-bootstrap';
import React from 'react';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';



const OptionList = ({ items, control }) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  return (
    <>
      <strong className="d-flex mb-4">{lang['TAG_SYSTEM.OPTIONS.TITLE']}</strong>
      <Row>
        {items.map((option) => {
          const name = `option_${option.name}`;

          return (
            <Col key={'is_searchable'} sm={4}>
              <Form.Group className="d-flex">
                <Controller
                  name={name}
                  control={control}
                  render={({ field }) => (
                    <Form.Check
                      {...field}
                      type="checkbox"
                      role='button'
                      id={name}
                      name={name}
                      checked={field.value}
                    />
                  )}
                />
                <Form.Label
                  htmlFor={name}
                  role="button"
                  className='w-100'
                >
                  {lang[`TAG_SYSTEM.OPTIONS.${option.name?.toUpperCase()}`]}
                </Form.Label>
              </Form.Group>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

OptionList.propTypes = {
  items: PropTypes.array.isRequired,
  control: PropTypes.any.isRequired,
};

export default OptionList;
