import { actionsNotifications } from '../notification';
import { NOTIFICATIONS_TYPES } from '../../widgets/Notification';
import { getUserRoles } from '../../requests/users';
import { parseToPars } from '../../utils/parseUrlParams';
import axiosApiInstance from '../../requests/utils/api';



const actions = {
  SET_PROJECTS: 'SET_PROJECTS',
  SET_PROJECT: 'SET_PROJECT',
  FILTER_PROJECTS: 'FILTER_PROJECTS',
  UPDATE_PROJECT: 'UPDATE_PROJECT',
  LOADING_PROJECTS: 'LOADING_PROJECTS',
  toggleLoadingProjects: (payload) => ({
    type: actions.LOADING_PROJECTS,
    payload,
  }),
  setProjects: (payload) => ({
    type: actions.SET_PROJECTS,
    payload,
  }),
  setProject: (payload) => ({
    type: actions.SET_PROJECT,
    payload,
  }),
  updateProject: (payload) => ({
    type: actions.UPDATE_PROJECT,
    payload,
  }),
  updateProjectCategory: (payload) => async (dispatch, getState, api) => {
    const { language: { lang } } = getState();

    try {
      dispatch(actions.toggleLoadingProjects(true));

      const URL = `projects/${payload.projectId}/category`;

      await api.put(URL, {
        category_id: payload.categoryId,
      });

      dispatch(actions.updateProject(payload));

      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.SUCCESS_CATEGORY_EDIT'],
          NOTIFICATIONS_TYPES.success
        )
      );
    } catch {
      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.ERROR_CATEGORY_EDIT'],
          NOTIFICATIONS_TYPES.error
        )
      );
    } finally {
      dispatch(actions.toggleLoadingProjects(false));
    }
  },
  startProjects: (payload) => async (dispatch, getState, api) => {
    const { language: { lang } } = getState();

    try {
      dispatch(actions.toggleLoadingProjects(true));

      await api.put('projects/start', {
        ids: payload.ids,
        type: 'start',
      });
      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.SUCCESS_PROJECTS_START'],
          NOTIFICATIONS_TYPES.success
        )
      );


      dispatch(actions.fetchProjects({
        queryParams: payload.queryParams,
        type: payload.type,
      }));
    } catch {
      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.ERROR_PROJECTS_START'],
          NOTIFICATIONS_TYPES.error
        )
      );
    } finally {
      dispatch(actions.toggleLoadingProjects(false));
    }
  },
  fetchProjects: (params) => async (dispatch, getState, api) => {
    const { language: { lang } } = getState();

    try {
      dispatch(actions.toggleLoadingProjects(true));
      const queryString = parseToPars(params.queryParams);
      const response = await api.get(`projects${queryString}`);

      if (response.data) {
        const projects = response.data.projects;

        projects.forEach((project) => {
          if (!project.executorRoles && !params?.filteredParams?.from_stat) {
            const executorRoles = project.executor && getUserRoles(project.executor);

            project.executorRoles = executorRoles || [];
          }
        });

        dispatch(actions.setProjects({
          projects,
          [`total_${params.type}`]: response.data?.total,
          total: response.data?.total,
          type: params.type,
        }));
      }
    } catch {
      dispatch(
        actionsNotifications.add(
          lang['NOTIFICATION.FETCH_PROJECTS_FAILED'],
          NOTIFICATIONS_TYPES.error
        )
      );
    } finally {
      dispatch(actions.toggleLoadingProjects(false));
    }
  },
  getReopenedProjects: () => async (dispatch, _getState, api) => {
    try {
      const params = new URLSearchParams(document.location.search);

      let url = '/projects/reopened/list';

      if (params.has('id')) {
        url += `?${params.toString()}`;
      }

      const response = await api.get(url);

      return response?.data ?? [];
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return [];
    }
  },
  getProject: (id) => {
    return async (dispatch, getState) => {
      const { language: { lang } } = getState();

      try {
        dispatch(actions.toggleLoadingProjects(true));
        const URL = `projects/${id}`;
        const data = await axiosApiInstance.get(URL);

        if (data.data.project) {
          dispatch(actions.setProject(data.data.project));
          dispatch(actions.toggleLoadingProjects(false));
        }
      } catch {
        dispatch(
          actionsNotifications.add(
            lang['NOTIFICATION.FETCH_PROJECTS_FAILED'],
            NOTIFICATIONS_TYPES.error
          )
        );
        dispatch(actions.toggleLoadingProjects(false));
      }
    };
  },
};

export default actions;
