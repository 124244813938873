import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { isFabros, isXFLOW } from '../../../utils/isFabros';
import MySelect from '../../../widgets/MySelect';
import { isBYPersonalData, isRU, isSwiftRU, isTransitIban } from '../../../utils/checker';
import getCountriesOptionsList from '../../../utils/getCountriesOptionsList';
import getCountryCodeOptionsList from '../../../utils/getCountryCodeOptionsList';



const FinanceInformationTab = ({ isDisabled, control, errors, formValues }) => {
  const { lang, countries } = useSelector(({ language, countries }) => ({
    lang: language.lang,
    countries: countries.countries,
  }), shallowEqual);

  return (
    <div className="row">
      <h3 className='col-12'>{lang['CUSTOMER.FINANCES_INFO']}</h3>
      <div className='col-12'>
        <hr />
      </div>

      <Controller
        name="country"
        control={control}
        render={({ field }) => (
          <Form.Group className="col-lg-4">
            <Form.Label>
              {lang['REGISTRATION.COUNTRY']}
            </Form.Label>
            <MySelect
              {...field}
              isClearable
              disabled={isDisabled}
              isInvalid={!!errors.country}
              options={getCountriesOptionsList(countries)}
            />
            <Form.Text className="text-muted">
              {lang['REGISTRATION.COUNTRY_PLEASE']}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {lang['REGISTRATION.COUNTRY_ERROR']}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />
      <Controller
        name="city"
        control={control}
        render={({ field }) => (
          <Form.Group className="col-lg-4">
            <Form.Label>
              {lang['REGISTRATION.CITY']}
            </Form.Label>
            <Form.Control
              {...field}
              disabled={isDisabled}
              autoComplete="off"
              type="text"
              placeholder="Minsk"
              isInvalid={!!errors.city}
            />
            <Form.Text className="text-muted">
              {isBYPersonalData(formValues) ? lang['REGISTRATION.CITY_PLEASE_RUSSIAN'] : lang['REGISTRATION.CITY_PLEASE_LATIN']}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {isBYPersonalData(formValues) ? lang['REGISTRATION.CITY_ERROR_RU'] : lang['REGISTRATION.CITY_ERROR_LATIN']}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />
      <Controller
        name="address"
        control={control}
        render={({ field }) => (
          <Form.Group className="col-lg-4">
            <Form.Label>
              {lang['REGISTRATION.ADDRESS']}
            </Form.Label>
            <Form.Control
              {...field}
              disabled={isDisabled}
              autoComplete="off"
              type="text"
              placeholder="str Lenina 1-1"
              isInvalid={!!errors.address}
            />
            <Form.Text className="text-muted">
              {isBYPersonalData(formValues) ? lang['REGISTRATION.ADDRESS_PLEASE_RUSSIAN'] : (isXFLOW ? lang['REGISTRATION.ADDRESS_PLEASE_LATIN_XFLOW'] : lang['REGISTRATION.ADDRESS_PLEASE_LATIN'])} {isFabros ? lang['REGISTRATION.ADDRESS_MAX_CHARACTERS'] : ''}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {isBYPersonalData(formValues) ? lang['REGISTRATION.ADDRESS_ERROR_RU'] : lang['REGISTRATION.ADDRESS_ERROR_LATIN']}
              <br />
              {isFabros ? lang['REGISTRATION.ADDRESS_ERROR_MAX_CHARACTERS'] : ''}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />
      {isFabros && isRU(formValues) && (
        <Controller
          name="inn"
          control={control}
          render={({ field }) => (
            <Form.Group className={'col-lg-4'}>
              <Form.Label>
                {lang['REGISTRATION.INN']}
              </Form.Label>
              <Form.Control
                {...field}
                disabled={isDisabled}
                type="text"
                placeholder=""
                isInvalid={!!errors.inn}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.INN_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.INN_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}
      {isXFLOW && (
        <Controller
          name="post_code"
          control={control}
          render={({ field }) => (
            <Form.Group className="col-lg-4">
              <Form.Label>
                {lang['REGISTRATION.POST_CODE']}
              </Form.Label>
              <Form.Control
                {...field}
                type="text"
                disabled={isDisabled}
                placeholder=''
                isInvalid={errors.post_code}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.POST_CODE_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.POST_CODE_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}

      {isXFLOW && (
        <>
          <Controller
            name="bank"
            control={control}
            render={({ field }) => (
              <Form.Group className={'col-lg-4'}>
                <Form.Label>
                  {lang['REGISTRATION.BANK_NAME']}
                </Form.Label>
                <Form.Control
                  {...field}
                  disabled={isDisabled}
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.bank}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.BANK_NAME_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.BANK_NAME_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
          <Controller
            name="bank_country"
            control={control}
            render={({ field }) => (
              <Form.Group className={'col-lg-4'}>
                <Form.Label>
                  {lang['REGISTRATION.BANK_COUNTRY']}
                </Form.Label>
                <MySelect
                  {...field}
                  autoComplete="off"
                  isClearable
                  disabled={isDisabled}
                  isInvalid={!!errors.bank_country}
                  options={getCountriesOptionsList(countries)}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.BANK_COUNTRY_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.BANK_COUNTRY_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
          <Controller
            name="bank_account_name"
            control={control}
            render={({ field }) => (
              <Form.Group className={'col-lg-4'}>
                <Form.Label>
                  {lang['REGISTRATION.BANK_ACCOUNT_NAME']}
                </Form.Label>
                <Form.Control
                  {...field}
                  disabled={isDisabled}
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.bank_account_name}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.BANK_ACCOUNT_NAME_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.BANK_ACCOUNT_NAME_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
          <Controller
            name="tin_country"
            control={control}
            render={({ field }) => (
              <Form.Group className={'col-lg-4'}>
                <Form.Label>
                  {lang['REGISTRATION.TIN_COUNTRY']}
                </Form.Label>
                <MySelect
                  {...field}
                  autoComplete="off"
                  isClearable
                  disabled={isDisabled}
                  isInvalid={!!errors.tin_country}
                  options={getCountryCodeOptionsList(countries)}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.TIN_COUNTRY_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.TIN_COUNTRY_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
          <Controller
            name="tin_number"
            control={control}
            render={({ field }) => (
              <Form.Group className={'col-lg-4'}>
                <Form.Label>
                  {lang['REGISTRATION.TIN_NUMBER']}
                </Form.Label>
                <Form.Control
                  {...field}
                  disabled={isDisabled}
                  autoComplete="off"
                  type="text"
                  placeholder=""
                  isInvalid={!!errors.tin_number}
                />
                <Form.Text className="text-muted">
                  {lang['REGISTRATION.TIN_NUMBER_PLEASE']}
                </Form.Text>
                <Form.Control.Feedback type="invalid">
                  {lang['REGISTRATION.TIN_NUMBER_ERROR']}
                </Form.Control.Feedback>
              </Form.Group>
            )}
          />
        </>
      )}

      <Controller
        name="iban"
        control={control}
        render={({ field }) => (
          <Form.Group className={'col-lg-4'}>
            <Form.Label>
              {lang['REGISTRATION.ACCOUNT_NUMBER']}
            </Form.Label>
            <Form.Control
              {...field}
              disabled={isDisabled}
              autoComplete="off"
              type="text"
              placeholder=""
              isInvalid={!!errors.iban}
            />
            <Form.Text className="text-muted">
              {lang['REGISTRATION.ACCOUNT_NUMBER_PLEASE']}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {lang['REGISTRATION.ACCOUNT_NUMBER_ERROR']}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />
      <Controller
        name="swift"
        control={control}
        render={({ field }) => (
          <Form.Group className={'col-lg-4'}>
            <Form.Label>
              {lang['REGISTRATION.SWIFT']}
            </Form.Label>
            <Form.Control
              {...field}
              disabled={isDisabled}
              autoComplete="off"
              type="text"
              placeholder=""
              isInvalid={!!errors.swift}
            />
            <Form.Text className="text-muted">
              {lang['REGISTRATION.SWIFT_PLEASE']}
            </Form.Text>
            <Form.Control.Feedback type="invalid">
              {lang['REGISTRATION.SWIFT_ERROR']}
            </Form.Control.Feedback>
          </Form.Group>
        )}
      />

      {isSwiftRU(formValues) && (
        <Controller
          name="bic_number"
          control={control}
          render={({ field }) => (
            <Form.Group className={'col-lg-4'}>
              <Form.Label>
                {lang['REGISTRATION.BIC']}
              </Form.Label>
              <Form.Control
                {...field}
                disabled={isDisabled}
                autoComplete="off"
                type="text"
                placeholder=""
                isInvalid={!!errors.bic_number}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.BIC_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.BIC_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}
      {isTransitIban(formValues) && (
        <Controller
          name="transit_iban"
          control={control}
          render={({ field }) => (
            <Form.Group className="col-lg-4">
              <Form.Label>
                {lang['REGISTRATION.TRANSIT_IBAN']}
              </Form.Label>
              <Form.Control
                {...field}
                disabled={isDisabled}
                type="text"
                isInvalid={!!errors.transit_iban}
              />
              <Form.Text className="text-muted">
                {lang['REGISTRATION.TRANSIT_IBAN_PLEASE']}
              </Form.Text>
              <Form.Control.Feedback type="invalid">
                {lang['REGISTRATION.TRANSIT_IBAN_ERROR']}
              </Form.Control.Feedback>
            </Form.Group>
          )}
        />
      )}
    </div>
  );
};

FinanceInformationTab.propTypes = {
  isDisabled: PropTypes.bool.isRequired,
  control: PropTypes.any.isRequired,
  errors: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
};

export default FinanceInformationTab;
