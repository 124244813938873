import { nameUsers } from './users';
import { nameTags } from './tags';
import { nameGroups } from './groups';
import { nameTasks } from './tasks';
import { nameLanguage } from './getLanguage';
import { nameCategories } from './categories';



const nameProjects = 'projects';
const nameConfigs = 'configs';
const priorities = {
  high: 'high',
  medium: 'medium',
  low: 'low',
};

/**
 * Cache Lifetime List
 *
 * @type {{[p: string]: string, "[nameConfigs]": string, "[nameProjects]": string, "[nameProfile]": string, "[nameGroups]": string, "[nameLanguage]": string, "[nameUsers]": string, "[nameTasks]": string, "[nameTags]": string}}
 */
const requestPriority = {
  profile: priorities.low,
  [nameConfigs]: priorities.low,
  [nameUsers]: priorities.medium,
  [nameTags]: priorities.medium,
  [nameGroups]: priorities.low,
  [nameTasks]: priorities.high,
  [nameProjects]: priorities.high,
  [nameLanguage]: priorities.low,
  [nameCategories]: priorities.low,
};

export default requestPriority;
