import React from 'react';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { ButtonGroup } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import urlPageTagSystemTags from '../../../urls/urlPageTagSystemTags';
import DeleteHintTooltip from './components/DeleteHintTooltip';



const GroupsTableItem = ({
  group,
  onEdit,
  onDelete,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const getGroupTemplates = () => {
    if (!group?.parents || !group?.parents?.[0]) {
      return '---';
    }
    if (group?.parents?.length > 1) {
      return (
        <div>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={
              (props) => (
                <Tooltip id="button-tooltip" {...props}>
                  {group?.parents?.map((item, index) => {
                    // eslint-disable-next-line no-unsafe-optional-chaining
                    return `${item.name}${index !== group?.parents?.length - 1 ? ', ' : ''}`;
                  })}
                </Tooltip>
              )
            }
          >
            <span>{group?.parents?.length} {lang['TAG_SYSTEM.TEMPLATES.TITLE'] }</span>
          </OverlayTrigger>
        </div>
      );
    }
    return (
      <>
        {group.parents?.[0]?.name }
      </>
    );
  };

  return (
    <tr>
      <td className='text-center'>
        {group.id}
      </td>
      <td className='text-left'>
        <Link
          className='font-weight-bold'
          to={urlPageTagSystemTags({ groupId: group.id })}
        >
          {group.name}
          {group.isGlobal && (
            <i className="fa fa-globe pl-1 align-top small" />
          )}
        </Link>

      </td>
      <td className='text-left'>
        {getGroupTemplates()}
      </td>
      <td className='text-left'>
        {group.hint || '---'}
      </td>
      <td className='text-center'>
        <ButtonGroup>
          <Button
            id={'edit-group'}
            title={lang['GLOBAL.EDIT']}
            variant='success'
            size='sm'
            onClick={onEdit}
          >
            <i className="fa fa-edit kt-m-0" />
          </Button>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 250, hide: 400 }}
            overlay={(props) => (
              <DeleteHintTooltip group={group} {...props} />
            )}
          >
            <Button
              disabled={!group.deletable}
              id="delete-group"
              title={lang['GLOBAL.REMOVE']}
              variant='danger'
              size='sm'
              onClick={onDelete}
            >
              <i className="fa fa-trash kt-m-0" />
            </Button>
          </OverlayTrigger>
        </ButtonGroup>
      </td>
    </tr>
  );
};


GroupsTableItem.propTypes = {
  group: PropTypes.object.isRequired,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
};

GroupsTableItem.defaultProps = {
  onEdit: () => {},
  onDelete: () => {},
};

export default GroupsTableItem;
