export const TASK_BONUS_STATUS = {
  CANCELED: 0,
  WAITING: 1,
  APPLIED: 2,
};

export const SHOW_TASK_DETAILS_BONUSES_MAP = [
  'artist_choosing',
  'designer_choosing',
  'designer_coloring',
  'editor_checking_designer',
  'artist_drawing',
  'device_testing',
];

/**
 * Get tasks bonus status function
 *
 * @param {object} lang
 * @param {number} status
 * @returns {string}
 */
export const getTaskBonusStatus = (lang, status) => {
  switch (status) {
    case TASK_BONUS_STATUS.CANCELED: {
      return lang['BONUS_STATUS.CANCELED'];
    }
    case TASK_BONUS_STATUS.WAITING: {
      return lang['BONUS_STATUS.WAITING'];
    }
    case TASK_BONUS_STATUS.APPLIED: {
      return lang['BONUS_STATUS.APPLIED'];
    }
    default: {
      return '---';
    }
  }
};

/**
 * Transform bonus status name to color name
 *
 * @param {string} status
 * @returns {string}
 */
export const bonusStatusToColor = (status) => {
  switch (status) {
    case TASK_BONUS_STATUS.APPLIED: {
      return 'success';
    }
    case TASK_BONUS_STATUS.CANCELED: {
      return 'danger';
    }
    case TASK_BONUS_STATUS.WAITING: {
      return 'secondary';
    }
    default: {
      return 'info';
    }
  }
};
