import { getCheckedValue } from '../../../utils/getCheckedValue';

/**
 * Checks the correctness of the entered data in the field and recursively checks its additional fields
 *
 * @this ProjectCreate
 * @param {any} field
 * @param {any} project
 * @returns {boolean}
 */
export default function (field, project = null) {
  if (!project) {
    project = this.state.project;
  }

  //TODO ugly hack to fix required executor for coloring-artist project. Needs to refactor
  if (field.title === 'Executor' && project.visibility === 'off' && project.project_type === 'coloring-artist') {
    field.isRequired = true;
  } else if (field.title === 'Executor' && project.visibility === 'on' && project.project_type === 'coloring-artist') {
    field.isRequired = false;
  }

  if (!field.isRequired) {
    // eslint-disable-next-line array-callback-return,consistent-return
    return !(field.addedFields && field.addedFields.findIndex((item) => {
      if ((item.value === project[field.key] && !this._checkField(item, project))) {
        return true;
      }
    }) !== -1);
  }

  if (field.keys) {
    // eslint-disable-next-line fp/no-loops
    for (let i = 0; i < field.keys.length; i += 1) {
      const val = getCheckedValue(project[field.keys[i]]);

      if (!val && val !== 0) {
        return false;
      }
    }
  } else {
    const val = getCheckedValue(project[field.key]);

    if (!val && val !== 0) {
      return false;
    }
  }

  // eslint-disable-next-line array-callback-return,consistent-return
  return !(field.addedFields && field.addedFields.findIndex((item) => {
    if ((item.value === project[field.key] && !this._checkField(item, project))) {
      return true;
    }
  }) !== -1);
}
