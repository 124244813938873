import React from 'react';
import Button from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Spinner } from 'react-bootstrap';
import Modal from '../../../../widgets/Modal';



const DeleteTag = ({
  show,
  loading,
  onClose,
  onDelete,
}) => {
  const { lang } = useSelector(({ language }) => ({
    lang: language.lang,
  }), shallowEqual);

  const handleDelete = () => {
    onDelete(() => {
      onClose();
    });
  };

  return (
    <Modal
      title={lang['TAG_SYSTEM.TAGS.YOU_SURE_DELETE']}
      show={show}
      bodyStyle={{ overflow: 'unset' }}
      close={onClose}
      footer={(
        <>
          <Button variant='secondary' onClick={onClose}>{lang['GLOBAL.CANCEL']}</Button>
          <Button variant='danger' onClick={handleDelete}>
            {loading && <Spinner animation="border" style={{ height: '12px', width: '12px' }} />}
            {' '}
            {lang['GLOBAL.YES_DELETE']}
          </Button>
        </>
      )}
    >
      <div>
        {lang['GLOBAL.YOU_SURE_DELETE_SUB']}
      </div>
    </Modal>
  );
};

DeleteTag.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  onClose: PropTypes.func,
  onDelete: PropTypes.func,
};

DeleteTag.defaultProps = {
  show: false,
  onClose: () => {},
};

export default DeleteTag;
