import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button, ButtonGroup } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import Table from 'react-bootstrap/Table';
import { dateTimeFormat, formatToPrettyDateTime } from '../../utils/formats';
import SubHeader from '../../../_metronic/layout/sub-header/SubHeader';
import checkRole from '../../utils/checkRole';
import groupsRoles from '../customers/roles/groupsRoles';
import roles from '../customers/roles/roles';
import Preload from '../../widgets/Preload';
import manualActions from '../../store/manuals/actions';
import urlPageManualsCreate from '../../urls/urlPageManualsCreate';
import urlPageManualEdit from '../../urls/urlPageManualEdit';
import urlPageManualDetails from '../../urls/urlPageManualDetails';
import usePrevious from '../../hooks/usePrevious';



const ListManuals = () => {
  const lang = useSelector((store) => store.language.lang);
  const { loading: LOAD, manuals } = useSelector((store) => store.manuals);
  const { roles: userRoles, currentRole } = useSelector((store) => store.user);
  const prevRole = usePrevious(currentRole);
  const dispatch = useDispatch();

  useEffect(() => {
    if (prevRole !== currentRole) {
      dispatch(manualActions.getManuals());
    }
  }, [ currentRole ]);

  const deleteManual = (event) => {
    dispatch(manualActions.deleteManual(event.target.id));
  };

  const updateManual = (manual) => {
    dispatch(manualActions.updateManual(manual));
  };

  return LOAD ? (
    <Preload />
  ) : (
    <>
      <SubHeader
        title={lang['MANUAL.LIST']}
        toolbar={
          checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['lead-coloring-editor'].key ]) && (
            <Link to={urlPageManualsCreate()} className="btn btn-bold btn-label-brand">
              {lang['MANUAL.CREATE']}
            </Link>
          )
        }
      />
      <div className="row">
        <div className="col-12">
          <div className="kt-portlet">
            <div className="kt-portlet__head">
              <div className="kt-portlet__head-label">
                <h3 className="kt-portlet__head-title">
                  {lang['MANUAL.AVAILABLE']}
                </h3>
              </div>
            </div>
            <div className="kt-portlet__body">
              <div className="kt-section__content mw-100 overflow-auto">
                <Table striped className="text-center vertical-align-middle">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{lang['MANUAL.TITLE']}</th>
                      <th>{lang['MANUAL.FOR_ROLE']}</th>
                      <th>{lang['GLOBAL.STATUS']}</th>
                      <th>{lang['MANUAL.DATE_PUBLISHED']}</th>
                      <th>{lang['MANUAL.DATE_CHANGES']}</th>
                      <th>{lang['GLOBAL.ACTIONS']}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {manuals.map((manual, index) => {
                      return (
                        <tr key={manual.id}>
                          <th scope="row">
                            {index + 1}
                          </th>
                          <td>{manual.name}</td>
                          <td>
                            <span
                              key={uuidv4()}
                              className="btn btn-label-brand btn-sm"
                            >
                              {roles[manual.role]?.title}
                            </span>
                          </td>
                          <td>
                            {manual.is_published ?
                              <span className="btn btn-label-success-o2 btn-sm">
                                {lang['MANUAL.IS_PUBLISHED.YES']}
                              </span>
                              : <span className="btn btn-label-dark-o2 btn-sm">
                                {lang['MANUAL.IS_PUBLISHED.NO']}
                              </span>}
                          </td>
                          <td>
                            {manual.is_published && manual.published_at ? dateTimeFormat.format(new Date(manual.published_at.replace(/-/g, '/'))) : '---'}
                          </td>
                          <td>
                            {manual.updated_at ? formatToPrettyDateTime(manual.updated_at) : '---'}
                          </td>
                          <td>
                            <ButtonGroup>
                              <Button
                                as={Link}
                                to={urlPageManualDetails({ manualId: manual.id })}
                                title={lang['MANUAL.VIEW']}
                                variant='brand'
                                size='sm'
                              >
                                <i className="fa fa-eye kt-m-0" />
                              </Button>
                              {checkRole(userRoles, [ ...groupsRoles.admin, ...groupsRoles.managers.all, roles['lead-coloring-editor'].key ]) && (<>
                                {manual.is_published ?
                                  <Button
                                    title={lang['MANUAL.HIDDEN']}
                                    style={{ color: 'white' }}
                                    variant='warning'
                                    size='sm'
                                    onClick={() => {
                                      updateManual({
                                        ...manual,
                                        is_published: false,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-times-circle kt-m-0" />
                                  </Button>
                                  :
                                  <Button
                                    title={lang['MANUAL.PUBLISH']}
                                    variant='success'
                                    size='sm'
                                    onClick={() => {
                                      updateManual({
                                        ...manual,
                                        is_published: true,
                                      });
                                    }}
                                  >
                                    <i className="fa fa-check kt-m-0" />
                                  </Button>
                                }
                                <Button
                                  title={lang['GLOBAL.EDIT']}
                                  variant='primary'
                                  size='sm'
                                  as={Link}
                                  to={urlPageManualEdit({ manualId: manual.id })}
                                >
                                  <i className="fa fa-edit kt-m-0" />
                                </Button>
                                <Button
                                  id={manual.id}
                                  title={lang['GLOBAL.REMOVE']}
                                  variant='danger'
                                  size='sm'
                                  onClick={deleteManual}
                                >
                                  <i className="fa fa-trash kt-m-0" />
                                </Button>
                              </>)}
                            </ButtonGroup>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ListManuals;
