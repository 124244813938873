import React from 'react';
import { components } from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import MySelect from './MySelect';



const propTypes = {
  canCreate: PropTypes.bool,
  onChange: PropTypes.func,
  value: PropTypes.any,
  filter: PropTypes.func,
  lang: PropTypes.object,
  categories: PropTypes.any,
  isClearable: PropTypes.bool,
  disabled: PropTypes.bool,
};

/**
 * Component for select categories
 *
 * @returns {*}
 */
class SelectCategories extends React.PureComponent {
  state = {
    fullList: [],
    list: [],
    value: '',
    isLoading: false,
  };

  render () {
    return (
      <MySelect
        as={this.props.canCreate && CreatableSelect}
        isClearable={this.props.isClearable}
        isMulti
        isLoading={this.state.isLoading}
        isDisabled={this.state.isLoading || this.props.disabled}
        inputValue={this.state.value}
        onInputChange={(value) => {
          this.setState({ value });
        }}
        closeMenuOnScroll
        options={this.state.list}
        classNamePrefix={'categories'}
        value={this.state.list.filter((category) => this.props.value.findIndex(
          ((item) => item === category.value)
        ) !== -1)}
        onChange={(value) => {
          this.props.onChange(value ? value.map((v) => v.value) : [], value || []);
        }}
        components={{
          MenuList: (props) => {
            return (
              <components.MenuList {...props}>
                <div className='d-flex flex-wrap justify-content-between'>
                  {props.children}
                </div>
              </components.MenuList>
            );
          },
          Option: (props) => {
            return (
              <div
                style={{
                  backgroundColor: 'hsl(0,0%,90%)',
                  borderRadius: 2,
                  display: 'flex',
                  margin: 2,
                  minWidth: 0,
                  boxSizing: 'border-box',
                  flex: '1 0 auto',
                  textAlign: 'center',
                  cursor: 'pointer',
                }}
              >
                <components.Option {...props}>
                  {props.children}
                </components.Option>
              </div>);
          },
        }}
      />
    );
  }

  componentDidUpdate (prevProps) {
    if (prevProps.filter !== this.props.filter) {
      this.setState({
        list: this.props.filter ? this.state.fullList.filter(this.props.filter) : this.state.fullList,
      });
    }

    if (prevProps.categories.length !== this.props.categories.length) {
      this.getCategories();
    }
  }

  componentDidMount () {
    this.getCategories();
  }

  getCategories = () => {
    const categories = this.props.categories.filter((category) => category.id !== this.props.project?.options?.category_id).map((category) => ({
      value: category.id,
      label: category.name,
      slug: category.slug,
      deleted_at: category.deleted_at,
    }));

    this.setState({
      fullList: categories,
      list: this.props.filter ? categories.filter(this.props.filter) : categories,
    });
  };
}

const mapStoreToProps = (store) => {
  return {
    lang: store.language.lang,
    categories: store.categories,
  };
};

SelectCategories.defaultProps = {
  canCreate: false,
  isClearable: true,
  disabled: false,
  value: [],
};

SelectCategories.propTypes = propTypes;

export default connect(mapStoreToProps)(SelectCategories);
