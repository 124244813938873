import { connect } from 'react-redux';
import { mapDispatchToProps, mapStoreToProps, TaskInfo } from '../tasks/info/TaskInfo';
import { status } from '../../utils/statusToColor';
import tasks from './tasks';



class TutorialTaskInfo extends TaskInfo {
  constructor (props) {
    super(props);

    this.state.task = {};
    this.state.LOAD_TASK = false;
    this.computed.canStartWork = () => {
      return (
        this.computed.realStatus === status.waiting
      );
    };
  }

  loadTaskTimeout = null;

  _getTask = () => {
    const id = Number(this.props.id);

    this.setState({
      LOAD_TASK: false,
      LOAD_GROUP: false,
      LOAD_GROUPS: false,
      task: tasks.artist.find((task) => task.id === id),
    });
  };

  _startTask = async () => {
    this.setState({
      task: {
        ...this.state.task,
        status: status.process,
        executor_id: this.props.user.id,
        started_at: new Date(),
      },
      LOAD_TASK: false,
    }, this.props.nextStepTour);
  };

  componentWillUnmount () {
    if (this.loadTaskTimeout) {
      clearTimeout(this.loadTaskTimeout);
    }
  }

  finishTask = () => {
    this.setState({
      task: {
        ...this.state.task,
        status: status.finished,
      },
    });
  };

  selectFiles = () => {
    this.setState({
      task: {
        ...this.state.task,
        files: tasks.afterLoad.artist,
        project: {
          ...this.state.task.project,
          files: tasks.afterLoad.artist,
        },
      },
    });
  };
}

const mapStoreToProps2 = (store) => {
  return {
    ...mapStoreToProps(store),
    user: store.user.user,
  };
};

export default connect(mapStoreToProps2, mapDispatchToProps, null, { forwardRef: true })(TutorialTaskInfo);
